define("flaneur/pods/brand/boutiques/boutique/products/new/route", ["exports", "flaneur/models/product"], function (_exports, _product) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    model() {
      return Ember.RSVP.hash({
        product: _product.default.create({
          brand: this.brand._id,
          currency: this.brand.currentBoutique.currency,
          boutique: this.brand.currentBoutique._id
        }),
        boutique: this.brand.currentBoutique,
        fieldMessages: {},
        // Error messages for each field
        isLoading: false,
        imageFolder: `brand/${this.brand._id}`
      });
    },

    actions: {
      onSave(product) {
        const brand = this.brand;
        const boutique = this.brand.currentBoutique;
        this.set('currentModel.isLoading', true);
        return product.save().then(() => {
          this.set('currentModel.isLoading', false);
          this.notify.show('success', 'Product successfully updated');
          this.analytics.gtm({
            action: 'created_new_product',
            label: product._id
          });
          this.transitionTo('brand.boutiques.boutique.products.index', brand, boutique);
          window.scrollTo(0, 0);
        }).catch(validationErrors => {
          this.set('currentModel.isLoading', false);
          this.set('currentModel.fieldMessages', validationErrors);
          this.notify.show('error', 'Your product is missing information. Please review fields in red.');
        });
      }

    }
  });

  _exports.default = _default;
});