define("flaneur/enums/regular-expressions", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    accountNumber: /^(\d){8}$/,
    bic: /^([A-Z]{4})([A-Z]{2})([A-Z0-9]{2})([A-Z0-9]{3})?$/i,
    sortCode: /^(?!(?:0{6}|00-00-00))(\d\d-\d\d-\d\d)$/
  };
  _exports.default = _default;
});