define("flaneur/mixins/keyword-highlight", ["exports", "mark.js"], function (_exports, _mark) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Mixin.create({
    // Public API
    keywordHighlightOpts: {},
    // Private API
    _markInstance: null,

    didInsertElement() {
      this._super(...arguments);

      const {
        selectors
      } = this.keywordHighlightOpts;

      if (Ember.isEmpty(selectors)) {
        return;
      }

      this.set('_markInstance', new _mark.default(selectors.join(',')));
    },

    didRender() {
      this._super(...arguments);

      const opts = {
        element: 'span',
        className: 'highlight'
      };
      /* Remove all current marks */

      this._markInstance.unmark(opts);

      const keyword = this[this.keywordHighlightOpts.keywordField];

      if (Ember.isEmpty(keyword)) {
        return;
      }
      /* Mark the new keyword */


      this._markInstance.mark(keyword, opts);
    }

  });

  _exports.default = _default;
});