define("flaneur/pods/components/pages/shipments/index-page/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    analytics: Ember.inject.service(),
    brand: Ember.inject.service(),
    features: Ember.inject.service(),
    router: Ember.inject.service(),
    classNames: ['page'],
    routePrefix: 'brand.boutiques.boutique.shipments',

    init() {
      this._super(...arguments);

      if (!this.routeModels) {
        this.set('routeModels', [this.brand, this.brand.currentBoutique]);
      }
    },

    didInsertElement() {
      this._super(...arguments);

      this.set('orderNumber', this.model.ordernumber);
    },

    searchOrder: Ember._action(function () {
      return this.search(this.orderNumber);
    }),
    resetSearch: Ember._action(function () {
      this.set('orderNumber', '');
      return this.search();
    }),
    onChangeOrderNumber: Ember._action(function (orderNumber) {
      if (Ember.isEmpty(orderNumber)) {
        return this.resetSearch();
      }

      this.set('orderNumber', orderNumber);
    }),
    openHelpCenter: Ember._action(function () {
      return window.open('https://trouvaboutiques.zendesk.com/hc/en-us/articles/360015615937');
    })
  });

  _exports.default = _default;
});