define("flaneur/pods/components/pages/statements/index/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "Gwbe0kMn",
    "block": "{\"symbols\":[\"statement\",\"@model\"],\"statements\":[[10,\"div\"],[15,0,[31,[\"statements \",[32,0,[\"styleNamespace\"]]]]],[12],[2,\"\\n  \"],[10,\"div\"],[14,0,\"statements__header\"],[12],[2,\"Report\"],[13],[2,\"\\n  \"],[10,\"div\"],[14,0,\"statements__header\"],[12],[2,\"Date\"],[13],[2,\"\\n  \"],[10,\"div\"],[14,0,\"statements__header statements__header--payout\"],[12],[2,\"Payout\"],[13],[2,\"\\n  \"],[10,\"div\"],[14,0,\"statements__header\"],[12],[13],[2,\"\\n\\n\"],[6,[37,1],[[30,[36,0],[[30,[36,0],[[32,2]],null]],null]],[[\"key\"],[\"_id\"]],[[\"default\"],[{\"statements\":[[2,\"    \"],[8,\"statement-item\",[],[[\"@statement\"],[[32,1]]],null],[2,\"\\n\"]],\"parameters\":[1]}]]],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"-track-array\",\"each\"]}",
    "moduleName": "flaneur/pods/components/pages/statements/index/template.hbs"
  });

  _exports.default = _default;
});