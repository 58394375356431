define("flaneur/pods/components/basic-form-section/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "QrZ8qq5x",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[18,1,[[30,[36,9],null,[[\"publicAPI\",\"label\",\"control\",\"help\",\"suggestion\",\"warning\",\"invalid\"],[[35,0],[30,[36,3],[[35,8]],[[\"section\"],[[30,[36,1],[[35,0]],null]]]],[30,[36,3],[[35,7]],[[\"section\"],[[30,[36,1],[[35,0]],null]]]],[30,[36,3],[[35,6]],[[\"section\"],[[30,[36,1],[[35,0]],null]]]],[30,[36,3],[[35,5]],[[\"section\"],[[30,[36,1],[[35,0]],null]]]],[30,[36,3],[[35,4]],[[\"section\"],[[30,[36,1],[[35,0]],null]]]],[30,[36,3],[[35,2]],[[\"section\"],[[30,[36,1],[[35,0]],null]]]]]]]]],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"publicAPI\",\"readonly\",\"invalidComponent\",\"component\",\"warningComponent\",\"suggestionComponent\",\"helpComponent\",\"controlComponent\",\"labelComponent\",\"hash\"]}",
    "moduleName": "flaneur/pods/components/basic-form-section/template.hbs"
  });

  _exports.default = _default;
});