define("flaneur/pods/components/statement-item/component", ["exports", "@glimmer/component", "big.js", "ember-component-css/pod-names"], function (_exports, _component, _big, _podNames) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _class, _descriptor, _descriptor2;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  let StatementItemComponent = (_dec = Ember.inject.service, _dec2 = Ember.inject.service, (_class = class StatementItemComponent extends _component.default {
    constructor(...args) {
      super(...args);

      _initializerDefineProperty(this, "brand", _descriptor, this);

      _initializerDefineProperty(this, "media", _descriptor2, this);

      _defineProperty(this, "styleNamespace", _podNames.default['statement-item']);
    }

    get isStatementsVersion3() {
      return this.brand.currentBoutique.statements_version === 3;
    }

    get previewUrl() {
      const {
        statement
      } = this.args;
      return this.isStatementsVersion3 && Ember.isPresent(statement.statement_url) ? statement.previewUrl : null;
    }

    get showActions() {
      return this.media.isSmall && Ember.isPresent(this.previewUrl);
    }

    get payout() {
      const {
        total_payout,
        isCurrencyStatement
      } = this.args.statement;
      return isCurrencyStatement ? total_payout : (0, _big.default)(total_payout).times(-1);
    }

    get currency() {
      var _this$args$statement$;

      return (_this$args$statement$ = this.args.statement.currency) !== null && _this$args$statement$ !== void 0 ? _this$args$statement$ : this.brand.currentBoutique.currency;
    }

  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "brand", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "media", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  })), _class));
  _exports.default = StatementItemComponent;
});