define("flaneur/pods/orders/order/route", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    model() {
      return {
        errors: [{
          message: "This email has expired! Please log in to your retail panel and confirm the order from the 'orders' page"
        }]
      };
    }

  });

  _exports.default = _default;
});