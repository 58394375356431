define("flaneur/pods/brand/boutiques/boutique/frames/index/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "ZO7sqjdT",
    "block": "{\"symbols\":[\"@model\"],\"statements\":[[6,[37,0],[[32,0,[\"isEmpty\"]]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"  \"],[8,\"pages/frames/empty\",[],[[],[]],null],[2,\"\\n\"]],\"parameters\":[]},{\"statements\":[[2,\"  \"],[8,\"pages/frames/index\",[],[[\"@model\"],[[32,1]]],null],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"if\"]}",
    "moduleName": "flaneur/pods/brand/boutiques/boutique/frames/index/template.hbs"
  });

  _exports.default = _default;
});