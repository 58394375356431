define("flaneur/pods/components/zendesk-redirect/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "Q+O7i1jG",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[10,\"div\"],[14,0,\"zendesk-redirect__container\"],[12],[2,\"\\n    \"],[10,\"div\"],[14,0,\"zendesk-redirect__content\"],[12],[2,\"\\n        \"],[18,1,null],[2,\"\\n    \"],[13],[2,\"\\n    \"],[10,\"div\"],[14,0,\"zendesk-redirect__footer\"],[12],[2,\"\\n        \"],[10,\"img\"],[14,\"src\",\"https://trouva-media.imgix.net/rphc/logos/trouva_2018_h75.png\"],[14,0,\"zendesk-redirect__logo\"],[14,\"alt\",\"Trouva\"],[12],[13],[2,\"\\n    \"],[13],[2,\"\\n\"],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[]}",
    "moduleName": "flaneur/pods/components/zendesk-redirect/template.hbs"
  });

  _exports.default = _default;
});