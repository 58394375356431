define("flaneur/pods/components/access-point-modal/component", ["exports", "@glimmer/component", "ember-component-css/pod-names"], function (_exports, _component, _podNames) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _class, _descriptor;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  let AccessPointModalComponent = (_dec = Ember._tracked, _dec2 = Ember._action, _dec3 = Ember._action, (_class = class AccessPointModalComponent extends _component.default {
    constructor() {
      super(...arguments);

      _initializerDefineProperty(this, "isNotifyMessage", _descriptor, this);

      this.isNotifyMessage = false;
    }

    get styleNamespace() {
      return _podNames.default['access-point-modal'];
    }

    notifyCustomerAndFail() {
      this.isNotifyMessage = !this.isNotifyMessage;
      this.args.createZendeskTicketForAccessPoint();
      this.args.setReservationToFail();
    }

    onDismiss() {
      this.args.dismissAccessPointModal();
    }

  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "isNotifyMessage", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "notifyCustomerAndFail", [_dec2], Object.getOwnPropertyDescriptor(_class.prototype, "notifyCustomerAndFail"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "onDismiss", [_dec3], Object.getOwnPropertyDescriptor(_class.prototype, "onDismiss"), _class.prototype)), _class));
  _exports.default = AccessPointModalComponent;
});