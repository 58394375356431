define("flaneur/pods/components/product-amendment-card/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "9kzyLZPA",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[10,\"div\"],[14,0,\"product-amendment-card--avatar\"],[12],[1,[30,[36,2],[\"monogram\"],null]],[13],[2,\"\\n\\n\"],[10,\"div\"],[14,0,\"product-amendment-card--content\"],[12],[2,\"\\n  \"],[10,\"span\"],[14,0,\"product-amendment-card--name\"],[12],[2,\"From \"],[1,[34,3]],[13],[2,\"\\n  \"],[10,\"span\"],[14,0,\"product-amendment-card--timestamp\"],[12],[1,[30,[36,4],[[35,0,[\"created_at\"]],\"dd MMM yyyy\"],null]],[13],[2,\"\\n\\n\"],[2,\"  \"],[10,\"h5\"],[14,0,\"product-amendment-card--field\"],[12],[1,[30,[36,5],[[35,0,[\"attribute\"]]],null]],[13],[2,\"\\n\\n  \"],[10,\"p\"],[14,0,\"product-amendment-card--message\"],[12],[1,[35,0,[\"note\"]]],[13],[2,\"\\n\\n  \"],[10,\"div\"],[14,0,\"product-amendment-card--status\"],[12],[2,\"\\n\"],[2,\"\\n\"],[6,[37,7],[[35,6]],null,[[\"default\"],[{\"statements\":[[2,\"      \"],[10,\"div\"],[14,0,\"product-amendment-card--awaiting-change\"],[12],[2,\"\\n        \"],[10,\"span\"],[12],[2,\"After updating, click below to notify our merchandising team\"],[13],[2,\"\\n      \"],[13],[2,\"\\n      \"],[8,\"button\",[],[[\"@onClick\"],[[30,[36,1],[[32,0],\"resolve\",[35,0]],null]]],[[\"default\"],[{\"statements\":[[2,\"\\n        Mark as resolved\\n      \"]],\"parameters\":[]}]]],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"\\n\"],[6,[37,7],[[35,8]],null,[[\"default\"],[{\"statements\":[[2,\"      \"],[10,\"div\"],[14,0,\"product-amendment-card--change-made\"],[12],[2,\"\\n        \"],[10,\"span\"],[12],[2,\"Our merchandising team has been notified and will review this soon\"],[13],[2,\"\\n      \"],[13],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"  \"],[13],[2,\"\\n\"],[13],[2,\"\\n\\n\"],[18,1,null],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"amendment\",\"action\",\"svg-jar\",\"defaultProductAmendmentFrom\",\"date-format\",\"capitalize\",\"isPending\",\"if\",\"isResolved\"]}",
    "moduleName": "flaneur/pods/components/product-amendment-card/template.hbs"
  });

  _exports.default = _default;
});