define("flaneur/pods/components/pages/packaging/products/component", ["exports", "@glimmer/component", "ember-component-css/pod-names", "ember-concurrency", "flaneur/models/packaging/product"], function (_exports, _component, _podNames, _emberConcurrency, _product) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  let PagesPackagingProducts = (_dec = Ember.inject.service, _dec2 = Ember.inject.service, _dec3 = Ember.inject.service, _dec4 = Ember.inject.service('packagingBasket'), _dec5 = Ember._tracked, _dec6 = Ember._action, _dec7 = Ember._action, (_class = class PagesPackagingProducts extends _component.default {
    constructor(...args) {
      super(...args);

      _initializerDefineProperty(this, "analytics", _descriptor, this);

      _initializerDefineProperty(this, "brand", _descriptor2, this);

      _initializerDefineProperty(this, "router", _descriptor3, this);

      _initializerDefineProperty(this, "basket", _descriptor4, this);

      _initializerDefineProperty(this, "searchQuery", _descriptor5, this);
    }

    get styleNamespace() {
      return _podNames.default['pages/packaging/products'];
    }

    *searchPackage(query) {
      yield (0, _emberConcurrency.timeout)(250);
      this.searchQuery = query.toUpperCase();
      const {
        products
      } = yield _product.default.find({
        variants_sku: this.searchQuery,
        limit: 6
      });
      return products;
    }

    registerToastAPI(api) {
      this.basket.toastAPI = api;
    }

    selectPackage(packagingProduct) {
      if (!packagingProduct) {
        return;
      }

      this.analytics.gtm({
        action: 'packaging_clicks_result',
        label: this.searchQuery
      });
      this.router.transitionTo('brand.boutiques.boutique.packaging.products.product', this.brand, this.brand.currentBoutique._id, packagingProduct.id, {
        queryParams: {
          searchQuery: this.searchQuery
        }
      });
    }

  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "analytics", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "brand", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "router", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "basket", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "searchQuery", [_dec5], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return '';
    }
  }), _applyDecoratedDescriptor(_class.prototype, "searchPackage", [_emberConcurrency.restartableTask], Object.getOwnPropertyDescriptor(_class.prototype, "searchPackage"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "registerToastAPI", [_dec6], Object.getOwnPropertyDescriptor(_class.prototype, "registerToastAPI"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "selectPackage", [_dec7], Object.getOwnPropertyDescriptor(_class.prototype, "selectPackage"), _class.prototype)), _class));
  _exports.default = PagesPackagingProducts;
});