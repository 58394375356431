define("flaneur/pods/components/order-item/component", ["exports", "flaneur/models/zendesk", "flaneur/config/environment", "big.js", "flaneur/enums/shipping-provider", "flaneur/enums/courier-booking-error-reason", "date-fns", "flaneur/enums/order-channel"], function (_exports, _zendesk, _environment, _big, _shippingProvider, _courierBookingErrorReason, _dateFns, _orderChannel) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); enumerableOnly && (symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols); } return keys; }

  function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = null != arguments[i] ? arguments[i] : {}; i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { _defineProperty(target, key, source[key]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } return target; }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  const PRINTABLE_DOCUMENTS = {
    LABEL: 'label',
    CUSTOMS_FORM: 'customsForm',
    RECEIPT: 'receipt'
  };

  var _default = Ember.Component.extend({
    classNames: ['order-item-container'],
    analytics: Ember.inject.service(),
    brand: Ember.inject.service(),
    orderHistory: Ember.inject.service(),
    media: Ember.inject.service(),
    cookies: Ember.inject.service(),
    shipments: null,
    label: null,
    customsForm: null,
    receipt: null,
    enableConfirmWarningModal: false,
    displayConfirmWarningModal: false,
    nextOpenDayDate: null,
    showEligibleModal: false,
    isGiftModalOpen: false,
    isMadeModalOpen: false,
    hasDismissedGiftModal: false,
    cookieName: 'dismissedGiftModal',
    bookingFailed: false,
    bookingFailedDueToDropship: false,
    _loading: false,

    requestedManualLabel() {},

    markAsCollectedAction() {},

    updateReservationBoutique() {},

    bookShipment() {},

    inStock() {},

    refreshAction() {},

    storeTrackingCode() {},

    currentState: Ember.computed.alias('reservationBoutique.0.status'),
    _isOnHoliday: Ember.computed.alias('brand.currentBoutique.isOnHoliday'),
    isPartiallySelfShipping: Ember.computed.alias('boutique.allow_partial_self_shipping'),
    isReturn: Ember.computed.equal('reservationBoutique.0.status', 'return'),
    isActive: Ember.computed.equal('reservationBoutique.0.status', 'active'),
    isPrint: Ember.computed.equal('reservationBoutique.0.status', 'print'),
    isCollection: Ember.computed.equal('reservationBoutique.0.status', 'collection'),
    isCompleted: Ember.computed.equal('reservationBoutique.0.status', 'completed'),
    isClickAndCollect: Ember.computed.equal('reservationBoutique.0.status', 'click-and-collect'),
    isOrderClickAndCollect: Ember.computed.equal('order.mode', 'clickcollect'),
    isUPSAccessPointOrder: Ember.computed.equal('order.mode', 'ups_access_point'),
    hasLabel: Ember.computed.notEmpty('label'),
    reservationsLength: Ember.computed.alias('reservations.length'),
    multipleReservations: Ember.computed.gt('reservationsLength', 1),
    activeLength: Ember.computed('reservations.@each.status', function () {
      return this.reservations.filter(curr => curr.get('status') === 'active').length;
    }),
    successLength: Ember.computed('reservations.@each.status', function () {
      return this.reservations.filter(curr => curr.get('status') === 'success').length;
    }),
    failLength: Ember.computed('reservations.@each.status', function () {
      return this.reservations.filter(curr => curr.get('status') === 'fail' || curr.get('status') === 'fail other').length;
    }),
    isAllOutOfStock: Ember.computed('failLength', 'reservations.length', function () {
      return this.failLength === this.get('reservations.length');
    }),
    isShipmentReservation: Ember.computed('reservationBoutique.0.shipping_provider', function () {
      const shippingProvider = this.get('reservationBoutique.0.shipping_provider');
      return shippingProvider === _shippingProvider.default.DECIDED_AT_COURIER_BOOKING || shippingProvider === _shippingProvider.default.UPS_ACCESS_POINT || shippingProvider === _shippingProvider.default.EASYPOST || shippingProvider === _shippingProvider.default.P2G;
    }),
    noShippingProvider: Ember.computed.equal('reservationBoutique.0.shipping_provider', 'other'),
    otherTrackingInfo: Ember.computed.alias('reservationBoutique.0.other_tracking_info'),
    isMadeOrder: Ember.computed.equal('order.channel', _orderChannel.default.MADE_DOT_COM),

    init() {
      this._super(...arguments);

      const reservationBoutique = this.get('reservationBoutique.0');
      const boutique = this.boutique;

      if (['print', 'collection', 'completed'].includes(reservationBoutique.status)) {
        if (reservationBoutique.shipping_provider === _shippingProvider.default.EASYPOST || reservationBoutique.shipping_provider === _shippingProvider.default.UPS_ACCESS_POINT) {
          const shipment = this.get('shipments.0');

          if (shipment) {
            this.setProperties({
              label: shipment.label_url,
              customsForm: shipment.customs_form_url
            });
          }
        }

        if (reservationBoutique.shipping_provider === _shippingProvider.default.P2G) {
          this.setProperties({
            label: boutique.prefers_thermal_label ? reservationBoutique.p2g_shipping_thermal_label : reservationBoutique.p2g_shipping_label
          });
        }
      }
    },

    shipmentInfo: Ember.computed.reads('shipments.0'),
    otherInfo: Ember.computed('reservationBoutique.0.other_tracking_info', '_hideTrackingInput', function () {
      const info = this.get('reservationBoutique.0');
      if (!info) return {};
      return {
        trackingInfo: info.other_tracking_info,
        hideTrackingInput: this._hideTrackingInput
      };
    }),
    shouldShowGiftModal: Ember.computed('order', 'boutique', function () {
      const {
        order: {
          gift
        },
        boutique: {
          gift_wrap
        }
      } = this;
      return gift && gift_wrap;
    }),
    hasClickedDontShowGiftModal: Ember.computed('cookies', 'cookieName', function () {
      return this.cookies.exists(this.cookieName);
    }),

    createZendeskTicket() {
      const {
        order,
        boutique
      } = this;

      const itemsValue = this._getItemsValue();

      const channelTag = _zendesk.default.getChannelTag(order.channel);

      return _zendesk.default.createEnrichedTicket({
        requester_email: boutique.support_email || boutique.order_email || this.get('session.user.email'),
        requester_name: boutique.name,
        subject: this._createSubject(),
        tags: ['label_request', `${boutique.address_country.iso_code}_boutique`, channelTag],
        auto: true,
        comment: {
          body: `
                    Order number: #${order.ordernumber}
                    On backalley: ${_environment.default.backalleyURL}/orders/${order._id}

                    PICKUP INFO:
                        - Name of boutique: ${boutique.name}
                        - Address line 1: ${boutique.address_line_1}
                        - Address line 2: ${boutique.address_line_2}
                        - Address line 3: ${boutique.address_line_3}
                        - Country: ${boutique.address_country.name}
                        - Zip code: ${boutique.address_postcode}
                        - City: ${boutique.address_city}
                        - Contact name: ${boutique.primary_contact_name}
                        - Phone: ${boutique.support_phone}
                        - Order email: ${boutique.order_email}
                        - Opening hours:
                            ${boutique.oh[0].label}: ${boutique.oh[0].time}
                            ${boutique.oh[1].label}: ${boutique.oh[1].time}
                            ${boutique.oh[2].label}: ${boutique.oh[2].time}
                            ${boutique.oh[3].label}: ${boutique.oh[3].time}
                            ${boutique.oh[4].label}: ${boutique.oh[4].time}
                            ${boutique.oh[5].label}: ${boutique.oh[5].time}
                            ${boutique.oh[6].label}: ${boutique.oh[6].time}

                    DELIVERY INFO:
                        - Company name: ${order.delivery_company || '----'}
                        - Address line 1: ${order.delivery_address_line_1}
                        - Address line 2: ${order.delivery_address_line_2}
                        - Country: ${order.delivery_country}
                        - Zip code: ${order.delivery_postcode}
                        - City: ${order.delivery_city}
                        - Fullname: ${order.delivery_full_name}
                        - Phone: ${order.user_phone}
                        - Email: ${order.email}

                    ITEM INFO:
                        - Value of Items: ${itemsValue} GBP
                        - Items list:
                            ${this._getItemsList()}
                `
        }
      });
    },

    _createZendeskTicketForAccessPoint() {
      const {
        order,
        boutique
      } = this;

      const channelTag = _zendesk.default.getChannelTag(order.channel);

      const fields = _environment.default.environment === 'production' ? {
        ticket_type: 114102074512
      } : {
        ticket_type: 360011604994
      };
      return _zendesk.default.createEnrichedTicket({
        requester_email: 'abyss@trouva.com',
        requester_name: boutique.name,
        subject: `${boutique.name} flagged item too large for Access Point`,
        tags: ['UPS_access_point_address', `${boutique.address_country.iso_code}_boutique`, channelTag],
        custom_fields: [{
          id: fields.ticket_type,
          value: 'ups_access_point'
        }],
        auto: true,
        comment: {
          body: `
                    Order number: #${order.ordernumber}
                    On backalley: ${_environment.default.backalleyURL}/orders/${order._id}

                    ${boutique.name} has flagged that order no ${order.ordernumber} is too large for Access Point Delivery

                    The customer address on file is:

                        - Company name: ${order.delivery_company || '----'}
                        - Address line 1: ${order.delivery_address_line_1}
                        - Address line 2: ${order.delivery_address_line_2}
                        - Country: ${order.delivery_country}
                        - Zip code: ${order.delivery_postcode}
                        - City: ${order.delivery_city}
                        - Fullname: ${order.delivery_full_name}
                        - Phone: ${order.user_phone}
                        - Email: ${order.email}

                    Please check with the customer where they would like this to be delivered and then change
                    the shipping provider in Backalley to be Easypost, or cancel the order if:

                        - the customer prefers
                        - you have not heard back in 24 hours
                `
        }
      });
    },

    _getItemsList() {
      const reservations = this.reservations;
      let items = '';
      reservations.forEach(reservation => {
        items = items + `${reservation.product.name}: #${reservation.quantity}
                            `;
      });
      return items;
    },

    _getItemsValue() {
      const reservations = this.reservations;
      const totalValue = reservations.reduce((total, reservation) => (0, _big.default)(total).plus(reservation.price).times(reservation.quantity), 0);
      return (0, _big.default)((0, _big.default)(totalValue).toNumber()).div(100);
    },

    _createSubject() {
      return `Trouva - #${this.get('order.ordernumber')} - Label Request`;
    },

    _allInStock() {
      const activeReservations = this.reservations.filter(r => r.status === 'active');
      const hasActiveReservations = activeReservations.length > 0;
      const reservationBoutique = this.get('reservationBoutique.0');
      const {
        boutique
      } = reservationBoutique;

      if (hasActiveReservations.length === 0) {
        return Ember.RSVP.resolve();
      }

      return this.inStock(this.get('order._id'), boutique).then(() => {
        this.reservations.setEach('status', 'success');
      });
    },

    /**
     * This is the more up-to-date handler that replaced _arrangeCourier
     * which came before. Rather than just handling Easypost, this can
     * now handle both Easypost and P2G.
     */
    _bookShipment(boutique_id, order) {
      this.set('_loading', true);
      return this.bookShipment(boutique_id, order._id).then(() => {
        return this.setProperties({
          _p2gStep: 'print',
          _shipmentShippingStep: 'print',
          bookingFailed: false,
          bookingFailedDueToDropship: false,
          'reservationBoutique.0.arranged_courier': true
        });
      }).catch(err => {
        this.set('_shipmentShippingStep', 'fail');
        this.set('_p2gStep', 'fail');
        return Ember.RSVP.reject(err);
      }).finally(() => {
        this.set('_loading', false);
      });
    },

    _updateReservationBoutique(type) {
      const reservationBoutique = this.get('reservationBoutique.0');

      if (type === PRINTABLE_DOCUMENTS.LABEL) {
        reservationBoutique.set('printed_shipping_label', true);
      }

      if (type === PRINTABLE_DOCUMENTS.CUSTOMS_FORM) {
        reservationBoutique.set('printed_customs_form', true);
      }

      if (type === PRINTABLE_DOCUMENTS.RECEIPT) {
        reservationBoutique.set('printed_receipt', true);
      }

      return this.updateReservationBoutique(reservationBoutique);
    },

    _confirmAndBook() {
      const order = this.order;
      const reservationBoutique = this.get('reservationBoutique.0');
      const {
        boutique
      } = reservationBoutique;
      this.set('_loading', true);
      return this._allInStock().then(() => {
        this.analytics.gtm({
          action: 'clicked_confirm_and_book_courier',
          label: order._id
        });

        if (this.isShipmentReservation) {
          // p2g or easypost
          return this._bookShipment(boutique, order);
        } else {
          // we should not end up here
          throw new Error('Unable to book courier (#10)');
        }
      }).catch(ex => {
        this.set('bookingFailed', true);

        if (ex.reason === _courierBookingErrorReason.default.DROPSHIP) {
          this.set('bookingFailedDueToDropship', true);
        }

        this.analytics.gtm({
          action: 'booked_courier_failed',
          label: order._id
        }); // re-throwing for global error handler

        throw ex;
      });
    },

    setCookie() {
      const oneWeek = (0, _dateFns.addDays)(new Date(), 7);

      const options = _objectSpread({
        oneWeek
      }, _environment.default.cookies);

      this.cookies.write(this.cookieName, true, options);
    },

    actions: {
      printInNewTab(type) {
        window.open(this.get(type));

        if (type === 'label') {
          this.analytics.gtm({
            action: 'opened_shipping_label',
            label: this.order._id
          });
        }

        if (type === 'customsForm') {
          this.analytics.gtm({
            action: 'opened_customs_invoice',
            label: this.order._id
          });
        }

        return this._updateReservationBoutique(type);
      },

      confirmAndBook() {
        if (this.enableConfirmWarningModal && !this.displayConfirmWarningModal) {
          return this.set('displayConfirmWarningModal', true);
        }

        if (this.shouldShowGiftModal && !this.hasDismissedGiftModal && !this.hasClickedDontShowGiftModal) {
          return this.set('isGiftModalOpen', true);
        }

        const {
          address_country: {
            iso_code: boutiqueCountry
          }
        } = this.boutique;
        const [resBoutique] = this.reservationBoutique;
        const {
          shipping_provider
        } = resBoutique;
        const isNonUkBoutique = boutiqueCountry !== 'GB';
        const isMultiReservation = this.multipleReservations;
        const isShippingWithAP = shipping_provider === 'ups_access_point';
        const isAccessPoint = this.isUPSAccessPointOrder && isShippingWithAP;

        if (isAccessPoint && isNonUkBoutique) {
          Ember.set(this, 'showEligibleModal', true);
          return;
        }

        if (isAccessPoint && isMultiReservation) {
          Ember.set(this, 'showEligibleModal', true);
          return;
        }

        return this._confirmAndBook();
      },

      confirmAndBookFromGiftModal(dontShowAgain) {
        this.setProperties({
          isGiftModalOpen: false,
          hasDismissedGiftModal: true
        });

        if (dontShowAgain) {
          this.setCookie();
        }

        return this._confirmAndBook();
      },

      confirmAndBookFromModal() {
        this.toggleProperty('showEligibleModal');
        return this._confirmAndBook();
      },

      setReservationToFail() {
        const [reservation] = this.reservations;
        return this.setReservationToFail(reservation).then(() => {
          this.reservations.setEach('status', 'fail other');
        });
      },

      confirm() {
        return this._allInStock().catch(() => {
          this.set('bookingFailed', true);
        });
      },

      markAsCollected() {
        this.set('_loading', true);
        this.set('reservationBoutique.0.marked_as_collected', true);
        this.set('reservationBoutique.0.marked_as_collected_at', new Date());
        return this.markAsCollectedAction(this.get('reservationBoutique.0')).then(() => {
          this.set('_loading', false);
        }).catch(() => {
          this.set('reservationBoutique.0.marked_as_collected', false);
          this.set('reservationBoutique.0.marked_as_collected_at', null);
          this.set('errorAfterMarkingAsCollected', true);
        });
      },

      createTicket(order) {
        // For manual shipping
        this.set('_loading', true);
        const ticketSent = this.ticketSent;

        if (ticketSent) {
          this.set('ticketSent', false);
        }

        return this.createZendeskTicket().then(() => {
          this.set('ticketSent', true);
          this.set('_manualShippingStep', 'waiting');
          this.analytics.gtm({
            action: 'contacted_support',
            label: order._id
          });
        }).then(() => {
          const reservationBoutique = this.get('reservationBoutique.0');
          reservationBoutique.requested_manual_label = true;
          return this.requestedManualLabel(reservationBoutique);
        }).catch(() => {
          return this.set('_manualShippingStep', 'fail');
        }).finally(() => {
          return this.set('_loading', false);
        });
      },

      /**
       * Updates shipping provider on reservationBoutique and reservations.
       *
       * @function setShippingProvider
       * @param {string} provider - New provider to set
       */
      setShippingByThemselves(resBoutique) {
        const [reservationBoutique] = resBoutique;
        const provider = _shippingProvider.default.OTHER;
        const reservations = this.reservations.filter(curr => curr.get('status') === 'success');
        const reservationPromises = reservations.map(reservation => {
          Ember.set(reservation, 'shipping_provider', provider);
          return reservation.put();
        });
        this.analytics.gtm({
          action: 'changed_shipping_method',
          label: reservationBoutique.order._id
        });
        Ember.set(reservationBoutique, 'shipping_provider', provider);
        return Ember.RSVP.all([this.updateReservationBoutique(reservationBoutique), ...reservationPromises]);
      },

      openNewReceiptTab() {
        const brandId = this.brand._id;
        const boutiqueId = this.boutique._id;
        const order = this.order;
        window.open(`/${brandId}/boutiques/${boutiqueId}/receipt/${order._id}`);
        this.analytics.gtm({
          action: 'opened_receipt',
          label: order._id
        });
        return this._updateReservationBoutique('receipt');
      },

      setProp(property, value) {
        this.set(property, value);
      },

      /**
       * Copies private tracking code variable to the reservationBoutique and
       * calls the update method of the model.
       *
       * @function storeTrackingCode
       * @param {string} trackingCode - the tracking code to save
       * @returns {reservationBoutique}
       */
      storeTrackingCode(trackingCode) {
        const reservationBoutique = this.get('reservationBoutique.0');
        Ember.set(reservationBoutique, 'other_tracking_info', trackingCode);
        Ember.set(this, '_loading', true);
        return reservationBoutique.put().finally(() => {
          Ember.set(this, '_loading', false);
          this.analytics.gtm({
            action: 'clicked_add_tracking_info',
            label: Ember.get(this, 'reservationBoutique.0.order')
          });
        });
      },

      markAsReturned(reservation) {
        this.analytics.gtm({
          action: 'clicked_mark_as_returned',
          label: Ember.get(this, 'reservationBoutique.0.order')
        });
        reservation.set('returned', true);
        reservation.set('status', 'fail returned');
        return reservation.markAsReturned().then(() => {
          reservation.set('isLoading', false);
        });
      },

      onWarningModalCancel() {
        this.set('displayConfirmWarningModal', false);
      },

      onWarningModalConfirm() {
        this.setProperties({
          enableConfirmWarningModal: false,
          displayConfirmWarningModal: false
        });
        const {
          address_country: {
            iso_code: boutiqueCountry
          }
        } = this.boutique;
        const [resBoutique] = this.reservationBoutique;
        const {
          shipping_provider
        } = resBoutique;
        const isNonUkBoutique = boutiqueCountry !== 'GB';
        const isMultiReservation = this.multipleReservations;
        const isShippingWithAP = shipping_provider === 'ups_access_point';
        const isAccessPoint = this.isUPSAccessPointOrder && isShippingWithAP;

        if (isAccessPoint && isNonUkBoutique) {
          Ember.set(this, 'showEligibleModal', true);
          return;
        }

        if (isAccessPoint && isMultiReservation) {
          Ember.set(this, 'showEligibleModal', true);
          return;
        }

        return this._confirmAndBook();
      },

      closeMobileMenu() {
        this.set('showOrderItemMobileMenu', false);
        this.refreshAction();
      },

      contactSupport() {
        this.analytics.gtm({
          action: 'clicked_mark_as_out_of_stock',
          label: this.order._id
        });
        this.toggleProperty('contactSupport');
      },

      dismissAccessPointModal() {
        this.toggleProperty('showEligibleModal');
      },

      onCloseMadeModal() {
        this.toggleProperty('isMadeModalOpen');
      },

      openContactSupportFromMadeModal() {
        this.set('isMadeModalOpen', false);
        this.set('contactSupport', true);
      },

      createZendeskTicketForAccessPoint() {
        return this._createZendeskTicketForAccessPoint();
      },

      closeGiftModal() {
        this.toggleProperty('isGiftModalOpen');
      }

    }
  });

  _exports.default = _default;
});