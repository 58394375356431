define("flaneur/pods/components/curate-brand-logotype/component", ["exports", "@glimmer/component"], function (_exports, _component) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  class CurateBrandLogotypeComponent extends _component.default {
    get transformation() {
      return ['w_160', 'h_160', 'c_lpad', 'b_auto', 'r_max', 'f_auto'].join(',');
    }

  }

  _exports.default = CurateBrandLogotypeComponent;
});