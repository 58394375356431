define("flaneur/pods/components/pages/packaging/orders/order/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "GxNbZ97h",
    "block": "{\"symbols\":[\"@order\"],\"statements\":[[10,\"div\"],[15,0,[31,[\"page \",[32,0,[\"styleNamespace\"]]]]],[12],[2,\"\\n  \"],[10,\"div\"],[14,0,\"breadcrumbs\"],[12],[2,\"\\n    \"],[8,\"link-to\",[[24,0,\"breadcrumb__item\"]],[[\"@route\"],[\"brand.boutique.boutique.packaging.orders\"]],[[\"default\"],[{\"statements\":[[2,\"\\n      Orders\\n    \"]],\"parameters\":[]}]]],[2,\"\\n    \"],[10,\"span\"],[14,0,\"breadcrumbs__separator\"],[12],[2,\"/\"],[13],[2,\"\\n    \"],[8,\"link-to\",[[24,0,\"breadcrumb__item\"]],[[\"@route\",\"@model\"],[\"brand.boutique.boutique.packaging.orders.order\",[32,1,[\"order_number\"]]]],[[\"default\"],[{\"statements\":[[2,\"\\n      Order #\"],[1,[32,1,[\"orderNumber\"]]],[2,\"\\n    \"]],\"parameters\":[]}]]],[2,\"\\n  \"],[13],[2,\"\\n\\n  \"],[10,\"div\"],[14,0,\"heading\"],[12],[2,\"\\n    \"],[10,\"div\"],[14,0,\"heading__title\"],[12],[2,\"\\n      \"],[10,\"h1\"],[12],[2,\"Order details\"],[13],[2,\"\\n      \"],[10,\"h2\"],[12],[2,\"Order \"],[1,[32,1,[\"orderNumber\"]]],[2,\", placed on \"],[1,[30,[36,0],[[32,1,[\"createdAt\"]],\"PP\"],null]],[13],[2,\"\\n    \"],[13],[2,\"\\n  \"],[13],[2,\"\\n\\n  \"],[8,\"packaging-products-table\",[],[[\"@items\",\"@editable\"],[[32,1,[\"reservations\"]],false]],null],[2,\"\\n\"],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"date-format\"]}",
    "moduleName": "flaneur/pods/components/pages/packaging/orders/order/template.hbs"
  });

  _exports.default = _default;
});