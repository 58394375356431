define("flaneur/pods/components/pages/insights/table-product/component", ["exports", "@glimmer/component", "date-fns", "flaneur/enums/boutique-restock-information-origin"], function (_exports, _component, _dateFns, _boutiqueRestockInformationOrigin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _class, _descriptor, _descriptor2, _descriptor3;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  let PagesInsightsTableProductComponent = (_dec = Ember.inject.service, _dec2 = Ember.inject.service, _dec3 = Ember.inject.service, _dec4 = Ember._action, (_class = class PagesInsightsTableProductComponent extends _component.default {
    constructor(...args) {
      super(...args);

      _initializerDefineProperty(this, "router", _descriptor, this);

      _initializerDefineProperty(this, "analytics", _descriptor2, this);

      _initializerDefineProperty(this, "boutiqueRestockInformation", _descriptor3, this);
    }

    get recommendation() {
      return this.args.recommendation;
    }

    get productId() {
      return this.recommendation.product.id;
    }

    get restockInformation() {
      return this.boutiqueRestockInformation.getEditableRestockInformation(this.recommendation);
    }

    get daysInStock() {
      if (!this.recommendation.metrics.daysLeftInStock) {
        return '-';
      }

      return this.recommendation.metrics.daysLeftInStock <= 30 ? this.recommendation.metrics.daysLeftInStock : '30+';
    }

    get leadDate() {
      return (0, _dateFns.format)((0, _dateFns.parseISO)(this.restockInformation.lead_date), 'd MMMM');
    }

    get isOutOfStock() {
      return this.recommendation.isOutOfStock;
    }

    openRestockModal() {
      this.router.transitionTo({
        queryParams: {
          'restock-product': this.productId
        }
      });
      this.analytics.gtm({
        action: 'restock-modal-open',
        label: this.restockInformation ? _boutiqueRestockInformationOrigin.default.INSIGHTS_TABLE_EDIT : _boutiqueRestockInformationOrigin.default.INSIGHTS_TABLE_ADD
      });
    }

  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "router", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "analytics", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "boutiqueRestockInformation", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "openRestockModal", [_dec4], Object.getOwnPropertyDescriptor(_class.prototype, "openRestockModal"), _class.prototype)), _class));
  _exports.default = PagesInsightsTableProductComponent;
});