define("flaneur/pods/components/curate-tags-filter/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "SP025GHJ",
    "block": "{\"symbols\":[\"collapsible\",\"tag\",\"@selected\",\"@tags\"],\"statements\":[[10,\"div\"],[15,0,[31,[\"curate-tags-filter \",[32,0,[\"styleNamespace\"]]]]],[12],[2,\"\\n  \"],[8,\"collapsible\",[],[[\"@isOpen\",\"@onClick\"],[[32,0,[\"isOpen\"]],[32,0,[\"toggleIsOpen\"]]]],[[\"default\"],[{\"statements\":[[2,\"\\n    \"],[8,[32,1,[\"label\"]],[],[[],[]],[[\"default\"],[{\"statements\":[[2,\"\\n      \"],[10,\"div\"],[14,0,\"curate-tags-filter__title\"],[12],[2,\"Tags\"],[13],[2,\"\\n      \"],[8,\"fa-icon\",[[24,0,\"curate-tags-filter__icon\"]],[[\"@icon\"],[[30,[36,0],[[32,1,[\"isOpen\"]],\"minus\",\"plus\"],null]]],null],[2,\"\\n    \"]],\"parameters\":[]}]]],[2,\"\\n\\n    \"],[8,[32,1,[\"content\"]],[],[[],[]],[[\"default\"],[{\"statements\":[[2,\"\\n\"],[6,[37,2],[[30,[36,1],[[30,[36,1],[[32,4]],null]],null]],null,[[\"default\"],[{\"statements\":[[2,\"        \"],[8,\"radio-button\",[],[[\"@label\",\"@value\",\"@groupValue\",\"@onChange\"],[[32,2,[\"name\"]],[32,2,[\"_id\"]],[32,3],[32,0,[\"onChange\"]]]],null],[2,\"\\n\"]],\"parameters\":[2]}]]],[2,\"    \"]],\"parameters\":[]}]]],[2,\"\\n  \"]],\"parameters\":[1]}]]],[2,\"\\n\"],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"if\",\"-track-array\",\"each\"]}",
    "moduleName": "flaneur/pods/components/curate-tags-filter/template.hbs"
  });

  _exports.default = _default;
});