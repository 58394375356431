define("flaneur/helpers/to-money-currency", ["exports", "big.js", "flaneur/enums/currency-symbol"], function (_exports, _big, _currencySymbol) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.toMoneyCurrency = toMoneyCurrency;
  _exports.default = void 0;

  function toMoneyCurrency([currency, price]) {
    if (Ember.isEmpty(price)) {
      return '';
    }

    let currencySymbol = '£';

    if (!Ember.isEmpty(currency)) {
      currencySymbol = _currencySymbol.default[currency];
    }

    return `${currencySymbol}${(0, _big.default)(price).div(100).toFixed(2).toString()}`;
  }

  var _default = Ember.Helper.helper(toMoneyCurrency);

  _exports.default = _default;
});