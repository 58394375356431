define("flaneur/pods/components/how-to/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  class HowToComponent extends Ember.Component {
    constructor(...args) {
      super(...args);

      _defineProperty(this, "steps", [{
        number: 1,
        descriptionLine1: 'Download our .XLSX template and follow',
        descriptionLine2: 'the instructions to add your product data',
        svgName: 'download-xlsx'
      }, {
        number: 2,
        descriptionLine1: 'Upload a .CSV with all of your',
        descriptionLine2: 'products listed',
        svgName: 'upload-csv'
      }, {
        number: 3,
        descriptionLine1: 'Add categories to complete and create',
        descriptionLine2: 'your listings',
        svgName: 'product-list'
      }]);
    }

  }

  _exports.default = HowToComponent;
});