define("flaneur/pods/components/input-price/component", ["exports", "@glimmer/component", "ember-component-css/pod-names", "flaneur/enums/currency-symbol", "big.js"], function (_exports, _component, _podNames, _currencySymbol, _big) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _class;

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  let InputPriceComponent = (_dec = Ember._action, (_class = class InputPriceComponent extends _component.default {
    constructor(...args) {
      super(...args);

      _defineProperty(this, "styleNamespace", _podNames.default['input-price']);
    }

    get currencySymbol() {
      return _currencySymbol.default[this.args.currency];
    }

    onFocusOut(event) {
      const {
        value
      } = event.target;

      if (Ember.isEmpty(value)) {
        return this.args.onChange(value);
      }

      try {
        const parsedValue = value.replace(/,/g, '.');
        const priceInPence = (0, _big.default)(parsedValue).times(100).round().toNumber();
        return this.args.onChange(priceInPence);
      } catch (error) {
        /* Big() throws an error when the parsedValue is not valid, e.g. "abc" */
        event.target.value = '';
        return this.args.onChange(undefined);
      }
    }

  }, (_applyDecoratedDescriptor(_class.prototype, "onFocusOut", [_dec], Object.getOwnPropertyDescriptor(_class.prototype, "onFocusOut"), _class.prototype)), _class));
  _exports.default = InputPriceComponent;
});