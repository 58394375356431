define("flaneur/pods/brand/boutiques/boutique/packaging/orders/index/controller", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  class PackagingOrdersIndexController extends Ember.Controller {
    constructor(...args) {
      super(...args);

      _defineProperty(this, "queryParams", ['page', 'limit', 'sort']);

      _defineProperty(this, "page", 1);

      _defineProperty(this, "limit", 48);

      _defineProperty(this, "sort", '-created_at');
    }

  }

  _exports.default = PackagingOrdersIndexController;
});