define("flaneur/pods/components/release-notification/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "aVfr7ivq",
    "block": "{\"symbols\":[\"__arg0\"],\"statements\":[[6,[37,1],[[32,0,[\"pubsub\",\"updateNotification\"]]],null,[[\"default\"],[{\"statements\":[[2,\"  \"],[8,\"notification\",[],[[\"@onDismiss\",\"@namedBlocksInfo\"],[[32,0,[\"dismiss\"]],[30,[36,2],null,[[\"title\",\"body\",\"actions\"],[0,0,0]]]]],[[\"default\"],[{\"statements\":[[6,[37,1],[[30,[36,0],[[32,1],\"title\"],null]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"\\n\"],[6,[37,1],[[32,0,[\"pubsub\",\"updateNotification\",\"title\"]]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"        \"],[1,[32,0,[\"pubsub\",\"updateNotification\",\"title\"]]],[2,\"\\n\"]],\"parameters\":[]},{\"statements\":[[2,\"        Retail Panel update available\\n\"]],\"parameters\":[]}]]],[2,\"    \"]],\"parameters\":[]},{\"statements\":[[6,[37,1],[[30,[36,0],[[32,1],\"body\"],null]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"\\n\"],[6,[37,1],[[32,0,[\"pubsub\",\"updateNotification\",\"body\"]]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"        \"],[8,\"marked\",[],[[\"@markdown\"],[[32,0,[\"pubsub\",\"updateNotification\",\"body\"]]]],null],[2,\"\\n\"]],\"parameters\":[]},{\"statements\":[[2,\"        \"],[10,\"p\"],[12],[2,\"Please refresh your browser to access the latest update of the Retail Panel.\"],[13],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"    \"]],\"parameters\":[]},{\"statements\":[[6,[37,1],[[30,[36,0],[[32,1],\"actions\"],null]],null,[[\"default\"],[{\"statements\":[[2,\"\\n      \"],[8,\"button\",[],[[\"@size\",\"@onClick\"],[\"small\",[32,0,[\"refresh\"]]]],[[\"default\"],[{\"statements\":[[2,\"\\n        Refresh\\n      \"]],\"parameters\":[]}]]],[2,\"\\n    \"]],\"parameters\":[]}]]]],\"parameters\":[]}]]]],\"parameters\":[]}]]]],\"parameters\":[1]}]]],[2,\"\\n\"]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"-is-named-block-invocation\",\"if\",\"hash\"]}",
    "moduleName": "flaneur/pods/components/release-notification/template.hbs"
  });

  _exports.default = _default;
});