define("flaneur/pods/components/reservation-item/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "7MCTomfn",
    "block": "{\"symbols\":[\"@reservation\"],\"statements\":[[10,\"div\"],[14,0,\"reservation-item clearfix\"],[12],[2,\"\\n    \"],[10,\"div\"],[14,0,\"reservation-item-image\"],[12],[2,\"\\n    \"],[10,\"img\"],[15,\"src\",[34,0]],[14,\"alt\",\"\"],[12],[13],[2,\"\\n    \"],[13],[2,\"\\n    \"],[10,\"div\"],[14,0,\"reservation-item-info\"],[12],[2,\"\\n    \"],[10,\"div\"],[14,0,\"reservation-price\"],[12],[2,\"\\n        \"],[10,\"p\"],[12],[2,\"Quantity: \"],[1,[32,1,[\"quantity\"]]],[13],[2,\"\\n    \"],[13],[2,\"\\n    \"],[10,\"strong\"],[12],[1,[32,1,[\"product\",\"name\"]]],[13],[2,\"\\n\"],[6,[37,1],[[32,1,[\"selected_size\"]]],null,[[\"default\"],[{\"statements\":[[2,\"        \"],[10,\"p\"],[14,0,\"reservation-variation\"],[12],[2,\"Variation: \"],[1,[32,1,[\"selected_size\"]]],[13],[2,\"\\n\"]],\"parameters\":[]}]]],[6,[37,1],[[32,1,[\"stock_line\",\"sku\"]]],null,[[\"default\"],[{\"statements\":[[2,\"        \"],[10,\"p\"],[14,0,\"reservation-sku\"],[12],[2,\"SKU: \"],[1,[32,1,[\"stock_line\",\"sku\"]]],[13],[2,\"\\n\"]],\"parameters\":[]}]]],[6,[37,1],[[32,1,[\"product\",\"standard_manufacturer\",\"name\"]]],null,[[\"default\"],[{\"statements\":[[2,\"        \"],[10,\"p\"],[14,0,\"reservation-manufacturer\"],[12],[2,\"Brand: \"],[1,[32,1,[\"product\",\"standard_manufacturer\",\"name\"]]],[13],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"    \"],[13],[2,\"\\n\"],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"thumbnail\",\"if\"]}",
    "moduleName": "flaneur/pods/components/reservation-item/template.hbs"
  });

  _exports.default = _default;
});