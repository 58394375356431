define("flaneur/pods/components/form-flows/product/gallery-details/edit-modal/component", ["exports", "flaneur/config/environment", "flaneur/mixins/color", "flaneur/utils/cloudinary-upload"], function (_exports, _environment, _color, _cloudinaryUpload) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const CLOUDINARY_CONFIG = {
    cloudName: _environment.default.CLOUDINARY_NAME,
    uploadPreset: _environment.default.CLOUDINARY
  };
  const ZOOM_SCALE = 1000;

  var _default = Ember.Component.extend(_color.default, {
    media: Ember.inject.service(),
    config: null,
    image: null,
    canvas: null,
    rotationAngle: 0,
    zoom: 1,
    dims: {
      width: 650,
      height: 650
    },
    originalUrl: Ember.computed.or('item.original_url', 'item.url'),

    async didInsertElement() {
      this._super(...arguments);

      const Fabric = await this.getFabric();
      const canvas = new Fabric.Canvas('c1');
      canvas.backgroundColor = '#fff';
      canvas.originX = 'center';
      canvas.originY = 'center';
      canvas.renderAll();
      this.set('canvas', canvas);
      this.set('config', this.item.config);
      this.initImage(canvas);
    },

    getFabric() {
      return emberAutoImportDynamic("fabric").then(module => module.default).then(({
        fabric
      }) => fabric);
    },

    async initImage(canvas) {
      const Fabric = await this.getFabric();
      Fabric.util.loadImage(this.originalUrl, img => {
        const image = new Fabric.Image(img);
        image.set({
          selectable: false,
          centeredScaling: true,
          originX: 'center',
          originY: 'center',
          isPortrait: image.width < image.height
        });
        canvas.add(image);
        canvas.centerObject(image);
        canvas.setActiveObject(image);

        if (Ember.isPresent(this.config)) {
          this.setInitialValuesFromConfig();
          this.set('image', image);
          this.setImageZoom(this.zoom);
          this.image.rotate(this.rotationAngle);
          return canvas.renderAll();
        }

        if (image.isPortrait) {
          image.scaleToHeight(this.dims.height);
        } else {
          image.scaleToWidth(this.dims.width);
        }

        this.set('zoom', image.scaleX * ZOOM_SCALE);
        this.setZoomLimits();
        this.set('image', image);
        return canvas.renderAll();
      }, null, {
        crossOrigin: 'Anonymous'
      });
    },

    setInitialValuesFromConfig() {
      if (this.config.rotation) {
        this.set('rotationAngle', this.config.rotation);
      }

      if (this.config.zoom) {
        this.set('zoom', this.config.zoom);
        this.setZoomLimits(ZOOM_SCALE);
      }
    },

    setZoomLimits(value) {
      const zoom = value || this.zoom;
      this.set('minZoom', zoom - zoom / 1.1);
      this.set('maxZoom', zoom + zoom / 1.1);
    },

    setImageZoom(value) {
      this.image.scaleX = value / ZOOM_SCALE;
      this.image.scaleY = value / ZOOM_SCALE;
    },

    actions: {
      cancel() {
        this.toggleProperty('editImage');
      },

      rotate90() {
        if (this.rotationAngle === 360) {
          this.set('rotationAngle', 0);
        }

        this.set('rotationAngle', this.rotationAngle + 90);
        this.image.rotate(this.rotationAngle);
        this.canvas.centerObject(this.image);
        this.canvas.renderAll();
      },

      fitX() {
        this.image.scaleToWidth(this.dims.width);
        this.set('zoom', this.image.scaleX * ZOOM_SCALE);
        this.canvas.centerObject(this.image);
        this.canvas.renderAll();
      },

      fitY() {
        this.image.scaleToHeight(this.dims.height);
        this.set('zoom', this.image.scaleX * ZOOM_SCALE);
        this.canvas.centerObject(this.image);
        this.canvas.renderAll();
      },

      zoomChanged(value) {
        this.setImageZoom(value);
        this.canvas.centerObject(this.image);
        this.set('zoom', value);
        this.canvas.renderAll();
      },

      save() {
        const cloudinaryUpload = new _cloudinaryUpload.default(CLOUDINARY_CONFIG);
        const canvas = this.canvas;
        const file = canvas.toDataURL({
          format: 'png'
        });
        const formData = {
          folder: this.folder,
          tags: ['browser_uploads']
        };
        return cloudinaryUpload.upload(file, formData).then(res => {
          this.set('item', {
            url: res.secure_url,
            original_url: this.originalUrl,
            width: this.image.width,
            height: this.image.height,
            cloudinary_top_colors: this.getColors(res.colors),
            cloudinary_top_colors_hsv: this.getColorsHSV(res.colors),
            cloudinary_google_colors: this.getColors(res.predominant.google),
            config: {
              zoom: this.zoom,
              rotation: this.rotationAngle
            }
          });
          this.toggleProperty('editImage');
          this.editImageAction(this.item);
        });
      }

    }
  });

  _exports.default = _default;
});