define("flaneur/pods/components/pages/settings/order-fulfilment/component", ["exports", "ember-concurrency", "flaneur/models/country", "flaneur/models/boutique/validations"], function (_exports, _emberConcurrency, _country, _validations) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const fields = ['from_warehouse', 'address_line_1', 'address_line_2', 'address_line_3', 'address_city', 'address_country', 'address_postcode', 'warehouse_oh'];
  const Validations = (0, _validations.getSpecificValidations)(fields, {
    prefix: 'boutique.'
  });

  var _default = Ember.Component.extend(Validations, {
    // Public API
    boutique: null,
    analytics: Ember.inject.service(),
    // Private API
    classNames: ['subpage'],

    didInsertElement() {
      this._super(...arguments);

      Ember.set(this.boutique, 'fields', fields);
    },

    _searchCountry: (0, _emberConcurrency.task)(function* (value) {
      yield (0, _emberConcurrency.timeout)(500);
      return yield _country.default.find({
        weengs_name: {
          $regex: value,
          $options: 'i'
        }
      });
    }).restartable(),
    actions: {
      onChangeToggle(value) {
        Ember.set(this.boutique, 'from_warehouse', value);
      },

      async save() {
        const {
          validations
        } = await this.validations.validate();
        return this.onSave(fields, validations);
      },

      setBoutiqueWarehouseOH(oh) {
        this.analytics.gtm({
          action: 'updated_warehouse_opening_hours'
        });
        this.set('boutique.warehouse_oh', oh);
      }

    }
  });

  _exports.default = _default;
});