define("flaneur/pods/brand/boutiques/boutique/curate/brands/brand/checkout/information/route", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  class BrandBoutiquesBoutiqueCurateBrandsBrandCheckoutInformationRoute extends Ember.Route {}

  _exports.default = BrandBoutiquesBoutiqueCurateBrandsBrandCheckoutInformationRoute;
});