define("flaneur/pods/components/pages/welcome/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    router: Ember.inject.service(),
    // Public API
    boutique: null,
    // Private API
    classNames: ['page'],
    actions: {
      goToSettings() {
        return this.router.transitionTo('brand.boutiques.boutique.settings');
      }

    }
  });

  _exports.default = _default;
});