define("flaneur/pods/google-optimize/service", ["exports", "flaneur/config/environment"], function (_exports, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Service.extend({
    analytics: Ember.inject.service(),
    features: Ember.inject.service(),
    session: Ember.inject.service(),

    init() {
      /*
       * Required: the Google Analytics gtag implementation is not used.
       * More info: https://support.google.com/optimize/answer/9059383#registering-implementation-callbacks
       */
      this._super(...arguments);

      const dataLayer = window.dataLayer || [];

      window.gtag = function () {
        dataLayer.push(arguments);
      };

      window.gtag('js', new Date());
      window.gtag('config', _environment.default.googleAnalyticsId, {
        optimize_id: _environment.default.googleOptimize.id
      });
    },

    activate() {
      /*
       * Using ember-metrics, track the "optimize.activate" event
       * to activate Google Optimize.
       */
      this.analytics.trackEvent('GoogleTagManager', {
        event: 'optimize.activate'
      });
      /*
       * Using the previously defined `gtag`, set the
       * Google Optimize's callback function
       */

      window.gtag('event', 'optimize.callback', {
        callback: Ember.run.bind(this, this.implementExperiments)
      });
    },

    identify(boutique) {
      const dataLayer = window.dataLayer || [];
      const addressCountry = boutique.registered_address_country || {
        iso_code: null
      };
      const info = {
        boutique_name: boutique.name,
        boutique_country_iso_code: addressCountry.iso_code,
        user_role: this.session.user.role
      };
      dataLayer.push(info);
      return info;
    },

    implementExperiments(variant, experimentId) {
      const experiment = this._getExperimentById(experimentId);
      /*
       * If an experiment with the given ID is **not** found,
       * gracefully return and do nothing :)
       */


      if (Ember.isEmpty(experiment)) {
        return;
      }
      /*
       * If an experiment with the given ID is found,
       * and the variant's value (i.e. `variant`) is
       * the variation, enable the corresponding
       * feature flag
       */


      if (Ember.isEqual(variant, experiment.variant)) {
        /*
         * Although we use `ember-feature-flags`, you can
         * put any code inside this `if` statement
         */
        this.features.enable(experiment.featureFlag);
      }
    },

    _getExperimentById(id) {
      return _environment.default.googleOptimize.experiments.findBy('id', id);
    }

  });

  _exports.default = _default;
});