define("flaneur/pods/components/pages/products/bulk/upload/amend-step/error-row/message/component", ["exports", "flaneur/enums/bulk-upload-error-status"], function (_exports, _bulkUploadErrorStatus) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _class;

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  let ErrorRowMessage = (_dec = Ember.computed('error.status'), (_class = class ErrorRowMessage extends Ember.Component {
    get showSuccess() {
      return [_bulkUploadErrorStatus.default.UPDATED, _bulkUploadErrorStatus.default.REMOVED].includes(this.error.status);
    }

  }, (_applyDecoratedDescriptor(_class.prototype, "showSuccess", [_dec], Object.getOwnPropertyDescriptor(_class.prototype, "showSuccess"), _class.prototype)), _class));
  _exports.default = ErrorRowMessage;
});