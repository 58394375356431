define("flaneur/pods/components/star-boutique-label/component", ["exports", "flaneur/enums/boutique-tier"], function (_exports, _boutiqueTier) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  let StarBoutiqueLabelComponent = (_dec = Ember.inject.service, _dec2 = Ember.computed.readOnly('brand.currentBoutique'), _dec3 = Ember.computed.equal('boutique.tier', _boutiqueTier.default.STAR_GOLD), _dec4 = Ember.computed.equal('boutique.tier', _boutiqueTier.default.STAR_SILVER), _dec5 = Ember.computed.equal('boutique.tier', _boutiqueTier.default.STAR_BRONZE), _dec6 = Ember.computed('boutique.tier'), (_class = class StarBoutiqueLabelComponent extends Ember.Component {
    constructor(...args) {
      super(...args);

      _initializerDefineProperty(this, "brand", _descriptor, this);

      _defineProperty(this, "tagName", 'span');

      _defineProperty(this, "classNames", ['star-boutique-label']);

      _defineProperty(this, "classNameBindings", ['isGold:star-boutique-label--gold', 'isSilver:star-boutique-label--silver', 'isBronze:star-boutique-label--bronze']);

      _initializerDefineProperty(this, "boutique", _descriptor2, this);

      _initializerDefineProperty(this, "isGold", _descriptor3, this);

      _initializerDefineProperty(this, "isSilver", _descriptor4, this);

      _initializerDefineProperty(this, "isBronze", _descriptor5, this);
    }

    get isVisible() {
      return [_boutiqueTier.default.STAR_GOLD, _boutiqueTier.default.STAR_SILVER, _boutiqueTier.default.STAR_BRONZE].includes(this.boutique.tier);
    }

  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "brand", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "boutique", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "isGold", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "isSilver", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "isBronze", [_dec5], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "isVisible", [_dec6], Object.getOwnPropertyDescriptor(_class.prototype, "isVisible"), _class.prototype)), _class));
  _exports.default = StarBoutiqueLabelComponent;
});