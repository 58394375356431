define("flaneur/pods/join/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "Uq3pyHXY",
    "block": "{\"symbols\":[],\"statements\":[[8,\"pages/join\",[],[[\"@user\",\"@pb\"],[[34,0,[\"user\"]],[34,0,[\"pb\"]]]],null],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"model\"]}",
    "moduleName": "flaneur/pods/join/template.hbs"
  });

  _exports.default = _default;
});