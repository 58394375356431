define("flaneur/pods/brand/boutiques/boutique/orders/utils", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.getReservationBoutiquesLimit = getReservationBoutiquesLimit;
  _exports.getReservationBoutiqueSort = getReservationBoutiqueSort;
  _exports.getStage = getStage;
  _exports.parseSingleOrMultipleOrderNumbers = parseSingleOrMultipleOrderNumbers;
  const STAGE_ACTIVE = 'active';
  const STAGE_COLLECTION = 'collection';
  const STAGE_CLICK_AND_COLLECT = 'click-and-collect';
  const STAGE_PRINT = 'print';

  function getReservationBoutiquesLimit(orderNumber, status, limit) {
    if (Ember.isPresent(orderNumber)) {
      return 150;
    }

    if (status === STAGE_ACTIVE || status === STAGE_PRINT || status === STAGE_COLLECTION) {
      return 10;
    }

    return limit;
  }

  function getReservationBoutiqueSort(status) {
    if (status === STAGE_COLLECTION || status === STAGE_PRINT || status === STAGE_CLICK_AND_COLLECT) {
      return 'estimated_collection_date';
    }

    if (status === STAGE_ACTIVE) {
      return 'boutique_processing_cut_off_time';
    }

    return '-created_at';
  }

  function getStage(stage, orderNumber) {
    return orderNumber ? undefined : stage;
  }

  function parseSingleOrMultipleOrderNumbers(orderNumber) {
    return orderNumber !== null && orderNumber !== void 0 && orderNumber.includes(',') ? orderNumber.split(',') : orderNumber;
  }
});