define("flaneur/models/reservation", ["exports", "flaneur/mixins/request", "fetch", "flaneur/config/environment"], function (_exports, _request, _fetch, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const Reservation = Ember.Object.extend(_request.default, {
    // Instance methods

    /**
     * Save reservation
     * @return {Object} Promise
     */
    save() {
      var reservation = this;
      return this.request({
        method: 'POST',
        url: this.apiURL(`orders/${reservation.order}/reservations`),
        data: JSON.stringify(reservation.sanitize())
      });
    },

    /**
     * Delete a reservation
     * @return {Object} Promise
     */
    remove() {
      var reservation = this;
      return this.request({
        method: 'DELETE',
        url: this.apiURL(`orders/${reservation.order}/reservations/${reservation._id}`)
      });
    },

    /**
     * Update a reservation
     * @return {Object} Promise
     */
    put() {
      return this.request({
        method: 'PUT',
        url: this.apiURL(`orders/${this.order._id}/reservations/${this._id}`),
        data: JSON.stringify({
          _id: this._id,
          quantity: this.quantity,
          status: this.status,
          shipped: this.shipped,
          sizes: this.sizes,
          boutiques: this.boutiques,
          shipping_provider: this.shipping_provider,
          returned: this.returned,
          not_access_point_eligible: this.not_access_point_eligible
        })
      }).catch(err => {
        throw err.responseJSON ? err.responseJSON.errors[0].detail : err.message;
      });
    },

    /**
     * Mark a reservation as returned
     * @return {Object} Promise
     */
    markAsReturned() {
      return this.request({
        method: 'POST',
        url: this.apiURL(`orders/${this.order._id}/reservations/${this._id}/mark-as-returned`)
      }).catch(err => {
        throw err.responseJSON ? err.responseJSON.errors[0].detail : err.message;
      });
    },

    async markAsOutOfStock({
      alternatives = [],
      message = ''
    } = {}) {
      const uri = `${_environment.default.apiURL}/api/v2/orders/${this.order._id}/reservations/${this._id}/mark-as-out-of-stock`;
      const res = await (0, _fetch.default)(uri, {
        method: 'POST',
        credentials: 'include',
        cache: false,
        headers: {
          'Content-Type': 'application/json; charset=UTF-8'
        },
        body: JSON.stringify({
          alternatives,
          message
        })
      });

      if (!res.ok) {
        throw new Error('error marking reservation as out of stock');
      }

      const {
        reservations: [reservation]
      } = await res.json();
      Ember.set(this, 'status', reservation.status);
      return reservation;
    }

  });
  Reservation.reopenClass(_request.default, {
    // Class methods
    _create(reservation) {
      // reservation.container = this.container;
      return this.create(reservation);
    },

    setContainer(container) {
      this.container = container;
    },

    find(data) {
      return this.request({
        method: 'GET',
        url: this.apiURL(`orders/${data.order}/reservations`),
        data: data
      }).then(res => {
        return res.reservations.map(reservation => this._create(reservation));
      });
    },

    findReservationsByBoutique(data) {
      return this.request({
        method: 'GET',
        url: this.apiURL('reservations'),
        data: data
      }).then(res => {
        return res.reservations.map(reservation => this._create(reservation));
      });
    },

    confirmReservations(data) {
      return this.request({
        method: 'PUT',
        url: this.apiURL(`orders/${data.order}/reservations/confirmReservations`),
        data: JSON.stringify(data)
      }).then(res => {
        return res.orders.map(reservation => this._create(reservation));
      }).catch(({
        responseJSON
      }) => {
        if (responseJSON) {
          throw new Error(responseJSON.errors ? responseJSON.errors[0].detail : '');
        } else {
          throw new Error('');
        }
      });
    }

  });
  var _default = Reservation;
  _exports.default = _default;
});