define("flaneur/pods/upgrade-account/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "lCPHnxAy",
    "block": "{\"symbols\":[],\"statements\":[[1,[30,[36,1],null,[[\"notify\"],[[35,0]]]]],[2,\"\\n\\n\"],[10,\"div\"],[14,0,\"log\"],[12],[2,\"\\n  \"],[10,\"div\"],[14,0,\"log-container\"],[12],[2,\"\\n    \"],[10,\"form\"],[14,0,\"log-form\"],[12],[2,\"\\n      \"],[10,\"h1\"],[14,0,\"title\"],[12],[2,\"Upgrade Account\"],[13],[2,\"\\n      \"],[10,\"p\"],[12],[2,\"To setup your new retailer account please choose a new password. This will be your new password on trouva.com.\"],[13],[2,\"\\n      \"],[10,\"br\"],[12],[13],[2,\"\\n      \"],[1,[30,[36,3],null,[[\"class\",\"name\",\"type\",\"placeholder\",\"value\"],[\"form-control\",\"form-password\",\"password\",\"New password\",[35,2,[\"password\"]]]]]],[2,\"\\n      \"],[1,[30,[36,3],null,[[\"class\",\"name\",\"type\",\"placeholder\",\"value\"],[\"form-control\",\"form-password\",\"password\",\"Repeat password confirmation\",[35,2,[\"passwordConfirmation\"]]]]]],[2,\"\\n\\n      \"],[8,\"button\",[[24,4,\"submit\"]],[[\"@onClick\"],[[30,[36,4],[\"upgrade\",[35,2]],null]]],[[\"default\"],[{\"statements\":[[2,\"\\n        Upgrade my account\\n      \"]],\"parameters\":[]}]]],[2,\"\\n    \"],[13],[2,\"\\n  \"],[13],[2,\"\\n\"],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"notify\",\"nav-bar-alert\",\"model\",\"input\",\"route-action\"]}",
    "moduleName": "flaneur/pods/upgrade-account/template.hbs"
  });

  _exports.default = _default;
});