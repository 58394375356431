define("flaneur/pods/components/boutique-restock-information-modal/component", ["exports", "@glimmer/component", "@datadog/browser-rum", "ember-component-css/pod-names", "ember-concurrency", "date-fns", "flaneur/models/product", "flaneur/models/boutique-restock-information", "flaneur/models/boutique-restock-recommendation", "flaneur/enums/boutique-restock-information-status", "flaneur/enums/boutique-restock-information-reason", "flaneur/enums/boutique-restock-recommendation-type"], function (_exports, _component, _browserRum, _podNames, _emberConcurrency, _dateFns, _product, _boutiqueRestockInformation, _boutiqueRestockRecommendation, _boutiqueRestockInformationStatus, _boutiqueRestockInformationReason, _boutiqueRestockRecommendationType) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _dec8, _dec9, _dec10, _dec11, _dec12, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _descriptor6, _descriptor7, _descriptor8, _descriptor9;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  let BoutiqueRestockInformationModalComponent = (_dec = Ember.inject.service, _dec2 = Ember.inject.service, _dec3 = Ember.inject.service, _dec4 = Ember.inject.service, _dec5 = Ember.inject.service('boutiqueRestockInformation'), _dec6 = Ember._tracked, _dec7 = Ember._tracked, _dec8 = Ember._tracked, _dec9 = Ember._tracked, _dec10 = Ember._action, _dec11 = Ember._action, _dec12 = Ember._action, (_class = class BoutiqueRestockInformationModalComponent extends _component.default {
    constructor() {
      super(...arguments);

      _initializerDefineProperty(this, "analytics", _descriptor, this);

      _initializerDefineProperty(this, "brand", _descriptor2, this);

      _initializerDefineProperty(this, "notify", _descriptor3, this);

      _initializerDefineProperty(this, "router", _descriptor4, this);

      _initializerDefineProperty(this, "boutiqueRestockInformationService", _descriptor5, this);

      _initializerDefineProperty(this, "recommendation", _descriptor6, this);

      _initializerDefineProperty(this, "boutiqueRestockInformation", _descriptor7, this);

      _initializerDefineProperty(this, "isEdit", _descriptor8, this);

      _initializerDefineProperty(this, "hasFinishedInitialization", _descriptor9, this);

      _defineProperty(this, "restockReason", _boutiqueRestockInformationReason.default);

      _defineProperty(this, "restockStatus", _boutiqueRestockInformationStatus.default);

      if (!this.args.productId) {
        return this.notifyInexistentProduct();
      }

      if (!this.args.origin) {
        this.analytics.gtm({
          action: 'restock-modal-open',
          label: 'direct'
        });
      }

      this.fetchBoutiqueRestockRecommendation();
    }

    get isCancel() {
      return Ember.isPresent(this.args.action) && this.args.action === 'cancel';
    }

    get isCreateRestocking() {
      return !this.isEdit && !this.isCancel;
    }

    get isCreateNotAvailable() {
      return !this.isEdit && this.isCancel;
    }

    get isUpdateToRestocking() {
      return this.isEdit && !this.isCancel;
    }

    get isUpdateToNotAvailable() {
      return this.isEdit && this.isCancel;
    }

    get isSubmitDisabled() {
      if (this.isCreateNotAvailable || this.isUpdateToNotAvailable) {
        return Ember.isEmpty(this.boutiqueRestockInformation.reason.type);
      }

      return false;
    }

    get isOtherReason() {
      return this.boutiqueRestockInformation.reason.type === _boutiqueRestockInformationReason.default.OTHER;
    }

    get heading() {
      if (this.isCreateRestocking) {
        return {
          title: 'Add incoming stock',
          subtitle: 'Tell us about your incoming stock to help us generate you more sales.'
        };
      }

      if (this.isCreateNotAvailable) {
        return {
          title: 'Hide recommendation'
        };
      }

      if (this.isUpdateToNotAvailable) {
        return {
          title: 'Cancel incoming stock'
        };
      }

      return {
        title: 'Manage incoming stock',
        subtitle: 'Tell us about your incoming stock to help us generate you more sales.'
      };
    }

    get styleNamespace() {
      return _podNames.default['boutique-restock-information-modal'];
    }

    get errors() {
      return this.boutiqueRestockInformation.validations;
    }

    getCurrentQuantity(product) {
      let currentQuantity = 0;

      for (let i = 0; i < product.stock_lines.length; i++) {
        const {
          quantity
        } = product.stock_lines[i];

        if (quantity === -1) {
          currentQuantity = -1;
          break;
        }

        currentQuantity += quantity;
      }

      return currentQuantity;
    }

    async getRecommendationFromProduct() {
      var _product$local_sales_, _product$local_sales_2, _product$standard_man;

      const product = await this.fetchProduct();

      if (!product) {
        return null;
      }

      const {
        boutiqueRestockInformation
      } = await _boutiqueRestockInformation.default.find({
        status: _boutiqueRestockInformationStatus.default.RESTOCKING,
        'product[$in]': [product._id]
      });
      return new _boutiqueRestockRecommendation.default({
        product: {
          id: product._id,
          name: product.name,
          localPrice: product.local_price,
          thumbnailUrl: product.thumbnail.url,
          localSalesPrice: {
            amount: product.sales ? (_product$local_sales_ = product.local_sales_price) === null || _product$local_sales_ === void 0 ? void 0 : _product$local_sales_.amount : null,
            currenty: (_product$local_sales_2 = product.local_sales_price) === null || _product$local_sales_2 === void 0 ? void 0 : _product$local_sales_2.currency
          }
        },
        standardManufacturer: {
          name: (_product$standard_man = product.standard_manufacturer) === null || _product$standard_man === void 0 ? void 0 : _product$standard_man.name
        },
        currentQuantity: this.getCurrentQuantity(product),
        type: _boutiqueRestockRecommendationType.default.GENERAL,
        restockInformation: boutiqueRestockInformation
      });
    }

    async fetchProduct() {
      const {
        products: [product]
      } = await _product.default.find({
        _id: this.args.productId,
        limit: 1,
        populate: 'standard_manufacturer',
        fields: ['brand', 'local_price', 'local_sales_price', 'name', 'price', 'sales_price', 'sales', 'standard_manufacturer', 'stock_lines', 'thumbnail'].join(',')
      });
      return product;
    }

    async fetchBoutiqueRestockRecommendation() {
      const [recommendation] = await _boutiqueRestockRecommendation.default.find({
        boutiqueId: this.boutiqueId,
        productId: this.args.productId,
        limit: 1,
        attachRestockInformation: true
      });
      this.recommendation = recommendation !== null && recommendation !== void 0 ? recommendation : await this.getRecommendationFromProduct();

      if (!this.recommendation) {
        return this.notifyInexistentProduct();
      }

      const editableBoutiqueRestockInformation = this.boutiqueRestockInformationService.getEditableRestockInformation(this.recommendation);

      if (editableBoutiqueRestockInformation) {
        this.boutiqueRestockInformation = editableBoutiqueRestockInformation;
        this.isEdit = true;
      }

      this.hasFinishedInitialization = true;
    }

    trackAction(action) {
      const label = this.boutiqueRestockInformation.isRestocking ? 'restock' : 'no-restock';
      this.analytics.gtm({
        action,
        label
      });
    }

    notifyInexistentProduct() {
      _browserRum.datadogRum.addError(new Error(`failed to find product "${this.args.productId}" in boutique-restock-information-modal`));

      this.notify.show('error', 'Oops! The product you want to restock does not exist.');
      this.args.closeModal();
    }

    setReason(reasonType) {
      this.boutiqueRestockInformation.reason = {
        type: reasonType,
        notes: ''
      };
    }

    setDate(date) {
      this.boutiqueRestockInformation.lead_date = (0, _dateFns.formatISO)(date);
    }

    transitionToInventory() {
      this.router.transitionTo('brand.boutiques.boutique.inventory');
      this.args.closeModal();
    }

    *createRestockInformation() {
      this.boutiqueRestockInformation.boutique = this.brand.currentBoutique._id;
      this.boutiqueRestockInformation.product = this.recommendation.product.id;
      this.boutiqueRestockInformation.status = this.isCreateRestocking ? _boutiqueRestockInformationStatus.default.RESTOCKING : _boutiqueRestockInformationStatus.default.NOT_AVAILABLE;

      if (this.isCreateRestocking) {
        this.boutiqueRestockInformation.reason = {};
      }

      this.boutiqueRestockInformation.disableValidations = false;

      if (!this.boutiqueRestockInformation.isValid) {
        return;
      }

      yield (0, _emberConcurrency.timeout)(400);

      try {
        const boutiqueRestockInformation = yield this.boutiqueRestockInformation.create();
        this.boutiqueRestockInformationService.addCreated(boutiqueRestockInformation);
        this.trackAction('submit-restock-action');
        this.notify.show('success', this.isCreateRestocking ? '🎉 Incoming stock added' : 'Recommendation hidden');
        this.args.closeModal();
      } catch (error) {
        _browserRum.datadogRum.addError(error);

        this.notify.show('error', 'Oops! An error ocurred while submitting your restock. Please try again.');
      }
    }

    *updateRestockInformation() {
      if (this.isUpdateToRestocking) {
        this.boutiqueRestockInformation.status = _boutiqueRestockInformationStatus.default.RESTOCKING;
        this.boutiqueRestockInformation.reason = {};
      }

      if (this.isUpdateToNotAvailable) {
        this.boutiqueRestockInformation.status = _boutiqueRestockInformationStatus.default.NOT_AVAILABLE;
        this.boutiqueRestockInformation.quantity = null;
        this.boutiqueRestockInformation.lead_date = null;
      }

      this.boutiqueRestockInformation.disableValidations = false;

      if (!this.boutiqueRestockInformation.isValid) {
        return;
      }

      yield (0, _emberConcurrency.timeout)(400);

      try {
        yield this.boutiqueRestockInformation.update();
        this.trackAction('edit-restock-action');
        this.notify.show('success', this.isUpdateToRestocking ? 'Incoming stock updated' : 'Incoming stock cancelled');
        this.args.closeModal();
      } catch (error) {
        _browserRum.datadogRum.addError(error);

        this.notify.show('error', 'Oops! An error ocurred while editing your restock. Please try again.');
      }
    }

  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "analytics", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "brand", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "notify", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "router", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "boutiqueRestockInformationService", [_dec5], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor6 = _applyDecoratedDescriptor(_class.prototype, "recommendation", [_dec6], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor7 = _applyDecoratedDescriptor(_class.prototype, "boutiqueRestockInformation", [_dec7], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return new _boutiqueRestockInformation.default();
    }
  }), _descriptor8 = _applyDecoratedDescriptor(_class.prototype, "isEdit", [_dec8], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return false;
    }
  }), _descriptor9 = _applyDecoratedDescriptor(_class.prototype, "hasFinishedInitialization", [_dec9], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return false;
    }
  }), _applyDecoratedDescriptor(_class.prototype, "setReason", [_dec10], Object.getOwnPropertyDescriptor(_class.prototype, "setReason"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "setDate", [_dec11], Object.getOwnPropertyDescriptor(_class.prototype, "setDate"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "transitionToInventory", [_dec12], Object.getOwnPropertyDescriptor(_class.prototype, "transitionToInventory"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "createRestockInformation", [_emberConcurrency.restartableTask], Object.getOwnPropertyDescriptor(_class.prototype, "createRestockInformation"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "updateRestockInformation", [_emberConcurrency.restartableTask], Object.getOwnPropertyDescriptor(_class.prototype, "updateRestockInformation"), _class.prototype)), _class));
  _exports.default = BoutiqueRestockInformationModalComponent;
});