define("flaneur/pods/components/orders-header/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    analytics: Ember.inject.service(),
    classNames: ['orders-header'],
    boutique: null,
    ordernumber: null,
    isLoading: false,
    newOrdersCount: null,
    awaitingCollectionCount: null,
    clickAndCollectCount: null,

    searchAction() {},

    resetSearchAction() {},

    refreshAction() {},

    addLoadingAction() {},

    actions: {
      search() {
        this.analytics.gtm({
          action: 'applied_search_in_orders',
          label: this.ordernumber
        });
        this.searchAction(this.ordernumber);
      },

      resetSearch() {
        this.resetSearchAction();
      }

    }
  });

  _exports.default = _default;
});