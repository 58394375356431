define("flaneur/pods/components/tag/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "U8we5tK1",
    "block": "{\"symbols\":[\"&default\",\"@theme\",\"&attrs\",\"@namedBlocksInfo\"],\"statements\":[[11,\"span\"],[16,0,[31,[\"tag \",[30,[36,2],[[32,2],[30,[36,1],[\"tag--\",[32,2]],null]],null],\" \",[32,0,[\"styleNamespace\"]]]]],[17,3],[12],[2,\"\\n\"],[6,[37,2],[[30,[36,3],[[32,4],\"icon\",false],null]],null,[[\"default\"],[{\"statements\":[[2,\"    \"],[10,\"span\"],[14,0,\"tag__icon\"],[12],[2,\"\\n      \"],[18,1,[[30,[36,0],[\"icon\"],null]]],[2,\"\\n    \"],[13],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"\\n\"],[6,[37,2],[[30,[36,4],[[30,[36,3],[[32,4],\"label\",false],null],[30,[36,3],[[32,4],\"icon\",false],null]],null]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"    \"],[18,1,[[30,[36,0],[\"label\"],null]]],[2,\"\\n\"]],\"parameters\":[]},{\"statements\":[[2,\"    \"],[18,1,null],[2,\"\\n\"]],\"parameters\":[]}]]],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"-named-block-invocation\",\"concat\",\"if\",\"-has-block\",\"or\"]}",
    "moduleName": "flaneur/pods/components/tag/template.hbs"
  });

  _exports.default = _default;
});