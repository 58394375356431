define("flaneur/pods/components/rp-modals/shopify-sync-started/component", ["exports", "flaneur/util/default-to"], function (_exports, _defaultTo) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    classNames: ['rp-modals__shopify-sync-started'],
    active: (0, _defaultTo.default)(false)
  });

  _exports.default = _default;
});