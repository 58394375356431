define("flaneur/pods/components/reservation-product/other/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "yqIpPJhc",
    "block": "{\"symbols\":[],\"statements\":[[10,\"p\"],[14,0,\"reservation-status-message\"],[12],[2,\"\\n\"],[6,[37,2],[[35,6]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"        \"],[1,[30,[36,0],null,[[\"label\",\"isSuccess\"],[\"paid\",true]]]],[2,\"\\n\"]],\"parameters\":[]},{\"statements\":[[6,[37,2],[[35,5]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"        \"],[1,[30,[36,0],null,[[\"label\",\"isError\"],[\"cancelled\",true]]]],[2,\"\\n\"]],\"parameters\":[]},{\"statements\":[[6,[37,2],[[35,4]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"        \"],[1,[30,[36,0],null,[[\"label\",\"isSuccess\"],[\"Marked as in stock\",true]]]],[2,\"\\n\"]],\"parameters\":[]},{\"statements\":[[6,[37,2],[[35,3]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"        \"],[1,[30,[36,0],null,[[\"label\",\"isError\"],[\"out of stock\",true]]]],[2,\"\\n\"]],\"parameters\":[]},{\"statements\":[[6,[37,2],[[35,1]],null,[[\"default\"],[{\"statements\":[[2,\"        \"],[1,[30,[36,0],null,[[\"label\",\"isError\"],[\"cancelled\",true]]]],[2,\"\\n    \"]],\"parameters\":[]}]]]],\"parameters\":[]}]]]],\"parameters\":[]}]]]],\"parameters\":[]}]]]],\"parameters\":[]}]]],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"reservation-status\",\"isReasonFailOther\",\"if\",\"isReasonFail\",\"isReasonSuccess\",\"isReasonFailCanceled\",\"sentInStatement\"]}",
    "moduleName": "flaneur/pods/components/reservation-product/other/template.hbs"
  });

  _exports.default = _default;
});