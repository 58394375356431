define("flaneur/validators/vat-number", ["exports", "ember-cp-validations/validators/base"], function (_exports, _base) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  const VatNumber = _base.default.extend({
    validate(value, options) {
      if (options.allowEmpty && Ember.isEmpty(value)) {
        return true;
      }

      return emberAutoImportDynamic("jsvat").then(jsvat => {
        const countries = Ember.isEmpty(options.countryCode) ? jsvat.countries : jsvat.countries.filter(country => country.codes.includes(options.countryCode));
        const {
          isValid
        } = jsvat.checkVAT(value, countries);
        return isValid || options.message || 'Please insert a valid VAT number';
      });
    }

  });

  var _default = VatNumber;
  _exports.default = _default;
});