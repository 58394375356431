define("flaneur/pods/components/new-label/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "Q+0OZy5V",
    "block": "{\"symbols\":[\"&default\",\"@namedBlocksInfo\"],\"statements\":[[8,\"tag\",[[16,0,[31,[\"new-label \",[32,0,[\"styleNamespace\"]]]]]],[[\"@theme\"],[\"positive\"]],[[\"default\"],[{\"statements\":[[2,\"\\n  \"],[8,\"fa-icon\",[],[[\"@icon\"],[\"sparkles\"]],null],[2,\"\\n\"],[6,[37,1],[[30,[36,0],[[32,2],\"default\",[27,[32,1]]],null]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"    \"],[18,1,null],[2,\"\\n\"]],\"parameters\":[]},{\"statements\":[[2,\"    New\\n\"]],\"parameters\":[]}]]]],\"parameters\":[]}]]],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"-has-block\",\"if\"]}",
    "moduleName": "flaneur/pods/components/new-label/template.hbs"
  });

  _exports.default = _default;
});