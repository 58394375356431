define("flaneur/pods/components/form-flows/input/input/component", ["exports", "flaneur/util/default-to"], function (_exports, _defaultTo) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  const NOOP = function () {};

  var _default = Ember.Component.extend({
    tagName: '',
    inputId: Ember.computed('elementId', function () {
      // If elementId was not provided, create a new one
      const elementId = this.elementId;
      return `${elementId}-input`;
    }),
    value: null,
    inputClass: null,
    maxlength: null,
    type: null,
    disabled: null,
    min: undefined,
    max: undefined,
    oninput: (0, _defaultTo.default)(NOOP)
  });

  _exports.default = _default;
});