define("flaneur/pods/components/pages/settings/accounting-details/component", ["exports", "flaneur/models/boutique/validations"], function (_exports, _validations) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const fields = ['bank_account_number', 'bank_sort_code', 'bank_account_iban', 'bank_account_bic', 'vat_number', 'is_vat_registered'];
  const Validations = (0, _validations.getSpecificValidations)(fields, {
    prefix: 'boutique.'
  });

  var _default = Ember.Component.extend(Validations, {
    // Public API
    boutique: null,
    analytics: Ember.inject.service(),

    onSave() {},

    // Private API
    classNames: ['subpage'],
    previousVatNumber: null,
    vatNumber: Ember.computed.alias('boutique.vat_number'),
    isVatRegistered: Ember.computed.alias('boutique.isVatRegistered'),
    isGBR: Ember.computed.alias('boutique.isGBR'),
    registeredAddressIsoCodeAlpha3: Ember.computed.alias('boutique.registeredAddressIsoCodeAlpha3'),
    ossNumber: Ember.computed.alias('boutique.eu_vat.registration_number'),

    didInsertElement() {
      this._super(...arguments);

      Ember.set(this.boutique, 'fields', fields);
      Ember.set(this, 'previousVatNumber', this.vatNumber);
    },

    actions: {
      onChangeVatRegistered(value) {
        this.analytics.gtm({
          action: this.isVatRegistered ? 'selected_VAT_registered_checkbox' : 'unselected_VAT_registered_checkbox'
        });
        this.setProperties({
          vatNumber: value ? null : this.previousVatNumber,
          isVatRegistered: !value
        });
      },

      async save() {
        const {
          validations
        } = await this.validations.validate();
        return this.onSave(fields, validations);
      }

    }
  });

  _exports.default = _default;
});