define("flaneur/models/zendesk", ["exports", "flaneur/mixins/request"], function (_exports, _request) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); enumerableOnly && (symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols); } return keys; }

  function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = null != arguments[i] ? arguments[i] : {}; i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { _defineProperty(target, key, source[key]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } return target; }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  const Zendesk = Ember.Object.extend(_request.default, {// Instance methods
  });
  Zendesk.reopenClass(_request.default, {
    // Class methods
    createTicket(data) {
      return this.request({
        url: this.apiURL('zendesk/tickets'),
        method: 'post',
        data: JSON.stringify({
          ticket: data
        })
      });
    },

    createEnrichedTicket(data) {
      return this.request({
        url: this.apiURL('zendesk/tickets/enriched'),
        method: 'post',
        data: JSON.stringify(_objectSpread({}, data))
      });
    },

    getChannelTag(channel) {
      if (channel) {
        return `channel_${channel}`;
      } else {
        return '';
      }
    },

    createAuthenticationToken(endpoint) {
      return this.request({
        url: this.thirdPartyAuthServiceApiURL(endpoint),
        method: 'get'
      });
    },

    createHandsfreeBulkUploadTicket(boutique, csvFile, images, additionalInfo) {
      return this.createEnrichedTicket({
        requester_email: boutique.support_email || boutique.order_email,
        requester_name: boutique.name,
        subject: `${boutique.name} - Upload request - Action required`,
        tags: ['community', 'boutiques', 'products', 'product_upload'],
        auto: true,
        comment: {
          body: `
                    ${boutique.name} has submitted the following files and images for a bulk upload request:
                    ---------------------
                    CSV File:
                    ${csvFile ? csvFile.secure_url : 'N/A'}
                    ---------------------
                    Photos:
                    ${images.map(photo => photo.secure_url + '\n')}
                    ---------------------
                    Additional Information:
                    ${additionalInfo ? additionalInfo : 'N/A'}
                `
        }
      });
    }

  });
  var _default = Zendesk;
  _exports.default = _default;
});