define("flaneur/pods/components/resource-search/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "zckJTJlI",
    "block": "{\"symbols\":[\"result\",\"index\",\"&default\"],\"statements\":[[8,\"fa-icon\",[[24,0,\"fa\"]],[[\"@icon\",\"@prefix\"],[\"search\",\"far\"]],null],[2,\"\\n\"],[10,\"input\"],[15,2,[34,0]],[15,0,[34,11]],[15,\"oninput\",[30,[36,5],[[32,0],\"_onInput\"],null]],[14,4,\"text\"],[12],[13],[2,\"\\n\\n\"],[6,[37,4],[[35,9]],null,[[\"default\",\"else\"],[{\"statements\":[[6,[37,2],[[35,10]],[[\"value\",\"results\",\"onCreate\",\"createComponent\",\"isCreateEnabled\"],[[35,0],[35,9],[30,[36,5],[[32,0],\"_onCreate\"],null],[35,8],[35,7]]],[[\"default\"],[{\"statements\":[[6,[37,2],[[35,6]],[[\"result\",\"onSelect\"],[[32,1],[30,[36,5],[[32,0],\"_onSelect\"],null]]],[[\"default\"],[{\"statements\":[[2,\"      \"],[18,3,[[32,1],[32,2]]],[2,\"\\n\"]],\"parameters\":[]}]]]],\"parameters\":[1,2]}]]]],\"parameters\":[]},{\"statements\":[[6,[37,4],[[35,3]],null,[[\"default\"],[{\"statements\":[[2,\"    \"],[1,[30,[36,2],[[35,1]],[[\"value\"],[[35,0]]]]],[2,\"\\n\"]],\"parameters\":[]}]]]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"value\",\"emptyComponent\",\"component\",\"noResults\",\"if\",\"action\",\"resultComponent\",\"isCreateEnabled\",\"createComponent\",\"results\",\"resultsComponent\",\"inputClass\"]}",
    "moduleName": "flaneur/pods/components/resource-search/template.hbs"
  });

  _exports.default = _default;
});