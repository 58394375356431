define("flaneur/models/curate/order/validations", ["exports", "flaneur/util/validation/validations"], function (_exports, _validations) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    agrees_with_brand_terms: (0, _validations.validateInclusion)({
      in: [true],
      message: "You need to agree with the brand's T&Cs"
    }),
    'contact_details.contact_name': (0, _validations.validatePresence)({
      presence: true,
      message: 'Contact name should not be empty'
    }),
    'contact_details.email_address': (0, _validations.validatePresence)({
      presence: true,
      message: 'Email address should not be empty'
    }),
    'contact_details.phone_number': (0, _validations.validatePresence)({
      presence: true,
      message: 'Phone number should not be empty'
    }),
    'invoice_details.boutique_name': (0, _validations.validatePresence)({
      presence: true,
      message: 'Boutique name should not be empty'
    }),
    'invoice_details.vat_number': (0, _validations.validatePresence)({
      presence: true,
      message: 'VAT number should not be empty'
    })
  };
  _exports.default = _default;
});