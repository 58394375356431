define("flaneur/pods/components/order-reference/component", ["exports", "@glimmer/component", "flaneur/enums/order-channel"], function (_exports, _component, _orderChannel) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  class OrderReferenceComponent extends _component.default {
    get reference() {
      const isMadeOrder = this.args.order.channel === _orderChannel.default.MADE_DOT_COM;
      return isMadeOrder ? this.args.order.channel_specific.order_reference : this.args.order.ordernumber;
    }

  }

  _exports.default = OrderReferenceComponent;
});