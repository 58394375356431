define("flaneur/utils/params-lists", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.searchByList = _exports.madeStatusFilterList = _exports.stockFilterList = _exports.salesFilterList = _exports.sortFilterList = _exports.sizeList = _exports.discountList = _exports.stockList = _exports.limitList = void 0;

  const Obj = $1 => Ember.Object.create($1);

  const limitList = [Obj({
    label: '20 products per page',
    params: {
      limit: 20
    }
  }), Obj({
    label: '50 products per page',
    params: {
      limit: 50
    }
  }), Obj({
    label: '100 products per page',
    params: {
      limit: 100
    }
  })];
  _exports.limitList = limitList;
  const stockList = [Obj({
    label: 'Leave unchanged',
    params: {}
  }), Obj({
    label: 'In stock',
    params: {
      stock: true
    }
  }), Obj({
    label: 'Out of stock',
    params: {
      stock: false
    }
  })];
  _exports.stockList = stockList;
  const discountList = [Obj({
    label: 'No discount',
    params: {
      discount: 0
    }
  }), Obj({
    label: '5%',
    params: {
      discount: 5
    }
  }), Obj({
    label: '10%',
    params: {
      discount: 10
    }
  }), Obj({
    label: '15%',
    params: {
      discount: 15
    }
  }), Obj({
    label: '20%',
    params: {
      discount: 20
    }
  }), Obj({
    label: '25%',
    params: {
      discount: 25
    }
  }), Obj({
    label: '30%',
    params: {
      discount: 30
    }
  }), Obj({
    label: '35%',
    params: {
      discount: 35
    }
  }), Obj({
    label: '40%',
    params: {
      discount: 40
    }
  }), Obj({
    label: '45%',
    params: {
      discount: 45
    }
  }), Obj({
    label: '50%',
    params: {
      discount: 50
    }
  }), Obj({
    label: '55%',
    params: {
      discount: 55
    }
  }), Obj({
    label: '60%',
    params: {
      discount: 60
    }
  }), Obj({
    label: '65%',
    params: {
      discount: 65
    }
  }), Obj({
    label: '70%',
    params: {
      discount: 70
    }
  }), Obj({
    label: '75%',
    params: {
      discount: 75
    }
  })];
  _exports.discountList = discountList;
  const sizeList = [Obj({
    label: 'Leave unchanged',
    params: {}
  }), Obj({
    label: 'Small size',
    params: {
      shipping_size: 'small'
    }
  }), Obj({
    label: 'Medium size',
    params: {
      shipping_size: 'medium'
    }
  })];
  _exports.sizeList = sizeList;
  const sortFilterList = [Obj({
    params: {
      sort: '-created_at'
    },
    label: 'Newest created first'
  }), Obj({
    params: {
      sort: 'created_at'
    },
    label: 'Oldest created first'
  }), Obj({
    params: {
      sort: '-updated_at'
    },
    label: 'Newest updated first'
  }), Obj({
    params: {
      sort: 'updated_at'
    },
    label: 'Oldest updated first'
  }), Obj({
    params: {
      sort: 'name'
    },
    label: 'Name: A-Z'
  }), Obj({
    params: {
      sort: '-name'
    },
    label: 'Name: Z-A'
  }), Obj({
    params: {
      sort: 'price'
    },
    label: 'Price: lowest first'
  }), Obj({
    params: {
      sort: '-price'
    },
    label: 'Price: highest first'
  })];
  _exports.sortFilterList = sortFilterList;
  const salesFilterList = [Obj({
    label: 'All',
    desktopLabel: 'Any sale status',
    active: true,
    params: {
      sales: null
    }
  }), Obj({
    label: 'On sale',
    desktopLabel: 'On sale',
    params: {
      sales: true
    }
  }), Obj({
    label: 'Not on sale',
    desktopLabel: 'Not on sale',
    params: {
      sales: false
    }
  })];
  _exports.salesFilterList = salesFilterList;
  const stockFilterList = [{
    label: 'Any stock status',
    params: {
      stock: null
    }
  }, {
    label: 'In stock',
    params: {
      stock: true
    }
  }, {
    label: 'Out of stock',
    params: {
      stock: false
    }
  }, {
    label: 'Incoming stock',
    params: {
      stock: 'restocking'
    }
  }];
  _exports.stockFilterList = stockFilterList;
  const madeStatusFilterList = [{
    label: 'Any MADE status',
    params: {
      'made-status': null
    }
  }, {
    label: 'Selected for MADE',
    params: {
      'made-status': 'selected'
    }
  }
  /*
   * @TODO: Add back once promenade's behaviour is changed
   */
  // {
  //     label: 'Not selected for MADE',
  //     params: { 'made-status': 'not-selected' },
  // },
  ];
  _exports.madeStatusFilterList = madeStatusFilterList;
  const searchByList = [{
    label: 'name',
    params: {
      'search-by': 'name'
    }
  }, {
    label: 'brand',
    params: {
      'search-by': 'standard_manufacturer'
    }
  }, {
    label: 'GTIN',
    params: {
      'search-by': 'gtin'
    }
  }, {
    label: 'SKU',
    params: {
      'search-by': 'sku'
    }
  }];
  _exports.searchByList = searchByList;
});