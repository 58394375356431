define("flaneur/pods/components/form-select/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "oBATxVNp",
    "block": "{\"symbols\":[\"option\"],\"statements\":[[10,\"div\"],[15,0,[31,[\"item item-select \",[30,[36,1],[[35,2],\"active\",\"\"],null],\" \",[30,[36,1],[[35,0],\"disabled\",\"\"],null]]]],[12],[2,\"\\n  \"],[10,\"p\"],[14,0,\"item-label\"],[12],[1,[34,3]],[13],[2,\"\\n  \"],[10,\"div\"],[14,0,\"item-form\"],[12],[2,\"\\n      \"],[10,\"div\"],[14,0,\"form-element\"],[12],[2,\"\\n        \"],[8,\"power-select\",[],[[\"@options\",\"@selected\",\"@onChange\",\"@searchField\",\"@disabled\",\"@searchEnabled\"],[[34,4],[34,5],[30,[36,7],[[32,0],[30,[36,6],[[35,5]],null]],null],\"display\",[34,0],false]],[[\"default\"],[{\"statements\":[[2,\"\\n          \"],[1,[32,1]],[2,\"\\n        \"]],\"parameters\":[1]}]]],[2,\"\\n      \"],[13],[2,\"\\n  \"],[13],[2,\"\\n\"],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"disabled\",\"if\",\"active\",\"label\",\"list\",\"selected\",\"mut\",\"action\"]}",
    "moduleName": "flaneur/pods/components/form-select/template.hbs"
  });

  _exports.default = _default;
});