define("flaneur/pods/components/lightbox/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "ajq6Sxql",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[8,\"modal-dialog\",[],[[\"@containerClass\"],[[31,[\"lightbox \",[32,0,[\"styleNamespace\"]],\"__modal \",[30,[36,0],[[32,0,[\"class\"]],[32,0,[\"class\"]]],null]]]]],[[\"default\"],[{\"statements\":[[2,\"\\n  \"],[11,\"div\"],[24,\"role\",\"button\"],[24,0,\"control\"],[4,[38,1],[\"click\",[32,0,[\"previous\"]]],null],[12],[2,\"\\n    \"],[8,\"fa-icon\",[],[[\"@icon\",\"@prefix\"],[\"chevron-left\",\"far\"]],null],[2,\"\\n  \"],[13],[2,\"\\n  \"],[10,\"div\"],[14,0,\"carousel\"],[12],[2,\"\\n    \"],[18,1,null],[2,\"\\n  \"],[13],[2,\"\\n  \"],[11,\"div\"],[24,\"role\",\"button\"],[24,0,\"control\"],[4,[38,1],[\"click\",[32,0,[\"next\"]]],null],[12],[2,\"\\n    \"],[8,\"fa-icon\",[],[[\"@icon\",\"@prefix\"],[\"chevron-right\",\"far\"]],null],[2,\"\\n  \"],[13],[2,\"\\n\\n  \"],[8,\"button\",[],[[\"@theme\",\"@onClick\"],[\"secondary\",[32,0,[\"close\"]]]],[[\"default\"],[{\"statements\":[[2,\"\\n    \"],[8,\"fa-icon\",[],[[\"@icon\",\"@prefix\"],[\"times\",\"far\"]],null],[2,\"\\n    Close\\n  \"]],\"parameters\":[]}]]],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"if\",\"on\"]}",
    "moduleName": "flaneur/pods/components/lightbox/template.hbs"
  });

  _exports.default = _default;
});