define("flaneur/pods/components/confirmation-modal/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "IP9tYuwV",
    "block": "{\"symbols\":[\"@onClose\",\"@title\",\"&default\",\"@onConfirm\",\"@mainCta\"],\"statements\":[[8,\"modal-dialog\",[],[[\"@containerClass\",\"@translucentOverlay\",\"@onClose\"],[[32,0,[\"styleNamespace\"]],true,[32,1]]],[[\"default\"],[{\"statements\":[[2,\"\\n  \"],[10,\"div\"],[14,0,\"ember-modal-dialog__header\"],[12],[2,\"\\n      \"],[10,\"h1\"],[12],[1,[32,2]],[13],[2,\"\\n  \"],[13],[2,\"\\n\\n  \"],[11,\"div\"],[24,0,\"ember-modal-dialog__close\"],[4,[38,0],[\"click\",[32,1]],null],[12],[2,\"\\n    \"],[8,\"fa-icon\",[],[[\"@icon\",\"@prefix\"],[\"times\",\"far\"]],null],[2,\"\\n  \"],[13],[2,\"\\n\\n  \"],[10,\"div\"],[14,0,\"ember-modal-dialog__body\"],[12],[2,\"\\n    \"],[18,3,null],[2,\"\\n\\n    \"],[8,\"checkbox\",[],[[\"@checked\",\"@onChange\"],[[32,0,[\"isButtonEnabled\"]],[30,[36,2],[[30,[36,1],[[32,0,[\"isButtonEnabled\"]]],null]],null]]],[[\"default\"],[{\"statements\":[[2,\"\\n      Yes I'm sure\\n    \"]],\"parameters\":[]}]]],[2,\"\\n  \"],[13],[2,\"\\n\\n  \"],[10,\"div\"],[14,0,\"ember-modal-dialog__footer\"],[12],[2,\"\\n      \"],[8,\"button\",[],[[\"@disabled\",\"@size\",\"@onClick\"],[[30,[36,3],[[32,0,[\"isButtonEnabled\"]]],null],\"large\",[32,4]]],[[\"default\"],[{\"statements\":[[2,\"\\n        \"],[1,[32,5]],[2,\"\\n      \"]],\"parameters\":[]}]]],[2,\"\\n\\n      \"],[8,\"button\",[],[[\"@theme\",\"@size\",\"@onClick\"],[\"secondary\",\"large\",[32,1]]],[[\"default\"],[{\"statements\":[[2,\"\\n        Cancel\\n      \"]],\"parameters\":[]}]]],[2,\"\\n  \"],[13],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"on\",\"mut\",\"fn\",\"not\"]}",
    "moduleName": "flaneur/pods/components/confirmation-modal/template.hbs"
  });

  _exports.default = _default;
});