define("flaneur/pods/brand/boutiques/boutique/receipt/order/route", ["exports", "flaneur/models/reservation", "flaneur/models/manufacturer"], function (_exports, _reservation, _manufacturer) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _class, _descriptor, _descriptor2;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  let BrandBoutiquesBoutiqueReceiptOrderRoute = (_dec = Ember.inject.service, _dec2 = Ember.inject.service, (_class = class BrandBoutiquesBoutiqueReceiptOrderRoute extends Ember.Route {
    constructor(...args) {
      super(...args);

      _initializerDefineProperty(this, "brand", _descriptor, this);

      _initializerDefineProperty(this, "router", _descriptor2, this);
    }

    model({
      order: orderId
    }) {
      const boutiqueId = this.brand.currentBoutique._id;
      return _reservation.default.findReservationsByBoutique({
        boutique: boutiqueId,
        order: orderId,
        populate: 'product,boutique,order,stock_line'
      }).then(async unfilteredReservations => {
        // Filter reservations which are not this boutique's.
        const reservations = unfilteredReservations.filter(res => res.boutique._id === boutiqueId && res.status === 'success');

        if (reservations.length === 0) {
          return this.router.transitionTo('brand.boutiques.boutique.orders', this.brand._id, boutiqueId);
        }

        const [{
          order,
          boutique
        }] = reservations;
        const manufacturersIds = new Set(reservations.map(({
          product
        }) => {
          return product.standard_manufacturer;
        }).filter(Boolean));
        const manufacturers = await _manufacturer.default.find({
          _id: Array.from(manufacturersIds),
          fields: 'name'
        });
        reservations.forEach(reservation => {
          const {
            product
          } = reservation;
          const {
            standard_manufacturer
          } = product;
          product.standard_manufacturer = manufacturers.find(manufacturer => manufacturer._id === standard_manufacturer);
        });
        return {
          order,
          reservations,
          boutique,
          multipleBoutiques: reservations.length !== unfilteredReservations.length
        };
      });
    }

  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "brand", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "router", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  })), _class));
  _exports.default = BrandBoutiquesBoutiqueReceiptOrderRoute;
});