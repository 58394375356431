define("flaneur/pods/components/curate-categories-filter/link/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "TalaYbIF",
    "block": "{\"symbols\":[\"&attrs\",\"&default\"],\"statements\":[[11,\"span\"],[24,\"role\",\"button\"],[24,0,\"curate-categories-filter__link\"],[17,1],[12],[2,\"\\n  \"],[18,2,null],[2,\"\\n\"],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[]}",
    "moduleName": "flaneur/pods/components/curate-categories-filter/link/template.hbs"
  });

  _exports.default = _default;
});