define("flaneur/pods/components/packaging-how-to/component", ["exports", "@glimmer/component", "ember-component-css/pod-names"], function (_exports, _component, _podNames) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  class PackagingHowToComponent extends _component.default {
    get styleNamespace() {
      return _podNames.default['packaging-how-to'];
    }

  }

  _exports.default = PackagingHowToComponent;
});