define("flaneur/pods/components/selected-for-made-banner/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "NCrK4/52",
    "block": "{\"symbols\":[\"__arg0\",\"&default\",\"@title\",\"&attrs\"],\"statements\":[[8,\"banner\",[[16,0,[32,0,[\"styleNamespace\"]]],[17,4]],[[\"@title\",\"@theme\",\"@namedBlocksInfo\"],[[32,3],\"made\",[30,[36,3],null,[[\"icon\",\"text\"],[0,0]]]]],[[\"default\"],[{\"statements\":[[6,[37,1],[[30,[36,0],[[32,1],\"icon\"],null]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"\\n    \"],[1,[30,[36,2],[\"made-monogram\"],null]],[2,\"\\n  \"]],\"parameters\":[]},{\"statements\":[[6,[37,1],[[30,[36,0],[[32,1],\"text\"],null]],null,[[\"default\"],[{\"statements\":[[18,2,null]],\"parameters\":[]}]]]],\"parameters\":[]}]]]],\"parameters\":[1]}]]],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"-is-named-block-invocation\",\"if\",\"svg-jar\",\"hash\"]}",
    "moduleName": "flaneur/pods/components/selected-for-made-banner/template.hbs"
  });

  _exports.default = _default;
});