define("flaneur/models/curate/category/index", ["exports", "fetch", "flaneur/config/environment", "query-string", "flaneur/enums/curate-category-status"], function (_exports, _fetch, _environment, _queryString, _curateCategoryStatus) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4;

  function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); enumerableOnly && (symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols); } return keys; }

  function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = null != arguments[i] ? arguments[i] : {}; i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { _defineProperty(target, key, source[key]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } return target; }

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  let CurateCategory = (_dec = Ember._tracked, _dec2 = Ember._tracked, _dec3 = Ember._tracked, _dec4 = Ember._tracked, (_class = class CurateCategory {
    constructor({
      _id,
      name,
      status,
      parent,
      ancestors = [],
      count
    }) {
      _initializerDefineProperty(this, "name", _descriptor, this);

      _initializerDefineProperty(this, "status", _descriptor2, this);

      _initializerDefineProperty(this, "parent", _descriptor3, this);

      _initializerDefineProperty(this, "ancestors", _descriptor4, this);

      this._id = _id;
      this.name = name;
      this.status = status;
      this.parent = parent;
      this.count = count;
      this.ancestors = ancestors.map(ancestor => new CurateCategory(ancestor)).sort((a, b) => a.ancestors.length - b.ancestors.length);
    }

    get isPublished() {
      return this.status === _curateCategoryStatus.default.PUBLISHED;
    }

    static async findHierarchy({
      id,
      brand
    }) {
      const queryParams = _queryString.default.stringify({
        id,
        brand
      });

      const url = `${_environment.default.curateApiURL}/api/v1/categories-hierarchy?${queryParams}`;
      const res = await (0, _fetch.default)(url, {
        method: 'GET',
        credentials: 'include',
        cache: false
      });

      if (!res.ok) {
        throw new Error('error finding categories hierarchy');
      }

      const {
        categories_hierarchy: categories,
        meta
      } = await res.json();
      return categories.reduce((acc, category) => {
        var _category$parent, _acc$key;

        const key = (_category$parent = category.parent) !== null && _category$parent !== void 0 ? _category$parent : 'topLevel';
        return _objectSpread(_objectSpread({}, acc), {}, {
          [key]: [...((_acc$key = acc[key]) !== null && _acc$key !== void 0 ? _acc$key : []), new CurateCategory(category)]
        });
      }, _objectSpread({
        selected: id
      }, meta !== null && meta !== void 0 && meta.total ? {
        total: meta.total
      } : {}));
    }

    static async find(query = {}) {
      const queryParams = _queryString.default.stringify(_objectSpread({
        status: _curateCategoryStatus.default.PUBLISHED
      }, query));

      const url = `${_environment.default.curateApiURL}/api/v1/categories?${queryParams}`;
      const res = await (0, _fetch.default)(url, {
        method: 'GET',
        credentials: 'include',
        cache: false
      });

      if (!res.ok) {
        throw new Error('error finding categories');
      }

      const {
        categories,
        meta
      } = await res.json();
      return {
        categories: categories.map(category => new CurateCategory(category)),
        meta
      };
    }

    static async findById(id) {
      const url = `${_environment.default.curateApiURL}/api/v1/categories/${id}`;
      const res = await (0, _fetch.default)(url, {
        method: 'GET',
        credentials: 'include',
        cache: false
      });

      if (!res.ok) {
        throw new Error(`error finding category ${id}`);
      }

      const {
        categories: [category]
      } = await res.json();

      if (!category) {
        return null;
      }

      return new CurateCategory(_objectSpread(_objectSpread({}, category), {}, {
        parent: category.parent._id
      }));
    }

    static async findDescendants(id) {
      const {
        categories
      } = await CurateCategory.find({
        parent: id ? id : 'null',
        populate: 'ancestors',
        sort: 'name'
      });

      if (categories.length > 0) {
        return {
          categories,
          hasDescendants: true
        };
      }

      const category = await CurateCategory.findById(id);

      if (!category.isPublished) {
        return {
          categories: []
        };
      }

      return {
        categories: [category],
        hasDescendants: false
      };
    }

  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "name", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "status", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "parent", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "ancestors", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  })), _class));
  _exports.default = CurateCategory;
});