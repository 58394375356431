define("flaneur/pods/components/frames-tips/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "fJ0uHT8O",
    "block": "{\"symbols\":[],\"statements\":[[10,\"p\"],[12],[2,\"\\n  Get inspired by the best Frames in\\n  \"],[10,\"a\"],[14,0,\"anchor\"],[14,\"target\",\"_blank\"],[14,\"rel\",\"noopener\"],[14,6,\"https://www.trouva.com/frames/no-ordinary-world\"],[12],[2,\"Home,\"],[13],[2,\"\\n  \"],[10,\"a\"],[14,0,\"anchor\"],[14,\"target\",\"_blank\"],[14,\"rel\",\"noopener\"],[14,6,\"https://www.trouva.com/frames/cosy-moments\"],[12],[2,\"Fashion\"],[13],[2,\"\\n  and\\n  \"],[10,\"a\"],[14,0,\"anchor\"],[14,\"target\",\"_blank\"],[14,\"rel\",\"noopener\"],[14,6,\"https://www.trouva.com/frames/fluidity-meets-minimalism\"],[12],[2,\"Lifestyle\"],[13],[2,\"\\n\"],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[]}",
    "moduleName": "flaneur/pods/components/frames-tips/template.hbs"
  });

  _exports.default = _default;
});