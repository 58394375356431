define("flaneur/pods/components/curate-filters/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "ZLnTLDfV",
    "block": "{\"symbols\":[\"&attrs\",\"&default\"],\"statements\":[[11,\"div\"],[24,0,\"curate-filters\"],[17,1],[12],[2,\"\\n  \"],[18,2,[[30,[36,1],null,[[\"categories\",\"tags\"],[[30,[36,0],[\"curate-categories-filter\"],null],[30,[36,0],[\"curate-tags-filter\"],null]]]]]],[2,\"\\n\"],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"component\",\"hash\"]}",
    "moduleName": "flaneur/pods/components/curate-filters/template.hbs"
  });

  _exports.default = _default;
});