define("flaneur/pods/brand/boutiques/boutique/inventory/index/route", ["exports", "flaneur/models/product"], function (_exports, _product) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); enumerableOnly && (symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols); } return keys; }

  function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = null != arguments[i] ? arguments[i] : {}; i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { _defineProperty(target, key, source[key]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } return target; }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  var _default = Ember.Route.extend({
    queryParams: {
      limit: {
        refreshModel: true
      },
      page: {
        refreshModel: true
      },
      query: {
        refreshModel: true
      },
      searchBy: {
        refreshModel: true
      },
      sort: {
        refreshModel: true
      },
      stock: {
        refreshModel: true
      }
    },

    beforeModel() {
      if (this.brand.currentBoutique.isFeedBoutique) {
        this.transitionTo('brand.boutiques.boutique.products.index', this.brand);
      }
    },

    async model({
      limit,
      page,
      query,
      searchBy,
      sort,
      stock
    }) {
      const params = {
        archived: false,
        brand: this.brand._id,
        page,
        limit,
        sort,
        use_new_filter: true,
        populate_standard_manufacturer: true
      };
      const parsedStock = this.parseStockQueryParameter(stock);
      Ember.assign(params, this.getParams(parsedStock, query, searchBy));
      return _product.default.find(params).then(({
        meta,
        products
      }) => {
        return {
          products,
          meta: _objectSpread(_objectSpread({}, meta), {}, {
            query,
            searchBy,
            sort,
            stock: parsedStock,
            limit
          })
        };
      });
    },

    getParams(stock, query, searchBy) {
      const params = {};

      if (Ember.isPresent(stock)) {
        Ember.assign(params, this.getStockParams(stock));
      }

      if (Ember.isPresent(query)) {
        Ember.assign(params, this.getSearchParams(query, searchBy));
      }

      return params;
    },

    getStockParams(stock) {
      if (stock === 'restocking') {
        return {
          boutique_restock_information_status: 'restocking'
        };
      }

      if (stock === true) {
        return {
          stock_lines_stock_status: 'in'
        };
      }

      if (stock === false) {
        return {
          stock_lines_stock_status: 'out'
        };
      }

      return {};
    },

    getSearchParams(query, searchBy) {
      return searchBy === 'sku' ? {
        sku: query
      } : {
        free_text_search: query
      };
    },

    parseStockQueryParameter(stock) {
      if (stock === 'true') {
        return true;
      }

      if (stock === 'false') {
        return false;
      }

      return stock;
    }

  });

  _exports.default = _default;
});