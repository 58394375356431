define("flaneur/models/boutique/index", ["exports", "date-fns", "date-fns-tz", "flaneur/mixins/request", "flaneur/models/boutique/validations", "flaneur/enums/country-iso-code-alpha-3", "flaneur/enums/eu-vat-status", "flaneur/enums/boutique-tier", "flaneur/enums/boutique-feature"], function (_exports, _dateFns, _dateFnsTz, _request, _validations, _countryIsoCodeAlpha, _euVatStatus, _boutiqueTier, _boutiqueFeature) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); enumerableOnly && (symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols); } return keys; }

  function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = null != arguments[i] ? arguments[i] : {}; i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { _defineProperty(target, key, source[key]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } return target; }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  const Boutique = Ember.Object.extend(_request.default, _validations.Validations, {
    addressCountryDocuments: Ember.computed.alias('address_country.documents'),
    registeredAddressIsoCodeAlpha3: Ember.computed.alias('registered_address_country.iso_code_alpha_3'),
    isVatRegistered: Ember.computed.alias('is_vat_registered'),
    isGBR: Ember.computed.equal('registeredAddressIsoCodeAlpha3', _countryIsoCodeAlpha.default.GBR),
    isOssRegistered: Ember.computed('eu_vat', 'eu_vat.status', function () {
      var _this$eu_vat;

      return Boolean(this.eu_vat) && ((_this$eu_vat = this.eu_vat) === null || _this$eu_vat === void 0 ? void 0 : _this$eu_vat.status) === _euVatStatus.default.OSS;
    }),
    isStarTier: Ember.computed('tier', function () {
      return [_boutiqueTier.default.STAR_GOLD, _boutiqueTier.default.STAR_SILVER, _boutiqueTier.default.STAR_BRONZE].includes(this.tier);
    }),
    isOnHoliday: Ember.computed('holiday_from', 'holiday_to', function () {
      const from = this.holiday_from;
      const to = this.holiday_to;

      if (!from || !to) {
        return false;
      }

      if ((0, _dateFns.isSameDay)((0, _dateFns.parseISO)(from), (0, _dateFns.parseISO)(to))) {
        return (0, _dateFns.isSameDay)(new Date(), (0, _dateFns.parseISO)(from));
      }

      return (0, _dateFns.isWithinInterval)(new Date(), {
        start: (0, _dateFns.parseISO)(from),
        end: (0, _dateFns.parseISO)(to)
      });
    }),
    isUsingFilestack: Ember.computed('rp_upload_type', function () {
      return ['filestack', 'native'].includes(this.rp_upload_type);
    }),
    isUsingFilestackPicker: Ember.computed.equal('rp_upload_type', 'filestack'),
    hasTermsAndConditions: Ember.computed.notEmpty('terms_and_conditions'),
    hasAcceptedTerms: Ember.computed.notEmpty('terms_and_conditions.terms_url'),
    hasPendingTerms: Ember.computed.notEmpty('terms_and_conditions.pending_terms_url'),
    hasAutomaticallyAcceptedTerms: Ember.computed('timezone', 'terms_and_conditions.{limit_for_acceptance,pending_terms_url}', function () {
      const {
        limit_for_acceptance,
        pending_terms_url
      } = this.terms_and_conditions;

      if (!limit_for_acceptance) {
        return false;
      }

      const limitForAcceptance = Ember.isEmpty(this.timezone) ? (0, _dateFns.parseISO)(limit_for_acceptance) : (0, _dateFnsTz.utcToZonedTime)((0, _dateFns.parseISO)(limit_for_acceptance), this.timezone);
      return Ember.isPresent(pending_terms_url) && (0, _dateFns.isAfter)(new Date(), limitForAcceptance);
    }),
    isPackagingEnabled: Ember.computed('registeredAddressIsoCodeAlpha3', function () {
      const countries = [_countryIsoCodeAlpha.default.GBR, _countryIsoCodeAlpha.default.DEU];
      return countries.includes(this.registeredAddressIsoCodeAlpha3);
    }),
    isCurateEnabled: Ember.computed('features', function () {
      var _this$features;

      return (_this$features = this.features) === null || _this$features === void 0 ? void 0 : _this$features.includes(_boutiqueFeature.default.CURATE);
    }),
    b2bServices: Ember.computed('isCurateEnabled', 'isPackagingEnabled', 'isQuickShipEnabled', function () {
      return [this.isPackagingEnabled ? 'packaging' : null, this.isQuickShipEnabled ? 'quickship' : null, this.isCurateEnabled ? 'curate' : null].filter(Boolean);
    }),

    getBulkOperationById(id) {
      return this.bulk_upload_history.findBy('bulk_operation_id', id);
    },

    /**
     * Reload this instance from the server
     * @return {Promise} returns the uptodate instance
     */
    reload() {
      return this.request({
        method: 'GET',
        url: this.apiURL(`boutiques/${this._id}`)
      }).then(res => {
        this.setProperties(res.boutiques[0]);
      });
    },

    /**
     * Create new boutique
     * @return {Object} Promise
     */
    post() {
      var boutique = this;
      return this.request({
        method: 'POST',
        url: this.apiURL('boutiques'),
        data: JSON.stringify(boutique.sanitize())
      }).then(res => {
        boutique.setProperties(res.boutiques[0]);
        return boutique;
      });
    },

    /**
     * Save boutique
     * @return {Object} Promise
     */
    save() {
      const boutique = this.copy();

      if (typeof boutique.address_country === 'object') {
        boutique.address_country = boutique.address_country._id;
      }

      boutique.areas = boutique.areas.map(area => typeof area === 'object' ? area._id : area);
      const sanitized = (0, _request.sanitize)(boutique);
      return this.request({
        method: 'PUT',
        url: this.apiURL(`boutiques/${sanitized._id}`),
        data: JSON.stringify(sanitized, (k, v) => k === 'currency' ? undefined : v)
      }).then(res => {
        boutique.setProperties(res.boutiques[0]);
      });
    },

    /**
     * Accept terms and conditions
     * @return {Object} Promise
     */
    acceptTerms(terms) {
      return this.request({
        method: 'POST',
        url: this.apiURL(`boutiques/${this._id}/accept-terms`),
        data: JSON.stringify({
          accepted_terms: terms
        })
      }).then(res => this.setProperties(res.boutiques[0]));
    },

    acceptCurateTerms() {
      return this.request({
        method: 'POST',
        url: this.apiURL(`boutiques/${this._id}/accept-curate-terms`)
      }).then(({
        boutiques
      }) => {
        const [boutique] = boutiques;
        this.set('curate_terms_and_conditions', boutique.curate_terms_and_conditions);
      });
    }

  });
  Boutique.reopen({
    /**
     * Make a copy of the object
     * @return {Object} Copy
     */
    copy() {
      return Boutique.create(this);
    }

  });
  Boutique.reopenClass(_request.default, {
    // Class methods
    setContainer(container) {
      this.container = container;
    },

    _create(boutique) {
      return this.create(boutique);
    },

    find(query = {}) {
      return this.request({
        method: 'GET',
        url: this.apiURL('boutiques'),
        data: _objectSpread(_objectSpread({}, query), {}, {
          skip_links: true
        })
      }).then(res => res.boutiques.map(boutique => this._create(boutique)));
    },

    /**
     * Find boutique by id
     * @param  {String} id boutique _id
     * @return {Object}    Promise
     */
    findById(id) {
      return this.request({
        method: 'GET',
        url: this.apiURL(`boutiques/${id}?skip_links=true`)
      }).then(res => this._create(res.boutiques[0]));
    },

    /**
     * Save boutique
     * @return {Object} Promise
     */
    partialSave(boutique) {
      if (typeof boutique.address_country === 'object') {
        boutique.address_country = boutique.address_country._id;
      }

      if (boutique.areas && boutique.areas.length) {
        boutique.areas = boutique.areas.map(area => typeof area === 'object' ? area._id : area);
      }

      const sanitized = (0, _request.sanitize)(boutique);
      return this.request({
        method: 'PUT',
        url: this.apiURL(`boutiques/${sanitized._id}`),
        data: JSON.stringify(sanitized, (k, v) => k === 'currency' ? undefined : v)
      }).then(res => res.boutiques[0]);
    }

  });
  var _default = Boutique;
  _exports.default = _default;
});