define("flaneur/pods/components/reservation-product-gift/component", ["exports", "@glimmer/component", "ember-component-css/pod-names"], function (_exports, _component, _podNames) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  class ReservationProductGift extends _component.default {
    get hasOptedIn() {
      const {
        gift_wrap
      } = this.args.boutique;
      return gift_wrap;
    }

    get styleNamespace() {
      return _podNames.default['reservation-product-gift'];
    }

  }

  _exports.default = ReservationProductGift;
});