define("flaneur/pods/components/form-flows/product/product-options/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    media: Ember.inject.service(),
    classNames: ['product-options'],
    elementId: 'product-options',
    skuHelpCentreArticleHref: 'https://trouvaboutiques.zendesk.com/hc/en-us/articles/360006699057',
    hasStockLineWithUnlimitedQuantity: Ember.computed('product.stock_lines.@each.quantity', function () {
      return this.product.stock_lines.some(stockLine => stockLine.quantity === -1);
    }),
    draggableClassName: Ember.computed('media.isLarge', function () {
      return !this.media.isLarge ? '.product-options__option-title' : '.product-options__option-number';
    }),
    actions: {
      onChangeVariant(product, stockLine, variantName, variant) {
        if (variant === null) {
          return product.removeVariant(stockLine, variantName);
        }

        return product.addVariant(stockLine, variant);
      },

      addStockLine(product) {
        return product.addStockLine();
      },

      removeStockLine(product, stockLine) {
        return product.removeStockLine(stockLine);
      },

      reorderItems(product, stockLines) {
        stockLines = stockLines.map((stockLine, index) => {
          stockLine.display_order = index;
          return stockLine;
        });
        product.set('stock_lines', stockLines);
      },

      dragStarted() {
        document.ontouchmove = function (e) {
          e.preventDefault();
        };
      },

      dragStopped() {
        document.ontouchmove = function () {
          return true;
        };
      }

    }
  });

  _exports.default = _default;
});