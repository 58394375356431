define("flaneur/enums/shipment-status-type", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    PENDING: 'pending',
    FAILED: 'failed',
    EXPIRED: 'expired',
    BOOKED: 'booked'
  };
  _exports.default = _default;
});