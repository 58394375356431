define("flaneur/pods/components/frame-form/tags/component", ["exports", "@glimmer/component", "ember-component-css/pod-names", "flaneur/enums/frame-tag-type", "flaneur/models/frame-tag"], function (_exports, _component, _podNames, _frameTagType, _frameTag) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _class, _descriptor, _descriptor2;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  let FrameFormTagsComponent = (_dec = Ember._tracked, _dec2 = Ember._tracked, _dec3 = Ember._action, _dec4 = Ember._action, _dec5 = Ember._action, _dec6 = Ember._action, _dec7 = Ember._action, (_class = class FrameFormTagsComponent extends _component.default {
    constructor() {
      super(...arguments);

      _initializerDefineProperty(this, "isModalOpen", _descriptor, this);

      _initializerDefineProperty(this, "standardTags", _descriptor2, this);

      this.standardTags = this.args.standardTags || [];
    }

    get styleNamespace() {
      return _podNames.default['frame-form/tags'];
    }

    get firstTagOptions() {
      return _frameTag.default.find({
        type: _frameTagType.default.ALWAYS_ON
      });
    }

    get secondTagOptions() {
      return _frameTag.default.find({
        type: _frameTagType.default.TREND
      });
    }

    get hasFirstTag() {
      return this.standardTags.length > 0;
    }

    get hasSecondTag() {
      return this.standardTags.length > 1;
    }

    get firstTagSelected() {
      return !this.hasFirstTag ? null : this.standardTags[0];
    }

    get secondTagSelected() {
      return !this.hasSecondTag ? null : this.standardTags[1];
    }

    onFirstTagChanged(tag) {
      this.standardTags = [tag, this.secondTagSelected].filter(Boolean);
    }

    onSecondTagChanged(tag) {
      this.standardTags = [this.firstTagSelected, tag];
    }

    saveTags() {
      this.args.onUpdateTags(this.standardTags);
      this.closeModal();
    }

    clearTags() {
      this.standardTags = [];
    }

    closeModal() {
      this.isModalOpen = false;
      this.standardTags = this.args.standardTags || [];
    }

  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "isModalOpen", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "standardTags", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "onFirstTagChanged", [_dec3], Object.getOwnPropertyDescriptor(_class.prototype, "onFirstTagChanged"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "onSecondTagChanged", [_dec4], Object.getOwnPropertyDescriptor(_class.prototype, "onSecondTagChanged"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "saveTags", [_dec5], Object.getOwnPropertyDescriptor(_class.prototype, "saveTags"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "clearTags", [_dec6], Object.getOwnPropertyDescriptor(_class.prototype, "clearTags"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "closeModal", [_dec7], Object.getOwnPropertyDescriptor(_class.prototype, "closeModal"), _class.prototype)), _class));
  _exports.default = FrameFormTagsComponent;
});