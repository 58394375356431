define("flaneur/pods/shopify-integration/controller", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    queryParams: ['shop', 'code'],
    shop: null,
    code: null,
    // Admin functionality only
    searchBoutiqueNameAsAdmin: '',
    selectedBoutiqueAsAdmin: null
  });

  _exports.default = _default;
});