define("flaneur/pods/brand/boutiques/boutique/welcome/route", ["exports", "flaneur/models/boutique"], function (_exports, _boutique) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    brand: Ember.inject.service(),

    beforeModel(transition) {
      /* Disallow the user to access to this route directly from the URL */
      if (transition.sequence === 0) {
        this.replaceWith('brand.boutiques.boutique.settings', this.brand, this.brand.currentBoutique);
      }
    },

    model() {
      return _boutique.default.create(Ember.getOwner(this).ownerInjection(), this.brand.currentBoutique);
    }

  });

  _exports.default = _default;
});