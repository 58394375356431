define("flaneur/pods/zendesk/logout/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "oshHPEQm",
    "block": "{\"symbols\":[],\"statements\":[[8,\"zendesk-redirect\",[],[[],[]],[[\"default\"],[{\"statements\":[[2,\"\\n    \"],[10,\"h1\"],[14,0,\"zendesk-redirect__title\"],[12],[2,\"You have been logged out of the Boutique Help Centre\"],[13],[2,\"\\n    \"],[8,\"button\",[],[[\"@onClick\"],[[30,[36,1],[[32,0],[30,[36,0],[\"redirectToRetailPanel\"],null]],null]]],[[\"default\"],[{\"statements\":[[2,\"Return to Retail Panel\"]],\"parameters\":[]}]]],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"route-action\",\"action\"]}",
    "moduleName": "flaneur/pods/zendesk/logout/template.hbs"
  });

  _exports.default = _default;
});