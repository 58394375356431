define("flaneur/pods/components/form-flows/shipment/view-details/component", ["exports", "date-fns", "flaneur/enums/shipment-status-type"], function (_exports, _dateFns, _shipmentStatusType) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    analytics: Ember.inject.service(),
    brand: Ember.inject.service(),
    isShowDetails: false,
    contactSupport: false,
    dateFormat: 'dd MMM yyyy',
    isSuccess: Ember.computed('shipment.{status,use_automatic_pickup_booking,estimated_collection_date}', function () {
      if (this.shipment.status === _shipmentStatusType.default.BOOKED) {
        return true;
      }

      if (!this.shipment.use_automatic_pickup_booking) {
        return false;
      } // Automatic pickup booking shipments don't have 'booked' status after order creation,
      // but have estimated_collection_date if the pickup label was booked


      return Ember.isPresent(this.shipment.estimated_collection_date);
    }),
    deliveryDate: Ember.computed('shipment.{delivery_days,estimated_collection_date}', function () {
      const collectionDate = (0, _dateFns.parseISO)(this.shipment.estimated_collection_date);
      const deliveryDays = this.shipment.delivery_days;

      if (!deliveryDays) {
        return collectionDate;
      }

      return (0, _dateFns.addDays)(collectionDate, deliveryDays);
    }),
    actions: {
      printInNewTab(url, type) {
        if (type === 'label') {
          this.analytics.gtm({
            action: 'opened_shipment_label',
            label: this.shipment._id
          });
        }

        if (type === 'customsForm') {
          this.analytics.gtm({
            action: 'opened_custom_invoice',
            label: this.shipment._id
          });
        }

        return window.open(url);
      },

      contactSupport() {
        this.analytics.gtm({
          action: 'clicked_contact_support',
          label: this.shipment._id
        });
        this.toggleProperty('contactSupport');
      },

      onSentTicket() {
        this.analytics.gtm({
          action: 'contacted_support',
          label: this.shipment._id
        });
        this.toggleProperty('zendeskTicketSent');
      },

      openAndTrackTrackingUrl() {
        this.analytics.gtm({
          action: 'opened_tracking_link',
          label: this.shipment._id
        });
        return window.open(this.shipment.tracking_url);
      }

    }
  });

  _exports.default = _default;
});