define("flaneur/utils/billable-weight", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.getUpsBillableWeight = getUpsBillableWeight;
  _exports.MAX_UPS_BILLABLE_WEIGHT = void 0;
  const MAX_UPS_BILLABLE_WEIGHT = 70; // in kilogram

  _exports.MAX_UPS_BILLABLE_WEIGHT = MAX_UPS_BILLABLE_WEIGHT;

  function getUpsBillableWeight(shipment) {
    const {
      package: {
        weight,
        width,
        height,
        length
      }
    } = shipment;

    if (Ember.isEmpty(width) || Ember.isEmpty(height) || Ember.isEmpty(length)) {
      return {
        isCalculated: false,
        value: null
      };
    }

    const dimensions = width * height * length;
    const billableWeight = Math.max(weight, dimensions / 5000);
    return {
      isCalculated: true,
      value: Math.ceil(billableWeight)
    };
  }
});