define("flaneur/models/order", ["exports", "flaneur/mixins/request"], function (_exports, _request) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const Order = Ember.Object.extend(_request.default, {
    // Instance methods

    /**
     * Save order
     * @return {Object} Promise
     */
    save() {
      var order = this;
      return this.request({
        method: 'POST',
        url: this.apiURL('orders'),
        data: JSON.stringify(order.sanitize())
      });
    },

    /**
     * Delete a order
     * @return {Object} Promise
     */
    remove() {
      var order = this;
      return this.request({
        method: 'DELETE',
        url: this.apiURL(`orders/${order._id}`)
      });
    }

  });
  Order.reopenClass(_request.default, {
    // Class methods
    _create(order) {
      order.container = this.container;
      return this.create(order);
    },

    setContainer(container) {
      this.container = container;
    },

    find(data) {
      return this.request({
        method: 'GET',
        url: this.apiURL('orders'),
        data: data
      }).then(res => {
        return res.orders.map(order => this._create(order));
      });
    }

  });
  var _default = Order;
  _exports.default = _default;
});