define("flaneur/pods/components/curate-categories-filter/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "T1vrzr9S",
    "block": "{\"symbols\":[\"collapsible\",\"@categories\",\"&attrs\",\"@allLabel\"],\"statements\":[[10,\"div\"],[15,0,[32,0,[\"styleNamespace\"]]],[12],[2,\"\\n  \"],[8,\"collapsible\",[],[[\"@isOpen\",\"@onClick\"],[[32,0,[\"isCollapsed\"]],[30,[36,0],[\"isCollapsed\",[32,0]],null]]],[[\"default\"],[{\"statements\":[[2,\"\\n    \"],[8,[32,1,[\"label\"]],[],[[],[]],[[\"default\"],[{\"statements\":[[2,\"\\n      \"],[10,\"div\"],[14,0,\"curate-categories-filter__header\"],[12],[2,\"\\n        \"],[10,\"h2\"],[14,0,\"curate-categories-filter__title\"],[12],[2,\"Categories\"],[13],[2,\"\\n        \"],[8,\"fa-icon\",[[24,0,\"curate-categories-filter__icon\"]],[[\"@icon\"],[[30,[36,1],[[32,1,[\"isOpen\"]],\"minus\",\"plus\"],null]]],null],[2,\"\\n      \"],[13],[2,\"\\n    \"]],\"parameters\":[]}]]],[2,\"\\n    \"],[8,[32,1,[\"content\"]],[],[[],[]],[[\"default\"],[{\"statements\":[[2,\"\\n      \"],[11,\"div\"],[24,0,\"curate-categories-filter\"],[17,3],[12],[2,\"\\n        \"],[10,\"div\"],[15,0,[31,[\"curate-categories-filter__category \",[30,[36,1],[[30,[36,2],[[32,2,[\"selected\"]]],null],\"curate-categories-filter__selected\"],null]]]],[12],[2,\"\\n          \"],[8,\"curate-categories-filter/link\",[[4,[38,4],[\"click\",[30,[36,3],[[32,0,[\"onClickLink\"]],[29]],null]],null]],[[],[]],[[\"default\"],[{\"statements\":[[2,\"\\n            \"],[1,[32,4]],[2,\"\\n\"],[6,[37,1],[[32,2,[\"total\"]]],null,[[\"default\"],[{\"statements\":[[2,\"              (\"],[1,[32,2,[\"total\"]]],[2,\")\\n\"]],\"parameters\":[]}]]],[2,\"          \"]],\"parameters\":[]}]]],[2,\"\\n        \"],[13],[2,\"\\n        \"],[8,\"curate-categories-filter/subtree\",[],[[\"@categories\",\"@level\",\"@onChange\"],[[32,2],\"topLevel\",[32,0,[\"onClickLink\"]]]],null],[2,\"\\n      \"],[13],[2,\"\\n    \"]],\"parameters\":[]}]]],[2,\"\\n  \"]],\"parameters\":[1]}]]],[2,\"\\n\"],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"toggle\",\"if\",\"not\",\"fn\",\"on\"]}",
    "moduleName": "flaneur/pods/components/curate-categories-filter/template.hbs"
  });

  _exports.default = _default;
});