define("flaneur/pods/reset/route", ["exports", "flaneur/models/user"], function (_exports, _user) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    model(params) {
      return _user.default._create({
        forgot_password_token: params.token
      });
    },

    actions: {
      resetPassword() {
        const user = this.currentModel;

        if (user.new_password !== user.new_password_confirmation) {
          return this.notify.show('error', "Passwords don't match");
        }

        user.reset().then(() => {
          this.notify.show('success', 'Password reset successful');
          this.transitionTo('login');
        }).catch(err => {
          this.notify.show('error', err.responseJSON.errors[0].detail || 'Error resetting password');
        });
      }

    }
  });

  _exports.default = _default;
});