define("flaneur/pods/components/input-range/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    // Public API
    value: null,
    increment: 1,
    minimum: 0,
    maximum: Infinity,
    disabled: false,

    onChange() {},

    // Private API
    classNames: ['input-range'],
    classNameBindings: ['disabled:input-range--disabled'],
    numericValue: Ember.computed('value', {
      get() {
        return this._parseValue(this.value);
      },

      set(_, v) {
        return v;
      }

    }),
    hasReachedMinimum: Ember.computed('numericValue', 'minimum', function () {
      return this.numericValue <= this.minimum;
    }),
    hasReachedMaximum: Ember.computed('numericValue', 'maximum', function () {
      return this.numericValue >= this.maximum;
    }),

    _parseValue(value) {
      return Ember.isBlank(value) ? null : parseInt(value, 10);
    },

    actions: {
      onClickMinus() {
        const parsedValue = this._parseValue(this.numericValue);

        const value = this.hasReachedMinimum ? parsedValue : parsedValue - this.increment;
        return this.onChange(value);
      },

      onClickPlus() {
        const parsedValue = this._parseValue(this.numericValue);

        const value = this.hasReachedMaximum ? parsedValue : parsedValue + this.increment;
        return this.onChange(value);
      },

      onInputChange(value) {
        const parsedValue = this._parseValue(value);

        return this.onChange(parsedValue < this.minimum ? this.minimum : parsedValue);
      }

    }
  });

  _exports.default = _default;
});