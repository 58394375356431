define("flaneur/pods/components/pages/products/bulk/upload/review-step/category-modal/component", ["exports", "flaneur/models/category"], function (_exports, _category) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _dec8, _dec9, _dec10, _dec11, _dec12, _dec13, _class, _descriptor, _descriptor2;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  let CategoryModal = (_dec = Ember.computed.notEmpty('selectedMacroCategory'), _dec2 = Ember.computed.notEmpty('selectedParentCategory'), _dec3 = Ember.computed('products'), _dec4 = Ember.computed('products'), _dec5 = Ember.computed('products'), _dec6 = Ember.computed('products'), _dec7 = Ember.computed('products.length'), _dec8 = Ember.computed('selectedMacroCategory.isTerminal', 'selectedParentCategory.isTerminal', 'selectedTerminalCategory.isTerminal'), _dec9 = Ember.computed('selectedMacroCategory._id'), _dec10 = Ember.computed('selectedParentCategory.{_id,isTerminal}'), _dec11 = Ember._action, _dec12 = Ember._action, _dec13 = Ember._action, (_class = class CategoryModal extends Ember.Component {
    constructor(...args) {
      super(...args);

      _initializerDefineProperty(this, "canSelectParentCategory", _descriptor, this);

      _initializerDefineProperty(this, "canSelectTerminalCategory", _descriptor2, this);
    }

    get selectedMacroCategory() {
      var _product$cachedCatego;

      const [product] = this.products;
      return (_product$cachedCatego = product.cachedCategoryHierarchy) === null || _product$cachedCatego === void 0 ? void 0 : _product$cachedCatego.selectedMacroCategory;
    }

    get selectedParentCategory() {
      var _product$cachedCatego2;

      const [product] = this.products;
      return (_product$cachedCatego2 = product.cachedCategoryHierarchy) === null || _product$cachedCatego2 === void 0 ? void 0 : _product$cachedCatego2.selectedParentCategory;
    }

    get selectedTerminalCategory() {
      var _product$cachedCatego3;

      const [product] = this.products;
      return (_product$cachedCatego3 = product.cachedCategoryHierarchy) === null || _product$cachedCatego3 === void 0 ? void 0 : _product$cachedCatego3.selectedTerminalCategory;
    }

    get macroCategories() {
      return _category.default.find({
        isMacro: true,
        sort: 'name'
      });
    }

    get firstProduct() {
      return this.products[0];
    }

    get remainingProductsCount() {
      return this.products.length - 1;
    }

    get isTerminalCategory() {
      return this.selectedMacroCategory && this.selectedMacroCategory.isTerminal || this.selectedParentCategory && this.selectedParentCategory.isTerminal || this.selectedTerminalCategory && this.selectedTerminalCategory.isTerminal;
    }

    get parentCategories() {
      return this.selectedMacroCategory ? _category.default.find({
        isParent: true,
        macro: this.selectedMacroCategory._id,
        sort: 'name'
      }) : Ember.RSVP.resolve([]);
    }

    get terminalCategories() {
      if (Ember.isEmpty(this.selectedParentCategory) || this.selectedParentCategory.isTerminal) {
        return Ember.RSVP.resolve([]);
      }

      return _category.default.find({
        isTerminal: true,
        parent: this.selectedParentCategory._id,
        sort: 'name'
      });
    }

    onChangeMacroCategory(category) {
      this.setProperties({
        selectedMacroCategory: category,
        selectedParentCategory: null,
        selectedTerminalCategory: null
      });
    }

    onChangeParentCategory(category) {
      this.setProperties({
        selectedParentCategory: category,
        selectedTerminalCategory: null
      });
    }

    selectCategory() {
      const category = this.selectedParentCategory.isTerminal ? this.selectedParentCategory : this.selectedTerminalCategory;
      const cachedCategoryHierarchy = {
        selectedMacroCategory: this.selectedMacroCategory,
        selectedParentCategory: this.selectedParentCategory,
        selectedTerminalCategory: this.selectedTerminalCategory
      };
      return this.addCategory(category, cachedCategoryHierarchy);
    }

  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "canSelectParentCategory", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "canSelectTerminalCategory", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "selectedMacroCategory", [_dec3], Object.getOwnPropertyDescriptor(_class.prototype, "selectedMacroCategory"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "selectedParentCategory", [_dec4], Object.getOwnPropertyDescriptor(_class.prototype, "selectedParentCategory"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "selectedTerminalCategory", [_dec5], Object.getOwnPropertyDescriptor(_class.prototype, "selectedTerminalCategory"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "firstProduct", [_dec6], Object.getOwnPropertyDescriptor(_class.prototype, "firstProduct"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "remainingProductsCount", [_dec7], Object.getOwnPropertyDescriptor(_class.prototype, "remainingProductsCount"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "isTerminalCategory", [_dec8], Object.getOwnPropertyDescriptor(_class.prototype, "isTerminalCategory"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "parentCategories", [_dec9], Object.getOwnPropertyDescriptor(_class.prototype, "parentCategories"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "terminalCategories", [_dec10], Object.getOwnPropertyDescriptor(_class.prototype, "terminalCategories"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "onChangeMacroCategory", [_dec11], Object.getOwnPropertyDescriptor(_class.prototype, "onChangeMacroCategory"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "onChangeParentCategory", [_dec12], Object.getOwnPropertyDescriptor(_class.prototype, "onChangeParentCategory"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "selectCategory", [_dec13], Object.getOwnPropertyDescriptor(_class.prototype, "selectCategory"), _class.prototype)), _class));
  _exports.default = CategoryModal;
});