define("flaneur/pods/brand/boutiques/boutique/insights/route", ["exports", "flaneur/models/boutique-restock-recommendation"], function (_exports, _boutiqueRestockRecommendation) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _class, _descriptor, _descriptor2;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  const FALLBACK_TIME = 5000;
  let BrandBoutiquesBoutiqueInsights = (_dec = Ember.inject.service, _dec2 = Ember.inject.service, (_class = class BrandBoutiquesBoutiqueInsights extends Ember.Route {
    constructor(...args) {
      super(...args);

      _initializerDefineProperty(this, "brand", _descriptor, this);

      _initializerDefineProperty(this, "router", _descriptor2, this);

      _defineProperty(this, "queryParams", {
        q: {
          refreshModel: true
        },
        page: {
          refreshModel: true
        },
        limit: {
          refreshModel: true
        },
        stock: {
          refreshModel: true
        }
      });

      _defineProperty(this, "limit_list", [{
        label: '20 per page',
        params: {
          limit: 20
        }
      }, {
        label: '50 per page',
        params: {
          limit: 50
        }
      }, {
        label: '100 per page',
        params: {
          limit: 100
        }
      }]);
    }

    get boutiqueId() {
      return this.brand.currentBoutique._id;
    }

    model({
      page,
      limit,
      q,
      stock
    }) {
      const meta = _boutiqueRestockRecommendation.default.count({
        boutiqueId: this.boutiqueId,
        searchQuery: q,
        stock
      }).then(total => {
        return {
          limitList: this.limit_list,
          selectedLimit,
          total
        };
      });

      const fetchingRecommendations = _boutiqueRestockRecommendation.default.find({
        boutiqueId: this.boutiqueId,
        limit,
        attachRestockInformation: true,
        offset: limit * (page - 1),
        searchQuery: q,
        stock
      });

      const fallback = new Promise(resolve => {
        return setTimeout(resolve, FALLBACK_TIME, 'timeout');
      });
      const recommendations = Promise.race([fetchingRecommendations, fallback]);
      const selectedLimit = this.limit_list.find(({
        params: {
          limit: limitToCheck
        }
      }) => Ember.isEqual(limitToCheck, limit));
      return {
        queryParams: {
          query: q,
          limit,
          page,
          stock
        },
        meta,
        recommendations
      };
    }

  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "brand", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "router", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  })), _class));
  _exports.default = BrandBoutiquesBoutiqueInsights;
});