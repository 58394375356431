define("flaneur/pods/components/input-price/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "L+mNagoq",
    "block": "{\"symbols\":[\"__arg0\",\"@error\",\"@disabled\",\"@value\",\"&attrs\"],\"statements\":[[8,\"input-affixed\",[[16,0,[31,[\"input-price \",[32,0,[\"styleNamespace\"]]]]],[17,5]],[[\"@disabled\",\"@error\",\"@namedBlocksInfo\"],[[32,3],[32,2],[30,[36,4],null,[[\"prefix\",\"default\"],[0,0]]]]],[[\"default\"],[{\"statements\":[[6,[37,0],[[30,[36,3],[[32,1],\"prefix\"],null]],null,[[\"default\",\"else\"],[{\"statements\":[[1,[32,0,[\"currencySymbol\"]]]],\"parameters\":[]},{\"statements\":[[6,[37,0],[[30,[36,3],[[32,1],\"default\"],null]],null,[[\"default\"],[{\"statements\":[[2,\"\\n    \"],[11,\"input\"],[16,0,[31,[\"input \",[30,[36,0],[[32,2],\"input--error\"],null]]]],[16,\"disabled\",[32,3]],[24,\"placeholder\",\"0.00\"],[16,2,[30,[36,1],[[32,4]],null]],[4,[38,2],[\"focusout\",[32,0,[\"onFocusOut\"]]],null],[12],[13],[2,\"\\n  \"]],\"parameters\":[]}]]]],\"parameters\":[]}]]]],\"parameters\":[1]}]]]],\"hasEval\":false,\"upvars\":[\"if\",\"to-money\",\"on\",\"-is-named-block-invocation\",\"hash\"]}",
    "moduleName": "flaneur/pods/components/input-price/template.hbs"
  });

  _exports.default = _default;
});