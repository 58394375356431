define("flaneur/pods/components/orders-overview/component", ["exports", "jquery", "flaneur/mixins/courier-calculator"], function (_exports, _jquery, _courierCalculator) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const ROUTE_ORDERS_INDEX = 'brand.boutiques.boutique.orders.index';

  var _default = Ember.Component.extend(_courierCalculator.default, {
    router: Ember.inject.service(),
    media: Ember.inject.service(),
    brand: Ember.inject.service(),
    classNames: ['orders-overview'],
    classNameBindings: ['showOrdersOverviewMobile:orders-overview--display'],
    boutique: null,
    activeLink: 'brand.boutiques.boutique.orders.stage.active',
    printLink: 'brand.boutiques.boutique.orders.stage.print',
    collectionLink: 'brand.boutiques.boutique.orders.stage.collection',

    refreshAction() {},

    addLoadingAction() {},

    currentRouteName: Ember.computed.alias('router.currentRouteName'),
    ordersToConfirm: Ember.computed.alias('activeOrdersCount'),
    ordersToPrint: Ember.computed.alias('printOrdersCount'),
    hasAnyOrder: Ember.computed.gt('totalOrders', 0),
    isBoutiqueShippingProviderOther: Ember.computed.equal('boutique.shipping_provider', 'other'),
    showOrdersOverviewMobile: Ember.computed.and('media.isLarge', 'showOrdersOverview'),
    currentActiveRouteFullName: Ember.computed('router.currentRoute.{name,params}', function () {
      const {
        currentRoute
      } = this.router;

      if (currentRoute.name === ROUTE_ORDERS_INDEX) {
        return ROUTE_ORDERS_INDEX;
      }

      return `${currentRoute.name}.${currentRoute.params.stage}`;
    }),
    showOrdersOverview: Ember.computed('currentActiveRouteFullName', 'currentRouteName', 'isBoutiqueShippingProviderOther', 'media.isLarge', function () {
      if (this.isBoutiqueShippingProviderOther) {
        return false;
      }

      if (this.media.isLarge) {
        return this.currentActiveRouteFullName === ROUTE_ORDERS_INDEX;
      }

      return true;
    }),
    totalOrders: Ember.computed('totalOrdersToConfirmPrint', 'clickAndCollectCount', function () {
      return this.totalOrdersToConfirmPrint + this.clickAndCollectCount;
    }),
    totalOrdersToConfirmPrint: Ember.computed('ordersToConfirm', 'ordersToPrint', function () {
      return this.ordersToConfirm + this.ordersToPrint;
    }),

    _scrollAnimation() {
      const scrollTop = (0, _jquery.default)('.order-search-box').offset();

      if (!scrollTop) {
        return;
      }

      (0, _jquery.default)('html, body').animate({
        scrollTop: scrollTop.top
      }, 500);
    },

    actions: {
      refresh(route, ordernumbers) {
        this.addLoadingAction();

        if (this.currentActiveRouteFullName === route) {
          this.refreshAction(ordernumbers);
        }

        Ember.run.later(() => this._scrollAnimation(), 1000);
      },

      openHelpCenter() {
        window.open('https://trouvaboutiques.zendesk.com/hc/en-us/articles/360015293217-Your-Christmas-need-to-know-dates');
      }

    }
  });

  _exports.default = _default;
});