define("flaneur/pods/brand/settings/users/new/route", ["exports", "flaneur/models/user"], function (_exports, _user) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    brand: Ember.inject.service(),

    model() {
      return _user.default.create(Ember.getOwner(this).ownerInjection(), {});
    },

    actions: {
      save(user) {
        return user.addToBrand(this.brand._id).then(() => {
          this.notify.show('success', 'You successfully added a user to your boutique!');
          this.transitionTo('brand.settings.users');
        }).catch(() => {
          this.notify.show('error', 'Oops! An error ocurred while adding a new user. Please try again.');
        });
      }

    }
  });

  _exports.default = _default;
});