define("flaneur/pods/components/pages/products/bulk/upload/amend-step/brand-amend-modal/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "v3mbjVgR",
    "block": "{\"symbols\":[],\"statements\":[[8,\"modal-dialog\",[],[[\"@containerClass\",\"@translucentOverlay\",\"@onClose\"],[[31,[[32,0,[\"styleNamespace\"]],\"__modal\"]],true,[32,0,[\"close\"]]]],[[\"default\"],[{\"statements\":[[2,\"\\n  \"],[10,\"div\"],[14,0,\"ember-modal-dialog__header\"],[12],[2,\"\\n    \"],[10,\"h1\"],[12],[2,\"Add brand\"],[13],[2,\"\\n  \"],[13],[2,\"\\n\\n  \"],[11,\"div\"],[24,\"role\",\"button\"],[24,0,\"ember-modal-dialog__close\"],[4,[38,0],[\"click\",[32,0,[\"close\"]]],null],[12],[2,\"\\n    \"],[8,\"fa-icon\",[],[[\"@icon\",\"@prefix\"],[\"times\",\"far\"]],null],[2,\"\\n  \"],[13],[2,\"\\n\\n  \"],[10,\"div\"],[14,0,\"ember-modal-dialog__body\"],[12],[2,\"\\n    \"],[10,\"div\"],[14,0,\"form-input\"],[12],[2,\"\\n      \"],[10,\"label\"],[14,0,\"form-input__label\"],[14,\"for\",\"brand-selector-id\"],[12],[2,\"\\n        Brand name\\n      \"],[13],[2,\"\\n      \"],[10,\"div\"],[14,0,\"form-input__group\"],[12],[2,\"\\n        \"],[8,\"manufacturer-select\",[],[[\"@manufacturer\",\"@onChangeManufacturer\",\"@onCreateManufacturer\"],[[34,1,[\"standard_manufacturer\"]],[32,0,[\"onChangeManufacturer\"]],[30,[36,3],[[30,[36,2],[[35,1,[\"standard_manufacturer\"]]],null]],null]]],null],[2,\"\\n      \"],[13],[2,\"\\n    \"],[13],[2,\"\\n  \"],[13],[2,\"\\n\\n  \"],[10,\"div\"],[14,0,\"ember-modal-dialog__footer\"],[12],[2,\"\\n    \"],[8,\"button\",[],[[\"@theme\",\"@onClick\"],[\"secondary\",[32,0,[\"close\"]]]],[[\"default\"],[{\"statements\":[[2,\"\\n      Cancel\\n    \"]],\"parameters\":[]}]]],[2,\"\\n    \"],[8,\"button\",[],[[\"@disabled\",\"@onClick\"],[[30,[36,4],[[32,0,[\"isBrandFixed\"]]],null],[32,0,[\"selectNewBrand\"]]]],[[\"default\"],[{\"statements\":[[2,\"\\n      Add brand\\n    \"]],\"parameters\":[]}]]],[2,\"\\n  \"],[13],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"on\",\"product\",\"mut\",\"fn\",\"not\"]}",
    "moduleName": "flaneur/pods/components/pages/products/bulk/upload/amend-step/brand-amend-modal/template.hbs"
  });

  _exports.default = _default;
});