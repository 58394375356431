define("flaneur/enums/stripe-card-brand", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    VISA: 'visa',
    MASTERCARD: 'mastercard',
    AMEX: 'amex',
    DISCOVER: 'discover',
    DINERS: 'diners',
    JCB: 'jcb',
    UNIONPAY: 'unionpay',
    UNKNOWN: 'unknown'
  };
  _exports.default = _default;
});