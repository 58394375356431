define("flaneur/pods/zendesk/logout/route", ["exports", "flaneur/models/user"], function (_exports, _user) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    session: Ember.inject.service(),

    model() {
      return this.session.getLoggedIn().then(({
        isLoggedIn,
        user
      }) => {
        if (isLoggedIn) {
          this.session.set('user', _user.default._create(user));
          return this.session.getUserBrand(user);
        }
      }).then(brand => {
        return this.session.set('user.brand', brand ? brand._id : null);
      });
    },

    actions: {
      redirectToRetailPanel() {
        return this.transitionTo('brand.boutiques.index', this.session.get('user.brand'));
      }

    }
  });

  _exports.default = _default;
});