define("flaneur/pods/components/active-orders-header/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "NPmeqMyT",
    "block": "{\"symbols\":[\"item\"],\"statements\":[[10,\"div\"],[14,0,\"active-orders-header\"],[12],[2,\"\\n  \"],[10,\"div\"],[14,0,\"tab-header-container\"],[12],[2,\"\\n\"],[6,[37,4],[[30,[36,3],[[30,[36,3],[[32,0,[\"tabs\"]]],null]],null]],null,[[\"default\"],[{\"statements\":[[2,\"      \"],[8,\"link-to\",[[24,0,\"tab-header-container__tab\"]],[[\"@route\",\"@model\",\"@click\"],[[32,1,[\"route\"]],[32,1,[\"model\"]],[30,[36,0],[[32,0,[\"refresh\"]],[32,1,[\"route\"]]],null]]],[[\"default\"],[{\"statements\":[[2,\"\\n        \"],[8,\"fa-icon\",[[24,0,\"fa\"]],[[\"@icon\",\"@prefix\"],[[32,1,[\"icon\"]],\"far\"]],null],[2,\"\\n        \"],[1,[32,1,[\"label\"]]],[2,\"\\n\\n\"],[6,[37,2],[[30,[36,1],[[32,1,[\"count\"]],0],null]],null,[[\"default\"],[{\"statements\":[[2,\"          \"],[10,\"span\"],[12],[1,[32,1,[\"count\"]]],[13],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"      \"]],\"parameters\":[]}]]],[2,\"\\n      \"],[10,\"p\"],[14,0,\"triangle\"],[12],[13],[2,\"\\n\"]],\"parameters\":[1]}]]],[2,\"  \"],[13],[2,\"\\n\"],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"fn\",\"gt\",\"if\",\"-track-array\",\"each\"]}",
    "moduleName": "flaneur/pods/components/active-orders-header/template.hbs"
  });

  _exports.default = _default;
});