define("flaneur/pods/brand/settings/account/route", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    model() {
      return {
        user: this.session.get('user')
      };
    },

    actions: {
      logout() {
        this.session.logout().then(() => this.transitionTo('login'));
      }

    }
  });

  _exports.default = _default;
});