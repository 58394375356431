define("flaneur/pods/components/boutique-restock-recommendation-table/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "EF0hRtHH",
    "block": "{\"symbols\":[\"recommendation\"],\"statements\":[[10,\"div\"],[15,0,[31,[\"boutique-restock-recommendation-table \",[32,0,[\"styleNamespace\"]]]]],[12],[2,\"\\n\"],[6,[37,2],[[30,[36,1],[[30,[36,1],[[35,0]],null]],null]],null,[[\"default\"],[{\"statements\":[[2,\"    \"],[8,\"boutique-restock-recommendation-table/row\",[],[[\"@recommendation\"],[[32,1]]],null],[2,\"\\n\"]],\"parameters\":[1]}]]],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"filteredRecommendations\",\"-track-array\",\"each\"]}",
    "moduleName": "flaneur/pods/components/boutique-restock-recommendation-table/template.hbs"
  });

  _exports.default = _default;
});