define("flaneur/utils/attempt-to-load-image", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = attempToLoadImage;

  // this is a duplicate of the attempToLoadImage in filestack.js, there hasn't been
  // time to normalise that file so that they share the same code as this is close
  // to release time.
  async function attempToLoadImage(imageData, attemptCount = 1, outerResolve = null, outerReject = null) {
    let promise = new Promise((resolve, reject) => {
      const triggerReject = message => {
        console.error(`${message} (attempt ${attemptCount})`);
        reject(new Error(message, imageData));
      };

      const img = new Image();

      const cleanup = () => {
        clearTimeout(tid);
        img.onload = null;
        img.onerror = null;
      };

      const tid = setTimeout(() => {
        cleanup();
        triggerReject('Image reloading from Imgix reached timeout (20s)');
      }, 20 * 1000);

      img.onload = () => {
        cleanup();

        if (img.height && img.width) {
          if (!imageData.width) {
            imageData.width = img.width;
          }

          if (!imageData.height) {
            imageData.height = img.height;
          }

          resolve(imageData);
          return;
        }

        triggerReject('Unable to reload image from Imgix');
      };

      img.onerror = () => {
        cleanup();
        triggerReject('Unable to reload image from Imgix');
      };

      img.src = imageData.url;
    });

    if (outerResolve) {
      promise = promise.then(outerResolve);
    }

    if (outerReject) {
      promise = promise.catch(outerReject);
    }

    return promise;
  }
});