define("flaneur/services/navigation-history", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Service.extend({
    router: Ember.inject.service(),
    history: [],
    currentRouteName: Ember.computed.alias('history.lastObject'),
    previousRouteName: Ember.computed('history.[]', function () {
      return this.history.get(this.history.length - 2) === undefined ? null : this.history.get(this.history.length - 2);
    }),

    init() {
      this._super(...arguments);

      this.router.on('routeDidChange', ({
        targetName
      }) => this.history.pushObject(targetName));
    }

  });

  _exports.default = _default;
});