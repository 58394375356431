define("flaneur/models/product/validations", ["exports", "flaneur/util/validation/validations"], function (_exports, _validations) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.putValidations = _exports.postValidations = void 0;

  function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); enumerableOnly && (symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols); } return keys; }

  function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = null != arguments[i] ? arguments[i] : {}; i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { _defineProperty(target, key, source[key]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } return target; }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  const commonValidations = {
    name: [(0, _validations.validateByType)('presence', {
      presence: true,
      description: 'Name'
    }), (0, _validations.validateByType)('length', {
      min: 2,
      description: 'Name cannot be blank'
    })],
    'local_price.amount': [(0, _validations.validateByType)('presence', {
      presence: true,
      description: 'Price'
    }), (0, _validations.validateByType)('number', {
      description: 'Price',
      integer: true,
      gte: 100,
      message: 'The price must be at least 1.00'
    })],
    description: [(0, _validations.validateByType)('presence', {
      presence: true,
      description: 'Description'
    }), (0, _validations.validateByType)('length', {
      max: 2000,
      description: 'Description'
    })],
    thumbnail: [(0, _validations.validateByType)('presence', {
      presence: true,
      description: 'Product must have at least one image (thumbnail)',
      message: 'Product must have at least one image (thumbnail)'
    })],
    categories: [(0, _validations.validateByType)('presence', {
      presence: true,
      description: 'All categories',
      message: 'Product must have a category'
    })],
    unbranded: [(0, _validations.validateByType)('inclusion', {
      in: [true, false, null]
    })]
  };

  const postValidations = _objectSpread(_objectSpread({}, commonValidations), {}, {
    'category.isTerminal': [(0, _validations.validateTruism)({
      description: 'Category is a terminal',
      message: 'All category fields must be filled'
    })]
  });

  _exports.postValidations = postValidations;

  const putValidations = _objectSpread({}, commonValidations);

  _exports.putValidations = putValidations;
});