define("flaneur/pods/brand/boutiques/boutique/packaging/products/index/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "VNTAkPZ8",
    "block": "{\"symbols\":[\"@model\"],\"statements\":[[8,\"pages/packaging/products/index\",[],[[\"@products\",\"@categories\",\"@meta\",\"@selectedCategory\",\"@selectedVariantStockStatus\"],[[32,1,[\"products\"]],[32,1,[\"categories\"]],[32,1,[\"meta\"]],[32,1,[\"selectedCategory\"]],[32,1,[\"selectedVariantStockStatus\"]]]],null],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[]}",
    "moduleName": "flaneur/pods/brand/boutiques/boutique/packaging/products/index/template.hbs"
  });

  _exports.default = _default;
});