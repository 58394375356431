define("flaneur/pods/components/stock-line-status/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    classNames: ['stock-line-status'],
    classNameBindings: ['isOutOfStock:stock-line-status--out-of-stock:stock-line-status--in-stock'],
    stockLine: null,
    isOutOfStock: Ember.computed.equal('stockLine.quantity', 0),
    label: Ember.computed('isOutOfStock', function () {
      return this.isOutOfStock ? 'Out of stock' : 'In stock';
    })
  });

  _exports.default = _default;
});