define("flaneur/pods/login/route", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  class LoginRoute extends Ember.Route {}

  _exports.default = LoginRoute;
});