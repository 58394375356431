define("flaneur/pods/zendesk/login/route", ["exports", "flaneur/models/zendesk"], function (_exports, _zendesk) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    session: Ember.inject.service(),
    queryParams: {
      return_to: {
        refreshModel: true
      }
    },

    model(params) {
      return this.session.getLoggedIn().then(({
        isLoggedIn,
        user
      }) => {
        if (!isLoggedIn) {
          return;
        }

        if (!['admin', 'retail'].includes(user.role)) {
          return;
        }

        return _zendesk.default.createAuthenticationToken('zendesk/help/token');
      }).then(token => {
        const url = `https://trouva.zendesk.com/access/jwt?jwt=${token}&return_to=${params.return_to}`;
        window.location.replace(url);
      });
    }

  });

  _exports.default = _default;
});