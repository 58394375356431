define("flaneur/pods/brand/route", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _dec5, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  let BrandRoute = (_dec = Ember.inject.service, _dec2 = Ember.inject.service, _dec3 = Ember.inject.service, _dec4 = Ember.inject.service, _dec5 = Ember.inject.service, (_class = class BrandRoute extends Ember.Route {
    serialize(model) {
      return {
        brand_id: model._id
      };
    }

    constructor() {
      super(...arguments);

      _initializerDefineProperty(this, "brand", _descriptor, this);

      _initializerDefineProperty(this, "media", _descriptor2, this);

      _initializerDefineProperty(this, "notificationsStorage", _descriptor3, this);

      _initializerDefineProperty(this, "pubsub", _descriptor4, this);

      _initializerDefineProperty(this, "router", _descriptor5, this);

      this.brand.set('owner', Ember.getOwner(this).ownerInjection());
      this.router.on('routeDidChange', Ember.run.bind(this, this.toggleSidebar));
      this.pubsub.subscribe('flaneur', 'notification', notification => {
        this.pubsub.addNotification(notification);
        this.notificationsStorage.fetchNotifications({
          boutique: this.brand.currentBoutique,
          skipLoadingState: true
        });
      });
    }

    toggleSidebar() {
      if (!this.media.isXlarge || !this.controller) {
        return;
      }

      this.controller.set('showSidebar', false);
      const element = document.getElementById('overlay');

      if (element) {
        element.style.display = 'none';
      }
    }

    async model({
      brand_id: brandId
    }) {
      try {
        var _boutique$is_curate_b;

        const brand = await this.brand.findById(brandId);
        const {
          currentBoutique: boutique
        } = brand;
        const isBoutiqueCurateBrand = (_boutique$is_curate_b = boutique.is_curate_brand) !== null && _boutique$is_curate_b !== void 0 ? _boutique$is_curate_b : false;

        if (isBoutiqueCurateBrand) {
          return this.transitionTo('services');
        }

        this.notificationsStorage.fetchNotifications({
          boutique
        });
        return brand;
      } catch (error) {
        if (error.message === 'no boutiques') {
          return this.transitionTo('index');
        }

        if (error.status === 401) {
          return this.transitionTo('login');
        }

        if (error.status === 403) {
          return this.transitionTo('limited_access');
        }

        if (error.status === 422) {
          return this.transitionTo('index');
        }
      }
    }

  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "brand", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "media", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "notificationsStorage", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "pubsub", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "router", [_dec5], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  })), _class));
  _exports.default = BrandRoute;
});