define("flaneur/pods/components/confirm-order-items/component", ["exports", "flaneur/pods/components/confirm-order-items/readable-dates", "flaneur/utils/cut-off-time", "date-fns"], function (_exports, _readableDates, _cutOffTime, _dateFns) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); enumerableOnly && (symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols); } return keys; }

  function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = null != arguments[i] ? arguments[i] : {}; i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { _defineProperty(target, key, source[key]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } return target; }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  var _default = Ember.Component.extend({
    groupedReservations: null,
    reservationBoutiques: null,
    productsRecommendations: null,
    boutiqueOpeningHours: Ember.computed('boutique.{from_warehouse,warehouse_oh,oh}', function () {
      const {
        from_warehouse,
        oh,
        warehouse_oh
      } = this.boutique;
      return from_warehouse ? warehouse_oh : oh;
    }),
    oh: Ember.computed('boutique.{holiday_from,holiday_to,isOnHoliday}', 'boutiqueOpeningHours', function () {
      const isOnHoliday = this.boutique.isOnHoliday;
      const boutiqueHolidayTo = this.boutique.holiday_to;
      const startDate = isOnHoliday && boutiqueHolidayTo ? (0, _dateFns.parseISO)(boutiqueHolidayTo) : new Date();
      return this.createOpeningHoursFromRange(startDate, (0, _dateFns.addDays)(startDate, 7));
    }),
    todayLabel: Ember.computed('todayOpenHours.date', function () {
      return (0, _readableDates.formatDates)(this.todayOpenHours.date);
    }),
    todayOpenHours: Ember.computed('oh', function () {
      return this.oh[0];
    }),
    nextOpenDay: Ember.computed('oh', 'todayOpenHours.isOpen', function () {
      const nextOpenDay = this.todayOpenHours.isOpen ? 1 : 0;
      return this.oh.filter(({
        isOpen
      }) => isOpen)[nextOpenDay];
    }),
    nextOpenDayLabel: Ember.computed('nextOpenDay.date', function () {
      return (0, _readableDates.formatDates)(this.nextOpenDay.date);
    }),
    immediatelyReservationsLength: Ember.computed('calculatedReservations.immediately', function () {
      return Object.keys(this.get('calculatedReservations.immediately')).length;
    }),
    todayReservationsLength: Ember.computed('calculatedReservations.today', function () {
      return Object.keys(this.get('calculatedReservations.today')).length;
    }),
    nextOpenDayReservationsLength: Ember.computed('calculatedReservations.nextOpenDay', function () {
      return Object.keys(this.get('calculatedReservations.nextOpenDay')).length;
    }),
    calculatedReservations: Ember.computed('boutique.timezone', 'groupedReservations', 'reservationBoutiques', function () {
      const reservations = {
        immediately: {},
        today: {},
        nextOpenDay: {}
      };
      const reservationsKeys = Object.keys(this.groupedReservations);
      reservationsKeys.forEach(order => {
        const reservationBoutique = this.reservationBoutiques[order][0];
        const selectedUrgencyForReservation = (0, _cutOffTime.getStateFromCutOffTime)(reservationBoutique.boutique_processing_cut_off_time, this.boutique.timezone);
        reservations[selectedUrgencyForReservation][order] = this.groupedReservations[order];
      });
      return reservations;
    }),

    createOpeningHoursFromRange(startDate, lastDate) {
      const oh = [];

      for (; (0, _dateFns.isBefore)(startDate, lastDate); startDate = (0, _dateFns.addDays)(startDate, 1)) {
        const dayOfWeek = (0, _dateFns.format)(startDate, 'iiii');
        const ohForDay = this.boutiqueOpeningHours.find(({
          label
        }) => label === dayOfWeek) || {
          active: true
        };
        const isOnHoliday = this.isWithinHoliday(startDate);

        if (isOnHoliday) {
          Ember.set(ohForDay, 'active', false);
        }

        oh.push(_objectSpread({
          date: (0, _dateFns.toDate)(startDate),
          isOpen: ohForDay.active
        }, ohForDay));
      }

      return oh;
    },

    isWithinHoliday(date) {
      const {
        holiday_from,
        holiday_to
      } = this.boutique;

      if (!holiday_from || !holiday_to) {
        return false;
      }

      if ((0, _dateFns.isSameDay)((0, _dateFns.parseISO)(holiday_from), (0, _dateFns.parseISO)(holiday_to))) {
        return (0, _dateFns.isSameDay)(date, (0, _dateFns.parseISO)(holiday_from));
      }

      return (0, _dateFns.isWithinInterval)(date, {
        start: (0, _dateFns.parseISO)(holiday_from),
        end: (0, _dateFns.parseISO)(holiday_to)
      });
    }

  });

  _exports.default = _default;
});