define("flaneur/pods/limited-access/route", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    session: Ember.inject.service(),

    async model() {
      const session = this.session;
      const {
        isLoggedIn,
        user
      } = await session.getLoggedIn();
      const isRetailer = user.role === 'retail';
      const isAdmin = user.role === 'admin';
      return {
        isLoggedIn,
        isRetailer,
        isAdmin,
        user
      };
    }

  });

  _exports.default = _default;
});