define("flaneur/pods/components/pages/login-page/component", ["exports", "@glimmer/component", "ember-component-css/pod-names", "ember-concurrency"], function (_exports, _component, _podNames, _emberConcurrency) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _dec8, _dec9, _dec10, _dec11, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _descriptor6, _descriptor7, _descriptor8, _descriptor9, _descriptor10;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  let PagesLoginPageComponent = (_dec = Ember.inject.service, _dec2 = Ember.inject.service, _dec3 = Ember.inject.service, _dec4 = Ember.inject.service, _dec5 = Ember._tracked, _dec6 = Ember._tracked, _dec7 = Ember._tracked, _dec8 = Ember._tracked, _dec9 = Ember._tracked, _dec10 = Ember._tracked, _dec11 = Ember._action, (_class = class PagesLoginPageComponent extends _component.default {
    constructor() {
      super(...arguments);

      _initializerDefineProperty(this, "analytics", _descriptor, this);

      _initializerDefineProperty(this, "firebaseService", _descriptor2, this);

      _initializerDefineProperty(this, "router", _descriptor3, this);

      _initializerDefineProperty(this, "session", _descriptor4, this);

      _initializerDefineProperty(this, "flow", _descriptor5, this);

      _initializerDefineProperty(this, "email", _descriptor6, this);

      _initializerDefineProperty(this, "password", _descriptor7, this);

      _initializerDefineProperty(this, "errorMessage", _descriptor8, this);

      _initializerDefineProperty(this, "isWidgetRendered", _descriptor9, this);

      _initializerDefineProperty(this, "isPendingRedirect", _descriptor10, this);

      _defineProperty(this, "firebaseUiAuthContainerId", 'firebaseui-auth-container');

      _defineProperty(this, "styleNamespace", _podNames.default['pages/login-page']);

      _defineProperty(this, "updateIsPendingRedirectTimeout", 3500);

      _defineProperty(this, "redirectToTrouvaTimeout", 10000);

      this.firebaseService.startUi({
        selector: this.firebaseUiAuthContainerSelector,
        onSignInSuccess: ({
          user
        }) => this.onSignInSuccess.perform(user),
        onSignInFailure: error => {
          console.error(error);
        },
        onUiShown: () => {
          this.isWidgetRendered = true;
          setTimeout(() => {
            this.isPendingRedirect = this.firebaseService.ui.isPendingRedirect();
          }, this.updateIsPendingRedirectTimeout);
        }
      });
      this.isPendingRedirect = this.firebaseService.ui.isPendingRedirect();
    }

    get firebaseUiAuthContainerSelector() {
      return `#${this.firebaseUiAuthContainerId}`;
    }

    get isFirebaseUiAuthContainerHidden() {
      return this.isAuthenticating || this.redirectToTrouva.isRunning || this.flow !== 'firebase';
    }

    get isAuthenticating() {
      return this.isPendingRedirect || this.onSignInSuccess.isRunning;
    }

    transitionToBaseRoute() {
      const {
        postLoginTransition,
        user
      } = this.session;

      if (postLoginTransition) {
        return postLoginTransition.retry();
      }

      return this.router.transitionTo('brand', user.brand);
    }

    *onSignInSuccess(user) {
      let idToken;
      let {
        providerId: provider
      } = user.providerData[0] || {};

      try {
        idToken = yield user.getIdToken();
      } catch (error) {
        console.error('error getting firebase ID token');
        return;
      }

      try {
        yield this.session.loginWithFirebase({
          idToken,
          mode: 'firebase',
          provider
        });

        if (!this.session.isLoggedIn) {
          return this.redirectToTrouva.perform();
        }

        this.transitionToBaseRoute();
      } catch (error) {
        console.error('failed to login with firebase', error);
        this.isPendingRedirect = this.firebaseService.ui.isPendingRedirect();
      }
    }

    *redirectToTrouva() {
      yield (0, _emberConcurrency.timeout)(this.redirectToTrouvaTimeout);
      this.router.transitionTo('index');
      yield _emberConcurrency.forever;
    }

    loginWithPassword(event) {
      event.preventDefault();
      this.errorMessage = null;
      return this.session.authenticate(this.email, this.password).then(() => {
        this.analytics.gtm({
          action: 'logged_in'
        });
        this.transitionToBaseRoute();
      }).catch(() => {
        this.errorMessage = 'Your username or password have not been recognised.';
      });
    }

  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "analytics", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "firebaseService", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "router", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "session", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "flow", [_dec5], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return 'firebase';
    }
  }), _descriptor6 = _applyDecoratedDescriptor(_class.prototype, "email", [_dec6], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor7 = _applyDecoratedDescriptor(_class.prototype, "password", [_dec7], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor8 = _applyDecoratedDescriptor(_class.prototype, "errorMessage", [_dec8], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor9 = _applyDecoratedDescriptor(_class.prototype, "isWidgetRendered", [_dec9], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return false;
    }
  }), _descriptor10 = _applyDecoratedDescriptor(_class.prototype, "isPendingRedirect", [_dec10], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return false;
    }
  }), _applyDecoratedDescriptor(_class.prototype, "onSignInSuccess", [_emberConcurrency.task], Object.getOwnPropertyDescriptor(_class.prototype, "onSignInSuccess"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "redirectToTrouva", [_emberConcurrency.task], Object.getOwnPropertyDescriptor(_class.prototype, "redirectToTrouva"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "loginWithPassword", [_dec11], Object.getOwnPropertyDescriptor(_class.prototype, "loginWithPassword"), _class.prototype)), _class));
  _exports.default = PagesLoginPageComponent;
});