define("flaneur/pods/components/boutique-restock-recommendation-scroll/component", ["exports", "@glimmer/component", "flaneur/breakpoints", "ember-component-css/pod-names", "flaneur/models/boutique-restock-recommendation"], function (_exports, _component, _breakpoints, _podNames, _boutiqueRestockRecommendation) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _class, _descriptor, _descriptor2, _descriptor3;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  let BoutiqueRestockRecommendationScrollComponent = (_dec = Ember.inject.service, _dec2 = Ember.inject.service, _dec3 = Ember._tracked, (_class = class BoutiqueRestockRecommendationScrollComponent extends _component.default {
    get styleNamespace() {
      return _podNames.default['boutique-restock-recommendation-scroll'];
    }

    get boutiqueId() {
      return this.brand.currentBoutique._id;
    }

    get filteredRecommendations() {
      return this.recommendations.filter(recommendation => !this.boutiqueRestockInformation.getEditableRestockInformation(recommendation));
    }

    get slidesPerBreakpoint() {
      return [{
        breakpoint: _breakpoints.default.small,
        slidesToShow: 1,
        nextSlidePreviewWidth: 48,
        edgeDividerWidth: 16
      }, {
        breakpoint: _breakpoints.default.large,
        slidesToShow: 2
      }];
    }

    constructor() {
      super(...arguments);

      _initializerDefineProperty(this, "brand", _descriptor, this);

      _initializerDefineProperty(this, "boutiqueRestockInformation", _descriptor2, this);

      _initializerDefineProperty(this, "recommendations", _descriptor3, this);

      this.fetchRecommendations();
    }

    async fetchRecommendations() {
      try {
        this.recommendations = await _boutiqueRestockRecommendation.default.find({
          boutiqueId: this.boutiqueId,
          limit: 9,
          attachRestockInformation: true
        });
      } catch (e) {
        console.error('failed to fetch recommendations:', e);
      }
    }

  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "brand", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "boutiqueRestockInformation", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "recommendations", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return [];
    }
  })), _class));
  _exports.default = BoutiqueRestockRecommendationScrollComponent;
});