define("flaneur/pods/components/reservation-status/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    classNames: ['reservation-status'],
    classNameBindings: ['isError:error', 'isWarning:warning', 'isSuccess:success'],
    tagName: 'span',
    label: '',
    isError: false,
    isWarning: false,
    isSucess: false
  });

  _exports.default = _default;
});