define("flaneur/utils/incoterm", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.allowIncotermCustomization = allowIncotermCustomization;

  function allowIncotermCustomization(shippingPath) {
    return Ember.isNone(shippingPath) || !shippingPath.isIncotermDomestic;
  }
});