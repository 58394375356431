define("flaneur/pods/brand/boutiques/boutique/orders/controller", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    emptyPageItems: [{
      icon: 'stopwatch',
      title: 'To Process',
      text: "Congratulations, you have new orders! All new orders will appear here, once you have confirmed it’s in stock our courier services will be notified. Simply print off the shipping label, package the order and we'll collect it the next working day."
    }, {
      icon: 'store-alt',
      title: 'Click & Collect',
      text: 'We share your boutique location on your page so that customers can pick up their order from you directly in store if they prefer. This is a great way to meet your Trouva customers and encourage extra footfall.'
    }, {
      icon: 'box-up',
      title: 'Returns',
      text: 'Returns can happen, but don’t worry we handle this all for you and we won’t refund the customer until you have reviewed and confirmed the return on this page.'
    }, {
      icon: 'shipping-fast',
      title: 'Completed',
      text: 'Well done, your job is done, our customer operations team will track your order every step of the way and make sure it reaches your customer safely.'
    }],
    hasNoOrders: Ember.computed('model.{clickAndCollectCount,returnOrdersCount,totalNewOrdersCount}', function () {
      const {
        clickAndCollectCount,
        returnOrdersCount,
        totalNewOrdersCount,
        completedOrdersCount
      } = this.model;
      return clickAndCollectCount <= 0 && returnOrdersCount <= 0 && totalNewOrdersCount <= 0 && completedOrdersCount <= 0;
    })
  });

  _exports.default = _default;
});