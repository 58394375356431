define("flaneur/models/curate/brand/index", ["exports", "fetch", "query-string", "flaneur/config/environment", "flaneur/enums/curate-brand-status"], function (_exports, _fetch, _queryString, _environment, _curateBrandStatus) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _class, _descriptor;

  function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); enumerableOnly && (symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols); } return keys; }

  function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = null != arguments[i] ? arguments[i] : {}; i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { _defineProperty(target, key, source[key]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } return target; }

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  const EMPTY_DISCOUNT = 'Not available for this brand.';
  let CurateBrand = (_dec = Ember._tracked, (_class = class CurateBrand {
    constructor(brand) {
      _initializerDefineProperty(this, "status", _descriptor, this);

      const {
        _id,
        slug,
        status,
        name,
        description,
        currency = 'GBP',
        logo,
        images,
        catalogues,
        tags,
        product_count,
        discount_message,
        delivery_info,
        minimum_order_value,
        payment_terms,
        terms_and_conditions_url,
        products_api_version,
        is_retail_price_incl_vat,
        is_wholesale_price_incl_vat,
        lead_time_message
      } = brand;
      const sortedImages = images.sort((a, b) => a.display_order - b.display_order);
      this.id = _id;
      this.slug = slug;
      this.status = status;
      this.name = name;
      this.description = description;
      this.currency = currency;
      this.logo = logo;
      this.images = sortedImages;
      this.catalogues = catalogues;
      this.tags = tags;
      this.product_count = product_count;
      this.discount_message = discount_message;
      this.delivery_info = delivery_info;
      this.minimum_order_value = minimum_order_value;
      this.payment_terms = payment_terms;
      this.terms_and_conditions_url = terms_and_conditions_url;
      this.products_api_version = products_api_version;
      this.is_retail_price_incl_vat = is_retail_price_incl_vat;
      this.is_wholesale_price_incl_vat = is_wholesale_price_incl_vat;
      this.lead_time_message = lead_time_message;
    }

    get hasDiscountMessage() {
      var _this$discount_messag;

      const discountMessage = (_this$discount_messag = this.discount_message) !== null && _this$discount_messag !== void 0 ? _this$discount_messag : '';
      return discountMessage.replace(EMPTY_DISCOUNT, '').trim() !== '';
    }

    get discountMessage() {
      return this.hasDiscountMessage ? this.discount_message : EMPTY_DISCOUNT;
    }

    get isPublished() {
      return this.status === _curateBrandStatus.default.PUBLISHED;
    }

    get isPreview() {
      return this.status === _curateBrandStatus.default.PREVIEW;
    }

    static async find(query = {}) {
      const queryParams = _queryString.default.stringify(_objectSpread({
        populate: 'tags'
      }, query), {
        arrayFormat: 'bracket'
      });

      const url = `${_environment.default.curateApiURL}/api/v1/brands?${queryParams}`;
      const res = await (0, _fetch.default)(url, {
        method: 'GET',
        credentials: 'include',
        headers: {
          'Content-Type': 'application/json; charset=UTF-8'
        },
        cache: false
      });
      const {
        brands = [],
        meta
      } = await res.json();
      return {
        brands: brands.map(brand => new CurateBrand(brand)),
        meta
      };
    }

  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "status", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  })), _class));
  _exports.default = CurateBrand;
});