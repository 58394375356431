define("flaneur/pods/components/oos-alternative/component", ["exports", "jquery", "flaneur/util/escape-regex", "flaneur/models/product"], function (_exports, _jquery, _escapeRegex, _product) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    analytics: Ember.inject.service(),
    brand: Ember.inject.service(),
    // Public API
    reservation: null,
    // Private API
    classNames: ['out-of-stock'],
    query: '',
    outOfStockMessage: '',
    isLoadingProductSearch: false,
    submitting: false,
    submitted: false,
    // NOTE: non computed variables are shared among component instances
    productSearch: Ember.computed(function () {
      return [];
    }),
    selectedAlternatives: Ember.computed(function () {
      return [];
    }),
    // extend products with component specific attributes
    productSearchExtended: Ember.computed('productSearch.@each.alternative_name', 'selectedAlternatives.@each.alternative_name', function () {
      return this.productSearch.map(product => {
        // NOTE: name is unique, but _id is not because we duplicate product for each size
        const alternative = this.selectedAlternatives.find(p => p.alternative_name === product.alternative_name);
        product.set('selected', Ember.isPresent(alternative));
        return product;
      });
    }),

    didInsertElement() {
      this._super(...arguments);

      this.search();
      (0, _jquery.default)('html').addClass('hide-scroll-bar');
    },

    didDestroyElement() {
      this._super(...arguments);

      (0, _jquery.default)('html').removeClass('hide-scroll-bar');
    },

    search(fallback = false) {
      this.set('isLoadingProductSearch', true);
      const query = {
        brand: this.brand._id,
        limit: 20,
        page: 1,
        archived: false
      };

      if (Ember.isPresent(this.query)) {
        query.name = {
          $regex: `${(0, _escapeRegex.default)(this.query)}`,
          $options: 'i'
        };
      } else if (!fallback) {
        query.categories = {
          $in: this.reservation.product.categories
        };
      }

      return _product.default.find(query).then(({
        products = []
      }) => {
        this.set('isLoadingProductSearch', false);
        /*
         * If there is no search query and no product was in stock for the given category,
         * do another search including all categories
         */

        if (!fallback && products.filter(product => product.isInStock).length === 0) {
          return this.search(true);
        }

        this.set('productSearch', this.splitByStockLines(products));
      });
    },

    splitByStockLines(products) {
      return products.filter(product => product._id !== this.reservation.product && product._id !== this.reservation.product._id).reduce((results, product) => {
        // create an independent product for each stock_line
        product.stock_lines.forEach(stockLine => {
          // clone
          const copy = _product.default.create(product); // set unique name


          copy.set('alternative_name', stockLine.size && stockLine.size.label ? `${copy.name} - ${stockLine.size.label}` : copy.name);
          copy.set('selected_size', stockLine);
          copy.set('isInStock', this.isInStock(stockLine));
          results.push(copy);
        });
        return results;
      }, []);
    },

    isInStock(stockLine) {
      return stockLine.quantity > 0 || stockLine.quantity === -1;
    },

    actions: {
      updateQuery(q) {
        this.set('query', q);
        return this.search();
      },

      selectAlternative(product) {
        const alternatives = this.selectedAlternatives;
        alternatives.find(({
          alternative_name
        }) => alternative_name === product.alternative_name) || !product.isInStock ? alternatives.removeObject(product) : alternatives.insertAt(0, product);
      },

      deselect(product) {
        this.selectedAlternatives.removeObject(product);
      },

      outOfStock(reservation) {
        var _this$outOfStockMessa;

        this.set('submitting', true);
        this.analytics.gtm({
          action: 'clicked_mark_as_out_of_stock',
          label: reservation.order._id
        });
        return reservation.markAsOutOfStock({
          alternatives: this.selectedAlternatives.map(({
            _id
          }) => _id),
          message: (_this$outOfStockMessa = this.outOfStockMessage) !== null && _this$outOfStockMessa !== void 0 ? _this$outOfStockMessa : ''
        }).then(() => this.set('submitted', true)).catch(() => this.set('error', false)).finally(() => this.set('submitting', false));
      }

    }
  });

  _exports.default = _default;
});