define("flaneur/models/stock-line/index", ["exports", "flaneur/mixins/request"], function (_exports, _request) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const StockLine = Ember.Object.extend({
    /* Instance methods */
  });
  StockLine.reopenClass(_request.default, {
    /* Class methods */
    find(params = {}) {
      return this.request({
        method: 'GET',
        url: this.apiURL('stock_lines'),
        data: params
      }).then(({
        stock_lines,
        meta
      }) => {
        return {
          stockLines: stock_lines.map(stockLine => StockLine.create(stockLine)),
          meta: Ember.isPresent(meta) ? meta : {
            total: 0,
            page_total: 1,
            page: 1
          }
        };
      });
    }

  });
  var _default = StockLine;
  _exports.default = _default;
});