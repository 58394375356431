define("flaneur/pods/brand/boutiques/boutique/shipments/new/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "QWn1S/Tu",
    "block": "{\"symbols\":[\"@model\"],\"statements\":[[8,\"pages/shipments/new\",[],[[\"@model\",\"@backToHistory\",\"@submitForm\"],[[32,1],[30,[36,0],[\"backToHistory\"],null],[30,[36,0],[\"submitForm\"],null]]],null],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"route-action\"]}",
    "moduleName": "flaneur/pods/brand/boutiques/boutique/shipments/new/template.hbs"
  });

  _exports.default = _default;
});