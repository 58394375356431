define("flaneur/pods/components/pages/settings/component", ["exports", "flaneur/enums/operational-status"], function (_exports, _operationalStatus) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    brand: Ember.inject.service(),
    session: Ember.inject.service(),
    // Public API
    boutique: null,
    // Private API
    classNames: ['page'],
    tabs: Ember.computed('session.user.{isAdmin,isRetailAdmin}', 'brand.currentBoutique.{isOnHoliday,operational_status}', function () {
      const {
        operational_status,
        isOnHoliday
      } = this.brand.currentBoutique;
      const isPaused = operational_status === _operationalStatus.default.PAUSED;
      const isPartial = operational_status === _operationalStatus.default.PARTIAL;
      const {
        isAdmin,
        isRetailAdmin
      } = this.session.user;
      const accountDetails = isAdmin || isRetailAdmin ? {
        label: 'Accounting Details',
        page: 'accounting-details'
      } : null;

      const operationalModeIcon = () => {
        if (isPaused) {
          return {
            name: 'pause-circle',
            prefix: 'fas'
          };
        }

        if (isPartial) {
          return {
            name: 'clock',
            prefix: 'fas'
          };
        }

        return null;
      };

      const holidayModeIcon = () => {
        if (isPaused) {
          return null;
        }

        if (isOnHoliday) {
          return {
            name: 'tree-palm',
            prefix: 'far'
          };
        }

        return null;
      };

      const tabs = [{
        label: 'Boutique Profile',
        page: 'index'
      }, accountDetails, {
        label: 'Order Fulfilment',
        page: 'order-fulfilment'
      }, {
        label: 'Contact Details',
        page: 'contact-details'
      }, {
        label: 'Holiday Mode',
        page: 'holiday-mode',
        icon: holidayModeIcon()
      }, {
        label: 'Operational Status',
        page: 'operational-status',
        icon: operationalModeIcon()
      }];
      return tabs.filter(tab => Ember.isPresent(tab));
    }),
    actions: {
      async onCompleteSetup() {
        if (this.boutique) {
          return this.boutique.save().then(boutique => Ember.setProperties(this.brand.currentBoutique, boutique));
        }

        return new Promise(res => Ember.run.later(res, 1000));
      }

    }
  });

  _exports.default = _default;
});