define("flaneur/pods/components/packaging-banner/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "iB/e+jAA",
    "block": "{\"symbols\":[],\"statements\":[[2,\"  \"],[10,\"div\"],[15,0,[31,[\"packaging-banner \",[32,0,[\"styleNamespace\"]]]]],[12],[2,\"\\n    Enter the code \"],[1,[32,0,[\"freeShippingAmount\",\"code\"]]],[2,\" at checkout to receive free shipping on orders over \"],[1,[32,0,[\"freeShippingAmount\",\"value\"]]],[2,\" (delivery takes 2-3 business days)\\n  \"],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[]}",
    "moduleName": "flaneur/pods/components/packaging-banner/template.hbs"
  });

  _exports.default = _default;
});