define("flaneur/pods/components/gift-wrap-modal/component", ["exports", "@glimmer/component", "ember-component-css/pod-names"], function (_exports, _component, _podNames) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _class;

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  let GiftWrapModalComponent = (_dec = Ember._action, _dec2 = Ember._action, (_class = class GiftWrapModalComponent extends _component.default {
    constructor(...args) {
      super(...args);

      _defineProperty(this, "enabled", false);
    }

    get styleNamespace() {
      return _podNames.default['gift-wrap-modal'];
    }

    continueAndBook() {
      return this.args.confirmAndBookFromGiftModal(this.enabled);
    }

    closeModal() {
      return this.args.closeGiftModal();
    }

  }, (_applyDecoratedDescriptor(_class.prototype, "continueAndBook", [_dec], Object.getOwnPropertyDescriptor(_class.prototype, "continueAndBook"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "closeModal", [_dec2], Object.getOwnPropertyDescriptor(_class.prototype, "closeModal"), _class.prototype)), _class));
  _exports.default = GiftWrapModalComponent;
});