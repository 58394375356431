define("flaneur/pods/brand/boutiques/boutique/terms-and-conditions/route", ["exports", "flaneur/mixins/authorized-route", "flaneur/enums/retail-permission-type"], function (_exports, _authorizedRoute, _retailPermissionType) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_authorizedRoute.default, {
    brand: Ember.inject.service(),
    permission: _retailPermissionType.default.TERMS_AND_CONDITIONS,

    model() {
      return this.brand.currentBoutique;
    }

  });

  _exports.default = _default;
});