define("flaneur/pods/components/pages/curate/index-page/component", ["exports", "@glimmer/component", "ember-component-css/pod-names"], function (_exports, _component, _podNames) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const _excluded = ["selected"];

  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _dec8, _dec9, _dec10, _dec11, _dec12, _dec13, _dec14, _dec15, _dec16, _dec17, _dec18, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _descriptor6, _descriptor7, _descriptor8;

  function _objectWithoutProperties(source, excluded) { if (source == null) return {}; var target = _objectWithoutPropertiesLoose(source, excluded); var key, i; if (Object.getOwnPropertySymbols) { var sourceSymbolKeys = Object.getOwnPropertySymbols(source); for (i = 0; i < sourceSymbolKeys.length; i++) { key = sourceSymbolKeys[i]; if (excluded.indexOf(key) >= 0) continue; if (!Object.prototype.propertyIsEnumerable.call(source, key)) continue; target[key] = source[key]; } } return target; }

  function _objectWithoutPropertiesLoose(source, excluded) { if (source == null) return {}; var target = {}; var sourceKeys = Object.keys(source); var key, i; for (i = 0; i < sourceKeys.length; i++) { key = sourceKeys[i]; if (excluded.indexOf(key) >= 0) continue; target[key] = source[key]; } return target; }

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  let PagesCurateIndexPageComponent = (_dec = Ember.inject.service, _dec2 = Ember.inject.service, _dec3 = Ember.inject.service, _dec4 = Ember.inject.service, _dec5 = Ember._tracked, _dec6 = Ember._tracked, _dec7 = Ember._tracked, _dec8 = Ember._tracked, _dec9 = Ember._action, _dec10 = Ember._action, _dec11 = Ember._action, _dec12 = Ember._action, _dec13 = Ember._action, _dec14 = Ember._action, _dec15 = Ember._action, _dec16 = Ember._action, _dec17 = Ember._action, _dec18 = Ember._action, (_class = class PagesCurateIndexPageComponent extends _component.default {
    constructor() {
      super(...arguments);

      _initializerDefineProperty(this, "analytics", _descriptor, this);

      _initializerDefineProperty(this, "brand", _descriptor2, this);

      _initializerDefineProperty(this, "router", _descriptor3, this);

      _initializerDefineProperty(this, "routerScroll", _descriptor4, this);

      _initializerDefineProperty(this, "tags", _descriptor5, this);

      _initializerDefineProperty(this, "previousSearchQuery", _descriptor6, this);

      _initializerDefineProperty(this, "showBrandReferralModal", _descriptor7, this);

      _initializerDefineProperty(this, "isFiltersVisible", _descriptor8, this);

      _defineProperty(this, "faqItems", [{
        label: 'Who is responsible for customs paperwork?',
        content: 'Most EU brands will handle this paperwork as part of their shipping process to boutiques. Generally speaking, brands will require the boutique to cover any customs charges and handling fees. If otherwise, this will be outlined on the brand’s profile. A small number of brands will require the boutique to arrange their own shipping. For these cases, we will opt to work with QuickShip to ensure a simple and fast delivery. We will always try to give you all the information you need about shipping costs in advance.'
      }, {
        label: 'How can I know if there are any additional handling charges upon delivery?',
        content: 'Handling is not charged separately and will be included in the shipping costs we show at checkout where possible, and will always be known to you ahead of order confirmation. We will always try to give you all the information you need about shipping costs in advance.'
      }, {
        label: 'How do I process VAT payments for products coming outside the UK?',
        content: 'Most of our brands will ship DDP (Delivered Duties Paid). This means that they will pay VAT ahead of time, and include it on your invoice. However some brands may send DDU or otherwise. We aim to include all such information on the brand’s profile.'
      }, {
        label: 'Can I see the delivery charges before I place an order?',
        content: 'We are working to get as clear a breakdown of the shipping costs for each brand listed on the platform before you place your order. This will include cost estimates (where variable by size/weight), or an estimate based on the brand’s minimum order requirements. For brands for which we do not yet have this information, we will send it to you prior to final order confirmation.\n\nIf you haven’t previously ordered from a brand we will set up an account on your behalf. This process will occur when you make your first order with each of the brands available.\n\nIf you have previously ordered from the brand please check the box that states "I already have an account with.." in the order summary. This can help us streamline the order process.'
      }, {
        label: 'Do I need to set up an account with the brand?',
        content: 'If you haven’t previously ordered from a brand we will set up an account on your behalf. This process will occur when you make your first order with each of the brands available.\n\nIf you have previously ordered from the brand please check the box that states "I already have an account with.." in the order summary. This can help us streamline the order process.'
      }, {
        label: 'How do I place an order?',
        content: '1. Browse the brands available on the platform and click into a brand card to see all products available. You can use the category and tag functions to filter down the brands as required, or simply scroll and be inspired!\n2. The process is simple, add products to your basket taking note of the brands pack sizes and minimum order requirements, and then proceed to checkout\n3. Within the checkout page you can amend products and quantities as you wish, before heading through to the final confirmation page.\n4. Once you have confirmed your order, the brand will automatically receive an order notification from you via Curate\n\nKeep an eye on your inbox. We may be in touch on behalf of the brand should there be any out of stock products to offer alternatives. Once the brand confirms the order, we will send along to you a confirmation email with an attached order invoice.'
      }, {
        label: 'How do I pay?',
        content: 'You are responsible for making payments directly to the brand via bank transfer.\n\nOnce the order is confirmed you will receive an invoice from the brand with instructions on how to pay them. We will notify you when this invoice has been created, with a copy of the final invoice.'
      }, {
        label: 'What are the payment terms?',
        content: 'We aim to negotiate 30 day net payment terms as standard for you but this is dependent on the brand.\n\nYou can see the payment terms on offer from each brand at the top of the brand profile. This will also be explained on the invoice.'
      }, {
        label: 'Is there a minimum spend?',
        content: 'This is dependent on the brand. You can find the minimum spend information at the top of the brand profile. We have negotiated no or low minimum order value requirements on first orders where possible.\n\nSome brands may have a minimum order quantity (MOQ). This will typically only apply from the second order.'
      }, {
        label: 'What happens if there’s a problem with my order?',
        content: 'If you have any questions you can contact us by emailing: [curate.support@trouva.com](mailto:curate.support@trouva.com)'
      }]);

      this.setPreviousTag();
      Ember.addListener(this.routerScroll, 'didScroll', this, 'onDidScroll');
    }

    async setPreviousTag() {
      if (!this.args.model.queryParams.tag) {
        return;
      }

      const {
        queryParams: {
          tag
        },
        tags
      } = this.args.model;
      this.previousTag = tags.find(({
        _id
      }) => _id === tag);
    }

    scrollToTop() {
      return document.querySelector('.content-header__title').scrollIntoView({
        block: 'start',
        behavior: 'smooth'
      });
    }

    willDestroy() {
      Ember.removeListener(this.routerScroll, 'didScroll', this, 'onDidScroll');
      super.willDestroy(...arguments);
    }

    onDidScroll() {
      if (!this.routerScroll.preserveScrollPosition) {
        return;
      }

      Ember.set(this.routerScroll, 'preserveScrollPosition', false);
      this.scrollToTop();
    }

    get brandsTaskValue() {
      var _lastSuccessful$value;

      const {
        lastSuccessful
      } = this.args.model.brandsTask.task;
      return (_lastSuccessful$value = lastSuccessful === null || lastSuccessful === void 0 ? void 0 : lastSuccessful.value) !== null && _lastSuccessful$value !== void 0 ? _lastSuccessful$value : {
        brands: [],
        meta: {
          page: 1,
          page_total: 1,
          total: 1
        }
      };
    }

    get meta() {
      return this.brandsTaskValue.meta;
    }

    get brands() {
      return this.brandsTaskValue.brands;
    }

    get searchQuery() {
      return this.args.model.queryParams.query;
    }

    get showLoadingSpinner() {
      const {
        brandsTask
      } = this.args.model;
      const isInitialLoad = brandsTask.isRunning && brandsTask.task.performCount === 1;
      const isLoadingAfterEmptyState = brandsTask.isRunning && this.brands.length === 0;
      return isInitialLoad || isLoadingAfterEmptyState;
    }

    get showEmptyState() {
      return this.brands.length === 0 && !this.showLoadingSpinner;
    }

    get hasSelectedFilter() {
      var _this$selectedTag;

      return ((_this$selectedTag = this.selectedTag) === null || _this$selectedTag === void 0 ? void 0 : _this$selectedTag.slug) !== undefined || this.selectedCategory || Ember.isPresent(this.searchQuery) || this.args.model.queryParams.page > 1;
    }

    get styleNamespace() {
      return _podNames.default['pages/curate/index-page'];
    }

    get selectedTag() {
      const {
        model: {
          tags,
          queryParams: {
            tag
          }
        }
      } = this.args;
      return tags.find(({
        _id
      }) => _id === tag);
    }

    get parentCategory() {
      const hasPreAncestors = this.selectedCategory.ancestors.length > 1;
      return hasPreAncestors ? this.selectedCategory.ancestors[0].name : '';
    }

    get selectedCategory() {
      const {
        categories: hiearchy
      } = this.args.model;

      const _ref = hiearchy !== null && hiearchy !== void 0 ? hiearchy : {},
            {
        selected
      } = _ref,
            tree = _objectWithoutProperties(_ref, _excluded);

      if (!selected) {
        return null;
      }

      const categories = Object.values(tree !== null && tree !== void 0 ? tree : {}).flat();
      return categories.find(({
        _id: id
      }) => id === selected);
    }

    get hasFilterApplied() {
      return this.selectedTag || this.selectedCategory;
    }

    preserveScroll() {
      Ember.set(this.routerScroll, 'preserveScrollPosition', true);
    }

    toggleIsFiltersVisible() {
      this.isFiltersVisible = !this.isFiltersVisible;
    }

    onChangeSearchQuery(searchQuery) {
      const query = Ember.isBlank(searchQuery) ? undefined : searchQuery;

      if (query) {
        this.previousSearchQuery = query;
      }

      this.analytics.gtm({
        action: query ? 'applied_search_by_brand' : 'cleared_search_by_brand',
        label: query ? query : this.previousSearchQuery
      });
      this.preserveScroll();
      this.router.transitionTo({
        queryParams: {
          page: 1,
          query
        }
      });
    }

    onChangeTag(tag) {
      var _this$previousTag;

      this.preserveScroll();

      if (tag.slug) {
        this.previousTag = tag;
      }

      this.analytics.gtm({
        action: tag._id ? 'applied_tag_filter' : 'cleared_tag_filter',
        label: tag._id ? tag.slug : (_this$previousTag = this.previousTag) === null || _this$previousTag === void 0 ? void 0 : _this$previousTag.slug
      });
      this.router.transitionTo({
        queryParams: {
          page: 1,
          tag: tag._id
        }
      });
    }

    onClearCategory(category) {
      this.preserveScroll();
      this.analytics.gtm({
        action: 'cleared_category_filter',
        label: category.name
      });
      this.router.transitionTo({
        queryParams: {
          page: 1,
          category: undefined
        }
      });
    }

    onChangeCategory(category) {
      this.preserveScroll();
      this.router.transitionTo({
        queryParams: {
          page: 1,
          category
        }
      });
    }

    clearFilters() {
      this.preserveScroll();
      this.router.transitionTo({
        queryParams: {
          page: 1,
          tag: undefined,
          query: undefined,
          category: undefined
        }
      });
    }

    trackFaqToggle({
      key
    }) {
      if (!key) {
        return;
      }

      this.analytics.gtm({
        action: 'curate_opened_FAQ',
        label: key
      });
    }

    trackMyOrders() {
      this.analytics.gtm({
        action: 'curate_viewed_my_curate_orders',
        label: this.brand.currentBoutique.id
      });
    }

    toggleBrandReferralModal() {
      this.analytics.gtm({
        action: this.showBrandReferralModal ? 'closed_referral_modal' : 'clicked_suggest_brand',
        label: this.brand.currentBoutique.name
      });
      this.showBrandReferralModal = !this.showBrandReferralModal;
    }

    async onChangePage(page) {
      await this.router.transitionTo({
        queryParams: {
          page,
          preserveScrollPosition: true
        }
      });
      this.scrollToTop();
    }

  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "analytics", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "brand", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "router", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "routerScroll", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "tags", [_dec5], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor6 = _applyDecoratedDescriptor(_class.prototype, "previousSearchQuery", [_dec6], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor7 = _applyDecoratedDescriptor(_class.prototype, "showBrandReferralModal", [_dec7], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor8 = _applyDecoratedDescriptor(_class.prototype, "isFiltersVisible", [_dec8], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return true;
    }
  }), _applyDecoratedDescriptor(_class.prototype, "toggleIsFiltersVisible", [_dec9], Object.getOwnPropertyDescriptor(_class.prototype, "toggleIsFiltersVisible"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "onChangeSearchQuery", [_dec10], Object.getOwnPropertyDescriptor(_class.prototype, "onChangeSearchQuery"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "onChangeTag", [_dec11], Object.getOwnPropertyDescriptor(_class.prototype, "onChangeTag"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "onClearCategory", [_dec12], Object.getOwnPropertyDescriptor(_class.prototype, "onClearCategory"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "onChangeCategory", [_dec13], Object.getOwnPropertyDescriptor(_class.prototype, "onChangeCategory"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "clearFilters", [_dec14], Object.getOwnPropertyDescriptor(_class.prototype, "clearFilters"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "trackFaqToggle", [_dec15], Object.getOwnPropertyDescriptor(_class.prototype, "trackFaqToggle"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "trackMyOrders", [_dec16], Object.getOwnPropertyDescriptor(_class.prototype, "trackMyOrders"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "toggleBrandReferralModal", [_dec17], Object.getOwnPropertyDescriptor(_class.prototype, "toggleBrandReferralModal"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "onChangePage", [_dec18], Object.getOwnPropertyDescriptor(_class.prototype, "onChangePage"), _class.prototype)), _class));
  _exports.default = PagesCurateIndexPageComponent;
});