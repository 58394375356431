define("flaneur/pods/components/pages/settings/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "H9i0j6pV",
    "block": "{\"symbols\":[\"tab\",\"&default\"],\"statements\":[[8,\"boutique-setup-progress\",[],[[\"@boutique\",\"@onComplete\"],[[34,2],[30,[36,3],[[32,0],\"onCompleteSetup\"],null]]],null],[2,\"\\n\\n\"],[10,\"div\"],[14,0,\"heading\"],[12],[2,\"\\n  \"],[10,\"div\"],[14,0,\"heading__title\"],[12],[2,\"\\n    \"],[10,\"h1\"],[12],[2,\"Boutique settings\"],[13],[2,\"\\n  \"],[13],[2,\"\\n\"],[13],[2,\"\\n\\n\"],[10,\"div\"],[14,0,\"tabs\"],[12],[2,\"\\n\"],[6,[37,6],[[30,[36,5],[[30,[36,5],[[35,4]],null]],null]],null,[[\"default\"],[{\"statements\":[[2,\"    \"],[8,\"link-to\",[[24,0,\"tabs__tab\"]],[[\"@route\",\"@activeClass\"],[[30,[36,0],[\"brand.boutiques.boutique.settings.\",[32,1,[\"page\"]]],null],\"tabs__tab--selected\"]],[[\"default\"],[{\"statements\":[[2,\"\\n      \"],[10,\"div\"],[15,0,[31,[\"tabs__label \",[30,[36,1],[[32,1,[\"icon\"]],\"tabs__label--bigger\"],null]]]],[12],[1,[32,1,[\"label\"]]],[13],[2,\"\\n\"],[6,[37,1],[[32,1,[\"icon\"]]],null,[[\"default\"],[{\"statements\":[[2,\"        \"],[8,\"fa-icon\",[[24,0,\"tabs__icon\"]],[[\"@icon\",\"@prefix\"],[[32,1,[\"icon\",\"name\"]],[32,1,[\"icon\",\"prefix\"]]]],null],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"    \"]],\"parameters\":[]}]]],[2,\"\\n\"]],\"parameters\":[1]}]]],[13],[2,\"\\n\\n\"],[18,2,null],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"concat\",\"if\",\"boutique\",\"action\",\"tabs\",\"-track-array\",\"each\"]}",
    "moduleName": "flaneur/pods/components/pages/settings/template.hbs"
  });

  _exports.default = _default;
});