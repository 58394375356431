define("flaneur/services/notifications-storage", ["exports", "flaneur/utils/local-storage", "flaneur/models/notification", "date-fns"], function (_exports, _localStorage, _notification, _dateFns) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _dec5, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  let NotificationsStorageService = (_dec = Ember.inject.service, _dec2 = Ember._tracked, _dec3 = Ember._tracked, _dec4 = Ember._tracked, _dec5 = Ember._tracked, (_class = class NotificationsStorageService extends Ember.Service {
    constructor() {
      super(...arguments);

      _initializerDefineProperty(this, "brand", _descriptor, this);

      _initializerDefineProperty(this, "cachedNotifications", _descriptor2, this);

      _initializerDefineProperty(this, "isLoadingNotifications", _descriptor3, this);

      _initializerDefineProperty(this, "refreshIntervalId", _descriptor4, this);

      _initializerDefineProperty(this, "readNotifications", _descriptor5, this);

      const readNotifications = _localStorage.default.getItem('readNotifications');

      this.readNotifications = readNotifications ? JSON.parse(readNotifications) : this.readNotifications;
    }

    markNotificationAsRead(notification) {
      if (!notification.isNew) {
        return;
      }

      const allReadNotifications = [...this.readNotifications, parseInt(notification._id, 10)];
      const uniqueReadNotifications = [...new Set(allReadNotifications)];
      notification.isNew = false;
      this.readNotifications = uniqueReadNotifications;

      _localStorage.default.setItem('readNotifications', JSON.stringify(this.readNotifications));
    }

    async fetchNotifications({
      skipLoadingState = false
    }) {
      if (!skipLoadingState) {
        this.isLoadingNotifications = true;
      }

      const {
        notifications
      } = await _notification.default.find();
      notifications.forEach(notification => {
        const notificationId = parseInt(notification._id, 10);
        notification.isNew = !this.readNotifications.includes(notificationId);
      });
      this.cachedNotifications = this.filterNotifications(notifications);
      this.isLoadingNotifications = false;

      if (this.refreshIntervalId) {
        clearInterval(this.refreshIntervalId);
      }

      const refreshIntervalId = setInterval(() => {
        this.cachedNotifications = this.filterNotifications(notifications);
      }, 3600000
      /* Every hour */
      );
      this.refreshIntervalId = refreshIntervalId;
    }

    filterNotifications(notifications) {
      return notifications.filter(notification => this.filterByBoutique(notification, this.brand.currentBoutique)).filter(notification => this.filterBySchedule(notification));
    }

    isIncludedInFilter(filter = [], value) {
      return filter.length === 0 || filter.includes(value);
    }

    isSomeInFilter(filter = [], values) {
      return filter.length === 0 || values.some(service => filter.includes(service));
    }

    filterByBoutique(notification, boutique) {
      const {
        boutiques,
        countries,
        zones,
        tiers,
        b2b_services,
        promotions,
        feed_boutiques = []
      } = notification.filters;
      const {
        _id,
        tier,
        registered_address_country: {
          _id: country,
          zone
        },
        b2bServices,
        promotions: boutiquePromotions = [],
        isFeedBoutique
      } = boutique;
      const promotionNames = boutiquePromotions.map(({
        name
      }) => name);
      return this.isIncludedInFilter(boutiques, _id) && this.isIncludedInFilter(countries, country) && this.isIncludedInFilter(zones, zone) && this.isIncludedInFilter(tiers, tier) && this.isIncludedInFilter(feed_boutiques, isFeedBoutique) && this.isSomeInFilter(b2b_services, b2bServices) && this.isSomeInFilter(promotions, promotionNames);
    }

    filterBySchedule({
      schedule
    }) {
      const {
        start_date: startDate,
        end_date: endDate
      } = schedule;

      if (!startDate && !endDate) {
        return true;
      }

      if (!endDate) {
        return (0, _dateFns.isAfter)(new Date(), (0, _dateFns.parseISO)(startDate));
      }

      if (!startDate) {
        return (0, _dateFns.isBefore)(new Date(), (0, _dateFns.parseISO)(endDate));
      }

      return (0, _dateFns.isWithinInterval)(new Date(), {
        start: (0, _dateFns.parseISO)(startDate),
        end: (0, _dateFns.parseISO)(endDate)
      });
    }

  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "brand", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "cachedNotifications", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return [];
    }
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "isLoadingNotifications", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return false;
    }
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "refreshIntervalId", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "readNotifications", [_dec5], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return [];
    }
  })), _class));
  _exports.default = NotificationsStorageService;
});