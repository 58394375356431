define("flaneur/services/filestack", ["exports", "filestack-js", "flaneur/config/environment", "flaneur/utils/get-imgix-url"], function (_exports, _filestackJs, _environment, _getImgixUrl) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const _excluded = ["maxFileSize"];

  function _objectWithoutProperties(source, excluded) { if (source == null) return {}; var target = _objectWithoutPropertiesLoose(source, excluded); var key, i; if (Object.getOwnPropertySymbols) { var sourceSymbolKeys = Object.getOwnPropertySymbols(source); for (i = 0; i < sourceSymbolKeys.length; i++) { key = sourceSymbolKeys[i]; if (excluded.indexOf(key) >= 0) continue; if (!Object.prototype.propertyIsEnumerable.call(source, key)) continue; target[key] = source[key]; } } return target; }

  function _objectWithoutPropertiesLoose(source, excluded) { if (source == null) return {}; var target = {}; var sourceKeys = Object.keys(source); var key, i; for (i = 0; i < sourceKeys.length; i++) { key = sourceKeys[i]; if (excluded.indexOf(key) >= 0) continue; target[key] = source[key]; } return target; }

  function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); enumerableOnly && (symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols); } return keys; }

  function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = null != arguments[i] ? arguments[i] : {}; i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { _defineProperty(target, key, source[key]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } return target; }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  const delay = ms => new Promise(resolve => setTimeout(resolve, ms));

  class ImageUploadError extends Error {
    constructor(message, response) {
      super(message);
      this.response = response;
    }

    toString() {
      return `${this.message}: ${JSON.stringify(this.response)}`;
    }

  }

  class FilestackService extends Ember.Service {
    constructor() {
      super(...arguments);
      this.client = _filestackJs.default.init(_environment.default.filestack.apiKey);
      this.client.on('upload.error', this.uploadError.bind(this));
      this.pickerResolve = null;
      this.pickerReject = null;
    }

    attempToLoadImage(imageData, attemptCount = 1, outerResolve = null, outerReject = null) {
      let promise = new Promise((resolve, reject) => {
        const triggerReject = message => {
          console.error(`${message} (attempt ${attemptCount})`);
          reject(new ImageUploadError(message, imageData));
        };

        const img = new Image();

        const cleanup = () => {
          clearTimeout(tid);
          img.onload = null;
          img.onerror = null;
        };

        const tid = setTimeout(() => {
          cleanup();
          triggerReject('Image reloading from Imgix reached timeout (20s)');
        }, 20 * 1000);

        img.onload = () => {
          cleanup();

          if (img.height && img.width) {
            if (!imageData.width) {
              imageData.width = img.width;
            }

            if (!imageData.height) {
              imageData.height = img.height;
            }

            resolve(imageData);
            return;
          }

          triggerReject('Unable to reload image from Imgix');
        };

        img.onerror = () => {
          cleanup();
          triggerReject('Unable to reload image from Imgix');
        };

        img.src = imageData.url;
      });

      if (outerResolve) {
        promise = promise.then(outerResolve);
      }

      if (outerReject) {
        promise = promise.catch(outerReject);
      }

      return promise;
    }

    uploadError(event) {
      console.error('An upload error occurred:', event);

      if (this.pickerReject) {
        this.pickerReject(new Error('Failed to upload any files'));
        this.pickerReject = null;
      }
    }

    getStorageOptions(mixin = {}) {
      const {
        filestack: filestackConfig
      } = _environment.default;

      const storageOptions = _objectSpread(_objectSpread(_objectSpread(_objectSpread(_objectSpread({}, filestackConfig.path ? {
        path: filestackConfig.path
      } : {}), filestackConfig.container ? {
        container: filestackConfig.container
      } : {}), filestackConfig.location ? {
        location: filestackConfig.location
      } : {}), filestackConfig.region ? {
        region: filestackConfig.region
      } : {}), mixin);

      return storageOptions;
    }

    openPicker(optionsOverride = {}, namingMeta = {}) {
      return new Promise((resolve, reject) => {
        // because filestack can trigger errors outside of the promise
        this.pickerResolve = resolve;
        this.pickerReject = reject;
        const storeTo = this.getStorageOptions();

        const {
          maxFileSize
        } = optionsOverride,
              supportedOverrides = _objectWithoutProperties(optionsOverride, _excluded);

        const options = _objectSpread({
          onUploadDone: async result => {
            var _result$filesUploaded, _result$filesFailed;

            this.pickerResolve = null;
            this.pickerReject = null;
            const filesUploaded = [];
            const filesFailed = []; // whilst filestack may say things have uploaded we need to
            // convert each URL to an imgix URL, and verify we can load

            if ((_result$filesUploaded = result.filesUploaded) !== null && _result$filesUploaded !== void 0 && _result$filesUploaded.length) {
              /* eslint-disable prettier/prettier */
              const results = await Promise.allSettled(result.filesUploaded.map(res => this.onUploadResultsPerImage(res, {
                destFilename: res.key,
                isPathAlreadyIncluded: true
              })));
              /* eslint-enable prettier/prettier */

              results.forEach(result => {
                if (result.status === 'fulfilled') {
                  filesUploaded.push(result.value);
                } else {
                  filesFailed.push(result.reason);
                }
              });
            }

            if ((_result$filesFailed = result.filesFailed) !== null && _result$filesFailed !== void 0 && _result$filesFailed.length) {
              filesFailed.push(...result.filesFailed);
            }

            resolve({
              filesUploaded,
              filesFailed
            });
          },
          storeTo,
          // stop filestack prepending random string to path
          disableStorageKey: true,
          accept: ['image/*'],
          fromSources: ['local_file_system'],
          onFileSelected: file => {
            if (maxFileSize && file.size > maxFileSize) {
              return Promise.reject(new Error('File is too large, select something smaller than 5Mb'));
            } // Override the filename


            file.name = this.generateFileName(file, namingMeta);
            return Promise.resolve(file);
          },
          maxFiles: 1,
          uploadInBackground: false
        }, supportedOverrides);

        this.client.picker(options).open();
      });
    }

    onUploadResultsPerImage(res, {
      destFilename,
      isPathAlreadyIncluded = false
    }) {
      /* eslint-disable prettier/prettier */
      return new Promise((resolve, reject) => {
        if (!destFilename) {
          console.error(JSON.stringify({
            res,
            destFilename
          }));
          reject(new Error('Missing destination filename'));
        }

        if (!res.url || !res.filename || !res.mimetype || !res.size) {
          console.error(JSON.stringify({
            res,
            destFilename
          }));
          reject(new Error('Invalid file response'));
        }

        if (res.status !== 'Stored') {
          console.error(JSON.stringify({
            res,
            destFilename
          }));
          reject(new Error('Failed to upload file'));
        }

        const imgixUrl = (0, _getImgixUrl.default)(destFilename, {
          isPathAlreadyIncluded
        });
        const response = {
          url: imgixUrl,
          fsUrl: res.url,
          filename: destFilename,
          mimetype: res.mimetype,
          size: res.size,
          status: res.status
        }; // first attempt, this can resolve straight away

        return this.attempToLoadImage(response, 0, resolve).catch(async () => {
          let isResolved = false;
          let resolvedValue = null;

          const doNothing = () => {};

          const markResolved = v => {
            isResolved = true;
            resolvedValue = v;
          }; // try over a period of 3 seconds to load the image
          // this gives us a chance each second to succeed
          // if we reach the end however...


          for (let i = 1; i < 4; i++) {
            await delay(1000);
            await this.attempToLoadImage(response, i, markResolved, doNothing);

            if (isResolved) {
              resolve(resolvedValue);
              return resolvedValue;
            }
          } // we try one last time to load, at this point we
          // we have tried 5 times (0 -> 4) and we end the promise
          // one way or another


          await delay(1000);
          await this.attempToLoadImage(response, 4, resolve, reject);
        });
      });
    }

    randomString(length = 16) {
      let result = '';

      while (result.length < length) {
        const chunk = Math.random().toString(36).substring(4) || 'X';
        result += chunk;
      }

      return result.substring(0, length);
    }

    generateFileName(originalFile, namingMeta = {}) {
      const whichName = originalFile.filename || originalFile.name;
      const extension = String(whichName).split('.').pop() || '';
      const {
        folderBase,
        filenameBase,
        useRandomSuffix
      } = namingMeta;
      const randomSuffix = useRandomSuffix ? this.randomString() : '';
      const prefix = filenameBase || this.randomString();
      const folder = folderBase || 'unknown';
      const name = `${folder}/${prefix}-${randomSuffix}`;
      return extension ? `${name}.${extension}` : name;
    }

    uploadFile(file, namingMeta = {}, onProgress = null) {
      const token = {};
      const uploadOptions = {
        onProgress: progress => {
          if (onProgress) {
            onProgress(progress.totalPercent);
          }
        }
      };
      const filename = this.generateFileName(file, namingMeta);
      const storageOptions = this.getStorageOptions({
        filename,
        // stop filestack prepending random string to path
        disableStorageKey: true
      });
      return this.client.upload(file, uploadOptions, storageOptions, token).then(res => this.onUploadResultsPerImage(res, {
        destFilename: filename
      })).catch(error => {
        console.error(`Failed to upload file: ${error}`);
        throw error;
      });
    }

  }

  _exports.default = FilestackService;
});