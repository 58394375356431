define("flaneur/models/country", ["exports", "flaneur/mixins/request"], function (_exports, _request) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const Country = Ember.Object.extend(_request.default, {});
  Country.reopenClass(_request.default, {
    find(data = {}) {
      return this.request({
        method: 'GET',
        url: this.apiURL('countries'),
        data
      }).then(res => {
        return res.countries.map(country => this.create(country));
      });
    },

    findById(id) {
      return this.request({
        method: 'GET',
        url: this.apiURL(`countries/${id}`)
      }).then(({
        countries
      }) => {
        return Ember.isEmpty(countries) ? null : this.create(countries[0]);
      });
    }

  });
  var _default = Country;
  _exports.default = _default;
});