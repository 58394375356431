define("flaneur/pods/components/form-flows/shipment/progress/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "7YRyf1xS",
    "block": "{\"symbols\":[],\"statements\":[[8,\"progress-bar\",[],[[\"@steps\"],[[32,0,[\"steps\"]]]],null],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[]}",
    "moduleName": "flaneur/pods/components/form-flows/shipment/progress/template.hbs"
  });

  _exports.default = _default;
});