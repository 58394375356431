define("flaneur/pods/components/tracking-modal/component", ["exports", "flaneur/util/default-to"], function (_exports, _defaultTo) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const DEFAULT_DROPDOWN_PLACEHOLDER = 'Select a shipping provider';
  const COURIER_DONT_KNOW = "I don't know";
  const COURIER_OTHER = 'Other';

  var _default = Ember.Component.extend({
    analytics: Ember.inject.service(),

    updateReservationBoutique() {},

    onTrackingModalCompleted() {},

    onTrackingModalCancel() {},

    courierList: (0, _defaultTo.default)(['Royal Mail (UK)', 'DPD', 'Parcel Force', 'DHL', 'Hermes/MyHermes', 'UPS', COURIER_OTHER, COURIER_DONT_KNOW]),
    selectedCourier: DEFAULT_DROPDOWN_PLACEHOLDER,
    isOtherSelected: false,
    showNoCourierSelectedError: false,
    showTrackingInputError: false,
    showCourierInputError: false,
    showTrackingRadios: false,
    showTrackingInput: false,
    showMainScreen: true,
    dontKnowSelected: false,
    noTrackingCode: true,
    isTrackingRadioChecked: false,
    manuallyEnteredCourier: '',
    manuallyEnteredTrackingCode: '',
    // computes the class for the courier dropdown - error or no error
    computedCourierDropdownClass: Ember.computed('showNoCourierSelectedError', function () {
      if (!this.showNoCourierSelectedError) {
        return 'form-control error-border in-modal-dropdown';
      } else {
        return 'form-control in-modal-dropdown';
      }
    }),
    // computes the class for the tracking input - error or no error
    computedTrackingInputClass: Ember.computed('showTrackingInputError', function () {
      if (this.showTrackingInputError) {
        return 'form-control error-border';
      } else {
        return 'form-control';
      }
    }),
    // computes the class for the courier input - error or no error
    computedCourierInputClass: Ember.computed('showCourierInputError', function () {
      if (this.showCourierInputError) {
        return 'form-control error-border';
      } else {
        return 'form-control';
      }
    }),
    actions: {
      //reset the states of all the variables to default
      clean(instance) {
        instance.setProperties({
          isOtherSelected: false,
          showNoCourierSelectedError: false,
          showTrackingInputError: false,
          showCourierInputError: false,
          showTrackingRadios: false,
          showTrackingInput: false,
          showMainScreen: true,
          dontKnowSelected: false,
          noTrackingCode: true,
          isTrackingRadioChecked: false,
          manuallyEnteredCourier: '',
          manuallyEnteredTrackingCode: ''
        });
      },

      //logic to deal with courier selection on the dropdown
      selectCourier(option) {
        this.actions.clean(this);
        this.set('selectedCourier', option);

        if (this.selectedCourier === COURIER_DONT_KNOW) {
          this.setProperties({
            dontKnowSelected: true,
            noTrackingCode: false
          });
        } else if (this.selectedCourier === COURIER_OTHER) {
          this.set('isOtherSelected', true);
        } else {
          this.set('showTrackingRadios', true);
        }
      },

      //logic for pressing confirm button
      onConfirm() {
        if (this.selectedCourier === DEFAULT_DROPDOWN_PLACEHOLDER) {
          this.set('showNoCourierSelectedError', true);
        } else if (this.showTrackingInput && this.manuallyEnteredTrackingCode.length === 0) {
          this.setProperties({
            noTrackingCode: true,
            showTrackingInputError: true
          });
        } else if (this.selectedCourier === COURIER_OTHER && this.manuallyEnteredTrackingCode.length === 0 && this.isTrackingRadioChecked) {
          this.set('showCourierInputError', true);
        } else if (!this.showTrackingInput || this.dontKnowSelected) {
          if (this.selectedCourier === COURIER_OTHER) {
            this.set('reservationBoutique.0.other_shipping_carrier', this.manuallyEnteredCourier);
          } else {
            this.set('reservationBoutique.0.other_shipping_carrier', this.selectedCourier);
          }

          this.set('reservationBoutique.0.other_tracking_info', this.manuallyEnteredTrackingCode);
          this.updateReservationBoutique(this.get('reservationBoutique.0'));
          this.setProperties({
            showMainScreen: false,
            showMissingText: true
          });
        } else if (this.selectedCourier != DEFAULT_DROPDOWN_PLACEHOLDER && this.manuallyEnteredTrackingCode.length > 0) {
          this.setProperties({
            showMainScreen: false,
            showSuccessScreen: true
          });

          if (this.selectedCourier != COURIER_OTHER) {
            this.set('reservationBoutique.0.other_shipping_carrier', this.selectedCourier);
          } else {
            this.set('reservationBoutique.0.other_shipping_carrier', this.manuallyEnteredCourier);
          }

          this.set('reservationBoutique.0.other_tracking_info', this.manuallyEnteredTrackingCode);
          this.updateReservationBoutique(this.get('reservationBoutique.0'));
          this.analytics.gtm({
            action: 'added_tracking_code',
            label: this.get('reservationBoutique.0.order._id')
          });
        }
      },

      //listens to inputs on the courier input box
      onManuallyEnterCourier(value) {
        this.set('manuallyEnteredCourier', value);
        this.analytics.gtm({
          action: 'clicked_mark_as_returned',
          label: this.get('reservationBoutique.0.order._id')
        });

        if (!this.manuallyEnteredCourier.length > 0) {
          this.set('showTrackingRadios', false);
          return;
        }

        this.setProperties({
          showCourierInputError: false,
          showTrackingRadios: true
        });
      },

      //listens to inputs on the tracking input box
      onManuallyEnterTracking(value) {
        this.set('manuallyEnteredTrackingCode', value);
      },

      //when tracking radio 'yes' is selected
      onTrackingRadioYes() {
        this.setProperties({
          isTrackingRadioChecked: true,
          showTrackingInput: true
        });
      },

      //when tracking radio 'no' is selected
      onTrackingRadioNo() {
        this.setProperties({
          isTrackingRadioChecked: false,
          showTrackingInput: false,
          noTrackingCode: true
        });
      }

    }
  });

  _exports.default = _default;
});