define("flaneur/utils/file-size", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.getReadableFileSize = getReadableFileSize;
  _exports.getFileExtension = getFileExtension;
  const ONE_MEGABYTE = 1024000; // in bytes

  function getReadableFileSize(file) {
    const factor = 1024;

    if (file.size < ONE_MEGABYTE) {
      return `${Math.ceil(file.size / factor)} Kb`;
    }

    return `${Math.ceil(file.size / (factor * 1000))} Mb`;
  }

  function getFileExtension(file = {}) {
    var _file$name;

    return (_file$name = file.name) === null || _file$name === void 0 ? void 0 : _file$name.split('.').pop();
  }
});