define("flaneur/pods/components/form-flows/shipment/order-details/validations", ["exports", "flaneur/util/validation/validations", "flaneur/util/validation", "flaneur/utils/price"], function (_exports, _validations, _validation, _price) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.quote = quote;
  _exports.validateItem = validateItem;
  _exports.validateItems = validateItems;
  _exports.details = details;

  function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); enumerableOnly && (symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols); } return keys; }

  function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = null != arguments[i] ? arguments[i] : {}; i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { _defineProperty(target, key, source[key]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } return target; }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function validatePrice({
    disabled
  }) {
    return disabled ? true : [(0, _validations.validatePresence)({
      presence: true,
      message: 'Enter a price'
    }), (0, _validations.validateNumber)({
      integer: true,
      gt: 0,
      allowString: true,
      description: 'Price'
    })];
  }

  function quote(shipment) {
    return (0, _validation.default)(shipment, {
      'delivery_address.country': (0, _validations.validatePresence)({
        presence: true,
        message: "Country can't be blank"
      }),
      'package.width': [(0, _validations.validatePresence)({
        presence: true,
        message: 'Enter a width'
      }), (0, _validations.validateNumber)({
        integer: true,
        gt: 0,
        allowString: true,
        description: 'Width'
      })],
      'package.height': [(0, _validations.validatePresence)({
        presence: true,
        message: 'Enter a height'
      }), (0, _validations.validateNumber)({
        integer: true,
        gt: 0,
        allowString: true,
        description: 'Height'
      })],
      'package.length': [(0, _validations.validatePresence)({
        presence: true,
        message: 'Enter a length'
      }), (0, _validations.validateNumber)({
        integer: true,
        gt: 0,
        allowString: true,
        description: 'Length'
      })],
      'package.weight': [(0, _validations.validatePresence)({
        presence: true,
        message: 'Enter a weight'
      }), (0, _validations.validateNumber)({
        positive: true,
        allowString: true,
        message: 'Weight must be greater than 0Kg'
      }), (0, _validations.validateDecimals)({
        maxDecimals: 2,
        message: 'Weight is invalid'
      })]
    });
  }

  function validateItem(item, {
    disablePriceExclVat = false,
    disablePriceInclVat = false
  } = {}) {
    return (0, _validation.default)(item, {
      name: (0, _validations.validatePresence)({
        presence: true,
        message: 'Enter a name'
      }),
      quantity: [(0, _validations.validatePresence)({
        presence: true,
        message: 'Enter a quantity'
      }), (0, _validations.validateNumber)({
        gt: 0,
        allowString: true,
        description: 'Quantity'
      }), (0, _validations.validateNumber)({
        integer: true,
        allowString: true,
        message: 'Quantity must be a whole number'
      })],
      priceInclVat: validatePrice({
        disabled: disablePriceInclVat
      }),
      priceExclVat: validatePrice({
        disabled: disablePriceExclVat
      })
    });
  }

  function validateItems(items, options) {
    const itemErrors = items.reduce((prev, item, index) => {
      const validationResult = validateItem(item, options);

      if (validationResult !== true) {
        prev[index] = validationResult;
      }

      return prev;
    }, {});

    if (Object.getOwnPropertyNames(itemErrors).length > 0) {
      return {
        items: itemErrors
      };
    } else {
      return true;
    }
  }

  function details() {
    const {
      allowIncotermCustomization,
      items
    } = this.shipment;
    const itemsValidations = validateItems(items, {
      disablePriceExclVat: !allowIncotermCustomization,
      disablePriceInclVat: allowIncotermCustomization
    });
    const orderIdValidations = (0, _validation.default)(this.shipment, {
      order_id: (0, _validations.validatePresence)({
        presence: true,
        message: 'Enter an order ID'
      })
    });
    const hasInsurance = Ember.isPresent(this.shipment.insurance);
    const maximumInsurancePrice = this.shipment.getMaximumInsurancePrice(this.boutique.currency);
    const insuranceValidations = hasInsurance ? (0, _validation.default)(this.shipment, {
      'insurance.amount': [(0, _validations.validatePresence)({
        presence: true,
        message: 'Enter a value'
      }), (0, _validations.validateNumber)({
        gt: 0,
        message: 'Enter a value'
      }), (0, _validations.validateNumber)({
        lte: this.shipment.totalAmount,
        message: 'Value must not exceed total value of package'
      }), (0, _validations.validateNumber)({
        lte: maximumInsurancePrice.amount,
        message: `Value must not exceed a maximum of ${(0, _price.formatPrice)(maximumInsurancePrice)}`
      })],
      accepts_insurance_terms: (0, _validations.validateInclusion)({
        in: [true],
        message: 'Please accept the T&Cs'
      })
    }) : {};
    const hasItemsErrors = Object.keys(itemsValidations).length > 0;
    const hasOrderIdErrors = Object.keys(orderIdValidations).length > 0;
    const hasInsuranceErrors = Object.keys(insuranceValidations).length > 0;

    if (!hasItemsErrors && !hasOrderIdErrors && !hasInsuranceErrors) {
      return true;
    }

    return _objectSpread(_objectSpread(_objectSpread({}, itemsValidations), orderIdValidations), insuranceValidations);
  }
});