define("flaneur/pods/components/tooltip/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "LPXl37Jh",
    "block": "{\"symbols\":[\"&attrs\",\"&default\"],\"statements\":[[11,\"div\"],[16,0,[31,[\"tooltip \",[30,[36,0],[[32,0,[\"isPositionTop\"]],\"tooltip--top\"],null],\" \",[30,[36,0],[[32,0,[\"isClickable\"]],\"tooltip--clickable\"],null],\" \",[32,0,[\"styleNamespace\"]]]]],[17,1],[12],[2,\"\\n  \"],[10,\"div\"],[14,0,\"tooltip__context\"],[12],[2,\"\\n    \"],[18,2,[[30,[36,2],null,[[\"context\"],[[30,[36,1],[\"empty-template\"],null]]]]]],[2,\"\\n  \"],[13],[2,\"\\n\\n  \"],[10,\"div\"],[14,0,\"tooltip__body\"],[12],[2,\"\\n    \"],[18,2,[[30,[36,2],null,[[\"body\"],[[30,[36,1],[\"empty-template\"],null]]]]]],[2,\"\\n  \"],[13],[2,\"\\n\"],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"if\",\"component\",\"hash\"]}",
    "moduleName": "flaneur/pods/components/tooltip/template.hbs"
  });

  _exports.default = _default;
});