define("flaneur/pods/components/nav-bar-notification/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "f+Lw0NtM",
    "block": "{\"symbols\":[\"&default\",\"@notification\",\"@namedBlocksInfo\"],\"statements\":[[10,\"div\"],[15,0,[31,[\"notification-card \",[32,0,[\"styleNamespace\"]]]]],[12],[2,\"\\n  \"],[11,\"div\"],[24,0,\"notification-card__content\"],[4,[38,1],[\"click\",[32,0,[\"click\"]]],null],[12],[2,\"\\n    \"],[10,\"h3\"],[12],[2,\"\\n\"],[6,[37,2],[[32,2,[\"isNew\"]]],null,[[\"default\"],[{\"statements\":[[2,\"        \"],[10,\"div\"],[14,0,\"notification-card__content--new\"],[12],[13],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"      \"],[1,[32,2,[\"title\"]]],[2,\"\\n    \"],[13],[2,\"\\n\\n    \"],[10,\"p\"],[14,0,\"notification-card__content-body\"],[12],[2,\"\\n      \"],[8,\"marked\",[],[[\"@markdown\"],[[32,2,[\"body\"]]]],null],[2,\"\\n    \"],[13],[2,\"\\n    \"],[10,\"p\"],[14,0,\"notification-card__content-date\"],[12],[2,\"\\n      \"],[1,[30,[36,3],[[32,2,[\"date\"]],\"PP\"],null]],[2,\"\\n    \"],[13],[2,\"\\n\\n\"],[6,[37,2],[[30,[36,4],[[32,3],\"action\",false],null]],null,[[\"default\"],[{\"statements\":[[2,\"      \"],[10,\"div\"],[14,0,\"notification-card__content__action\"],[12],[2,\"\\n        \"],[18,1,[[30,[36,0],[\"action\"],null]]],[2,\"\\n      \"],[13],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"  \"],[13],[2,\"\\n\"],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"-named-block-invocation\",\"on\",\"if\",\"date-format\",\"-has-block\"]}",
    "moduleName": "flaneur/pods/components/nav-bar-notification/template.hbs"
  });

  _exports.default = _default;
});