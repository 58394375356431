define("flaneur/pods/components/print-order-items/component", ["exports", "date-fns"], function (_exports, _dateFns) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    classNames: ['order-print'],
    formatDate: '',
    dateFormat: 'dd-MM-yyyy',

    close() {},

    inStock() {},

    requestedManualLabel() {},

    bookShipment() {},

    updateReservationBoutique() {},

    reservationsByEstimatedCollectionDate: Ember.computed('dateFormat', 'groupedReservations', 'reservationBoutiques', function () {
      const sortedReservationsByDate = [];
      const orderIds = Object.keys(this.groupedReservations);
      orderIds.forEach(orderId => {
        let [{
          estimated_collection_date: estimatedCollectionDate
        }] = this.reservationBoutiques[orderId];
        const reservations = this.groupedReservations[orderId];
        estimatedCollectionDate = (0, _dateFns.format)((0, _dateFns.parseISO)(estimatedCollectionDate), this.dateFormat);

        if (!sortedReservationsByDate[estimatedCollectionDate]) {
          sortedReservationsByDate[estimatedCollectionDate] = {};
        }

        sortedReservationsByDate[estimatedCollectionDate][orderId] = reservations;
      });
      return sortedReservationsByDate;
    }),
    actions: {
      toReadableDate(date) {
        const now = new Date();
        const parsedDate = (0, _dateFns.parse)(date, this.dateFormat, now);
        const diffInDays = (0, _dateFns.differenceInDays)(parsedDate, (0, _dateFns.startOfDay)(now));

        if (diffInDays === 0) {
          return 'to be picked up <span>today</span>';
        }

        if (diffInDays === -1) {
          return 'should have been picked up <span>yesterday</span>';
        }

        if (diffInDays === 1) {
          return 'to be picked up <span>tomorrow</span>';
        }

        if (diffInDays < 1) {
          return `should have been picked up <span>${Math.abs(diffInDays)} days ago on ${(0, _dateFns.format)(parsedDate, 'PPP')}</span>`;
        }

        return `to be picked up in <span>${diffInDays} days on ${(0, _dateFns.format)(parsedDate, 'PPP')}</span>`;
      }

    }
  });

  _exports.default = _default;
});