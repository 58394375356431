define("flaneur/enums/courier-booking-error-reason", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    DROPSHIP: 'dropship-delivery-tag-exists'
  };
  _exports.default = _default;
});