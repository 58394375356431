define("flaneur/pods/components/terms-and-conditions-notification/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "MSJ4CXWu",
    "block": "{\"symbols\":[\"__arg0\"],\"statements\":[[6,[37,1],[[32,0,[\"shouldShowNotification\"]]],null,[[\"default\"],[{\"statements\":[[2,\"  \"],[8,\"notification\",[],[[\"@onDismiss\",\"@namedBlocksInfo\"],[[32,0,[\"dismiss\"]],[30,[36,2],null,[[\"title\",\"body\",\"actions\"],[0,0,0]]]]],[[\"default\"],[{\"statements\":[[6,[37,1],[[30,[36,0],[[32,1],\"title\"],null]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"Update to Terms & Conditions\"]],\"parameters\":[]},{\"statements\":[[6,[37,1],[[30,[36,0],[[32,1],\"body\"],null]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"We've updated your T&Cs. Please check and accept the changes.\"]],\"parameters\":[]},{\"statements\":[[6,[37,1],[[30,[36,0],[[32,1],\"actions\"],null]],null,[[\"default\"],[{\"statements\":[[2,\"\\n      \"],[8,\"button\",[],[[\"@onClick\"],[[32,0,[\"goToTerms\"]]]],[[\"default\"],[{\"statements\":[[2,\"\\n        Go to Terms and Conditions\\n      \"]],\"parameters\":[]}]]],[2,\"\\n    \"]],\"parameters\":[]}]]]],\"parameters\":[]}]]]],\"parameters\":[]}]]]],\"parameters\":[1]}]]],[2,\"\\n\"]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"-is-named-block-invocation\",\"if\",\"hash\"]}",
    "moduleName": "flaneur/pods/components/terms-and-conditions-notification/template.hbs"
  });

  _exports.default = _default;
});