define("flaneur/pods/components/pages/users/index/component", ["exports", "flaneur/enums/retail-role-type", "flaneur/enums/retail-permission-type"], function (_exports, _retailRoleType, _retailPermissionType) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    brand: Ember.inject.service(),
    notify: Ember.inject.service(),
    session: Ember.inject.service(),
    media: Ember.inject.service(),
    classNames: ['page'],
    columns: ['Email', 'Role', 'Actions'],
    retailRoles: ['admin', 'user'],
    retailRolesDescriptions: {
      admin: 'Access to everything in the Retail Panel',
      user: 'Access to everything in the Retail Panel \nexcept T&Cs and Statements'
    },
    changedUsers: [],
    userToRemove: null,
    sessionUserEmail: Ember.computed.readOnly('session.user.email'),
    canChangeRole: Ember.computed.or('session.user.isAdmin', 'session.user.isRetailAdmin'),
    actions: {
      onChangeUserRetailRole(user, retailRole) {
        const retail_permissions = retailRole === _retailRoleType.default.ADMIN ? [_retailPermissionType.default.ALL] : [];
        const permissions = retailRole === _retailRoleType.default.ADMIN ? ['retail_management'] : [];
        Ember.set(user, 'retail_permissions', retail_permissions);
        Ember.set(user, 'permissions', permissions);
        this.changedUsers.addObject(user);
      },

      save() {
        return Ember.RSVP.allSettled([this.changedUsers.map(user => user.save())]).then(() => this.notify.show('success', 'User(s) successfully updated')).catch(() => this.notify.show('error', 'Oops! An error ocurred while updating your user(s). Please try again.'));
      },

      showConfirmationModal(user) {
        this.set('userToRemove', user);
      },

      hideConfirmationModal() {
        this.set('userToRemove', null);
      },

      removeUser() {
        const users = this.brand.users.rejectBy('user', this.userToRemove._id);

        if (users.length === 0) {
          return this.notify.show('error', 'You must have at least one user.');
        }

        this.brand.set('users', users);
        return this.brand.save().then(() => {
          this.users.removeObject(this.userToRemove);
          this.set('userToRemove', null);
          this.notify.show('success', 'You successfully removed a user');
        }).catch(() => {
          this.notify.show('error', 'Error while removing the user');
        });
      }

    }
  });

  _exports.default = _default;
});