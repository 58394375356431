define("flaneur/pods/components/order-status/component", ["exports", "date-fns"], function (_exports, _dateFns) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const STATUSES = {
    CONFIRMED: {
      success: 'Confirmed',
      pending: 'Confirmed'
    },
    LABEL_PRINTED: {
      success: 'Label Printed',
      pending: 'Label Printed'
    },
    RECEIPT_PRINTED: {
      success: 'Receipt Printed',
      pending: 'Receipt Printed'
    },
    CUSTOMS_FORM_PRINTED: {
      success: 'Customs Invoice Printed',
      pending: 'Customs Invoice Printed'
    },
    COLLECTED: {
      success: 'Picked up',
      pending: 'Picked up'
    },
    TRACKING_CODE: {
      success: 'Tracking Provided',
      pending: 'Tracking Provided'
    }
  };

  var _default = Ember.Component.extend({
    classNames: ['order-status'],
    dateFormat: 'dd-MM-yyyy',
    currentState: null,
    order: null,
    reservationBoutique: null,
    reservationLength: null,
    successLength: null,
    failLength: null,
    activeLength: null,
    isActive: Ember.computed.equal('currentState', 'active'),
    isPrint: Ember.computed.equal('currentState', 'print'),
    isCollection: Ember.computed.equal('currentState', 'collection'),
    isClickAndCollect: Ember.computed.equal('currentState', 'click-and-collect'),
    isReturn: Ember.computed.equal('currentState', 'return'),
    isCompleted: Ember.computed.equal('currentState', 'completed'),
    printedReceipt: Ember.computed.alias('reservationBoutique.0.printed_receipt'),
    printedShippingLabel: Ember.computed.alias('reservationBoutique.0.printed_shipping_label'),
    printedCustomsForm: Ember.computed.alias('reservationBoutique.0.printed_customs_form'),
    markedAsCollected: Ember.computed.alias('reservationBoutique.0.marked_as_collected'),
    markAsReturned: Ember.computed.alias('reservationBoutique.0.mark_as_returned'),
    arrangedCourier: Ember.computed.alias('reservationBoutique.0.arranged_courier'),
    isReservationBoutiqueShippingProviderOther: Ember.computed.equal('reservationBoutique.0.shipping_provider', 'other'),
    otherTrackingInfo: Ember.computed.alias('reservationBoutique.0.other_tracking_info'),
    hasAnyActiveLengthReservation: Ember.computed.gt('activeLength', 0),
    hasCustomsForm: Ember.computed.alias('shipment.hasCustomsForm'),
    tabs: Ember.computed('arrangedCourier', 'currentState', 'failLength', 'hasAnyActiveLengthReservation', 'isActive', 'isCollection', 'isCompleted', 'isOrderClickAndCollect', 'isPrint', 'isReservationBoutiqueShippingProviderOther', 'isReturn', 'markAsReturned', 'markedAsCollected', 'otherTrackingInfo', 'printedCustomsForm', 'printedReceipt', 'printedShippingLabel', 'reservationBoutique.0.status', function () {
      let tabs = [];

      if (this.isReturn) {
        return;
      }

      if (this.isCompleted) {
        return this._createCompletedTabs();
      }

      if (this.isReservationBoutiqueShippingProviderOther && this.isOrderClickAndCollect) {
        return [...this._createActiveTabs(), ...this._createPrintTabs()];
      }

      if (this.isReservationBoutiqueShippingProviderOther && !this.isOrderClickAndCollect) {
        return [...this._createActiveTabs(), ...this._createPrintTabs(), ...this._createTrackingCodeTabs()];
      }

      if (this.isActive || this.isPrint || this.isCollection) {
        tabs = [...tabs, ...this._createActiveTabs()];
      }

      if (this.isPrint || this.isCollection) {
        tabs = [...tabs, ...this._createPrintTabs()];
      }

      if (this.isCollection) {
        tabs = [...tabs, ...this._createCollectionTabs()];
      }

      return tabs;
    }),

    _createActiveTabs() {
      if (this.isReservationBoutiqueShippingProviderOther) {
        return [this._createTab(STATUSES.CONFIRMED, !this.hasAnyActiveLengthReservation)];
      }

      if (this.failLength === this.reservationsLength) {
        return [{
          label: 'Fail',
          status: 'error'
        }];
      }

      if (this.isActive && !this.arrangedCourier) {
        return [this._createTab(STATUSES.CONFIRMED, false)];
      }

      return [this._createTab(STATUSES.CONFIRMED, true)];
    },

    _createPrintTabs() {
      const {
        printedReceipt,
        printedShippingLabel,
        printedCustomsForm
      } = this;

      if (this.isReservationBoutiqueShippingProviderOther) {
        return [this._createTab(STATUSES.RECEIPT_PRINTED, printedReceipt)];
      }

      const status = [this._createTab(STATUSES.RECEIPT_PRINTED, printedReceipt), this._createTab(STATUSES.LABEL_PRINTED, printedShippingLabel)];

      if (this.hasCustomsForm) {
        status.push(this._createTab(STATUSES.CUSTOMS_FORM_PRINTED, printedCustomsForm));
      }

      return status;
    },

    _createCollectionTabs() {
      const {
        markedAsCollected
      } = this;
      return [this._createTab(STATUSES.COLLECTED, markedAsCollected)];
    },

    _createTrackingCodeTabs() {
      const {
        otherTrackingInfo
      } = this;
      return [this._createTab(STATUSES.TRACKING_CODE, !!otherTrackingInfo)];
    },

    _createCompletedTabs() {
      const {
        successLength,
        reservationsLength
      } = this;
      const markedAsCollectedAt = this.get('reservationBoutique.0.marked_as_collected_at');
      const markAsCollectAtCopy = markedAsCollectedAt ? `at ${(0, _dateFns.format)((0, _dateFns.parseISO)(markedAsCollectedAt), this.dateFormat)}` : '';
      let tab = {
        label: `Picked up ${markAsCollectAtCopy}`,
        status: 'active'
      };

      if (reservationsLength !== successLength) {
        tab = successLength === 0 ? {
          label: 'Fail',
          status: 'error'
        } : {
          label: 'Partial Success',
          status: 'warning'
        };
      }

      return [tab];
    },

    _createTab(label, success) {
      return {
        label: label[success ? 'success' : 'pending'],
        status: success ? 'active' : 'inactive'
      };
    }

  });

  _exports.default = _default;
});