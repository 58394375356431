define("flaneur/models/statement-eu-vat/index", ["exports", "fetch", "query-string", "flaneur/config/environment"], function (_exports, _fetch, _queryString, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); enumerableOnly && (symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols); } return keys; }

  function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = null != arguments[i] ? arguments[i] : {}; i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { _defineProperty(target, key, source[key]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } return target; }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  class StatementEuVat {
    constructor({
      _id,
      created_at,
      period_from,
      period_to,
      statement_number,
      vat_total
    }) {
      this._id = _id;
      this.created_at = new Date(created_at);
      this.period_from = new Date(period_from);
      this.period_to = new Date(period_to);
      this.statement_number = statement_number;
      this.vat_total = vat_total;
    }

    get url() {
      return `${_environment.default.apiURL}/api/v2/statements_eu_vat/${this._id}/download`;
    }

    static async find(query = {}) {
      const queryParams = _queryString.default.stringify(_objectSpread({
        sort: '-created_at'
      }, query));

      const url = `${_environment.default.apiURL}/api/v2/statements_eu_vat?${queryParams}`;
      const res = await (0, _fetch.default)(url, {
        method: 'GET',
        credentials: 'include',
        cache: false
      });

      if (!res.ok) {
        throw new Error('error finding statements-eu-vat');
      }

      try {
        const {
          statements_eu_vat
        } = await res.json();
        return {
          statementsEuVat: statements_eu_vat.map(statementEuVat => new StatementEuVat(statementEuVat))
        };
      } catch (error) {
        throw new Error('error finding statements-eu-vat');
      }
    }

  }

  _exports.default = StatementEuVat;
});