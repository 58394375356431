define("flaneur/pods/components/boutique-restock-information-modal/product/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "7E1MMvCP",
    "block": "{\"symbols\":[\"@recommendation\",\"@origin\",\"@isLoading\"],\"statements\":[[6,[37,1],[[30,[36,3],[[32,3]],null]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"  \"],[10,\"div\"],[15,0,[31,[\"boutique-restock-information-product \",[30,[36,1],[[32,2],[30,[36,0],[\"boutique-restock-information-product--\",[32,2]],null]],null],\" \",[32,0,[\"styleNamespace\"]]]]],[12],[2,\"\\n    \"],[10,\"img\"],[15,\"src\",[30,[36,2],[[32,1,[\"product\",\"thumbnailUrl\"]],\"c_fill,f_auto,w_80,h_80,q_auto\"],null]],[15,\"alt\",[32,1,[\"product\",\"name\"]]],[12],[13],[2,\"\\n    \"],[10,\"div\"],[14,0,\"details\"],[12],[2,\"\\n      \"],[10,\"div\"],[12],[2,\"\\n\"],[6,[37,1],[[32,1,[\"tag\"]]],null,[[\"default\"],[{\"statements\":[[2,\"          \"],[8,\"tag\",[],[[\"@theme\"],[[32,1,[\"tag\",\"theme\"]]]],[[\"default\"],[{\"statements\":[[1,[32,1,[\"tag\",\"text\"]]]],\"parameters\":[]}]]],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"      \"],[13],[2,\"\\n      \"],[10,\"p\"],[14,0,\"details__manufacturer\"],[12],[2,\"\\n        \"],[1,[32,1,[\"standardManufacturer\",\"name\"]]],[2,\"\\n      \"],[13],[2,\"\\n      \"],[10,\"p\"],[14,0,\"details__name\"],[12],[1,[32,1,[\"product\",\"name\"]]],[13],[2,\"\\n      \"],[10,\"p\"],[14,0,\"details__price\"],[12],[2,\"\\n        \"],[8,\"price\",[],[[\"@price\",\"@salesPrice\",\"@isOnSale\"],[[32,1,[\"product\",\"localPrice\"]],[32,1,[\"product\",\"localSalesPrice\"]],[32,0,[\"isOnSale\"]]]],null],[2,\"\\n      \"],[13],[2,\"\\n\\n      \"],[8,\"boutique-restock-recommendation-stock-status\",[],[[\"@recommendation\"],[[32,1]]],null],[2,\"\\n    \"],[13],[2,\"\\n  \"],[13],[2,\"\\n\"]],\"parameters\":[]},{\"statements\":[[2,\"  \"],[10,\"div\"],[15,0,[31,[\"boutique-restock-information-product boutique-restock-information-product--loading \",[32,0,[\"styleNamespace\"]]]]],[12],[13],[2,\"\\n\"]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"concat\",\"if\",\"cloudinary-image\",\"not\"]}",
    "moduleName": "flaneur/pods/components/boutique-restock-information-modal/product/template.hbs"
  });

  _exports.default = _default;
});