define("flaneur/pods/components/form-flows/shipment/component", ["exports", "flaneur/pods/components/form-flows/_base/component", "flaneur/pods/components/form-flows/shipment/order-details/validations", "flaneur/pods/components/form-flows/shipment/customer-details/validations", "flaneur/pods/components/form-flows/shipment/pickup-details/validations", "ember-concurrency"], function (_exports, _component, _validations, _validations2, _validations3, _emberConcurrency) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _component.default.extend({
    analytics: Ember.inject.service(),
    media: Ember.inject.service(),
    notify: Ember.inject.service(),
    classNames: ['form-flows--shipment'],
    baseComponentPath: 'form-flows/shipment',
    shipment: null,
    countries: null,
    boutique: null,
    boutiqueCollectionAddress: null,
    shippingPath: {},
    currentStepIndex: 0,
    isBackToHistoryEnabled: Ember.computed.reads('shipment.exceedsMaxUpsBillableWeight'),
    steps: Ember.computed('countries.@each._id', 'shipment._isNewAddress', 'shippingPath', function () {
      const {
        shipment,
        boutique,
        countries,
        boutiqueCollectionAddress
      } = this;

      const quoteValidations = _validations.default.quote.bind(null, shipment);

      const detailsValidations = Ember.run.bind(this, _validations.default.details);

      const addressValidations = _validations2.default.validations.bind(null, shipment);

      const collectionAddressValidations = _validations3.default.address.bind(null, shipment);

      return [{
        index: 0,
        step: 'quote',
        validate: quoteValidations,
        countries,
        boutique
      }, {
        index: 1,
        step: 'customer-details',
        validate: addressValidations,
        countries,
        boutique
      }, {
        index: 2,
        step: 'pickup-details',
        subStep: 'location',
        validate: collectionAddressValidations,
        countries,
        boutiqueCollectionAddress
      }, shipment._isNewAddress ? {
        index: 2,
        step: 'pickup-details',
        subStep: 'times'
      } : null, {
        index: 3,
        step: 'order-details',
        validate: detailsValidations
      }, {
        index: 4,
        step: 'book-courier',
        countries,
        boutique
      }].filter(Boolean);
    }),
    isBookCourierOrOpeningHoursStep: Ember.computed('currentStep.{step,subStep}', function () {
      const {
        step,
        subStep
      } = this.currentStep;
      const isBookCourierStep = step === 'book-courier';
      const isOpeningHoursStep = step === 'pickup-details' && subStep === 'times';
      return isBookCourierStep || isOpeningHoursStep;
    }),
    submitFormTask: (0, _emberConcurrency.task)(function* () {
      this.analytics.gtm({
        action: 'clicked_book_shipment',
        label: this.shipment.order_id
      });
      yield this.submitForm(this.shipment).then(shipments => {
        const [shipment] = shipments;
        this.analytics.gtm({
          action: 'book_shipment_success',
          label: shipment._id
        });
      }).catch(err => {
        const {
          responseJSON: {
            errors
          }
        } = err;
        const defaultMessage = 'Sorry, we could not book this courier. Please contact Trouva to assist with this.';
        const errorMessage = errors && errors[0] && errors[0].detail ? errors[0].detail : defaultMessage;
        this.notify.show('error', errorMessage, 5000);
        this.analytics.gtm({
          action: 'book_shipment_failed',
          label: this.shipment.order_id
        });
      });
    }),

    onSuccessfulNextStep() {
      const hasSubStep = Ember.isPresent(this.currentStep.subStep);
      const label = !hasSubStep ? this.currentStep.step : `${this.currentStep.step}_${this.currentStep.subStep}`;
      this.analytics.gtm({
        action: 'quick_ship_complete_step',
        label
      });
      const scrollable = this.media.isLarge ? window : document.getElementById('outlet-container');
      scrollable === null || scrollable === void 0 ? void 0 : scrollable.scrollTo({
        top: 0
      });
    },

    actions: {
      setShippingPath(shippingPath) {
        this.set('shippingPath', shippingPath);
      },

      backToHistory() {
        this.analytics.gtm({
          action: 'tried_to_book_shipment_over_max_weight'
        });
        return this.backToHistory();
      }

    }
  });

  _exports.default = _default;
});