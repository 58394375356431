define("flaneur/util/validation/validations", ["exports", "ember-validators", "flaneur/util/validation/messages", "flaneur/util/validation/date"], function (_exports, _emberValidators, _messages, _date) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.validateByType = validateByType;
  _exports.validateDecimals = validateDecimals;
  _exports.validateTruism = validateTruism;
  _exports.validateLength = validateLength;
  _exports.validateNumber = validateNumber;
  _exports.validatePresence = validatePresence;
  _exports.validateInclusion = validateInclusion;
  _exports.validateFormat = validateFormat;
  _exports.validateExclusion = validateExclusion;
  _exports.validateDate = validateDate;
  _exports.validateConfirmation = validateConfirmation;
  _exports.validateCollection = validateCollection;

  /**
   * This module includes higher order functions that each returns an 'ember-validators' validator.
   * For documentation on the options provided, look at the documentation of 'ember-validators'
   * https://offirgolan.github.io/ember-validators/
   *
   * OPTIONAL global options available:
   * - {string} description:  Description of the field. Ex: "Order ID"
   * - {string} message:      Message to show when validation fails. Ex: "'{value}' is not a valid value for {description} field".
   */
  function validateByType(type, options = {}) {
    return (model, property) => {
      const value = Ember.get(model, property);
      const result = (0, _emberValidators.validate)(type, value, options, model, property);
      return result === true ? true : (0, _messages.buildMessage)(property, result);
    };
  } // Validate the correct number of decimals


  function validateDecimals(options = {}) {
    return (model, property) => {
      const value = Ember.get(model, property);
      const decimals = `${value}`.split('.')[1];

      if (!decimals || decimals.length <= options.maxDecimals) {
        return true;
      }

      return (0, _messages.buildMessage)(property, {
        context: options,
        value
      });
    };
  }

  function validateTruism(options = {}) {
    return (model, property) => {
      const value = Ember.get(model, property);

      if (value === true) {
        return true;
      }

      return (0, _messages.buildMessage)(property, {
        context: options,
        value
      });
    };
  }

  function validateLength(options = {}) {
    return validateByType('length', options);
  }

  function validateNumber(options = {}) {
    return validateByType('number', options);
  }

  function validatePresence(options = {}) {
    return validateByType('presence', options);
  }

  function validateInclusion(options = {}) {
    return validateByType('inclusion', options);
  }

  function validateFormat(options = {}) {
    return validateByType('format', options);
  }

  function validateExclusion(options = {}) {
    return validateByType('exclusion', options);
  }

  function validateDate(options = {}) {
    return (model, property) => {
      const value = Ember.get(model, property);
      const result = (0, _date.validateDate)(value, options);
      return result === true ? true : (0, _messages.buildMessage)(property, result);
    };
  }

  function validateConfirmation(options = {}) {
    return validateByType('confirm', options);
  }

  function validateCollection(options = {}) {
    return validateByType('collection', options);
  }
});