define("flaneur/pods/brand/boutiques/boutique/shipments/view/route", ["exports", "flaneur/models/shipment", "flaneur/models/country"], function (_exports, _shipment, _country) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    analytics: Ember.inject.service(),
    brand: Ember.inject.service(),

    async model({
      shipment_id
    }) {
      const {
        shipments
      } = await _shipment.default.find({
        _id: shipment_id
      });

      if (Ember.isEmpty(shipments)) {
        return this.transitionTo('brand.boutiques.boutique.shipments');
      }

      const unsortedCountries = await _country.default.find({
        shipping_enabled: true,
        skip_links: true
      });
      const countries = unsortedCountries.sortBy('weengs_name');
      return {
        shipment: shipments[0],
        options: {
          countries,
          boutique: this.brand.currentBoutique
        }
      };
    }

  });

  _exports.default = _default;
});