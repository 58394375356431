define("flaneur/pods/components/notification/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "1WEnrSuO",
    "block": "{\"symbols\":[\"@body\",\"&default\",\"@title\",\"@namedBlocksInfo\"],\"statements\":[[10,\"div\"],[15,0,[31,[\"notification \",[30,[36,1],[[32,0,[\"isDismissed\"]],\"notification--dismiss\"],null],\" \",[32,0,[\"styleNamespace\"]]]]],[12],[2,\"\\n  \"],[11,\"a\"],[24,0,\"notification__dismiss\"],[4,[38,2],[\"click\",[32,0,[\"dismiss\"]]],null],[12],[2,\"\\n    \"],[8,\"fa-icon\",[[24,0,\"fa\"]],[[\"@icon\",\"@prefix\"],[\"times\",\"far\"]],null],[2,\"\\n  \"],[13],[2,\"\\n\\n  \"],[10,\"h1\"],[14,0,\"notification__title\"],[12],[2,\"\\n\"],[6,[37,1],[[30,[36,3],[[32,4],\"title\",false],null]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"      \"],[18,2,[[30,[36,0],[\"title\"],null]]],[2,\"\\n\"]],\"parameters\":[]},{\"statements\":[[2,\"      \"],[1,[32,3]],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"  \"],[13],[2,\"\\n\\n  \"],[10,\"div\"],[14,0,\"notification__body\"],[12],[2,\"\\n\"],[6,[37,1],[[30,[36,3],[[32,4],\"body\",false],null]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"      \"],[18,2,[[30,[36,0],[\"body\"],null]]],[2,\"\\n\"]],\"parameters\":[]},{\"statements\":[[2,\"      \"],[1,[32,1]],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"  \"],[13],[2,\"\\n\\n  \"],[10,\"div\"],[14,0,\"notification__actions\"],[12],[2,\"\\n    \"],[18,2,[[30,[36,0],[\"actions\"],null]]],[2,\"\\n  \"],[13],[2,\"\\n\"],[13]],\"hasEval\":false,\"upvars\":[\"-named-block-invocation\",\"if\",\"on\",\"-has-block\"]}",
    "moduleName": "flaneur/pods/components/notification/template.hbs"
  });

  _exports.default = _default;
});