define("flaneur/pods/components/resource-search/results/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "wNgOJ4U/",
    "block": "{\"symbols\":[\"result\",\"index\",\"&default\"],\"statements\":[[6,[37,6],[[30,[36,5],[[30,[36,5],[[35,4]],null]],null]],null,[[\"default\"],[{\"statements\":[[2,\"  \"],[18,3,[[32,1],[32,2]]],[2,\"\\n\"]],\"parameters\":[1,2]}]]],[2,\"\\n\"],[6,[37,9],[[30,[36,8],[[35,1],[35,7]],null]],null,[[\"default\"],[{\"statements\":[[2,\"  \"],[1,[30,[36,3],[[35,2]],[[\"value\",\"onSelect\"],[[35,1],[35,0]]]]],[2,\"\\n\"]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"onCreate\",\"value\",\"createComponent\",\"component\",\"results\",\"-track-array\",\"each\",\"isCreateEnabled\",\"and\",\"if\"]}",
    "moduleName": "flaneur/pods/components/resource-search/results/template.hbs"
  });

  _exports.default = _default;
});