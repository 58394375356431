define("flaneur/pods/brand/boutiques/boutique/orders/fetch", ["exports", "flaneur/models/shipment", "flaneur/models/reservation", "flaneur/models/reservation-boutique", "flaneur/pods/brand/boutiques/boutique/orders/populate", "flaneur/pods/brand/boutiques/boutique/orders/utils", "lodash.groupby", "flaneur/models/boutique-restock-recommendation", "flaneur/enums/boutique-restock-recommendation-type"], function (_exports, _shipment, _reservation, _reservationBoutique, _populate, _utils, _lodash, _boutiqueRestockRecommendation, _boutiqueRestockRecommendationType) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.fetchShipments = fetchShipments;
  _exports.fetchReservations = fetchReservations;
  _exports.fetchReservationBoutiqueWithMeta = fetchReservationBoutiqueWithMeta;
  _exports.fetchReservationBoutiqueCount = fetchReservationBoutiqueCount;
  _exports.fetchOrders = fetchOrders;
  _exports.fetchOrdersCount = fetchOrdersCount;
  _exports.fetchBoutiqueRestockRecommendations = fetchBoutiqueRestockRecommendations;
  _exports.STAGE_COLLECTION = _exports.STAGE_PRINT = _exports.STAGE_ACTIVE = void 0;

  function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); enumerableOnly && (symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols); } return keys; }

  function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = null != arguments[i] ? arguments[i] : {}; i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { _defineProperty(target, key, source[key]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } return target; }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  const STAGE_ACTIVE = 'active';
  _exports.STAGE_ACTIVE = STAGE_ACTIVE;
  const STAGE_PRINT = 'print';
  _exports.STAGE_PRINT = STAGE_PRINT;
  const STAGE_COLLECTION = 'collection';
  _exports.STAGE_COLLECTION = STAGE_COLLECTION;
  const STAGE_CLICK_AND_COLLECT = 'click-and-collect';
  const STAGE_RETURN = 'return';
  const STAGE_COMPLETED = 'completed';

  function chunkArray(myArray, chunkSize = 10) {
    const results = [];
    const orderIds = myArray.slice();

    for (let i = 0; i < orderIds.length; i += chunkSize) {
      results.push(orderIds.slice(i, i + chunkSize));
    }

    return results;
  }

  function fetchShipmentsByIds(boutiqueId, ids) {
    return _shipment.default.find({
      boutique: boutiqueId,
      order: {
        $in: ids
      },
      sort: '-created_at'
    }).then(({
      shipments
    }) => shipments);
  }

  function flatten(chunks) {
    return chunks.reduce((acc, chunk) => acc.concat(chunk), []);
  }

  function fetchShipments(boutiqueId, orderIds) {
    if (Ember.isNone(boutiqueId) || Ember.isEmpty(orderIds)) {
      return Ember.RSVP.resolve({});
    }

    const orderIdsInChunks = chunkArray(orderIds, 10);
    return Ember.RSVP.all(orderIdsInChunks.map(ids => {
      return fetchShipmentsByIds(boutiqueId, ids);
    })).then(shipmentChunks => {
      const flattenedShipments = flatten(shipmentChunks);
      return (0, _lodash.default)(flattenedShipments, ({
        order
      }) => order);
    });
  }

  function fetchReservations(boutiqueId, orderIds, status = '') {
    if (Ember.isNone(boutiqueId) || Ember.isEmpty(orderIds)) {
      return Ember.RSVP.resolve({});
    }

    const sort = status === 'completed' ? '-created_at' : 'created_at';
    return _reservation.default.findReservationsByBoutique({
      boutique: boutiqueId,
      order: {
        $in: orderIds
      },
      populate: 'product,order,stock_line',
      sort,
      skip_links: true
    }).then(reservations => {
      if (reservations.length === 1) {
        (0, _populate.populateProductCategories)(reservations);
      }

      (0, _populate.populateProductsStandardManufacturers)(reservations);
      return (0, _lodash.default)(reservations, curr => curr.get('order._id'));
    });
  }

  async function fetchReservationBoutiqueWithMeta(boutique, status, currentPage, orderReference, limit = 10, additionalParams = {}) {
    return _reservationBoutique.default.findWithMeta(_objectSpread({
      boutique,
      status,
      limit: (0, _utils.getReservationBoutiquesLimit)(orderReference, status, limit),
      'channel_specific.order_reference': Ember.isArray(orderReference) ? {
        $in: orderReference
      } : orderReference,
      sort: (0, _utils.getReservationBoutiqueSort)(status),
      page: currentPage
    }, additionalParams)).then(({
      meta,
      reservation_boutiques: reservationBoutiques
    }) => {
      return {
        reservationBoutiques: (0, _lodash.default)(reservationBoutiques, curr => curr.get('order')),
        currentPage: currentPage + 1,
        lastPage: meta ? meta.page_total : null,
        total: meta ? meta.total : null
      };
    });
  }

  async function fetchReservationBoutiqueCount(boutiqueId, extraData = {}) {
    return _reservationBoutique.default.count(_objectSpread({
      boutique: boutiqueId
    }, extraData)).then(({
      count
    }) => count !== null && count !== void 0 ? count : 0);
  }

  async function fetchOrders(boutiqueId, orderNumber, stage, currentModel = {
    currentPage: 1
  }) {
    const status = (0, _utils.getStage)(stage, orderNumber);
    const {
      reservationBoutiques,
      currentPage,
      lastPage,
      total
    } = await fetchReservationBoutiqueWithMeta(boutiqueId, status, currentModel.currentPage, orderNumber);
    const orderIds = Object.keys(reservationBoutiques);
    return {
      shipments: await fetchShipments(boutiqueId, orderIds),
      reservationBoutiques,
      reservations: await fetchReservations(boutiqueId, orderIds, status),
      currentPage,
      lastPage,
      total
    };
  }

  async function fetchOrdersCount(boutiqueId) {
    const activeOrdersCount = fetchReservationBoutiqueCount(boutiqueId, {
      status: STAGE_ACTIVE
    });
    const printOrdersCount = fetchReservationBoutiqueCount(boutiqueId, {
      status: STAGE_PRINT
    });
    const clickAndCollectCount = fetchReservationBoutiqueCount(boutiqueId, {
      status: STAGE_CLICK_AND_COLLECT
    });
    const returnOrdersCount = fetchReservationBoutiqueCount(boutiqueId, {
      status: STAGE_RETURN
    });
    const completedOrdersCount = fetchReservationBoutiqueCount(boutiqueId, {
      status: STAGE_COMPLETED,
      limit: 1
    });
    const collectOrdersCount = fetchReservationBoutiqueCount(boutiqueId, {
      status: STAGE_COLLECTION,
      limit: 1
    });
    const totalNewOrdersCount = Ember.RSVP.all([activeOrdersCount, collectOrdersCount, printOrdersCount]).then(items => items.reduce((a, b) => a + b));
    return Ember.RSVP.hash({
      activeOrdersCount,
      collectOrdersCount,
      printOrdersCount,
      clickAndCollectCount,
      returnOrdersCount,
      totalNewOrdersCount,
      completedOrdersCount
    });
  }

  async function fetchBoutiqueRestockRecommendations(groupedReservations, boutiqueId) {
    if (!groupedReservations) {
      return {};
    }

    const productsIds = new Set();

    for (const orderId in groupedReservations) {
      const reservations = groupedReservations[orderId];
      reservations.forEach(({
        product
      }) => productsIds.add(product === null || product === void 0 ? void 0 : product._id));
    }

    if (productsIds.size <= 0) {
      return {};
    }

    try {
      const recommendations = await _boutiqueRestockRecommendation.default.find({
        boutiqueId,
        attachRestockInformation: true,
        productId: [...productsIds],
        limit: 5000,
        filters: [{
          member: 'StockRecommendations.recommendationType',
          operator: 'equals',
          values: [_boutiqueRestockRecommendationType.default.STOCK]
        }]
      });
      return (0, _lodash.default)(recommendations, ({
        product: {
          id
        }
      }) => id);
    } catch (e) {
      console.error(e);
      return {};
    }
  }
});