define("flaneur/pods/components/tip-box/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "gMIRPLpo",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[10,\"div\"],[14,0,\"tip-box__container\"],[12],[2,\"\\n  \"],[10,\"div\"],[14,0,\"tip-box__thumbtack\"],[12],[2,\"\\n    \"],[8,\"fa-icon\",[[24,0,\"fa\"]],[[\"@icon\",\"@prefix\"],[\"thumbtack\",\"fas\"]],null],[2,\"\\n  \"],[13],[2,\"\\n\\n  \"],[10,\"div\"],[14,0,\"tip-box__body\"],[12],[2,\"\\n    \"],[18,1,null],[2,\"\\n  \"],[13],[2,\"\\n\"],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[]}",
    "moduleName": "flaneur/pods/components/tip-box/template.hbs"
  });

  _exports.default = _default;
});