define("flaneur/pods/components/curate-categories-filter/subtree/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "giGsKZPf",
    "block": "{\"symbols\":[\"category\",\"@categories\",\"@onChange\"],\"statements\":[[10,\"ul\"],[15,0,[32,0,[\"styleNamespace\"]]],[12],[2,\"\\n\"],[6,[37,7],[[30,[36,6],[[30,[36,6],[[32,0,[\"categories\"]]],null]],null]],null,[[\"default\"],[{\"statements\":[[2,\"    \"],[10,\"li\"],[15,0,[31,[\"curate-categories-filter__category\\n    \",[30,[36,3],[[30,[36,2],[[30,[36,1],[[32,1,[\"_id\"]],[32,2,[\"selected\"]]],null],[30,[36,0],[[32,2],[32,1,[\"_id\"]]],null]],null],\"curate-categories-filter__selected\"],null]]]],[12],[2,\"\\n      \"],[8,\"curate-categories-filter/link\",[[4,[38,5],[\"click\",[30,[36,4],[[32,3],[30,[36,3],[[30,[36,2],[[30,[36,1],[[32,1,[\"_id\"]],[32,2,[\"selected\"]]],null],[30,[36,0],[[32,2],[32,1,[\"_id\"]]],null]],null],[32,0,[\"parentId\"]],[32,1,[\"_id\"]]],null]],null]],null]],[[],[]],[[\"default\"],[{\"statements\":[[2,\"\\n        \"],[1,[32,1,[\"name\"]]],[2,\"\\n\"],[6,[37,3],[[32,1,[\"count\"]]],null,[[\"default\"],[{\"statements\":[[2,\"          \"],[10,\"span\"],[14,0,\"curate-categories-filter__counter\"],[12],[2,\"(\"],[1,[32,1,[\"count\"]]],[2,\")\"],[13],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"      \"]],\"parameters\":[]}]]],[2,\"\\n    \"],[13],[2,\"\\n\"],[6,[37,3],[[30,[36,0],[[32,2],[32,1,[\"_id\"]]],null]],null,[[\"default\"],[{\"statements\":[[2,\"        \"],[8,\"curate-categories-filter/subtree\",[],[[\"@categories\",\"@level\",\"@onChange\"],[[32,2],[32,1,[\"_id\"]],[32,3]]],null],[2,\"\\n\"]],\"parameters\":[]}]]]],\"parameters\":[1]}]]],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"get\",\"eq\",\"or\",\"if\",\"fn\",\"on\",\"-track-array\",\"each\"]}",
    "moduleName": "flaneur/pods/components/curate-categories-filter/subtree/template.hbs"
  });

  _exports.default = _default;
});