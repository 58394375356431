define("flaneur/pods/services/quick-ship/new/controller", ["exports", "flaneur/models/shipment"], function (_exports, _shipment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _class;

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  let ServicesQuickShipNewController = (_dec = Ember._action, _dec2 = Ember._action, (_class = class ServicesQuickShipNewController extends Ember.Controller {
    backToHistory() {
      return this.transitionToRoute('services.quickship');
    }

    submitForm(shipment) {
      return _shipment.default.bookExternal(shipment).then(shipments => {
        return this.transitionToRoute('services.quick-ship.view', shipments[0]._id);
      }).catch(error => {
        const isErrorRetryable = error.status === 429;

        if (isErrorRetryable) {
          this.notify.show('error', 'Oops! An error occurred while booking a courier. Please try again.');
          return;
        }

        return this.transitionToRoute('services.quick-ship');
      });
    }

  }, (_applyDecoratedDescriptor(_class.prototype, "backToHistory", [_dec], Object.getOwnPropertyDescriptor(_class.prototype, "backToHistory"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "submitForm", [_dec2], Object.getOwnPropertyDescriptor(_class.prototype, "submitForm"), _class.prototype)), _class));
  _exports.default = ServicesQuickShipNewController;
});