define("flaneur/pods/components/orders-header/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "YgyduYKt",
    "block": "{\"symbols\":[],\"statements\":[[10,\"div\"],[14,0,\"orders-header-container\"],[12],[2,\"\\n  \"],[1,[30,[36,8],null,[[\"isLoading\",\"totalNewOrdersCount\",\"clickAndCollectCount\",\"returnCount\",\"refreshAction\",\"addLoadingAction\",\"boutique\"],[[35,7],[35,6],[35,5],[35,4],[35,3],[35,2],[35,1]]]]],[2,\"\\n\\n  \"],[10,\"div\"],[14,0,\"order-search-box\"],[12],[2,\"\\n\"],[2,\"    \"],[8,\"fa-icon\",[[24,0,\"fa\"]],[[\"@icon\",\"@prefix\"],[\"search\",\"far\"]],null],[2,\"\\n    \"],[1,[30,[36,10],null,[[\"value\",\"enter\",\"class\",\"placeholder\"],[[35,9],[30,[36,0],[[32,0],\"search\"],null],\"form-control\",\"Find an order number\"]]]],[2,\"\\n\\n    \"],[8,\"button\",[],[[\"@onClick\"],[[30,[36,0],[[32,0],\"search\"],null]]],[[\"default\"],[{\"statements\":[[2,\"Search\"]],\"parameters\":[]}]]],[2,\"\\n\\n\"],[6,[37,11],[[35,9]],null,[[\"default\"],[{\"statements\":[[2,\"      \"],[8,\"button\",[],[[\"@theme\",\"@onClick\"],[\"secondary\",[30,[36,0],[[32,0],\"resetSearch\"],null]]],[[\"default\"],[{\"statements\":[[2,\"Clear\"]],\"parameters\":[]}]]],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"  \"],[13],[2,\"\\n\"],[13],[2,\"\\n\\n\"]],\"hasEval\":false,\"upvars\":[\"action\",\"boutique\",\"addLoadingAction\",\"refreshAction\",\"returnCount\",\"clickAndCollectCount\",\"totalNewOrdersCount\",\"isLoading\",\"nav-order-tabs\",\"ordernumber\",\"input\",\"if\"]}",
    "moduleName": "flaneur/pods/components/orders-header/template.hbs"
  });

  _exports.default = _default;
});