define("flaneur/pods/components/order-item-actions/component", ["exports", "date-fns", "flaneur/mixins/courier-calculator"], function (_exports, _dateFns, _courierCalculator) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend(_courierCalculator.default, {
    classNames: ['order-item-action'],
    brand: Ember.inject.service(),
    media: Ember.inject.service(),
    currentState: null,
    isActive: null,
    isPrint: null,
    isCollection: null,
    isCompleted: null,
    activeLength: null,
    displayTrackingModal: false,
    insertTrackingCode: false,
    showMarkAsShipped: false,

    confirm() {},

    openNewReceiptTab() {},

    updateReservationBoutique() {},

    isToday: Ember.computed('order.created_at', function () {
      return (0, _dateFns.isSameDay)(new Date(), (0, _dateFns.parseISO)(this.order.created_at));
    }),
    returningCustomer: Ember.computed('order.repeat_order', function () {
      return this.get('order.repeat_order') ? ', Returning customer' : ', First time customer';
    }),
    courierData: Ember.computed('reservationBoutique.0.shipping_provider', 'shipment', function () {
      const {
        courierData
      } = this.getCourierData(this.get('reservationBoutique.0'), this.shipment);
      return courierData;
    }),
    courierLogo: Ember.computed.reads('courierData.logo'),
    hasCustomsForm: Ember.computed.alias('shipment.hasCustomsForm'),
    hasBooked: Ember.computed.alias('reservationBoutique.0.arranged_courier'),
    printedReceipt: Ember.computed.alias('reservationBoutique.0.printed_receipt'),
    printedLabel: Ember.computed.alias('reservationBoutique.0.printed_shipping_label'),
    printedCustomsForm: Ember.computed.alias('reservationBoutique.0.printed_customs_form'),
    isReservationBoutiqueShippingProviderOther: Ember.computed.equal('reservationBoutique.0.shipping_provider', 'other'),
    isActiveOrPrintedReceipt: Ember.computed.or('isActive', 'printedReceipt'),
    isActiveOrPrintedLabel: Ember.computed.or('isActive', 'printedLabel'),
    printedReceiptAndLabel: Ember.computed.and('printedReceipt', 'printedLabel'),
    isPrintAndPrintedDocuments: Ember.computed.and('isPrint', 'printedReceiptAndLabel'),
    isMarkedAsCollected: Ember.computed.alias('reservationBoutique.0.marked_as_collected'),
    isNotOutOfStock: Ember.computed.not('isAllOutOfStock'),
    isActiveAndNotOutOfStock: Ember.computed.and('isActive', 'isNotOutOfStock'),
    isAnyReservationInActive: Ember.computed.gt('activeLength', 0),
    otherTrackingInfo: Ember.computed.alias('reservationBoutique.0.other_tracking_info'),
    isReservationBoutiqueFulfilmentMethodShippingExpress: Ember.computed.equal('reservationBoutique.0.fulfilment_method', 'shipping_express'),
    actions: {
      onTrackingModalCancel() {
        this.setProperties({
          insertTrackingCode: false,
          displayTrackingModal: true
        });
      },

      onTrackingModalCompleted(other_tracking_info) {
        this.setProperties({
          insertTrackingCode: false,
          displayTrackingModal: false,
          showMarkAsShipped: true,
          'reservationBoutique.0.other_tracking_info': other_tracking_info
        });
      },

      displayTrackingModal() {
        this.analytics.gtm({
          action: 'clicked_add_tracking_info',
          label: this.order._id
        });
        this.setProperties({
          insertTrackingCode: true,
          displayTrackingModal: true
        });
      }

    }
  });

  _exports.default = _default;
});