define("flaneur/pods/components/made-order-modal/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "AmWjmoPz",
    "block": "{\"symbols\":[\"@order\",\"@onClose\",\"@onClickContactSupport\"],\"statements\":[[8,\"modal-dialog\",[],[[\"@containerClass\",\"@translucentOverlay\"],[[31,[\"made-order-modal \",[32,0,[\"styleNamespace\"]]]],true]],[[\"default\"],[{\"statements\":[[2,\"\\n    \"],[10,\"div\"],[14,0,\"ember-modal-dialog__header\"],[12],[2,\"\\n        \"],[10,\"h1\"],[12],[2,\"\\n            MADE order #\"],[1,[32,1,[\"channel_specific\",\"order_reference\"]]],[2,\"\\n        \"],[13],[2,\"\\n        \"],[11,\"a\"],[24,0,\"ember-modal-dialog__close\"],[4,[38,0],[\"click\",[32,2]],null],[12],[2,\"\\n            \"],[8,\"fa-icon\",[],[[\"@icon\",\"@prefix\"],[\"times\",\"fal\"]],null],[2,\"\\n        \"],[13],[2,\"\\n    \"],[13],[2,\"\\n    \"],[10,\"div\"],[14,0,\"ember-modal-dialog__body\"],[12],[2,\"\\n        \"],[10,\"p\"],[12],[2,\"This order was placed by a customer on MADE.com. Please do not use any Trouva branded packaging on this order, or place any of your own shop information inside the box. Use plain packaging where possible.\"],[13],[2,\"\\n    \"],[13],[2,\"\\n    \"],[10,\"div\"],[14,0,\"ember-modal-dialog__footer\"],[12],[2,\"\\n        \"],[8,\"button\",[[24,0,\"made-order-modal__button\"]],[[\"@theme\",\"@onClick\"],[\"secondary\",[32,3]]],[[\"default\"],[{\"statements\":[[2,\"\\n            \"],[8,\"fa-icon\",[],[[\"@icon\",\"@prefix\"],[\"flag\",\"far\"]],null],[2,\" Contact support\\n        \"]],\"parameters\":[]}]]],[2,\"\\n        \"],[8,\"button\",[],[[\"@onClick\"],[[32,2]]],[[\"default\"],[{\"statements\":[[2,\"\\n            Ok, I understand \\n        \"]],\"parameters\":[]}]]],[2,\"\\n    \"],[13],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"on\"]}",
    "moduleName": "flaneur/pods/components/made-order-modal/template.hbs"
  });

  _exports.default = _default;
});