define("flaneur/pods/components/confirm-order-item-warning/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    classNames: ['confirm-order-item-warning'],
    nextOpenDayDate: null,

    onCancel() {},

    onConfirm() {}

  });

  _exports.default = _default;
});