define("flaneur/pods/components/manufacturer-suggestion/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "Sgmp+tUq",
    "block": "{\"symbols\":[],\"statements\":[[8,\"ember-power-select-no-matches\",[],[[\"@message\"],[\"No brand found with this name\"]],null],[2,\"\\n\\n\"],[10,\"div\"],[14,0,\"manufacturer-suggestion__create\"],[12],[2,\"\\n  \"],[8,\"fa-icon\",[],[[\"@icon\"],[\"plus-square\"]],null],[2,\"\\n  Add \\\"\"],[1,[35,0,[\"searchText\"]]],[2,\"\\\" as a new brand\\n\"],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"term\"]}",
    "moduleName": "flaneur/pods/components/manufacturer-suggestion/template.hbs"
  });

  _exports.default = _default;
});