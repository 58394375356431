define("flaneur/pods/components/form-flows/product/gallery-details/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    media: Ember.inject.service(),
    classNames: ['gallery-details'],
    elementId: 'gallery-details',
    hasImages: Ember.computed.gt('product.images.length', 0),
    actions: {
      uploadImages(imgs) {
        if (Ember.isEmpty(imgs)) {
          return;
        }

        const images = this.product.images;
        images.addObjects(Ember.isArray(imgs) ? imgs : Ember.A([imgs]));

        if (Ember.isEmpty(this.get('product.thumbnail'))) {
          this.set('product.thumbnail', Ember.Object.create());
          this.set('product.thumbnail.url', images.get('firstObject.url'));
          this.set('product.thumbnail.originalUrl', this.get('product.thumbnail.url'));
          this.set('product.thumbnail.width', images.get('firstObject.width'));
          this.set('product.thumbnail.height', images.get('firstObject.height'));
        }
      },

      imageEdited(img, index) {
        this.imageEdited(img, index);
      },

      deleteImage(img) {
        this.deleteImage(img);
      }

    }
  });

  _exports.default = _default;
});