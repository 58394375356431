define("flaneur/pods/components/pages/your-contract/component", ["exports", "jquery", "flaneur/models/country", "ember-concurrency", "flaneur/models/boutique"], function (_exports, _jquery, _country, _emberConcurrency, _boutique) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const FIELD_PADDING_TOP = 40; // in pixels

  var _default = Ember.Component.extend({
    analytics: Ember.inject.service(),
    media: Ember.inject.service(),
    notify: Ember.inject.service(),
    router: Ember.inject.service(),
    // Public API
    prospectiveBoutique: null,
    // Private API
    classNames: ['page'],
    confirmationCheck: false,
    previousVatNumber: null,
    selectedCountry: null,
    disableValidations: Ember.computed.alias('prospectiveBoutique.disableValidations'),
    vatNumber: Ember.computed.alias('prospectiveBoutique.vat_number'),
    isVatRegistered: Ember.computed.alias('prospectiveBoutique.is_vat_registered'),
    country: Ember.computed.alias('prospectiveBoutique.registered_address_country'),
    currency: Ember.computed.readOnly('prospectiveBoutique.currency'),
    membershipDetails: Ember.computed('prospectiveBoutique', function () {
      const {
        membership_fee,
        commission_rate,
        fix_shipping_charge,
        other_commercial_terms
      } = this.prospectiveBoutique;
      return [{
        title: 'Membership Fee',
        value: membership_fee,
        isPrice: true
      }, {
        title: 'Commission\n(Excluding VAT/Sales tax)',
        value: `${commission_rate}%`
      }, {
        title: 'Fulfillment contribution\n(Excluding VAT/Sales tax)',
        value: fix_shipping_charge || '0',
        isPrice: true
      }, {
        title: 'Transit Protection\n(Excluding VAT)',
        value: 'Included as standard'
      }, {
        title: 'Responsibility for packaging',
        value: 'Retailer'
      }, {
        title: 'Other',
        value: other_commercial_terms
      }];
    }),

    async init() {
      this._super(...arguments);

      this.setProperties({
        disableValidations: true,
        previousVatNumber: this.prospectiveBoutique.vat_number
      });

      if (Ember.isPresent(this.country)) {
        _country.default.findById(this.country).then(country => this.set('selectedCountry', country));
      }
    },

    _scrollToField(field) {
      const label = document.querySelector(`label[for="${Ember.String.dasherize(field)}"]`);

      if (Ember.isEmpty(label)) {
        return;
      }

      (0, _jquery.default)('html, body').animate({
        scrollTop: label.offsetTop - FIELD_PADDING_TOP
      }, 600);
    },

    _searchCountry: (0, _emberConcurrency.task)(function* (value) {
      yield (0, _emberConcurrency.timeout)(500);
      return yield _country.default.find({
        weengs_name: {
          $regex: value,
          $options: 'i'
        }
      });
    }).restartable(),
    actions: {
      onChangeVatRegistered(value) {
        this.setProperties({
          vatNumber: value ? '' : this.previousVatNumber,
          isVatRegistered: !value
        });
      },

      onChangeCountry(country) {
        this.setProperties({
          selectedCountry: country,
          country: country ? country._id : null
        });

        if (Ember.isPresent(this.prospectiveBoutique.primary_phone)) {
          return;
        }

        emberAutoImportDynamic("libphonenumber-js").then(({
          getCountryCallingCode
        }) => {
          const callingCode = getCountryCallingCode(country.iso_code);
          Ember.set(this.prospectiveBoutique, 'primary_phone', `+${callingCode}`);
        });
        this.analytics.gtm({
          action: 'select_registered_country'
        });
      },

      async agree() {
        if (!this.confirmationCheck) {
          this.notify.show('error', 'Please tick the checkbox to agree & proceed.');
          return;
        }

        this.set('disableValidations', false);
        const {
          validations: {
            isInvalid,
            error
          }
        } = await this.prospectiveBoutique.validate();

        if (isInvalid) {
          this._scrollToField(error.attribute);

          this.notify.show('error', 'Oops! It looks like you have some invalid fields.');
          return;
        }

        return this.prospectiveBoutique.save().then(() => this.prospectiveBoutique.acceptContract()).then(async ({
          boutique: boutiqueId
        }) => {
          const boutique = await _boutique.default.findById(boutiqueId);
          return this.router.transitionTo('brand.boutiques.boutique.welcome', boutique.brand, boutique);
        }).catch(() => this.notify.show('error', 'Oops! An unexpected error ocurred while agreeing & proceeding. Please try again.'));
      }

    }
  });

  _exports.default = _default;
});