define("flaneur/pods/components/resource-search/result/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "sdYnPtKs",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[18,1,[[35,1],[35,0]]]],\"hasEval\":false,\"upvars\":[\"index\",\"result\"]}",
    "moduleName": "flaneur/pods/components/resource-search/result/template.hbs"
  });

  _exports.default = _default;
});