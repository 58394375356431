define("flaneur/pods/components/order-loading-message/click-and-collect/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "pMD5uNk3",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[18,1,null],[2,\"\\n\\n\"],[10,\"div\"],[14,0,\"empty-state-message\"],[12],[2,\"\\n    You don't have any Click and Collect orders at the moment.\\n\"],[13],[2,\"\\n\"],[10,\"hr\"],[12],[13]],\"hasEval\":false,\"upvars\":[]}",
    "moduleName": "flaneur/pods/components/order-loading-message/click-and-collect/template.hbs"
  });

  _exports.default = _default;
});