define("flaneur/pods/components/curate-product-card/component", ["exports", "siema", "@glimmer/component", "ember-component-css/pod-names"], function (_exports, _siema, _component, _podNames) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _dec8, _dec9, _dec10, _dec11, _dec12, _dec13, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  let CurateProductCardComponent = (_dec = Ember.inject.service, _dec2 = Ember.inject.service, _dec3 = Ember._tracked, _dec4 = Ember._tracked, _dec5 = Ember._tracked, _dec6 = Ember._action, _dec7 = Ember._action, _dec8 = Ember._action, _dec9 = Ember._action, _dec10 = Ember._action, _dec11 = Ember._action, _dec12 = Ember._action, _dec13 = Ember._action, (_class = class CurateProductCardComponent extends _component.default {
    constructor(...args) {
      super(...args);

      _initializerDefineProperty(this, "analytics", _descriptor, this);

      _initializerDefineProperty(this, "router", _descriptor2, this);

      _initializerDefineProperty(this, "selectedImageIndex", _descriptor3, this);

      _initializerDefineProperty(this, "siema", _descriptor4, this);

      _initializerDefineProperty(this, "hasInitiallyResized", _descriptor5, this);

      _defineProperty(this, "styleNamespace", _podNames.default['curate-product-card']);
    }

    get product() {
      return this.args.product;
    }

    get siemaSelector() {
      return `#carousel-${this.args.product.id}`;
    }

    get images() {
      var _this$args$product$im;

      return (_this$args$product$im = this.args.product.images) !== null && _this$args$product$im !== void 0 ? _this$args$product$im : [];
    }

    get hasImages() {
      return this.images.length > 0;
    }

    get showCarousel() {
      return this.images.length > 1;
    }
    /*
     * These are the same transformations as trouva-web uses
     * for showing search results. Please beware when changing this
     * as Cloudinary charges for additional transformation combinations.
     *
     * https://github.com/Trouva/trouva-web/blob/master/src/components/utils/CloudinaryAPI/index.js
     */


    get imageTransformations() {
      const searchResultTransformation = ['w_360', 'h_360'];
      const defaultTransformation = ['f_auto', 'q_auto:eco', 'dpr_auto', 'c_pad', 'b_white'];
      const lqipTransformation = ['f_auto', 'dpr_auto', 'c_pad', 'b_white', 'e_blur:2000', 'q_40'];
      return {
        default: [...searchResultTransformation, ...defaultTransformation].join(','),
        lqip: [...searchResultTransformation, ...lqipTransformation].join(',')
      };
    }

    willDestroy() {
      var _this$siema;

      super.willDestroy(...arguments);
      (_this$siema = this.siema) === null || _this$siema === void 0 ? void 0 : _this$siema.destroy();
    }

    initializeSiema() {
      if (!this.showCarousel) {
        return;
      }

      this.siema = new _siema.default({
        selector: this.siemaSelector,
        loop: true,
        onChange: () => {
          this.selectedImageIndex = this.siema.currentSlide;
        }
      });
    }

    onDidInsert() {
      this.initializeSiema();
    }

    onDidResize() {
      var _this$siema2;

      if (!this.hasInitiallyResized) {
        this.hasInitiallyResized = true;
        return;
      }

      (_this$siema2 = this.siema) === null || _this$siema2 === void 0 ? void 0 : _this$siema2.destroy({
        restoreMarkup: true
      }, () => {
        this.initializeSiema();
      });
    }

    onChangeQuantity(quantity) {
      this.analytics.gtm({
        action: 'curate_changed_product_quantity',
        label: quantity
      });
      this.selectedQuantity = quantity;
    }

    trackImageClick() {
      this.analytics.gtm({
        action: 'curate_clicked_product_image',
        label: this.product.stockLines[0].sku
      });
    }

    openModal() {
      this.analytics.gtm({
        action: 'clicked_product_card_to_open',
        label: this.product.name
      });
      return this.router.transitionTo('brand.boutiques.boutique.curate.brands.brand.products.product', this.args.product.slug);
    }

    nextImage() {
      this.analytics.gtm({
        action: 'curate_viewed_more_product_images_from_card',
        label: this.product.name
      });
      this.siema.next();
    }

    goToPhoto(index) {
      this.siema.goTo(index);
    }

    previousImage() {
      this.analytics.gtm({
        action: 'curate_viewed_more_product_images_from_card',
        label: this.product.name
      });
      this.siema.prev();
    }

  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "analytics", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "router", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "selectedImageIndex", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return 0;
    }
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "siema", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return null;
    }
  }), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "hasInitiallyResized", [_dec5], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return false;
    }
  }), _applyDecoratedDescriptor(_class.prototype, "onDidInsert", [_dec6], Object.getOwnPropertyDescriptor(_class.prototype, "onDidInsert"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "onDidResize", [_dec7], Object.getOwnPropertyDescriptor(_class.prototype, "onDidResize"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "onChangeQuantity", [_dec8], Object.getOwnPropertyDescriptor(_class.prototype, "onChangeQuantity"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "trackImageClick", [_dec9], Object.getOwnPropertyDescriptor(_class.prototype, "trackImageClick"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "openModal", [_dec10], Object.getOwnPropertyDescriptor(_class.prototype, "openModal"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "nextImage", [_dec11], Object.getOwnPropertyDescriptor(_class.prototype, "nextImage"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "goToPhoto", [_dec12], Object.getOwnPropertyDescriptor(_class.prototype, "goToPhoto"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "previousImage", [_dec13], Object.getOwnPropertyDescriptor(_class.prototype, "previousImage"), _class.prototype)), _class));
  _exports.default = CurateProductCardComponent;
});