define("flaneur/models/carrier-lane/index", ["exports", "flaneur/config/environment", "query-string", "fetch"], function (_exports, _environment, _queryString, _fetch) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); enumerableOnly && (symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols); } return keys; }

  function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = null != arguments[i] ? arguments[i] : {}; i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { _defineProperty(target, key, source[key]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } return target; }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  class CarrierLane {
    constructor({
      source_country_code,
      destination_country_code
    } = {}) {
      this.source_country_code = source_country_code;
      this.destination_country_code = destination_country_code;
    }

    static async findByIsoCodesWithExtraShipping({
      sourceIsoCode,
      destinationIsoCode
    }) {
      const queryParams = _queryString.default.stringify(_objectSpread(_objectSpread(_objectSpread({}, sourceIsoCode && {
        source_country_code: sourceIsoCode
      }), destinationIsoCode && {
        destination_country_code: destinationIsoCode
      }), {}, {
        variant: 'extra_shipping'
      }));

      const url = `${_environment.default.apiURL}/api/v2/carrier_lanes?${queryParams}`;
      const res = await (0, _fetch.default)(url, {
        method: 'GET',
        credentials: 'include',
        cache: false
      });

      if (!res.ok) {
        return {
          carrier_lanes: []
        };
      }

      const {
        carrier_lanes = []
      } = await res.json();
      return {
        carrier_lanes: carrier_lanes.map(cl => new CarrierLane(cl))
      };
    }

  }

  _exports.default = CarrierLane;
});