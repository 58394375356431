define("flaneur/pods/components/order-item-header/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "215qQgsp",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[10,\"div\"],[12],[2,\"\\n  \"],[10,\"span\"],[14,0,\"round\"],[12],[13],[2,\"\\n  \"],[10,\"h2\"],[12],[2,\"Order #\"],[8,\"order-reference\",[],[[\"@order\"],[[34,0]]],null],[13],[2,\"\\n\"],[13],[2,\"\\n\"],[10,\"div\"],[14,0,\"order-item-details-text\"],[12],[2,\"\\n    \"],[10,\"span\"],[14,0,\"dash\"],[12],[2,\"- \"],[13],[2,\"\\n    \"],[10,\"h2\"],[12],[2,\"\\n        Placed at \"],[10,\"span\"],[14,0,\"hour\"],[12],[1,[30,[36,1],[[35,0,[\"created_at\"]],\"p\"],null]],[13],[2,\"\\n    \"],[13],[2,\"\\n    \"],[10,\"h2\"],[12],[2,\"\\n\"],[6,[37,3],[[35,4]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"            \"],[10,\"span\"],[14,0,\"hour today\"],[12],[2,\"today\"],[13],[2,\"\\n            \"],[10,\"span\"],[14,0,\"date\"],[12],[2,\"(\"],[1,[30,[36,1],[[35,0,[\"created_at\"]],\"dd MMMM yyyy\"],null]],[2,\")\"],[13],[2,\"\\n\"]],\"parameters\":[]},{\"statements\":[[6,[37,3],[[30,[36,2],[\"isSmall\"],null]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"                \"],[10,\"span\"],[14,0,\"date\"],[12],[2,\"on \"],[1,[30,[36,1],[[35,0,[\"created_at\"]],\"P\"],null]],[13],[2,\"\\n\"]],\"parameters\":[]},{\"statements\":[[2,\"                \"],[10,\"span\"],[14,0,\"date\"],[12],[2,\"on \"],[1,[30,[36,1],[[35,0,[\"created_at\"]],\"dd MMMM yyyy\"],null]],[13],[2,\"\\n\"]],\"parameters\":[]}]]]],\"parameters\":[]}]]],[2,\"    \"],[13],[2,\"\\n    \"],[10,\"h2\"],[14,0,\"ship-to\"],[12],[2,\"\\n        - Ship to \"],[10,\"span\"],[14,0,\"hour\"],[12],[1,[35,0,[\"delivery_country\"]]],[13],[2,\"\\n    \"],[13],[2,\"\\n\"],[13],[2,\"\\n\\n\"],[18,1,null],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"order\",\"date-format\",\"media\",\"if\",\"isToday\"]}",
    "moduleName": "flaneur/pods/components/order-item-header/template.hbs"
  });

  _exports.default = _default;
});