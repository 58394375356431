define("flaneur/models/boutique/validations", ["exports", "ember-cp-validations", "flaneur/enums/regular-expressions"], function (_exports, _emberCpValidations, _regularExpressions) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.getSpecificValidations = getSpecificValidations;
  _exports.Validations = void 0;
  const validators = {
    name: (0, _emberCpValidations.validator)('presence', {
      presence: true,
      description: 'Boutique name'
    }),
    address: (0, _emberCpValidations.validator)('presence', {
      presence: true,
      description: 'Address'
    }),
    address_line_1: (0, _emberCpValidations.validator)('presence', {
      presence: true,
      description: 'Address line 1'
    }),
    address_city: (0, _emberCpValidations.validator)('presence', {
      presence: true,
      description: 'City'
    }),
    address_postcode: (0, _emberCpValidations.validator)('presence', {
      presence: true,
      description: 'Postcode'
    }),
    primary_contact_name: (0, _emberCpValidations.validator)('presence', {
      presence: true,
      description: 'Primary contact name'
    }),
    primary_phone: (0, _emberCpValidations.validator)('presence', {
      presence: true,
      description: 'Primary phone number'
    }),
    primary_email: (0, _emberCpValidations.validator)('format', {
      type: 'email',
      description: 'Primary email'
    }),
    support_email: (0, _emberCpValidations.validator)('format', {
      type: 'email',
      description: 'Support email'
    }),
    support_phone: (0, _emberCpValidations.validator)('presence', {
      presence: true,
      description: 'Support phone number'
    }),
    order_email: (0, _emberCpValidations.validator)('format', {
      type: 'email',
      description: 'Order email'
    }),
    statement_email: (0, _emberCpValidations.validator)('format', {
      type: 'email',
      description: 'Statement email'
    }),
    'cover.url': (0, _emberCpValidations.validator)('format', {
      type: 'url',
      message: 'You must add a cover photo'
    }),
    'cover_thumbnail.url': (0, _emberCpValidations.validator)('format', {
      type: 'url',
      message: 'You must add a shop front image'
    }),
    description: (0, _emberCpValidations.validator)('presence', {
      presence: true,
      description: 'Long description'
    }),
    mini_description: (0, _emberCpValidations.validator)('presence', {
      presence: true,
      description: 'Mini description'
    }),
    bank_account_bic: (0, _emberCpValidations.validator)('format', {
      regex: _regularExpressions.default.bic,
      message: 'You must provide a valid BIC',
      disabled: Ember.computed.readOnly('model.isGBR')
    }),
    bank_account_iban: (0, _emberCpValidations.validator)('iban', {
      disabled: Ember.computed.readOnly('model.isGBR')
    }),
    bank_account_number: (0, _emberCpValidations.validator)('format', {
      regex: _regularExpressions.default.accountNumber,
      message: 'Please provide a valid account number',
      disabled: Ember.computed.not('model.isGBR')
    }),
    bank_sort_code: (0, _emberCpValidations.validator)('format', {
      regex: _regularExpressions.default.sortCode,
      message: 'Please provide a valid sort code',
      disabled: Ember.computed.not('model.isGBR')
    }),
    vat_number: (0, _emberCpValidations.validator)('vat-number', {
      allowEmpty: Ember.computed.not('model.isVatRegistered'),
      countryCode: Ember.computed.readOnly('model.registeredAddressIsoCodeAlpha3')
    }),
    oh: (0, _emberCpValidations.validator)('length', {
      min: 1,
      message: 'Please insert your opening hours'
    })
  };

  function getSpecificValidations(fields = [], options = {}) {
    if (Ember.isEmpty(fields)) {
      return (0, _emberCpValidations.buildValidations)({});
    }

    const specificValidators = fields.reduce((acc, field) => {
      const validator = validators[field];

      if (Ember.isPresent(validator)) {
        const name = `${options.prefix || ''}${field}`;
        acc[name] = validator;
      }

      return acc;
    }, {});
    return (0, _emberCpValidations.buildValidations)(specificValidators);
  }

  const Validations = (0, _emberCpValidations.buildValidations)(validators);
  _exports.Validations = Validations;
});