define("flaneur/pods/services/quick-ship/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "9KVbRZcC",
    "block": "{\"symbols\":[],\"statements\":[[10,\"header\"],[14,0,\"header\"],[12],[2,\"\\n  \"],[10,\"div\"],[14,0,\"header__logo\"],[12],[2,\"\\n    \"],[8,\"fa-icon\",[],[[\"@icon\",\"@prefix\"],[\"truck\",\"far\"]],null],[2,\"\\n    \"],[10,\"span\"],[12],[2,\"QuickShip by Trouva\"],[13],[2,\"\\n  \"],[13],[2,\"\\n  \"],[10,\"div\"],[12],[2,\"Hi \"],[1,[32,0,[\"brand\",\"currentBoutique\",\"name\"]]],[2,\"!\"],[13],[2,\"\\n\"],[13],[2,\"\\n\\n\"],[1,[30,[36,1],[[30,[36,0],null,null]],null]],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"-outlet\",\"component\"]}",
    "moduleName": "flaneur/pods/services/quick-ship/template.hbs"
  });

  _exports.default = _default;
});