define("flaneur/pods/components/form-flows/product/category-details/component", ["exports", "ember-in-viewport"], function (_exports, _emberInViewport) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const FALLBACK_HS_CODE = 69120085;

  var _default = Ember.Component.extend(_emberInViewport.default, {
    media: Ember.inject.service(),
    classNames: ['category-details'],
    elementId: 'category-details',
    selectedMacro: null,
    selectedParent: null,
    selectedTerminal: null,
    selectedCategory: null,

    didReceiveAttrs() {
      this._super(...arguments);

      const categories = this.categories;
      const [categoryId] = this.product.categories;

      if (categoryId && categories.length > 0) {
        const [selectedCategory] = categories.filter(category => category._id === categoryId);

        this._setCategories(selectedCategory);
      }
    },

    errorMessage: Ember.computed('model.fieldMessages.{category,categories}', function () {
      const validationMessages = this.model.fieldMessages;

      if (validationMessages['categories']) {
        const [category] = validationMessages.categories;
        return category.message;
      }

      if (validationMessages['category.isTerminal']) {
        return validationMessages['category.isTerminal'].get('firstObject').message;
      }

      return undefined;
    }),
    macros: Ember.computed('categories', function () {
      const categories = this.categories;
      return categories.filter(category => category.isMacro === true);
    }),
    parents: Ember.computed('categories', 'selectedCategory', 'selectedMacro', function () {
      const categories = this.categories;
      const selectedMacro = this.selectedMacro;
      return categories.filter(category => category.isParent === true && category.macro === selectedMacro);
    }),
    terminals: Ember.computed('categories', 'selectedParent', function () {
      const categories = this.categories;
      const selectedParent = this.selectedParent;
      return categories.filter(category => category.isTerminal === true && category.parent === selectedParent);
    }),

    _setCategories(selectedCategory) {
      if (!this.product.hs_code) {
        Ember.set(this.product, 'hs_code', {});
      }

      this.setProperties({
        selectedCategory: selectedCategory,
        'product.category': selectedCategory,
        'product.categories.0': selectedCategory._id,
        'product.hs_code.default': selectedCategory.hs_code || FALLBACK_HS_CODE
      });
      const {
        _id,
        macro,
        parent,
        isMacro,
        isParent,
        isTerminal
      } = selectedCategory;

      if (isMacro) {
        return this.setProperties({
          selectedMacro: _id,
          selectedParent: null,
          selectedTerminal: null
        });
      }

      if (isParent) {
        return this.setProperties({
          selectedMacro: macro,
          selectedParent: _id,
          selectedTerminal: null
        });
      }

      if (isParent && isTerminal) {
        return this.setProperties({
          selectedMacro: macro,
          selectedParent: _id,
          selectedTerminal: null
        });
      }

      this.setProperties({
        selectedMacro: macro,
        selectedParent: parent,
        selectedTerminal: _id
      });
    },

    actions: {
      setCategory(category) {
        return this._setCategories(category);
      }

    }
  });

  _exports.default = _default;
});