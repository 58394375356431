define("flaneur/util/address", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.requiredFields = requiredFields;
  _exports.isFieldRequired = isFieldRequired;
  _exports.isPostcodeRequired = isPostcodeRequired;
  _exports.isCountyRequired = isCountyRequired;
  _exports.postcodeLabel = postcodeLabel;
  _exports.countyLabel = countyLabel;
  _exports.fieldLabelsForCountry = fieldLabelsForCountry;

  function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); enumerableOnly && (symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols); } return keys; }

  function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = null != arguments[i] ? arguments[i] : {}; i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { _defineProperty(target, key, source[key]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } return target; }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function requiredFields(isoCode) {
    // Presence, is a common validation, so generalising here
    const presenceValidation = {
      type: 'presence',
      options: {
        presence: true
      }
    }; // Country agnostic base fields

    const baseFields = {
      address_line_1: {
        validations: [presenceValidation, {
          type: 'length',
          options: {
            min: 1
          }
        }]
      },
      city: {
        validations: [presenceValidation, {
          type: 'length',
          options: {
            min: 2
          }
        }]
      },
      country: {
        validations: [presenceValidation]
      }
    };

    switch (isoCode.toUpperCase()) {
      case 'HK': // Hong Kong

      case 'AE':
        // United Arab Emirates
        return _objectSpread({}, baseFields);

      case 'IE':
        // Ireland
        return _objectSpread(_objectSpread({}, baseFields), {}, {
          postcode: {
            validations: []
          }
        });

      case 'US':
        // United States of America
        return _objectSpread(_objectSpread({}, baseFields), {}, {
          county: {
            validations: [presenceValidation, {
              type: 'length',
              options: {
                is: 2
              }
            }]
          },
          postcode: {
            validations: [presenceValidation]
          }
        });

      default:
        return _objectSpread(_objectSpread({}, baseFields), {}, {
          postcode: {
            validations: [presenceValidation]
          }
        });
    }
  }

  function isFieldRequired(isoCode, field) {
    const fields = Object.keys(requiredFields(isoCode));
    return fields.includes(field);
  }

  function isPostcodeRequired(isoCode) {
    return isFieldRequired(isoCode, 'postcode');
  }

  function isCountyRequired(isoCode) {
    return isFieldRequired(isoCode, 'county');
  }

  function postcodeLabel(isoCode) {
    switch (isoCode.toUpperCase()) {
      case 'GB':
        // Great britain
        return 'Postcode';

      case 'IE':
        // Ireland
        return 'EIRCODE';

      case 'US':
        // United states
        return 'Zip Code';

      default:
        return 'Postal code';
    }
  }

  function countyLabel(isoCode) {
    switch (isoCode.toUpperCase()) {
      case 'GB':
        // Great britain
        return 'County';

      case 'US': // United states

      case 'AU':
        // Australia
        return 'State';

      default:
        return 'Province / Region';
    }
  }

  function fieldLabelsForCountry(isoCode) {
    return {
      address_line_1: 'Address line 1',
      address_line_2: 'Address line 2',
      city: 'City',
      country: 'Country',
      postcode: postcodeLabel(isoCode),
      countyLabel: countyLabel(isoCode)
    };
  }
});