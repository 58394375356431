define("flaneur/pods/brand/boutiques/boutique/controller", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    isGBR: Ember.computed.readOnly('brand.currentBoutique.isGBR')
  });

  _exports.default = _default;
});