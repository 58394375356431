define("flaneur/pods/components/pages/products/bulk/upload/component", ["exports", "ember-concurrency", "flaneur/models/product", "flaneur/models/boutique"], function (_exports, _emberConcurrency, _product, _boutique) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const _excluded = ["csvLine", "cachedCategoryHierarchy"],
        _excluded2 = ["csvLine", "sku"];

  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _dec8, _dec9, _dec10, _dec11, _dec12, _dec13, _dec14, _dec15, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _descriptor6, _descriptor7, _descriptor8, _descriptor9;

  function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); enumerableOnly && (symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols); } return keys; }

  function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = null != arguments[i] ? arguments[i] : {}; i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { _defineProperty(target, key, source[key]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } return target; }

  function _objectWithoutProperties(source, excluded) { if (source == null) return {}; var target = _objectWithoutPropertiesLoose(source, excluded); var key, i; if (Object.getOwnPropertySymbols) { var sourceSymbolKeys = Object.getOwnPropertySymbols(source); for (i = 0; i < sourceSymbolKeys.length; i++) { key = sourceSymbolKeys[i]; if (excluded.indexOf(key) >= 0) continue; if (!Object.prototype.propertyIsEnumerable.call(source, key)) continue; target[key] = source[key]; } } return target; }

  function _objectWithoutPropertiesLoose(source, excluded) { if (source == null) return {}; var target = {}; var sourceKeys = Object.keys(source); var key, i; for (i = 0; i < sourceKeys.length; i++) { key = sourceKeys[i]; if (excluded.indexOf(key) >= 0) continue; target[key] = source[key]; } return target; }

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  let PagesProductsBulkUpload = (_dec = Ember.inject.service, _dec2 = Ember.inject.service, _dec3 = Ember.inject.service, _dec4 = Ember.inject.service, _dec5 = Ember.inject.service, _dec6 = Ember.inject.service, _dec7 = Ember.computed.alias('productsBulkUploader.products'), _dec8 = Ember.computed.notEmpty('productsBulkUploader.csvFileInfo'), _dec9 = Ember.computed('products.{@each.images,@each.categories}'), _dec10 = Ember.computed('isUploadStepComplete', 'isAmendStepComplete', 'isReviewStepComplete'), _dec11 = Ember.computed('hasCsvFileInfo', 'isUploadStepComplete'), _dec12 = Ember._action, _dec13 = Ember._action, _dec14 = Ember._action, _dec15 = Ember._action, (_class = class PagesProductsBulkUpload extends Ember.Component {
    constructor(...args) {
      super(...args);

      _initializerDefineProperty(this, "analytics", _descriptor, this);

      _initializerDefineProperty(this, "brand", _descriptor2, this);

      _initializerDefineProperty(this, "notify", _descriptor3, this);

      _initializerDefineProperty(this, "productsBulkUploader", _descriptor4, this);

      _initializerDefineProperty(this, "router", _descriptor5, this);

      _initializerDefineProperty(this, "session", _descriptor6, this);

      _defineProperty(this, "classNames", ['page']);

      _defineProperty(this, "isUploadStepComplete", false);

      _defineProperty(this, "isAmendStepComplete", false);

      _defineProperty(this, "uploadGuideUrl", 'https://trouvaboutiques.zendesk.com/hc/en-us/articles/360008025098-How-to-bulk-upload-products');

      _initializerDefineProperty(this, "products", _descriptor7, this);

      _initializerDefineProperty(this, "hasCsvFileInfo", _descriptor8, this);

      _initializerDefineProperty(this, "createProductsTask", _descriptor9, this);
    }

    get isReviewStepComplete() {
      return this.products.length && this.products.every(({
        images,
        categories
      }) => images.length && categories.length);
    }

    get progressSteps() {
      return [{
        key: 'upload',
        label: 'Upload .CSV',
        complete: this.isUploadStepComplete
      }, {
        key: 'amend',
        label: 'Resolve conflicts',
        complete: this.isAmendStepComplete
      }, {
        key: 'review',
        label: 'Add categories and images',
        complete: this.isReviewStepComplete
      }];
    }

    get showDownloadTemplate() {
      return !this.hasCsvFileInfo && !this.isUploadStepComplete;
    }

    addHistoryEntryToBoutique(createdProducts) {
      const [product] = createdProducts;
      const boutique = {
        _id: this.brand.currentBoutique._id,
        bulk_upload_history: this.brand.currentBoutique.bulk_upload_history || []
      };
      const historyEntry = {
        status: 'created',
        bulk_operation_id: product.created_metadata.bulk_operation_id,
        products: createdProducts.map(({
          _id
        }) => _id),
        filename: this.productsBulkUploader.csvFileInfo.name,
        created_by: this.session.user._id,
        user_role: this.session.user.role
      };
      boutique.bulk_upload_history.push(historyEntry);
      return boutique;
    }

    completeUploadStep() {
      this.analytics.gtm({
        action: 'bulk_upload_complete_step',
        label: 'upload'
      });
      const amendErrors = this.productsBulkUploader.errors.filter(this.productsBulkUploader.isAmendError);

      if (Ember.isPresent(amendErrors)) {
        this.set('isUploadStepComplete', true);
        return;
      }

      this.setProperties({
        isUploadStepComplete: true,
        isAmendStepComplete: true
      });
      this.analytics.gtm({
        action: 'bulk_upload_no_amend_errors'
      });
      this.analytics.gtm({
        action: 'bulk_upload_complete_step',
        label: 'amend'
      });
    }

    backToUploadStep() {
      this.set('isUploadStepComplete', false);
      this.analytics.gtm({
        action: 'bulk_upload_back_to_step',
        label: 'upload'
      });
    }

    completeAmendStep() {
      this.set('isAmendStepComplete', true);
      this.analytics.gtm({
        action: 'bulk_upload_complete_step',
        label: 'amend'
      });
    }

    notifyReviewStepChange() {
      this.notifyPropertyChange('isReviewStepComplete');
    }

  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "analytics", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "brand", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "notify", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "productsBulkUploader", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "router", [_dec5], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor6 = _applyDecoratedDescriptor(_class.prototype, "session", [_dec6], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor7 = _applyDecoratedDescriptor(_class.prototype, "products", [_dec7], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor8 = _applyDecoratedDescriptor(_class.prototype, "hasCsvFileInfo", [_dec8], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "isReviewStepComplete", [_dec9], Object.getOwnPropertyDescriptor(_class.prototype, "isReviewStepComplete"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "progressSteps", [_dec10], Object.getOwnPropertyDescriptor(_class.prototype, "progressSteps"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "showDownloadTemplate", [_dec11], Object.getOwnPropertyDescriptor(_class.prototype, "showDownloadTemplate"), _class.prototype), _descriptor9 = _applyDecoratedDescriptor(_class.prototype, "createProductsTask", [_emberConcurrency.task], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return function* () {
        const strippedProducts = this.products.map(_ref => {
          let {
            csvLine,
            cachedCategoryHierarchy
          } = _ref,
              product = _objectWithoutProperties(_ref, _excluded);

          const strippedStockLines = product.stock_lines.map(_ref2 => {
            let {
              csvLine,
              sku
            } = _ref2,
                stockLine = _objectWithoutProperties(_ref2, _excluded2);

            return _objectSpread(_objectSpread({}, stockLine), {}, {
              sku: sku === null || sku === void 0 ? void 0 : sku.value,
              variants: stockLine.variants.map(({
                _id
              }) => _id)
            });
          });
          return _objectSpread(_objectSpread({}, product), {}, {
            categories: product.categories.map(({
              _id
            }) => _id),
            standard_manufacturer: product.standard_manufacturer._id,
            stock_lines: strippedStockLines
          });
        });
        const createdProducts = yield _product.default.bulkCreate(strippedProducts);
        const boutique = this.addHistoryEntryToBoutique(createdProducts);
        yield _boutique.default.partialSave(boutique);
        this.router.transitionTo('brand.boutiques.boutique.products');
        this.notify.show('success', 'Your products have been created!', 5000);
        this.analytics.gtm({
          action: 'bulk_upload_create_products',
          label: strippedProducts.length
        });
        this.productsBulkUploader.reset();
      };
    }
  }), _applyDecoratedDescriptor(_class.prototype, "completeUploadStep", [_dec12], Object.getOwnPropertyDescriptor(_class.prototype, "completeUploadStep"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "backToUploadStep", [_dec13], Object.getOwnPropertyDescriptor(_class.prototype, "backToUploadStep"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "completeAmendStep", [_dec14], Object.getOwnPropertyDescriptor(_class.prototype, "completeAmendStep"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "notifyReviewStepChange", [_dec15], Object.getOwnPropertyDescriptor(_class.prototype, "notifyReviewStepChange"), _class.prototype)), _class));
  _exports.default = PagesProductsBulkUpload;
});