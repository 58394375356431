define("flaneur/pods/components/curate-empty-basket/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "h9FGDq9n",
    "block": "{\"symbols\":[\"@brand\"],\"statements\":[[10,\"div\"],[15,0,[31,[[32,0,[\"styleNamespace\"]],\" curate-empty-basket\"]]],[12],[2,\"\\n  \"],[1,[30,[36,0],[\"curate-empty-order-form\"],[[\"class\"],[\"curate-empty-basket__icon\"]]]],[2,\"\\n  \"],[10,\"h1\"],[14,0,\"curate-empty-basket__title\"],[12],[2,\"Nothing on your basket\"],[13],[2,\"\\n  \"],[10,\"p\"],[14,0,\"curate-empty-basket__description\"],[12],[2,\"\\n    Looks like you haven’t added anything to your \"],[1,[32,1,[\"name\"]]],[2,\" basket yet.\\n  \"],[13],[2,\"\\n\\n  \"],[8,\"link-to\",[[24,0,\"button button--primary\"]],[[\"@track\",\"@route\"],[[30,[36,1],[[32,0,[\"trackBackToCurate\"]]],null],\"brand.boutiques.boutique.curate.brands.brand\"]],[[\"default\"],[{\"statements\":[[2,\"\\n    Continue shopping\\n  \"]],\"parameters\":[]}]]],[2,\"\\n\"],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"svg-jar\",\"fn\"]}",
    "moduleName": "flaneur/pods/components/curate-empty-basket/template.hbs"
  });

  _exports.default = _default;
});