define("flaneur/models/reservation-boutique", ["exports", "flaneur/mixins/request"], function (_exports, _request) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); enumerableOnly && (symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols); } return keys; }

  function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = null != arguments[i] ? arguments[i] : {}; i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { _defineProperty(target, key, source[key]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } return target; }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  const ReservationBoutique = Ember.Object.extend(_request.default, {
    // Instance methods

    /**
     * Update a reservation
     * @return {Object} Promise
     */
    put() {
      return this.request({
        method: 'PUT',
        url: this.apiURL(`reservation_boutiques/${this._id}`),
        data: JSON.stringify({
          _id: this._id,
          shipping_provider: this.shipping_provider,
          other_tracking_info: this.other_tracking_info,
          other_shipping_carrier: this.other_shipping_carrier,
          marked_as_collected: this.marked_as_collected,
          marked_as_collected_at: this.marked_as_collected_at,
          requested_manual_label: this.requested_manual_label,
          printed_shipping_label: this.printed_shipping_label,
          printed_receipt: this.printed_receipt,
          printed_customs_form: this.printed_customs_form
        })
      }).catch(err => {
        throw err.responseJSON ? err.responseJSON.errors[0].detail : err.message;
      });
    }

  });
  ReservationBoutique.reopenClass(_request.default, {
    // Class methods
    _create(reservationBoutique) {
      reservationBoutique.container = this.container;
      return this.create(reservationBoutique);
    },

    setContainer(container) {
      this.container = container;
    },

    find(data) {
      return this.request({
        method: 'GET',
        url: this.apiURL('reservation_boutiques'),
        data
      }).then(res => {
        return res.reservation_boutiques.map(reservationBoutique => this._create(reservationBoutique));
      });
    },

    count(data) {
      return this.request({
        method: 'GET',
        url: this.apiURL('reservation_boutiques/count'),
        data
      });
    },

    findWithMeta(data) {
      return this.request({
        method: 'GET',
        url: this.apiURL('reservation_boutiques'),
        data
      }).then(res => {
        return _objectSpread(_objectSpread({}, res), {}, {
          reservation_boutiques: res.reservation_boutiques.map(reservationBoutique => this._create(reservationBoutique))
        });
      });
    }

  });
  var _default = ReservationBoutique;
  _exports.default = _default;
});