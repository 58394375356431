define("flaneur/pods/brand/boutiques/boutique/curate/brands/brand/basket/route", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  class BrandBoutiquesBoutiqueCurateBrandsBrandBasketRoute extends Ember.Route {
    model() {
      return this.modelFor('brand.boutiques.boutique.curate.brands.brand');
    }

  }

  _exports.default = BrandBoutiquesBoutiqueCurateBrandsBrandBasketRoute;
});