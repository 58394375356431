define("flaneur/pods/components/packaging-placeholder-image/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "8bNQSCRX",
    "block": "{\"symbols\":[],\"statements\":[[10,\"div\"],[15,0,[31,[\"packaging-placeholder-image \",[32,0,[\"styleNamespace\"]]]]],[12],[2,\"\\n  \"],[8,\"fa-icon\",[],[[\"@icon\",\"@prefix\"],[\"box-full\",\"fal\"]],null],[2,\"\\n\"],[13]],\"hasEval\":false,\"upvars\":[]}",
    "moduleName": "flaneur/pods/components/packaging-placeholder-image/template.hbs"
  });

  _exports.default = _default;
});