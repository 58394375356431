define("flaneur/pods/brand/boutiques/boutique/products/index/controller", ["exports", "flaneur/utils/params-lists", "flaneur/models/product", "flaneur/mixins/bulk-update", "flaneur/enums/boutique-restock-information-origin"], function (_exports, _paramsLists, _product, _bulkUpdate, _boutiqueRestockInformationOrigin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend(_bulkUpdate.default, {
    notify: Ember.inject.service(),
    analytics: Ember.inject.service(),
    router: Ember.inject.service(),
    queryParams: ['archived', 'limit', 'page', 'q', 'sort', 'stage', 'bulkOperationId', 'stock', 'stockLeadDateFrom', 'stockLeadDateTo', {
      madeStatus: 'made-status'
    }, {
      sales: {
        type: 'boolean'
      }
    }],
    archived: false,
    limit: 20,
    page: 1,
    q: null,
    sales: null,
    sort: '-created_at',
    stage: 'none',
    stock: null,
    stockLeadDateFrom: null,
    stockLeadDateTo: null,
    bulkOperationId: null,
    madeStatus: null,
    isConfirmationModalOpen: false,
    isDiscountModalOpen: false,
    selectedDiscount: null,
    emptyPageItems: [{
      icon: 'plus-circle',
      title: 'Add new products',
      text: 'The best way to increase your performance on Trouva, is to make sure customers can shop your full range. You can start by adding products individually, upload in bulk from a spreadsheet or we can even sync your products directly from your Shopify site if you have one.'
    }, {
      icon: 'box-full',
      title: 'Optimise your product content',
      text: 'The more relevant and accurate information you share about your products, the more sales you can make! The best way to do this is to make sure you have the best possible product content by adding or editing details of any of your listings.'
    }],
    checkedProducts: Ember.computed.filterBy('model.products', 'checked', true),
    checkedCount: Ember.computed.alias('checkedProducts.length'),
    isEditMode: Ember.computed.gt('checkedCount', 0),
    singleProductAction: null,

    clearSingleProduct() {
      this.set('singleProductAction', null);
    },

    init() {
      this._super(...arguments);

      const defaults = {
        archived: this.archived,
        q: this.q,
        sort: this.sort,
        page: this.page,
        limit: this.limit,
        sales: this.sales,
        stock: this.stock,
        stockLeadDateFrom: this.stockLeadDateFrom,
        stockLeadDateTo: this.stockLeadDateTo,
        bulkOperationId: this.bulkOperationId,
        madeStatus: this.madeStatus
      };
      this.set('defaults', defaults);
      this.set('selectedDiscount', _paramsLists.discountList[0]);
    },

    hasNoProducts: Ember.computed('archived', 'bulkOperationId', 'madeStatus', 'model.products', 'q', 'sales', 'stage', 'stock', 'stockLeadDateFrom', 'stockLeadDateTo', function () {
      return Ember.isEmpty(this.model.products) && Ember.isEmpty(this.q) && Ember.isEmpty(this.sales) && Ember.isEmpty(this.stock) && Ember.isEmpty(this.stockLeadDateFrom) && Ember.isEmpty(this.stockLeadDateTo) && !this.archived && this.stage === 'none' && Ember.isEmpty(this.bulkOperationId) && Ember.isEmpty(this.madeStatus);
    }),
    actions: {
      applyBulkEdit(discount) {
        var _this$selectedDiscoun;

        return this.bulkUpdate('discount', discount ? discount === null || discount === void 0 ? void 0 : discount.params : (_this$selectedDiscoun = this.selectedDiscount) === null || _this$selectedDiscoun === void 0 ? void 0 : _this$selectedDiscoun.params, this.singleProductAction).then(() => {
          this.notify.show('success', 'Changes applied to selected products');
        }).catch(() => {
          this.notify.show('error', 'There was an error applying the changes to your products. Please try again', 5000);
        }).finally(() => {
          this.set('isDiscountModalOpen', false);
          this.set('selectedDiscount', _paramsLists.discountList[0]);
          this.set('singleProductAction', null);
        });
      },

      showModal(modalType, product) {
        if (modalType === 'confirmation') {
          this.set('isConfirmationModalOpen', true);
        }

        if (modalType === 'discount') {
          this.set('isDiscountModalOpen', true);
        }

        if (product) {
          this.set('singleProductAction', product);
        }
      },

      archiveSelected() {
        if (this.singleProductAction) {
          const products = [this.singleProductAction];
          return _product.default.bulkRemove(products).then(() => {
            this.set('isConfirmationModalOpen', false);
            this.clearSingleProduct();
            this.model.products.removeObjects(products);
            this.set('model.meta.total', this.currentModel.meta.total - products.length);
            this.notify.show('success', 'Selected products were archived');
          });
        }

        const checkedProducts = this.model.products.filter(({
          checked
        }) => checked);
        return _product.default.bulkRemove(checkedProducts).then(() => {
          this.set('isConfirmationModalOpen', false);
          this.model.products.removeObjects(checkedProducts);
          this.set('model.meta.total', this.currentModel.meta.total - checkedProducts.length);
          this.notify.show('success', 'Selected products were archived');
        });
      },

      setCheckedProducts(checked) {
        this.model.products.setEach('checked', checked);
      },

      showRestockModal() {
        const checkedProduct = this.model.products.filter(({
          checked
        }) => checked);
        this.router.transitionTo({
          queryParams: {
            'restock-product': checkedProduct[0]._id,
            'restock-origin': _boutiqueRestockInformationOrigin.default.BULK_EDIT
          }
        });
        this.analytics.gtm({
          action: 'restock-modal-open',
          label: 'drawer'
        });
      },

      closeConfirmationModal() {
        this.set('isConfirmationModalOpen', false);
        this.clearSingleProduct();
      },

      closeDiscountModal() {
        this.set('isDiscountModalOpen', false);
        this.clearSingleProduct();
      }

    }
  });

  _exports.default = _default;
});