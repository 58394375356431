define("flaneur/helpers/get-string", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.getString = getString;
  _exports.default = void 0;

  function getString([obj, key]) {
    return !obj ? undefined : obj[key];
  }

  var _default = Ember.Helper.helper(getString);

  _exports.default = _default;
});