define("flaneur/pods/components/pages/settings/contact-details/component", ["exports", "flaneur/models/boutique/validations"], function (_exports, _validations) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const fields = ['primary_contact_name', 'primary_phone', 'primary_email', 'support_email', 'support_phone', 'order_email', 'statement_email'];
  const Validations = (0, _validations.getSpecificValidations)(fields, {
    prefix: 'boutique.'
  });

  var _default = Ember.Component.extend(Validations, {
    // Public API
    onSave() {},

    didInsertElement() {
      this._super(...arguments);

      Ember.set(this.boutique, 'fields', fields);
    },

    // Private API
    classNames: ['subpage'],
    actions: {
      async save() {
        const {
          validations
        } = await this.validations.validate();
        return this.onSave(fields, validations);
      }

    }
  });

  _exports.default = _default;
});