define("flaneur/pods/components/form-flows/_base/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); enumerableOnly && (symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols); } return keys; }

  function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = null != arguments[i] ? arguments[i] : {}; i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { _defineProperty(target, key, source[key]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } return target; }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  const NOTIFY_ERROR = 'Please fix the error in the form and try again.';

  function findStepIndex(steps, step, subStep) {
    if (Ember.isEmpty(subStep)) {
      return steps.findIndex(item => item.step === step);
    }

    return steps.findIndex(item => item.step === step && item.subStep === subStep);
  }

  var _default = Ember.Component.extend({
    notify: Ember.inject.service(),
    // Public API
    baseComponentPath: null,
    steps: null,

    onSuccessfulNextStep() {},

    init() {
      this._super(...arguments); // Make properties on development only


      (false && !(Array.isArray(this.steps)) && Ember.assert('"steps" property missing or is not an array', Array.isArray(this.steps)));
      (false && !(typeof this.baseComponentPath === 'string') && Ember.assert('"baseComponentPath" property is not a string', typeof this.baseComponentPath === 'string'));
    },

    // Step management
    currentStepIndex: 0,
    currentStep: Ember.computed('currentStepIndex', function () {
      const {
        currentStepIndex,
        baseComponentPath,
        steps
      } = this;
      const step = steps[currentStepIndex];
      return _objectSpread(_objectSpread({}, step), {}, {
        component: `${baseComponentPath}/${step.step}`
      });
    }),
    // Navigation button flags
    isPrevButtonEnabled: Ember.computed.gt('currentStepIndex', 0),
    isNextButtonEnabled: Ember.computed('currentStepIndex', 'steps.length', function () {
      const currentStepIndex = this.currentStepIndex;
      const lastStepIndex = this.steps.length - 1;
      return currentStepIndex !== lastStepIndex;
    }),
    isSubmitButtonEnabled: Ember.computed('currentStepIndex', 'steps.length', function () {
      const currentStepIndex = this.currentStepIndex;
      const lastStepIndex = this.steps.length - 1;
      return currentStepIndex === lastStepIndex;
    }),
    actions: {
      goToStep(step, subStep) {
        const stepIndex = findStepIndex(this.steps, step, subStep);
        this.notify.hideErrors();
        this.set('currentStepIndex', stepIndex);
      },

      prevStep() {
        this.notify.hideErrors();
        this.decrementProperty('currentStepIndex');
      },

      nextStep() {
        const currentStep = this.currentStep;
        const VALIDATION_ERROR = 'VALIDATION_ERROR';
        this.notify.hideErrors();
        return Ember.RSVP.resolve().then(() => {
          const stepHasValidations = Boolean(currentStep.validate);

          if (!stepHasValidations) {
            this.set('errors', undefined);
            return;
          }

          const validationResult = currentStep.validate();

          if (validationResult !== true) {
            this.set('errors', validationResult);
            return Ember.RSVP.reject({
              type: VALIDATION_ERROR,
              errors: validationResult
            });
          }

          this.set('errors', undefined);
          return;
        }).then(() => this.onSuccessfulNextStep()).then(() => this.incrementProperty('currentStepIndex')).catch(error => {
          if (error.type === VALIDATION_ERROR) {
            this.notify.show('error', NOTIFY_ERROR);
          }

          throw error;
        });
      }

    }
  });

  _exports.default = _default;
});