define("flaneur/pods/components/boutique-restock-recommendation-scroll/card/component", ["exports", "@glimmer/component", "ember-component-css/pod-names", "flaneur/enums/boutique-restock-information-origin"], function (_exports, _component, _podNames, _boutiqueRestockInformationOrigin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _class, _descriptor, _descriptor2;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  let BoutiqueRestockRecommendationScrollCardComponent = (_dec = Ember.inject.service, _dec2 = Ember.inject.service, _dec3 = Ember._action, _dec4 = Ember._action, (_class = class BoutiqueRestockRecommendationScrollCardComponent extends _component.default {
    constructor(...args) {
      super(...args);

      _initializerDefineProperty(this, "analytics", _descriptor, this);

      _initializerDefineProperty(this, "router", _descriptor2, this);

      _defineProperty(this, "styleNamespace", _podNames.default['boutique-restock-recommendation-scroll/card']);
    }

    get product() {
      return this.args.recommendation.product;
    }

    get isOnSale() {
      return Ember.isPresent(this.product.localSalesPrice.amount);
    }

    get currentQuantity() {
      return this.args.recommendation.currentQuantity;
    }

    get isOutOfStock() {
      return this.currentQuantity === 0;
    }

    addStock() {
      this.analytics.gtm({
        action: 'restock-modal-open',
        label: 'carousel-add'
      });
      return this.router.transitionTo({
        queryParams: {
          'restock-product': this.product.id,
          'restock-origin': _boutiqueRestockInformationOrigin.default.ORDERS_SCROLL_CARD
        }
      });
    }

    cancel() {
      this.analytics.gtm({
        action: 'restock-modal-open',
        label: 'carousel-cancel'
      });
      return this.router.transitionTo({
        queryParams: {
          'restock-product': this.product.id,
          'restock-origin': _boutiqueRestockInformationOrigin.default.ORDERS_SCROLL_CARD,
          'restock-action': 'cancel'
        }
      });
    }

  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "analytics", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "router", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "addStock", [_dec3], Object.getOwnPropertyDescriptor(_class.prototype, "addStock"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "cancel", [_dec4], Object.getOwnPropertyDescriptor(_class.prototype, "cancel"), _class.prototype)), _class));
  _exports.default = BoutiqueRestockRecommendationScrollCardComponent;
});