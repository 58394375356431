define("flaneur/helpers/json-length", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.jsonLength = jsonLength;
  _exports.default = void 0;

  function jsonLength([json]) {
    return Object.keys(json).length;
  }

  var _default = Ember.Helper.helper(jsonLength);

  _exports.default = _default;
});