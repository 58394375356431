define("flaneur/pods/components/discard-changes-modal/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "Sxmu74K3",
    "block": "{\"symbols\":[\"modal\",\"button\"],\"statements\":[[6,[37,6],null,[[\"active\"],[[35,5]]],[[\"default\"],[{\"statements\":[[6,[37,4],[[32,1,[\"body\"]]],null,[[\"default\"],[{\"statements\":[[2,\"    \"],[10,\"h1\"],[14,0,\"title\"],[12],[2,\"Wait!\"],[13],[2,\"\\n    \"],[10,\"p\"],[12],[2,\"Are you sure you want to discard your unsaved changes?\"],[13],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"\\n\"],[6,[37,4],[[32,1,[\"actions\"]]],null,[[\"default\"],[{\"statements\":[[2,\"    \"],[8,\"button\",[[24,4,\"submit\"]],[[\"@onClick\",\"@loading\"],[[34,0],[34,1]]],[[\"default\"],[{\"statements\":[[2,\"\\n\"],[6,[37,2],[[32,2,[\"loading\"]]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"        Discarding... \"],[8,[32,2,[\"spinner\"]],[],[[],[]],null],[2,\"\\n\"]],\"parameters\":[]},{\"statements\":[[2,\"        Discard\\n\"]],\"parameters\":[]}]]],[2,\"    \"]],\"parameters\":[2]}]]],[2,\"\\n    \"],[8,\"button\",[],[[\"@theme\",\"@onClick\"],[\"secondary\",[30,[36,3],[[32,0],[32,1,[\"dismiss\"]]],null]]],[[\"default\"],[{\"statements\":[[2,\"\\n      Cancel\\n    \"]],\"parameters\":[]}]]],[2,\"\\n\"]],\"parameters\":[]}]]]],\"parameters\":[1]}]]]],\"hasEval\":false,\"upvars\":[\"confirm\",\"uploading\",\"if\",\"action\",\"component\",\"active\",\"trouva-elements/modal\"]}",
    "moduleName": "flaneur/pods/components/discard-changes-modal/template.hbs"
  });

  _exports.default = _default;
});