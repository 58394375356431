define("flaneur/pods/components/reservation-product/active/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    analytics: Ember.inject.service(),
    isOtherIssue: false,
    isOutOfStock: false,
    reservationStatusLabel: Ember.computed('isOutOfStock', 'isOtherIssue', function () {
      return this.isOtherIssue ? 'Other issue' : 'Out of stock';
    }),
    actions: {
      markAsOutOfStock(reservation) {
        this.toggleProperty('isOutOfStock');
        reservation.toggleProperty('_outOfStock');
      },

      markAsHavingIssue(reservation) {
        this.toggleProperty('isOtherIssue');
        reservation.toggleProperty('_hasOtherIssue');
      }

    }
  });

  _exports.default = _default;
});