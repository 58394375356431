define("flaneur/pods/brand/boutiques/boutique/curate/terms/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "H5mv+5ca",
    "block": "{\"symbols\":[],\"statements\":[[8,\"pages/curate/terms\",[],[[],[]],null],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[]}",
    "moduleName": "flaneur/pods/brand/boutiques/boutique/curate/terms/template.hbs"
  });

  _exports.default = _default;
});