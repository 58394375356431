define("flaneur/pods/components/metabase-embed/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "whl/pqtO",
    "block": "{\"symbols\":[],\"statements\":[[10,\"div\"],[15,0,[31,[\"metabase-embed \",[32,0,[\"styleNamespace\"]]]]],[12],[2,\"\\n\"],[6,[37,0],[[32,0,[\"hasIframeURL\"]]],null,[[\"default\"],[{\"statements\":[[2,\"    \"],[10,\"iframe\"],[14,\"title\",\"Metabase dashboard\"],[15,\"src\",[32,0,[\"iframeURL\"]]],[14,\"frameborder\",\"0\"],[14,\"width\",\"100%\"],[14,\"height\",\"1850\"],[14,\"allowtransparency\",\"\"],[12],[2,\"\\n    \"],[13],[2,\"\\n\"]],\"parameters\":[]}]]],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"if\"]}",
    "moduleName": "flaneur/pods/components/metabase-embed/template.hbs"
  });

  _exports.default = _default;
});