define("flaneur/pods/components/rp-modals/shopify-sync-started/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "JZa+TMuI",
    "block": "{\"symbols\":[\"modal\"],\"statements\":[[6,[37,3],null,[[\"active\"],[[35,2]]],[[\"default\"],[{\"statements\":[[6,[37,1],[[32,1,[\"body\"]]],null,[[\"default\"],[{\"statements\":[[2,\"    \"],[10,\"div\"],[14,0,\"shopify-sync-started__content-group\"],[12],[2,\"\\n      \"],[10,\"div\"],[14,0,\"shopify-sync-started__content\"],[12],[2,\"\\n        \"],[10,\"h2\"],[12],[2,\"Thanks for connecting your Shopify store to Trouva.\"],[13],[2,\"\\n        \"],[10,\"p\"],[12],[2,\"We'll start syncing your products and let you know when that's complete.\"],[13],[2,\"\\n        \"],[8,\"button\",[],[[\"@theme\",\"@onClick\"],[\"secondary\",[30,[36,0],[[32,0],[32,1,[\"dismiss\"]]],null]]],[[\"default\"],[{\"statements\":[[2,\"\\n          Dismiss\\n        \"]],\"parameters\":[]}]]],[2,\"\\n      \"],[13],[2,\"\\n    \"],[13],[2,\"\\n\"]],\"parameters\":[]}]]]],\"parameters\":[1]}]]]],\"hasEval\":false,\"upvars\":[\"action\",\"component\",\"active\",\"generic-modal\"]}",
    "moduleName": "flaneur/pods/components/rp-modals/shopify-sync-started/template.hbs"
  });

  _exports.default = _default;
});