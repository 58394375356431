define("flaneur/util/stock-line", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.generateStockLineSalesPrice = generateStockLineSalesPrice;
  _exports.generateStockLine = generateStockLine;
  _exports.convertProductToStockLines = convertProductToStockLines;
  _exports.convertBoutiquesArrayToStockLines = convertBoutiquesArrayToStockLines;
  _exports.hasSizes = hasSizes;

  function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); enumerableOnly && (symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols); } return keys; }

  function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = null != arguments[i] ? arguments[i] : {}; i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { _defineProperty(target, key, source[key]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } return target; }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  const GBP_ISO_CODE = 'GBP';
  /**
   * Takes a resource of an unknown type and returns the resource _id
   *
   * @param   {*} resource        - An unknown type, can either be an object, string or something else
   * @returns {string|undefined}  - String if the resource id is found, undefined if not
   */

  function getResourceId(resource) {
    switch (typeof resource) {
      case 'object':
        return resource._id;

      case 'string':
        return resource;

      default:
        (false && !(false) && Ember.assert('"resource" is not an object or string'));
        return;
    }
  }
  /**
   * Uses the appropriate sales price field, depending on the currency.
   * Promenade expects that:
   * - GBP boutiques to use the "sales_price" field
   * - NON-GBP boutiques to use "local_sales_price" field
   *
   * @param   {string}    currency    - Currency of the boutique
   * @param   {number}    price       - The sales price of the stock line
   * @returns {object}    The appropriate price object
   */


  function generateStockLineSalesPrice(currency, price) {
    if (currency !== GBP_ISO_CODE) {
      return {
        local_sales_price: {
          currency,
          amount: price
        }
      };
    }

    return {
      local_sales_price: {
        currency,
        amount: price
      },
      sales_price: price
    };
  }
  /**
   * Creates a stock line, taking in required fields as separate arguments and the rest as overrides
   *
   * @param   {string}    boutiqueId  - Boutique id
   * @param   {string}    productId   - Product id
   * @param   {string}    currency    - Currency of the boutique
   * @param   {number}    salesPrice  - The sales price of the stock line
   * @param   {object}    overrides   - overrides for the stock line
   * @returns {object}    Generated stock line
   */


  function generateStockLine(boutiqueId, productId, currency, salesPrice = 0, overrides = {}) {
    (false && !(typeof currency === 'string') && Ember.assert('"currency" is required & must be a string', typeof currency === 'string'));
    (false && !(typeof boutiqueId === 'string') && Ember.assert('"boutiqueId" is required & must be a string', typeof boutiqueId === 'string'));
    return _objectSpread(_objectSpread({
      boutique: boutiqueId,
      product: productId,
      // Default values, meant to be replaced by "overrides"
      sales: false,
      quantity: null,
      variants: [],
      dimensions: {
        width: null,
        height: null,
        length: null
      },
      care_instructions: ''
    }, generateStockLineSalesPrice(currency, salesPrice)), overrides);
  }
  /**
   * Takes a product.boutiques item and converts it to an array of one stock line.
   *
   * @param   {string}    boutiqueId  - Boutique id
   * @param   {string}    productId   - Product id
   * @param   {string}    currency    - Currency of the boutique
   * @param   {object}    pBoutique   - product.boutiques item
   * @returns {object[]}  Array with one stock line
   */


  function generateStockLinesWithoutSizes(boutiqueId, productId, currency, pBoutique) {
    const salesPrice = pBoutique.local_sales_price.amount;
    return [generateStockLine(boutiqueId, productId, currency, salesPrice, {
      quantity: pBoutique.quantity,
      sales: pBoutique.sales
    })];
  }
  /**
   * Takes a product.boutiques item and converts it to stock lines with sizes.
   *
   * @param   {string}    boutiqueId  - Boutique id
   * @param   {string}    productId   - Product id
   * @param   {string}    currency    - Currency of the boutique
   * @param   {object}    pBoutique   - product.boutiques item
   * @returns {object[]}  array of stock lines
   */


  function generateStockLinesWithSizes(boutiqueId, productId, currency, pBoutique) {
    return pBoutique.sizes.map(size => {
      const salesPrice = pBoutique.local_sales_price.amount;
      const sizeId = getResourceId(size.size);
      return generateStockLine(boutiqueId, productId, currency, salesPrice, {
        size: sizeId,
        sales: pBoutique.sales,
        quantity: size.quantity
      });
    });
  }
  /**
   * Convert product to using stock lines, prioritising existing stock lines.
   *
   * If stock lines exist already:
   * 1. delete boutiques array
   * 2. use existing stock lines
   *
   * If stock lines don't exist:
   * 1. create and attach stock lines
   * 2. delete boutiques array
   *
   * @param   {object} product    - Product to convert
   * @returns {object}            - Converted product
   */


  function convertProductToStockLines(product) {
    (false && !(typeof product.local_price === 'object') && Ember.assert('"local_price" is required', typeof product.local_price === 'object'));
    const currency = product.local_price.currency; // If stock lines exist already:
    // 1. delete boutiques array
    // 2. use existing stock lines

    if (product.stock_lines && product.stock_lines.length) {
      delete product.boutiques;
      delete product.sizes; // Regenerate stock lines in case new fields were added

      product.stock_lines = product.stock_lines.map(stockLine => {
        return generateStockLine(product.boutique, product._id, currency, 0, stockLine);
      });
      return product;
    } // If stock lines don't exist:
    // 1. create and attach stock lines
    // 2. delete boutiques array


    let stock_lines = convertBoutiquesArrayToStockLines(currency, product.boutiques, product._id);

    if (!stock_lines) {
      stock_lines = [generateStockLine(product.boutique, product._id, currency)];
    }

    product.stock_lines = stock_lines;
    delete product.boutiques;
    delete product.sizes;
    return product;
  }
  /**
   * Converts a product with a boutiques array into stock lines.
   *
   * @param {string}      currency        - The currency of the boutique
   * @param {object[]}    boutiquesArray  - The product.boutiques array
   * @param {string}      productId       - The product's ID
   * @returns {object[]}                  - Returns generated stock lines
   */


  function convertBoutiquesArrayToStockLines(currency, boutiquesArray, productId) {
    (false && !(currency !== undefined) && Ember.assert('"currency" is required when creating stock lines', currency !== undefined)); // If boutiques doesn't exist, exit.

    if (!boutiquesArray) return; // Convert boutiques to stock lines

    return boutiquesArray.reduce((stockLines, pBoutique) => {
      const boutiqueId = getResourceId(pBoutique.boutique); // If the product has multiple sizes, generate the stock lines with sizes

      if (pBoutique.sizes && pBoutique.sizes.length) {
        return stockLines.concat(generateStockLinesWithSizes(boutiqueId, productId, currency, pBoutique));
      } // If the product doesn't have sizes, generate stock lines without sizes


      return stockLines.concat(generateStockLinesWithoutSizes(boutiqueId, productId, currency, pBoutique));
    }, []);
  }
  /**
   * Checks if the stock lines have sizes.
   *
   * @param {object[]}    stock_lines - Array of stock lines
   * @returns {boolean}               - "true" if stock lines have sizes, "false" if not
   */


  function hasSizes(stock_lines) {
    return stock_lines.every(stock_line => stock_line.size);
  }
});