define("flaneur/models/user/index", ["exports", "date-fns", "flaneur/mixins/request", "flaneur/enums/retail-role-type", "flaneur/enums/retail-permission-type", "flaneur/models/user/validations"], function (_exports, _dateFns, _request, _retailRoleType, _retailPermissionType, _validations) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const User = Ember.Object.extend(_request.default, _validations.Validations, {
    isAdmin: Ember.computed.equal('role', 'admin'),
    isRetailAdmin: Ember.computed.equal('retailRole', _retailRoleType.default.ADMIN),
    createdSince: Ember.computed('created_at', function () {
      return (0, _dateFns.formatDistanceToNowStrict)((0, _dateFns.parseISO)(this.created_at), {
        addSuffix: true
      });
    }),
    retailRole: Ember.computed('retail_permissions.[]', function () {
      return (this.retail_permissions || []).includes(_retailPermissionType.default.ALL) ? _retailRoleType.default.ADMIN : _retailRoleType.default.USER;
    }),

    addToBrand(brandId) {
      return this.request({
        method: 'POST',
        url: this.apiURL('users/addToBrand'),
        data: JSON.stringify({
          brand: brandId,
          email: this.email
        })
      });
    },

    signup() {
      return this.request({
        method: 'POST',
        url: this.apiURL('signup'),
        data: JSON.stringify(this.sanitize())
      });
    },

    forgot() {
      return this.request({
        method: 'POST',
        url: this.apiURL('forgot'),
        data: JSON.stringify(this.sanitize())
      });
    },

    reset() {
      return this.request({
        method: 'POST',
        url: this.apiURL('reset'),
        data: JSON.stringify(this.sanitize())
      });
    },

    save() {
      return this.request({
        method: 'PUT',
        url: this.apiURL(`users/${this._id}`),
        data: JSON.stringify({
          _id: this._id,
          role: this.role,
          retail_permissions: this.retail_permissions,
          permissions: this.permissions
        })
      }).then(users => {
        this.setProperties(users[0]);
      });
    }

  });
  User.reopenClass(_request.default, {
    // Class methods
    _create(user) {
      return this.create(user);
    },

    setContainer(container) {
      this.container = container;
    },

    /**
     * Find user by id
     * @param  {String} id user _id
     * @return {Object}    Promise
     */
    findById(id) {
      return this.request({
        method: 'GET',
        url: this.apiURL(`users/${id}`)
      }).then(res => this._create(res.users[0]));
    },

    /**
     * Find user by ids
     * @param  {String} id user _id
     * @return {Object}    Promise
     */
    findByIds(ids) {
      return this.request({
        method: 'GET',
        url: this.apiURL(`users/${ids.join(',')}`)
      }).then(res => res.users.map(user => this._create(user)));
    }

  });
  var _default = User;
  _exports.default = _default;
});