define("flaneur/pods/zendesk/login/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "TU7DPiN8",
    "block": "{\"symbols\":[],\"statements\":[[8,\"zendesk-redirect\",[],[[],[]],[[\"default\"],[{\"statements\":[[2,\"\\n    \"],[10,\"h1\"],[14,0,\"zendesk-redirect__title\"],[12],[2,\"You are being redirected to the Boutique Help Centre\"],[13],[2,\"\\n    \"],[8,\"fa-icon\",[[24,0,\"zendesk-redirect__spinner\"]],[[\"@icon\",\"@prefix\",\"@spin\"],[\"spinner-third\",\"far\",true]],null],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[]}",
    "moduleName": "flaneur/pods/zendesk/login/template.hbs"
  });

  _exports.default = _default;
});