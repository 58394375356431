define("flaneur/pods/components/form-flows/product/description-details/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    media: Ember.inject.service(),
    classNames: ['description-details'],
    elementId: 'description-details',
    subStep: Ember.computed.alias('options.subStep'),
    priceErrors: Ember.computed('model.fieldMessages', function () {
      return this.model.fieldMessages['local_price.amount'];
    }),
    salesPriceErrors: Ember.computed.reads('model.fieldMessages.stock_lines.0.amount'),
    descriptionProgressStyle: Ember.computed('product.description.length', function () {
      return Ember.String.htmlSafe(`width: calc(${this.product.description.length}%/5); max-width: 100%;`);
    }),
    tooLongDescription: Ember.computed.gte('product.description.length', 1500),
    perfectLengthDescription: Ember.computed('product.description.length', function () {
      return this.product.description.length > 500 && this.product.description.length < 1500;
    }),
    goodLengthDescription: Ember.computed('product.description.length', function () {
      return this.product.description.length > 300 && this.product.description.length < 500;
    }),
    mediumLengthDescription: Ember.computed('product.description.length', function () {
      return this.product.description.length > 150 && this.product.description.length < 300;
    }),

    init() {
      this._super(...arguments);

      this.set('previousManufacturer', this.product.standard_manufacturer);
    },

    actions: {
      onChangeSalesToggle(product, salesValue) {
        return product.updateStockLineSales(salesValue);
      },

      updateSalesPrice(product, value) {
        return product.updateStockLineSalesPrice(value);
      },

      onCreateManufacturer(manufacturer) {
        this.set('product.standard_manufacturer', manufacturer);
      },

      onChangeManufacturer(manufacturer) {
        this.set('product.standard_manufacturer', manufacturer);
        this.set('product.unbranded', false);
      },

      onToggleUnbranded(unbranded) {
        if (unbranded) {
          this.set('previousManufacturer', this.product.standard_manufacturer);
        }

        this.set('product.standard_manufacturer', unbranded ? null : this.previousManufacturer);

        if (!unbranded && Ember.isEmpty(this.product.standard_manufacturer)) {
          this.set('product.unbranded', null);
          return;
        }

        this.set('product.unbranded', unbranded);
      }

    }
  });

  _exports.default = _default;
});