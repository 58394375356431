define("flaneur/pods/components/other-actions/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "ZdHvihqk",
    "block": "{\"symbols\":[\"collapsible\",\"@onClick\",\"&default\"],\"statements\":[[8,\"collapsible\",[[16,0,[32,0,[\"styleNamespace\"]]]],[[\"@onClick\"],[[32,2]]],[[\"default\"],[{\"statements\":[[2,\"\\n  \"],[8,[32,1,[\"label\"]],[],[[],[]],[[\"default\"],[{\"statements\":[[2,\"\\n    \"],[8,\"fa-icon\",[],[[\"@icon\",\"@prefix\"],[\"ellipsis-v\",\"far\"]],null],[2,\"\\n  \"]],\"parameters\":[]}]]],[2,\"\\n\\n  \"],[8,[32,1,[\"content\"]],[],[[],[]],[[\"default\"],[{\"statements\":[[2,\"\\n    \"],[18,3,null],[2,\"\\n  \"]],\"parameters\":[]}]]],[2,\"\\n\"]],\"parameters\":[1]}]]],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[]}",
    "moduleName": "flaneur/pods/components/other-actions/template.hbs"
  });

  _exports.default = _default;
});