define("flaneur/pods/components/form-flows/shipment/progress/component", ["exports", "@glimmer/component"], function (_exports, _component) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  class FormFlowsShipmentProgressComponent extends _component.default {
    get steps() {
      return [{
        key: 'quote',
        label: 'Quote',
        complete: this.args.currentStepIndex >= 1
      }, {
        key: 'customer-details',
        label: 'Delivery',
        complete: this.args.currentStepIndex >= 2
      }, {
        key: 'pickup-details',
        label: 'Pickup',
        complete: this.args.currentStepIndex >= 3
      }, {
        key: 'order-details',
        label: 'Shipment details',
        complete: this.args.currentStepIndex >= 4
      }, {
        key: 'book-courier',
        label: 'Review',
        complete: false
      }];
    }

  }

  _exports.default = FormFlowsShipmentProgressComponent;
});