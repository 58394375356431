define("flaneur/pods/components/pages/packaging/products/product/component", ["exports", "jquery", "@glimmer/component", "ember-concurrency", "ember-component-css/pod-names"], function (_exports, _jquery, _component, _emberConcurrency, _podNames) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _dec5, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4;

  function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); enumerableOnly && (symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols); } return keys; }

  function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = null != arguments[i] ? arguments[i] : {}; i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { _defineProperty(target, key, source[key]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } return target; }

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  let PagesPackagingProductsProduct = (_dec = Ember.inject.service, _dec2 = Ember.inject.service, _dec3 = Ember.inject.service, _dec4 = Ember.inject.service('packagingBasket'), _dec5 = Ember._action, (_class = class PagesPackagingProductsProduct extends _component.default {
    constructor(...args) {
      super(...args);

      _initializerDefineProperty(this, "analytics", _descriptor, this);

      _initializerDefineProperty(this, "brand", _descriptor2, this);

      _initializerDefineProperty(this, "media", _descriptor3, this);

      _initializerDefineProperty(this, "basket", _descriptor4, this);
    }

    get styleNamespace() {
      return _podNames.default['pages/packaging/products/product'];
    }

    get backgroundImage() {
      const [{
        url
      }] = this.args.product.images;
      return Ember.String.htmlSafe(`background-image: url("${url}")`);
    }

    get sortedVariants() {
      const variants = this.args.product.variants.map(variant => {
        return variant.quantity ? variant : _objectSpread(_objectSpread({}, variant), {}, {
          quantity: 1
        });
      });
      return variants.sortBy('sku');
    }

    scrollTop() {
      const scrollable = this.media.isLarge ? 'html, body' : '#outlet-container';
      (0, _jquery.default)(scrollable).animate({
        scrollTop: 0
      }, 300);
    }

    scrollToVariant() {
      const {
        selectedSku
      } = this.args;
      const {
        isLarge
      } = this.media;

      if (!selectedSku) {
        return;
      }

      const scrollTop = document.getElementById('variants');

      if (!scrollTop) {
        return;
      }

      const scrollable = isLarge ? 'html, body' : '#outlet-container';
      (0, _jquery.default)(scrollable).animate({
        scrollTop: scrollTop.offsetTop + 64
      }, 500);
    }

    *addToBasket(variant) {
      var _this$basket$toastAPI;

      if (!variant.quantity || variant.quantity === 0) {
        return;
      }

      yield (0, _emberConcurrency.timeout)(500);
      this.basket.add({
        quantity: variant.quantity,
        product: {
          id: this.args.product.id,
          name: this.args.product.name,
          image: this.args.product.images[0],
          variant: {
            sku: variant.sku,
            dimensions: variant.dimensions,
            color: variant.color,
            pack_size: variant.pack.units,
            pack_price: variant.pack.price
          }
        }
      });
      Ember.set(variant, 'quantity', 1);
      this.scrollTop();
      (_this$basket$toastAPI = this.basket.toastAPI) === null || _this$basket$toastAPI === void 0 ? void 0 : _this$basket$toastAPI.showToast();
      this.analytics.gtm({
        action: 'packaging_add_to_basket'
      });
    }

  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "analytics", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "brand", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "media", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "basket", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "scrollToVariant", [_dec5], Object.getOwnPropertyDescriptor(_class.prototype, "scrollToVariant"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "addToBasket", [_emberConcurrency.task], Object.getOwnPropertyDescriptor(_class.prototype, "addToBasket"), _class.prototype)), _class));
  _exports.default = PagesPackagingProductsProduct;
});