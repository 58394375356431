define("flaneur/models/frame/validations", ["exports", "ember-cp-validations"], function (_exports, _emberCpValidations) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.updateValidations = _exports.createValidations = void 0;
  const createValidations = (0, _emberCpValidations.buildValidations)({
    name: (0, _emberCpValidations.validator)('presence', true)
  });
  _exports.createValidations = createValidations;
  const updateValidations = (0, _emberCpValidations.buildValidations)({});
  _exports.updateValidations = updateValidations;
});