define("flaneur/pods/brand/boutiques/boutique/products/edit/route", ["exports", "flaneur/models/product", "flaneur/models/product-amendment"], function (_exports, _product, _productAmendment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    brand: Ember.inject.service(),
    navigationHistory: Ember.inject.service(),

    async model(params) {
      const {
        products
      } = await _product.default.find({
        _id: params.product_id,
        populate: 'standard_manufacturer,origin_country'
      });
      const [product] = products;
      return {
        product,
        boutique: this.brand.currentBoutique,
        isLoading: false,
        imageFolder: `brand/${product.brand}`,
        fieldMessages: {},
        // Error messages for each field
        curationNotes: {}
      };
    },

    async afterModel(model) {
      const boutiqueId = Ember.get(model, 'boutique._id');
      const productId = Ember.get(model, 'product._id');
      const res = await _productAmendment.default.findByProduct(boutiqueId, productId);
      Ember.set(model, 'curationNotes', res.field_product_amendments);
    },

    _isInvalidSalesPrice(product) {
      const isOnSale = product.get('currentNestedBoutique.sales');
      const isSalesPriceLessThanOrEqualToZero = product.get('currentNestedBoutique.local_sales_price.amount') <= 0;
      return isOnSale && isSalesPriceLessThanOrEqualToZero;
    },

    _setThumbnail(imgs) {
      const [thumbnail] = imgs;
      this.set('currentModel.product.thumbnail', thumbnail);
    },

    _previousRoute() {
      if (this.navigationHistory.previousRouteName === 'brand.boutiques.boutique.inventory') {
        return this.navigationHistory.previousRouteName;
      }

      return 'brand.boutiques.boutique.products.index';
    },

    actions: {
      onSave(product) {
        if (this._isInvalidSalesPrice(product)) {
          return this.notify.show('error', 'Sales price should be more than 0');
        }

        this.set('model.isLoading', true);
        return product.save().then(() => {
          this.set('model.isLoading', false);
          this.notify.show('success', 'Product successfully updated');
          this.analytics.gtm({
            action: 'edited_product',
            label: product._id
          });
          this.transitionTo(this._previousRoute(), this.brand, this.brand.currentBoutique);
          window.scrollTo(0, 0);
        }).catch(validationErrors => {
          this.set('currentModel.isLoading', false);
          this.set('currentModel.fieldMessages', validationErrors);
          this.notify.show('error', 'Your product is missing information. Please review fields in red.');
        });
      },

      async resolveAmendment(product, amendment) {
        await this.actions.onSave.call(this, product, false);
        await amendment.resolve();
      },

      imagesChanged(imgs) {
        this.set('currentModel.product.images', imgs);

        this._setThumbnail(imgs);
      },

      calculatePreviousRoute() {
        return this._previousRoute();
      }

    }
  });

  _exports.default = _default;
});