define("flaneur/pods/brand/boutiques/boutique/shipments/index/controller", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    brand: Ember.inject.service(),
    queryParams: ['ordernumber', ' page'],
    ordernumber: '',
    page: 1,
    hasNoShipments: Ember.computed('model.shipments.length', 'ordernumber', function () {
      return this.model.shipments.length <= 0 && Ember.isEmpty(this.ordernumber);
    }),
    actions: {
      openHelpCenter() {
        return window.open('https://trouvaboutiques.zendesk.com/hc/en-us/articles/360015615937');
      }

    }
  });

  _exports.default = _default;
});