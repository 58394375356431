define("flaneur/pods/brand/boutiques/boutique/curate/brands/brand/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "ePApY8uV",
    "block": "{\"symbols\":[\"@model\"],\"statements\":[[8,\"curate-basket-toast\",[],[[\"@brand\"],[[32,1,[\"brand\"]]]],null],[2,\"\\n\\n\"],[1,[30,[36,1],[[30,[36,0],null,null]],null]]],\"hasEval\":false,\"upvars\":[\"-outlet\",\"component\"]}",
    "moduleName": "flaneur/pods/brand/boutiques/boutique/curate/brands/brand/template.hbs"
  });

  _exports.default = _default;
});