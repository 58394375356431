define("flaneur/initializers/boutique", ["exports", "flaneur/models/boutique"], function (_exports, _boutique) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    name: 'boutique',
    initialize: function (application) {
      _boutique.default.setContainer(application.__container__);

      application.register('boutique:main', _boutique.default, {
        singleton: false
      });
    }
  };
  _exports.default = _default;
});