define("flaneur/initializers/secrets-service", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.initialize = initialize;
  _exports.default = void 0;

  function initialize(application) {
    application.inject('route', 'secrets', 'service:secrets');
    application.inject('component', 'secrets', 'service:secrets');
  }

  var _default = {
    name: 'secrets-service',
    initialize: initialize
  };
  _exports.default = _default;
});