define("flaneur/utils/products-bulk-upload/validations", ["exports", "ajv", "ajv-errors"], function (_exports, _ajv, _ajvErrors) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.dataPathRegExps = _exports.validateMultipleStockLines = _exports.validateOneStockLine = _exports.validateProduct = _exports.RESOURCE_ALREADY_EXISTS = _exports.RESOURCE_NOT_FOUND = void 0;

  function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); enumerableOnly && (symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols); } return keys; }

  function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = null != arguments[i] ? arguments[i] : {}; i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { _defineProperty(target, key, source[key]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } return target; }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  const RESOURCE_NOT_FOUND = '<%not-found%>';
  _exports.RESOURCE_NOT_FOUND = RESOURCE_NOT_FOUND;
  const RESOURCE_ALREADY_EXISTS = '<%already-exists%>';
  _exports.RESOURCE_ALREADY_EXISTS = RESOURCE_ALREADY_EXISTS;
  const ajv = new _ajv.default({
    allErrors: true,
    jsonPointers: true
  });
  (0, _ajvErrors.default)(ajv);
  const productSchema = {
    type: 'object',
    required: ['brand', 'boutiques', 'name', 'description', 'local_price', 'standard_manufacturer', 'unbranded'],
    properties: {
      brand: {
        type: 'string'
      },
      boutiques: {
        type: 'array',
        maxItems: 1,
        items: {
          type: 'object',
          properties: {
            boutique: {
              type: 'string'
            },
            stock: {
              type: 'boolean'
            }
          }
        }
      },
      name: {
        type: 'string',
        minLength: 3
      },
      description: {
        type: 'string'
      },
      local_price: {
        type: 'object',
        required: ['amount', 'currency'],
        properties: {
          amount: {
            type: 'number',
            minimum: 1
          },
          currency: {
            type: 'string'
          }
        },
        errorMessage: {
          properties: {
            amount: 'price must be greater than 0'
          }
        }
      },
      standard_manufacturer: {
        type: 'object',
        properties: {
          _id: {
            type: ['string', 'null'],
            not: {
              const: RESOURCE_NOT_FOUND
            }
          }
        },
        errorMessage: {
          properties: {
            _id: 'brand ${0/name} was not found'
          }
        }
      },
      unbranded: {
        type: ['boolean', 'null']
      },
      gtin: {
        type: ['string', 'null']
      },
      images: {
        type: 'array'
      },
      categories: {
        type: 'array'
      },
      stock_lines: {
        type: 'array'
      }
    },
    errorMessage: {
      properties: {
        name: 'name is missing',
        description: 'description is missing'
      }
    }
  };
  const validateProduct = ajv.compile(productSchema);
  _exports.validateProduct = validateProduct;
  const variantsSchema = {
    type: 'array',
    items: {
      type: 'object',
      properties: {
        _id: {
          type: 'string',
          not: {
            const: RESOURCE_NOT_FOUND
          }
        },
        name: {
          type: 'string'
        },
        value: {
          type: 'string'
        }
      },
      errorMessage: {
        properties: {
          _id: '${0/name} ${0/value} was not found'
        }
      }
    }
  };
  const oneStockLineSchema = {
    type: 'object',
    required: ['quantity'],
    properties: {
      quantity: {
        type: 'integer',
        minimum: 0
      },
      dimensions: {
        type: 'object',
        properties: {
          length: {
            type: ['number', 'null']
          },
          width: {
            type: ['number', 'null']
          },
          height: {
            type: ['number', 'null']
          }
        }
      },
      care_instructions: {
        type: ['string', 'null']
      },
      sku: {
        type: ['object', 'null'],
        properties: {
          value: {
            type: ['string', 'null']
          },
          status: {
            type: ['string', 'null'],
            not: {
              const: RESOURCE_ALREADY_EXISTS
            }
          }
        },
        errorMessage: {
          properties: {
            status: 'SKU ${0/value} already exists'
          }
        }
      },
      variants: _objectSpread(_objectSpread({}, variantsSchema), {}, {
        minItems: 0
      })
    },
    errorMessage: {
      properties: {
        quantity: 'quantity must be greater or equal to 0'
      }
    }
  };
  const multipleStockLinesSchema = {
    type: 'object',
    required: ['quantity', 'variants'],
    properties: _objectSpread(_objectSpread({}, oneStockLineSchema.properties), {}, {
      variants: _objectSpread(_objectSpread({}, variantsSchema), {}, {
        minItems: 1
      })
    }),
    errorMessage: {
      properties: _objectSpread(_objectSpread({}, oneStockLineSchema.errorMessage.properties), {}, {
        variants: 'you need to add at least one variant (size, material, color or other)'
      })
    }
  };
  const validateOneStockLine = ajv.compile(oneStockLineSchema);
  _exports.validateOneStockLine = validateOneStockLine;
  const validateMultipleStockLines = ajv.compile(multipleStockLinesSchema);
  _exports.validateMultipleStockLines = validateMultipleStockLines;
  const dataPathRegExps = {
    upload: /^(\/name|\/description|\/local_price\/amount|\/variants|\/quantity)$/gi,
    amend: /^(\/standard_manufacturer\/_id|\/variants\/\d+\/_id|\/sku\/status)$/gi
  };
  _exports.dataPathRegExps = dataPathRegExps;
});