define("flaneur/models/boutique-restock-recommendation/index", ["exports", "@cubejs-client/core", "flaneur/config/environment", "flaneur/models/boutique-restock-information", "flaneur/enums/boutique-restock-recommendation-type", "flaneur/enums/tag-theme"], function (_exports, _core, _environment, _boutiqueRestockInformation, _boutiqueRestockRecommendationType, _tagTheme) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _dec5, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _class2, _temp;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  const DimensionsMap = new Map([['StockRecommendations.productId', 'product.id'], ['StockRecommendations.productName', 'product.name'], ['StockRecommendations.productLocalPriceAmount', 'product.localPrice.amount'], ['StockRecommendations.productLocalPriceCurrency', 'product.localPrice.currency'], ['StockRecommendations.productOnSalePrice', 'product.localSalesPrice.amount'], ['StockRecommendations.productThumbnailUrl', 'product.thumbnailUrl'], ['StockRecommendations.currentQuantity', 'currentQuantity'], ['StockRecommendations.recommendationType', 'type'], ['StockRecommendations.manufacturerName', 'standardManufacturer.name'], ['StockRecommendations.unitsSold', 'metrics.unitsSold'], ['StockRecommendations.daysLeftInStock', 'metrics.daysLeftInStock'], ['StockRecommendations.forecastMonthlySales', 'metrics.forecastMonthlySales'], ['StockRecommendations.revenue', 'metrics.revenue.amount'], ['StockRecommendations.productRank', 'product.rank']]);

  const parseNumber = (number, {
    multiplier = 1,
    precision = 0
  } = {}) => number ? parseFloat((parseFloat(number) * multiplier).toFixed(precision)) : null;

  const parseAmount = amount => {
    const number = parseNumber(amount, {
      multiplier: 100
    });
    return number <= 0 ? null : number;
  };

  const DimensionsTransformations = new Map([['product.name', name => name.trim()], ['product.localPrice.amount', amount => parseAmount(amount)], ['product.localSalesPrice.amount', amount => parseAmount(amount)], ['metrics.revenue.amount', amount => parseAmount(amount)], ['metrics.daysLeftInStock', days => parseNumber(days)], ['metrics.forecastMonthlySales', days => parseNumber(days, {
    precision: 1
  })]]);
  let BoutiqueRestockRecommendation = (_dec = Ember._tracked, _dec2 = Ember._tracked, _dec3 = Ember._tracked, _dec4 = Ember._tracked, _dec5 = Ember._tracked, (_class = (_temp = _class2 = class BoutiqueRestockRecommendation {
    constructor({
      product,
      currentQuantity,
      type,
      standardManufacturer,
      metrics,
      restockInformation = []
    } = {}) {
      _initializerDefineProperty(this, "product", _descriptor, this);

      _initializerDefineProperty(this, "currentQuantity", _descriptor2, this);

      _initializerDefineProperty(this, "type", _descriptor3, this);

      _initializerDefineProperty(this, "metrics", _descriptor4, this);

      _initializerDefineProperty(this, "restockInformation", _descriptor5, this);

      this.product = product !== null && product !== void 0 ? product : {
        localPrice: {},
        localSalesPrice: {}
      };
      this.standardManufacturer = standardManufacturer !== null && standardManufacturer !== void 0 ? standardManufacturer : {};
      this.currentQuantity = currentQuantity;
      this.type = type;
      this.metrics = metrics !== null && metrics !== void 0 ? metrics : {
        revenue: {}
      };
      this.restockInformation = restockInformation;
    }

    get hasMetrics() {
      var _this$metrics$revenue;

      return [this.metrics.unitsSold, this.metrics.daysLeftInStock, (_this$metrics$revenue = this.metrics.revenue) === null || _this$metrics$revenue === void 0 ? void 0 : _this$metrics$revenue.amount, this.metrics.forecastMonthlySales].some(Ember.isPresent);
    }

    get isOutOfStock() {
      return !parseInt(this.currentQuantity, 10);
    }

    get hasUnlimitedStock() {
      return parseInt(this.currentQuantity, 10) === -1;
    }

    get isTypeStock() {
      return this.type === _boutiqueRestockRecommendationType.default.STOCK;
    }

    get tag() {
      const isStockRecommendation = this.type === _boutiqueRestockRecommendationType.default.STOCK;

      if (isStockRecommendation && this.isOutOfStock) {
        return {
          theme: _tagTheme.default.NEGATIVE,
          text: 'Restock now',
          description: 'Our data has indicated that this product is sold out and should be re-stocked.'
        };
      }

      if (isStockRecommendation) {
        return {
          theme: _tagTheme.default.INFO,
          text: 'Restock soon',
          description: 'Our data has indicated that this product is selling fast and should be re-stocked.'
        };
      }

      return undefined;
    }

    static parseRawRecommendation(raw) {
      const recommendation = new BoutiqueRestockRecommendation();
      [...DimensionsMap.keys()].forEach(dimension => {
        const property = DimensionsMap.get(dimension);
        const transform = DimensionsTransformations.has(property) ? DimensionsTransformations.get(property) : v => v;
        Ember.set(recommendation, property, transform(raw[dimension]));
      });
      const {
        currency
      } = recommendation.product.localPrice;
      recommendation.product.localSalesPrice.currency = currency;
      recommendation.metrics.revenue.currency = currency;
      return recommendation;
    }

    static getFilterByBoutique(ids) {
      return {
        member: 'StockRecommendations.boutiqueId',
        operator: 'equals',
        values: Ember.isArray(ids) ? ids : [ids]
      };
    }

    static getFilterByProduct(ids) {
      return {
        member: 'StockRecommendations.productId',
        operator: 'equals',
        values: Ember.isArray(ids) ? ids : [ids]
      };
    }

    static getFilterBySearchQuery(name) {
      return {
        or: [{
          member: 'StockRecommendations.manufacturerNameSearch',
          operator: 'contains',
          values: Ember.isArray(name) ? name : [name]
        }, {
          member: 'StockRecommendations.productNameSearch',
          operator: 'contains',
          values: Ember.isArray(name) ? name : [name]
        }]
      };
    }

    static getFilterByStock(stock) {
      return {
        member: 'StockRecommendations.currentQuantity',
        operator: stock === false ? 'equals' : 'gt',
        values: ['0']
      };
    }

    static async find(params = {}) {
      const {
        boutiqueId,
        productId,
        limit = 24,
        filters = [],
        order,
        attachRestockInformation = false,
        offset,
        searchQuery,
        stock
      } = params;
      const dimensions = [...DimensionsMap.keys()];
      const resultSet = await this.cubejsApi.load({
        dimensions,
        filters: [boutiqueId ? this.getFilterByBoutique(boutiqueId) : null, productId ? this.getFilterByProduct(productId) : null, searchQuery ? this.getFilterBySearchQuery(searchQuery) : null, Ember.isPresent(stock) ? this.getFilterByStock(stock) : null, ...filters].filter(Boolean),
        order: order !== null && order !== void 0 ? order : [['StockRecommendations.recommendationType', 'desc'], ['StockRecommendations.productRank', 'asc']],
        limit,
        offset
      });
      const recommendations = resultSet.rawData().map(entry => {
        return this.parseRawRecommendation(entry);
      });

      if (!attachRestockInformation || recommendations.length <= 0) {
        return recommendations;
      }

      const {
        boutiqueRestockInformation,
        meta
      } = await _boutiqueRestockInformation.default.find({
        'product[$in]': recommendations.map(({
          product: {
            id
          }
        }) => id),
        boutique: this.boutiqueId
      });

      if (meta.total === 0) {
        return recommendations;
      }

      boutiqueRestockInformation.forEach(bri => {
        const recommendation = recommendations.find(({
          product: {
            id
          }
        }) => {
          var _bri$product;

          return id === ((_bri$product = bri.product) === null || _bri$product === void 0 ? void 0 : _bri$product._id);
        });

        if (!Ember.isArray(recommendation === null || recommendation === void 0 ? void 0 : recommendation.restockInformation)) {
          return;
        }

        recommendation.restockInformation = [bri, ...recommendation.restockInformation];
      });
      return recommendations;
    }

    static async count(params = {}) {
      var _resultSet$loadRespon;

      const {
        boutiqueId,
        filters = [],
        searchQuery,
        stock
      } = params;
      const resultSet = await this.cubejsApi.load({
        measures: ['StockRecommendations.count'],
        filters: [boutiqueId ? this.getFilterByBoutique(boutiqueId) : null, searchQuery ? this.getFilterBySearchQuery(searchQuery) : null, stock ? this.getFilterByStock(stock) : null, ...filters].filter(Boolean)
      });
      return (_resultSet$loadRespon = resultSet.loadResponse.results[0]) === null || _resultSet$loadRespon === void 0 ? void 0 : _resultSet$loadRespon.data[0]['StockRecommendations.count'];
    }

  }, _defineProperty(_class2, "cubejsApi", (0, _core.default)('api-token', {
    apiUrl: `${_environment.default.cubejsGatewayApiURL}/cubejs-api/v1`,
    credentials: 'include'
  })), _temp), (_descriptor = _applyDecoratedDescriptor(_class.prototype, "product", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "currentQuantity", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "type", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "metrics", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "restockInformation", [_dec5], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return [];
    }
  })), _class));
  _exports.default = BoutiqueRestockRecommendation;
});