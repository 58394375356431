define("flaneur/pods/components/form-flows/shipment/pickup-details/component", ["exports", "flaneur/util/address"], function (_exports, _address) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const NEW_ADDRESS_TEXT = 'Enter a different pickup address';
  const NEW_ADDRESS = {
    address_line_1: NEW_ADDRESS_TEXT
  };

  var _default = Ember.Component.extend({
    analytics: Ember.inject.service(),
    tagName: 'section',
    classNames: ['pickup-details'],
    subStep: Ember.computed.alias('options.subStep'),
    countries: Ember.computed.alias('options.countries'),
    boutiqueCollectionAddress: Ember.computed.alias('options.boutiqueCollectionAddress'),
    isUSA: Ember.computed.equal('selectedDeliveryCountry.iso_code', 'US'),
    selectedOption: Ember.computed('boutiqueCollectionAddress', 'shipment._isNewAddress', function () {
      const boutiqueCollectionAddress = this.boutiqueCollectionAddress;
      const isNewAddress = this.shipment._isNewAddress;

      switch (isNewAddress) {
        case undefined:
          return undefined;

        case true:
          return NEW_ADDRESS;

        default:
          return boutiqueCollectionAddress;
      }
    }),
    selectedDeliveryCountry: Ember.computed('countries', 'shipment.collection_address.country', function () {
      return this.countries.find(country => country.iso_code === this.shipment.collection_address.country);
    }),
    pickupOptions: Ember.computed('boutiqueCollectionAddress', 'shipment.collection_address', function () {
      const boutiqueCollectionAddress = this.boutiqueCollectionAddress;
      return [boutiqueCollectionAddress, NEW_ADDRESS];
    }),
    _countyLabel: Ember.computed('shipment.collection_address.country', function () {
      const selectedDeliveryCountryCode = this.get('shipment.collection_address.country');
      return (0, _address.countyLabel)(selectedDeliveryCountryCode);
    }),
    _postcodeLabel: Ember.computed('shipment.collection_address.country', function () {
      const selectedDeliveryCountryCode = this.get('shipment.collection_address.country');
      return (0, _address.postcodeLabel)(selectedDeliveryCountryCode);
    }),
    _hasPostalCode: Ember.computed('shipment.collection_address.country', function () {
      const selectedDeliveryCountryCode = this.get('shipment.collection_address.country');
      return (0, _address.isPostcodeRequired)(selectedDeliveryCountryCode);
    }),
    OH: Ember.computed.map('shipment.pickup_slots', function (day) {
      Ember.set(day, 'appointment', false);
      return day;
    }),

    trackSelectCollectionAddress(isNewAddress) {
      this.analytics.gtm({
        action: 'quick_ship_select_pickup_address',
        label: isNewAddress ? 'new' : 'existing'
      });
    },

    actions: {
      setAddressOption(option) {
        if (option.address_line_1 === NEW_ADDRESS_TEXT) {
          this.set('shipment.collection_address', {
            type: 'boutique',
            company_name: 'Trouva',
            country: this.get('boutiqueCollectionAddress.country'),
            country_iso_alpha_3: this.get('boutiqueCollectionAddress.country_iso_alpha_3')
          });
          this.trackSelectCollectionAddress(true);
          return this.set('shipment._isNewAddress', true);
        }

        this.set('shipment.collection_address', this.boutiqueCollectionAddress);
        this.trackSelectCollectionAddress(false);
        return this.set('shipment._isNewAddress', false);
      }

    }
  });

  _exports.default = _default;
});