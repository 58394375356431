define("flaneur/pods/components/pages/join/validations", ["exports", "ember-cp-validations"], function (_exports, _emberCpValidations) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const Validations = (0, _emberCpValidations.buildValidations)({
    password: (0, _emberCpValidations.validator)('length', {
      min: 8,
      message: 'Password must have at least 8 characters'
    }),
    confirmPassword: (0, _emberCpValidations.validator)('confirmation', {
      on: 'password',
      message: 'Passwords do not match'
    })
  }, {
    disabled: Ember.computed.readOnly('model.disableValidations')
  });
  var _default = Validations;
  _exports.default = _default;
});