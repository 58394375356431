define("flaneur/utils/filestack-url-uploader", ["exports", "flaneur/config/environment", "filestack-js", "flaneur/utils/get-imgix-url", "flaneur/utils/attempt-to-load-image"], function (_exports, _environment, filestack, _getImgixUrl, _attemptToLoadImage) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.convertImageFromFilestackToPromenade = convertImageFromFilestackToPromenade;
  _exports.getFilestackConfig = getFilestackConfig;
  _exports.default = void 0;

  function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); enumerableOnly && (symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols); } return keys; }

  function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = null != arguments[i] ? arguments[i] : {}; i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { _defineProperty(target, key, source[key]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } return target; }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  /**
   * An alternative to Cloudinary upload, which appears to use the API to upoad
   * urls (even though it calls them files), and then presumably Cloudinary takes
   * over and fetches the images from the urls. Here, we do the same, but via
   * Filestack's method.
   */
  class FilestackUrlUploader {
    /**
     * Setup configuration of Filestack and check that configuration is correct
     * @param passedConfig
     */
    constructor(passedConfig) {
      this.noop = function () {};

      this.passedConfig = passedConfig;
      if (!passedConfig.apiKey) throw new Error('apiKey property missing from config');
    }

    createClientIfNotExists() {
      if (this.client) return;
      this.client = filestack.init(this.passedConfig.apiKey);
    }

    randomString(length = 16) {
      let result = '';

      while (result.length < length) {
        const chunk = Math.random().toString(36).substring(4) || 'X';
        result += chunk;
      }

      return result.substring(0, length);
    }

    generateFileName(originalFile, namingMeta = {}) {
      // note this is different to the other generateFileName method
      // this one handles the source file being a string
      const extension = String(originalFile).split('.').pop() || '';
      const {
        folderBase,
        filenameBase,
        useRandomSuffix
      } = namingMeta;
      const randomSuffix = useRandomSuffix ? this.randomString() : '';
      const prefix = filenameBase || this.randomString();
      const folder = folderBase || 'unknown';
      const name = `${folder}/${prefix}-${randomSuffix}`;
      return extension ? `${name}.${extension}` : name;
    }
    /**
     * Uploads a single file to Filestack using the storeURL method and returns
     * a promise.
     *
     * Responsibilities:
     * 1. Upload file via Filestack's storeURL API
     * 2. Inject additional form data to be sent to Filestack
     * 3. Send progress updates to progressHandler
     *
     * @param file - URL of the file to upload
     * @param namingMeta
     * @param progressHandler
     * @returns {Promise}
     */


    upload(file, namingMeta = {}, progressHandler = this.noop) {
      this.createClientIfNotExists();
      const filename = this.generateFileName(file, namingMeta);
      const {
        filestack: filestackConfig
      } = _environment.default;

      if (typeof file !== 'string') {
        return Ember.RSVP.Promise.reject(new Error('File must be a string containing a valid URL'));
      }

      return new Ember.RSVP.Promise((resolve, reject) => {
        this.client.storeURL(file, _objectSpread(_objectSpread(_objectSpread(_objectSpread({
          filename
        }, filestackConfig.path ? {
          path: filestackConfig.path
        } : {}), filestackConfig.container ? {
          container: filestackConfig.container
        } : {}), filestackConfig.location ? {
          location: filestackConfig.location
        } : {}), filestackConfig.region ? {
          region: filestackConfig.region
        } : {})).then(response => {
          progressHandler({
            loaded: 100,
            total: 100
          }, file, 100);
          resolve(_objectSpread(_objectSpread({}, response), {}, {
            destFilename: filename
          }));
        }).catch(error => {
          reject(error);
        });
      });
    }

  }
  /**
   * Get an image in Promenade's format given an image
   * in Filestack's format.
   *
   * @param {Object} image
   * @returns {Object} the converted object
   */


  _exports.default = FilestackUrlUploader;

  async function convertImageFromFilestackToPromenade(image) {
    // attempt to load image using image tag to get dimensions
    await (0, _attemptToLoadImage.default)(image).catch(error => {
      // eslint-disable-next-line no-console
      console.log(image);
      console.error('Failed to load image for its dimensions', error);
    });
    const response = {
      url: image.url,
      original_url: image.url,
      width: image.width,
      height: image.height,
      format: image.mimetype,
      // @TODO check
      resource_type: image.type,
      video: undefined,
      filestack_top_colors: [],
      filestack_top_colors_hsv: [],
      filestack_google_colors: []
    }; // because we cannot set disableStorageKey on the storeURL process
    // we do not have full control on the path of where the image ends
    // up. Filestack PREPENDS!?! a random alphanum to the beginning of the
    // path in s3. So, we have to use that path for bulk uploads. As far
    // as I can see, there is no solution to this. Aside from us downloading
    // the remote images in the bulk csv ourselves, and then uploading them
    // using the filestack upload method. Or, an even crazier solution would
    // be to use filestack's raw API methods, that allow to set a remote source
    // and then a storage in s3. But, that would be a lot of work, and even
    // then we do not know if we'll be able to disable the storage key prefix.
    // So we have to put up with these strange URLs for now. And that means
    // using the image.key rather than image.destFilename.

    response.url = (0, _getImgixUrl.default)(image.key, {
      isPathAlreadyIncluded: true
    });
    return response;
  }
  /**
   * Get Filestack configuration from environment
   * @returns {Object} Filestack configuration
   */


  function getFilestackConfig() {
    return {
      apiKey: _environment.default.filestack.apiKey
    };
  }
});