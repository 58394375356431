define("flaneur/services/analytics", ["exports", "ember-metrics/services/metrics"], function (_exports, _metrics) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); enumerableOnly && (symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols); } return keys; }

  function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = null != arguments[i] ? arguments[i] : {}; i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { _defineProperty(target, key, source[key]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } return target; }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  var _default = _metrics.default.extend({
    all(data) {
      this.trackPage(_objectSpread({
        event: 'event'
      }, data));
    },

    gtm(data) {
      this.trackPage('GoogleTagManager', _objectSpread({
        event: 'event',
        category: 'retail_panel'
      }, data));
    },

    initializeDatalayer(boutiqueId) {
      window.dataLayer = window.dataLayer || [];
      window.dataLayer.push({
        originalLocation: `${document.location.protocol}//${document.location.hostname}${document.location.pathname}${document.location.search}`
      });
      window.dataLayer.push({
        projectName: 'retail_panel',
        boutiqueId
      });
    }

  });

  _exports.default = _default;
});