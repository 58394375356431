define("flaneur/pods/components/button/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "OzqnSe/j",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[18,1,[[30,[36,2],null,[[\"loading\",\"spinner\"],[[35,1],[30,[36,0],[\"fa-icon\"],[[\"icon\",\"prefix\",\"spin\"],[\"spinner-third\",\"far\",true]]]]]]]],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"component\",\"isLoading\",\"hash\"]}",
    "moduleName": "flaneur/pods/components/button/template.hbs"
  });

  _exports.default = _default;
});