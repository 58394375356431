define("flaneur/pods/components/tag/component", ["exports", "@glimmer/component", "ember-component-css/pod-names", "flaneur/enums/tag-theme"], function (_exports, _component, _podNames, _tagTheme) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  class TagComponent extends _component.default {
    constructor() {
      super(...arguments);

      _defineProperty(this, "themes", Object.values(_tagTheme.default));

      _defineProperty(this, "styleNamespace", _podNames.default.tag);

      (false && !(Ember.isEmpty(this.args.theme) || this.themes.includes(this.args.theme)) && Ember.assert(`The theme ${this.args.theme} passed to <Tag /> is not valid.`, Ember.isEmpty(this.args.theme) || this.themes.includes(this.args.theme)));
    }

  }

  _exports.default = TagComponent;
});