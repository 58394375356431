define("flaneur/pods/components/pages/curate/brands/brand/basket-page/component", ["exports", "ember-concurrency", "flaneur/models/curate/product", "flaneur/pods/components/pages/curate/brands/brand/basket-page/validations", "flaneur/mixins/keyword-highlight", "flaneur/enums/curate-order-submitted-type", "ember-component-css/pod-names"], function (_exports, _emberConcurrency, _product, _validations, _keywordHighlight, _curateOrderSubmittedType, _podNames) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _dec8, _dec9, _dec10, _dec11, _dec12, _dec13, _dec14, _dec15, _dec16, _dec17, _dec18, _dec19, _dec20, _dec21, _dec22, _dec23, _dec24, _dec25, _dec26, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _descriptor6, _descriptor7, _descriptor8, _descriptor9, _descriptor10, _descriptor11, _descriptor12, _descriptor13, _descriptor14, _descriptor15, _descriptor16, _descriptor17;

  function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); enumerableOnly && (symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols); } return keys; }

  function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = null != arguments[i] ? arguments[i] : {}; i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { _defineProperty(target, key, source[key]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } return target; }

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  const NEW_ADDRESS_TEXT = 'Enter a different address';
  const UPDATE_DEBOUNCE_TIME = 500;
  let PagesCurateBrandsBrandBasketPageComponent = (_dec = Ember.inject.service('brand'), _dec2 = Ember.inject.service, _dec3 = Ember.inject.service, _dec4 = Ember.inject.service, _dec5 = Ember.inject.service, _dec6 = Ember.inject.service, _dec7 = Ember.inject.service, _dec8 = Ember._tracked, _dec9 = Ember._tracked, _dec10 = Ember._tracked, _dec11 = Ember._tracked, _dec12 = Ember._tracked, _dec13 = Ember._tracked, _dec14 = Ember._tracked, _dec15 = Ember._tracked, _dec16 = Ember._tracked, _dec17 = Ember._tracked, _dec18 = Ember._action, _dec19 = Ember._action, _dec20 = Ember._action, _dec21 = Ember._action, _dec22 = Ember._action, _dec23 = Ember._action, _dec24 = Ember._action, _dec25 = Ember._action, _dec26 = Ember._action, (_class = class PagesCurateBrandsBrandBasketPageComponent extends Ember.Component.extend(_validations.Validations, _keywordHighlight.default) {
    get styleNamespace() {
      return _podNames.default['pages/curate/brands/brand/basket-page'];
    }

    constructor() {
      super(...arguments);

      _initializerDefineProperty(this, "currentBrand", _descriptor, this);

      _initializerDefineProperty(this, "analytics", _descriptor2, this);

      _initializerDefineProperty(this, "media", _descriptor3, this);

      _initializerDefineProperty(this, "notify", _descriptor4, this);

      _initializerDefineProperty(this, "router", _descriptor5, this);

      _initializerDefineProperty(this, "curateBasket", _descriptor6, this);

      _initializerDefineProperty(this, "features", _descriptor7, this);

      _initializerDefineProperty(this, "selectedProduct", _descriptor8, this);

      _initializerDefineProperty(this, "productQuery", _descriptor9, this);

      _initializerDefineProperty(this, "disableValidations", _descriptor10, this);

      _initializerDefineProperty(this, "invoiceAddressOptions", _descriptor11, this);

      _initializerDefineProperty(this, "selectedInvoiceOption", _descriptor12, this);

      _initializerDefineProperty(this, "isNewInvoiceAddress", _descriptor13, this);

      _initializerDefineProperty(this, "deliveryAddressOptions", _descriptor14, this);

      _initializerDefineProperty(this, "selectedDeliveryOption", _descriptor15, this);

      _initializerDefineProperty(this, "isNewDeliveryAddress", _descriptor16, this);

      _initializerDefineProperty(this, "hasOrderSubmitted", _descriptor17, this);

      _defineProperty(this, "keywordHighlightOpts", {
        keywordField: 'productQuery',
        selectors: ['.ember-power-select-option:not(.ember-power-select-option--no-matches-message)']
      });

      _defineProperty(this, "disableAddressValidations", true);

      _defineProperty(this, "invoiceNewAddress", {});

      _defineProperty(this, "deliveryNewAddress", {});

      this.deliveryAddressOptions = [this.order.delivery_address, {
        line_1: NEW_ADDRESS_TEXT
      }];
      this.isNewDeliveryAddress = false;
      this.selectedDeliveryOption = this.deliveryAddressOptions[0];
      this.invoiceAddressOptions = [this.hasAccountingAddress ? this.order.invoice_details.address : null, {
        line_1: NEW_ADDRESS_TEXT
      }].filter(Boolean);
      this.isNewInvoiceAddress = !this.hasAccountingAddress;
      this.selectedInvoiceOption = this.invoiceAddressOptions[0];
    }

    get hasEmptyBasket() {
      return !this.order.reservations.length && !this.hasOrderSubmitted;
    }

    get boutique() {
      return this.currentBrand.currentBoutique;
    }

    get boutiqueId() {
      return this.boutique.id;
    }

    get brandId() {
      return this.brand.id;
    }

    get order() {
      return this.curateBasket.order;
    }

    get orderTotal() {
      return this.curateBasket.totalPrice;
    }

    get hasAccountingAddress() {
      return Ember.isPresent(this.boutique.accounting_address_line_1);
    }

    get isSubmitOrderDisabled() {
      return !this.order.reservations.length;
    }

    *searchProduct(query) {
      yield (0, _emberConcurrency.timeout)(250);
      this.productQuery = query;
      const {
        curateProducts
      } = yield _product.default.find({
        sku: query,
        brand: this.brand.id,
        limit: 6
      });
      return curateProducts.flatMap(product => product.stockLines.filter(stockLine => stockLine.sku.toUpperCase().includes(query.toUpperCase())).map(stockLine => _objectSpread(_objectSpread({}, product), {}, {
        selectedStockLine: _objectSpread({
          name: product.name
        }, stockLine)
      })));
    }

    selectProduct(product) {
      const productWithValidations = _validations.ProductWithValidations.create(Ember.getOwner(this).ownerInjection(), _objectSpread(_objectSpread({}, product), {}, {
        quantity: product.selectedStockLine.minimumOrderQuantity
      }));

      this.selectedProduct = productWithValidations;
    }

    async addProduct() {
      if (!this.selectedProduct) {
        return;
      }

      Ember.set(this.selectedProduct, 'disableValidations', false);
      const {
        validations: {
          isInvalid
        }
      } = await this.selectedProduct.validate();

      if (isInvalid) {
        return;
      }

      this.order.reservations = [this.selectedProduct, ...this.order.reservations];
      this.selectedProduct = null;
      this.productQuery = null;
    }

    removeProduct(product) {
      this.analytics.gtm({
        action: 'curate_removed_product',
        label: product.selectedStockLine.sku
      });
      this.order.reservations.removeObject(product);
    }

    updateProductQuantity(product, event) {
      Ember.set(product, 'disableValidations', false);
      const parsedValue = parseInt(event.target.value, 10);
      const quantity = Number.isNaN(parsedValue) ? null : parsedValue;
      this.analytics.gtm({
        action: 'curate_changed_quantity',
        label: quantity
      });
      Ember.set(product, 'quantity', quantity);
    }

    setInvoiceAndDeliveryAddresses() {
      if (this.isNewInvoiceAddress) {
        this.order.invoice_details = _objectSpread(_objectSpread({}, this.order.invoice_details), {}, {
          address: {
            line_1: `${this.invoiceNewAddress.line_1}, ${this.invoiceNewAddress.line_2 || ''}`,
            line_2: `${this.invoiceNewAddress.postcode}, ${this.invoiceNewAddress.city}`
          }
        });
      }

      if (this.isNewDeliveryAddress) {
        this.order.delivery_address = {
          line_1: `${this.deliveryNewAddress.line_1}, ${this.deliveryNewAddress.line_2 || ''}`,
          line_2: `${this.deliveryNewAddress.postcode}, ${this.deliveryNewAddress.city}`
        };
      }
    }

    async redirectToCheckout(ev) {
      ev.preventDefault();
      this.disableValidations = false;
      this.order.disableValidations = true;
      const {
        validations
      } = await this.validations.validate();

      if (validations.isInvalid) {
        return;
      }

      this.analytics.gtm({
        action: 'clicked_proceed_to_checkout',
        label: this.brandId
      });
      this.disableValidations = true;
      this.order.disableValidations = true;
      this.router.transitionTo('brand.boutiques.boutique.curate.brands.brand.checkout');
    }

    async submitOrder(ev) {
      ev.preventDefault();
      this.analytics.gtm({
        action: 'curate_clicked_submit_purchase_order',
        label: this.brandId
      });
      this.disableValidations = false;
      this.order.disableValidations = false;
      const {
        validations
      } = await this.validations.validate();
      const isOrderInvalid = !this.order.isValid;

      if (validations.isInvalid || isOrderInvalid) {
        return;
      }

      this.order.boutique = this.currentBrand.currentBoutique._id;
      this.order.brands = [this.brand.id];
      this.setInvoiceAndDeliveryAddresses();
      const {
        has_brand_account: hasAccountWithBrand
      } = this.curateBasket.order;
      return this.order.create().then(({
        _id: id
      }) => {
        const queryParams = {
          'order-submitted-id': id,
          'order-submitted-type': hasAccountWithBrand ? _curateOrderSubmittedType.default.EXISTING_ACCOUNT : _curateOrderSubmittedType.default.NEW_ACCOUNT,
          'order-submitted-brand': this.brand.name
        };
        this.hasOrderSubmitted = true;
        this.curateBasket.clearOrder(this.brand);
        this.router.transitionTo('brand.boutiques.boutique.curate.orders.submitted', {
          queryParams
        });
      }).catch(() => {
        this.notify.show('error', 'Oops! An error ocurred while submitting your purchase order. Please try again.');
      });
    }

    setInvoiceAddressOption(option) {
      this.selectedInvoiceOption = option;

      if (option.line_1 === NEW_ADDRESS_TEXT) {
        this.isNewInvoiceAddress = true;
        return;
      }

      this.order.invoice_details = _objectSpread(_objectSpread({}, this.order.invoice_details), {}, {
        address: {
          line_1: this.boutique.accounting_address_line_1,
          line_2: this.boutique.accounting_address_line_2
        }
      });
      this.isNewInvoiceAddress = false;
    }

    setDeliveryAddressOption(option) {
      this.selectedDeliveryOption = option;

      if (option.line_1 === NEW_ADDRESS_TEXT) {
        this.isNewDeliveryAddress = true;
        return;
      }

      this.order.delivery_address = {
        line_1: `${this.boutique.address_line_1}, ${this.boutique.address_line_2 || ''}`,
        line_2: `${this.boutique.address_postcode}, ${this.boutique.address_city}`
      };
      this.isNewDeliveryAddress = false;
    }

    *trackUpdate(action) {
      yield (0, _emberConcurrency.timeout)(UPDATE_DEBOUNCE_TIME);
      return yield this.analytics.gtm({
        action,
        label: this.boutiqueId
      });
    }

    onHasBrandAccount(value) {
      if (value) {
        this.analytics.gtm({
          action: 'curate_checked_existing_account_with_brand',
          label: this.brandId
        });
      }

      this.order.has_brand_account = value;
    }

  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "currentBrand", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "analytics", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "media", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "notify", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "router", [_dec5], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor6 = _applyDecoratedDescriptor(_class.prototype, "curateBasket", [_dec6], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor7 = _applyDecoratedDescriptor(_class.prototype, "features", [_dec7], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor8 = _applyDecoratedDescriptor(_class.prototype, "selectedProduct", [_dec8], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor9 = _applyDecoratedDescriptor(_class.prototype, "productQuery", [_dec9], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor10 = _applyDecoratedDescriptor(_class.prototype, "disableValidations", [_dec10], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return true;
    }
  }), _descriptor11 = _applyDecoratedDescriptor(_class.prototype, "invoiceAddressOptions", [_dec11], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor12 = _applyDecoratedDescriptor(_class.prototype, "selectedInvoiceOption", [_dec12], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor13 = _applyDecoratedDescriptor(_class.prototype, "isNewInvoiceAddress", [_dec13], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor14 = _applyDecoratedDescriptor(_class.prototype, "deliveryAddressOptions", [_dec14], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor15 = _applyDecoratedDescriptor(_class.prototype, "selectedDeliveryOption", [_dec15], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor16 = _applyDecoratedDescriptor(_class.prototype, "isNewDeliveryAddress", [_dec16], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor17 = _applyDecoratedDescriptor(_class.prototype, "hasOrderSubmitted", [_dec17], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return false;
    }
  }), _applyDecoratedDescriptor(_class.prototype, "searchProduct", [_emberConcurrency.restartableTask], Object.getOwnPropertyDescriptor(_class.prototype, "searchProduct"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "selectProduct", [_dec18], Object.getOwnPropertyDescriptor(_class.prototype, "selectProduct"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "addProduct", [_dec19], Object.getOwnPropertyDescriptor(_class.prototype, "addProduct"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "removeProduct", [_dec20], Object.getOwnPropertyDescriptor(_class.prototype, "removeProduct"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "updateProductQuantity", [_dec21], Object.getOwnPropertyDescriptor(_class.prototype, "updateProductQuantity"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "redirectToCheckout", [_dec22], Object.getOwnPropertyDescriptor(_class.prototype, "redirectToCheckout"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "submitOrder", [_dec23], Object.getOwnPropertyDescriptor(_class.prototype, "submitOrder"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "setInvoiceAddressOption", [_dec24], Object.getOwnPropertyDescriptor(_class.prototype, "setInvoiceAddressOption"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "setDeliveryAddressOption", [_dec25], Object.getOwnPropertyDescriptor(_class.prototype, "setDeliveryAddressOption"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "trackUpdate", [_emberConcurrency.restartableTask], Object.getOwnPropertyDescriptor(_class.prototype, "trackUpdate"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "onHasBrandAccount", [_dec26], Object.getOwnPropertyDescriptor(_class.prototype, "onHasBrandAccount"), _class.prototype)), _class));
  _exports.default = PagesCurateBrandsBrandBasketPageComponent;
});