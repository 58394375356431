define("flaneur/pods/components/terms-and-conditions-notification/component", ["exports", "date-fns", "@glimmer/component", "flaneur/config/environment"], function (_exports, _dateFns, _component, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5;

  function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); enumerableOnly && (symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols); } return keys; }

  function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = null != arguments[i] ? arguments[i] : {}; i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { _defineProperty(target, key, source[key]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } return target; }

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  let TermsAndConditionsNotificationComponent = (_dec = Ember.inject.service, _dec2 = Ember.inject.service, _dec3 = Ember.inject.service, _dec4 = Ember.inject.service, _dec5 = Ember._tracked, _dec6 = Ember._action, _dec7 = Ember._action, (_class = class TermsAndConditionsNotificationComponent extends _component.default {
    constructor(...args) {
      super(...args);

      _initializerDefineProperty(this, "brand", _descriptor, this);

      _initializerDefineProperty(this, "cookies", _descriptor2, this);

      _initializerDefineProperty(this, "router", _descriptor3, this);

      _initializerDefineProperty(this, "session", _descriptor4, this);

      _defineProperty(this, "cookieName", 'terms_and_conditions_notification_dismissed');

      _defineProperty(this, "excludedRoutes", ['brand.boutiques.boutique.terms-and-conditions', 'brand.boutiques.boutique.welcome']);

      _initializerDefineProperty(this, "hasDismissedNotification", _descriptor5, this);
    }

    get boutique() {
      return this.brand.currentBoutique;
    }

    get hasTermsAndConditions() {
      return this.boutique.hasTermsAndConditions;
    }

    get hasPendingTerms() {
      return this.boutique.hasPendingTerms;
    }

    get limitForAcceptance() {
      return this.boutique.terms_and_conditions.limit_for_acceptance;
    }

    get hasAutomaticallyAcceptedTerms() {
      return this.boutique.hasAutomaticallyAcceptedTerms;
    }

    get isAdmin() {
      const {
        isAdmin,
        isRetailAdmin
      } = this.session.user;
      return isAdmin || isRetailAdmin;
    }

    get shouldShowNotification() {
      const hasDismissedNotification = this.cookies.exists(this.cookieName) || this.hasDismissedNotification;
      const isExcludedRoute = this.excludedRoutes.includes(this.router.currentRouteName);
      return !hasDismissedNotification && this.isAdmin && this.hasTermsAndConditions && this.hasPendingTerms && !this.hasAutomaticallyAcceptedTerms && !isExcludedRoute;
    }

    setCookie() {
      const oneWeek = (0, _dateFns.add)(new Date(), {
        days: 7
      });

      const options = _objectSpread({
        expires: this.limitForAcceptance && !(0, _dateFns.isBefore)((0, _dateFns.parseISO)(this.limitForAcceptance), new Date()) ? (0, _dateFns.parseISO)(this.limitForAcceptance) : oneWeek
      }, _environment.default.cookies);

      this.cookies.write(this.cookieName, true, options);
      Ember.run.later(() => {
        this.hasDismissedNotification = true;
      }, 275);
    }

    dismiss() {
      this.setCookie();
    }

    goToTerms() {
      this.setCookie();
      this.router.transitionTo('brand.boutiques.boutique.terms-and-conditions', this.brand, this.boutique._id);
    }

  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "brand", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "cookies", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "router", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "session", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "hasDismissedNotification", [_dec5], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "dismiss", [_dec6], Object.getOwnPropertyDescriptor(_class.prototype, "dismiss"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "goToTerms", [_dec7], Object.getOwnPropertyDescriptor(_class.prototype, "goToTerms"), _class.prototype)), _class));
  _exports.default = TermsAndConditionsNotificationComponent;
});