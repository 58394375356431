define("flaneur/pods/components/input-affixed/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "EnauslUP",
    "block": "{\"symbols\":[\"&default\",\"@namedBlocksInfo\",\"@error\",\"@disabled\",\"&attrs\"],\"statements\":[[11,\"div\"],[16,0,[31,[\"input-affixed \",[32,0,[\"styleNamespace\"]],\" \",[30,[36,2],[[32,4],\"input-affixed--disabled\"],null],\" \",[30,[36,2],[[32,3],\"input-affixed--error\"],null],\" \",[30,[36,2],[[30,[36,1],[[32,2],\"prefix\",false],null],\"input-affixed--prefixed\"],null],\" \",[30,[36,2],[[30,[36,1],[[32,2],\"suffix\",false],null],\"input-affixed--suffixed\"],null]]]],[17,5],[12],[2,\"\\n  \"],[18,1,null],[2,\"\\n\\n\"],[6,[37,2],[[30,[36,1],[[32,2],\"prefix\",false],null]],null,[[\"default\"],[{\"statements\":[[2,\"    \"],[10,\"div\"],[14,0,\"input-affixed__prefix\"],[12],[2,\"\\n      \"],[18,1,[[30,[36,0],[\"prefix\"],null]]],[2,\"\\n    \"],[13],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"\\n\"],[6,[37,2],[[30,[36,1],[[32,2],\"suffix\",false],null]],null,[[\"default\"],[{\"statements\":[[2,\"    \"],[10,\"div\"],[14,0,\"input-affixed__suffix\"],[12],[2,\"\\n      \"],[18,1,[[30,[36,0],[\"suffix\"],null]]],[2,\"\\n    \"],[13],[2,\"\\n\"]],\"parameters\":[]}]]],[13]],\"hasEval\":false,\"upvars\":[\"-named-block-invocation\",\"-has-block\",\"if\"]}",
    "moduleName": "flaneur/pods/components/input-affixed/template.hbs"
  });

  _exports.default = _default;
});