define("flaneur/pods/components/form-flows/product/gallery-details/gallery-item/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "Ata9HZ9u",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[10,\"div\"],[14,0,\"gallery-details__item\"],[12],[2,\"\\n  \"],[10,\"div\"],[14,0,\"gallery-details__edit-item\"],[12],[2,\"\\n    \"],[11,\"span\"],[4,[38,1],[[32,0],\"deleteImage\",[35,0]],null],[12],[2,\"\\n      \"],[8,\"fa-icon\",[],[[\"@icon\",\"@prefix\",\"@class\"],[\"times\",\"far\",\"fa\"]],null],[2,\"\\n    \"],[13],[2,\"\\n  \"],[13],[2,\"\\n  \"],[10,\"div\"],[14,0,\"image-manager-list-item\"],[15,5,[34,2]],[12],[13],[2,\"\\n\"],[13],[2,\"\\n\\n\"],[18,1,null],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"item\",\"action\",\"imageItemStyle\"]}",
    "moduleName": "flaneur/pods/components/form-flows/product/gallery-details/gallery-item/template.hbs"
  });

  _exports.default = _default;
});