define("flaneur/pods/components/pages/insights/component", ["exports", "@glimmer/component", "ember-component-css/pod-names"], function (_exports, _component, _podNames) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _dec8, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4;

  function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); enumerableOnly && (symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols); } return keys; }

  function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = null != arguments[i] ? arguments[i] : {}; i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { _defineProperty(target, key, source[key]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } return target; }

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  let PagesInsightsComponent = (_dec = Ember.inject.service, _dec2 = Ember.inject.service, _dec3 = Ember.inject.service, _dec4 = Ember._tracked, _dec5 = Ember._action, _dec6 = Ember._action, _dec7 = Ember._action, _dec8 = Ember._action, (_class = class PagesInsightsComponent extends _component.default {
    constructor() {
      super(...arguments);

      _initializerDefineProperty(this, "brand", _descriptor, this);

      _initializerDefineProperty(this, "router", _descriptor2, this);

      _initializerDefineProperty(this, "analytics", _descriptor3, this);

      _initializerDefineProperty(this, "seenModal", _descriptor4, this);

      _defineProperty(this, "tableHeader", [{
        label: 'Product'
      }, {
        label: 'Price'
      }, {
        label: 'Stock'
      }, {
        label: 'Units sold',
        tooltip: 'Total units sold of this product in the last 30 days'
      }, {
        label: 'Revenue',
        tooltip: 'Total revenue from this product in the last 30 days'
      }, {
        label: 'Days left in stock',
        tooltip: 'Estimated number of days left in stock, based on your current stock and run-rate over the last 30 days (Run-rate = Units sold / days in stock)'
      }, {
        label: '30 day sales forecast',
        tooltip: 'Estimated monthly sales based on your current run-rate multiplied by 30 (Run-rate = Units sold / days in stock)'
      }, {
        label: 'Stock plans'
      }]);

      _defineProperty(this, "filtersList", [{
        params: {
          stock: null
        },
        label: 'All products'
      }, {
        params: {
          stock: true
        },
        label: 'In stock'
      }, {
        params: {
          stock: false
        },
        label: 'Out of stock'
      }]);

      const hasSeenInsightsEducationModal = localStorage.getItem('hasSeenInsightsEducationModal');

      if (!hasSeenInsightsEducationModal) {
        localStorage.setItem('hasSeenInsightsEducationModal', 'yes');
      }

      this.hasSeenInsightsEducationModal = hasSeenInsightsEducationModal;
    }

    get meta() {
      return this.args.meta;
    }

    get styleNamespace() {
      return _podNames.default['pages/insights'];
    }

    get recommendations() {
      return this.args.recommendations;
    }

    get shouldShowRecommendations() {
      var _this$meta;

      return this.recommendations !== 'timeout' && ((_this$meta = this.meta) === null || _this$meta === void 0 ? void 0 : _this$meta.total);
    }

    get showModal() {
      return this.hasSeenInsightsEducationModal !== 'yes' && !this.seenModal;
    }

    get pageTotal() {
      var _this$args$meta, _this$args$meta2;

      return ((_this$args$meta = this.args.meta) === null || _this$args$meta === void 0 ? void 0 : _this$args$meta.total) === 0 ? 1 : Math.ceil(((_this$args$meta2 = this.args.meta) === null || _this$args$meta2 === void 0 ? void 0 : _this$args$meta2.total) / this.args.params.limit);
    }

    get selectedFilter() {
      const stock = this.args.params.stock;
      return this.filtersList.find(filter => filter.params.stock === stock);
    }

    search(parameters) {
      return this.router.transitionTo({
        queryParams: _objectSpread(_objectSpread({}, parameters), {}, {
          page: 1
        })
      });
    }

    onChangeLimit(query) {
      this.router.transitionTo({
        queryParams: {
          limit: query.params.limit
        }
      });
    }

    closeModal() {
      this.seenModal = true;
    }

    onChangeQuery(query) {
      this.analytics.gtm({
        action: 'insights-search-submit',
        label: query
      });
      return this.search({
        q: query
      });
    }

    onChangeStockFilter(filter) {
      this.analytics.gtm({
        action: 'insights-filter-change',
        label: filter.label
      });
      return this.search(filter.params);
    }

  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "brand", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "router", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "analytics", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "seenModal", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "onChangeLimit", [_dec5], Object.getOwnPropertyDescriptor(_class.prototype, "onChangeLimit"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "closeModal", [_dec6], Object.getOwnPropertyDescriptor(_class.prototype, "closeModal"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "onChangeQuery", [_dec7], Object.getOwnPropertyDescriptor(_class.prototype, "onChangeQuery"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "onChangeStockFilter", [_dec8], Object.getOwnPropertyDescriptor(_class.prototype, "onChangeStockFilter"), _class.prototype)), _class));
  _exports.default = PagesInsightsComponent;
});