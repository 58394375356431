define("flaneur/models/manufacturer", ["exports", "flaneur/mixins/request"], function (_exports, _request) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); enumerableOnly && (symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols); } return keys; }

  function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = null != arguments[i] ? arguments[i] : {}; i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { _defineProperty(target, key, source[key]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } return target; }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  const Manufacturer = Ember.Object.extend(_request.default, {});
  Manufacturer.reopenClass(_request.default, {
    // Class methods
    find(query) {
      return this.request({
        method: 'GET',
        url: this.apiURL('manufacturers'),
        data: _objectSpread(_objectSpread({}, query), {}, {
          archived: {
            $ne: true
          }
        })
      }).then(res => {
        return res.manufacturers.map(manufacturer => {
          return this.create(manufacturer);
        });
      });
    },

    /**
     * Create new manufacturer
     *
     * @return {Object} Promise
     */
    async post(manufacturer) {
      const res = await this.request({
        method: 'post',
        url: this.apiURL('manufacturers'),
        data: JSON.stringify(manufacturer)
      });
      const createdManufacturer = Ember.get(res, 'manufacturers.0');
      return this.create(createdManufacturer);
    }

  });
  var _default = Manufacturer;
  _exports.default = _default;
});