define("flaneur/pods/brand/boutiques/boutique/loading/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "/+hB/IHN",
    "block": "{\"symbols\":[],\"statements\":[[10,\"div\"],[14,0,\"boot-loading\"],[12],[2,\"\\n  \"],[1,[30,[36,0],[\"loading\"],null]],[2,\"\\n\"],[13],[2,\"\\n\\n\"],[1,[30,[36,2],[[30,[36,1],null,null]],null]],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"svg-jar\",\"-outlet\",\"component\"]}",
    "moduleName": "flaneur/pods/brand/boutiques/boutique/loading/template.hbs"
  });

  _exports.default = _default;
});