define("flaneur/mixins/courier-calculator", ["exports", "flaneur/couriers-data/p2g-couriers", "flaneur/couriers-data/easypost-couriers"], function (_exports, _p2gCouriers, _easypostCouriers) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Mixin.create({
    getCourierData(reservationBoutique, shipment = {}) {
      if (reservationBoutique.shipping_provider === 'p2g') {
        const p2gCourier = _p2gCouriers.default.getById(reservationBoutique.p2g_service_id);

        const p2gCourierName = p2gCourier.picked_up_by;
        return {
          courierName: p2gCourierName,
          courierData: this._createP2GData(p2gCourier)
        };
      }

      if (reservationBoutique.shipping_provider === 'easypost' || reservationBoutique.shipping_provider === 'ups_access_point') {
        if (reservationBoutique.shipments) {
          shipment = reservationBoutique.shipments[0];
        }

        const easyPostCourier = _easypostCouriers.default.getById(shipment.carrier_name);

        const easyPostCourierName = easyPostCourier.picked_up_by;
        return {
          courierName: easyPostCourierName,
          courierData: this._createEasyPostData(easyPostCourier)
        };
      } // TODO: remove after manual order process is removed


      if (reservationBoutique.shipping_provider === 'manual') {
        return {
          courierName: 'manual',
          courierData: {
            count: 1,
            picked_up_by: 'manual',
            courier_to_display: 'manual',
            ordernumbers: []
          }
        };
      }

      return {
        courierName: 'legacy',
        courierData: {
          count: 1,
          picked_up_by: 'legacy',
          courier_to_display: 'legacy',
          ordernumbers: []
        }
      };
    },

    _createEasyPostData({
      picked_up_by,
      courier_to_display,
      logo = ''
    }) {
      return {
        count: 1,
        picked_up_by,
        logo,
        courier_to_display,
        ordernumbers: []
      };
    },

    _createP2GData({
      picked_up_by,
      courier_to_display,
      logo = ''
    }) {
      return {
        count: 1,
        picked_up_by,
        courier_to_display,
        logo,
        ordernumbers: []
      };
    }

  });

  _exports.default = _default;
});