define("flaneur/pods/components/curate-basket-toast/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "hWq/v8HR",
    "block": "{\"symbols\":[],\"statements\":[[10,\"div\"],[15,0,[31,[[32,0,[\"styleNamespace\"]],\" curate-basket-toast \",[30,[36,1],[[30,[36,0],[[32,0,[\"curateBasket\",\"isToastVisible\"]]],null],\"curate-basket-toast--hidden\"],null]]]],[12],[2,\"\\n  \"],[10,\"h3\"],[12],[2,\"Your purchase order\"],[13],[2,\"\\n  \"],[10,\"div\"],[12],[2,\"\\n    \"],[10,\"div\"],[12],[2,\"Total\"],[13],[2,\"\\n    \"],[10,\"div\"],[12],[2,\"\\n      \"],[1,[30,[36,3],[[30,[36,2],[[32,0,[\"curateBasket\",\"totalPrice\",\"amount\"]]],null]],[[\"currency\",\"style\"],[[32,0,[\"curateBasket\",\"totalPrice\",\"currency\"]],\"currency\"]]]],[2,\"\\n    \"],[13],[2,\"\\n  \"],[13],[2,\"\\n\\n  \"],[8,\"button\",[],[[\"@onClick\"],[[32,0,[\"proceed\"]]]],[[\"default\"],[{\"statements\":[[2,\"\\n    Proceed to Review\\n  \"]],\"parameters\":[]}]]],[2,\"\\n\"],[13]],\"hasEval\":false,\"upvars\":[\"not\",\"if\",\"to-money\",\"format-number\"]}",
    "moduleName": "flaneur/pods/components/curate-basket-toast/template.hbs"
  });

  _exports.default = _default;
});