define("flaneur/pods/components/horizontal-scroll/component", ["exports", "@glimmer/component", "ember-cached-decorator-polyfill", "ember-component-css/pod-names", "flaneur/utils/is-overflown", "flaneur/breakpoints", "ember-concurrency"], function (_exports, _component, _emberCachedDecoratorPolyfill, _podNames, _isOverflown, _breakpoints, _emberConcurrency) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _dec8, _dec9, _dec10, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _descriptor6;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  const DEFAULT_SLIDES_TO_SHOW = 3;
  const DEFAULT_NEXT_SLIDE_PREVIEW_WIDTH = 116;
  const DEFAULT_DIVIDER_WIDTH = 0;
  const DEFAULT_EDGE_DIVIDER_WIDTH = 0;
  const DEFAULT_SLIDES_PER_BREAKPOINT = [{
    breakpoint: _breakpoints.default.small,
    slidesToShow: 1,
    nextSlidePreviewWidth: 48
  }, {
    breakpoint: _breakpoints.default.large,
    slidesToShow: 2
  }];
  let HorizontalScrollComponent = (_dec = Ember.inject.service, _dec2 = Ember._tracked, _dec3 = Ember._tracked, _dec4 = Ember._tracked, _dec5 = Ember._tracked, _dec6 = Ember._tracked, _dec7 = Ember._action, _dec8 = Ember._action, _dec9 = Ember._action, _dec10 = Ember._action, (_class = class HorizontalScrollComponent extends _component.default {
    constructor(...args) {
      super(...args);

      _initializerDefineProperty(this, "media", _descriptor, this);

      _initializerDefineProperty(this, "slidesEl", _descriptor2, this);

      _initializerDefineProperty(this, "lastKnownScrollPosition", _descriptor3, this);

      _initializerDefineProperty(this, "isPreviousDisabled", _descriptor4, this);

      _initializerDefineProperty(this, "isNextDisabled", _descriptor5, this);

      _initializerDefineProperty(this, "isNavigationDisabled", _descriptor6, this);

      _defineProperty(this, "styleNamespace", _podNames.default['horizontal-scroll']);

      _defineProperty(this, "guid", `horizontalScroll-${Ember.guidFor(this)}`);
    }

    get slidesPerBreakpoint() {
      var _this$args$slidesPerB;

      return (_this$args$slidesPerB = this.args.slidesPerBreakpoint) !== null && _this$args$slidesPerB !== void 0 ? _this$args$slidesPerB : DEFAULT_SLIDES_PER_BREAKPOINT;
    }

    get slidesToShow() {
      var _this$args$slidesToSh;

      return (_this$args$slidesToSh = this.args.slidesToShow) !== null && _this$args$slidesToSh !== void 0 ? _this$args$slidesToSh : DEFAULT_SLIDES_TO_SHOW;
    }

    get nextSlidePreviewWidth() {
      var _this$args$nextSlideP;

      return (_this$args$nextSlideP = this.args.nextSlidePreviewWidth) !== null && _this$args$nextSlideP !== void 0 ? _this$args$nextSlideP : DEFAULT_NEXT_SLIDE_PREVIEW_WIDTH;
    }

    get dividerWidth() {
      var _this$args$dividerWid;

      return (_this$args$dividerWid = this.args.dividerWidth) !== null && _this$args$dividerWid !== void 0 ? _this$args$dividerWid : DEFAULT_DIVIDER_WIDTH;
    }

    get edgeDividerWidth() {
      var _this$args$edgeDivide;

      return (_this$args$edgeDivide = this.args.edgeDividerWidth) !== null && _this$args$edgeDivide !== void 0 ? _this$args$edgeDivide : DEFAULT_EDGE_DIVIDER_WIDTH;
    }

    get slideWidth() {
      const {
        hasInitialized,
        slidesToShow,
        nextSlidePreviewWidth,
        edgeDividerWidth,
        dividerWidth
      } = this.slidesConfiguration;

      if (!hasInitialized) {
        return Ember.String.htmlSafe('');
      }

      const eachSlidesNextSlideFractionWidth = (nextSlidePreviewWidth + edgeDividerWidth) / slidesToShow;
      return Ember.String.htmlSafe(`width: calc((100% / ${slidesToShow}) - ${Math.ceil(eachSlidesNextSlideFractionWidth + dividerWidth)}px`);
    }

    get slidesConfiguration() {
      var _breakpoint$slidesToS, _breakpoint$nextSlide, _breakpoint$dividerWi, _breakpoint$edgeDivid;

      const slidesConfiguration = {
        hasInitialized: false
      };

      if (!this.slidesEl) {
        return slidesConfiguration;
      }

      slidesConfiguration.hasInitialized = true;
      const breakpoint = this.slidesPerBreakpoint.find(({
        breakpoint
      }) => window.matchMedia(breakpoint).matches);
      const slidesToShow = (_breakpoint$slidesToS = breakpoint === null || breakpoint === void 0 ? void 0 : breakpoint.slidesToShow) !== null && _breakpoint$slidesToS !== void 0 ? _breakpoint$slidesToS : this.slidesToShow;
      const nextSlidePreviewWidth = (_breakpoint$nextSlide = breakpoint === null || breakpoint === void 0 ? void 0 : breakpoint.nextSlidePreviewWidth) !== null && _breakpoint$nextSlide !== void 0 ? _breakpoint$nextSlide : this.nextSlidePreviewWidth;
      const dividerWidth = (_breakpoint$dividerWi = breakpoint === null || breakpoint === void 0 ? void 0 : breakpoint.dividerWidth) !== null && _breakpoint$dividerWi !== void 0 ? _breakpoint$dividerWi : this.dividerWidth;
      const edgeDividerWidth = (_breakpoint$edgeDivid = breakpoint === null || breakpoint === void 0 ? void 0 : breakpoint.edgeDividerWidth) !== null && _breakpoint$edgeDivid !== void 0 ? _breakpoint$edgeDivid : this.edgeDividerWidth;
      slidesConfiguration.slidesToShow = slidesToShow;
      slidesConfiguration.nextSlidePreviewWidth = nextSlidePreviewWidth;
      slidesConfiguration.dividerWidth = dividerWidth;
      slidesConfiguration.edgeDividerWidth = edgeDividerWidth;
      this.setCSSVariables(slidesConfiguration);
      return slidesConfiguration;
    }

    get hideNavigation() {
      var _this$args$hideNaviga;

      return (_this$args$hideNaviga = this.args.hideNavigation) !== null && _this$args$hideNaviga !== void 0 ? _this$args$hideNaviga : false;
    }

    get scrollBreakpoints() {
      const overflown = (0, _isOverflown.isOverflown)(this.slidesEl);

      if (!overflown) {
        return [];
      }

      const scrollBreakpoints = [];
      const {
        nextSlidePreviewWidth,
        edgeDividerWidth
      } = this.slidesConfiguration;
      const {
        offsetWidth,
        scrollWidth
      } = this.slidesEl;
      const sectionWidth = offsetWidth - (nextSlidePreviewWidth + edgeDividerWidth);

      for (let scroll = 0; scroll < scrollWidth; scroll += sectionWidth) {
        scrollBreakpoints.push(scroll);
      }

      return scrollBreakpoints;
    }

    setCSSVariables({
      dividerWidth,
      edgeDividerWidth
    }) {
      const rootEl = document.getElementById(this.guid);
      rootEl.style.setProperty('--divider-width', `${dividerWidth}px`);
      rootEl.style.setProperty('--edge-divider-width', `${edgeDividerWidth}px`);
    }

    onDidInsertSlides(el) {
      this.slidesEl = el;
    }

    onDidResizeSlides() {
      if (!this.slidesEl) {
        return;
      }

      this.isNavigationDisabled = !(0, _isOverflown.isOverflown)(this.slidesEl);
    }

    *onScroll(ev) {
      yield (0, _emberConcurrency.animationFrame)();
      const scrollPosition = ev.target.scrollLeft;
      const offsetWidth = ev.target.offsetWidth;
      const maxScrollWidth = this.slidesEl.scrollWidth;
      this.lastKnownScrollPosition = scrollPosition;
      this.isPreviousDisabled = scrollPosition <= 0;
      this.isNextDisabled = scrollPosition + offsetWidth >= maxScrollWidth;
    }

    onClickPrev() {
      const {
        scrollBreakpoints
      } = this;

      for (let i = scrollBreakpoints.length - 1; i >= 0; i--) {
        const section = scrollBreakpoints[i];

        if (section < this.lastKnownScrollPosition) {
          this.slidesEl.scrollTo({
            left: section,
            behavior: 'smooth'
          });
          break;
        }
      }
    }

    onClickNext() {
      const {
        scrollBreakpoints
      } = this;

      for (let i = 0; i < scrollBreakpoints.length; i++) {
        const section = scrollBreakpoints[i];

        if (section > this.lastKnownScrollPosition) {
          this.slidesEl.scrollTo({
            left: section,
            behavior: 'smooth'
          });
          break;
        }
      }
    }

  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "media", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "slidesEl", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "lastKnownScrollPosition", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return 0;
    }
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "isPreviousDisabled", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return true;
    }
  }), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "isNextDisabled", [_dec5], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return false;
    }
  }), _descriptor6 = _applyDecoratedDescriptor(_class.prototype, "isNavigationDisabled", [_dec6], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return true;
    }
  }), _applyDecoratedDescriptor(_class.prototype, "slideWidth", [_emberCachedDecoratorPolyfill.cached], Object.getOwnPropertyDescriptor(_class.prototype, "slideWidth"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "slidesConfiguration", [_emberCachedDecoratorPolyfill.cached], Object.getOwnPropertyDescriptor(_class.prototype, "slidesConfiguration"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "onDidInsertSlides", [_dec7], Object.getOwnPropertyDescriptor(_class.prototype, "onDidInsertSlides"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "onDidResizeSlides", [_dec8], Object.getOwnPropertyDescriptor(_class.prototype, "onDidResizeSlides"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "onScroll", [_emberConcurrency.restartableTask], Object.getOwnPropertyDescriptor(_class.prototype, "onScroll"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "onClickPrev", [_dec9], Object.getOwnPropertyDescriptor(_class.prototype, "onClickPrev"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "onClickNext", [_dec10], Object.getOwnPropertyDescriptor(_class.prototype, "onClickNext"), _class.prototype)), _class));
  _exports.default = HorizontalScrollComponent;
});