define("flaneur/pods/components/button/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const themes = ['primary', 'secondary', 'terciary'];
  const sizes = ['small', 'medium', 'large'];

  var _default = Ember.Component.extend({
    // Public API
    theme: 'primary',
    size: 'medium',
    loading: false,
    rounded: false,

    onClick() {},

    // Private API
    tagName: 'button',
    classNames: ['button'],
    classNameBindings: ['isLoading:button--loading', 'isPrimary:button--primary', 'isSecondary:button--secondary', 'isTerciary:button--terciary', 'isSmall:button--small', 'isLarge:button--large', 'isRounded:button--rounded'],
    attributeBindings: ['disabled'],
    implicitlyLoading: false,
    isPrimary: Ember.computed.equal('theme', 'primary'),
    isSecondary: Ember.computed.equal('theme', 'secondary'),
    isTerciary: Ember.computed.equal('theme', 'terciary'),
    isSmall: Ember.computed.equal('size', 'small'),
    isLarge: Ember.computed.equal('size', 'large'),
    isLoading: Ember.computed.or('loading', 'implicitlyLoading'),
    isRounded: Ember.computed.equal('rounded', true),

    init() {
      this._super(...arguments);

      (false && !(themes.includes(this.theme)) && Ember.assert(`The theme ${this.theme} passed to <Button> is not valid. Please use "primary", "secondary", or "terciary".`, themes.includes(this.theme)));
      (false && !(sizes.includes(this.size)) && Ember.assert(`The size ${this.size} passed to <Button> is not valid. Please use "small", "medium", or "large".`, sizes.includes(this.size)));
    },

    click() {
      if (this.isLoading) {
        return;
      }

      Ember.trySet(this, 'implicitlyLoading', true);
      const result = this.onClick();
      const isPromise = result && typeof result.then === 'function';

      const setLoadingFalse = () => Ember.trySet(this, 'implicitlyLoading', false);

      if (isPromise) {
        return result.then(setLoadingFalse, setLoadingFalse);
      }

      setLoadingFalse();
      return result;
    }

  });

  _exports.default = _default;
});