define("flaneur/models/frame-tag/index", ["exports", "fetch", "flaneur/config/environment", "query-string"], function (_exports, _fetch, _environment, _queryString) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const blackListedTags = ['trouva-loves'];

  class FrameTag {
    constructor({
      _id,
      name,
      slug
    }) {
      this._id = _id;
      this.name = name;
      this.slug = slug;
    }

    static async find(query = {}) {
      const queryParams = _queryString.default.stringify(query);

      const url = `${_environment.default.apiURL}/api/v2/frame_tags?${queryParams}`;
      const res = await (0, _fetch.default)(url, {
        method: 'GET',
        credentials: 'include',
        cache: false
      });

      if (!res.ok) {
        throw new Error('Unable to find frame_tags');
      }

      const {
        frame_tags: frameTags
      } = await res.json();
      return frameTags.filter(({
        slug
      }) => !blackListedTags.includes(slug)).map(frameTag => new FrameTag(frameTag));
    }

  }

  _exports.default = FrameTag;
});