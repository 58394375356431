define("flaneur/pods/components/pages/terms-and-conditions-index/component", ["exports", "flaneur/models/country", "flaneur/models/user"], function (_exports, _country, _user) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    brand: Ember.inject.service(),
    media: Ember.inject.service(),
    session: Ember.inject.service(),
    analytics: Ember.inject.service(),
    // Public API
    model: null,
    // Private API
    classNames: ['page'],
    helpCentreArticleUrl: 'https://trouvaboutiques.zendesk.com/hc/en-us/articles/360015629097',
    defaultFieldValue: '-',
    termsAndConditions: Ember.computed.alias('model.terms_and_conditions'),
    membershipFee: Ember.computed.alias('model.membership_fee'),
    termsUrl: Ember.computed.or('termsAndConditions.pending_terms_url', 'termsAndConditions.terms_url'),
    retailerFields: Ember.computed('model.{name,primary_email,support_phone}', function () {
      return [{
        title: 'Name',
        value: this.model.name
      }, {
        title: 'Phone',
        value: this.model.support_phone
      }, {
        title: 'Email Address',
        value: this.model.primary_email
      }];
    }),
    registeredFields: Ember.computed('model.{contract_owner_name,registered_name,registered_number,vat_number}', 'registeredAddress', 'registeredCountry', function () {
      return [{
        title: 'Contract Signatory Name',
        value: this.model.contract_owner_name
      }, {
        title: 'Legal Name of Retailer',
        value: this.model.registered_name
      }, {
        title: 'Registered No.',
        value: this.model.registered_number
      }, {
        title: 'VAT Number',
        value: this.model.vat_number
      }, {
        title: 'Registered Address',
        value: this.registeredAddress
      }, {
        title: 'Registered Country',
        value: this.registeredCountry.then(({
          name
        }) => name)
      }];
    }),
    commercialFields: Ember.computed('model.{commission_rate,enable_fix_charge,fix_shipping_charge,membership_fee,other_commercial_terms,other_commercial_terms_service_fee}', function () {
      return [{
        title: 'Membership Fee',
        value: this.model.membership_fee,
        isPrice: true
      }, {
        title: 'Commission Level',
        value: `${this.model.commission_rate}%`
      }, {
        title: 'Fulfillment Contribution',
        value: this.model.enable_fix_charge ? this.model.fix_shipping_charge || '0' : '0',
        isPrice: true
      }, {
        title: 'Transit Protection',
        value: 'Included as standard'
      }, {
        title: 'Responsibility for Packaging',
        value: 'Retailer'
      }, {
        title: 'Other',
        value: `${this.model.other_commercial_terms ? `<p>${this.model.other_commercial_terms}</p>` : ''}` + (this.model.other_commercial_terms_service_fee ? this.model.other_commercial_terms_service_fee.split('\n\n').map(part => `<p>${part}</p>`).join('') : '')
      }];
    }),
    registeredAddress: Ember.computed('model.{registered_address_line_1,registered_address_line_2}', function () {
      const {
        registered_address_line_1: line1,
        registered_address_line_2: line2
      } = this.model;

      if (Ember.isPresent(line1) && Ember.isPresent(line2)) {
        return `${line1}<br>${line2}`;
      }

      if (Ember.isPresent(line1)) {
        return line1;
      }

      return null;
    }),
    registeredCountry: Ember.computed('defaultFieldValue', 'model.registered_address_country', async function () {
      const [country] = Ember.isPresent(this.model.registered_address_country) ? await _country.default.find({
        _id: this.model.registered_address_country
      }) : await Ember.RSVP.resolve([{
        name: this.defaultFieldValue
      }]);
      return country;
    }),
    acceptedBy: Ember.computed('termsAndConditions.accepted_by', function () {
      const userId = this.termsAndConditions.accepted_by;
      return userId ? _user.default.findById(userId) : Ember.RSVP.resolve(null);
    }),
    actions: {
      acceptTerms() {
        this.analytics.gtm({
          action: 'checked_tandc_checkbox'
        });
        return this.model.acceptTerms(this.termsAndConditions.pending_terms_url);
      }

    }
  });

  _exports.default = _default;
});