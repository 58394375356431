define("flaneur/pods/components/reservation-product/other/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    reservation: null,
    sentInStatement: Ember.computed.alias('reservation.sent_in_statement'),
    isReasonSuccess: Ember.computed.equal('reservation.status', 'success'),
    isReasonFail: Ember.computed.equal('reservation.status', 'fail'),
    isReasonFailCancelled: Ember.computed.equal('reservation.status', 'fail cancelled'),
    isReasonFailOther: Ember.computed.equal('reservation.status', 'fail other')
  });

  _exports.default = _default;
});