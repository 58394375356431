define("flaneur/pods/brand/boutiques/boutique/curate/brands/brand/basket/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "aElJ9Baz",
    "block": "{\"symbols\":[\"@model\"],\"statements\":[[8,\"pages/curate/brands/brand/basket-page\",[],[[\"@brand\",\"@orders\"],[[32,1,[\"brand\"]],[32,1,[\"orders\"]]]],null],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[]}",
    "moduleName": "flaneur/pods/brand/boutiques/boutique/curate/brands/brand/basket/template.hbs"
  });

  _exports.default = _default;
});