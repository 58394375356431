define("flaneur/pods/reset/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "XqesepSu",
    "block": "{\"symbols\":[],\"statements\":[[1,[30,[36,1],null,[[\"notify\"],[[35,0]]]]],[2,\"\\n\\n\"],[10,\"div\"],[14,0,\"lp-dots\"],[12],[2,\"\\n  \"],[10,\"header\"],[14,0,\"lp-header\"],[12],[2,\"\\n\"],[6,[37,2],null,[[\"class\",\"route\"],[\"lp-logo\",\"index\"]],[[\"default\"],[{\"statements\":[[2,\"      \"],[10,\"img\"],[14,\"src\",\"https://trouva-media.imgix.net/rphc/logos/trouva_2018_h75.png\"],[14,\"alt\",\"Trouva logo\"],[12],[13],[2,\"\\n\"]],\"parameters\":[]}]]],[6,[37,2],null,[[\"class\",\"route\"],[\"lp-login\",\"login\"]],[[\"default\"],[{\"statements\":[[2,\"      Login\\n\"]],\"parameters\":[]}]]],[6,[37,2],null,[[\"class\",\"route\"],[\"lp-link\",\"index\"]],[[\"default\"],[{\"statements\":[[2,\"      Home\\n\"]],\"parameters\":[]}]]],[2,\"  \"],[13],[2,\"\\n\"],[13],[2,\"\\n\\n\"],[10,\"div\"],[14,0,\"log\"],[12],[2,\"\\n  \"],[10,\"div\"],[14,0,\"log-container\"],[12],[2,\"\\n    \"],[11,\"form\"],[24,0,\"log-form\"],[4,[38,3],[[32,0],\"resetPassword\"],[[\"on\"],[\"submit\"]]],[12],[2,\"\\n      \"],[10,\"h1\"],[14,0,\"title\"],[12],[2,\"Enter new password\"],[13],[2,\"\\n      \"],[1,[30,[36,5],null,[[\"class\",\"name\",\"type\",\"placeholder\",\"value\"],[\"form-control\",\"form-password\",\"password\",\"New password\",[35,4,[\"new_password\"]]]]]],[2,\"\\n      \"],[1,[30,[36,5],null,[[\"class\",\"name\",\"type\",\"placeholder\",\"value\"],[\"form-control\",\"form-password\",\"password\",\"Confirm new password\",[35,4,[\"new_password_confirmation\"]]]]]],[2,\"\\n      \"],[8,\"button\",[[24,4,\"submit\"]],[[],[]],[[\"default\"],[{\"statements\":[[2,\"Set password\"]],\"parameters\":[]}]]],[2,\"\\n    \"],[13],[2,\"\\n  \"],[13],[2,\"\\n\"],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"notify\",\"nav-bar-alert\",\"link-to\",\"action\",\"model\",\"input\"]}",
    "moduleName": "flaneur/pods/reset/template.hbs"
  });

  _exports.default = _default;
});