define("flaneur/models/curate/order/index", ["exports", "fetch", "date-fns", "query-string", "flaneur/config/environment", "flaneur/utils/price", "flaneur/models/model", "flaneur/models/curate/order/validations"], function (_exports, _fetch, _dateFns, _queryString, _environment, _price, _model, _validations) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _dec8, _dec9, _dec10, _dec11, _dec12, _dec13, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _descriptor6, _descriptor7, _descriptor8, _descriptor9, _descriptor10, _descriptor11, _descriptor12;

  function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); enumerableOnly && (symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols); } return keys; }

  function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = null != arguments[i] ? arguments[i] : {}; i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { _defineProperty(target, key, source[key]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } return target; }

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  let CurateOrder = (_dec = Ember._tracked, _dec2 = Ember._tracked, _dec3 = Ember._tracked, _dec4 = Ember._tracked, _dec5 = Ember._tracked, _dec6 = Ember._tracked, _dec7 = Ember._tracked, _dec8 = Ember._tracked, _dec9 = Ember._tracked, _dec10 = Ember._tracked, _dec11 = Ember._tracked, _dec12 = Ember._tracked, _dec13 = Ember._action, (_class = class CurateOrder extends _model.default {
    constructor(order = {}) {
      super({
        validations: _validations.default
      });

      _initializerDefineProperty(this, "agrees_with_brand_terms", _descriptor, this);

      _initializerDefineProperty(this, "boutique", _descriptor2, this);

      _initializerDefineProperty(this, "brands", _descriptor3, this);

      _initializerDefineProperty(this, "contact_details", _descriptor4, this);

      _initializerDefineProperty(this, "created_at", _descriptor5, this);

      _initializerDefineProperty(this, "currency", _descriptor6, this);

      _initializerDefineProperty(this, "delivery_address", _descriptor7, this);

      _initializerDefineProperty(this, "has_brand_account", _descriptor8, this);

      _initializerDefineProperty(this, "invoice_details", _descriptor9, this);

      _initializerDefineProperty(this, "order_number", _descriptor10, this);

      _initializerDefineProperty(this, "reservations", _descriptor11, this);

      _initializerDefineProperty(this, "total_price", _descriptor12, this);

      const {
        _id,
        agrees_with_brand_terms = false,
        brands = [],
        contact_details,
        created_at,
        currency,
        delivery_address,
        has_brand_account = false,
        invoice_details = {
          address: {}
        },
        order_number,
        reservations = [],
        total_price
      } = order;
      this._id = _id;
      this.agrees_with_brand_terms = agrees_with_brand_terms;
      this.brands = brands;
      this.contact_details = contact_details;
      this.created_at = created_at;
      this.currency = currency;
      this.delivery_address = delivery_address;
      this.has_brand_account = has_brand_account;
      this.invoice_details = invoice_details;
      this.order_number = order_number;
      this.reservations = reservations;
      this.total_price = total_price;
    }

    get formattedDeliveryAddress() {
      const {
        line_1,
        line_2
      } = this.delivery_address;
      return [line_1, line_2].join(', ');
    }

    get formattedInvoiceAddress() {
      const {
        address: {
          line_1,
          line_2
        }
      } = this.invoice_details;
      return [line_1, line_2].join(', ');
    }

    get brand() {
      return this.brands[0];
    }

    async create() {
      const body = {
        agrees_with_brand_terms: this.agrees_with_brand_terms,
        boutique: this.boutique,
        brands: this.brands,
        contact_details: this.contact_details,
        delivery_address: this.delivery_address,
        invoice_details: this.invoice_details,
        reservations: this.reservations.map(reservation => ({
          product_id: reservation.id,
          quantity: reservation.quantity,
          sku: reservation.selectedStockLine.sku
        }))
      };
      const res = await (0, _fetch.default)(`${_environment.default.curateApiURL}/api/v1/orders`, {
        method: 'POST',
        credentials: 'include',
        cache: false,
        headers: {
          'Content-Type': 'application/json; charset=UTF-8'
        },
        body: JSON.stringify(body)
      });

      if (!res.ok) {
        throw new Error('error creating order');
      }

      const {
        orders: [order]
      } = await res.json();
      Ember.setProperties(this, order);
      return this;
    }

    static async find(query = {}) {
      const queryParams = _queryString.default.stringify(query, {
        arrayFormat: 'bracket'
      });

      const url = `${_environment.default.curateApiURL}/api/v1/orders?${queryParams}`;
      const res = await (0, _fetch.default)(url, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json; charset=UTF-8'
        },
        credentials: 'include',
        cache: false
      });
      const {
        orders = []
      } = await res.json();
      return orders.map(order => new CurateOrder(order));
    }

    static async findById(id) {
      const url = `${_environment.default.curateApiURL}/api/v1/orders/${id}`;
      const res = await (0, _fetch.default)(url, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json; charset=UTF-8'
        },
        credentials: 'include',
        cache: false
      });

      if (!res.ok) {
        throw Error(`error finding order ${id} by ID`);
      }

      const {
        orders: [order] = []
      } = await res.json();
      return new CurateOrder(order);
    }

    async downloadCsv(boutique) {
      const Papa = await emberAutoImportDynamic("papaparse").then(module => module.default);
      const total = (0, _price.formatPrice)(this.total_price);
      const csvLines = this.reservations.map((reservation, index) => {
        const productData = {
          'Product Number': reservation.stock_line.sku,
          'Product Name': reservation.product.name,
          'Product Pack Price (Excl. VAT)': (0, _price.formatPrice)(reservation.stock_line.wholesale_price),
          'Pack Quantity': reservation.quantity,
          'Total Price (Excl. VAT)': (0, _price.formatPrice)({
            amount: reservation.stock_line.wholesale_price.amount * reservation.quantity,
            currency: reservation.stock_line.wholesale_price.currency
          })
        };
        return index !== 0 ? productData : _objectSpread({
          'Order Number': this.order_number,
          'Created At': (0, _dateFns.format)((0, _dateFns.parseISO)(this.created_at), 'PPP'),
          Boutique: boutique.name,
          'Shipping address': this.formattedDeliveryAddress,
          'Invoice address': this.formattedInvoiceAddress,
          VAT: boutique.vat_number,
          Brand: this.brands[0].name,
          Total: total
        }, productData);
      });
      const csv = Papa.unparse(csvLines, {
        quotes: true
      });
      const anchor = document.createElement('a');
      anchor.href = `data:text/csv;charset=utf-8,${csv}`;
      anchor.download = `curate-order-${this.order_number}.csv`;
      document.body.appendChild(anchor);
      anchor.click();
      document.body.removeChild(anchor);
    }

  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "agrees_with_brand_terms", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "boutique", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "brands", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "contact_details", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "created_at", [_dec5], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor6 = _applyDecoratedDescriptor(_class.prototype, "currency", [_dec6], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor7 = _applyDecoratedDescriptor(_class.prototype, "delivery_address", [_dec7], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor8 = _applyDecoratedDescriptor(_class.prototype, "has_brand_account", [_dec8], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor9 = _applyDecoratedDescriptor(_class.prototype, "invoice_details", [_dec9], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor10 = _applyDecoratedDescriptor(_class.prototype, "order_number", [_dec10], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor11 = _applyDecoratedDescriptor(_class.prototype, "reservations", [_dec11], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor12 = _applyDecoratedDescriptor(_class.prototype, "total_price", [_dec12], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "downloadCsv", [_dec13], Object.getOwnPropertyDescriptor(_class.prototype, "downloadCsv"), _class.prototype)), _class));
  _exports.default = CurateOrder;
});