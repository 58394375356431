define("flaneur/services/contact-us", ["exports", "flaneur/mixins/request"], function (_exports, _request) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Service.extend(_request.default, {
    send(retailer) {
      return this.request({
        method: 'POST',
        url: this.apiURL('contact_us'),
        data: JSON.stringify(retailer)
      });
    }

  });

  _exports.default = _default;
});