define("flaneur/models/packaging/promotion-code/index", ["exports", "fetch", "flaneur/config/environment"], function (_exports, _fetch, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const UNLIMITED_USAGE = 0;

  class PackagingPromotionCode {
    constructor({
      _id,
      code,
      type,
      expired_at,
      currency,
      value,
      value_GBP,
      value_EUR,
      minimum_value_GBP,
      minimum_value_EUR,
      total_redemptions,
      limit_to_x_usage,
      single_usage
    }) {
      this.id = _id;
      this.code = code;
      this.type = type;
      this.expired_at = expired_at;
      this.currency = currency;
      this.value = value;
      this.value_GBP = value_GBP;
      this.value_EUR = value_EUR;
      this.minimum_value_GBP = minimum_value_GBP;
      this.minimum_value_EUR = minimum_value_EUR;
      this.total_redemptions = total_redemptions;
      this.limit_to_x_usage = limit_to_x_usage || UNLIMITED_USAGE;
      this.single_usage = single_usage;
    }

    get isValid() {
      return !this.isExpired && !this.hasReachedMaximumUsage;
    }

    get isExpired() {
      return Ember.isPresent(this.expired_at) && new Date(this.expired_at) < new Date();
    }

    get isUnlimited() {
      return this.limit_to_x_usage === UNLIMITED_USAGE;
    }

    get hasReachedMaximumUsage() {
      const hasReachedLimitUsage = !this.isUnlimited && this.limit_to_x_usage <= this.total_redemptions;
      const hasReachedSingleUsage = this.single_usage && this.total_redemptions === 1;
      return hasReachedLimitUsage || hasReachedSingleUsage;
    }

    static async findById(id) {
      const url = `${_environment.default.packagingApiURL}/api/promotion-codes/${id}`;
      const res = await (0, _fetch.default)(url, {
        method: 'GET',
        credentials: 'include',
        cache: false
      });

      if (!res.ok) {
        return null;
      }

      const {
        promotion_codes = []
      } = await res.json();
      const [promotionCode] = promotion_codes;
      return promotionCode ? new PackagingPromotionCode(promotionCode) : null;
    }

  }

  _exports.default = PackagingPromotionCode;
});