define("flaneur/pods/components/nav-tabs/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "U0lZIj0u",
    "block": "{\"symbols\":[\"tab\",\"index\",\"&default\"],\"statements\":[[10,\"div\"],[12],[2,\"\\n  \"],[10,\"nav\"],[14,0,\"tabs-nav\"],[12],[2,\"\\n\"],[6,[37,7],[[30,[36,6],[[30,[36,6],[[35,5]],null]],null]],null,[[\"default\"],[{\"statements\":[[2,\"      \"],[11,\"a\"],[16,0,[30,[36,3],[[30,[36,2],[[32,2],[35,1]],null],\"active\",\"\"],null]],[4,[38,4],[[32,0],\"changeTab\",[32,2]],null],[12],[2,\"\\n        \"],[2,[32,1,[\"label\"]]],[2,\"\\n      \"],[13],[2,\"\\n\"]],\"parameters\":[1,2]}]]],[2,\"  \"],[13],[2,\"\\n\\n  \"],[10,\"div\"],[14,0,\"tabs-views\"],[12],[2,\"\\n\"],[6,[37,3],[[35,0]],null,[[\"default\"],[{\"statements\":[[2,\"      \"],[18,3,[[35,0]]],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"  \"],[13],[2,\"\\n\"],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"activeTab\",\"activeIndex\",\"eq\",\"if\",\"action\",\"tabs\",\"-track-array\",\"each\"]}",
    "moduleName": "flaneur/pods/components/nav-tabs/template.hbs"
  });

  _exports.default = _default;
});