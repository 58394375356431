define("flaneur/pods/brand/boutiques/boutique/products/bulk/upload/route", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  class BrandBoutiquesBoutiqueProductsBulkUploadRoute extends Ember.Route {}

  _exports.default = BrandBoutiquesBoutiqueProductsBulkUploadRoute;
});