define("flaneur/pods/components/price/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "5GK7I2jb",
    "block": "{\"symbols\":[],\"statements\":[[10,\"div\"],[14,0,\"regular\"],[12],[2,\"\\n  \"],[1,[30,[36,3],[[30,[36,2],[[35,4,[\"amount\"]]],null]],[[\"style\",\"currency\"],[[35,1],[35,4,[\"currency\"]]]]]],[2,\"\\n\"],[13],[2,\"\\n\\n\"],[6,[37,6],[[35,5]],null,[[\"default\"],[{\"statements\":[[2,\"  \"],[10,\"div\"],[14,0,\"sale\"],[12],[2,\"\\n    \"],[1,[30,[36,3],[[30,[36,2],[[35,0,[\"amount\"]]],null]],[[\"style\",\"currency\"],[[35,1],[35,0,[\"currency\"]]]]]],[2,\"\\n  \"],[13],[2,\"\\n\"]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"salesPrice\",\"formatStyle\",\"to-money\",\"format-number\",\"price\",\"isOnSale\",\"if\"]}",
    "moduleName": "flaneur/pods/components/price/template.hbs"
  });

  _exports.default = _default;
});