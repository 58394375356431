define("flaneur/pods/brand/boutiques/boutique/orders/stage/route", ["exports", "flaneur/models/shipment", "flaneur/models/reservation", "flaneur/enums/shipping-provider", "flaneur/pods/brand/boutiques/boutique/orders/fetch", "flaneur/pods/brand/boutiques/boutique/orders/utils"], function (_exports, _shipment, _reservation, _shippingProvider, _fetch, _utils) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); enumerableOnly && (symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols); } return keys; }

  function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = null != arguments[i] ? arguments[i] : {}; i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { _defineProperty(target, key, source[key]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } return target; }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  const placeholderThumbnail = 'https://trouva-media.imgix.net/rphc/logos/placeholder-with-logo.png';
  const START_PAGE_NUMBER = 1;

  var _default = Ember.Route.extend({
    analytics: Ember.inject.service(),
    brand: Ember.inject.service(),
    orderHistory: Ember.inject.service(),
    router: Ember.inject.service(),
    queryParams: {
      ordernumber: {
        refreshModel: true
      }
    },

    init() {
      this._super(...arguments);

      this.router.on('routeDidChange', transition => {
        if (transition.targetName && !transition.targetName.includes('brand.boutiques.boutique.orders')) {
          return;
        }

        this.orderHistory.addRoute(this.router.currentRoute);
      });
    },

    deactivate() {
      this._super(...arguments);

      this.set('currentModel.currentPage', START_PAGE_NUMBER);
    },

    resetController(controller, isExiting) {
      if (isExiting) {
        controller.set('ordernumber', undefined);
      }
    },

    beforeModel({
      to: {
        params: {
          stage
        }
      }
    }) {
      const isValidStage = ['active', 'click-and-collect', 'collection', 'completed', 'print', 'return'].includes(stage);

      if (!isValidStage) {
        return this.transitionTo('brand.boutiques.boutique.orders.stage', this.brand, this.brand.currentBoutique._id, 'active');
      }

      if (this.currentModel) {
        this.set('currentModel.currentPage', START_PAGE_NUMBER);
      }
    },

    /**
     * !! Keep in mind !!
     * If you update this model, you may also need to update _loadMore()
     * otherwise items loaded via infinite scroll will be without the
     * new data you are adding.
     */
    model({
      ordernumber,
      stage
    }) {
      this.set('stage', stage);
      ordernumber = (0, _utils.parseSingleOrMultipleOrderNumbers)(ordernumber);
      this.set('orderHistory.orderSearched', !!ordernumber);
      const isBoutiqueShippingProviderOther = this.brand.currentBoutique.shipping_provider === _shippingProvider.default.OTHER;
      const boutiqueId = this.brand.currentBoutique._id;
      const {
        activeOrdersCount,
        printOrdersCount,
        collectOrdersCount
      } = this.modelFor('brand.boutiques.boutique.orders');
      return (0, _fetch.fetchOrders)(boutiqueId, ordernumber, stage).then(({
        shipments,
        reservationBoutiques,
        reservations: groupedReservations,
        currentPage,
        lastPage,
        total
      }) => {
        return Ember.RSVP.hash({
          activeOrdersCount,
          printOrdersCount,
          collectOrdersCount,
          boutique: this.brand.currentBoutique,
          brand_id: this.brand._id,
          currentPage,
          enableInfinite: true,
          groupedReservations,
          isBoutiqueShippingProviderOther,
          lastPage,
          loading: false,
          ordernumber,
          placeholderThumbnail,
          reservationBoutiques,
          shipments,
          stage,
          total,
          productsRecommendations: {}
        });
      });
    },

    afterModel(model) {
      this._super(...arguments);

      const {
        reservationBoutiques,
        ordernumber,
        groupedReservations
      } = model;
      this.setRestockRecommendations(groupedReservations);
      const [orderId] = Object.keys(reservationBoutiques);

      if (!orderId || !ordernumber) {
        return;
      }

      const {
        status: orderStatus
      } = reservationBoutiques[orderId][0];

      if (orderStatus !== this.stage) {
        return this.setSearchOrderParamsIfIsCallFromSearch(ordernumber, reservationBoutiques);
      }
    },

    setSearchOrderParamsIfIsCallFromSearch(ordernumber, reservationBoutiques) {
      if (ordernumber.length === 0 || Object.keys(reservationBoutiques).length === 0) {
        return this.set('currentModel.loading', false);
      }

      const currentRouteName = this.orderHistory.currentRoute();
      const orderId = Object.keys(reservationBoutiques)[0];
      const {
        status: orderStatus
      } = reservationBoutiques[orderId][0];
      const nextRouteName = `brand.boutiques.boutique.orders.stage.${orderStatus}`;

      if (nextRouteName === currentRouteName) {
        this.transitionTo({
          queryParams: {
            ordernumber
          }
        });
        return this.refresh();
      }

      return this.transitionTo('brand.boutiques.boutique.orders.stage', orderStatus, {
        queryParams: {
          ordernumber
        }
      });
    },

    _loadMore() {
      const {
        currentPage,
        lastPage
      } = this.currentModel;

      if (lastPage === null || currentPage > lastPage) {
        this.set('currentModel.loading', false);
        return Ember.RSVP.reject();
      }

      if (this.currentModel && this.currentModel.loading) {
        return Ember.RSVP.reject();
      }

      this.set('currentModel.loading', true);
      const boutiqueId = this.brand.get('currentBoutique._id');
      const params = [boutiqueId, (0, _utils.getStage)(this.stage), 1, undefined, 1];
      return (0, _fetch.fetchReservationBoutiqueWithMeta)(...params).then(({
        total
      }) => {
        const ordersDifference = this.get('currentModel.total') - total;

        if (ordersDifference > 0) {
          const pageDifference = Math.ceil(ordersDifference / 10);
          this.decrementProperty('currentModel.currentPage', pageDifference);
        }

        return (0, _fetch.fetchOrders)(boutiqueId, undefined, this.stage, this.currentModel).then(({
          shipments,
          reservationBoutiques,
          reservations,
          currentPage,
          total
        }) => {
          this.set('currentModel.currentPage', currentPage);
          this.set('currentModel.total', total);
          this.set('currentModel.loading', false);
          const currentModel = this.currentModel;

          const groupedReservations = _objectSpread(_objectSpread({}, currentModel.groupedReservations), reservations);

          this.set('currentModel.groupedReservations', groupedReservations);
          this.set('currentModel.reservationBoutiques', _objectSpread(_objectSpread({}, currentModel.reservationBoutiques), reservationBoutiques));
          this.set('currentModel.shipments', _objectSpread(_objectSpread({}, currentModel.shipments), shipments));
          this.setRestockRecommendations(groupedReservations);
        });
      });
    },

    async setRestockRecommendations(groupedReservations) {
      const boutiqueId = this.brand.currentBoutique._id;
      const recommendations = await (0, _fetch.fetchBoutiqueRestockRecommendations)(groupedReservations, boutiqueId);
      this.set('currentModel.productsRecommendations', recommendations);
    },

    _resetSearch(stayInSamePage = false, ordernumber) {
      const currentRoute = this.orderHistory.currentRoute();
      const previousRoute = this.orderHistory.previousRoute();
      const orderSearched = this.orderHistory.orderSearched;

      this._cleanPreviousResults();

      this.set('orderHistory.orderSearched', false);

      if (stayInSamePage || !orderSearched || !previousRoute || previousRoute === currentRoute) {
        this.transitionTo({
          queryParams: {
            ordernumber
          }
        });
        return this._refreshRoute();
      }

      const {
        route,
        stage
      } = this.orderHistory.parseRoute(previousRoute);
      return this._transitionTo(route, stage, false);
    },

    _cleanPreviousResults() {
      this.setProperties({
        'currentModel.groupedReservations': {},
        'currentModel.reservationBoutiques': {},
        'currentModel.weengsPickups': {},
        'currentModel.shipments': {},
        'currentModel.loading': true
      });
    },

    _refreshRoute() {
      this.setProperties({
        'currentModel.currentPage': 1,
        'currentModel.ordernumber': '',
        'orderHistory.orderSearched': false
      });
      return this.refresh();
    },

    _transitionTo(route, stage, cleanPreviousResults = true) {
      if (cleanPreviousResults) {
        this._cleanPreviousResults();
      }

      this.set('currentModel.enableInfinite', false);
      this.set('currentModel.loading', true);
      return this.transitionTo(route, this.brand, this.brand.currentBoutique, stage);
    },

    _searchOrder(orderReference) {
      const boutiqueId = this.brand.currentBoutique._id;
      this.set('currentModel.currentPage', 1);
      return (0, _fetch.fetchOrders)(boutiqueId, orderReference, this.stage, this.currentModel).then(({
        reservationBoutiques
      }) => {
        return this.setSearchOrderParamsIfIsCallFromSearch(orderReference, reservationBoutiques);
      });
    },

    actions: {
      loadMore() {
        return this._loadMore();
      },

      markAsCollected(reservationBoutique) {
        this.analytics.gtm({
          action: 'clicked_mark_as_collected',
          label: reservationBoutique.order
        });
        return reservationBoutique.put().then(() => this.analytics.gtm({
          action: 'marked_as_collected',
          label: reservationBoutique.order
        })).catch(() => this.analytics.gtm({
          action: 'mark_as_collected_failed',
          label: reservationBoutique.order
        }));
      },

      requestedManualLabel(reservationBoutique) {
        return reservationBoutique.put();
      },

      updateReservationBoutique(reservationBoutique) {
        return reservationBoutique.put();
      },

      bookShipment(boutiqueId, orderId) {
        return _shipment.default.post(boutiqueId, orderId);
      },

      inStock(order, boutique) {
        return _reservation.default.confirmReservations({
          order,
          boutique
        });
      },

      setFailOther(reservation) {
        reservation.setProperties({
          status: 'fail other',
          not_access_point_eligible: true
        });
        return reservation.put();
      },

      shipped(reservation) {
        reservation.set('shipped', true);
        return reservation.put();
      },

      addLoading() {
        this.set('orderHistory.orderSearched', false);

        this._cleanPreviousResults();

        this.setProperties({
          'currentModel.enableInfinite': false,
          'currentModel.loading': true
        });
      },

      refresh(ordernumber) {
        this._resetSearch(true, ordernumber);
      },

      resetSearch() {
        return this._resetSearch();
      },

      search(orderReference = '') {
        if (Ember.isEmpty(orderReference)) {
          this.set('currentModel.loading', true);
          return this._refreshRoute();
        }

        this.setProperties({
          'currentModel.enableInfinite': false,
          'orderHistory.orderSearched': true
        });

        this._cleanPreviousResults();

        orderReference = (0, _utils.parseSingleOrMultipleOrderNumbers)(orderReference);

        if (!orderReference) {
          return this.set('currentModel.loading', false);
        }

        orderReference = orderReference.indexOf('#') === 0 ? orderReference.substr(1) : orderReference;
        return this._searchOrder(orderReference);
      }

    }
  });

  _exports.default = _default;
});