define("flaneur/mixins/authorized-route", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Mixin.create({
    brand: Ember.inject.service(),
    notify: Ember.inject.service(),
    session: Ember.inject.service(),

    beforeModel() {
      const {
        user
      } = this.session;

      if (user.isAdmin || user.isRetailAdmin || user.retail_permissions.includes(this.permission)) {
        return this._super(...arguments);
      }

      this.notify.show('error', "Oops! You are not authorized to view the requested page. You've been redirected to the Orders page.", 5000);
      return this.transitionTo('brand.boutiques.boutique.orders.index', this.brand, this.brand.currentBoutique._id);
    }

  });

  _exports.default = _default;
});