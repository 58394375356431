define("flaneur/pods/components/pages/dashboard/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "0Wkrz5gK",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[10,\"div\"],[15,0,[31,[\"page \",[32,0,[\"styleNamespace\"]]]]],[12],[2,\"\\n  \"],[10,\"div\"],[14,0,\"tabs tabs--featured\"],[12],[2,\"\\n    \"],[8,\"link-to\",[[24,0,\"tabs__tab\"]],[[\"@route\",\"@track\",\"@activeClass\"],[\"brand.boutiques.boutique.dashboard.index\",[32,0,[\"trackDashboardTab\"]],\"tabs__tab--selected\"]],[[\"default\"],[{\"statements\":[[2,\"\\n      \"],[10,\"div\"],[14,0,\"tabs__label\"],[12],[2,\"\\n        \"],[8,\"fa-icon\",[],[[\"@icon\"],[\"home\"]],null],[2,\"\\n        Home\\n      \"],[13],[2,\"\\n    \"]],\"parameters\":[]}]]],[2,\"\\n\\n    \"],[8,\"link-to\",[[24,0,\"tabs__tab\"]],[[\"@route\",\"@activeClass\",\"@track\"],[\"brand.boutiques.boutique.dashboard.operational-metrics\",\"tabs__tab--selected\",[32,0,[\"trackOperationalMetricsTab\"]]]],[[\"default\"],[{\"statements\":[[2,\"\\n      \"],[10,\"div\"],[14,0,\"tabs__label\"],[12],[2,\"\\n        \"],[8,\"fa-icon\",[],[[\"@icon\"],[\"analytics\"]],null],[2,\"\\n        Operational Metrics\\n      \"],[13],[2,\"\\n    \"]],\"parameters\":[]}]]],[2,\"\\n  \"],[13],[2,\"\\n\\n  \"],[10,\"div\"],[14,0,\"subpage\"],[12],[2,\"\\n    \"],[18,1,null],[2,\"\\n  \"],[13],[2,\"\\n\"],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[]}",
    "moduleName": "flaneur/pods/components/pages/dashboard/template.hbs"
  });

  _exports.default = _default;
});