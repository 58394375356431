define("flaneur/pods/upgrade-account/route", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    model(params) {
      return Ember.Object.create({
        role: 'retail',
        role_token: params.role_token
      });
    },

    actions: {
      upgrade(user) {
        return this.session.upgradeAccount(user.get('password'), user.get('passwordConfirmation'), user.get('role_token')).then(() => {
          this.transitionTo('login');
          this.notify.show('success', 'We upgraded your account! Please Log in!');
        }).catch(err => this.notify.show('error', err.responseJSON.errors[0].detail));
      }

    }
  });

  _exports.default = _default;
});