define("flaneur/pods/brand/boutiques/boutique/packaging/orders/order/route", ["exports", "flaneur/models/packaging/order"], function (_exports, _order) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  class PackagingOrdersOrderRoute extends Ember.Route {
    model({
      id
    }) {
      return _order.default.findById(id);
    }

  }

  _exports.default = PackagingOrdersOrderRoute;
});