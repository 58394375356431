define("flaneur/pods/brand/boutiques/boutique/orders/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "sBZtIvx6",
    "block": "{\"symbols\":[],\"statements\":[[6,[37,5],[[35,4]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"  \"],[8,\"empty-page\",[],[[\"@heading\",\"@title\",\"@tip\",\"@subtitle\",\"@image\",\"@items\"],[[30,[36,3],null,[[\"title\",\"subtitle\"],[\"Orders\",\"Confirm new orders from your customers, and alert our local couriers that your parcel is ready to go\"]]],\"Keep track of all your Trouva orders.\",\"This page will update when your first order comes through.\",\"Use this page to process all your orders coming through Trouva.com. Confirm order details and alert our couriers that your packages are ready to be collected.\",\"orders\",[32,0,[\"emptyPageItems\"]]]],null],[2,\"\\n\"]],\"parameters\":[]},{\"statements\":[[2,\"  \"],[8,\"orders-header\",[],[[\"@ordernumber\",\"@totalNewOrdersCount\",\"@clickAndCollectCount\",\"@returnCount\",\"@refreshAction\",\"@resetSearchAction\",\"@searchAction\",\"@addLoadingAction\"],[[32,0,[\"model\",\"ordernumber\"]],[32,0,[\"model\",\"totalNewOrdersCount\"]],[32,0,[\"model\",\"clickAndCollectCount\"]],[32,0,[\"model\",\"returnOrdersCount\"]],[30,[36,0],[\"refresh\"],null],[30,[36,0],[\"resetSearch\"],null],[30,[36,0],[\"search\"],null],[30,[36,0],[\"addLoading\"],null]]],null],[2,\"\\n\\n  \"],[8,\"orders-overview\",[],[[\"@activeOrdersCount\",\"@printOrdersCount\",\"@clickAndCollectCount\",\"@refreshAction\",\"@addLoadingAction\"],[[32,0,[\"model\",\"activeOrdersCount\"]],[32,0,[\"model\",\"printOrdersCount\"]],[32,0,[\"model\",\"clickAndCollectCount\"]],[30,[36,0],[\"refresh\"],null],[30,[36,0],[\"addLoading\"],null]]],null],[2,\"\\n\\n  \"],[1,[30,[36,2],[[30,[36,1],null,null]],null]],[2,\"\\n\"]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"route-action\",\"-outlet\",\"component\",\"hash\",\"hasNoOrders\",\"if\"]}",
    "moduleName": "flaneur/pods/brand/boutiques/boutique/orders/template.hbs"
  });

  _exports.default = _default;
});