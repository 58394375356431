define("flaneur/validators/phone-number", ["exports", "ember-cp-validations/validators/base"], function (_exports, _base) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  const PhoneNumber = _base.default.extend({
    validate(value, options) {
      if (options.allowEmpty && Ember.isEmpty(value)) {
        return true;
      }

      if (Ember.isEmpty(value)) {
        return false;
      }

      return emberAutoImportDynamic("libphonenumber-js").then(({
        isValidNumber
      }) => {
        const isValid = isValidNumber(value);
        return isValid || options.message || 'Please insert a valid phone number';
      });
    }

  });

  var _default = PhoneNumber;
  _exports.default = _default;
});