define("flaneur/pods/components/star-boutique-label/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "iTvHHBR1",
    "block": "{\"symbols\":[],\"statements\":[[6,[37,0],[[32,0,[\"isGold\"]]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"  Gold Star Curator\\n\"]],\"parameters\":[]},{\"statements\":[[6,[37,0],[[32,0,[\"isSilver\"]]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"  Silver Star Curator\\n\"]],\"parameters\":[]},{\"statements\":[[6,[37,0],[[32,0,[\"isBronze\"]]],null,[[\"default\"],[{\"statements\":[[2,\"  Bronze Star Curator\\n\"]],\"parameters\":[]}]]]],\"parameters\":[]}]]]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"if\"]}",
    "moduleName": "flaneur/pods/components/star-boutique-label/template.hbs"
  });

  _exports.default = _default;
});