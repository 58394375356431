define("flaneur/pods/components/pause-mode-modal/component", ["exports", "@glimmer/component", "ember-component-css/pod-names", "flaneur/util/validation", "flaneur/util/validation/validations"], function (_exports, _component, _podNames, _validation, _validations) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _dec8, _dec9, _dec10, _dec11, _dec12, _dec13, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _descriptor6, _descriptor7, _descriptor8, _descriptor9;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  let PauseModeModalComponent = (_dec = Ember.inject.service, _dec2 = Ember._tracked, _dec3 = Ember._tracked, _dec4 = Ember._tracked, _dec5 = Ember._tracked, _dec6 = Ember._tracked, _dec7 = Ember._tracked, _dec8 = Ember._tracked, _dec9 = Ember._tracked, _dec10 = Ember._action, _dec11 = Ember._action, _dec12 = Ember._action, _dec13 = Ember._action, (_class = class PauseModeModalComponent extends _component.default {
    constructor(...args) {
      super(...args);

      _initializerDefineProperty(this, "analytics", _descriptor, this);

      _initializerDefineProperty(this, "showPauseReasons", _descriptor2, this);

      _initializerDefineProperty(this, "isReasonRadioChecked", _descriptor3, this);

      _initializerDefineProperty(this, "reason", _descriptor4, this);

      _initializerDefineProperty(this, "step", _descriptor5, this);

      _initializerDefineProperty(this, "details", _descriptor6, this);

      _initializerDefineProperty(this, "contactName", _descriptor7, this);

      _initializerDefineProperty(this, "contactNumber", _descriptor8, this);

      _initializerDefineProperty(this, "validations", _descriptor9, this);
    }

    get styleNamespace() {
      return _podNames.default['pause-mode-modal'];
    }

    get noReasonSelected() {
      return this.reason === null;
    }

    closeModal() {
      return this.args.onCloseModal();
    }

    nextStep() {
      this.reason === 'other' ? this.step += 2 : this.step += 1;
    }

    save() {
      const info = {
        reason: this.reason,
        details: this.details,
        contactName: this.contactName,
        contactNumber: this.contactNumber
      };
      this.validations = (0, _validation.default)(info, {
        details: (0, _validations.validatePresence)({
          presence: true,
          message: 'Please provide additional information'
        }),
        contactName: (0, _validations.validatePresence)({
          presence: true,
          message: 'Please provide a contact name'
        }),
        contactNumber: (0, _validations.validatePresence)({
          presence: true,
          message: 'Please provide a contact number'
        })
      });

      if (this.validations !== true) {
        return;
      }

      this.args.saveMode(info);
    }

    onClickHolidayMode() {
      if (this.step === 1) {
        this.analytics.gtm({
          action: 'clicks_holiday_mode_switch_1'
        });
      }

      this.analytics.gtm({
        action: 'clicks_holiday_mode_switch_2'
      });
    }

  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "analytics", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "showPauseReasons", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return false;
    }
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "isReasonRadioChecked", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return false;
    }
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "reason", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return null;
    }
  }), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "step", [_dec5], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return 1;
    }
  }), _descriptor6 = _applyDecoratedDescriptor(_class.prototype, "details", [_dec6], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return null;
    }
  }), _descriptor7 = _applyDecoratedDescriptor(_class.prototype, "contactName", [_dec7], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return null;
    }
  }), _descriptor8 = _applyDecoratedDescriptor(_class.prototype, "contactNumber", [_dec8], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return null;
    }
  }), _descriptor9 = _applyDecoratedDescriptor(_class.prototype, "validations", [_dec9], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return {};
    }
  }), _applyDecoratedDescriptor(_class.prototype, "closeModal", [_dec10], Object.getOwnPropertyDescriptor(_class.prototype, "closeModal"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "nextStep", [_dec11], Object.getOwnPropertyDescriptor(_class.prototype, "nextStep"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "save", [_dec12], Object.getOwnPropertyDescriptor(_class.prototype, "save"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "onClickHolidayMode", [_dec13], Object.getOwnPropertyDescriptor(_class.prototype, "onClickHolidayMode"), _class.prototype)), _class));
  _exports.default = PauseModeModalComponent;
});