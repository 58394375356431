define("flaneur/enums/packaging-form-type", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const FORMS = {
    GBR: 200703341635344,
    FRA: 200864957317362,
    DEU: 201534691122346
  };
  FORMS.default = FORMS.GBR;
  var _default = FORMS;
  _exports.default = _default;
});