define("flaneur/pods/components/selected-for-made-label/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "WPjSJyKz",
    "block": "{\"symbols\":[\"&default\",\"&attrs\",\"@namedBlocksInfo\"],\"statements\":[[8,\"tag\",[[16,0,[31,[\"selected-for-made-label \",[32,0,[\"styleNamespace\"]]]]],[17,2]],[[\"@theme\"],[\"made\"]],[[\"default\"],[{\"statements\":[[2,\"\\n  \"],[1,[30,[36,0],[\"made-monogram\"],[[\"class\"],[\"selected-for-made-label__icon\"]]]],[2,\"\\n\"],[6,[37,2],[[30,[36,1],[[32,3],\"default\",[27,[32,1]]],null]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"    \"],[18,1,null],[2,\"\\n\"]],\"parameters\":[]},{\"statements\":[[2,\"    Selected for MADE\\n\"]],\"parameters\":[]}]]]],\"parameters\":[]}]]],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"svg-jar\",\"-has-block\",\"if\"]}",
    "moduleName": "flaneur/pods/components/selected-for-made-label/template.hbs"
  });

  _exports.default = _default;
});