define("flaneur/pods/components/form-flows/error-group/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function getError(errors, path) {
    if (!errors) return;
    const errorWithDottedKey = errors[path];
    const errorWithNestedKey = Ember.get(errors, path);
    return errorWithDottedKey || errorWithNestedKey;
  }

  var _default = Ember.Component.extend({
    classNames: ['error-group'],
    errors: null,
    // error object
    errorPaths: null,
    // array of string paths to listen for
    _errors: Ember.computed('errors.@each.type', 'errorPaths', function () {
      const errors = this.errors;
      const errorPaths = this.errorPaths;
      return errorPaths.reduce((prev, errorPath) => {
        const error = getError(errors, errorPath);

        if (error && Array.isArray(error)) {
          // Handle array of validations
          prev.push(error[0]);
        } else if (error) {
          // Handle single validation
          prev.push(error);
        }

        return prev;
      }, []);
    })
  });

  _exports.default = _default;
});