define("flaneur/pods/brand/boutiques/boutique/curate/brands/brand/checkout/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "EEGiYyNL",
    "block": "{\"symbols\":[\"__arg0\"],\"statements\":[[8,\"curate-brand-header\",[],[[\"@class\",\"@namedBlocksInfo\"],[\"breadcrumbs--right\",[30,[36,0],null,[[\"actions\"],[0]]]]],[[\"default\"],[{\"statements\":[[6,[37,2],[[30,[36,1],[[32,1],\"actions\"],null]],null,[[\"default\"],[{\"statements\":[[2,\"\\n    \"],[8,\"link-to\",[],[[\"@track\",\"@route\"],[[32,0,[\"trackBackToBasket\"]],\"brand.boutiques.boutique.curate.brands.brand.basket\"]],[[\"default\"],[{\"statements\":[[2,\"\\n      Back to the purchase order page\\n    \"]],\"parameters\":[]}]]],[2,\"\\n  \"]],\"parameters\":[]}]]]],\"parameters\":[1]}]]],[2,\"\\n\"],[1,[30,[36,4],[[30,[36,3],null,null]],null]],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"hash\",\"-is-named-block-invocation\",\"if\",\"-outlet\",\"component\"]}",
    "moduleName": "flaneur/pods/brand/boutiques/boutique/curate/brands/brand/checkout/template.hbs"
  });

  _exports.default = _default;
});