define("flaneur/pods/components/pages/frames/new/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "6lXm0nki",
    "block": "{\"symbols\":[\"@frame\"],\"statements\":[[10,\"div\"],[15,0,[31,[\"page \",[32,0,[\"styleNamespace\"]]]]],[12],[2,\"\\n  \"],[10,\"div\"],[14,0,\"heading\"],[12],[2,\"\\n    \"],[10,\"div\"],[14,0,\"heading__back\"],[12],[2,\"\\n      \"],[8,\"link-to\",[],[[\"@route\",\"@models\"],[\"brand.boutiques.boutique.frames\",[30,[36,0],[[32,0,[\"brand\"]],[32,0,[\"brand\",\"currentBoutique\",\"_id\"]]],null]]],[[\"default\"],[{\"statements\":[[2,\"\\n        \"],[8,\"fa-icon\",[[24,0,\"fa\"]],[[\"@icon\",\"@prefix\"],[\"long-arrow-left\",\"far\"]],null],[2,\"Back to Frames\\n      \"]],\"parameters\":[]}]]],[2,\"\\n    \"],[13],[2,\"\\n\\n    \"],[10,\"div\"],[14,0,\"heading__title\"],[12],[2,\"\\n      \"],[10,\"h1\"],[12],[2,\"New Frame\"],[13],[2,\"\\n      \"],[10,\"h2\"],[12],[2,\"Select content for each space below to showcase your products\"],[13],[2,\"\\n    \"],[13],[2,\"\\n\\n    \"],[10,\"div\"],[14,0,\"heading__actions\"],[12],[2,\"\\n      \"],[8,\"frames-tips\",[],[[],[]],null],[2,\"\\n    \"],[13],[2,\"\\n  \"],[13],[2,\"\\n\\n  \"],[8,\"frame-form\",[],[[\"@frame\"],[[32,1]]],null],[2,\"\\n\"],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"array\"]}",
    "moduleName": "flaneur/pods/components/pages/frames/new/template.hbs"
  });

  _exports.default = _default;
});