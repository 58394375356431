define("flaneur/pods/components/order-loading-message/completed/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "rpLfO3MC",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[18,1,null],[2,\"\\n\\n\"],[10,\"div\"],[14,0,\"empty-state-message\"],[12],[2,\"\\n    You haven't completed any orders yet. \"],[10,\"br\"],[12],[13],[2,\" Once an order is shipped, it will appear here.\\n    \"],[10,\"hr\"],[12],[13],[2,\"\\n\"],[13]],\"hasEval\":false,\"upvars\":[]}",
    "moduleName": "flaneur/pods/components/order-loading-message/completed/template.hbs"
  });

  _exports.default = _default;
});