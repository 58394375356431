define("flaneur/pods/application/route", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _descriptor6;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  let ApplicationRoute = (_dec = Ember.inject.service, _dec2 = Ember.inject.service, _dec3 = Ember.inject.service, _dec4 = Ember.inject.service, _dec5 = Ember.inject.service, _dec6 = Ember.inject.service, (_class = class ApplicationRoute extends Ember.Route {
    constructor() {
      super(...arguments);

      _initializerDefineProperty(this, "analytics", _descriptor, this);

      _initializerDefineProperty(this, "googleOptimize", _descriptor2, this);

      _initializerDefineProperty(this, "media", _descriptor3, this);

      _initializerDefineProperty(this, "pubsub", _descriptor4, this);

      _initializerDefineProperty(this, "router", _descriptor5, this);

      _initializerDefineProperty(this, "routerScroll", _descriptor6, this);

      _defineProperty(this, "queryParams", {
        'restock-product': {
          replace: true
        },
        'restock-origin': {
          replace: true
        },
        'restock-action': {
          replace: true
        }
      });

      this.router.on('routeDidChange', transition => {
        var _transition$from, _transition$to;

        this.analytics.trackPage({
          event: 'retailPanelPageView',
          oldUrl: (_transition$from = transition.from) === null || _transition$from === void 0 ? void 0 : _transition$from.name,
          newUrl: (_transition$to = transition.to) === null || _transition$to === void 0 ? void 0 : _transition$to.name
        });

        if (this.pubsub.hasCriticalUpdate) {
          // Force reloading the current page from the server
          window.location.reload(true);
        }
      });
      const {
        pubsub,
        googleOptimize
      } = this;
      pubsub.subscribe('flaneur', 'critical-update', () => {
        pubsub.set('hasCriticalUpdate', true);
      });
      googleOptimize.activate();
      this.setScrollElement();
      window.addEventListener('resize', this.setScrollElement.bind(this));
    }

    willDestroy() {
      window.removeEventListener('resize', this.setScrollElement.bind(this));
      super.willDestroy(...arguments);
    }

    setScrollElement() {
      try {
        const scrollElement = this.media.isMedium ? 'window' : '#outlet-container';
        Ember.set(this.routerScroll, 'scrollElement', scrollElement);
      } catch (ex) {
        // catch errors when the routerScroll element has been destroyed
        console.warn(ex);
      }
    }

  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "analytics", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "googleOptimize", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "media", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "pubsub", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "router", [_dec5], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor6 = _applyDecoratedDescriptor(_class.prototype, "routerScroll", [_dec6], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  })), _class));
  _exports.default = ApplicationRoute;
});