define("flaneur/pods/components/form-flows/shipment/address_validations", ["exports", "flaneur/util/address", "flaneur/util/validation/validations"], function (_exports, _address, _validations) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = addressValidations;

  function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); enumerableOnly && (symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols); } return keys; }

  function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = null != arguments[i] ? arguments[i] : {}; i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { _defineProperty(target, key, source[key]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } return target; }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function addressValidations(isoCode) {
    const fieldsToValidate = (0, _address.requiredFields)(isoCode);
    const fieldLabels = (0, _address.fieldLabelsForCountry)(isoCode);
    return Object.entries(fieldsToValidate).reduce((prev, [field, fieldSettings]) => {
      prev[field] = fieldSettings.validations.map(validation => {
        return (0, _validations.validateByType)(validation.type, _objectSpread(_objectSpread({}, validation.options), {}, {
          description: fieldLabels[field]
        }));
      });
      return prev;
    }, {});
  }
});