define("flaneur/mixins/bulk-update", ["exports", "flaneur/models/product"], function (_exports, _product) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Mixin.create({
    /**
     * Bulk update products
     * @param  {String} key    Value to bulk update
     * @param  {Object} params Parameters
     * @return {Object}        Promise
     */
    bulkUpdate(key, params = {}, product) {
      if (Ember.isNone(params[key])) {
        return Ember.RSVP.Promise.reject('No value to change');
      }

      if (product) {
        product.stock_lines.forEach(stockLine => {
          const currency = product.local_price.currency;
          Ember.set(stockLine, 'sales', params.discount > 0);
          Ember.set(stockLine, 'local_sales_price.currency', currency);
        });
        const discountRatio = 1 - params.discount / 100;
        const newPrice = params.discount > 0 ? parseInt(product.local_price.amount * discountRatio, 10) : 0;

        const newProduct = this._setProductSalesPrice(product, newPrice);

        return _product.default.bulkUpdate([newProduct]);
      }

      const products = this.model.products.filter(({
        checked
      }) => checked).map(product => {
        if (key !== 'discount') {
          return;
        }

        product.stock_lines.forEach(stockLine => {
          const currency = product.local_price.currency;
          Ember.set(stockLine, 'sales', params.discount > 0);
          Ember.set(stockLine, 'local_sales_price.currency', currency);
        });

        if (params.discount === 0) {
          return this._setProductSalesPrice(product, 0);
        }

        if (params.discount > 0) {
          const ratio = 1 - params.discount / 100;
          const newPrice = parseInt(product.local_price.amount * ratio, 10);
          return this._setProductSalesPrice(product, newPrice);
        }
      });

      if (Ember.isArray(products)) {
        return _product.default.bulkUpdate(products);
      }
    },

    _setProductSalesPrice(product, salesPrice) {
      product.stock_lines.forEach(stockLine => {
        Ember.set(stockLine, 'sales_price', salesPrice);
        Ember.set(stockLine, 'local_sales_price.amount', salesPrice);
      });
      return product;
    }

  });

  _exports.default = _default;
});