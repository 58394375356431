define("flaneur/pods/join/route", ["exports", "flaneur/models/user", "flaneur/models/prospective-boutique"], function (_exports, _user, _prospectiveBoutique) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    queryParams: {
      resetPasswordToken: {
        replace: true
      }
    },

    model({
      resetPasswordToken
    }) {
      return Ember.RSVP.hash({
        pb: _prospectiveBoutique.default.findByEmail(Ember.getOwner(this).ownerInjection(), this.session.user.email),
        user: _user.default._create({
          forgot_password_token: resetPasswordToken
        })
      });
    },

    async afterModel(model) {
      this._super(...arguments);

      const {
        pb
      } = await model;
      const hasBoutique = Ember.isPresent(this.brand.currentBoutique._id);

      if (!pb._id && !this.brand.currentBoutique._id) {
        return this.transitionTo('application');
      }

      if (hasBoutique || pb.status === 'accepted') {
        return this.transitionTo('brand.boutiques.boutique.terms-and-conditions', this.brand, this.brand.currentBoutique._id);
      }

      if (!this.session.user.has_initiated_forgot_password) {
        return this.transitionTo('your-contract');
      }

      this.transitionTo({
        queryParams: {
          resetPasswordToken: null
        }
      });
    }

  });

  _exports.default = _default;
});