define("flaneur/pods/components/frequently-asked-questions/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "xiC0mQGk",
    "block": "{\"symbols\":[\"section\",\"item\",\"index\",\"collapsible\",\"&attrs\"],\"statements\":[[11,\"div\"],[16,0,[31,[\"faqs \",[32,0,[\"styleNamespace\"]]]]],[17,5],[12],[2,\"\\n  \"],[10,\"h3\"],[14,0,\"faqs__title\"],[12],[2,\"FAQs\"],[13],[2,\"\\n\\n  \"],[10,\"div\"],[14,0,\"faqs__content\"],[12],[2,\"\\n\"],[6,[37,4],[[30,[36,3],[[30,[36,3],[[32,0,[\"sections\"]]],null]],null]],null,[[\"default\"],[{\"statements\":[[2,\"      \"],[10,\"div\"],[14,0,\"faqs__section\"],[12],[2,\"\\n\"],[6,[37,4],[[30,[36,3],[[30,[36,3],[[32,1,[\"items\"]]],null]],null]],null,[[\"default\"],[{\"statements\":[[2,\"          \"],[8,\"collapsible\",[],[[\"@isOpen\",\"@onClick\"],[[30,[36,0],[[32,2,[\"key\"]],[32,0,[\"openKey\"]]],null],[30,[36,1],[[32,0,[\"toggle\"]],[32,2,[\"key\"]]],null]]],[[\"default\"],[{\"statements\":[[2,\"\\n            \"],[8,[32,4,[\"label\"]],[],[[],[]],[[\"default\"],[{\"statements\":[[2,\"\\n              \"],[1,[32,2,[\"label\"]]],[2,\"\\n\\n              \"],[8,\"fa-icon\",[],[[\"@icon\",\"@prefix\",\"@rotation\"],[\"chevron-down\",\"far\",[30,[36,2],[[32,4,[\"isOpen\"]],180,0],null]]],null],[2,\"\\n            \"]],\"parameters\":[]}]]],[2,\"\\n\\n            \"],[8,[32,4,[\"content\"]],[],[[],[]],[[\"default\"],[{\"statements\":[[2,\"\\n              \"],[8,\"marked\",[],[[\"@markdown\"],[[32,2,[\"content\"]]]],null],[2,\"\\n            \"]],\"parameters\":[]}]]],[2,\"\\n          \"]],\"parameters\":[4]}]]],[2,\"\\n\"]],\"parameters\":[2,3]}]]],[2,\"      \"],[13],[2,\"\\n\"]],\"parameters\":[1]}]]],[2,\"  \"],[13],[2,\"\\n\"],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"eq\",\"fn\",\"if\",\"-track-array\",\"each\"]}",
    "moduleName": "flaneur/pods/components/frequently-asked-questions/template.hbs"
  });

  _exports.default = _default;
});