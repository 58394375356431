define("flaneur/pods/brand/boutiques/boutique/curate/brands/brand/products/product/route", ["exports", "flaneur/models/curate/product"], function (_exports, _product) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  class BrandBoutiquesBoutiqueCurateBrandsBrandProductsProductRoute extends Ember.Route {
    model({
      product_slug
    }) {
      return _product.default.find({
        slug: product_slug,
        populate: 'brand',
        limit: 1
      }).then(({
        curateProducts: [product]
      }) => product);
    }

    serialize(model) {
      return {
        product_slug: model.slug
      };
    }

  }

  _exports.default = BrandBoutiquesBoutiqueCurateBrandsBrandProductsProductRoute;
});