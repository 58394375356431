define("flaneur/pods/brand/boutiques/boutique/inventory/index/controller", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    queryParams: ['limit', 'page', 'query', 'sort', 'stock', {
      searchBy: 'search-by'
    }],
    limit: 20,
    page: 1,
    query: '',
    sort: '-created_at',
    stock: null,
    searchBy: 'name',
    emptyPageItems: [{
      icon: 'tasks-alt',
      title: 'Update stock levels',
      text: 'When customers have a great first experience buying from your boutique they are far more likely to return. Use the inventory tool to keep stock levels up to date when a product is purchased in your store, anything purchased on Trouva.com will automatically update these amounts.'
    }, {
      icon: 'gifts',
      title: 'List your full stock depth',
      text: 'Customers can see how many of each product you have available and will often purchase products in bulk if they know you have large quantities. Be sure to update your stock levels to reflect your stock depth to take advantage of this.'
    }],
    hasNoProducts: Ember.computed('model.products', 'query', 'stock', function () {
      return Ember.isEmpty(this.model.products) && Ember.isEmpty(this.query) && Ember.isEmpty(this.stock);
    })
  });

  _exports.default = _default;
});