define("flaneur/pods/components/reservation-product/active/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "EUG18iYD",
    "block": "{\"symbols\":[],\"statements\":[[6,[37,4],[[30,[36,3],[[35,1,[\"status\"]],\"fail\"],null]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"  \"],[10,\"p\"],[14,0,\"reservation-status-message\"],[12],[2,\"\\n    \"],[1,[30,[36,5],null,[[\"label\",\"isError\"],[[35,6],true]]]],[2,\"\\n  \"],[13],[2,\"\\n\"]],\"parameters\":[]},{\"statements\":[[6,[37,4],[[30,[36,3],[[35,1,[\"status\"]],\"success\"],null]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"  \"],[10,\"p\"],[14,0,\"reservation-status-message\"],[12],[2,\"\\n    \"],[1,[30,[36,5],null,[[\"label\",\"isSuccess\"],[\"In stock\",true]]]],[2,\"\\n  \"],[13],[2,\"\\n\"]],\"parameters\":[]},{\"statements\":[[6,[37,4],[[30,[36,3],[[35,1,[\"status\"]],\"active\"],null]],null,[[\"default\"],[{\"statements\":[[2,\"  \"],[8,\"button\",[],[[\"@disabled\",\"@class\",\"@theme\",\"@size\",\"@onClick\"],[[34,0],\"btn-out-of-stock\",\"secondary\",\"small\",[30,[36,2],[[32,0],\"markAsOutOfStock\",[35,1]],null]]],[[\"default\"],[{\"statements\":[[2,\"\\n    Out of stock\\n  \"]],\"parameters\":[]}]]],[2,\"\\n  \"],[8,\"button\",[],[[\"@disabled\",\"@theme\",\"@size\",\"@onClick\"],[[34,0],\"secondary\",\"small\",[30,[36,2],[[32,0],\"markAsHavingIssue\",[35,1]],null]]],[[\"default\"],[{\"statements\":[[2,\"\\n    Other issue\\n  \"]],\"parameters\":[]}]]],[2,\"\\n\"]],\"parameters\":[]}]]]],\"parameters\":[]}]]]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"isOnHoliday\",\"reservation\",\"action\",\"eq\",\"if\",\"reservation-status\",\"reservationStatusLabel\"]}",
    "moduleName": "flaneur/pods/components/reservation-product/active/template.hbs"
  });

  _exports.default = _default;
});