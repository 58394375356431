define("flaneur/utils/cloudinary-upload", ["exports", "flaneur/util/color", "flaneur/config/environment"], function (_exports, _color, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.convertImageFromCloudinaryToPromenade = convertImageFromCloudinaryToPromenade;
  _exports.getCloudinaryConfig = getCloudinaryConfig;
  _exports.default = void 0;
  const _excluded = ["tags"];

  function _objectWithoutProperties(source, excluded) { if (source == null) return {}; var target = _objectWithoutPropertiesLoose(source, excluded); var key, i; if (Object.getOwnPropertySymbols) { var sourceSymbolKeys = Object.getOwnPropertySymbols(source); for (i = 0; i < sourceSymbolKeys.length; i++) { key = sourceSymbolKeys[i]; if (excluded.indexOf(key) >= 0) continue; if (!Object.prototype.propertyIsEnumerable.call(source, key)) continue; target[key] = source[key]; } } return target; }

  function _objectWithoutPropertiesLoose(source, excluded) { if (source == null) return {}; var target = {}; var sourceKeys = Object.keys(source); var key, i; for (i = 0; i < sourceKeys.length; i++) { key = sourceKeys[i]; if (excluded.indexOf(key) >= 0) continue; target[key] = source[key]; } return target; }

  function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); enumerableOnly && (symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols); } return keys; }

  function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = null != arguments[i] ? arguments[i] : {}; i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { _defineProperty(target, key, source[key]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } return target; }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  /**
   * CloudinaryUpload handles uploading files to cloudinary using the unsigned cloudinary upload api
   */
  class CloudinaryUpload {
    /**
     * Setup configuration of cloudinary and check that configuration is correct
     * @param config
     */
    constructor(config) {
      this.noop = function () {};

      if (!config.uploadPreset) throw new Error('uploadPreset property missing from config');
      if (!config.cloudName) throw new Error('cloudName property missing from config');
      this.config = _objectSpread(_objectSpread({}, config), {}, {
        apiURL: `https://api.cloudinary.com/v1_1/${config.cloudName}/upload`
      });
    }
    /**
     * Uploads a single file to cloudinary using XMLHttpRequest and return promise.
     *
     * Responsibilities:
     * 1. Upload file via cloudinary unsigned upload api
     * 2. Inject additional form data to be sent to cloudinary
     * 3. Send progress updates to progressHandler
     *
     * @param file
     * @param formData
     * @param progressHandler
     * @returns {Promise}
     */


    upload(file, formData = {}, progressHandler = this.noop) {
      return new Ember.RSVP.Promise((resolve, reject) => {
        const config = this.config;
        const tags = [];
        const xhr = new XMLHttpRequest();
        const fd = new FormData();
        xhr.open('POST', config.apiURL, true);
        xhr.setRequestHeader('X-Requested-With', 'XMLHttpRequest');
        xhr.upload.addEventListener('progress', function (event) {
          const progress = Math.round(event.loaded / event.total * 100);
          progressHandler(event, file, progress);
        });

        xhr.onreadystatechange = function (e) {
          // If the request is completed successfully, parse the response
          // from Cloudinary and return back to the caller
          if (xhr.readyState === 4 && xhr.status === 200) {
            const response = JSON.parse(xhr.responseText);
            resolve(response); // If the request is finished and it's not a 200 response
            // reject the promise and return back to the caller
          } else if (xhr.readyState === 4) {
            reject(e);
          }
        }; // If tags have been passed in the formData, merge them with the default tags.
        // By default cloudinary allows comma separated string and array for 'tags'
        // so the following handles both scenarios and sends them to Cloudinary as an array


        if (formData.tags) {
          if (Array.isArray(formData.tags)) {
            formData.tags.forEach(tag => tags.push(tag));
          } else if (typeof formData.tags === 'string') {
            formData.tags.split(',').forEach(tag => tags.push(tag));
          }
        } // Append the file to be sent to Cloudinary


        fd.append('upload_preset', config.uploadPreset);
        fd.append('tags', tags);
        fd.append('file', file); // Append custom form data

        const {
          tags: excludeTags
        } = formData,
              remainingFormData = _objectWithoutProperties(formData, _excluded);

        Object.entries(remainingFormData).forEach(([key, value]) => fd.append(key, value)); // Send the file on its way

        xhr.send(fd);
      });
    }

  }
  /**
   * Get an image in promenade's format giving an image
   * in Cloudinary's format.
   *
   * @param {Object} image
   * @returns {Object} the converted object
   */


  _exports.default = CloudinaryUpload;

  function convertImageFromCloudinaryToPromenade(image) {
    return {
      url: image.secure_url,
      width: image.width,
      height: image.height,
      format: image.format,
      resource_type: image.resource_type,
      video: image.video ? image.video : undefined,
      cloudinary_top_colors: image.colors ? (0, _color.getColors)(image.colors) : undefined,
      cloudinary_top_colors_hsv: image.colors ? (0, _color.getColorsHSV)(image.colors) : undefined,
      cloudinary_google_colors: image.colors ? (0, _color.getColors)(image.predominant.google) : undefined
    };
  }

  function getCloudinaryConfig() {
    return {
      cloudName: _environment.default.CLOUDINARY_NAME,
      uploadPreset: _environment.default.CLOUDINARY
    };
  }
});