define("flaneur/pods/components/form-flows/shipment/history/component", ["exports", "flaneur/enums/shipment-status-type"], function (_exports, _shipmentStatusType) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    analytics: Ember.inject.service(),
    brand: Ember.inject.service(),
    features: Ember.inject.service(),
    notify: Ember.inject.service(),
    dateFormat: 'dd MMM yyyy',
    routePrefix: 'brand.boutiques.boutique.shipments',
    hasShipments: Ember.computed.gt('shipments.length', 0),

    getStatusIcon({
      status,
      use_automatic_pickup_booking: useAutomaticPickupBooking,
      estimated_collection_date: estimatedCollectionDate
    }) {
      if (status === _shipmentStatusType.default.BOOKED) {
        return 'check-circle';
      }

      if (status === _shipmentStatusType.default.FAILED || status === _shipmentStatusType.default.EXPIRED || status === _shipmentStatusType.default.PENDING) {
        return 'times-circle';
      }

      if (useAutomaticPickupBooking && estimatedCollectionDate) {
        return 'check-circle';
      }
    },

    getStatusLabel({
      status,
      use_automatic_pickup_booking: useAutomaticPickupBooking,
      estimated_collection_date: estimatedCollectionDate
    }) {
      if (status === _shipmentStatusType.default.FAILED || status === _shipmentStatusType.default.EXPIRED || status === _shipmentStatusType.default.PENDING) {
        return 'failed';
      }

      if (!useAutomaticPickupBooking) {
        return status;
      } // Automatic pickup booking shipments don't have 'booked' status after order creation,
      // but have estimated_collection_date if the pickup label was booked


      if (estimatedCollectionDate) {
        return _shipmentStatusType.default.BOOKED;
      }

      return 'failed';
    },

    onCopySuccess: Ember._action(function () {
      this.notify.show('success', 'Copied tracking URL to clipboard!');
    })
  });

  _exports.default = _default;
});