define("flaneur/pods/components/boutique-setup-progress/component", ["exports", "flaneur/config/environment"], function (_exports, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    onboarding: Ember.inject.service(),

    // Public API
    onComplete() {},

    // Private API
    classNames: ['boutique-setup-progress'],
    isCompleted: false,
    retailUrl: _environment.default.retailURL,

    init() {
      this._super(...arguments);

      this.set('isVisible', !this.boutique.published && !this.onboarding.areStepsCompleted);
    },

    actions: {
      complete() {
        const result = this.onComplete();
        const isPromise = result && typeof result.then === 'function';

        if (isPromise) {
          return result.then(() => Ember.trySet(this, 'isCompleted', true));
        }

        return Ember.trySet(this, 'isCompleted', true);
      },

      close() {
        Ember.trySet(this, 'isVisible', false);
      }

    }
  });

  _exports.default = _default;
});