define("flaneur/pods/components/form-flows/shipment/quote/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    analytics: Ember.inject.service(),
    googleMapsApi: Ember.inject.service(),
    tagName: '',
    countries: Ember.computed.alias('options.countries'),
    countryName: Ember.computed.readOnly('options.boutique.address_country.name'),
    hasSelectedDeliveryCountry: Ember.computed.notEmpty('shipment.delivery_address.country'),
    currency: Ember.computed.readOnly('options.boutique.currency'),
    extraShippingRatesDocumentUrl: Ember.computed.readOnly('options.boutique.addressCountryDocuments.shipping_rates.url'),
    extraShippingSurchargesDocumentUrl: Ember.computed.readOnly('options.boutique.addressCountryDocuments.shipping_surcharges.url'),
    countryNoMatchesMessage: Ember.computed('countryName', function () {
      return `Country not available to ship from ${this.countryName}.`;
    }),
    selectedDeliveryCountry: Ember.computed('countries', 'shipment.delivery_address.country', function () {
      return this.countries.find(country => country.iso_code === this.shipment.delivery_address.country);
    }),
    actions: {
      setCountry(country) {
        this.shipment.setDeliveryAddressCountry(country);
        this.analytics.gtm({
          action: 'quick_ship_select_country',
          label: country.iso_code
        });
      }

    }
  });

  _exports.default = _default;
});