define("flaneur/pods/components/boutique-restock-recommendation-stock-status/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "KWlXLr9P",
    "block": "{\"symbols\":[\"@hideIcons\",\"@recommendation\"],\"statements\":[[10,\"div\"],[15,0,[31,[\"boutique-restock-recommendation-stock-status \",[30,[36,1],[[32,2,[\"isOutOfStock\"]],\"boutique-restock-recommendation-stock-status--oos\"],null],\" \",[32,0,[\"styleNamespace\"]]]]],[12],[2,\"\\n\"],[6,[37,1],[[32,2,[\"isOutOfStock\"]]],null,[[\"default\",\"else\"],[{\"statements\":[[6,[37,1],[[30,[36,0],[[32,1]],null]],null,[[\"default\"],[{\"statements\":[[2,\"    \"],[8,\"fa-icon\",[],[[\"@icon\"],[\"times-circle\"]],null],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"    Out of stock\\n\"]],\"parameters\":[]},{\"statements\":[[6,[37,1],[[32,2,[\"hasUnlimitedStock\"]]],null,[[\"default\",\"else\"],[{\"statements\":[[6,[37,1],[[30,[36,0],[[32,1]],null]],null,[[\"default\"],[{\"statements\":[[2,\"    \"],[8,\"fa-icon\",[],[[\"@icon\"],[\"clock\"]],null],[2,\"\\n    \"]],\"parameters\":[]}]]],[2,\" Unlimited stock\\n\"]],\"parameters\":[]},{\"statements\":[[6,[37,1],[[30,[36,0],[[32,1]],null]],null,[[\"default\"],[{\"statements\":[[2,\"    \"],[8,\"fa-icon\",[],[[\"@icon\"],[\"clock\"]],null],[2,\"\\n    \"]],\"parameters\":[]}]]],[2,\" \"],[1,[32,2,[\"currentQuantity\"]]],[2,\" in stock\\n  \"]],\"parameters\":[]}]]]],\"parameters\":[]}]]],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"not\",\"if\"]}",
    "moduleName": "flaneur/pods/components/boutique-restock-recommendation-stock-status/template.hbs"
  });

  _exports.default = _default;
});