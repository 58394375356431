define("flaneur/utils/filter-control-chars", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = filterControlChars;
  const BACKSPACE = 8;
  const DEL = 127;

  function filterControlChars(str) {
    return str.split('').filter(c => c.charCodeAt() > BACKSPACE && c.charCodeAt() !== DEL).join('');
  }
});