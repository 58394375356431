define("flaneur/helpers/to-money", ["exports", "big.js"], function (_exports, _big) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.toMoney = toMoney;
  _exports.default = void 0;

  function toMoney([price]) {
    return Ember.isEmpty(price) ? '' : (0, _big.default)(price).div(100).toFixed(2);
  }

  var _default = Ember.Helper.helper(toMoney);

  _exports.default = _default;
});