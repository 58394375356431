define("flaneur/pods/components/cloudinary-image/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    src: 'https://trouva-media.imgix.net/rphc/logos/placeholder-with-logo.png',
    width: 300,
    height: 300,
    cloudinaryUrlSegment: 'image/upload',
    fit: 'fill',
    quality: 'auto',
    transformedSrc: Ember.computed('src', 'width', 'height', 'fit', 'quality', 'cloudinaryUrlSegment', function () {
      const src = this.src || '';
      const {
        cloudinaryUrlSegment,
        width,
        height,
        fit,
        quality
      } = this;

      if (src.includes('imgix.net')) {
        const params = new URLSearchParams({
          fit,
          w: width,
          h: height,
          q: quality,
          auto: 'format'
        }); // @TODO here we lose any existing query string

        return `${src.split('?')[0]}?${params.toString()}`;
      }

      const transformation = `c_${fit},f_auto,w_${width},h_${height},q_${quality}`;
      return src.replace(cloudinaryUrlSegment, `${cloudinaryUrlSegment}/${transformation}`);
    })
  });

  _exports.default = _default;
});