define("flaneur/pods/components/order-loading-message/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "f5ZtWy+o",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[6,[37,3],[[35,1]],null,[[\"default\"],[{\"statements\":[[2,\"  \"],[10,\"div\"],[14,0,\"order-empty-message\"],[12],[2,\"\\n    \"],[10,\"p\"],[12],[1,[30,[36,2],[[35,1]],[[\"boutique\"],[[35,0]]]]],[13],[2,\"\\n  \"],[13],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"\\n\"],[18,1,null],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"boutique\",\"orderMessage\",\"component\",\"if\"]}",
    "moduleName": "flaneur/pods/components/order-loading-message/template.hbs"
  });

  _exports.default = _default;
});