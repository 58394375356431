define("flaneur/pods/components/pages/curate/brands/brand/checkout/information-page/component", ["exports", "ember-component-css/pod-names", "ember-concurrency", "flaneur/pods/components/pages/curate/brands/brand/checkout/information-page/validations", "flaneur/mixins/keyword-highlight", "flaneur/enums/curate-order-submitted-type"], function (_exports, _podNames, _emberConcurrency, _validations, _keywordHighlight, _curateOrderSubmittedType) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _dec8, _dec9, _dec10, _dec11, _dec12, _dec13, _dec14, _dec15, _dec16, _dec17, _dec18, _dec19, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _descriptor6, _descriptor7, _descriptor8, _descriptor9, _descriptor10, _descriptor11, _descriptor12, _descriptor13, _descriptor14;

  function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); enumerableOnly && (symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols); } return keys; }

  function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = null != arguments[i] ? arguments[i] : {}; i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { _defineProperty(target, key, source[key]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } return target; }

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  const NEW_ADDRESS_TEXT = 'Enter a different address';
  const UPDATE_DEBOUNCE_TIME = 500;
  let PagesCurateBrandsBrandCheckoutInformationPageComponent = (_dec = Ember.inject.service('brand'), _dec2 = Ember.inject.service, _dec3 = Ember.inject.service, _dec4 = Ember.inject.service, _dec5 = Ember.inject.service, _dec6 = Ember.inject.service, _dec7 = Ember.inject.service, _dec8 = Ember._tracked, _dec9 = Ember._tracked, _dec10 = Ember._tracked, _dec11 = Ember._tracked, _dec12 = Ember._tracked, _dec13 = Ember._tracked, _dec14 = Ember._tracked, _dec15 = Ember._action, _dec16 = Ember._action, _dec17 = Ember._action, _dec18 = Ember._action, _dec19 = Ember._action, (_class = class PagesCurateBrandsBrandCheckoutInformationPageComponent extends Ember.Component.extend(_validations.Validations, _keywordHighlight.default) {
    constructor() {
      super(...arguments);

      _initializerDefineProperty(this, "currentBrand", _descriptor, this);

      _initializerDefineProperty(this, "analytics", _descriptor2, this);

      _initializerDefineProperty(this, "media", _descriptor3, this);

      _initializerDefineProperty(this, "notify", _descriptor4, this);

      _initializerDefineProperty(this, "router", _descriptor5, this);

      _initializerDefineProperty(this, "curateBasket", _descriptor6, this);

      _initializerDefineProperty(this, "features", _descriptor7, this);

      _initializerDefineProperty(this, "disableValidations", _descriptor8, this);

      _initializerDefineProperty(this, "invoiceAddressOptions", _descriptor9, this);

      _initializerDefineProperty(this, "selectedInvoiceOption", _descriptor10, this);

      _initializerDefineProperty(this, "isNewInvoiceAddress", _descriptor11, this);

      _initializerDefineProperty(this, "deliveryAddressOptions", _descriptor12, this);

      _initializerDefineProperty(this, "selectedDeliveryOption", _descriptor13, this);

      _initializerDefineProperty(this, "isNewDeliveryAddress", _descriptor14, this);

      _defineProperty(this, "keywordHighlightOpts", {
        keywordField: 'productQuery',
        selectors: ['.ember-power-select-option:not(.ember-power-select-option--no-matches-message)']
      });

      _defineProperty(this, "invoiceNewAddress", {});

      _defineProperty(this, "deliveryNewAddress", {});

      if (this.orderTotal.amount === 0) {
        this.router.transitionTo('brand.boutiques.boutique.curate.brands.brand');
      }

      this.order.disableValidations = true;
      this.deliveryAddressOptions = [this.order.delivery_address, {
        line_1: NEW_ADDRESS_TEXT
      }];
      this.isNewDeliveryAddress = false;
      this.selectedDeliveryOption = this.deliveryAddressOptions[0];
      this.invoiceAddressOptions = [this.hasAccountingAddress ? this.order.invoice_details.address : null, {
        line_1: NEW_ADDRESS_TEXT
      }].filter(Boolean);
      this.isNewInvoiceAddress = !this.hasAccountingAddress;
      this.selectedInvoiceOption = this.invoiceAddressOptions[0];
    }

    get styleNamespace() {
      return _podNames.default['pages/curate/brands/brand/checkout/information-page'];
    }

    get progressSteps() {
      return [{
        key: 'details',
        label: 'Delivery and Invoice details',
        complete: false
      }, {
        key: 'confirmation',
        label: 'Confirmation',
        complete: false
      }];
    }

    get boutique() {
      return this.currentBrand.currentBoutique;
    }

    get boutiqueId() {
      return this.boutique.id;
    }

    get order() {
      return this.curateBasket.order;
    }

    get orderTotal() {
      return this.curateBasket.totalPrice;
    }

    get hasAccountingAddress() {
      return Ember.isPresent(this.boutique.accounting_address_line_1);
    }

    get isSubmitOrderDisabled() {
      return !this.order.reservations.length || !this.order.agrees_with_brand_terms;
    }

    setInvoiceAndDeliveryAddresses() {
      if (this.isNewInvoiceAddress) {
        this.order.invoice_details = _objectSpread(_objectSpread({}, this.order.invoice_details), {}, {
          address: {
            line_1: `${this.invoiceNewAddress.line_1}, ${this.invoiceNewAddress.line_2 || ''}`,
            line_2: `${this.invoiceNewAddress.postcode}, ${this.invoiceNewAddress.city}`
          }
        });
      }

      if (this.isNewDeliveryAddress) {
        this.order.delivery_address = {
          line_1: `${this.deliveryNewAddress.line_1}, ${this.deliveryNewAddress.line_2 || ''}`,
          line_2: `${this.deliveryNewAddress.postcode}, ${this.deliveryNewAddress.city}`
        };
      }
    }

    setInvoiceAddressOption(option) {
      this.selectedInvoiceOption = option;

      if (option.line_1 === NEW_ADDRESS_TEXT) {
        this.isNewInvoiceAddress = true;
        return;
      }

      this.order.invoice_details = _objectSpread(_objectSpread({}, this.order.invoice_details), {}, {
        address: {
          line_1: this.boutique.accounting_address_line_1,
          line_2: this.boutique.accounting_address_line_2
        }
      });
      this.isNewInvoiceAddress = false;
    }

    setDeliveryAddressOption(option) {
      this.selectedDeliveryOption = option;

      if (option.line_1 === NEW_ADDRESS_TEXT) {
        this.isNewDeliveryAddress = true;
        return;
      }

      this.order.delivery_address = {
        line_1: `${this.boutique.address_line_1}, ${this.boutique.address_line_2 || ''}`,
        line_2: `${this.boutique.address_postcode}, ${this.boutique.address_city}`
      };
      this.isNewDeliveryAddress = false;
    }

    async submitOrder(ev) {
      ev.preventDefault();
      this.order.disableValidations = false;
      this.analytics.gtm({
        action: 'curate_clicked_confirm_and_order_now',
        label: this.brand.id
      });
      this.disableValidations = false;
      this.order.disableValidations = false;
      const {
        validations
      } = await this.validations.validate();
      const isOrderInvalid = !this.order.isValid;

      if (validations.isInvalid || isOrderInvalid) {
        return;
      }

      this.order.boutique = this.currentBrand.currentBoutique._id;
      this.order.brands = [this.brand.id];
      this.setInvoiceAndDeliveryAddresses();
      const {
        has_brand_account: hasAccountWithBrand
      } = this.curateBasket.order;
      return this.order.create().then(({
        _id: id
      }) => {
        const queryParams = {
          'order-submitted-id': id,
          'order-submitted-type': hasAccountWithBrand ? _curateOrderSubmittedType.default.EXISTING_ACCOUNT : _curateOrderSubmittedType.default.NEW_ACCOUNT,
          'order-submitted-brand': this.brand.name
        };
        this.curateBasket.clearOrder(this.brand);
        this.router.transitionTo('brand.boutiques.boutique.curate.orders.submitted', {
          queryParams
        });
      }).catch(() => {
        this.notify.show('error', 'Oops! An error ocurred while submitting your purchase order. Please try again.');
      });
    }

    onAgreesWithBrandTerms(value) {
      if (value) {
        this.analytics.gtm({
          action: 'curate_agreed_to_brand_tcs',
          label: this.brandId
        });
      }

      this.order.agrees_with_brand_terms = value;
    }

    onHasBrandAccount(value) {
      if (value) {
        this.analytics.gtm({
          action: 'curate_checked_existing_account_with_brand',
          label: this.brandId
        });
      }

      this.order.has_brand_account = value;
    }

    *trackUpdate(action) {
      yield (0, _emberConcurrency.timeout)(UPDATE_DEBOUNCE_TIME);
      return yield this.analytics.gtm({
        action,
        label: this.boutiqueId
      });
    }

  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "currentBrand", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "analytics", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "media", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "notify", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "router", [_dec5], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor6 = _applyDecoratedDescriptor(_class.prototype, "curateBasket", [_dec6], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor7 = _applyDecoratedDescriptor(_class.prototype, "features", [_dec7], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor8 = _applyDecoratedDescriptor(_class.prototype, "disableValidations", [_dec8], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return true;
    }
  }), _descriptor9 = _applyDecoratedDescriptor(_class.prototype, "invoiceAddressOptions", [_dec9], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor10 = _applyDecoratedDescriptor(_class.prototype, "selectedInvoiceOption", [_dec10], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor11 = _applyDecoratedDescriptor(_class.prototype, "isNewInvoiceAddress", [_dec11], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor12 = _applyDecoratedDescriptor(_class.prototype, "deliveryAddressOptions", [_dec12], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor13 = _applyDecoratedDescriptor(_class.prototype, "selectedDeliveryOption", [_dec13], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor14 = _applyDecoratedDescriptor(_class.prototype, "isNewDeliveryAddress", [_dec14], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "setInvoiceAddressOption", [_dec15], Object.getOwnPropertyDescriptor(_class.prototype, "setInvoiceAddressOption"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "setDeliveryAddressOption", [_dec16], Object.getOwnPropertyDescriptor(_class.prototype, "setDeliveryAddressOption"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "submitOrder", [_dec17], Object.getOwnPropertyDescriptor(_class.prototype, "submitOrder"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "onAgreesWithBrandTerms", [_dec18], Object.getOwnPropertyDescriptor(_class.prototype, "onAgreesWithBrandTerms"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "onHasBrandAccount", [_dec19], Object.getOwnPropertyDescriptor(_class.prototype, "onHasBrandAccount"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "trackUpdate", [_emberConcurrency.restartableTask], Object.getOwnPropertyDescriptor(_class.prototype, "trackUpdate"), _class.prototype)), _class));
  _exports.default = PagesCurateBrandsBrandCheckoutInformationPageComponent;
});