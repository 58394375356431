define("flaneur/enums/boutique-restock-information-reason", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    SEASONAL: 'seasonal',
    DISCONTINUED: 'discontinued',
    SUPPLIER_ISSUES: 'supplier-issues',
    INSECURITY: 'insecurity',
    OTHER: 'other'
  };
  _exports.default = _default;
});