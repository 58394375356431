define("flaneur/enums/currency-symbol", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const currencySymbol = {
    GBP: '£',
    EUR: '€',
    DKK: 'kr. ',
    SEK: 'kr',
    USD: '$'
  };
  var _default = currencySymbol;
  _exports.default = _default;
});