define("flaneur/services/firebase-service", ["exports", "firebase/compat/app", "firebase/auth", "flaneur/config/environment", "firebaseui"], function (_exports, _app, _auth, _environment, _firebaseui) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _class, _descriptor, _descriptor2, _descriptor3;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  let FirebaseService = (_dec = Ember._tracked, _dec2 = Ember._tracked, _dec3 = Ember._tracked, (_class = class FirebaseService extends Ember.Service {
    constructor() {
      super(...arguments);

      _initializerDefineProperty(this, "app", _descriptor, this);

      _initializerDefineProperty(this, "authInstance", _descriptor2, this);

      _initializerDefineProperty(this, "ui", _descriptor3, this);

      if (_app.default.apps.length !== 0) {
        return;
      }

      this.app = _app.default.initializeApp(_environment.default.firebase);
      this.authInstance = (0, _auth.getAuth)(this.app);
      this.ui = new _firebaseui.default.auth.AuthUI(this.authInstance);
    }

    startUi({
      selector,
      onSignInSuccess,
      onSignInFailure,
      onUiShown
    }) {
      if (!this.ui.isPendingRedirect()) {
        this.ui.reset();
      }

      this.ui.start(selector, {
        signInOptions: [{
          provider: _app.default.auth.EmailAuthProvider.PROVIDER_ID,
          signInMethod: _app.default.auth.EmailAuthProvider.EMAIL_LINK_SIGN_IN_METHOD
        }, _app.default.auth.GoogleAuthProvider.PROVIDER_ID],
        callbacks: {
          signInSuccessWithAuthResult: result => {
            if (!result.user) {
              return false;
            }

            if (!result.user.email) {
              result.user.delete().catch(() => console.error('Unable to delete user from Firebase'));
              return false;
            }

            onSignInSuccess(result);
            return false;
          },
          signInFailure: onSignInFailure,
          uiShown: onUiShown
        }
      });
    }

    getAuth() {
      return this.authInstance;
    }

    signInWithCustomToken(customToken) {
      return (0, _auth.signInWithCustomToken)(this.authInstance, customToken);
    }

    signOut() {
      return (0, _auth.signOut)(this.authInstance);
    }

  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "app", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "authInstance", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "ui", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  })), _class));
  _exports.default = FirebaseService;
});