define("flaneur/pods/components/form-flows/product/gallery-details/gallery-item/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    tagName: '',
    item: null,
    index: null,

    imageEdited() {},

    deleteImage() {},

    imageItemStyle: Ember.computed('item.url', function () {
      return Ember.String.htmlSafe(`background-image: url(${this.item.url});`);
    }),
    actions: {
      editImageAction(img) {
        const index = this.index;
        this.imageEdited(img, index);
      },

      deleteImage(img) {
        this.deleteImage(img);
      }

    }
  });

  _exports.default = _default;
});