define("flaneur/utils/get-imgix-url", ["exports", "flaneur/config/environment"], function (_exports, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = getImgixUrl;

  function getImgixUrl(destFilename, {
    isPathAlreadyIncluded = false
  } = {}) {
    const {
      imgixPrefix,
      path
    } = (_environment.default === null || _environment.default === void 0 ? void 0 : _environment.default.filestack) || {};

    if (!destFilename || typeof destFilename !== 'string') {
      throw new Error('getImgixUrl: Invalid destination filename');
    }

    if (!imgixPrefix || typeof imgixPrefix !== 'string') {
      throw new Error('getImgixUrl: Missing imgix prefix');
    }

    if (!path || typeof path !== 'string') {
      throw new Error('getImgixUrl: Missing path');
    } // paths coming back from the storeUrl route already have the path included


    const combined = isPathAlreadyIncluded ? `${imgixPrefix}/${destFilename}` : `${imgixPrefix}${path}/${destFilename}`;
    const imgixUrl = combined.split('/').filter(v => v).join('/'); // fix the now removed :// part

    const [protocol, ...rest] = imgixUrl.split('/');
    return `${protocol}//${rest.join('/')}`;
  }
});