define("flaneur/pods/components/pages/inventory-index/component", ["exports", "flaneur/mixins/keyword-highlight", "flaneur/enums/boutique-feature", "flaneur/utils/params-lists"], function (_exports, _keywordHighlight, _boutiqueFeature, _paramsLists) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); enumerableOnly && (symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols); } return keys; }

  function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = null != arguments[i] ? arguments[i] : {}; i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { _defineProperty(target, key, source[key]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } return target; }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  var _default = Ember.Component.extend(_keywordHighlight.default, {
    analytics: Ember.inject.service(),
    brand: Ember.inject.service(),
    media: Ember.inject.service(),
    notify: Ember.inject.service(),
    router: Ember.inject.service(),
    limitList: _paramsLists.limitList,
    sortFilterList: _paramsLists.sortFilterList,
    stockFilterList: _paramsLists.stockFilterList,
    searchByList: _paramsLists.searchByList,
    madeStatusFilterList: _paramsLists.madeStatusFilterList,
    // Public API
    model: null,
    // Private API
    classNames: ['page'],
    classNameBindings: ['hasNoResults:page--no-results'],
    columns: ['Image', 'Product name', 'Brand', 'Variant', 'SKU', 'Price', 'Status', 'Stock'],
    filters: ['sort', 'stock'],
    defaultParameters: {
      query: '',
      stock: null
    },
    keywordHighlightOpts: {
      keywordField: 'searchQuery',
      selectors: ['.inventory__content__product__name', '.inventory__content__product__manufacturer', '.inventory__content__product__sku']
    },
    searchQuery: Ember.computed.alias('model.meta.query'),
    hasSearchQuery: Ember.computed.gte('searchQuery.length', 1),
    hasNoResults: Ember.computed.lte('model.products.length', 0),
    hasStockFilter: Ember.computed.notEmpty('selectedStockFilter.params.stock'),
    clearLabel: Ember.computed('hasSearchQuery', 'hasStockFilter', function () {
      if (this.hasSearchQuery && this.hasStockFilter) {
        return 'Clear all';
      }

      if (this.hasSearchQuery) {
        return 'Clear search';
      }

      if (this.hasStockFilter) {
        return 'Clear filter';
      }

      return '';
    }),
    enableUnlimitedStock: Ember.computed('brand.currentBoutique.features', function () {
      var _this$brand$currentBo;

      return (_this$brand$currentBo = this.brand.currentBoutique.features) === null || _this$brand$currentBo === void 0 ? void 0 : _this$brand$currentBo.includes(_boutiqueFeature.default.UNLIMITED_STOCK);
    }),

    didInsertElement() {
      this._super(...arguments);

      this.filters.forEach(filter => {
        this.initializeParameter(filter, this.model.meta[filter]);
      });
      this.initializeParameter('limit', this.model.meta.limit);
      this.initializeParameter('search-by', this.model.meta.searchBy);
    },

    initializeParameter(name, value) {
      const normalizedFilterName = Ember.String.capitalize(Ember.String.camelize(name));
      const affix = this.filters.includes(name) ? 'Filter' : '';
      const selectedFilter = this[`${Ember.String.camelize(name)}${affix}List`].find(({
        params
      }) => Ember.isEqual(params[name], value));
      this.set(`selected${normalizedFilterName}${affix}`, selectedFilter);
    },

    _changeParameter({
      name,
      value
    }) {
      const normalizedName = Ember.String.capitalize(Ember.String.camelize(name));
      const affix = this.filters.includes(name) ? 'Filter' : '';
      this.set(`selected${normalizedName}${affix}`, value);
      return this._search(value.params);
    },

    _search(parameters) {
      return this.router.transitionTo({
        queryParams: _objectSpread(_objectSpread({}, parameters), {}, {
          page: 1
        })
      });
    },

    /*
     * Tracking-related functions
     */
    _trackClickEditProduct(id) {
      this._track('clicked_edit_product', id);
    },

    _trackUpdateStock(id) {
      this._track('updated_stock', id);
    },

    _trackChangeSearchBy(searchBy) {
      this._track('applied_search_by_products_in_inventory_by', searchBy);
    },

    _trackChangeSortFilter(filter) {
      this._track('applied_sort_products_in_inventory_by', filter.params.sort);
    },

    _trackChangeFilter(filter) {
      this._track('applied_filter_products_in_inventory_by', filter);
    },

    _trackSearch(query) {
      this._track('applied_search_in_inventory', query);
    },

    _trackUpdateSku(id) {
      this._track('edited_sku', id);
    },

    _track(action, label) {
      this.analytics.all({
        action,
        label
      });
    },

    actions: {
      onChangeSearchBy(searchBy) {
        return this._changeParameter({
          name: 'search-by',
          value: searchBy
        }).then(() => this._trackChangeSearchBy(searchBy));
      },

      onChangeQuery(query) {
        this._trackSearch(query);

        return this._search({
          query
        });
      },

      onChangeQuantity(product) {
        return product.save().then(() => this._trackUpdateStock(product._id));
      },

      onChangeSortFilter(filter) {
        return this._changeParameter({
          name: 'sort',
          value: filter
        }).then(() => this._trackChangeSortFilter(filter));
      },

      onChangeStockFilter(filter) {
        this._trackChangeFilter('stock');

        return this._changeParameter({
          name: 'stock',
          value: filter
        });
      },

      onChangeLimit(value) {
        return this._changeParameter({
          name: 'limit',
          value
        });
      },

      onChangeSku(stockLine, product, sku, initialSku) {
        Ember.set(stockLine, 'sku', sku);
        return product.save().then(() => this._trackUpdateSku(stockLine._id)).catch(() => {
          Ember.set(stockLine, 'sku', initialSku);
          this.notify.show('error', 'There was an error updating the SKU. Please try again.', 5000);
        });
      },

      resetFilters() {
        this._search(this.defaultParameters);

        this.initializeParameter('stock', this.defaultParameters.stock);
      },

      onToggleUnlimited(product, stockLine, unlimited) {
        Ember.set(stockLine, 'quantity', unlimited ? -1 : 1);
        return product.save();
      },

      trackClickEditProduct({
        _id: id
      }) {
        return this._trackClickEditProduct(id);
      }

    }
  });

  _exports.default = _default;
});