define("flaneur/pods/components/confirm-order-items/readable-dates", ["exports", "date-fns"], function (_exports, _dateFns) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.formatDates = void 0;

  const formatDates = date => {
    const now = new Date();

    if ((0, _dateFns.isSameDay)(now, date)) {
      return 'today';
    }

    if ((0, _dateFns.isSameDay)((0, _dateFns.addDays)(now, 1), date)) {
      return 'tomorrow';
    }

    return `on ${(0, _dateFns.format)(date, 'PP')}`;
  };

  _exports.formatDates = formatDates;
});