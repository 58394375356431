define("flaneur/pods/components/form-opening-hours/component", ["exports", "date-fns", "lodash.clonedeep"], function (_exports, _dateFns, _lodash) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); enumerableOnly && (symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols); } return keys; }

  function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = null != arguments[i] ? arguments[i] : {}; i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { _defineProperty(target, key, source[key]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } return target; }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  var _default = Ember.Component.extend({
    media: Ember.inject.service(),
    classNames: ['form-opening-hours'],
    weekDays: 7,
    showAppointmentOnly: true,
    mobileEdit: false,
    // eslint-disable-next-line ember/require-computed-property-dependencies
    openingH: Ember.computed(function () {
      const ohs = this.openingHours ? (0, _lodash.default)(this.openingHours) : Ember.A([]);
      const numberOfDays = this.weekDays;

      if (ohs.length < numberOfDays) {
        return Array.from({
          length: numberOfDays
        }, (_, n) => n).map(i => {
          return {
            label: this.formatLabel(i),
            day: i,
            active: false,
            selectedStart: '10:00',
            selectedEnd: '18:00',
            appointment: false
          };
        });
      }

      return ohs.map((oh, i) => {
        return _objectSpread(_objectSpread({}, oh), {}, {
          label: this.formatLabel(i)
        });
      });
    }),
    hours: Ember.computed(function () {
      const start = 8;
      const end = 22;

      const isInt = val => val % 1 === 0;

      const hours = [];

      for (let hour = start; hour <= end; hour += 0.5) {
        const minutes = isInt(hour) ? 0 : 30; // if x.5 -> 30 min

        const date = (0, _dateFns.setHours)((0, _dateFns.setMinutes)(new Date(), minutes), hour);
        hours.push((0, _dateFns.format)(date, 'HH:mm'));
      }

      return hours;
    }),

    /* eslint-disable-next-line ember/no-observers */
    openingHDidChange: Ember.observer('openingH.@each.active', 'openingH.@each.appointment', 'openingH.@each.selectedStart', 'openingH.@each.selectedEnd', function () {
      this.action(this.openingH);
    }),

    didInsertElement() {
      this._super(...arguments);

      Ember.run.next(() => {
        this.set('loading', false);
      });
    },

    formatLabel(day) {
      const labels = ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday'];
      return labels[day];
    },

    actions: {
      mobileEdit() {
        this.set('mobileEdit', true);
      },

      dayUpdated(day, status) {
        day.set('active', status);
      },

      appointmentUpdated(day, status) {
        day.set('active', status);
      }

    }
  });

  _exports.default = _default;
});