define("flaneur/models/prospective-boutique/validations", ["exports", "ember-cp-validations"], function (_exports, _emberCpValidations) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.Validations = void 0;
  const Validations = (0, _emberCpValidations.buildValidations)({
    name: (0, _emberCpValidations.validator)('presence', {
      presence: true,
      description: "Boutique's name"
    }),
    email: (0, _emberCpValidations.validator)('format', {
      type: 'email',
      description: 'Email'
    }),
    postcode: (0, _emberCpValidations.validator)('presence', {
      presence: true,
      description: 'Postcode'
    }),
    contract_owner_name: (0, _emberCpValidations.validator)('presence', {
      presence: true,
      description: 'Name'
    }),
    primary_phone: (0, _emberCpValidations.validator)('phone-number'),
    registered_name: (0, _emberCpValidations.validator)('presence', {
      presence: true,
      description: 'Registered full name'
    }),
    registered_number: (0, _emberCpValidations.validator)('presence', {
      presence: true,
      description: 'Company registration number'
    }),
    registered_address_line_1: (0, _emberCpValidations.validator)('presence', {
      presence: true,
      description: 'Address line 1'
    }),
    registered_address_country: (0, _emberCpValidations.validator)('presence', {
      presence: true,
      description: 'Country'
    }),
    vat_number: (0, _emberCpValidations.validator)('vat-number', {
      allowEmpty: Ember.computed.not('model.is_vat_registered')
    }),
    is_vat_registered: (0, _emberCpValidations.validator)('inclusion', {
      in: [true, false]
    })
  }, {
    disabled: Ember.computed.readOnly('model.disableValidations')
  });
  _exports.Validations = Validations;
});