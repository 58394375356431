define("flaneur/pods/components/model-validation-message/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "d1hcyoKP",
    "block": "{\"symbols\":[\"error\",\"&default\",\"@namedBlocksInfo\",\"@property\",\"@model\"],\"statements\":[[10,\"span\"],[14,0,\"validation-message\"],[12],[2,\"\\n\"],[6,[37,3],[[30,[36,2],[[32,5,[\"validations\"]],[32,4]],null]],null,[[\"default\"],[{\"statements\":[[6,[37,1],[[32,1]],null,[[\"default\"],[{\"statements\":[[6,[37,1],[[30,[36,0],[[32,3],\"default\",[27,[32,2]]],null]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"        \"],[18,2,[[32,1]]],[2,\"\\n\"]],\"parameters\":[]},{\"statements\":[[2,\"        \"],[1,[32,1,[\"message\"]]],[2,\"\\n\"]],\"parameters\":[]}]]]],\"parameters\":[]}]]]],\"parameters\":[1]}]]],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"-has-block\",\"if\",\"get-string\",\"let\"]}",
    "moduleName": "flaneur/pods/components/model-validation-message/template.hbs"
  });

  _exports.default = _default;
});