define("flaneur/pods/components/curate-product-card/modal/component", ["exports", "siema", "@glimmer/component", "ember-component-css/pod-names", "flaneur/enums/key-code"], function (_exports, _siema, _component, _podNames, _keyCode) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _dec8, _dec9, _dec10, _dec11, _dec12, _dec13, _dec14, _dec15, _dec16, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _descriptor6;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  const QUANTITY_OPTIONS_COUNT = 10;
  const PADDING_REMOVAL = 20;
  let CurateProductCardModalComponent = (_dec = Ember.inject.service, _dec2 = Ember.inject.service, _dec3 = Ember._tracked, _dec4 = Ember._tracked, _dec5 = Ember._tracked, _dec6 = Ember._tracked, _dec7 = Ember._action, _dec8 = Ember._action, _dec9 = Ember._action, _dec10 = Ember._action, _dec11 = Ember._action, _dec12 = Ember._action, _dec13 = Ember._action, _dec14 = Ember._action, _dec15 = Ember._action, _dec16 = Ember._action, (_class = class CurateProductCardModalComponent extends _component.default {
    constructor() {
      super(...arguments);

      _initializerDefineProperty(this, "media", _descriptor, this);

      _initializerDefineProperty(this, "analytics", _descriptor2, this);

      _initializerDefineProperty(this, "topBackgroundHeight", _descriptor3, this);

      _initializerDefineProperty(this, "selectedImageIndex", _descriptor4, this);

      _initializerDefineProperty(this, "selectedStockLine", _descriptor5, this);

      _initializerDefineProperty(this, "selectedQuantity", _descriptor6, this);

      this.selectedStockLine = this.args.product.stockLines[0];
      this.selectedQuantity = this.selectedStockLine.minimumOrderQuantity;
    }

    get styleNamespace() {
      return _podNames.default['curate-product-card/modal'];
    }

    get product() {
      return this.args.product;
    }

    get selectedImage() {
      return this.args.product.images[this.selectedImageIndex];
    }

    get stockLines() {
      return this.args.product.stockLines;
    }

    get hasMoreThanOneImage() {
      return this.args.product.images.length > 1;
    }

    get quantityList() {
      const {
        minimumOrderQuantity
      } = this.selectedStockLine;
      return Array.from(new Array(QUANTITY_OPTIONS_COUNT).keys()).map(value => value + minimumOrderQuantity);
    }

    get wholesalePricePerUnit() {
      return {
        currency: this.selectedStockLine.wholesalePrice.currency,
        amount: Math.ceil(this.selectedStockLine.wholesalePrice.amount / this.selectedStockLine.packSize)
      };
    }

    get totalUnits() {
      return this.selectedStockLine.packSize * this.selectedQuantity;
    }

    get totalPrice() {
      return {
        currency: this.selectedStockLine.wholesalePrice.currency,
        amount: this.selectedStockLine.wholesalePrice.amount * this.selectedQuantity
      };
    }

    onKeyDown(e) {
      if (e.keyCode === _keyCode.default.ESC) {
        this.onClose();
        return;
      }

      if (e.keyCode === _keyCode.default.ARROW_LEFT) {
        this.previousImage();
        return;
      }

      if (e.keyCode === _keyCode.default.ARROW_RIGHT) {
        this.nextImage();
      }
    }

    willDestroy() {
      super.willDestroy(...arguments);
      document.removeEventListener('keydown', this.handleKeyDown, false);

      if (this.siema) {
        this.siema.destroy();
      }
    }

    onChangeImage() {
      const selectedImage = this.args.product.images[this.selectedImageIndex];

      if (selectedImage.sku === this.selectedStockLine.sku) {
        return;
      }

      this.selectedStockLine = this.stockLines.find(({
        sku
      }) => sku === selectedImage.sku);
    }

    trackChangeImageFromArrow() {
      this.analytics.gtm({
        action: 'viewed_more_images_from_lightbox_arrows',
        label: this.selectedStockLine.name
      });
    }

    trackChangeImageFromThumbnails() {
      this.analytics.gtm({
        action: 'viewed_more_images_from_lightbox_thumbnails',
        label: this.selectedStockLine.name
      });
    }

    addToBasket() {
      this.analytics.gtm({
        action: 'curate_clicked_add_to_purchase_order',
        label: this.selectedStockLine.sku
      });
      this.args.onAddToBasket(this.product, this.selectedStockLine.sku, this.selectedQuantity);
    }

    onDidInsertInfo(element) {
      var _ref;

      this.topBackgroundHeight = (_ref = (element === null || element === void 0 ? void 0 : element.offsetTop) - PADDING_REMOVAL) !== null && _ref !== void 0 ? _ref : 0;
    }

    async onDidInsertModal() {
      if (this.product.images.length < 2) {
        return;
      }

      const siema = new _siema.default({
        selector: '.curate-product-modal .curate-product-modal__carousel',
        loop: true,
        onChange: () => {
          this.selectedImageIndex = this.siema.currentSlide;
          this.onChangeImage();
        }
      });
      this.siema = siema;
      Ember.set(this, 'handleKeyDown', Ember.run.bind(this, this.onKeyDown), false);
      document.addEventListener('keydown', this.handleKeyDown);
    }

    goToPhoto(index) {
      this.siema.goTo(index);
    }

    nextImage() {
      this.trackChangeImageFromArrow();
      this.siema.next();
    }

    previousImage() {
      this.trackChangeImageFromArrow();
      this.siema.prev();
      this.onChangeImage();
    }

    selectQuantity(quantity) {
      this.analytics.gtm({
        action: 'curate_product_changed_product_quantity',
        label: `${quantity},${this.selectedStockLine.sku}`
      });
      this.selectedQuantity = quantity;
    }

    selectStockLine(stockLine) {
      this.analytics.gtm({
        action: 'curate_product_changed_stockline_option',
        label: this.selectedStockLine.name
      });
      this.selectedStockLine = stockLine;
      this.selectedQuantity = this.selectedStockLine.minimumOrderQuantity;

      if (this.selectedImage.sku === this.selectedStockLine.sku) {
        return;
      }

      const stockLinePhotoIndex = this.product.images.findIndex(image => image.sku === this.selectedStockLine.sku);

      if (stockLinePhotoIndex > -1) {
        this.goToPhoto(stockLinePhotoIndex);
      }
    }

    onClose() {
      this.args.onClose();
    }

  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "media", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "analytics", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "topBackgroundHeight", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "selectedImageIndex", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return 0;
    }
  }), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "selectedStockLine", [_dec5], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor6 = _applyDecoratedDescriptor(_class.prototype, "selectedQuantity", [_dec6], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "trackChangeImageFromThumbnails", [_dec7], Object.getOwnPropertyDescriptor(_class.prototype, "trackChangeImageFromThumbnails"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "addToBasket", [_dec8], Object.getOwnPropertyDescriptor(_class.prototype, "addToBasket"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "onDidInsertInfo", [_dec9], Object.getOwnPropertyDescriptor(_class.prototype, "onDidInsertInfo"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "onDidInsertModal", [_dec10], Object.getOwnPropertyDescriptor(_class.prototype, "onDidInsertModal"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "goToPhoto", [_dec11], Object.getOwnPropertyDescriptor(_class.prototype, "goToPhoto"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "nextImage", [_dec12], Object.getOwnPropertyDescriptor(_class.prototype, "nextImage"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "previousImage", [_dec13], Object.getOwnPropertyDescriptor(_class.prototype, "previousImage"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "selectQuantity", [_dec14], Object.getOwnPropertyDescriptor(_class.prototype, "selectQuantity"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "selectStockLine", [_dec15], Object.getOwnPropertyDescriptor(_class.prototype, "selectStockLine"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "onClose", [_dec16], Object.getOwnPropertyDescriptor(_class.prototype, "onClose"), _class.prototype)), _class));
  _exports.default = CurateProductCardModalComponent;
});