define("flaneur/models/shipment/index", ["exports", "date-fns", "flaneur/enums/incoterm", "flaneur/mixins/request", "ember-concurrency", "flaneur/models/shipment-rate", "flaneur/utils/billable-weight"], function (_exports, _dateFns, _incoterm, _request, _emberConcurrency, _shipmentRate, _billableWeight) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const _excluded = ["priceInclVat", "priceExclVat"];

  function _objectWithoutProperties(source, excluded) { if (source == null) return {}; var target = _objectWithoutPropertiesLoose(source, excluded); var key, i; if (Object.getOwnPropertySymbols) { var sourceSymbolKeys = Object.getOwnPropertySymbols(source); for (i = 0; i < sourceSymbolKeys.length; i++) { key = sourceSymbolKeys[i]; if (excluded.indexOf(key) >= 0) continue; if (!Object.prototype.propertyIsEnumerable.call(source, key)) continue; target[key] = source[key]; } } return target; }

  function _objectWithoutPropertiesLoose(source, excluded) { if (source == null) return {}; var target = {}; var sourceKeys = Object.keys(source); var key, i; for (i = 0; i < sourceKeys.length; i++) { key = sourceKeys[i]; if (excluded.indexOf(key) >= 0) continue; target[key] = source[key]; } return target; }

  function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); enumerableOnly && (symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols); } return keys; }

  function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = null != arguments[i] ? arguments[i] : {}; i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { _defineProperty(target, key, source[key]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } return target; }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function shipmentRateComputed(property) {
    return Ember.computed(`package.${property}`, 'package', {
      get() {
        return this.package[property];
      },

      set(_, value) {
        Ember.set(this.package, property, value);
        this.getShipmentRate.perform();
        return value;
      }

    });
  }

  const Shipment = Ember.Object.extend(_request.default, {
    packageWeight: shipmentRateComputed('weight'),
    packageWidth: shipmentRateComputed('width'),
    packageHeight: shipmentRateComputed('height'),
    packageLength: shipmentRateComputed('length'),
    isCrossingBorder: Ember.computed.readOnly('is_crossing_border'),

    /**
     * Easypost has a maximum of 5000 USD for their insurance.
     * In order to know the exact maximum for each currency,
     * we'd need to make a request to get the current currency
     * conversion rates from promenade but for now we're keeping
     * this values hardcoded.
     */
    maximumInsuranceAmountPerCurrency: {
      USD: 500000,
      GBP: 350000,
      EUR: 400000,
      DKK: 3000000,
      SEK: 4000000
    },

    /**
     * Consolidate the logic for deciding if the customs form is valid
     @return {String|Boolean} - customs_form_url or false
     */
    hasCustomsForm: Ember.computed('is_crossing_border', 'customs_form_url', 'created_at', function () {
      const customsFormUrl = this.customs_form_url;

      if (!customsFormUrl) {
        return false;
      }

      const isCrossingBorder = this.is_crossing_border === true; // is_crossing_border release date, TODO backfill db & remove

      const beforeCrossingBorderExisted = (0, _dateFns.isBefore)((0, _dateFns.parseISO)(this.created_at), (0, _dateFns.parseISO)('2019-05-21T14:10:00.000Z')); // in case of issue (or border dispute), allow temp override

      const alwaysShowCustomsInvoices = String(window.location.search).indexOf('alwaysShowCustomsInvoices=1') !== -1;
      return isCrossingBorder || alwaysShowCustomsInvoices || beforeCrossingBorderExisted ? customsFormUrl : false;
    }),
    itemPriceField: Ember.computed('allowIncotermCustomization', function () {
      return this.allowIncotermCustomization ? 'priceExclVat' : 'priceInclVat';
    }),
    upsBillableWeight: Ember.computed('package.{height,length,weight,width}', function () {
      return (0, _billableWeight.getUpsBillableWeight)(this);
    }),
    exceedsMaxUpsBillableWeight: Ember.computed('upsBillableWeight.{isCalculated,value}', function () {
      const {
        isCalculated,
        value
      } = this.upsBillableWeight;
      return isCalculated && value > _billableWeight.MAX_UPS_BILLABLE_WEIGHT;
    }),
    totalAmount: Ember.computed('items.@each.{priceInclVat,quantity}', function () {
      return this.items.reduce((total, {
        priceInclVat,
        quantity
      }) => {
        return total + priceInclVat * quantity;
      }, 0);
    }),

    getMaximumInsurancePrice(currency) {
      return {
        amount: this.maximumInsuranceAmountPerCurrency[currency],
        currency
      };
    },

    addItem(extra = {}) {
      return this.items.pushObject(_objectSpread({
        name: null,
        priceInclVat: null,
        priceExclVat: null,
        currency: null,
        quantity: 1
      }, extra));
    },

    setDeliveryAddressCountry(country) {
      this.setProperties({
        'delivery_address.country': country.iso_code,
        'delivery_address.country_iso_alpha_3': country.iso_code_alpha_3
      });
      this.getShipmentRate.perform();
    },

    resetShipmentRate() {
      this.set('rate', null);
    },

    getShipmentRate: (0, _emberConcurrency.task)(function* () {
      yield (0, _emberConcurrency.timeout)(250);
      const {
        package: {
          weight,
          width,
          height,
          length
        },
        delivery_address: {
          country: deliveryAddressCountry
        },
        collection_address: {
          country: collectionAddressCountry
        }
      } = this;
      const requiredFields = [weight, width, height, length, deliveryAddressCountry];

      if (requiredFields.some(Ember.isEmpty)) {
        return this.resetShipmentRate();
      }

      const shipmentRate = yield _shipmentRate.default.find({
        weight,
        width,
        height,
        length,
        source: collectionAddressCountry,
        destination: deliveryAddressCountry
      });

      if (Ember.isEmpty(shipmentRate)) {
        return this.resetShipmentRate();
      }

      const {
        rate
      } = shipmentRate;
      this.set('rate', rate);
    }).restartable()
  });
  Shipment.reopenClass(_request.default, {
    // Class methods
    _create(shipment) {
      shipment.container = this.container;
      return this.create(shipment);
    },

    createFromBoutique(boutique, extraProperties = {}) {
      const oh = boutique.from_warehouse ? boutique.warehouse_oh : boutique.oh;
      const boutiqueCollectionAddress = {
        type: 'boutique',
        company_name: 'Trouva',
        name: boutique.name,
        registered_name: boutique.registered_name,
        accounting_name: boutique.accounting_name,
        email: boutique.support_email,
        phone_number: boutique.support_phone,
        address_line_1: boutique.address_line_1,
        address_line_2: boutique.address_line_2,
        postcode: boutique.address_postcode,
        city: boutique.address_city,
        county: boutique.address_county,
        country: boutique.address_country.iso_code,
        country_iso_alpha_3: boutique.address_country.iso_code_alpha_3,
        vat_number: boutique.vat_number
      };
      const shipment = this.create(_objectSpread({
        type: 'network',
        boutique_id: boutique._id,
        order_id: '',
        package: {
          width: '',
          length: '',
          height: '',
          weight: ''
        },
        items: [],
        delivery_address: {
          type: 'customer',
          // Need to add so we can distinguish addresses
          name: '',
          email: '',
          phone_number: '',
          address_line_1: '',
          address_line_2: '',
          postcode: '',
          city: '',
          county: '',
          country: ''
        },
        collection_address: _objectSpread({}, boutiqueCollectionAddress),
        pickup_slots: oh.slice(0, 5),
        // Only need weekdays
        options: {
          delivery_instructions: ''
        },
        incoterm: _incoterm.default.DDU
      }, extraProperties));
      shipment.addItem({
        currency: boutique.currency
      });
      return shipment;
    },

    post(boutique_id, order_id) {
      return this.request({
        method: 'POST',
        url: this.apiURL('shipments/book'),
        data: JSON.stringify({
          boutique_id,
          order_id
        })
      }).then(({
        shipments
      }) => {
        return shipments.map(this._create.bind(this));
      }).catch(err => {
        if (err.responseJSON) {
          throw err.responseJSON.errors[0].reason ? {
            reason: err.responseJSON.errors[0].reason,
            message: err.responseJSON.errors[0].detail
          } : err.responseJSON.errors[0].detail;
        }

        throw err.message;
      });
    },

    find(data) {
      return this.request({
        method: 'GET',
        url: this.apiURL('shipments'),
        data
      }).then(({
        shipments,
        meta
      }) => {
        return {
          shipments: shipments.map(shipment => this._create(shipment)),
          meta
        };
      });
    },

    bookExternal(shipment) {
      const itemsWithPrice = shipment.items.map(item => {
        const {
          priceInclVat,
          priceExclVat
        } = item,
              restItem = _objectWithoutProperties(item, _excluded);

        const price = item[shipment.itemPriceField];
        return _objectSpread(_objectSpread({}, restItem), {}, {
          price
        });
      });

      const data = _objectSpread(_objectSpread({}, shipment), {}, {
        items: itemsWithPrice
      });

      return this.request({
        method: 'POST',
        url: this.apiURL('shipments/book-external'),
        data: JSON.stringify(data)
      }).then(({
        shipments
      }) => shipments.map(this._create.bind(this)));
    }

  });
  var _default = Shipment;
  _exports.default = _default;
});