define("flaneur/pods/components/nav-order-tabs/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const ORDERS_INDEX_ROUTE = 'brand.boutiques.boutique.orders.index';

  var _default = Ember.Component.extend({
    brand: Ember.inject.service(),
    media: Ember.inject.service(),
    router: Ember.inject.service(),
    boutique: null,
    open: false,
    returnCount: null,
    totalNewOrdersCount: null,
    clickAndCollectCount: null,
    awaitingCollectionCount: null,
    ordersOverviewRoute: {
      label: 'Orders overview',
      route: ORDERS_INDEX_ROUTE
    },

    refreshAction() {},

    addLoadingAction() {},

    currentRouteName: Ember.computed.alias('router.currentRouteName'),
    isBoutiqueShippingProviderOther: Ember.computed.equal('boutique.shipping_provider', 'other'),
    activeTab: Ember.computed('currentActiveRouteFullName', 'ordersOverviewRoute', 'router.currentRouteName', 'tabs', function () {
      if (this.router.currentRouteName === ORDERS_INDEX_ROUTE) {
        return {
          label: 'Orders overview',
          route: this.ordersOverviewRoute
        };
      }

      return this.tabs.find(({
        currentWhen,
        route,
        stage
      }) => {
        return currentWhen || `${route}.${stage}` === this.currentActiveRouteFullName;
      });
    }),
    tabs: Ember.computed('totalNewOrdersCount', 'awaitingCollectionCount', 'clickAndCollectCount', 'returnCount', 'isToProcessTabActive', function () {
      return [{
        label: 'To process',
        route: 'brand.boutiques.boutique.orders.stage',
        stage: 'active',
        count: this.totalNewOrdersCount,
        currentWhen: this.isToProcessTabActive
      }, {
        label: 'Click & Collect',
        route: 'brand.boutiques.boutique.orders.stage',
        stage: 'click-and-collect',
        count: this.clickAndCollectCount
      }, {
        label: 'Returns',
        route: 'brand.boutiques.boutique.orders.stage',
        stage: 'return',
        count: this.returnCount
      }, {
        label: 'Completed',
        route: 'brand.boutiques.boutique.orders.stage',
        stage: 'completed'
      }];
    }),
    currentActiveRouteFullName: Ember.computed('router.currentRoute.{name,params}', function () {
      const {
        currentRoute
      } = this.router;
      return `${currentRoute.name}.${currentRoute.params.stage}`;
    }),
    isToProcessTabActive: Ember.computed('currentActiveRouteFullName', function () {
      return ['brand.boutiques.boutique.orders.stage.active', 'brand.boutiques.boutique.orders.stage.print', 'brand.boutiques.boutique.orders.stage.collection'].includes(this.currentActiveRouteFullName);
    }),
    actions: {
      toggleMobileMenu() {
        this.toggleProperty('open');
      },

      refresh(route) {
        this.addLoadingAction();

        if (this.currentRouteName === route) {
          this.refreshAction();
        }

        this.set('open', false);
      }

    }
  });

  _exports.default = _default;
});