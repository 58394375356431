define("flaneur/pods/brand/boutiques/boutique/shipments/new/route", ["exports", "flaneur/models/shipment", "flaneur/models/country"], function (_exports, _shipment, _country) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    brand: Ember.inject.service(),
    notify: Ember.inject.service(),
    quickShip: Ember.inject.service(),
    router: Ember.inject.service(),

    async model() {
      const boutique = this.brand.currentBoutique;
      const countries = await _country.default.find({
        shipping_enabled: true,
        skip_links: true,
        sort: 'weengs_name'
      });
      const shipment = this.quickShip.prefilledShipment;
      this.quickShip.clearPrefilled();
      return {
        boutique,
        shipment,
        boutiqueCollectionAddress: shipment.collection_address,
        countries
      };
    },

    actions: {
      backToHistory() {
        return this.router.transitionTo('brand.boutiques.boutique.shipments', this.brand, this.brand.currentBoutique);
      },

      async submitForm(shipment) {
        try {
          const shipments = await _shipment.default.bookExternal(shipment);
          return this.router.transitionTo('brand.boutiques.boutique.shipments.view', this.brand, this.brand.currentBoutique, shipments[0]._id);
        } catch (error) {
          const isErrorRetryable = error.status === 429;

          if (isErrorRetryable) {
            this.notify.show('error', 'Oops! An error occurred while booking a courier. Please try again.');
            return;
          }

          this.notify.show('error', 'Oops! An error ocurred while booking a courier. Please contact support.');
          return this.router.transitionTo('brand.boutiques.boutique.shipments', this.brand, this.brand.currentBoutique);
        }
      }

    }
  });

  _exports.default = _default;
});