define("flaneur/pods/components/pages/curate/orders/submitted/component", ["exports", "@glimmer/component", "flaneur/enums/curate-order-submitted-type", "ember-component-css/pod-names"], function (_exports, _component, _curateOrderSubmittedType, _podNames) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _class, _descriptor, _descriptor2;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  let PagesCurateOrdersSubmittedComponent = (_dec = Ember.inject.service, _dec2 = Ember.inject.service, _dec3 = Ember._action, _dec4 = Ember._action, (_class = class PagesCurateOrdersSubmittedComponent extends _component.default {
    constructor(...args) {
      super(...args);

      _initializerDefineProperty(this, "router", _descriptor, this);

      _initializerDefineProperty(this, "analytics", _descriptor2, this);
    }

    get styleNamespace() {
      return _podNames.default['pages/curate/orders/submitted'];
    }

    get order() {
      return this.args.model.order;
    }

    get isNewAccountOrderSubmitted() {
      return this.args.model.orderSubmitted.type === _curateOrderSubmittedType.default.NEW_ACCOUNT;
    }

    trackBackToCurate() {
      this.analytics.gtm({
        action: 'curate_order_confirmation_continue',
        label: this.order._id
      });
    }

    trackMyOrdersPage() {
      this.analytics.gtm({
        action: 'curate_order_confirmation_orders',
        label: this.args.model.orderSubmitted.brand
      });
    }

  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "router", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "analytics", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "trackBackToCurate", [_dec3], Object.getOwnPropertyDescriptor(_class.prototype, "trackBackToCurate"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "trackMyOrdersPage", [_dec4], Object.getOwnPropertyDescriptor(_class.prototype, "trackMyOrdersPage"), _class.prototype)), _class));
  _exports.default = PagesCurateOrdersSubmittedComponent;
});