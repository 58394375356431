define("flaneur/pods/application/controller", ["exports", "flaneur/config/environment"], function (_exports, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _dec8, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _descriptor6;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  let ApplicationController = (_dec = Ember.inject.service, _dec2 = Ember.inject.service, _dec3 = Ember._tracked, _dec4 = Ember._tracked, _dec5 = Ember._tracked, _dec6 = Ember._tracked, _dec7 = Ember._action, _dec8 = Ember._action, (_class = class ApplicationController extends Ember.Controller {
    get restockProduct() {
      return this['restock-product'];
    }

    get restockOrigin() {
      return this['restock-origin'];
    }

    get restockAction() {
      return this['restock-action'];
    }

    constructor() {
      super(...arguments);

      _initializerDefineProperty(this, "pubsub", _descriptor, this);

      _initializerDefineProperty(this, "router", _descriptor2, this);

      _defineProperty(this, "queryParams", ['restock-product', 'restock-origin', 'restock-action']);

      _initializerDefineProperty(this, 'restock-product', _descriptor3, this);

      _initializerDefineProperty(this, 'restock-origin', _descriptor4, this);

      _initializerDefineProperty(this, 'restock-action', _descriptor5, this);

      _initializerDefineProperty(this, "showFeatureFlagsManager", _descriptor6, this);

      this.showFeatureFlagsManager = _environment.default.environment !== 'production';
    }

    closeRestockModal() {
      return this.router.transitionTo({
        queryParams: {
          'restock-product': undefined,
          'restock-origin': undefined,
          'restock-action': undefined
        }
      });
    }

    dismissNotification(index) {
      this.pubsub.removeNotificationAt(index);
    }

  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "pubsub", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "router", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, 'restock-product', [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, 'restock-origin', [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, 'restock-action', [_dec5], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor6 = _applyDecoratedDescriptor(_class.prototype, "showFeatureFlagsManager", [_dec6], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "closeRestockModal", [_dec7], Object.getOwnPropertyDescriptor(_class.prototype, "closeRestockModal"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "dismissNotification", [_dec8], Object.getOwnPropertyDescriptor(_class.prototype, "dismissNotification"), _class.prototype)), _class));
  _exports.default = ApplicationController;
});