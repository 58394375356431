define("flaneur/util/validation/messages", ["exports", "ember-validators/messages"], function (_exports, _messages) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.getMessage = getMessage;
  _exports.getDescription = getDescription;
  _exports.buildMessage = buildMessage;
  _exports.messages = void 0;

  function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); enumerableOnly && (symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols); } return keys; }

  function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = null != arguments[i] ? arguments[i] : {}; i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { _defineProperty(target, key, source[key]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } return target; }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  // Override custom messages with our own custom messages
  const messages = _objectSpread({}, _messages.default);
  /**
   * Gets the message passed from the context, with fall-back to default messages.
   *
   * @param context
   * @returns {string}    - Returned error message
   */


  _exports.messages = messages;

  function getMessage(context = {}) {
    const customMessage = context.message;
    const errorType = context.type;

    switch (typeof customMessage) {
      // If the message is a string, format and return back
      case 'string':
        return messages.formatMessage(customMessage, context);
      // If the message is a function, execute it to retrieve the message

      case 'function':
        {
          const customMessageValue = customMessage();
          return messages.formatMessage(customMessageValue, context);
        }
      // If undefined or anything else, fall-back to default message

      case 'undefined':
      default:
        return messages.getMessageFor(errorType, context);
    }
  }
  /**
   * Proxy method for `messages.getDescriptionFor`
   *
   * @param property
   * @param context
   * @returns {string}
   */


  function getDescription(property, context) {
    return messages.getDescriptionFor(null, context);
  }
  /**
   * Takes the result of a validator, parses the context to find a user friendly message to display
   * and attaches it to the result object
   *
   * @param property
   * @param result
   */


  function buildMessage(property, result = {}) {
    const description = getDescription(property, result.context);

    const context = _objectSpread(_objectSpread({}, result.context), {}, {
      value: result.value,
      description,
      type: result.type
    });

    result.message = getMessage(context);
    return result;
  }
});