define("flaneur/pods/components/category-select/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    categoryList: Ember.computed('categories', function () {
      const categories = this.categories;
      const categoryList = categories.sortBy('full_hierarchy_name').filter(category => category.full_hierarchy_name.search(/gifts/i) !== 0);
      return categoryList;
    }),
    selectedCategory: Ember.computed('categoryList.[]', 'selectedCategoryId', function () {
      const selectedCategoryId = this.selectedCategoryId;

      if (typeof selectedCategoryId === 'object') {
        return selectedCategoryId;
      }

      if (typeof selectedCategoryId === 'string') {
        return this.categoryList.find(cat => cat._id === selectedCategoryId);
      }

      return null;
    }),
    actions: {
      matcher(category, term) {
        term = term.toLowerCase().replace(/ and /g, ' & ').replace(/ and/g, ' &');
        const categoryDisplayName = category.full_hierarchy_name.toLowerCase().replace(/> /g, '');
        return categoryDisplayName.indexOf(term);
      },

      onChange(category) {
        const level = this.level || 'terminal';
        return this.onChangeCategory(category, level);
      }

    }
  });

  _exports.default = _default;
});