define("flaneur/pods/components/order-loading-message/return/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "UlXcle1Y",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[18,1,null],[2,\"\\n\\n\"],[10,\"div\"],[14,0,\"empty-state-message\"],[12],[2,\"\\n    You're not expecting any returns at the moment.\\n\"],[13],[2,\"\\n\"],[10,\"hr\"],[12],[13]],\"hasEval\":false,\"upvars\":[]}",
    "moduleName": "flaneur/pods/components/order-loading-message/return/template.hbs"
  });

  _exports.default = _default;
});