define("flaneur/pods/components/form-flows/shipment/pickup-details/validations", ["exports", "flaneur/util/validation/index", "flaneur/util/validation/validations", "flaneur/pods/components/form-flows/shipment/address_validations"], function (_exports, _index, _validations, _address_validations) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.address = address;

  function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); enumerableOnly && (symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols); } return keys; }

  function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = null != arguments[i] ? arguments[i] : {}; i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { _defineProperty(target, key, source[key]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } return target; }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function address(shipment) {
    // Validations returned from addressValidations return the top level keys
    // We need to prefix them with the correct path.
    const countryValidations = (0, _address_validations.default)(shipment.collection_address.country);
    const validationsWithPrefixedCollectionAddress = Object.entries(countryValidations).reduce((prev, [key, value]) => {
      prev[`collection_address.${key}`] = value;
      return prev;
    }, {}); // Boutiques need to provide their name and email

    const validationsWithBoutiqueDetails = _objectSpread(_objectSpread({}, validationsWithPrefixedCollectionAddress), {}, {
      _isNewAddress: [(0, _validations.validatePresence)({
        presence: true,
        message: 'Please select a location'
      })],
      'collection_address.name': [(0, _validations.validatePresence)({
        presence: true,
        description: 'Name'
      }), (0, _validations.validateLength)({
        min: 2,
        description: 'Name'
      })],
      'collection_address.email': [(0, _validations.validatePresence)({
        presence: true,
        description: 'Email'
      }), (0, _validations.validateFormat)({
        type: 'email',
        description: 'Email'
      })]
    }); // Run the validations


    return (0, _index.default)(shipment, validationsWithBoutiqueDetails);
  }
});