define("flaneur/pods/components/marked/component", ["exports", "@glimmer/component", "marked"], function (_exports, _component, _marked) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  class MarkedComponent extends _component.default {
    get markedContent() {
      return Ember.String.htmlSafe(_marked.marked.parse(this.args.markdown));
    }

  }

  _exports.default = MarkedComponent;
});