define("flaneur/pods/components/basic-form-section/suggestion/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    classNames: ['suggestion'],
    display: Ember.computed('section.state', function () {
      return Ember.get(this, 'section.state') === 'suggestion';
    })
  });

  _exports.default = _default;
});