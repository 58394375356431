define("flaneur/pods/components/collection-order-items/component", ["exports", "flaneur/util/default-to", "date-fns"], function (_exports, _defaultTo, _dateFns) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); enumerableOnly && (symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols); } return keys; }

  function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = null != arguments[i] ? arguments[i] : {}; i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { _defineProperty(target, key, source[key]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } return target; }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  const NO_DATE_KEY = 'no-date';
  const CLICK_AND_COLLECT_TAB = 'click-and-collect';

  var _default = Ember.Component.extend({
    orderHistory: Ember.inject.service(),
    ordersNumber: null,
    loading: false,
    groupedReservations: (0, _defaultTo.default)({}),
    reservationBoutiques: (0, _defaultTo.default)([]),
    dateFormat: 'dd-MM-yyyy',

    markAsCollectedAction() {},

    closeAction() {},

    orderSearched: Ember.computed.alias('orderHistory.orderSearched'),
    hasOrders: Ember.computed('sortedGroupReservationsByDate.[]', function () {
      return Object.keys(this.sortedGroupReservationsByDate).length > 0;
    }),
    sortedGroupReservationsWithoutDate: Ember.computed('sortedGroupReservationsByDate.[]', function () {
      return this.sortedGroupReservationsByDate[NO_DATE_KEY] || {};
    }),
    hasReservationsWithoutDate: Ember.computed('sortedGroupReservationsWithoutDate.[]', function () {
      return Object.keys(this.sortedGroupReservationsWithoutDate).length > 0;
    }),
    sortedReservationsDates: Ember.computed('dateFormat', 'sortedGroupReservationsByDate.[]', function () {
      const groupedReservations = _objectSpread({}, this.sortedGroupReservationsByDate);

      delete groupedReservations[NO_DATE_KEY];
      return Object.keys(groupedReservations).sort((dateA, dateB) => (0, _dateFns.isAfter)((0, _dateFns.parse)(dateA, this.dateFormat, new Date()), (0, _dateFns.parse)(dateB, this.dateFormat, new Date())));
    }),
    sortedGroupReservationsByDate: Ember.computed('groupedReservations.[]', 'reservationBoutiques.[]', function () {
      const sortedReservationsByDate = [];
      const orderIds = Object.keys(this.groupedReservations);
      orderIds.forEach(orderId => {
        let [{
          estimated_collection_date: estimatedCollectionDate
        }] = this.reservationBoutiques[orderId];
        const reservations = this.groupedReservations[orderId];
        estimatedCollectionDate = this._calculateEstimationCollectionDate(estimatedCollectionDate);

        if (!sortedReservationsByDate[estimatedCollectionDate]) {
          sortedReservationsByDate[estimatedCollectionDate] = {};
        }

        sortedReservationsByDate[estimatedCollectionDate][orderId] = reservations;
      });
      return sortedReservationsByDate;
    }),
    shouldShowAutomaticCourierUpdatesBanner: Ember.computed('currentTab', function () {
      return this.currentTab !== CLICK_AND_COLLECT_TAB;
    }),

    _calculateEstimationCollectionDate(collectionDate) {
      if (!collectionDate || this.currentTab === CLICK_AND_COLLECT_TAB) {
        return NO_DATE_KEY;
      }

      return (0, _dateFns.format)((0, _dateFns.parseISO)(collectionDate), this.dateFormat);
    },

    actions: {
      toReadableDate(date) {
        if (this.currentTab === CLICK_AND_COLLECT_TAB) {
          return 'will be collected by the customer';
        }

        const now = new Date();
        const diffInDays = (0, _dateFns.differenceInDays)((0, _dateFns.parse)(date, this.dateFormat, now), (0, _dateFns.startOfDay)(now));

        if (diffInDays === 0) {
          return 'to be picked up <span>today -</span>';
        }

        if (diffInDays === -1) {
          return 'should have been picked up <span>yesterday -</span>';
        }

        if (diffInDays === 1) {
          return 'to be picked up <span>tomorrow -<span>';
        }

        if (diffInDays < 1) {
          return `should have been picked up <span>${Math.abs(diffInDays)} days ago on</span>`;
        }

        return `to be picked up in <span>${diffInDays} days on </span>`;
      },

      isAfterToday(date) {
        const now = new Date();
        return (0, _dateFns.isAfter)(now, (0, _dateFns.parse)(date, this.dateFormat, now));
      }

    }
  });

  _exports.default = _default;
});