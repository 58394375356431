define("flaneur/pods/components/form-cloudinary/component", ["exports", "flaneur/config/environment", "flaneur/util/color", "flaneur/utils/cloudinary-upload"], function (_exports, _environment, _color, _cloudinaryUpload) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const CLOUDINARY_CONFIG = {
    cloudName: _environment.default.CLOUDINARY_NAME,
    uploadPreset: _environment.default.CLOUDINARY
  };

  var _default = Ember.Component.extend({
    notify: Ember.inject.service(),
    // Public API
    multiple: false,
    fileTypes: 'image/*',
    maxFileSize: 5000000,
    // 5MB in bytes
    folder: '',

    onUpload() {},

    // Private API
    classNames: ['form-cloudinary'],
    // Cached selector of the hidden input
    input: Ember.computed('element', function () {
      return this.element.querySelector('input');
    }),

    /**
     * Iterate through multiple cloudinary images and map to promenade standard image resource
     *
     * @param resources
     * @returns {object[]}
     */
    mapCloudinaryImagesToPromenade(resources) {
      return resources.map(this.mapCloudinaryImageToPromenade);
    },

    /**
     * Map cloudinary response to promenade standard image resource
     *
     * @param resource
     * @returns {object}
     */
    mapCloudinaryImageToPromenade(resource) {
      return {
        url: resource.secure_url,
        width: resource.width,
        height: resource.height,
        format: resource.format,
        resource_type: resource.resource_type,
        video: resource.video ? resource.video : undefined,
        cloudinary_top_colors: resource.colors ? (0, _color.getColors)(resource.colors) : undefined,
        cloudinary_top_colors_hsv: resource.colors ? (0, _color.getColorsHSV)(resource.colors) : undefined,
        cloudinary_google_colors: resource.colors ? (0, _color.getColors)(resource.predominant.google) : undefined
      };
    },

    /**
     * File upload progress handler.
     * Updates progress key of the current file.
     *
     * @param event
     * @param file
     * @param progress
     */
    uploadProgressHandler(_event, file, progress) {
      Ember.set(file, 'progress', progress);
    },

    /**
     * Handle multiple files being uploaded to cloudinary.
     *
     * Responsibilities:
     * 1. Validate file size
     * 2. Take care of component loading state
     * 3. Map cloudinary responses to promenade image
     *
     * @param files
     * @returns {Promise<image[] | never>}
     */
    uploadFiles(files) {
      const cloudinaryUpload = new _cloudinaryUpload.default(CLOUDINARY_CONFIG);
      const {
        multiple,
        maxFileSize,
        onUpload,
        folder
      } = this;
      const formData = {
        folder,
        tags: ['browser_uploads']
      }; // Remove files that have more than the maximum size

      const filesArray = Array.from(files).filter(file => file.size <= maxFileSize);

      if (Ember.isEmpty(filesArray) || filesArray.length < files.length) {
        this.notify.show('error', "Some photos you're trying to upload exceed the maximum size of 5MB. Please try again.", 4000);
      } // Start component loading state


      this.setProperties({
        isUploading: true,
        files: filesArray
      }); // Upload files and batch up the uploads, so we can change the loading state

      const promises = filesArray.map(file => {
        return this.uploadFile(cloudinaryUpload, file, formData, this.uploadProgressHandler);
      }); // When all uploads are finished, stop the component loading state

      return Ember.RSVP.all(promises).then(images => {
        // Map cloudinary objects to promenade object
        // and create ember objects for backwards compatibility
        // with old cloudinary-form component
        return this.mapCloudinaryImagesToPromenade(images).map(image => Ember.Object.create(image));
      }) // Inform the parent component that upload is completed
      .then(images => !multiple ? onUpload(images[0]) : onUpload(images)).finally(() => Ember.trySet(this, 'isUploading', false));
    },

    /**
     * Upload a single resource to cloudinary
     *
     * @param cloudinaryUpload
     * @param file
     * @returns {Promise}
     */
    uploadFile(cloudinaryUpload, file, formData, progressHandler) {
      return cloudinaryUpload.upload(file, formData, progressHandler);
    },

    actions: {
      openUploadDialog() {
        this.input.click();
      },

      handleFiles() {
        const files = this.input.files;
        return this.uploadFiles(files);
      },

      onDropzoneDragOver(event) {
        event.preventDefault();
      },

      onDropzoneDrop(event) {
        event.stopPropagation();
        event.preventDefault();
        const regex = RegExp(this.fileTypes);
        const files = Array.from(event.dataTransfer.files).filter(({
          type
        }) => regex.test(type));
        return this.uploadFiles(files);
      }

    }
  });

  _exports.default = _default;
});