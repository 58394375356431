define("flaneur/services/scroller", ["exports", "jquery", "ember-scroll-to/services/scroller"], function (_exports, _jquery, _scroller) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _scroller.default.extend({
    scrollable: Ember.computed(function () {
      return (0, _jquery.default)('html');
    })
  });

  _exports.default = _default;
});