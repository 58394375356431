define("flaneur/pods/brand/boutiques/boutique/packaging/orders/confirmation/controller", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  class PackagingOrdersConfirmationController extends Ember.Controller {
    constructor(...args) {
      super(...args);

      _defineProperty(this, "queryParams", [{
        orderNumber: 'order-number'
      }]);

      _defineProperty(this, "orderNumber", null);
    }

  }

  _exports.default = PackagingOrdersConfirmationController;
});