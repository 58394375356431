define("flaneur/pods/components/packaging-product-card/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "nlc5UmJs",
    "block": "{\"symbols\":[\"price\",\"@product\"],\"statements\":[[8,\"link-to\",[[16,0,[31,[\"packaging-product-card \",[32,0,[\"styleNamespace\"]]]]]],[[\"@route\",\"@models\"],[\"brand.boutiques.boutique.packaging.products.product\",[30,[36,2],[[32,0,[\"brand\"]],[32,0,[\"brand\",\"currentBoutique\",\"_id\"]],[32,2,[\"id\"]]],null]]],[[\"default\"],[{\"statements\":[[2,\"\\n\"],[6,[37,3],[[32,2,[\"isOutOfStock\"]]],null,[[\"default\"],[{\"statements\":[[2,\"    \"],[10,\"div\"],[14,0,\"packaging-product-card__oos\"],[12],[2,\"\\n      Out of stock\\n    \"],[13],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"\\n  \"],[10,\"div\"],[14,0,\"packaging-product-card__image\"],[12],[2,\"\\n    \"],[11,\"img\"],[16,\"src\",[32,0,[\"image\",\"url\"]]],[24,\"alt\",\"box\"],[4,[38,4],[\"error\",[32,0,[\"replaceBrokenImage\"]]],null],[12],[13],[2,\"\\n  \"],[13],[2,\"\\n  \"],[10,\"div\"],[14,0,\"packaging-product-card__card-info\"],[12],[2,\"\\n    \"],[10,\"p\"],[14,0,\"packaging-product-card__title\"],[12],[1,[32,2,[\"name\"]]],[13],[2,\"\\n    \"],[10,\"p\"],[14,0,\"packaging-product-card__variants\"],[12],[1,[30,[36,5],[[32,2,[\"variants\",\"length\"]],\"variant\"],null]],[13],[2,\"\\n    \"],[10,\"p\"],[14,0,\"packaging-product-card__price\"],[12],[2,\"\\n\"],[6,[37,6],[[32,2,[\"lowestVariantUnitPrice\"]]],null,[[\"default\"],[{\"statements\":[[2,\"        From\\n        \"],[1,[30,[36,1],[[30,[36,0],[[32,1,[\"amount\"]]],null]],[[\"style\",\"currency\"],[\"currency\",[32,1,[\"currency\"]]]]]],[2,\"/unit\\n        excl. VAT\\n\"]],\"parameters\":[1]}]]],[2,\"    \"],[13],[2,\"\\n  \"],[13],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"to-money\",\"format-number\",\"array\",\"if\",\"on\",\"pluralize\",\"let\"]}",
    "moduleName": "flaneur/pods/components/packaging-product-card/template.hbs"
  });

  _exports.default = _default;
});