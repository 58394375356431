define("flaneur/services/session", ["exports", "flaneur/mixins/request", "flaneur/models/user", "@datadog/browser-rum", "fetch", "flaneur/config/environment"], function (_exports, _request, _user, _browserRum, _fetch, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Service.extend(_request.default, {
    postLoginTransition: null,
    user: _user.default._create({}),
    isLoggedIn: false,
    analytics: Ember.inject.service(),
    firebaseService: Ember.inject.service(),

    /**
     * Logout the user
     * @return {Object} Promise
     */
    async logout() {
      await this.request({
        url: this.apiURL('logout'),
        method: 'GET'
      });
      await this.firebaseService.signOut();
      this.set('user', _user.default._create({}));
      this.set('isLoggedIn', false);
    },

    /**
     * Sync auth between Promenade and Firebase
     * @return {Object} Promise
     */
    syncAuth() {
      return this.request({
        url: this.apiURL('users/syncauth'),
        method: 'POST'
      });
    },

    /**
     * Fetch isLoggedIn from the server and refreshes data in the session
     * @return {Object} Promise
     */
    async isAuthenticated() {
      const res = await this.getLoggedIn();
      this.set('isLoggedIn', res.isLoggedIn);
      this.user.setProperties(res.user);

      if (!this.isLoggedIn) {
        throw new Error('Not logged in!');
      }

      this.identify();

      try {
        const {
          customToken
        } = await this.syncAuth();
        await this.firebaseService.signInWithCustomToken(customToken);
      } catch (error) {
        _browserRum.datadogRum.addError(error);
      }

      return res;
    },

    /**
     * Calls the api to see if the user is logged in
     * @return {Object} Promise
     */
    getLoggedIn() {
      return this.request({
        url: this.apiURL('isloggedin'),
        method: 'GET'
      });
    },

    /**
     * Get the brand of the user
     * @param  {[type]} user [description]
     * @return {[type]}      [description]
     */
    async getUserBrand(user) {
      const res = await this.request({
        method: 'GET',
        url: this.apiURL('brands'),
        data: {
          match: {
            users: {
              user: user._id
            }
          }
        }
      });
      return res.brands[0];
    },

    /**
     * Authenticates an user based on the email and password and updates the infos
     * in the session (user, isLoggedIn)
     * @param  {String} email    Email
     * @param  {String} password Password
     * @return {Object}          Promise
     */
    async authenticate(email, password) {
      const user = await this.request({
        method: 'POST',
        url: this.apiURL('login'),
        data: JSON.stringify({
          email,
          password
        })
      });
      const brand = await this.getUserBrand(user);
      this.set('isLoggedIn', true);
      this.set('user', _user.default._create(user));
      this.set('user.brand', brand ? brand._id : null);
      this.identify();
      return user;
    },

    async loginWithFirebase(payload = {}) {
      const res = await (0, _fetch.default)(`${_environment.default.apiURL}/api/v2/login/firebase`, {
        method: 'POST',
        credentials: 'include',
        cache: false,
        headers: {
          'Content-Type': 'application/json; charset=UTF-8'
        },
        body: JSON.stringify(payload)
      });

      if (!res.ok) {
        throw new Error('error logging in with Firebase');
      }

      const {
        isLoggedIn,
        user
      } = await this.getLoggedIn();

      if (!isLoggedIn) {
        throw new Error('not logged in');
      }

      this.set('isLoggedIn', isLoggedIn);
      this.set('user', _user.default._create(user));
      this.identify();

      try {
        const brand = await this.getUserBrand(user);
        this.set('user.brand', brand === null || brand === void 0 ? void 0 : brand._id);
      } catch (error) {
        await this.logout();
      }
    },

    identify() {
      try {
        const {
          _id,
          email
        } = this.user;

        if (!email || !_id) {
          return;
        }

        _browserRum.datadogRum.setUser({
          id: _id,
          email
        });

        this.analytics.initializeDatalayer(this.user.brand);
        this.analytics.identify({
          _id,
          email
        });
      } catch (e) {
        return;
      }
    },

    upgradeAccount(new_password, new_password_confirmation, role_token) {
      return this.request({
        url: this.apiURL('changerole'),
        data: JSON.stringify({
          new_password: new_password,
          new_password_confirmation: new_password_confirmation,
          role_token: role_token
        }),
        method: 'POST'
      });
    }

  });

  _exports.default = _default;
});