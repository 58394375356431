define("flaneur/pods/components/bulk-edit-footer/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "ITtx5ssa",
    "block": "{\"symbols\":[],\"statements\":[[10,\"div\"],[14,0,\"bulk-edit-footer__wrapper\"],[12],[2,\"\\n  \"],[10,\"div\"],[14,0,\"bulk-edit-footer__title\"],[12],[2,\"\\n    \"],[1,[30,[36,2],[[30,[36,1],[[35,0],1],null],\"Edit options\",\"Bulk edit options\"],null]],[2,\"\\n    (\"],[1,[30,[36,3],[[35,0],\"product\"],null]],[2,\" selected)\\n  \"],[13],[2,\"\\n\\n  \"],[10,\"div\"],[14,0,\"bulk-edit-footer__actions\"],[12],[2,\"\\n    \"],[8,\"button\",[],[[\"@theme\",\"@onClick\"],[\"secondary\",[30,[36,4],[[32,0],\"showDiscountModal\"],null]]],[[\"default\"],[{\"statements\":[[2,\"\\n      \"],[8,\"fa-icon\",[],[[\"@icon\",\"@prefix\"],[\"badge-percent\",\"fas\"]],null],[2,\" Set discount\\n    \"]],\"parameters\":[]}]]],[2,\"\\n\\n    \"],[8,\"button\",[],[[\"@theme\",\"@onClick\",\"@disabled\"],[\"secondary\",[30,[36,4],[[32,0],\"showRestockModal\"],null],[30,[36,5],[[35,0],1],null]]],[[\"default\"],[{\"statements\":[[2,\"\\n      \"],[8,\"fa-icon\",[],[[\"@icon\",\"@prefix\"],[\"inventory\",\"fas\"]],null],[2,\" Add stock plans\\n    \"]],\"parameters\":[]}]]],[2,\"\\n\\n    \"],[8,\"button\",[],[[\"@theme\",\"@onClick\"],[\"secondary\",[30,[36,4],[[32,0],\"showConfirmationModal\"],null]]],[[\"default\"],[{\"statements\":[[2,\"\\n      \"],[8,\"fa-icon\",[],[[\"@icon\",\"@prefix\"],[\"archive\",\"fas\"]],null],[2,\" Archive\\n    \"]],\"parameters\":[]}]]],[2,\"\\n  \"],[13],[2,\"\\n\\n  \"],[11,\"div\"],[24,\"role\",\"button\"],[24,0,\"bulk-edit-footer__close\"],[4,[38,4],[[32,0],\"uncheckProducts\"],null],[12],[2,\"\\n    \"],[8,\"fa-icon\",[],[[\"@icon\",\"@prefix\"],[\"times\",\"far\"]],null],[2,\"\\n  \"],[13],[2,\"\\n\"],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"checkedCount\",\"eq\",\"if\",\"pluralize\",\"action\",\"gt\"]}",
    "moduleName": "flaneur/pods/components/bulk-edit-footer/template.hbs"
  });

  _exports.default = _default;
});