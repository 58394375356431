define("flaneur/pods/components/pages/orders/return/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    actions: {
      inStock() {
        return this.inStock(...arguments);
      },

      loadMore() {
        return this.loadMore(...arguments);
      },

      resetSearch() {
        return this.resetSearch(...arguments);
      },

      splitByReturnAndNonReturnReservations(reservations) {
        const isReturnedItem = ({
          requested_return,
          returned
        }) => requested_return && !returned;

        const nonReturnedItem = item => !isReturnedItem(item);

        const returnedReservations = [...reservations].filter(isReturnedItem);
        const nonReturnedReservations = [...reservations].filter(nonReturnedItem);
        return {
          returnedReservations,
          nonReturnedReservations
        };
      }

    }
  });

  _exports.default = _default;
});