define("flaneur/pods/components/curate-categories-filter/subtree/component", ["exports", "@glimmer/component", "ember-component-css/pod-names"], function (_exports, _component, _podNames) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  class CurateCategoriesFilterSubtreeComponent extends _component.default {
    get styleNamespace() {
      return _podNames.default['curate-categories-filter/subtree'];
    }

    get parentId() {
      return this.args.level === 'topLevel' ? undefined : this.args.level;
    }

    get categories() {
      var _this$args$level;

      return this.args.categories[(_this$args$level = this.args.level) !== null && _this$args$level !== void 0 ? _this$args$level : 'topLevel'];
    }

  }

  _exports.default = CurateCategoriesFilterSubtreeComponent;
});