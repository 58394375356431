define("flaneur/pods/components/form-status/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "Yz9GoN+K",
    "block": "{\"symbols\":[\"toggle\"],\"statements\":[[10,\"div\"],[15,0,[31,[\"item item-status \",[34,3],\" \",[30,[36,1],[[35,2],\"active\",\"\"],null],\" \",[30,[36,1],[[35,0],\"disabled\",\"\"],null]]]],[12],[2,\"\\n  \"],[10,\"p\"],[14,0,\"item-label\"],[12],[2,[34,4]],[13],[2,\"\\n  \"],[10,\"div\"],[14,0,\"item-form\"],[12],[2,\"\\n    \"],[8,\"x-toggle\",[],[[\"@value\",\"@disabled\",\"@showLabels\",\"@onToggle\"],[[34,2],[34,0],true,[30,[36,5],[[32,0],\"updateSwitch\"],null]]],[[\"default\"],[{\"statements\":[[2,\"\\n        \"],[8,[32,1,[\"label\"]],[],[[\"@value\"],[[34,2]]],[[\"default\"],[{\"statements\":[[2,\"\\n          \"],[1,[30,[36,1],[[35,2],[35,7],[35,6]],null]],[2,\"\\n        \"]],\"parameters\":[]}]]],[2,\"\\n        \"],[8,[32,1,[\"switch\"]],[],[[],[]],null],[2,\"\\n    \"]],\"parameters\":[1]}]]],[2,\"\\n  \"],[13],[2,\"\\n  \"],[10,\"div\"],[14,0,\"permalink\"],[12],[13],[2,\"\\n\"],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"disabled\",\"if\",\"active\",\"itemClass\",\"label\",\"action\",\"offLabel\",\"onLabel\"]}",
    "moduleName": "flaneur/pods/components/form-status/template.hbs"
  });

  _exports.default = _default;
});