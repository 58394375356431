define("flaneur/pods/components/stock-line-input/component", ["exports", "ember-concurrency", "@datadog/browser-rum"], function (_exports, _emberConcurrency, _browserRum) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    // Public API
    stockLine: null,
    enableUnlimitedStock: true,

    onChange() {},

    // Private API
    classNames: ['stock-line-input'],
    classNameBindings: ['isUnlimited:stock-line-input--unlimited'],
    _message: null,
    _onChangeTimer: 1000,
    _clearMessageTimer: 4000,
    _previousQuantity: 1,
    quantity: Ember.computed.alias('stockLine.quantity'),
    isUnlimited: Ember.computed('quantity', {
      get() {
        return this.quantity === -1;
      },

      set(_, unlimited) {
        if (unlimited) {
          this.set('_previousQuantity', this.stockLine.quantity);
        }

        this.onChangeQuantity.perform(unlimited ? -1 : this._previousQuantity, 0, false);
        return unlimited;
      }

    }),
    onChangeQuantity: (0, _emberConcurrency.task)(function* (quantity, delay = this._onChangeTimer, showMessage = true) {
      try {
        Ember.trySet(this, '_message', null);

        if (Ember.isBlank(quantity) || Ember.isEqual(this.stockLine.quantity, quantity)) {
          return;
        }

        Ember.set(this.stockLine, 'quantity', quantity);
        yield (0, _emberConcurrency.timeout)(delay);

        if (showMessage) {
          this._triggerMessage('loading');
        }

        yield (0, _emberConcurrency.timeout)(delay);
        yield this.onChange(this.stockLine);

        if (showMessage) {
          this._triggerMessage('success');
        }
      } catch (e) {
        _browserRum.datadogRum.addError(e);

        if (showMessage) {
          this._triggerMessage('error');
        }
      }
    }).restartable(),

    _triggerMessage(type) {
      this.set('_message', type);

      if (type === 'success') {
        setTimeout(() => Ember.trySet(this, '_message', null), this._clearMessageTimer);
      }
    },

    actions: {
      onChangeQuantity(quantity) {
        return this.onChangeQuantity.perform(quantity);
      },

      onAddQuantity() {
        return this.onChangeQuantity.perform(1);
      }

    }
  });

  _exports.default = _default;
});