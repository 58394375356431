define("flaneur/utils/pipeline-requirements", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _exports.totalLive = _exports.unlisted = _exports.pendingUpdates = void 0;
  // Awaiting curation
  // - published = false
  // - pending = false
  // - featured = false
  // - unsuitable = false
  // - unsuitable_for_trouva = false
  // - no pending amendment notes
  // or
  // - published = true
  // - pending = false
  // - featured = false
  // - unsuitable = false
  // - unsuitable_for_trouva = false
  // - no pending amendment notes
  // Pending updates
  // - amendment_stats.pending > 0
  const pendingUpdates = {
    'amendment_stats.pending': {
      $gte: 1
    }
  }; // Unlisted
  // - unsuitable = true
  // or
  // - unsuitable_for_trouva = true

  _exports.pendingUpdates = pendingUpdates;
  const unlisted = {
    $or: [{
      unsuitable: true
    }, {
      unsuitable_for_trouva: true
    }]
  }; // Total live products
  // - published = true

  _exports.unlisted = unlisted;
  const totalLive = {
    published: true
  };
  _exports.totalLive = totalLive;
  var _default = {
    pendingUpdates,
    unlisted,
    totalLive
  };
  _exports.default = _default;
});