define("flaneur/enums/tag-theme", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    POSITIVE: 'positive',
    NEGATIVE: 'negative',
    WARNING: 'warning',
    INFO: 'info',
    CURATE: 'curate',
    MADE: 'made'
  };
  _exports.default = _default;
});