define("flaneur/models/curate/brand-referral/validations", ["exports", "flaneur/util/validation/validations"], function (_exports, _validations) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.referralValidations = void 0;
  const referralValidations = {
    name: [(0, _validations.validatePresence)({
      presence: true,
      message: 'Please add a name'
    })]
  };
  _exports.referralValidations = referralValidations;
});