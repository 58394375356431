define("flaneur/models/material", ["exports", "flaneur/mixins/request"], function (_exports, _request) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const Material = Ember.Object.extend(_request.default, {});
  Material.reopenClass(_request.default, {
    // Class methods
    find(query) {
      return this.request({
        method: 'GET',
        url: this.apiURL('materials'),
        data: query
      }).then(res => {
        return res.materials.map(material => {
          return this.create(material);
        });
      });
    }

  });
  var _default = Material;
  _exports.default = _default;
});