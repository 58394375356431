define("flaneur/pods/components/pages/landing/component", ["exports", "@glimmer/component", "ember-component-css/pod-names"], function (_exports, _component, _podNames) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  let LandingPage = (_dec = Ember.inject.service, _dec2 = Ember._tracked, _dec3 = Ember._tracked, _dec4 = Ember._tracked, (_class = class LandingPage extends _component.default {
    constructor() {
      super(...arguments);

      _initializerDefineProperty(this, "intl", _descriptor, this);

      _initializerDefineProperty(this, "selectedRange", _descriptor2, this);

      _initializerDefineProperty(this, "numberOfProducts", _descriptor3, this);

      _initializerDefineProperty(this, "selectedCurrency", _descriptor4, this);

      this.currencyOptions = [{
        currency: 'EUR',
        text: 'EUR - Euro'
      }, {
        currency: 'GBP',
        text: 'GBP - British pounds'
      }];
      this.gmvPerProduct = {
        EUR: {
          0: 1240,
          1: 1312,
          2: 1369,
          3: 2384
        },
        GBP: {
          0: 1032,
          1: 1143,
          2: 1386,
          3: 2301
        }
      };
      this.numberOfProducts = 300;
      this.selectedCurrency = this.currencyOptions[0];
      this.selectedRange = this.rangeOptions[0];
    }

    get styleNamespace() {
      return _podNames.default['pages/landing'];
    }

    get rangeOptions() {
      return [{
        level: 0,
        range: `Less than ${this.formatNumber(50)}`
      }, {
        level: 1,
        range: `${this.formatNumber(50)} - ${this.formatNumber(75)}`
      }, {
        level: 2,
        range: `${this.formatNumber(75)} - ${this.formatNumber(100)}`
      }, {
        level: 3,
        range: `Over ${this.formatNumber(100)}`
      }];
    }

    get earnings() {
      const {
        level
      } = this.selectedRange;
      const {
        currency
      } = this.selectedCurrency;
      const gmv = this.gmvPerProduct[currency][level];
      return gmv * this.numberOfProducts * 12;
    }

    formatNumber(number) {
      return this.intl.formatNumber(number, {
        style: 'currency',
        currency: this.selectedCurrency.currency,
        minimumFractionDigits: 0
      });
    }

  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "intl", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "selectedRange", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "numberOfProducts", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "selectedCurrency", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  })), _class));
  _exports.default = LandingPage;
});