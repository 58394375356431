define("flaneur/pods/components/date-select/component", ["exports", "@glimmer/component", "ember-component-css/pod-names", "date-fns"], function (_exports, _component, _podNames, _dateFns) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _dec5, _class, _descriptor, _descriptor2, _descriptor3;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  const MONTHS_IN_YEAR = 12;
  const DAYS_IN_MONTH = 31;
  const YEARS_TO_DISPLAY = 3;
  let DateSelectComponent = (_dec = Ember._tracked, _dec2 = Ember._tracked, _dec3 = Ember._tracked, _dec4 = Ember._action, _dec5 = Ember._action, (_class = class DateSelectComponent extends _component.default {
    constructor() {
      super(...arguments);

      _initializerDefineProperty(this, "day", _descriptor, this);

      _initializerDefineProperty(this, "month", _descriptor2, this);

      _initializerDefineProperty(this, "year", _descriptor3, this);

      _defineProperty(this, "generateArrayOfNumbers", (limiter, startPoint = 0) => {
        let arrayOfNumbers = [];

        for (let i = 0; i < limiter; i++) {
          arrayOfNumbers = [...arrayOfNumbers, startPoint + i];
        }

        return arrayOfNumbers;
      });

      if (Ember.isEmpty(this.args.initialDate)) {
        return;
      }

      const initialDate = (0, _dateFns.parseISO)(this.args.initialDate);
      const day = (0, _dateFns.format)(initialDate, 'd');
      const month = (0, _dateFns.format)(initialDate, 'MMMM');
      const year = (0, _dateFns.format)(initialDate, 'yyyy');
      this.day = parseInt(day, 10);
      this.month = month;
      this.year = parseInt(year, 10);
    }

    get styleNamespace() {
      return _podNames.default['date-select'];
    }

    get years() {
      const year = parseInt((0, _dateFns.format)(new Date(), 'yyyy'), 10);
      return this.generateArrayOfNumbers(YEARS_TO_DISPLAY, year);
    }

    get months() {
      return this.generateArrayOfNumbers(MONTHS_IN_YEAR, 1).map(month => (0, _dateFns.format)(new Date(0, month), 'MMMM'));
    }

    get days() {
      return this.generateArrayOfNumbers(DAYS_IN_MONTH, 1);
    }

    changeDate(name, event) {
      const value = event.target.value;
      this[name] = value;
    }

    setValidDate() {
      const {
        day,
        month,
        year
      } = this;

      if (Ember.isEmpty(day) || Ember.isEmpty(month) || Ember.isEmpty(year)) {
        return;
      }

      const formattedDay = day <= 9 ? `0${day}` : day;
      const formattedMonth = (0, _dateFns.format)((0, _dateFns.parse)(month, 'LLLL', new Date()), 'LL');
      const formattedDate = `${year}-${formattedMonth}-${formattedDay}T00:00.000Z`;
      const date = (0, _dateFns.parseISO)(formattedDate);
      return this.args.setDate(date);
    }

  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "day", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "month", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "year", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "changeDate", [_dec4], Object.getOwnPropertyDescriptor(_class.prototype, "changeDate"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "setValidDate", [_dec5], Object.getOwnPropertyDescriptor(_class.prototype, "setValidDate"), _class.prototype)), _class));
  _exports.default = DateSelectComponent;
});