define("flaneur/pods/components/form-flows/input/component", ["exports", "flaneur/util/default-to"], function (_exports, _defaultTo) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    classNames: ['form-input'],
    type: 'text',
    value: '',
    min: undefined,
    max: undefined,
    maxlength: undefined,
    disabled: (0, _defaultTo.default)(false),
    inputComponent: (0, _defaultTo.default)('form-flows/input/input'),

    onInput() {},

    // Create a unique id for the input, so we can associate to the label
    inputId: Ember.computed('elementId', function () {
      return `${this.elementId}-input`;
    }),
    hasError: Ember.computed('error', function () {
      return Boolean(this.error);
    }),
    error: Ember.computed('errors.@each.type', 'errors.type', function () {
      const errors = this.errors;
      return Array.isArray(errors) ? errors[0] : errors;
    }),
    inputClass: Ember.computed('error', 'disabled', function () {
      const classNames = 'form-control form-input__text';

      if (this.error) {
        return `${classNames} input--error`;
      }

      if (this.disabled) {
        return `${classNames} disabled`;
      }

      return classNames;
    }),
    actions: {
      onInput(event) {
        // "price" and "number" types need to be casted to numbers
        let value = event.target.value;

        if (this.type === 'price') {
          value = Math.round(Number(event.target.value) * 100);
        } else if (this.type === 'number') {
          value = Number(event.target.value);
        }

        this.onInput(value);
        this.set('value', value);
      }

    }
  });

  _exports.default = _default;
});