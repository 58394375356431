define("flaneur/pods/components/pages/users/new/component", ["exports", "flaneur/enums/retail-role-type", "flaneur/enums/retail-permission-type"], function (_exports, _retailRoleType, _retailPermissionType) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    notify: Ember.inject.service(),
    // Public API
    user: null,

    onAddUser() {},

    // Private API
    classNames: ['page'],
    enableValidations: false,
    retailRoles: ['admin', 'user'],
    retailRolesDescriptions: {
      admin: 'Access to everything in the Retail Panel',
      user: 'Access to everything in the Retail Panel except T&Cs and Statements'
    },
    actions: {
      onChangeUserRetailRole(user, retailRole) {
        const permissions = retailRole === _retailRoleType.default.ADMIN ? ['retail_management'] : [];
        const retailPermissions = retailRole === _retailRoleType.default.ADMIN ? [_retailPermissionType.default.ALL] : [];
        user.setProperties({
          permissions,
          retail_permissions: retailPermissions
        });
      },

      async addUser() {
        const {
          validations: {
            isInvalid
          }
        } = await this.user.validate({
          on: ['email']
        });

        if (isInvalid) {
          this.set('enableValidations', true);
          this.notify.show('error', 'Please add a valid email address and try again.');
          return;
        }

        return this.onAddUser(this.user);
      }

    }
  });

  _exports.default = _default;
});