define("flaneur/pods/brand/boutiques/boutique/curate/brands/brand/route", ["exports", "flaneur/models/curate/brand", "flaneur/models/curate/product", "flaneur/models/curate/order", "flaneur/models/curate/category", "tracked-maps-and-sets"], function (_exports, _brand, _product, _order, _category, _trackedMapsAndSets) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  let BrandBoutiquesBoutiqueCurateBrandsBrandRoute = (_dec = Ember.inject.service, _dec2 = Ember.inject.service, _dec3 = Ember.inject.service, _dec4 = Ember.inject.service, (_class = class BrandBoutiquesBoutiqueCurateBrandsBrandRoute extends Ember.Route {
    constructor() {
      super(...arguments);

      _initializerDefineProperty(this, "brand", _descriptor, this);

      _initializerDefineProperty(this, "curateBasket", _descriptor2, this);

      _initializerDefineProperty(this, "router", _descriptor3, this);

      _initializerDefineProperty(this, "session", _descriptor4, this);

      _defineProperty(this, "totalProductsCount", new _trackedMapsAndSets.TrackedMap());

      _defineProperty(this, "queryParams", {
        query: {
          refreshModel: true
        },
        page: {
          refreshModel: true
        },
        limit: {
          refreshModel: true
        },
        category: {
          refreshModel: true
        }
      });

      this.router.on('routeWillChange', transition => {
        var _transition$from, _transition$from$attr, _transition$from$attr2;

        const brandRoute = 'brand.boutiques.boutique.curate.brands.brand';
        const staysOnBrand = transition.to.find(route => route.name.includes(brandRoute));
        const brandName = (_transition$from = transition.from) === null || _transition$from === void 0 ? void 0 : (_transition$from$attr = _transition$from.attributes) === null || _transition$from$attr === void 0 ? void 0 : (_transition$from$attr2 = _transition$from$attr.brand) === null || _transition$from$attr2 === void 0 ? void 0 : _transition$from$attr2.name;

        if (staysOnBrand || !brandName || this.curateBasket.isEmpty) {
          return;
        }

        this.notify.show('success', `<strong>We saved your items</strong> for a future order with ${brandName}.`);
      });
    }

    async model({
      slug,
      query,
      category,
      page,
      limit
    }) {
      const {
        brands: [brand]
      } = await _brand.default.find({
        slug
      });

      if (Ember.isEmpty(brand)) {
        return this.redirectToIndex();
      }

      const {
        isAdmin
      } = this.session.user;
      const isAvailable = brand.isPublished || brand.isPreview;
      const isPreviewVisitedByNonAdmin = brand.isPreview && !isAdmin;

      if (!isAvailable || isPreviewVisitedByNonAdmin) {
        return this.redirectToIndex();
      }

      this.curateBasket.setup(brand);
      const queryParams = {
        brand: brand.id,
        sort: '-created_at',
        limit,
        page
      };
      Object.assign(queryParams, query ? {
        free_text_search: query
      } : {});
      Object.assign(queryParams, category ? {
        category: category
      } : {});
      const [{
        curateProducts: products,
        meta = {}
      }, orders, categories] = await Promise.all([_product.default.find(queryParams), _order.default.find({
        brands: [brand.id],
        boutique: this.brand.currentBoutique._id
      }), _category.default.findHierarchy({
        id: category,
        brand: brand.id
      })]);
      products.forEach(product => product.brand = brand);

      if (!orders.length) {
        var _brand$minimum_order_;

        brand.minimum_order_value.value = (_brand$minimum_order_ = brand.minimum_order_value.first_order_value) !== null && _brand$minimum_order_ !== void 0 ? _brand$minimum_order_ : brand.minimum_order_value.value;
      }

      const allProductsCount = await this.getTotalProductsCount(brand, Ember.isPresent(query) || Ember.isPresent(category), meta.total);
      return {
        brand,
        products,
        allProductsCount,
        meta,
        orders,
        categories,
        queryParams: {
          query,
          page,
          limit,
          category
        }
      };
    }

    redirectToIndex() {
      this.notify.show('error', "This brand's no longer available.");
      return this.router.transitionTo('brand.boutiques.boutique.curate.index');
    }

    async getTotalProductsCount(brand, hasFiltering, currentTotal) {
      const totalProducts = this.totalProductsCount.get(brand.slug);

      if (Ember.isPresent(totalProducts)) {
        return totalProducts;
      }

      if (!hasFiltering) {
        this.totalProductsCount.set(brand.slug, currentTotal);
        return currentTotal;
      }

      const {
        meta: {
          total
        }
      } = await _product.default.find({
        brand: brand.id,
        limit: 1
      });
      this.totalProductsCount.set(brand.slug, totalProducts);
      return total;
    }

    resetController(controller) {
      controller.set('preserveScrollPosition', false);
    }

  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "brand", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "curateBasket", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "router", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "session", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  })), _class));
  _exports.default = BrandBoutiquesBoutiqueCurateBrandsBrandRoute;
});