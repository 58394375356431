define("flaneur/pods/components/pages/shipments/view/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "2Ukz803a",
    "block": "{\"symbols\":[\"@model\"],\"statements\":[[10,\"div\"],[14,0,\"page\"],[12],[2,\"\\n  \"],[10,\"div\"],[14,0,\"heading\"],[12],[2,\"\\n    \"],[10,\"div\"],[14,0,\"heading__back\"],[12],[2,\"\\n      \"],[8,\"link-to\",[],[[\"@route\",\"@models\"],[[30,[36,0],[[32,0,[\"routePrefix\"]],\".index\"],null],[32,0,[\"routeModels\"]]]],[[\"default\"],[{\"statements\":[[2,\"\\n        \"],[8,\"fa-icon\",[[24,0,\"fa\"]],[[\"@icon\",\"@prefix\"],[\"long-arrow-left\",\"far\"]],null],[2,\"Back to shipments\\n      \"]],\"parameters\":[]}]]],[2,\"\\n    \"],[13],[2,\"\\n\\n    \"],[10,\"div\"],[14,0,\"heading__title\"],[12],[2,\"\\n      \"],[10,\"h1\"],[12],[2,\"Order \"],[1,[32,1,[\"shipment\",\"ordernumber\"]]],[13],[2,\"\\n    \"],[13],[2,\"\\n  \"],[13],[2,\"\\n\\n  \"],[8,\"form-flows/shipment/view-details\",[],[[\"@routePrefix\",\"@routeModels\",\"@shipment\",\"@options\"],[[32,0,[\"routePrefix\"]],[32,0,[\"routeModels\"]],[32,1,[\"shipment\"]],[32,1,[\"options\"]]]],null],[2,\"\\n\"],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"concat\"]}",
    "moduleName": "flaneur/pods/components/pages/shipments/view/template.hbs"
  });

  _exports.default = _default;
});