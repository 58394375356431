define("flaneur/enums/shipping-provider", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const shippingProvider = {
    UPS_ACCESS_POINT: 'ups_access_point',
    DECIDED_AT_COURIER_BOOKING: 'decided_at_courier_booking',
    EASYPOST: 'easypost',
    P2G: 'p2g',
    OTHER: 'other'
  };
  var _default = shippingProvider;
  _exports.default = _default;
});