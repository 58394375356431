define("flaneur/pods/components/form-flows/shipment/book-courier/component", ["exports", "flaneur/enums/incoterm"], function (_exports, _incoterm) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    brand: Ember.inject.service(),
    features: Ember.inject.service(),
    tagName: 'section',
    classNames: ['book-courier'],
    isEditing: true,
    showShipmentRate: true,
    itemPriceField: 'price',
    boutique: Ember.computed.alias('brand.currentBoutique'),
    countries: Ember.computed.alias('options.countries'),
    subStep: Ember.computed.alias('options.subStep'),
    package: Ember.computed.alias('shipment.package'),
    delivery: Ember.computed.alias('shipment.delivery_address'),
    collection: Ember.computed.alias('shipment.collection_address'),
    orderReference: Ember.computed.or('shipment.order_id', 'shipment.ordernumber'),
    isIncotermDdp: Ember.computed('shipment.incoterm', function () {
      return this.shipment.incoterm === _incoterm.default.DDP;
    }),
    incoterm: Ember.computed('isIncotermDdp', function () {
      return this.isIncotermDdp ? 'Bill to me (DDP - Deliver Duties Paid)' : 'Bill to the customer (DDU - Deliver Duties Unpaid)';
    }),
    insuranceValue: Ember.computed('isEditing', 'shipment.insurance.original', function () {
      return this.isEditing ? this.shipment.insurance : this.shipment.insurance.original;
    }),
    extraShippingRatesDocumentUrl: Ember.computed.readOnly('options.boutique.addressCountryDocuments.shipping_rates.url'),
    extraShippingSurchargesDocumentUrl: Ember.computed.readOnly('options.boutique.addressCountryDocuments.shipping_surcharges.url'),
    changeButtonClassName: Ember.computed('isEditing', function () {
      return this.isEditing ? 'book-courier__go-to-substep' : 'book-courier__go-to-substep--hidden';
    }),
    collectionCountry: Ember.computed('collection.country', 'countries', function () {
      return this.countries.find(country => country.iso_code === this.collection.country);
    }),
    deliveryCountry: Ember.computed('countries', 'delivery.country', function () {
      return this.countries.find(country => country.iso_code === this.delivery.country);
    }),
    totalPrice: Ember.computed('itemPriceField', 'shipment.{items,price,priceExclVat,priceInclVat}', function () {
      return this.shipment.items.reduce((total, item) => {
        var _item$this$itemPriceF;

        const amount = (_item$this$itemPriceF = item[this.itemPriceField]) !== null && _item$this$itemPriceF !== void 0 ? _item$this$itemPriceF : 0;
        return parseInt(amount, 10) * item.quantity + total;
      }, 0);
    }),
    actions: {
      goToStep(step, substep = '') {
        return this.goToStep(step, substep);
      }

    }
  });

  _exports.default = _default;
});