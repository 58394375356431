define("flaneur/pods/login/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "exlDQzOi",
    "block": "{\"symbols\":[],\"statements\":[[8,\"nav-bar-alert\",[],[[],[]],null],[2,\"\\n\\n\"],[8,\"pages/login-page\",[],[[],[]],null]],\"hasEval\":false,\"upvars\":[]}",
    "moduleName": "flaneur/pods/login/template.hbs"
  });

  _exports.default = _default;
});