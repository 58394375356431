define("flaneur/pods/components/form-flows/product/component", ["exports", "flaneur/pods/components/form-flows/_base/component", "flaneur/models/category", "flaneur/util/default-to"], function (_exports, _component, _category, _defaultTo) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _component.default.extend({
    brand: Ember.inject.service(),
    media: Ember.inject.service(),
    // Public API
    currentStepIndex: 0,
    currentStepName: 'description-details',
    previousRoute: 'brand.boutiques.boutique.products.index',

    imagesChanged() {},

    // Private API
    categories: [],
    curationNotes: (0, _defaultTo.default)({}),
    classNames: ['form-flows--product'],
    baseComponentPath: 'form-flows/product',
    isLastStep: Ember.computed.equal('currentStepName', 'summary-details'),
    steps: [{
      step: 'description-details'
    }, {
      step: 'package-details'
    }, {
      step: 'product-options'
    }, {
      step: 'category-details'
    }, {
      step: 'gallery-details'
    }, {
      step: 'gallery-order'
    }, {
      step: 'summary-details'
    }],

    init() {
      this._super(...arguments);

      const container = document.getElementById('outlet-container');
      Ember.run.next(() => {
        const scrollable = container && !this.media.isLarge ? container : window;
        scrollable.scrollTo({
          top: 0,
          behavior: 'auto'
        });
      });

      _category.default.find().then(categories => {
        this.set('categories', categories);
      });
    },

    _setThumbnail(imgs) {
      const [thumbnail] = imgs;
      this.set('product.thumbnail', thumbnail);
    },

    scrollToStep(step) {
      const container = document.getElementById('outlet-container');
      const stepElement = this.element.querySelector(`#${step}`); // Desktop and tablet/mobile have a different offset top because of the nav
      // <main> is position:relative, which causes the scroll position
      // to be incorrect. In order to correct this, we need to grab the offset
      // of the parent <main> element.
      // https://developer.mozilla.org/en-US/docs/Web/API/HTMLElement/offsetTop

      const offsetTop = !this.media.isLarge ? stepElement.offsetTop : stepElement.offsetTop + stepElement.offsetParent.offsetTop;
      Ember.run.next(() => {
        const scrollable = container && !this.media.isLarge ? container : window;
        scrollable.scrollTo({
          top: offsetTop,
          behavior: 'smooth'
        });
      });
    },

    actions: {
      goToStep(step) {
        this.set('currentStepName', step);
        this.scrollToStep(step);
      },

      imageEdited(img, index) {
        const images = this.product.images;
        images.replace(index, 1, [img]);
        this.set('product.images', images);

        if (index === 0) {
          this._setThumbnail(images);
        }
      },

      deleteImage(img) {
        const imageIndex = this.product.images.findIndex(({
          url
        }) => url === img.url);
        const images = this.product.images.toArray();
        images.splice(imageIndex, 1);
        this.set('product.images', images);

        if (imageIndex === 0) {
          this._setThumbnail(images);
        }
      }

    }
  });

  _exports.default = _default;
});