define("flaneur/pods/components/progress-bar/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "Zw84ULQi",
    "block": "{\"symbols\":[\"step\",\"index\",\"@steps\",\"@theme\"],\"statements\":[[10,\"div\"],[15,0,[31,[\"progress-bar \",[32,0,[\"styleNamespace\"]],\" progress-bar--\",[32,4]]]],[15,5,[32,0,[\"computedStyle\"]]],[12],[2,\"\\n\"],[6,[37,4],[[30,[36,3],[[30,[36,3],[[32,3]],null]],null]],[[\"key\"],[\"key\"]],[[\"default\"],[{\"statements\":[[2,\"    \"],[10,\"div\"],[15,0,[31,[\"step \",[30,[36,2],[[30,[36,1],[[32,0,[\"currentStep\"]],[32,2]],null],\"step--current\"],null],\" \",[30,[36,2],[[32,1,[\"complete\"]],\"step--complete\"],null],\" \",[30,[36,2],[[30,[36,1],[[32,3,[\"length\"]],[30,[36,0],[[32,2]],null]],null],\"step--last\"],null]]]],[12],[2,\"\\n      \"],[10,\"div\"],[14,0,\"step__icon\"],[12],[2,\"\\n        \"],[10,\"div\"],[14,0,\"step__circle\"],[12],[2,\"\\n\"],[6,[37,2],[[32,1,[\"complete\"]]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"            \"],[8,\"fa-icon\",[],[[\"@icon\",\"@prefix\"],[\"check\",\"far\"]],null],[2,\"\\n\"]],\"parameters\":[]},{\"statements\":[[2,\"            \"],[1,[30,[36,0],[[32,2]],null]],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"        \"],[13],[2,\"\\n        \"],[10,\"div\"],[14,0,\"step__label\"],[12],[1,[32,1,[\"label\"]]],[13],[2,\"\\n      \"],[13],[2,\"\\n    \"],[13],[2,\"\\n\"]],\"parameters\":[1,2]}]]],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"inc\",\"eq\",\"if\",\"-track-array\",\"each\"]}",
    "moduleName": "flaneur/pods/components/progress-bar/template.hbs"
  });

  _exports.default = _default;
});