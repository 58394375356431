define("flaneur/pods/components/confirm-order-item-warning/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "lsA9uAvv",
    "block": "{\"symbols\":[],\"statements\":[[10,\"div\"],[12],[2,\"\\n  \"],[10,\"div\"],[14,0,\"modal-header\"],[12],[2,\"\\n    \"],[10,\"div\"],[12],[2,\"\\n      \"],[8,\"fa-icon\",[[24,0,\"fa\"]],[[\"@icon\",\"@prefix\"],[\"exclamation\",\"far\"]],null],[2,\"\\n    \"],[13],[2,\"\\n  \"],[13],[2,\"\\n  \"],[10,\"div\"],[14,0,\"modal-container\"],[12],[2,\"\\n    \"],[10,\"span\"],[12],[2,\"Order #\"],[1,[35,0,[\"ordernumber\"]]],[13],[2,\"\\n    \"],[10,\"span\"],[12],[2,\"Placed at \"],[1,[30,[36,1],[[35,0,[\"created_at\"]],\"p\"],null]],[13],[2,\"\\n    \"],[10,\"p\"],[12],[2,\"\\n      This order came in when your shop is closed. By confirming this order today, you must prepare it (print and package) to be ready for pickup by the courier on your next open day (\"],[1,[30,[36,1],[[35,2],\"PPP\"],null]],[2,\").\\n    \"],[13],[2,\"\\n  \"],[13],[2,\"\\n\\n  \"],[10,\"div\"],[14,0,\"container-buttons\"],[12],[2,\"\\n    \"],[8,\"button\",[],[[\"@theme\",\"@onClick\"],[\"secondary\",[30,[36,4],[[32,0],[35,3]],null]]],[[\"default\"],[{\"statements\":[[2,\"Cancel\"]],\"parameters\":[]}]]],[2,\"\\n    \"],[8,\"button\",[],[[\"@onClick\"],[[30,[36,4],[[32,0],[35,5]],null]]],[[\"default\"],[{\"statements\":[[2,\"Confirm\"]],\"parameters\":[]}]]],[2,\"\\n  \"],[13],[2,\"\\n\"],[13]],\"hasEval\":false,\"upvars\":[\"order\",\"date-format\",\"nextOpenDayDate\",\"onCancel\",\"action\",\"onConfirm\"]}",
    "moduleName": "flaneur/pods/components/confirm-order-item-warning/template.hbs"
  });

  _exports.default = _default;
});