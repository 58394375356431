define("flaneur/services/notify", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const DISMISS_ANIMATION_DURATION_MS = 250;

  var _default = Ember.Service.extend({
    notifications: Ember.A([]),

    /**
     * Show message for the specified time
     * @param  {<String>'success'|'danger'} Type
     * @param  {String} message
     * @param  {Number} timeout Optional
     */
    show(type, message, timeout = 5000) {
      var notification = {
        type: type,
        message: message
      };
      this.notifications.addObject(notification);

      if (timeout) {
        setTimeout(() => this.hide(notification), Ember.testing ? 100 : timeout);
      }
    },

    hideErrors() {
      this.hideNotifications('error');
    },

    hideNotifications(type) {
      this.notifications.forEach(notification => {
        if (notification.type !== type) {
          return;
        }

        this.hide(notification);
      });
    },

    /**
     * Hide message
     * @param  {notification} message
     */
    hide(message) {
      Ember.set(message, 'dismiss', true);
      Ember.run.later(() => this.notifications.removeObject(message), DISMISS_ANIMATION_DURATION_MS);
    }

  });

  _exports.default = _default;
});