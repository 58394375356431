define("flaneur/pods/brand/boutiques/boutique/products/index/route", ["exports", "flaneur/util/escape-regex", "flaneur/models/manufacturer", "flaneur/utils/pipeline-requirements", "flaneur/models/product", "flaneur/models/boutique-restock-information", "flaneur/enums/boutique-restock-information-status"], function (_exports, _escapeRegex, _manufacturer, _pipelineRequirements, _product, _boutiqueRestockInformation, _boutiqueRestockInformationStatus) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); enumerableOnly && (symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols); } return keys; }

  function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = null != arguments[i] ? arguments[i] : {}; i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { _defineProperty(target, key, source[key]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } return target; }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  var _default = Ember.Route.extend({
    brand: Ember.inject.service(),
    queryParams: {
      archived: {
        refreshModel: true
      },
      bulkOperationId: {
        refreshModel: true
      },
      limit: {
        refreshModel: true
      },
      madeStatus: {
        refreshModel: true
      },
      page: {
        refreshModel: true
      },
      q: {
        refreshModel: true
      },
      sales: {
        refreshModel: true
      },
      sort: {
        refreshModel: true
      },
      stage: {
        refreshModel: true
      },
      stock: {
        refreshModel: true
      },
      stockLeadDateFrom: {
        refreshModel: true
      },
      stockLeadDateTo: {
        refreshModel: true
      }
    },

    async model(params) {
      Object.assign(params, {
        stock: this.parseStockQueryParameter(params.stock)
      });
      const {
        archived,
        limit,
        q,
        sales,
        sort,
        stage,
        stock,
        bulkOperationId,
        madeStatus
      } = params;
      const query = await this.buildQuery(params, {
        brand: this.brand,
        boutique: this.brand.currentBoutique
      });
      const results = await _product.default.find(query);
      const queryParams = {
        archived,
        limit,
        query: q,
        sales,
        sort,
        stage,
        stock,
        bulkOperationId,
        'made-status': madeStatus
      };
      this.attachBoutiqueRestockInformationToProducts(results.products);
      return {
        products: results.products,
        meta: _objectSpread({
          page: results.meta ? results.meta.page : 1,
          total: results.meta ? results.meta.total : 0,
          pageTotal: results.meta ? results.meta.page_total : 1
        }, queryParams)
      };
    },

    async buildQuery(params, opts = {
      brand: {},
      boutique: {}
    }) {
      const limit = params.limit || 20;
      const sort = params.sort || '-updated_at';
      const archived = params.archived || false;
      const boutiqueId = opts.brand.currentBoutique._id;
      const query = {
        archived,
        brand: opts.brand._id,
        limit,
        sort
      };
      ['page', 'sort', 'limit'].forEach(key => {
        if (Ember.isPresent(params[key])) {
          query[key] = params[key];
        }
      });
      const useDeprecatedParams = false;
      const extraParams = useDeprecatedParams ? await this._getDeprecatedParams(params, boutiqueId) : this._getParams(params);
      Ember.assign(query, extraParams);
      return query;
    },

    _getParams(queryParameters) {
      const params = {
        use_new_filter: true,
        populate_standard_manufacturer: true
      };
      Ember.assign(params, this._getStageQuery(queryParameters));
      Ember.assign(params, this._getStockQuery(queryParameters));
      Ember.assign(params, this._getStockLeadDateQuery(queryParameters));
      Ember.assign(params, this._getFreeTextSearchQuery(queryParameters));
      Ember.assign(params, this._getSalesQuery(queryParameters));
      Ember.assign(params, this._getIdsQuery(queryParameters));
      Ember.assign(params, this._getStockLinesChannelsQuery(queryParameters));
      return params;
    },

    _getStockLinesChannelsQuery({
      madeStatus
    }) {
      if (Ember.isEmpty(madeStatus)) {
        return {};
      }

      if (madeStatus === 'selected') {
        return {
          stock_lines_channels: ['madecom']
        };
      }

      if (madeStatus === 'not-selected') {
        return {
          stock_lines_channels: ['trouva']
        };
      }

      return {};
    },

    _getStageQuery({
      stage
    }) {
      if (Ember.isEmpty(stage)) {
        return {};
      }

      if (stage === 'pending_updates') {
        return {
          updates_required: true
        };
      }

      if (stage === 'unlisted') {
        return {
          unlisted: true
        };
      }

      if (stage === 'total_live') {
        return {
          published: true
        };
      }

      return {};
    },

    _getStockQuery({
      stock
    }) {
      if (Ember.isEmpty(stock)) {
        return {};
      }

      if (stock === 'restocking') {
        return {
          boutique_restock_information_status: 'restocking'
        };
      }

      if (stock === true) {
        return {
          stock_lines_stock_status: 'in'
        };
      }

      if (stock === false) {
        return {
          stock_lines_stock_status: 'out'
        };
      }

      return {};
    },

    _getStockLeadDateQuery({
      stock,
      stockLeadDateFrom,
      stockLeadDateTo
    }) {
      const query = {};

      if (Ember.isEmpty(stock) || stock !== 'restocking') {
        return query;
      }

      if (Ember.isPresent(stockLeadDateFrom)) {
        query.boutique_restock_information_lead_date_from = stockLeadDateFrom;
      }

      if (Ember.isPresent(stockLeadDateTo)) {
        query.boutique_restock_information_lead_date_to = stockLeadDateTo;
      }

      return query;
    },

    _getFreeTextSearchQuery({
      q
    }) {
      return Ember.isEmpty(q) ? {} : {
        free_text_search: q
      };
    },

    _getSalesQuery({
      sales
    }) {
      return Ember.isEmpty(sales) ? {} : {
        sales
      };
    },

    _getIdsQuery({
      bulkOperationId
    }) {
      const {
        bulk_upload_history: uploadHistory
      } = this.brand.currentBoutique;

      if (Ember.isEmpty(bulkOperationId)) {
        return {};
      }

      const upload = uploadHistory.findBy('bulk_operation_id', bulkOperationId);

      if (Ember.isEmpty(upload)) {
        return {
          ids: []
        };
      }

      return {
        ids: upload.products
      };
    },

    async _getDeprecatedParams(queryParameters, boutiqueId) {
      const params = {
        $and: [],
        populate: 'standard_manufacturer'
      };
      let requirements = null;

      if (Ember.isPresent(queryParameters.stage)) {
        if (queryParameters.stage === 'pending_updates') {
          requirements = _pipelineRequirements.default.pendingUpdates;
        }

        if (queryParameters.stage === 'unlisted') {
          requirements = _pipelineRequirements.default.unlisted;
        }

        if (queryParameters.stage === 'total_live') {
          requirements = _pipelineRequirements.default.totalLive;
        }
      }

      if (Ember.isPresent(requirements)) {
        params.$and.push(requirements);
      }

      const {
        q: freeTextSearch
      } = queryParameters;

      if (Ember.isPresent(freeTextSearch)) {
        const manufacturers = await this._searchManufacturers(freeTextSearch);
        const searchQuery = {
          $or: [{
            name: {
              $regex: freeTextSearch,
              $options: 'i'
            }
          }, {
            id: freeTextSearch
          }, {
            gtin: {
              $regex: freeTextSearch,
              $options: 'i'
            }
          }, {
            standard_manufacturer: {
              $in: manufacturers
            }
          }]
        };
        params.$and.push(searchQuery);
      }

      if (Ember.isPresent(queryParameters.sales)) {
        if (params.boutiques) {
          params.boutiques.$elemMatch.sales = queryParameters.sales;
          return;
        }

        params.boutiques = {
          $elemMatch: {
            boutique: boutiqueId,
            sales: queryParameters.sales
          }
        };
      }

      if (Ember.isPresent(queryParameters.bulkOperationId)) {
        let upload = this.brand.currentBoutique.bulk_upload_history.findBy('bulk_operation_id', queryParameters.bulkOperationId);

        if (Ember.isEmpty(upload)) {
          upload = {
            products: []
          };
        }

        params.$and.push({
          _id: {
            $in: upload.products
          }
        });
      }

      if (params.$and.length === 0) {
        delete params.$and;
      }

      return params;
    },

    _searchManufacturers(freeTextSearch) {
      if (Ember.isEmpty(freeTextSearch)) {
        return Ember.RSVP.resolve([]);
      }

      return _manufacturer.default.find({
        fields: '_id',
        $or: [{
          name: {
            $regex: (0, _escapeRegex.default)(freeTextSearch),
            $options: 'i'
          }
        }, {
          alternate_names: {
            $regex: (0, _escapeRegex.default)(freeTextSearch),
            $options: 'i'
          }
        }]
      }).then(manufacturers => manufacturers.map(({
        _id
      }) => _id));
    },

    async attachBoutiqueRestockInformationToProducts(products) {
      return _boutiqueRestockInformation.default.find({
        status: _boutiqueRestockInformationStatus.default.RESTOCKING,
        'product[$in]': products.map(({
          _id
        }) => _id)
      }).then(({
        boutiqueRestockInformation
      }) => {
        products.forEach(product => {
          const information = boutiqueRestockInformation.find(({
            product: {
              _id
            }
          }) => _id === product._id);
          product.hasRestockingInformation = Ember.isPresent(information);
        });
      });
    },

    /**
     * Stock query parameter can have a mix of booleans or
     * strings for values (e.g. true or "restocking"). In
     * these situations, ember always parses the query parameter
     * as a string. Therefore, we need to convert the strings to
     * booleans to use them throughout the app as they're supposed.
     *
     * @param {string|null} stock
     * @returns the correctly parsed stock query parameter
     */
    parseStockQueryParameter(stock) {
      if (stock === 'true') {
        return true;
      }

      if (stock === 'false') {
        return false;
      }

      return stock;
    },

    actions: {
      resetFilters() {
        const params = this.get('controller.defaults');
        const controller = this.controllerFor('brand.boutiques.boutique.products.index');

        if (Ember.isNone(params.page)) {
          params.page = 1;
        }

        controller.setProperties(params);
      }

    }
  });

  _exports.default = _default;
});