define("flaneur/pods/components/form-flows/product/package-details/component", ["exports", "ember-concurrency", "flaneur/models/country"], function (_exports, _emberConcurrency, _country) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    media: Ember.inject.service(),
    classNames: ['package-details'],
    elementId: 'package-details',
    firstStockLineDimensions: Ember.computed('product.stock_lines.length', function () {
      return this.product.stock_lines[0];
    }),
    dimensions: Ember.computed.reads('firstStockLineDimensions.dimensions'),
    care_instructions: Ember.computed.reads('firstStockLineDimensions.care_instructions'),
    hasDimensions: Ember.computed('dimensions.{height,length,width}', function () {
      return !!(this.dimensions.length && this.dimensions.width && this.dimensions.height);
    }),
    hasCareInstructions: Ember.computed('care_instructions', function () {
      return !Ember.isEmpty(this.care_instructions);
    }),
    searchCountry: (0, _emberConcurrency.task)(function* (query) {
      yield (0, _emberConcurrency.timeout)(500);
      const result = yield _country.default.find({
        weengs_name: {
          $regex: `^${query}`,
          $options: 'i'
        },
        skip_links: true,
        limit: 8
      });
      return result;
    }).restartable(),
    actions: {
      updateCareInstructions(product, value) {
        product.updateCareInstructions(value);
      },

      updateDimension(dimension, event) {
        const parsedValue = parseFloat(event.target.value);
        return this.product.updatedStockLineDimension(dimension, Number.isNaN(parsedValue) ? null : parsedValue);
      },

      updateShippingSize(data) {
        const product = this.product;
        product.setProperties(data);
      }

    }
  });

  _exports.default = _default;
});