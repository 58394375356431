define("flaneur/pods/components/reservation-support/component", ["exports", "flaneur/models/zendesk"], function (_exports, _zendesk) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const RETURN_LABEL = 'Return';

  var _default = Ember.Component.extend({
    analytics: Ember.inject.service(),
    classNames: ['reservation-support'],
    contactSupport: false,
    order: null,
    brand: null,
    showErrors: false,
    issueMessage: '',
    ticketSent: false,
    reservations: [],
    isNetwork: false,
    orderOrBookingLabel: 'order',

    onCancel() {},

    onSentTicket() {},

    issueDetailsHasError: Ember.computed.empty('issueMessage'),
    issueDetailsErrorLabel: Ember.computed('showErrors', function () {
      return this.showErrors ? '*' : '';
    }),

    createZendeskTicket() {
      return this.isNetwork ? this._createNetworkZendeskTicket() : this._createTrouvaZendeskTicket();
    },

    _createTrouvaZendeskTicket() {
      const {
        order,
        brand,
        issueMessage,
        reservations
      } = this;
      const currentBoutique = brand.get('currentBoutique');
      const state = this.get('reservationBoutique.0.status') ? `State: ${this.get('reservationBoutique.0.status')}` : RETURN_LABEL;

      const channelTag = _zendesk.default.getChannelTag(order.channel);

      const isMadeOrder = order.channel === 'madecom';

      const orderSummary = this._createOrderSummary(reservations);

      return _zendesk.default.createEnrichedTicket({
        requester_email: currentBoutique.support_email || currentBoutique.order_email || this.get('session.user.email'),
        requester_name: currentBoutique.name,
        subject: this._createSubject(state, isMadeOrder),
        tags: [`${order.ordernumber}:${currentBoutique._id}`, `${currentBoutique.address_country.iso_code}_boutique`, channelTag],
        auto: true,
        comment: {
          body: `
                    Order number: #${order.ordernumber}
                    ${isMadeOrder ? `MADE Order number: ${order.channel_specific.order_reference}` : ''}
                    Product: ${reservations.length > 0 ? orderSummary : 'Products from this order'}
                    https://retail.trouva.com/${brand._id}/boutiques/${currentBoutique._id}/orders/${this._getStatus()}?ordernumber=${order.ordernumber}
                    ----------
                    ${issueMessage || 'No message'}
                    ----------
                    Message from ${currentBoutique.name}
                `
        },
        ordernumber: order.ordernumber,
        channel_specific_order_reference: isMadeOrder ? order.channel_specific.order_reference : undefined,
        channel: order.channel,
        boutique_name: currentBoutique.name,
        boutique_email: currentBoutique.order_email,
        order_summary: orderSummary
      });
    },

    _createNetworkZendeskTicket() {
      const {
        order,
        brand,
        issueMessage
      } = this;
      const currentBoutique = brand.get('currentBoutique');
      const isMadeOrder = order.channel === 'madecom';
      return _zendesk.default.createEnrichedTicket({
        requester_email: currentBoutique.support_email || currentBoutique.order_email || this.get('session.user.email'),
        requester_name: currentBoutique.name,
        subject: `${currentBoutique.name} - Support request for QuickShip #${order.ordernumber} Action Required.`,
        tags: [`${order.ordernumber}:${currentBoutique._id}`, `${currentBoutique.address_country.iso_code}_boutique`, 'channel_network', 'trouva_shipping', 'boutique'],
        auto: true,
        comment: {
          body: `
                    Order number: #${order.ordernumber}
                    ${isMadeOrder ? `MADE Order number: ${order.channel_specific.order_reference}` : ''}
                    ${currentBoutique.name} has requested support for shipment:
                    https://retail.trouva.com/${brand._id}/boutiques/${currentBoutique._id}/shipments/${order._id}

                    ----------
                    ${issueMessage || 'No message'}
                    ----------
                `
        }
      });
    },

    _getStatus() {
      return 'return';
    },

    _createSubject(subject = RETURN_LABEL, isMadeOrder) {
      const orderNumber = this.ordernumber ? this.ordernumber : this.order.ordernumber;
      return isMadeOrder ? `${subject} - ${this.order.channel_specific.order_reference} - Products from #${orderNumber}` : `${subject} - Products from #${orderNumber}`;
    },

    _createOrderSummary(reservations) {
      return reservations.map(reservation => `${reservation.product.name} X ${reservation.quantity}`).join('\n');
    },

    actions: {
      onCancel() {
        return this.onCancel();
      },

      createTicket() {
        const ticketSent = this.ticketSent;

        if (ticketSent) {
          this.set('ticketSent', false);
        }

        if (this.issueDetailsHasError) {
          return this.set('showErrors', true);
        }

        return this.createZendeskTicket().then(() => {
          this.set('ticketSent', true);
          this.onSentTicket(true);
        });
      }

    }
  });

  _exports.default = _default;
});