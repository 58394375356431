define("flaneur/pods/brand/controller", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    media: Ember.inject.service(),
    router: Ember.inject.service(),
    showSidebar: Ember.computed('media.isLarge', 'router.currentRouteName', function () {
      const {
        currentRouteName
      } = this.router;
      const isCurateAfterSigningTerms = currentRouteName.startsWith('brand.boutiques.boutique.curate') && currentRouteName !== 'brand.boutiques.boutique.curate.terms';
      const routeWithNoSidebar = ['brand.boutiques.boutique.receipt.order', 'brand.boutiques.boutique.welcome'].includes(currentRouteName) || isCurateAfterSigningTerms;
      return routeWithNoSidebar ? false : !this.media.isLarge;
    })
  });

  _exports.default = _default;
});