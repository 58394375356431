define("flaneur/services/curate-basket", ["exports", "flaneur/models/curate/order", "ember-cp-validations"], function (_exports, _order, _emberCpValidations) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _class, _descriptor, _descriptor2, _dec3, _dec4, _dec5, _dec6, _dec7, _dec8, _dec9, _dec10, _dec11, _dec12, _class3, _descriptor3, _descriptor4, _descriptor5, _descriptor6, _descriptor7;

  function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); enumerableOnly && (symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols); } return keys; }

  function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = null != arguments[i] ? arguments[i] : {}; i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { _defineProperty(target, key, source[key]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } return target; }

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  const ProductValidations = (0, _emberCpValidations.buildValidations)({
    quantity: (0, _emberCpValidations.validator)('number', {
      gte: Ember.computed.readOnly('model.minimumOrderQuantity'),
      allowNone: false,
      message: 'This product has a minimum quantity of {gte}'
    })
  }, {
    disabled: Ember.computed.readOnly('model.disableValidations')
  });
  let ProductWithValidations = (_dec = Ember._tracked, _dec2 = Ember._tracked, (_class = class ProductWithValidations extends Ember.Object.extend(ProductValidations) {
    constructor(...args) {
      super(...args);

      _initializerDefineProperty(this, "quantity", _descriptor, this);

      _initializerDefineProperty(this, "disableValidations", _descriptor2, this);
    }

  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "quantity", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "disableValidations", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return true;
    }
  })), _class));
  let CurateBasket = (_dec3 = Ember.inject.service('brand'), _dec4 = Ember._tracked, _dec5 = Ember._tracked, _dec6 = Ember._tracked, _dec7 = Ember._tracked, _dec8 = Ember.computed('order.reservations.{[],@each.quantity}'), _dec9 = Ember.computed('order.currency', 'order.reservations.{[],@each.quantity}'), _dec10 = Ember._action, _dec11 = Ember._action, _dec12 = Ember._action, (_class3 = class CurateBasket extends Ember.Service {
    constructor(...args) {
      super(...args);

      _initializerDefineProperty(this, "currentBrand", _descriptor3, this);

      _initializerDefineProperty(this, "orders", _descriptor4, this);

      _initializerDefineProperty(this, "order", _descriptor5, this);

      _initializerDefineProperty(this, "isToastVisible", _descriptor6, this);

      _initializerDefineProperty(this, "hideToastTimeoutId", _descriptor7, this);
    }

    get totalUnits() {
      return this.order.reservations.reduce((units, {
        quantity
      }) => units + quantity, 0);
    }

    get totalPrice() {
      const amount = this.order.reservations.reduce((total, product) => total + product.quantity * product.selectedStockLine.wholesalePrice.amount, 0);
      return {
        amount,
        currency: this.order.currency
      };
    }

    get isEmpty() {
      return this.order.reservations.length === 0;
    }

    getOrderByBrand(brand) {
      return this.orders.get(brand.id);
    }

    showToast({
      hideAfter = 5000
    } = {}) {
      clearTimeout(this.hideToastTimeoutId);
      this.isToastVisible = true;
      const hideToastTimeoutId = setTimeout(() => {
        this.isToastVisible = false;
      }, Ember.testing ? 1 : hideAfter);
      this.hideToastTimeoutId = hideToastTimeoutId;
    }

    async addProduct(product, selectedStockLineSku, quantity) {
      const productWithValidations = ProductWithValidations.create(Ember.getOwner(this).ownerInjection(), _objectSpread(_objectSpread({}, product), {}, {
        quantity,
        selectedStockLine: product.stockLines.find(({
          sku
        }) => sku === selectedStockLineSku)
      }));

      if (!productWithValidations) {
        return;
      }

      Ember.set(productWithValidations, 'disableValidations', false);
      const {
        validations: {
          isInvalid
        }
      } = await productWithValidations.validate();

      if (isInvalid) {
        return;
      }

      Ember.set(this, 'order.reservations', [productWithValidations, ...this.order.reservations]);
      this.showToast();
    }

    clearOrder(brand = {
      currency: 'GBP'
    }) {
      Ember.set(this, 'order', null);
      this.orders.delete(brand.id);
      this.setup(brand);
    }

    setup(brand = {
      currency: 'GBP'
    }) {
      const {
        primary_contact_name,
        primary_email,
        primary_phone,
        name,
        vat_number,
        accounting_address_line_1,
        accounting_address_line_2,
        address_line_1,
        address_line_2,
        address_postcode,
        address_city
      } = this.currentBrand.currentBoutique;

      if (this.orders.has(brand.id)) {
        Ember.set(this, 'order', this.orders.get(brand.id));
        return;
      }

      const order = new _order.default({
        currency: brand.currency,
        contact_details: {
          contact_name: primary_contact_name,
          email_address: primary_email,
          phone_number: primary_phone
        },
        invoice_details: {
          boutique_name: name,
          vat_number: vat_number,
          address: {
            line_1: accounting_address_line_1,
            line_2: accounting_address_line_2
          }
        },
        delivery_address: {
          line_1: `${address_line_1}, ${address_line_2 || ''}`,
          line_2: `${address_postcode}, ${address_city}`
        }
      });
      this.orders.set(brand.id, order);
      Ember.set(this, 'order', this.orders.get(brand.id));
    }

  }, (_descriptor3 = _applyDecoratedDescriptor(_class3.prototype, "currentBrand", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class3.prototype, "orders", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return new Map();
    }
  }), _descriptor5 = _applyDecoratedDescriptor(_class3.prototype, "order", [_dec5], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor6 = _applyDecoratedDescriptor(_class3.prototype, "isToastVisible", [_dec6], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return false;
    }
  }), _descriptor7 = _applyDecoratedDescriptor(_class3.prototype, "hideToastTimeoutId", [_dec7], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class3.prototype, "totalUnits", [_dec8], Object.getOwnPropertyDescriptor(_class3.prototype, "totalUnits"), _class3.prototype), _applyDecoratedDescriptor(_class3.prototype, "totalPrice", [_dec9], Object.getOwnPropertyDescriptor(_class3.prototype, "totalPrice"), _class3.prototype), _applyDecoratedDescriptor(_class3.prototype, "addProduct", [_dec10], Object.getOwnPropertyDescriptor(_class3.prototype, "addProduct"), _class3.prototype), _applyDecoratedDescriptor(_class3.prototype, "clearOrder", [_dec11], Object.getOwnPropertyDescriptor(_class3.prototype, "clearOrder"), _class3.prototype), _applyDecoratedDescriptor(_class3.prototype, "setup", [_dec12], Object.getOwnPropertyDescriptor(_class3.prototype, "setup"), _class3.prototype)), _class3));
  _exports.default = CurateBasket;
});