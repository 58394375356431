define("flaneur/pods/components/form-flows/product/product-options/option/component", ["exports", "ember-concurrency", "flaneur/util/default-to", "flaneur/models/variant"], function (_exports, _emberConcurrency, _defaultTo, _variant) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function findVariantByName(variants, name) {
    return variants.find(variant => variant.name === name);
  }

  var _default = Ember.Component.extend({
    media: Ember.inject.service(),
    classNames: ['product-options__option'],
    classNameBindings: ['showDetails:product-options__option--open'],
    stockLine: (0, _defaultTo.default)(null),
    maxSkuChars: 30,
    isFeedBoutique: false,
    onChangeVariant: (0, _defaultTo.default)(() => {}),

    didInsertElement() {
      this._super(...arguments);

      this.set('showDetails', !this.stockLine._id || !this.media.isLarge);
    },

    selectedVariants: Ember.computed('stockLine.variants.@each.value', function () {
      const variants = this.stockLine.variants;
      return {
        size: findVariantByName(variants, 'size'),
        color: findVariantByName(variants, 'color'),
        material: findVariantByName(variants, 'material'),
        other: findVariantByName(variants, 'other')
      };
    }),
    variantSearchTask: (0, _emberConcurrency.task)(function* (variantName, searchTerm = '') {
      yield (0, _emberConcurrency.timeout)(500);
      const query = {
        name: variantName,
        value: {
          $regex: `^${searchTerm.trim()}`,
          $options: 'i'
        },
        hint: {
          name: 1,
          value: 1
        }
      };
      return yield _variant.default.find(query);
    }).restartable(),
    actions: {
      showCreateOptionWhenDoesntExist(searchTerm = '', searchVariants = []) {
        return !searchVariants.some(variant => {
          return variant.value.toLowerCase().trim() === searchTerm.toLowerCase().trim();
        });
      },

      async createVariant(name, value) {
        const variant = await _variant.default.create({
          name,
          value
        }).save();
        await this.onChangeVariant(this.stockLine, name, variant);
        return variant;
      },

      changeUnlimitedStockToLimited(stockLine) {
        Ember.set(stockLine, 'quantity', 0);
      }

    }
  });

  _exports.default = _default;
});