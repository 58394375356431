define("flaneur/pods/components/under-maintenance/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "QnsitQlt",
    "block": "{\"symbols\":[\"@title\",\"@description\"],\"statements\":[[10,\"div\"],[15,0,[31,[\"under-maintenance \",[32,0,[\"styleNamespace\"]]]]],[12],[2,\"\\n  \"],[10,\"div\"],[14,0,\"under-maintenance__image\"],[12],[2,\"\\n    \"],[10,\"img\"],[14,\"src\",\"https://trouva-media.imgix.net/rphc/empty-pages/maintenance.png\"],[14,\"alt\",\"under-maintenance\"],[12],[13],[2,\"\\n  \"],[13],[2,\"\\n  \"],[10,\"h1\"],[12],[1,[32,1]],[13],[2,\"\\n  \"],[10,\"p\"],[12],[1,[32,2]],[13],[2,\"\\n\"],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[]}",
    "moduleName": "flaneur/pods/components/under-maintenance/template.hbs"
  });

  _exports.default = _default;
});