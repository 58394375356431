define("flaneur/utils/is-overflown", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.isOverflown = void 0;

  const isOverflown = ({
    clientWidth,
    clientHeight,
    scrollWidth,
    scrollHeight
  }) => {
    return scrollHeight > clientHeight || scrollWidth > clientWidth;
  };

  _exports.isOverflown = isOverflown;
});