define("flaneur/pods/brand/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "dsDv3i7/",
    "block": "{\"symbols\":[],\"statements\":[[8,\"nav-bar\",[],[[\"@showSidebar\"],[[34,0]]],null],[2,\"\\n\"],[8,\"nav-bar-alert\",[],[[],[]],null],[2,\"\\n\\n\"],[10,\"main\"],[14,0,\"main\"],[14,1,\"main\"],[12],[2,\"\\n  \"],[10,\"div\"],[14,0,\"container\"],[12],[2,\"\\n    \"],[8,\"main-nav\",[],[[\"@showSidebar\"],[[34,0]]],null],[2,\"\\n    \"],[10,\"div\"],[14,0,\"outlet\"],[14,1,\"outlet-container\"],[12],[2,\"\\n      \"],[1,[30,[36,2],[[30,[36,1],null,null]],null]],[2,\"\\n    \"],[13],[2,\"\\n  \"],[13],[2,\"\\n\"],[13],[2,\"\\n\\n\"],[10,\"div\"],[14,1,\"outlet-footer\"],[15,0,[31,[\"outlet-footer \",[30,[36,3],[[35,0],\"outlet-footer--wide\",\"\"],null]]]],[12],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"showSidebar\",\"-outlet\",\"component\",\"if\"]}",
    "moduleName": "flaneur/pods/brand/template.hbs"
  });

  _exports.default = _default;
});