define("flaneur/pods/components/inline-editor/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "yoNab5Cj",
    "block": "{\"symbols\":[\"button\",\"@placeholder\",\"&default\"],\"statements\":[[10,\"div\"],[15,0,[31,[\"inline-editor \",[32,0,[\"styleNamespace\"]]]]],[12],[2,\"\\n  \"],[11,\"div\"],[24,0,\"inline-editor__context\"],[4,[38,1],[\"click\",[32,0,[\"toggleIsOpen\"]]],null],[12],[2,\"\\n    \"],[18,3,null],[2,\"\\n  \"],[13],[2,\"\\n\\n\"],[6,[37,0],[[32,0,[\"isOpen\"]]],null,[[\"default\"],[{\"statements\":[[2,\"    \"],[10,\"div\"],[14,0,\"inline-editor__editor\"],[12],[2,\"\\n      \"],[8,\"input\",[],[[\"@value\",\"@placeholder\"],[[32,0,[\"value\"]],[32,2]]],null],[2,\"\\n      \"],[8,\"button\",[],[[\"@onClick\"],[[32,0,[\"save\"]]]],[[\"default\"],[{\"statements\":[[2,\"\\n\"],[6,[37,0],[[32,1,[\"loading\"]]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"          \"],[8,[32,1,[\"spinner\"]],[],[[],[]],null],[2,\"\\n\"]],\"parameters\":[]},{\"statements\":[[2,\"          Save\\n\"]],\"parameters\":[]}]]],[2,\"      \"]],\"parameters\":[1]}]]],[2,\"\\n      \"],[8,\"button\",[],[[\"@theme\",\"@onClick\"],[\"secondary\",[32,0,[\"close\"]]]],[[\"default\"],[{\"statements\":[[2,\"\\n        Cancel\\n      \"]],\"parameters\":[]}]]],[2,\"\\n    \"],[13],[2,\"\\n\"]],\"parameters\":[]}]]],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"if\",\"on\"]}",
    "moduleName": "flaneur/pods/components/inline-editor/template.hbs"
  });

  _exports.default = _default;
});