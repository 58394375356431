define("flaneur/pods/components/pages/products/bulk/upload/amend-step/sku-amend-modal/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "8U3NtN1T",
    "block": "{\"symbols\":[],\"statements\":[[8,\"modal-dialog\",[],[[\"@containerClass\",\"@translucentOverlay\",\"@onClose\"],[[31,[[32,0,[\"styleNamespace\"]],\"__modal\"]],true,[32,0,[\"close\"]]]],[[\"default\"],[{\"statements\":[[2,\"\\n  \"],[10,\"div\"],[14,0,\"ember-modal-dialog__header\"],[12],[2,\"\\n    \"],[10,\"h1\"],[12],[2,\"Add SKU\"],[13],[2,\"\\n  \"],[13],[2,\"\\n\\n  \"],[11,\"div\"],[24,\"role\",\"button\"],[24,0,\"ember-modal-dialog__close\"],[4,[38,0],[\"click\",[32,0,[\"close\"]]],null],[12],[2,\"\\n    \"],[8,\"fa-icon\",[],[[\"@icon\",\"@prefix\"],[\"times\",\"far\"]],null],[2,\"\\n  \"],[13],[2,\"\\n\\n  \"],[10,\"div\"],[14,0,\"ember-modal-dialog__body\"],[12],[2,\"\\n    \"],[10,\"div\"],[14,0,\"form-input\"],[12],[2,\"\\n      \"],[10,\"label\"],[14,\"for\",\"sku\"],[12],[2,\"SKU (Stock Keeping Unit)\"],[13],[2,\"\\n      \"],[8,\"input\",[],[[\"@id\",\"@value\"],[\"sku\",[32,0,[\"stockLine\",\"sku\",\"value\"]]]],null],[2,\"\\n    \"],[13],[2,\"\\n\"],[6,[37,1],[[32,0,[\"alreadyExists\"]]],null,[[\"default\"],[{\"statements\":[[2,\"      \"],[10,\"span\"],[14,0,\"form-input__error-message\"],[12],[2,\"\\n        SKU already exists\\n      \"],[13],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"  \"],[13],[2,\"\\n\\n  \"],[10,\"div\"],[14,0,\"ember-modal-dialog__footer\"],[12],[2,\"\\n    \"],[8,\"button\",[],[[\"@theme\",\"@onClick\"],[\"secondary\",[32,0,[\"close\"]]]],[[\"default\"],[{\"statements\":[[2,\"\\n      Cancel\\n    \"]],\"parameters\":[]}]]],[2,\"\\n    \"],[8,\"button\",[],[[\"@onClick\"],[[32,0,[\"save\"]]]],[[\"default\"],[{\"statements\":[[2,\"\\n      Add SKU\\n    \"]],\"parameters\":[]}]]],[2,\"\\n  \"],[13],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"on\",\"if\"]}",
    "moduleName": "flaneur/pods/components/pages/products/bulk/upload/amend-step/sku-amend-modal/template.hbs"
  });

  _exports.default = _default;
});