define("flaneur/app", ["exports", "ember-resolver", "ember-load-initializers", "flaneur/config/environment", "flaneur/datadog", "lazysizes", "lazysizes/plugins/unveilhooks/ls.unveilhooks"], function (_exports, _emberResolver, _emberLoadInitializers, _environment, _datadog, _lazysizes, _ls) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  (0, _datadog.startDatadog)();
  Ember.Debug.registerDeprecationHandler((message, options, next) => {
    const ignoredDeprecations = [
    /*
     * triggered by ember-power-select
     *
     * we're currently blocked on updating this package
     */
    'ember-metal.get-with-default',
    /*
     * triggered by <StarBoutiqueLabel /> component
     */
    'ember-component.is-visible'];

    if (!ignoredDeprecations.includes(options.id)) {
      next(message, options);
    }
  });

  class App extends Ember.Application {
    constructor(...args) {
      super(...args);

      _defineProperty(this, "modulePrefix", _environment.default.modulePrefix);

      _defineProperty(this, "podModulePrefix", _environment.default.podModulePrefix);

      _defineProperty(this, "Resolver", _emberResolver.default);
    }

  }

  _exports.default = App;
  (0, _emberLoadInitializers.default)(App, _environment.default.modulePrefix);
});