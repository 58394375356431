define("flaneur/pods/components/input-text/component", ["exports", "@glimmer/component", "ember-component-css/pod-names"], function (_exports, _component, _podNames) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _class;

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  let InputTextComponent = (_dec = Ember._action, (_class = class InputTextComponent extends _component.default {
    constructor(...args) {
      super(...args);

      _defineProperty(this, "styleNamespace", _podNames.default['input-text']);
    }

    onFocusOut(event) {
      const {
        value = ''
      } = event.target;
      const parsedValue = value.toUpperCase();
      return this.args.onChange(parsedValue);
    }

  }, (_applyDecoratedDescriptor(_class.prototype, "onFocusOut", [_dec], Object.getOwnPropertyDescriptor(_class.prototype, "onFocusOut"), _class.prototype)), _class));
  _exports.default = InputTextComponent;
});