define("flaneur/pods/components/input-search/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "NUqobdMW",
    "block": "{\"symbols\":[],\"statements\":[[8,\"fa-icon\",[[24,0,\"input-search__zoom\"]],[[\"@icon\",\"@prefix\"],[\"search\",\"far\"]],null],[2,\"\\n\\n\"],[8,\"input\",[],[[\"@type\",\"@value\",\"@placeholder\",\"@enter\"],[\"search\",[34,1],[34,2],[30,[36,0],[[32,0],\"onEnter\"],null]]],null],[2,\"\\n\\n\"],[6,[37,4],[[35,3]],null,[[\"default\"],[{\"statements\":[[2,\"  \"],[11,\"button\"],[24,0,\"input-search__clear\"],[4,[38,0],[[32,0],\"resetValue\"],null],[12],[2,\"\\n    \"],[8,\"fa-icon\",[],[[\"@icon\"],[\"times-circle\"]],null],[2,\"\\n  \"],[13],[2,\"\\n\"]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"action\",\"searchValue\",\"placeholder\",\"hasValue\",\"if\"]}",
    "moduleName": "flaneur/pods/components/input-search/template.hbs"
  });

  _exports.default = _default;
});