define("flaneur/pods/brand/boutiques/boutique/shipments/index/route", ["exports", "flaneur/models/shipment"], function (_exports, _shipment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    analytics: Ember.inject.service(),
    orderHistory: Ember.inject.service(),
    router: Ember.inject.service(),
    queryParams: {
      ordernumber: {
        refreshModel: true
      },
      page: {
        refreshModel: true
      }
    },

    resetController(controller, isExiting) {
      if (isExiting) {
        controller.set('ordernumber', undefined);
      }
    },

    async model({
      ordernumber,
      page
    }) {
      const boutique = this.brand.currentBoutique;
      const limit = 20;
      const {
        shipments,
        meta
      } = await _shipment.default.find({
        boutique: boutique._id,
        type: 'network',
        ordernumber: Ember.isEmpty(ordernumber) ? undefined : ordernumber,
        sort: '-created_at',
        limit,
        page: Number(page)
      });
      const {
        page_total: total
      } = meta || 0;
      return {
        shipments,
        ordernumber,
        limit,
        page,
        total,
        boutique
      };
    },

    actions: {
      search(orderNumber = '') {
        return this.router.transitionTo({
          queryParams: {
            ordernumber: orderNumber,
            page: 1
          }
        });
      },

      onClickBookCourier() {
        this.analytics.gtm({
          action: 'clicked_book_courier'
        });
      }

    }
  });

  _exports.default = _default;
});