define("flaneur/models/packaging/category/index", ["exports", "fetch", "flaneur/config/environment", "query-string"], function (_exports, _fetch, _environment, _queryString) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  class PackagingCategory {
    constructor(category) {
      const {
        _id,
        name,
        slug
      } = category;
      this.id = _id;
      this.name = name;
      this.slug = slug;
    }

    static async find(query = {}) {
      const queryParams = _queryString.default.stringify(query);

      const url = `${_environment.default.packagingApiURL}/api/categories?${queryParams}`;
      const res = await (0, _fetch.default)(url, {
        method: 'GET',
        credentials: 'include',
        cache: false
      });
      const {
        categories = [],
        meta = {}
      } = await res.json();
      return {
        categories: categories.map(categories => new PackagingCategory(categories)),
        meta
      };
    }

  }

  _exports.default = PackagingCategory;

  _defineProperty(PackagingCategory, "allCategory", {
    name: 'All Products',
    slug: null
  });
});