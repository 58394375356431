define("flaneur/pods/components/frame-form/common/component", ["exports", "@glimmer/component", "ember-component-css/pod-names", "flaneur/enums/frames-badge"], function (_exports, _component, _podNames, _framesBadge) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _dec8, _class, _descriptor, _descriptor2, _descriptor3;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  let FrameFormCommonComponent = (_dec = Ember._tracked, _dec2 = Ember._tracked, _dec3 = Ember._tracked, _dec4 = Ember._action, _dec5 = Ember._action, _dec6 = Ember._action, _dec7 = Ember._action, _dec8 = Ember._action, (_class = class FrameFormCommonComponent extends _component.default {
    constructor() {
      var _this$args$item, _this$args$item$badge;

      super(...arguments);

      _initializerDefineProperty(this, "isBadgeModalOpen", _descriptor, this);

      _initializerDefineProperty(this, "badgeUrl", _descriptor2, this);

      _initializerDefineProperty(this, "selectedBadgeUrl", _descriptor3, this);

      this.framesBadges = Object.values(_framesBadge.default);
      this.selectedBadgeUrl = (_this$args$item = this.args.item) === null || _this$args$item === void 0 ? void 0 : (_this$args$item$badge = _this$args$item.badge) === null || _this$args$item$badge === void 0 ? void 0 : _this$args$item$badge.url;
      this.badgeUrl = this.selectedBadgeUrl;
    }

    get styleNamespace() {
      return _podNames.default['frame-form/common'];
    }

    openBadgeModal() {
      this.isBadgeModalOpen = true;
    }

    closeBadgeModal() {
      this.isBadgeModalOpen = false;
    }

    onChangeBadge(badgeUrl) {
      this.selectedBadgeUrl = badgeUrl;
    }

    removeBadge() {
      this.selectedBadgeUrl = undefined;
    }

    updateBadge() {
      this.badgeUrl = this.selectedBadgeUrl;
      const badge = this.selectedBadgeUrl ? {
        url: this.selectedBadgeUrl
      } : undefined;
      this.args.onUpdateBadge(this.args.item, badge);
      this.closeBadgeModal();
    }

  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "isBadgeModalOpen", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "badgeUrl", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "selectedBadgeUrl", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "openBadgeModal", [_dec4], Object.getOwnPropertyDescriptor(_class.prototype, "openBadgeModal"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "closeBadgeModal", [_dec5], Object.getOwnPropertyDescriptor(_class.prototype, "closeBadgeModal"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "onChangeBadge", [_dec6], Object.getOwnPropertyDescriptor(_class.prototype, "onChangeBadge"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "removeBadge", [_dec7], Object.getOwnPropertyDescriptor(_class.prototype, "removeBadge"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "updateBadge", [_dec8], Object.getOwnPropertyDescriptor(_class.prototype, "updateBadge"), _class.prototype)), _class));
  _exports.default = FrameFormCommonComponent;
});