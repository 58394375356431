define("flaneur/pods/components/basic-form-section/suggestion/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "aJt0+a2+",
    "block": "{\"symbols\":[\"&default\",\"@namedBlocksInfo\"],\"statements\":[[6,[37,1],[[35,3]],null,[[\"default\"],[{\"statements\":[[6,[37,2],null,[[\"transition-class\"],[\"slide\"]],[[\"default\"],[{\"statements\":[[6,[37,1],[[30,[36,0],[[32,2],\"default\",[27,[32,1]]],null]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"      \"],[18,1,null],[2,\"\\n\"]],\"parameters\":[]},{\"statements\":[],\"parameters\":[]}]]]],\"parameters\":[]}]]]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"-has-block\",\"if\",\"transition-group\",\"display\"]}",
    "moduleName": "flaneur/pods/components/basic-form-section/suggestion/template.hbs"
  });

  _exports.default = _default;
});