define("flaneur/pods/brand/boutiques/boutique/curate/orders/submitted/route", ["exports", "flaneur/models/curate/order"], function (_exports, _order) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  class BrandBoutiquesBoutiqueCurateOrdersSubmittedRoute extends Ember.Route {
    constructor(...args) {
      super(...args);

      _defineProperty(this, "queryParams", {
        orderSubmittedType: {
          replace: true
        },
        orderSubmittedBrand: {
          replace: true
        },
        orderSubmittedId: {
          replace: true
        }
      });
    }

    afterModel({
      order
    }) {
      if (!order) {
        return this.transitionTo('brand.boutiques.boutique.curate.index');
      }
    }

    async model({
      orderSubmittedType,
      orderSubmittedBrand,
      orderSubmittedId
    }) {
      return Ember.RSVP.hash({
        orderSubmitted: {
          type: orderSubmittedType,
          brand: orderSubmittedBrand
        },
        order: await _order.default.findById(orderSubmittedId)
      });
    }

  }

  _exports.default = BrandBoutiquesBoutiqueCurateOrdersSubmittedRoute;
});