define("flaneur/pods/components/print-order-items/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "f20b2MJW",
    "block": "{\"symbols\":[\"groupedReservationsByDate\",\"date\",\"reservations\",\"order\"],\"statements\":[[6,[37,20],[[30,[36,19],[[35,21]],null]],null,[[\"default\"],[{\"statements\":[[2,\"  \"],[10,\"div\"],[14,0,\"order-collection-title\"],[12],[2,\"\\n    \"],[10,\"p\"],[12],[1,[30,[36,14],[[32,1]],null]],[13],[2,\"\\n    \"],[10,\"h2\"],[12],[2,\"\\n      \"],[1,[30,[36,15],[[30,[36,14],[[32,1]],null],\"order\"],[[\"without-count\"],[true]]]],[2,\"\\n      \"],[1,[30,[36,18],[[30,[36,17],[[30,[36,16],[[32,0],\"toReadableDate\",[32,2]],null]],null]],null]],[2,\"\\n    \"],[13],[2,\"\\n  \"],[13],[2,\"\\n\\n\"],[6,[37,20],[[30,[36,19],[[32,1]],null]],null,[[\"default\"],[{\"statements\":[[2,\"    \"],[1,[30,[36,13],null,[[\"boutique\",\"order\",\"reservations\",\"productsRecommendations\",\"placeholderThumbnail\",\"currentTab\",\"reservationBoutique\",\"shipments\",\"close\",\"inStock\",\"requestedManualLabel\",\"bookShipment\",\"updateReservationBoutique\",\"refreshAction\"],[[35,12],[32,3,[\"0\",\"order\"]],[32,3],[35,11],[35,10],[35,9],[30,[36,7],[[35,8],[32,4]],null],[30,[36,7],[[35,6],[32,4]],null],[35,5],[35,4],[35,3],[35,2],[35,1],[35,0]]]]],[2,\"\\n\"]],\"parameters\":[3,4]}]]]],\"parameters\":[1,2]}]]]],\"hasEval\":false,\"upvars\":[\"refreshAction\",\"updateReservationBoutiqueAction\",\"bookShipmentAction\",\"requestedManualLabelAction\",\"inStockAction\",\"closeAction\",\"shipments\",\"get\",\"reservationBoutiques\",\"currentTab\",\"placeholderThumbnail\",\"productsRecommendations\",\"boutique\",\"order-item\",\"json-length\",\"pluralize\",\"action\",\"compute\",\"html-safe\",\"-each-in\",\"each\",\"reservationsByEstimatedCollectionDate\"]}",
    "moduleName": "flaneur/pods/components/print-order-items/template.hbs"
  });

  _exports.default = _default;
});