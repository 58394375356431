define("flaneur/pods/components/discount-modal/component", ["exports", "flaneur/utils/params-lists"], function (_exports, _paramsLists) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    currentDiscount: null,
    discountList: _paramsLists.discountList,
    actions: {
      selectDiscount(filter) {
        this.set('currentDiscount', filter);
      },

      apply() {
        return this.onApply(this.currentDiscount);
      },

      close() {
        return this.onClose();
      }

    }
  });

  _exports.default = _default;
});