define("flaneur/pods/components/manufacturer-select/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "+VfDVX5Z",
    "block": "{\"symbols\":[\"opt\",\"&default\",\"@disabled\",\"@manufacturer\",\"@triggerClass\",\"@triggerId\",\"@namedBlocksInfo\"],\"statements\":[[8,\"power-select-with-create\",[[4,[38,1],[[32,0,[\"onDidInsert\"]]],null],[4,[38,2],[[32,0,[\"highlightQuery\"]],[32,0,[\"query\"]]],null]],[[\"@closeOnSelect\",\"@disabled\",\"@dropdownClass\",\"@onChange\",\"@onCreate\",\"@placeholder\",\"@search\",\"@searchEnabled\",\"@selected\",\"@showCreateWhen\",\"@suggestedOptionComponent\",\"@triggerClass\",\"@triggerId\"],[false,[32,3],\"manufacturer-select__dropdown\",[32,0,[\"changeManufacturer\"]],[32,0,[\"createManufacturer\"]],\"Please select a brand or manufacturer\",[30,[36,0],[[32,0,[\"searchManufacturer\"]]],null],true,[32,4],[32,0,[\"showCreateManufacturerWhen\"]],\"manufacturer-suggestion\",[32,5],[32,6]]],[[\"default\"],[{\"statements\":[[2,\"\\n  \"],[10,\"span\"],[14,0,\"manufacturer-select__option\"],[12],[2,\"\\n\"],[6,[37,4],[[30,[36,3],[[32,7],\"default\",[27,[32,2]]],null]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"      \"],[18,2,[[32,1]]],[2,\"\\n\"]],\"parameters\":[]},{\"statements\":[[2,\"      \"],[1,[32,1,[\"name\"]]],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"  \"],[13],[2,\"\\n\"]],\"parameters\":[1]}]]],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"perform\",\"did-insert\",\"did-update\",\"-has-block\",\"if\"]}",
    "moduleName": "flaneur/pods/components/manufacturer-select/template.hbs"
  });

  _exports.default = _default;
});