define("flaneur/pods/components/curate-brand-logotype/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "Hhm4PqU+",
    "block": "{\"symbols\":[\"@brand\",\"&attrs\"],\"statements\":[[11,\"img\"],[24,0,\"lazyload\"],[16,\"data-src\",[30,[36,0],[[32,1,[\"logo\",\"url\"]],[32,0,[\"transformation\"]]],null]],[17,2],[12],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"cloudinary-image\"]}",
    "moduleName": "flaneur/pods/components/curate-brand-logotype/template.hbs"
  });

  _exports.default = _default;
});