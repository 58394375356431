define("flaneur/enums/bulk-upload-error-status", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    AWAITING: 'awaiting',
    UPDATED: 'updated',
    REMOVED: 'removed'
  };
  _exports.default = _default;
});