define("flaneur/pods/orders/order/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "1vgyIlOF",
    "block": "{\"symbols\":[],\"statements\":[[10,\"div\"],[14,0,\"order order-confirm-receipt\"],[12],[2,\"\\n  \"],[10,\"div\"],[14,0,\"container\"],[12],[2,\"\\n\\n    \"],[10,\"div\"],[14,0,\"heading\"],[12],[2,\"\\n      \"],[10,\"h1\"],[14,0,\"title\"],[12],[2,\"Order confirmation\"],[13],[2,\"\\n    \"],[13],[2,\"\\n\\n      \"],[10,\"div\"],[14,0,\"message message-error\"],[12],[2,\"\\n        \"],[10,\"div\"],[14,0,\"message-heading\"],[12],[2,\"\\n          \"],[8,\"fa-icon\",[[24,0,\"fa\"]],[[\"@icon\",\"@prefix\"],[\"exclamation-circle\",\"far\"]],null],[2,\"\\n          \"],[10,\"p\"],[14,0,\"message-title\"],[12],[2,\"There seems to be a problem\"],[13],[2,\"\\n          \"],[10,\"p\"],[14,0,\"message-subtitle\"],[12],[2,\"This email has expired! Please log in and confirm the order from the 'orders' page.\"],[13],[2,\"\\n        \"],[13],[2,\"\\n      \"],[13],[2,\"\\n\\n      \"],[10,\"div\"],[14,0,\"item item-static\"],[12],[2,\"\\n        \"],[10,\"div\"],[14,0,\"item-form\"],[12],[2,\"\\n\"],[6,[37,0],null,[[\"class\",\"route\"],[\"button button--secondary\",\"login\"]],[[\"default\"],[{\"statements\":[[2,\"            Go to Log in\\n\"]],\"parameters\":[]}]]],[2,\"        \"],[13],[2,\"\\n      \"],[13],[2,\"\\n\\n  \"],[13],[2,\"\\n\"],[13],[2,\"\\n\\n\"],[1,[30,[36,2],[[30,[36,1],null,null]],null]],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"link-to\",\"-outlet\",\"component\"]}",
    "moduleName": "flaneur/pods/orders/order/template.hbs"
  });

  _exports.default = _default;
});