define("flaneur/pods/components/form-text/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    type: 'text',
    placeholder: '',
    disabled: false,
    onKeyUp: () => {},
    onBlur: () => {}
  });

  _exports.default = _default;
});