define("flaneur/pods/components/boutique-restock-recommendation-scroll/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "lLnXK/Hz",
    "block": "{\"symbols\":[\"__arg0\",\"__arg1\",\"recommendation\",\"t\"],\"statements\":[[6,[37,2],[[30,[36,4],[[32,0,[\"filteredRecommendations\",\"length\"]],0],null]],null,[[\"default\"],[{\"statements\":[[2,\"  \"],[8,\"horizontal-scroll\",[[16,0,[32,0,[\"styleNamespace\"]]]],[[\"@items\",\"@slidesPerBreakpoint\",\"@dividerWidth\",\"@edgeDividerWidth\",\"@namedBlocksInfo\"],[[32,0,[\"filteredRecommendations\"]],[32,0,[\"slidesPerBreakpoint\"]],16,40,[30,[36,3],null,[[\"header\",\"slide\"],[0,1]]]]],[[\"default\"],[{\"statements\":[[6,[37,2],[[30,[36,1],[[32,1],\"header\"],null]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"\\n      Restock Opportunities\\n      \"],[8,\"tooltip\",[],[[],[]],[[\"default\"],[{\"statements\":[[2,\"\\n        \"],[8,[32,4,[\"context\"]],[],[[],[]],[[\"default\"],[{\"statements\":[[2,\"\\n          \"],[8,\"fa-icon\",[],[[\"@icon\"],[\"info-circle\"]],null],[2,\"\\n        \"]],\"parameters\":[]}]]],[2,\"\\n        \"],[8,[32,4,[\"body\"]],[],[[],[]],[[\"default\"],[{\"statements\":[[2,\"\\n          These products have sold the most units in the past 30 days.\\n        \"]],\"parameters\":[]}]]],[2,\"\\n      \"]],\"parameters\":[4]}]]],[2,\"\\n    \"]],\"parameters\":[]},{\"statements\":[[6,[37,2],[[30,[36,1],[[32,1],\"slide\"],null]],null,[[\"default\"],[{\"statements\":[[6,[37,0],[[32,2]],null,[[\"default\"],[{\"statements\":[[2,\"\\n      \"],[8,\"boutique-restock-recommendation-scroll/card\",[],[[\"@recommendation\"],[[32,3]]],null],[2,\"\\n    \"]],\"parameters\":[3]}]]]],\"parameters\":[]}]]]],\"parameters\":[]}]]]],\"parameters\":[1,2]}]]],[2,\"\\n\"]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"let\",\"-is-named-block-invocation\",\"if\",\"hash\",\"gt\"]}",
    "moduleName": "flaneur/pods/components/boutique-restock-recommendation-scroll/template.hbs"
  });

  _exports.default = _default;
});