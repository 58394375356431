define("flaneur/models/packaging/order/index", ["exports", "fetch", "flaneur/config/environment", "query-string"], function (_exports, _fetch, _environment, _queryString) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  class PackagingOrder {
    constructor(order) {
      const {
        _id,
        order_number,
        reservations,
        created_at,
        pricing
      } = order;
      this.id = _id;
      this.orderNumber = order_number;
      this.reservations = reservations;
      this.createdAt = created_at;
      this.promotionCode = pricing.promotion_code;
      this.subtotalPrice = pricing.subtotal_price;
      this.deliveryPrice = pricing.delivery_price;
      this.vatPrice = pricing.vat_price;
      this.discountPrice = pricing.discount_price;
      this.totalPrice = pricing.total_price;
    }

    static async create(data) {
      const url = `${_environment.default.packagingApiURL}/api/orders`;
      const res = await (0, _fetch.default)(url, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json; charset=UTF-8'
        },
        credentials: 'include',
        cache: false,
        body: JSON.stringify(data)
      });
      const body = await res.json();

      if (!res.ok) {
        throw new Error(`error creating packaging order: ${JSON.stringify(body)}`);
      }

      const {
        orders
      } = body;
      const [order] = orders;
      return new PackagingOrder(order);
    }

    static async find(query = {}) {
      const queryParams = _queryString.default.stringify(query);

      const url = `${_environment.default.packagingApiURL}/api/orders?${queryParams}`;
      const res = await (0, _fetch.default)(url, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json; charset=UTF-8'
        },
        credentials: 'include',
        cache: false
      });
      const {
        orders = [],
        meta = {}
      } = await res.json();
      return {
        orders: orders.map(order => new PackagingOrder(order)),
        meta
      };
    }

    static async createPaymentIntent(data) {
      const url = `${_environment.default.packagingApiURL}/api/orders/payment-intent`;
      const res = await (0, _fetch.default)(url, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json; charset=UTF-8'
        },
        credentials: 'include',
        cache: false,
        body: JSON.stringify(data)
      });
      const {
        payment_intent: {
          client_secret: clientSecret
        }
      } = await res.json();
      return {
        paymentIntent: {
          clientSecret
        }
      };
    }

  }

  _exports.default = PackagingOrder;
});