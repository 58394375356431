define("flaneur/pods/brand/boutiques/boutique/dashboard/index/route", ["exports", "flaneur/pods/brand/boutiques/boutique/orders/fetch", "flaneur/models/callout-banner", "flaneur/models/boutique-restock-recommendation", "flaneur/utils/cut-off-time"], function (_exports, _fetch, _calloutBanner, _boutiqueRestockRecommendation, _cutOffTime) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _class, _descriptor, _descriptor2;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  let BrandBoutiquesBoutiqueDashboardIndexRoute = (_dec = Ember.inject.service, _dec2 = Ember.inject.service, (_class = class BrandBoutiquesBoutiqueDashboardIndexRoute extends Ember.Route {
    constructor(...args) {
      super(...args);

      _initializerDefineProperty(this, "brand", _descriptor, this);

      _initializerDefineProperty(this, "features", _descriptor2, this);
    }

    async model() {
      const boutique = this.brand.currentBoutique;
      const lateCurrentPage = 1;
      const lateLimit = 50;
      const late = (0, _fetch.fetchReservationBoutiqueWithMeta)(boutique._id, _fetch.STAGE_ACTIVE, lateCurrentPage, undefined, lateLimit, {
        skip_links: true,
        fields: ['boutique_processing_cut_off_time', 'order']
      }).then(({
        reservationBoutiques
      }) => {
        return Object.keys(reservationBoutiques).reduce((totalLateOrders, order) => {
          const [{
            boutique_processing_cut_off_time
          }] = reservationBoutiques[order];
          const state = (0, _cutOffTime.getStateFromCutOffTime)(boutique_processing_cut_off_time, boutique.timezone);
          return state === 'immediately' ? totalLateOrders + 1 : totalLateOrders;
        }, 0);
      });
      const ordersCounters = Ember.RSVP.hash({
        late,
        confirm: (0, _fetch.fetchReservationBoutiqueCount)(boutique._id, {
          status: _fetch.STAGE_ACTIVE
        }),
        print: (0, _fetch.fetchReservationBoutiqueCount)(boutique._id, {
          status: _fetch.STAGE_PRINT
        }),
        collect: (0, _fetch.fetchReservationBoutiqueCount)(boutique._id, {
          status: _fetch.STAGE_COLLECTION
        })
      });

      const recommendations = _boutiqueRestockRecommendation.default.find({
        boutiqueId: boutique._id,
        limit: 9,
        attachRestockInformation: true
      });

      return {
        ordersCounters,
        recommendations,
        calloutBanner: _calloutBanner.default.find()
      };
    }

  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "brand", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "features", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  })), _class));
  _exports.default = BrandBoutiquesBoutiqueDashboardIndexRoute;
});