define("flaneur/pods/brand/boutiques/boutique/products/bulk/upload/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "eLdxy2/1",
    "block": "{\"symbols\":[],\"statements\":[[8,\"pages/products/bulk/upload\",[],[[\"@downloadTemplate\"],[[30,[36,0],[\"downloadTemplate\"],null]]],null],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"route-action\"]}",
    "moduleName": "flaneur/pods/brand/boutiques/boutique/products/bulk/upload/template.hbs"
  });

  _exports.default = _default;
});