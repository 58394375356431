define("flaneur/mixins/color", ["exports", "flaneur/util/color"], function (_exports, _color) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Mixin.create({
    getColors: _color.getColors,
    getColorsHSV: _color.getColorsHSV
  });

  _exports.default = _default;
});