define("flaneur/router", ["exports", "jquery", "flaneur/config/environment"], function (_exports, _jquery, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  class Router extends Ember.Router {
    constructor(...args) {
      super(...args);

      _defineProperty(this, "location", _environment.default.locationType);

      _defineProperty(this, "rootURL", _environment.default.rootURL);
    }

  }

  _exports.default = Router;
  Router.map(function () {
    this.route('login');
    this.route('brand', {
      path: '/:brand_id'
    }, function () {
      this.route('settings', function () {
        this.route('users', function () {
          this.route('new');
        });
        this.route('account');
      });
      this.route('boutiques', function () {
        this.route('boutique', {
          path: '/:boutique_id'
        }, function () {
          this.route('notifications');
          this.route('insights');
          this.route('products', function () {
            this.route('new');
            this.route('duplicate');
            this.route('edit', {
              path: '/edit/:product_id'
            });
            this.route('bulk', function () {
              this.route('upload');
            });
          });
          this.route('orders', function () {
            this.route('stage', {
              path: '/:stage'
            });
          });
          this.route('receipt', function () {
            this.route('order', {
              path: '/:order'
            });
          });
          this.route('statements', function () {
            this.route('eu-vat');
          });
          this.route('shipments', function () {
            this.route('new');
            this.route('view', {
              path: '/:shipment_id'
            });
          });
          this.route('inventory', function () {});
          this.route('terms-and-conditions');
          this.route('settings', function () {
            this.route('accounting-details');
            this.route('contact-details');
            this.route('holiday-mode');
            this.route('order-fulfilment');
            this.route('operational-status');
          });
          this.route('welcome');
          this.route('packaging', function () {
            this.route('products', function () {
              this.route('product', {
                path: '/:id'
              });
            });
            this.route('basket');
            this.route('orders', function () {
              this.route('confirmation');
              this.route('order', {
                path: '/:id'
              });
            });
          });
          this.route('curate', function () {
            this.route('brands', function () {
              this.route('brand', {
                path: '/:slug'
              }, function () {
                this.route('basket');
                this.route('products', function () {
                  this.route('product', {
                    path: '/:product_slug'
                  });
                });
                this.route('checkout', function () {
                  this.route('information');
                  this.route('payment');
                });
              });
            });
            this.route('terms');
            this.route('orders', function () {
              this.route('order');
              this.route('submitted');
            });
          });
          this.route('dashboard', function () {
            this.route('operational-metrics');
          });
        });
      });
      this.route('faq');
    }); // Quick Response for the retailers with token

    this.route('orders', function () {
      this.route('order', {
        path: '/:order_id/:boutique_id/:auth_token'
      });
    });
    this.route('forgot');
    this.route('reset', {
      path: 'reset/:token'
    });
    this.route('upgrade_account', {
      path: 'upgrade_account/:role_token'
    });
    this.route('limited_access', {
      path: 'limited-access'
    });
    this.route('shopify_integration', {
      path: 'shopify-integration'
    });
    this.route('not-found', {
      path: '/*path'
    });
    this.route('zendesk', function () {
      this.route('login');
      this.route('logout');
    });
    this.route('join');
    this.route('your-contract');
    this.route('services', function () {
      this.route('quick-ship', function () {
        this.route('view', {
          path: '/:id'
        });
        this.route('new');
      });
    });
  });
  Ember.Route.reopen({
    intl: Ember.inject.service(),

    beforeModel(transition) {
      const needsLogin = _environment.default.routesNeedLogin.find(route => route.match(this.routeName));

      if (needsLogin) {
        this.set('session.postLoginTransition', transition);
      }

      const nav = typeof navigator !== 'undefined' ? navigator : {};
      const locales = nav.languages && nav.languages.length ? nav.languages : nav.language ? [nav.language] : [];
      this.intl.setLocale(locales);
      return Ember.RSVP.cast(!needsLogin || this.session.isAuthenticated()).then(() => this._super()).catch(() => {
        transition.abort();
        return this.transitionTo('login');
      });
    },

    activate() {
      this._super();

      const cssClass = this.toCssClass(this.routeName);

      if (cssClass !== 'route-application' && cssClass !== 'route-loading') {
        (0, _jquery.default)('html').addClass(cssClass);
      }
    },

    deactivate() {
      this._super();

      const cssClass = this.toCssClass(this.routeName);
      (0, _jquery.default)('html').removeClass(cssClass);
    },

    toCssClass(name) {
      return Ember.String.dasherize(`route-${name.replace(/\./g, '-')}`);
    }

  });
  Ember.LinkComponent.reopen({
    attributeBindings: ['data-intro', 'data-position', 'data-step'],
    track: null,
    _invoke: function (event) {
      const trackAction = this.track;
      if (trackAction) trackAction();
      return this._super(event);
    }
  });
});