define("flaneur/models/shipment-rate/index", ["exports", "flaneur/mixins/request", "query-string"], function (_exports, _request, _queryString) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const ShipmentRate = Ember.Object.extend({
    /* Instance methods */
  });
  ShipmentRate.reopenClass(_request.default, {
    /* Class methods */
    find(parameters = {}) {
      const queryParams = _queryString.default.stringify(parameters);

      return this.request({
        method: 'GET',
        url: this.apiURL(`shipment_rates?${queryParams}`)
      }).then(({
        shipment_rates
      }) => {
        const [shipment_rate] = shipment_rates;
        return shipment_rate;
      });
    }

  });
  var _default = ShipmentRate;
  _exports.default = _default;
});