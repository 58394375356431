define("flaneur/pods/components/pages/join/component", ["exports", "flaneur/pods/components/pages/join/validations"], function (_exports, _validations) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend(_validations.default, {
    notify: Ember.inject.service(),
    router: Ember.inject.service(),
    session: Ember.inject.service(),
    // Public API
    user: null,
    // Private API
    disableValidations: true,
    minPasswordLength: 8,
    passwordStrengthScores: {
      0: 'Very weak',
      1: 'Weak',
      2: 'Fair',
      3: 'Good',
      4: 'Strong'
    },
    boutiqueName: Ember.computed.alias('pb.name'),
    email: Ember.computed.alias('session.user.email'),
    password: Ember.computed.alias('user.new_password'),
    confirmPassword: Ember.computed.alias('user.new_password_confirmation'),
    passwordStrengthScore: Ember.computed('minPasswordLength', 'password.length', 'passwordStrengthScores', function () {
      if (Ember.isEmpty(this.password) || this.password.length < this.minPasswordLength) {
        return 'Too short';
      }

      return emberAutoImportDynamic("zxcvbn").then(({
        default: zxcvbn
      }) => {
        const {
          score
        } = zxcvbn(this.password);
        return this.passwordStrengthScores[score];
      });
    }),
    actions: {
      async logIn() {
        this.set('disableValidations', false);
        const {
          validations
        } = await this.validations.validate();

        if (validations.isInvalid) {
          return;
        }

        try {
          await this.user.reset();
          return this.router.transitionTo('your-contract');
        } catch {
          this.notify.show('error', 'Could not set a new password! Please try again or contact our support');
        }
      }

    }
  });

  _exports.default = _default;
});