define("flaneur/pods/components/basic-form-section/component", ["exports", "flaneur/util/default-to"], function (_exports, _defaultTo) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const {
    assign
  } = Object;

  var _default = Ember.Component.extend({
    // html
    tagName: 'div',
    classNames: ['product__item', 'product__basic-form-section'],
    // Public API
    labelComponent: (0, _defaultTo.default)('basic-form-section/label'),
    controlComponent: (0, _defaultTo.default)('basic-form-section/control'),
    helpComponent: (0, _defaultTo.default)('basic-form-section/help'),
    // Help sections
    suggestionComponent: (0, _defaultTo.default)('basic-form-section/suggestion'),
    warningComponent: (0, _defaultTo.default)('basic-form-section/warning'),
    invalidComponent: (0, _defaultTo.default)('basic-form-section/invalid'),
    errors: Ember.computed({
      // Default to empty array.
      get: () => [],

      set(_, errors, oldErrors) {
        if (errors === oldErrors) return;
        Ember.run.schedule('sync', this, this.updateState, {
          errors
        });
        return errors;
      }

    }),
    warnings: Ember.computed({
      // Default to empty array
      get: () => [],

      set(_, warnings, oldWarnings) {
        if (warnings === oldWarnings) return;
        Ember.run.schedule('sync', this, this.updateState, {
          warnings
        });
        return warnings;
      }

    }),
    state: Ember.computed({
      // Default to 'none'
      get: () => 'none',

      set(_, state, oldState) {
        if (state === oldState) return state;
        Ember.run.scheduleOnce('actions', this, this.updateState, {
          state
        });
        return state;
      }

    }),
    type: (0, _defaultTo.default)(''),
    disabled: (0, _defaultTo.default)(false),
    required: (0, _defaultTo.default)(false),
    label: (0, _defaultTo.default)(''),
    // Private API
    _typeClass: Ember.computed('type', function () {
      const type = this.type;
      return `item-${type}`;
    }),
    _stateClass: Ember.computed('state', function () {
      const state = this.state;
      return `item-state-${state}`;
    }),

    init() {
      this._super();

      this.updateState({
        state: this.state,
        type: this.type,
        disabled: this.disabled,
        required: this.required,
        label: this.label,
        errors: this.errors,
        warnings: this.warnings
      });
    },

    updateState(changes) {
      const newState = Ember.set(this, 'publicAPI', assign({}, this.publicAPI, changes));
      return newState;
    }

  });

  _exports.default = _default;
});