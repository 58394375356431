define("flaneur/pods/components/pages/packaging/products/index/component", ["exports", "@glimmer/component", "ember-component-css/pod-names", "flaneur/enums/packaging-product-variant-stock-status"], function (_exports, _component, _podNames, _packagingProductVariantStockStatus) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  let Products = (_dec = Ember.inject.service, _dec2 = Ember.inject.service, _dec3 = Ember._tracked, _dec4 = Ember._tracked, _dec5 = Ember._action, _dec6 = Ember._action, _dec7 = Ember._action, (_class = class Products extends _component.default {
    constructor() {
      super(...arguments);

      _initializerDefineProperty(this, "brand", _descriptor, this);

      _initializerDefineProperty(this, "router", _descriptor2, this);

      _defineProperty(this, "limit", 24);

      _initializerDefineProperty(this, "selectedCategory", _descriptor3, this);

      _initializerDefineProperty(this, "selectedVariantStockStatus", _descriptor4, this);

      _defineProperty(this, "productStockStatuses", [_packagingProductVariantStockStatus.default.ALL, _packagingProductVariantStockStatus.default.IN_STOCK, _packagingProductVariantStockStatus.default.OUT_OF_STOCK]);

      this.selectedVariantStockStatus = this.args.selectedVariantStockStatus;
      this.selectedCategory = this.args.selectedCategory;
    }

    get showPagination() {
      return this.args.meta.page_total > 1;
    }

    get styleNamespace() {
      return _podNames.default['pages/packaging/products/index'];
    }

    get defaultCategory() {
      return this.args.categories[0];
    }

    get defaultStockStatus() {
      return this.productStockStatuses[1];
    }

    get showClearFilters() {
      return this.selectedCategory.id || this.selectedVariantStockStatus !== this.defaultStockStatus;
    }

    setDefaultFilters() {
      this.setProductStockStatus(this.defaultStockStatus);
      this.setPackagingCategory(this.defaultCategory);
    }

    setPackagingCategory(category) {
      const {
        slug
      } = category;
      const queryParams = {
        category: slug,
        page: 1
      };
      this.selectedCategory = category;
      return this.router.transitionTo('brand.boutiques.boutique.packaging.products', {
        queryParams
      });
    }

    setProductStockStatus(value) {
      const queryParams = {
        stock_status: value,
        page: 1
      };
      this.selectedVariantStockStatus = value;
      return this.router.transitionTo('brand.boutiques.boutique.packaging.products', {
        queryParams
      });
    }

  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "brand", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "router", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "selectedCategory", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "selectedVariantStockStatus", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "setDefaultFilters", [_dec5], Object.getOwnPropertyDescriptor(_class.prototype, "setDefaultFilters"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "setPackagingCategory", [_dec6], Object.getOwnPropertyDescriptor(_class.prototype, "setPackagingCategory"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "setProductStockStatus", [_dec7], Object.getOwnPropertyDescriptor(_class.prototype, "setProductStockStatus"), _class.prototype)), _class));
  _exports.default = Products;
});