define("flaneur/models/curate/brand-referral/index", ["exports", "fetch", "flaneur/models/model", "flaneur/config/environment", "flaneur/models/curate/brand-referral/validations"], function (_exports, _fetch, _model, _environment, _validations) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  let CurateBrandReferral = (_dec = Ember._tracked, _dec2 = Ember._tracked, _dec3 = Ember._tracked, _dec4 = Ember._tracked, (_class = class CurateBrandReferral extends _model.default {
    constructor(curateBrandReferral = {}) {
      super({
        validations: _validations.referralValidations
      });

      _initializerDefineProperty(this, "name", _descriptor, this);

      _initializerDefineProperty(this, "website_url", _descriptor2, this);

      _initializerDefineProperty(this, "description", _descriptor3, this);

      _initializerDefineProperty(this, "referred_by", _descriptor4, this);

      const {
        name,
        website_url,
        description,
        referred_by
      } = curateBrandReferral;
      this.name = name;
      this.website_url = website_url;
      this.description = description;
      this.referred_by = referred_by;
    }

    async create() {
      if (!this.isValid) {
        return Ember.RSVP.reject({
          errors: this.validations
        });
      }

      try {
        const payload = {
          name: this.name,
          website_url: this.website_url,
          description: this.description,
          referred_by: this.referred_by
        };
        const url = `${_environment.default.curateApiURL}/api/v1/brand_referrals`;
        const res = await (0, _fetch.default)(url, {
          method: 'POST',
          credentials: 'include',
          cache: false,
          headers: {
            'Content-Type': 'application/json; charset=UTF-8'
          },
          body: JSON.stringify(payload)
        });

        if (!res.ok) {
          throw new Error('error creating brand referral');
        }

        return new CurateBrandReferral(this.curateBrandReferral);
      } catch (e) {
        throw new Error('error creating brand referral');
      }
    }

  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "name", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "website_url", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "description", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "referred_by", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  })), _class));
  _exports.default = CurateBrandReferral;
});