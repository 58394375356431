define("flaneur/models/courier", ["exports", "flaneur/mixins/request"], function (_exports, _request) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const Courier = Ember.Object.extend(_request.default, {// Instance methods
  });
  Courier.reopenClass(_request.default, {
    // Class methods
    _create(courier) {
      courier.container = this.container;
      return this.create(courier);
    },

    setContainer(container) {
      this.container = container;
    },

    arrange(boutique, order) {
      return this.request({
        method: 'POST',
        url: this.apiURL('courier'),
        data: JSON.stringify({
          boutique,
          order
        })
      });
    }

  });
  var _default = Courier;
  _exports.default = _default;
});