define("flaneur/models/product/index", ["exports", "flaneur/mixins/request", "flaneur/util/validation/validations", "flaneur/util/stock-line", "flaneur/util/validation", "deep-object-diff", "flaneur/utils/filter-control-chars", "flaneur/models/product/validations", "lodash.clonedeep", "flaneur/enums/order-channel"], function (_exports, _request, _validations, _stockLine, _validation, _deepObjectDiff, _filterControlChars, _validations2, _lodash, _orderChannel) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const _excluded = ["standard_manufacturer"];

  function _objectWithoutProperties(source, excluded) { if (source == null) return {}; var target = _objectWithoutPropertiesLoose(source, excluded); var key, i; if (Object.getOwnPropertySymbols) { var sourceSymbolKeys = Object.getOwnPropertySymbols(source); for (i = 0; i < sourceSymbolKeys.length; i++) { key = sourceSymbolKeys[i]; if (excluded.indexOf(key) >= 0) continue; if (!Object.prototype.propertyIsEnumerable.call(source, key)) continue; target[key] = source[key]; } } return target; }

  function _objectWithoutPropertiesLoose(source, excluded) { if (source == null) return {}; var target = {}; var sourceKeys = Object.keys(source); var key, i; for (i = 0; i < sourceKeys.length; i++) { key = sourceKeys[i]; if (excluded.indexOf(key) >= 0) continue; target[key] = source[key]; } return target; }

  function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); enumerableOnly && (symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols); } return keys; }

  function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = null != arguments[i] ? arguments[i] : {}; i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { _defineProperty(target, key, source[key]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } return target; }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function isUndefined(val) {
    return typeof val === 'undefined';
  }

  const Product = Ember.Object.extend(_request.default, {
    init() {
      this._super(...arguments);

      const product = this;
      (false && !(!isUndefined(product.currency)) && Ember.assert('Product model: "currency" property is required', !isUndefined(product.currency)));
      (false && !(!isUndefined(product.brand)) && Ember.assert('Product model: "brand" property is required', !isUndefined(product.brand)));
      (false && !(!isUndefined(product.boutique)) && Ember.assert('Product model: "boutique" property is required', !isUndefined(product.boutique))); // Setup default values when an instance of the model is created

      const name = product.name || '';
      const archived = product.archived || false;
      const local_price = product.local_price || {
        currency: product.currency,
        amount: 0
      };
      const categories = product.categories || [];
      const images = product.images || [];
      const unbranded = Ember.isNone(product.unbranded) ? null : product.unbranded;
      this.setProperties({
        name,
        archived,
        images,
        categories,
        local_price,
        unbranded
      }); // Convert product to stock_lines if boutiques array exists

      (0, _stockLine.convertProductToStockLines)(product); // Create a copy of the product so we can compare changes done to the original object

      const _orig = (0, _lodash.default)(this);

      Ember.set(this, '_orig', _orig);
      Ember.set(this, 'images', this.buildImagesArray(product));
    },

    // Checks if the product has images
    hasImages: Ember.computed.gt('images.length', 0),
    // Checks if the product has sizes
    hasSizes: Ember.computed('stock_lines.@each._id', function () {
      return (0, _stockLine.hasSizes)(this.stock_lines);
    }),
    isOnSale: Ember.computed('stock_lines.@each.sales', {
      get() {
        const stockLines = this.stock_lines;
        if (!stockLines) return false;
        return stockLines.every(stockLine => stockLine.sales === true);
      },

      set(_, value) {
        return value;
      }

    }),
    stockLinesInStock: Ember.computed('stock_lines.@each.quantity', function () {
      return this.stock_lines.filter(({
        quantity
      }) => quantity !== 0);
    }),
    stockLinesWithMadeChannelEnabled: Ember.computed('stock_lines.@each.channels', function () {
      return this.stock_lines.filter(({
        channels
      }) => channels[_orderChannel.default.MADE_DOT_COM].enabled);
    }),
    isInStock: Ember.computed.notEmpty('stockLinesInStock'),

    /**
     * Builds the array of images.
     *
     * @param {object} product
     * @returns {object[]}
     */
    buildImagesArray({
      thumbnail,
      images = []
    }) {
      if (images.length === 0 && Ember.isEmpty(thumbnail)) {
        return [];
      }

      if (images.length === 0 && !Ember.isEmpty(thumbnail)) {
        return [thumbnail];
      }

      if (images.length > 0 && !Ember.isEmpty(thumbnail)) {
        const [firstImage] = images;

        if (firstImage.url !== thumbnail.url) {
          return [thumbnail, ...images];
        }
      }

      return images;
    },

    /**
     * Adds a stock line to the stock lines array.
     * If the product:
     * 1. has other variations already, add to the end of the list
     * 2. doesn't have variations, remove the original stock line and add it
     *
     * @param {object} overrides    - stock line overrides
     * @returns {object[]}          - Array of stock lines
     */
    addStockLine(overrides = {}) {
      const currency = this.currency;
      const boutiqueId = this.boutique;
      const productId = this._id; // Ensure we keep the sales flag and sales_price in sync with the rest of the stock lines

      const firstStockLine = this.stock_lines[0];
      const salesPrice = firstStockLine ? firstStockLine.local_sales_price.amount : 0;
      const sales = firstStockLine ? firstStockLine.sales : false;
      const care_instructions = firstStockLine ? firstStockLine.care_instructions : null;
      const dimensions = firstStockLine ? firstStockLine.dimensions : {};
      const [previousStockLine] = this.stock_lines.slice(-1);
      const variants = previousStockLine.variants.length > 1 ? previousStockLine.variants : [];
      const stockLine = (0, _stockLine.generateStockLine)(boutiqueId, productId, currency, salesPrice, _objectSpread(_objectSpread({}, overrides), {}, {
        sales,
        dimensions,
        care_instructions,
        variants
      }));
      return this.stock_lines.addObject(stockLine);
    },

    /**
     * Removes a stock line to the stock lines array if the product has sizes.
     *
     * If all stock lines are removed, create a new default stock line
     * keeping the local_sales_price and sales toggle the same
     *
     * @param {object} stockLine    - stock line to remove
     * @returns {object[]}          - Array of stock lines
     */
    removeStockLine(stockLine) {
      const currency = this.currency;
      const boutiqueId = this.boutique;
      const productId = this._id;
      this.stock_lines.removeObject(stockLine); // If there are no more stock lines, create the default stock line

      if (this.stock_lines.length === 0) {
        // We need to keep these values in the default stock line
        const {
          sales,
          local_sales_price
        } = stockLine;
        const salesPrice = local_sales_price.amount;
        const defaultStockLine = (0, _stockLine.generateStockLine)(boutiqueId, productId, currency, salesPrice, {
          sales
        });
        return this.stock_lines.addObject(defaultStockLine);
      }

      return this.stock_lines;
    },

    /**
     * Updates an attribute on each stock line with a given value
     *
     * @param {string} attribute    - Attribute to update on all stock lines
     * @param {*} value             - Value to assign to every stock line
     * @returns {object[]}          - Updated stock lines
     */
    updateAttributeOnAllStockLines(attribute, value) {
      this.stock_lines.forEach(stockLine => {
        Ember.set(stockLine, attribute, value);
      });
      return this.stock_lines;
    },

    /**
     * Update sales flag on all stock lines until we get around to handling
     * the sales separately for each variation
     *
     * @param {boolean} sales   - The sales flag, signifying if the product is on sale
     * @returns {object[]}      - Updated stock lines
     */
    updateStockLineSales(sales) {
      return this.updateAttributeOnAllStockLines('sales', sales);
    },

    /**
     * Update a dimension attribute on the stock lines
     *
     * @param {boolean} sales   - The stock dimension to update
     * @returns {object[]}      - Updated stock lines
     */
    updatedStockLineDimension(dimension, value) {
      (false && !(['width', 'length', 'height'].includes(dimension)) && Ember.assert(`Product model: Unknown dimension ${dimension}`, ['width', 'length', 'height'].includes(dimension)));
      return this.updateAttributeOnAllStockLines(`dimensions.${dimension}`, value);
    },

    /**
     * Update care instructions on all stock lines until we get around to handling
     * the care instructions for each variation
     *
     * @param {string} value - Instructions of how to care for the product
     */
    updateCareInstructions(value) {
      return this.updateAttributeOnAllStockLines('care_instructions', value);
    },

    /**
     * Updates the sales_price & local_sales_price on all stock lines
     * until we get around to handling sales price separately for each variation
     *
     * @param {number} salesPrice
     * @returns {object[]} Array of stock lines
     */
    updateStockLineSalesPrice(salesPrice) {
      const currency = this.currency;
      this.stock_lines.forEach(stockLine => {
        Ember.setProperties(stockLine, (0, _stockLine.generateStockLineSalesPrice)(currency, salesPrice));
      });
      return this.stock_lines;
    },

    validateItemQuantity(item) {
      return (0, _validation.default)(item, {
        quantity: [(0, _validations.validatePresence)({
          presence: true,
          message: 'Please enter a quantity to save'
        }), (0, _validations.validateNumber)({
          integer: true,
          allowString: true,
          message: 'Quantity must be a whole number'
        })]
      });
    },

    validateItemSalesPrice(localPrice, localSalesPrice) {
      const validations = [(0, _validations.validateNumber)({
        gt: 0,
        allowString: true,
        message: 'Sale price has to be greater than 0'
      })];
      (localPrice === null || localPrice === void 0 ? void 0 : localPrice.amount) && validations.push((0, _validations.validateNumber)({
        lt: localPrice.amount,
        allowString: true,
        message: `Sale price must be lower than the original price.`
      }));
      return (0, _validation.default)(localSalesPrice, {
        amount: validations
      });
    },

    validateStockLinesItems(items) {
      const itemErrors = items.reduce((prev, item, index) => {
        let validationResult = this.validateItemQuantity(item);

        if (item.sales === true) {
          const salesValidation = this.validateItemSalesPrice(item.local_price, item.local_sales_price);

          if (salesValidation !== true && validationResult !== true) {
            validationResult.local_sales_price = salesValidation;
          }

          if (salesValidation !== true && validationResult === true) {
            validationResult = _objectSpread({}, salesValidation);
          }
        }

        if (validationResult !== true) {
          prev[index] = validationResult;
        }

        return prev;
      }, {});

      if (Object.getOwnPropertyNames(itemErrors).length > 0) {
        return itemErrors;
      } else {
        return true;
      }
    },

    /**
     * Removes all variants of a certain name.
     *
     * @param stockLine         - The stock line to remove the variant from
     * @param variantName       - The name/type of variant to remove
     */
    removeVariant(stockLine, variantName) {
      const filteredVariants = stockLine.variants.filter(v => v.name !== variantName);
      Ember.set(stockLine, 'variants', filteredVariants);
    },

    /**
     * Adds a variant to a stock line, by removing the variant with the same name
     *
     * @param {object} stockLine    - The stock line to add variant to
     * @param {object} variant      - The variant to add
     * @returns {object[]}          - The new stock line variants
     */
    addVariant(stockLine, variant) {
      if (!stockLine.variants) {
        Ember.set(stockLine, 'variants', []);
      } // We can currently only hold one variant per stock line because of how the UI
      // is structured, so ensure we remove existing variants with the same name


      const filteredVariants = stockLine.variants.filter(v => v.name !== variant.name);
      Ember.set(stockLine, 'variants', filteredVariants);
      stockLine.variants.addObject(variant);
      return stockLine.variants;
    },

    diffChangesToProduct(originalProduct, newProduct) {
      return (0, _deepObjectDiff.diff)(originalProduct, newProduct);
    },

    /**
     * Takes a promenade payload, clones it to ensure the UI isn't affected and converts relationships to object ids
     *
     * @param {object} originalProduct  - The payload of the product to be adapted to promenade
     * @returns {object}                - The modified product
     */
    adaptPayloadToPromenade(product, updatedProduct) {
      const convertResourceObjectToId = resource => {
        switch (typeof resource) {
          case 'object':
            return resource._id;

          case 'string':
            return resource;

          case 'undefined':
            return undefined;

          default:
            throw new Error('UNHANDLED_RESOURCE_CAST');
        }
      };

      const convertResourceObjectsToIds = resources => {
        if (!resources) return undefined;
        return resources.map(convertResourceObjectToId);
      };

      delete product._orig;
      delete product.links; // Remove "boutiques" and "sizes" array

      delete product.sizes;
      delete product.backup_boutiques;
      delete product.boutiques; // Required fields

      product.brand = updatedProduct.brand;
      product.local_price = updatedProduct.local_price; // Optional promenade field remapping

      if (updatedProduct._id) {
        product._id = updatedProduct._id;
      }

      if (product.standard_manufacturer) {
        product.standard_manufacturer = convertResourceObjectToId(product.standard_manufacturer);
      }

      if (product.brand) {
        product.brand = convertResourceObjectToId(product.brand);
      }

      if (product.colors) {
        product.colors = convertResourceObjectsToIds(updatedProduct.colors);
      }

      if (product.categories) {
        product.categories = convertResourceObjectsToIds(updatedProduct.categories);
      } // When changes are detected on the images array, we need to send
      // all information otherwise we will end up deleting unmodified images


      if (product.images) {
        product.images = updatedProduct.images;
      } // If changes are found on the stock lines replace the diff'd stock lines because we need
      // to ensure stock lines always have the correct information before sending them to promenade


      if (product.stock_lines) {
        product.stock_lines = updatedProduct.stock_lines.map(stockLine => {
          if (stockLine.quantity !== null) {
            Ember.set(stockLine, 'quantity', Number(stockLine.quantity));
          }

          delete stockLine._id;
          delete stockLine.__v;
          delete stockLine.marked_as_oos_at;
          delete stockLine.created_at;
          delete stockLine.updated_at; // Promenade will handle creating the size if it needs to.

          if (stockLine.variants.length > 0) {
            delete stockLine.size;
          } // Convert variants to id's


          Ember.set(stockLine, 'variants', convertResourceObjectsToIds(stockLine.variants));
          return stockLine;
        });
      } // Clean description of ASCII characters


      if (product.description) {
        product.description = (0, _filterControlChars.default)(product.description);
      }

      return product;
    },

    /**
     * Calls promenade to update the product.
     *
     * @param   {object} product    - product to update
     * @returns {Promise<object>}   - The updated product
     */
    async saveExistingProduct(product) {
      const res = await this.request({
        method: 'put',
        url: this.apiURL(`products/${product._id}`),
        data: JSON.stringify(product)
      });
      const updatedProduct = Ember.get(res, 'products.0'); // Promenade doesn't support populating on updates,
      // so skip replacing the standard manufacturer object with an id.

      delete updatedProduct.standard_manufacturer;
      this.setProperties(updatedProduct);

      const _orig = (0, _lodash.default)(this);

      Ember.set(this, '_orig', _orig);
      return updatedProduct;
    },

    /**
     * Calls promenade to create a new product.
     *
     * @param   {object} product    - product to create
     * @returns {Promise<object>}   - The created product
     */
    async saveNewProduct(product) {
      const res = await this.request({
        method: 'post',
        url: this.apiURL('products'),
        data: JSON.stringify(product)
      });
      const createdProduct = Ember.get(res, 'products.0');
      this.setProperties(createdProduct);
      return createdProduct;
    },

    /**
     * Runs validations on specific fields.
     * The fields will be validated against the POST/CREATE validations
     *
     * @param {string[]} fieldsToValidate   - Array of string of fields to be validated
     * @returns {boolean}                   - true if validations passed, error thrown if not
     */
    validateFields(fieldsToValidate = []) {
      const isNewProduct = !this._id;
      const validations = isNewProduct ? _validations2.postValidations : _validations2.putValidations;
      const validationsToRun = fieldsToValidate.reduce((prev, fieldToValidate) => {
        if (validations[fieldToValidate]) {
          prev[fieldToValidate] = validations[fieldToValidate];
        }

        return prev;
      }, {});
      const validationResult = (0, _validation.default)(this, validationsToRun);
      return validationResult;
    },

    setStockLinesValidations(validationResult, stockLinesValidation) {
      if (stockLinesValidation === true) {
        return validationResult;
      }

      if (stockLinesValidation !== true && validationResult === true) {
        return {
          stock_lines: stockLinesValidation
        };
      }

      validationResult.stock_lines = stockLinesValidation;
      return validationResult;
    },

    /**
     * Validates the model and calls promenade to create or update a product.
     * - Update the product if: The product has an "_id" property
     * - Create the product if: The product doesn't have an "_id" property
     *
     * @returns {Promise<object>}   The created/updated product
     */
    async save() {
      // Clone product so that we make sure the UI doesn't change
      const updatedProduct = (0, _lodash.default)(this);
      const productDiffs = this.diffChangesToProduct(this._orig, updatedProduct);
      const product = this.adaptPayloadToPromenade(productDiffs, updatedProduct);
      const isNewProduct = !this._id;
      const validations = isNewProduct ? _validations2.postValidations : _validations2.putValidations;
      const saveAction = isNewProduct ? this.saveNewProduct : this.saveExistingProduct;
      let validationResult = (0, _validation.default)(updatedProduct, validations);
      const stockLinesValidation = this.validateStockLinesItems(updatedProduct.stock_lines.map(stockLine => {
        return _objectSpread(_objectSpread({}, stockLine), {}, {
          local_price: updatedProduct.local_price
        });
      }));
      validationResult = this.setStockLinesValidations(validationResult, stockLinesValidation);

      if (validationResult === true) {
        // If the validations pass, create/update product
        return saveAction.call(this, product);
      } else {
        // If the validations don't pass, throw error
        throw validationResult;
      }
    }

  });
  /**
   * Response from querying the products endpoint
   * @typedef {Promise<{meta: {total: number}, products: object[]}>} ProductsResourceResponse
   */

  Product.reopenClass(_request.default, {
    /**
     * Queries the products resource using Product#find
     *
     * @param   {string}  _id       - id of product to query
     * @returns {Promise<object>}   - Promise resolving to the found product
     */
    async findById(_id) {
      // Use #find because "products/:id" doesn't support stock_lines
      const res = await Product.find({
        _id
      });
      return Ember.get(res, 'products.0');
    },

    /**
     * Queries the products resource on promenade, populating stock lines.
     *
     * @param {object} query - Query to be passed on to the resource
     * @returns {ProductsResourceResponse}
     */
    async find(query = {}) {
      const res = await this.request({
        method: 'post',
        url: this.apiURL('products'),
        data: JSON.stringify(_objectSpread(_objectSpread({}, query), {}, {
          skip_links: true,
          attach_related: 'stock_lines'
        })),
        headers: {
          'X-HTTP-METHOD-OVERRIDE': 'GET'
        }
      });
      const products = res.products.map(product => {
        if (product.local_price && product.local_price.currency) {
          // Assign the currency to the top level of the product
          product.currency = product.local_price.currency; // Assign the boutique to the top level of the boutique

          product.boutique = Ember.get(product, 'stock_lines.0.boutique') || Ember.get(product, 'boutiques.0.boutique');
          return Product.create(product);
        }

        return product;
      });
      const meta = res.meta || {
        total: 0
      };
      return {
        products,
        meta
      };
    },

    bulkCreate(products = []) {
      if (Ember.isEmpty(products)) {
        return Ember.RSVP.reject('products must have at least one item');
      }

      return this.request({
        method: 'POST',
        url: this.apiURL('products'),
        data: JSON.stringify(products)
      });
    },

    bulkUpdate(products) {
      return this.request({
        method: 'PATCH',
        url: this.apiURL('products'),
        data: JSON.stringify({
          products: products.map(product => product.adaptPayloadToPromenade(product._orig, product))
        })
      }).then(res => {
        res.products.forEach(data => {
          const product = products.findBy('_id', data._id);
          /*
           * Promenade doesn't support populating on updates,
           * so we need to skip replacing the standard_manufacturer
           */

          const {
            standard_manufacturer
          } = data,
                properties = _objectWithoutProperties(data, _excluded);

          product.setProperties(properties);
        });
      });
    },

    /**
     * BUlk remove products
     * @param  {Array} products Products
     * @return {Object}          Promise
     */
    bulkRemove(products = []) {
      const ids = products.map(({
        _id
      }) => _id).join(',');
      return this.request({
        method: 'DELETE',
        url: this.apiURL(`products/${ids}`)
      });
    }

  });
  var _default = Product;
  _exports.default = _default;
});