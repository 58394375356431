define("flaneur/pods/components/curate-categories-filter/parent/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "HJRxzSvt",
    "block": "{\"symbols\":[\"@onClickLink\",\"@parent\"],\"statements\":[[2,\"  \"],[10,\"li\"],[14,0,\"curate-categories-filter__parent\"],[12],[2,\"\\n\"],[6,[37,2],[[32,2]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"      \"],[8,\"curate-categories-filter/link\",[[4,[38,1],[\"click\",[30,[36,0],[[32,1],[32,2,[\"_id\"]]],null]],null]],[[],[]],[[\"default\"],[{\"statements\":[[2,\"\\n        \"],[8,\"fa-icon\",[],[[\"@icon\",\"@prefix\"],[\"chevron-left\",\"far\"]],null],[2,\"\\n        All \"],[1,[32,2,[\"name\"]]],[2,\"\\n      \"]],\"parameters\":[]}]]],[2,\"\\n\"]],\"parameters\":[]},{\"statements\":[[2,\"      \"],[8,\"curate-categories-filter/link\",[[4,[38,1],[\"click\",[30,[36,0],[[32,1],[29]],null]],null]],[[],[]],[[\"default\"],[{\"statements\":[[2,\"\\n        \"],[8,\"fa-icon\",[],[[\"@icon\",\"@prefix\"],[\"chevron-left\",\"far\"]],null],[2,\"\\n        All categories\\n      \"]],\"parameters\":[]}]]],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"  \"],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"fn\",\"on\",\"if\"]}",
    "moduleName": "flaneur/pods/components/curate-categories-filter/parent/template.hbs"
  });

  _exports.default = _default;
});