define("flaneur/pods/brand/boutiques/boutique/curate/brands/brand/products/product/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "ZzAFLHbf",
    "block": "{\"symbols\":[\"@model\"],\"statements\":[[8,\"curate-product-card/modal\",[],[[\"@product\",\"@brand\",\"@onClose\",\"@onAddToBasket\"],[[32,1],[32,1,[\"brand\"]],[32,0,[\"closeModal\"]],[32,0,[\"addToBasket\"]]]],null]],\"hasEval\":false,\"upvars\":[]}",
    "moduleName": "flaneur/pods/brand/boutiques/boutique/curate/brands/brand/products/product/template.hbs"
  });

  _exports.default = _default;
});