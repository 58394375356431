define("flaneur/pods/index/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "KfIYo/O3",
    "block": "{\"symbols\":[],\"statements\":[[8,\"nav-bar-alert\",[],[[\"@notify\"],[[34,0]]],null],[2,\"\\n\\n\"],[10,\"header\"],[14,0,\"lp-header\"],[12],[2,\"\\n  \"],[8,\"nav-bar-buttons\",[],[[\"@isLoggedIn\",\"@brand\"],[[34,1,[\"isLoggedIn\"]],[34,1,[\"brand\"]]]],null],[2,\"\\n\"],[13],[2,\"\\n\\n\"],[8,\"pages/landing\",[],[[],[]],null],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"notify\",\"model\"]}",
    "moduleName": "flaneur/pods/index/template.hbs"
  });

  _exports.default = _default;
});