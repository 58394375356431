define("flaneur/pods/components/collapsible/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "+2DEhVS1",
    "block": "{\"symbols\":[\"@content\",\"&default\",\"@namedBlocksInfo\",\"@label\",\"&attrs\"],\"statements\":[[11,\"div\"],[16,0,[31,[\"collapsible \",[30,[36,3],[[32,0,[\"open\"]],\"collapsible--open\"],null]]]],[17,5],[12],[2,\"\\n  \"],[11,\"div\"],[24,0,\"collapsible__label\"],[4,[38,4],[\"click\",[32,0,[\"toggle\"]]],null],[4,[38,5],[[32,0,[\"close\"]]],null],[12],[2,\"\\n\"],[6,[37,3],[[30,[36,2],[[32,3],\"default\",[27,[32,2]]],null]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"      \"],[18,2,[[30,[36,1],null,[[\"label\",\"isOpen\"],[[30,[36,0],[\"empty-template\"],null],[32,0,[\"open\"]]]]]]],[2,\"\\n\"]],\"parameters\":[]},{\"statements\":[[2,\"      \"],[1,[32,4]],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"  \"],[13],[2,\"\\n\\n  \"],[8,\"liquid-spacer\",[],[[\"@growDuration\",\"@growWidth\"],[[32,0,[\"growDuration\"]],false]],[[\"default\"],[{\"statements\":[[2,\"\\n\"],[6,[37,3],[[32,0,[\"open\"]]],null,[[\"default\"],[{\"statements\":[[2,\"      \"],[10,\"div\"],[14,0,\"collapsible__content\"],[12],[2,\"\\n\"],[6,[37,3],[[30,[36,2],[[32,3],\"default\",[27,[32,2]]],null]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"          \"],[18,2,[[30,[36,1],null,[[\"content\"],[[30,[36,0],[\"empty-template\"],null]]]]]],[2,\"\\n\"]],\"parameters\":[]},{\"statements\":[[2,\"          \"],[1,[32,1]],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"      \"],[13],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"  \"]],\"parameters\":[]}]]],[2,\"\\n\"],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"component\",\"hash\",\"-has-block\",\"if\",\"on\",\"on-click-outside\"]}",
    "moduleName": "flaneur/pods/components/collapsible/template.hbs"
  });

  _exports.default = _default;
});