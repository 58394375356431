define("flaneur/pods/components/load-more/component", ["exports", "ember-in-viewport", "ember-in-viewport/utils/is-in-viewport", "jquery"], function (_exports, _emberInViewport, _isInViewport, _jquery) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend(_emberInViewport.default, {
    // Public API
    action() {},

    // Private API
    viewportOptionsOverride: Ember.on('willInsertElement', function () {
      Ember.setProperties(this, {
        viewportSpy: true,
        viewportTolerance: {
          top: 100,
          bottom: 200,
          left: 20,
          right: 20
        }
      });
    }),

    didEnterViewport() {
      return this.action().then(() => {
        // Check if the the loader is still in the viewport
        // after the action has been triggered
        Ember.run.scheduleOnce('afterRender', this, this.loadMore);
      }).catch(() => {});
    },

    loadMore() {
      const windowElement = (0, _jquery.default)(window);
      const boundingClientRect = this.element.getBoundingClientRect();
      const inViewportQ = (0, _isInViewport.default)(boundingClientRect, windowElement.innerHeight(), windowElement.innerWidth(), this.viewportTolerance);

      if (inViewportQ) {
        this.set('viewportEntered', false);
        this._startListening && this._startListening();
      }
    }

  });

  _exports.default = _default;
});