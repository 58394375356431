define("flaneur/pods/brand/boutiques/boutique/statements/index/route", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  class BrandBoutiquesBoutiqueStatementsIndexRoute extends Ember.Route {
    model() {
      return this.modelFor('brand.boutiques.boutique.statements');
    }

  }

  _exports.default = BrandBoutiquesBoutiqueStatementsIndexRoute;
});