define("flaneur/pods/components/basic-form-section/invalid/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "Xrk8Wz0n",
    "block": "{\"symbols\":[\"error\",\"&default\",\"@namedBlocksInfo\"],\"statements\":[[6,[37,4],[[35,6]],null,[[\"default\"],[{\"statements\":[[6,[37,5],null,[[\"transition-class\"],[\"slide\"]],[[\"default\"],[{\"statements\":[[6,[37,4],[[30,[36,3],[[32,3],\"default\",[27,[32,2]]],null]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"      \"],[18,2,null],[2,\"\\n\"]],\"parameters\":[]},{\"statements\":[[6,[37,2],[[30,[36,1],[[30,[36,1],[[35,0,[\"errors\"]]],null]],null]],null,[[\"default\"],[{\"statements\":[[2,\"        \"],[10,\"p\"],[14,0,\"help-section--copy\"],[12],[2,\"\\n          \"],[2,[32,1]],[2,\"\\n        \"],[13],[2,\"\\n\"]],\"parameters\":[1]}]]]],\"parameters\":[]}]]]],\"parameters\":[]}]]]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"section\",\"-track-array\",\"each\",\"-has-block\",\"if\",\"transition-group\",\"display\"]}",
    "moduleName": "flaneur/pods/components/basic-form-section/invalid/template.hbs"
  });

  _exports.default = _default;
});