define("flaneur/pods/components/shipment-rate-calculator/component", ["exports", "@glimmer/component", "ember-component-css/pod-names", "ember-concurrency", "flaneur/models/country", "flaneur/enums/currency-symbol"], function (_exports, _component, _podNames, _emberConcurrency, _country, _currencySymbol) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  let ShipmentRateCalculatorComponent = (_dec = Ember.inject.service, _dec2 = Ember.inject.service, _dec3 = Ember.inject.service, _dec4 = Ember.inject.service, _dec5 = Ember._tracked, _dec6 = Ember._action, _dec7 = Ember._action, (_class = class ShipmentRateCalculatorComponent extends _component.default {
    constructor(...args) {
      super(...args);

      _initializerDefineProperty(this, "analytics", _descriptor, this);

      _initializerDefineProperty(this, "brand", _descriptor2, this);

      _initializerDefineProperty(this, "quickShip", _descriptor3, this);

      _initializerDefineProperty(this, "router", _descriptor4, this);

      _initializerDefineProperty(this, "selectedCountry", _descriptor5, this);
    }

    get boutique() {
      return this.brand.currentBoutique;
    }

    get currency() {
      return this.boutique.currency;
    }

    get currencySymbol() {
      return _currencySymbol.default[this.currency];
    }

    get styleNamespace() {
      return _podNames.default['shipment-rate-calculator'];
    }

    get countryNoMatchesMessage() {
      return `Country not available to ship from ${this.boutique.address_country.name}.`;
    }

    get extraShippingRatesDocumentUrl() {
      var _this$boutique$addres;

      return (_this$boutique$addres = this.boutique.addressCountryDocuments) === null || _this$boutique$addres === void 0 ? void 0 : _this$boutique$addres.shipping_rates.url;
    }

    get extraShippingSurchargesDocumentUrl() {
      var _this$boutique$addres2;

      return (_this$boutique$addres2 = this.boutique.addressCountryDocuments) === null || _this$boutique$addres2 === void 0 ? void 0 : _this$boutique$addres2.shipping_surcharges.url;
    }

    *searchCountries(query) {
      yield (0, _emberConcurrency.timeout)(250);
      const countries = yield _country.default.find({
        shipping_enabled: true,
        skip_links: true,
        sort: 'weengs_name',
        weengs_name: {
          $regex: `^${query}`,
          $options: 'i'
        }
      });
      return countries;
    }

    selectCountry(country) {
      this.selectedCountry = country;
      this.quickShip.prefilledShipment.setDeliveryAddressCountry(country);
    }

    continueToBook(ev) {
      ev.preventDefault();
      this.analytics.gtm({
        action: 'clicked_book_courier_calculator'
      });
      const {
        route,
        routeModels = []
      } = this.args;
      return this.router.transitionTo(route, ...routeModels);
    }

  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "analytics", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "brand", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "quickShip", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "router", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "selectedCountry", [_dec5], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "searchCountries", [_emberConcurrency.restartableTask], Object.getOwnPropertyDescriptor(_class.prototype, "searchCountries"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "selectCountry", [_dec6], Object.getOwnPropertyDescriptor(_class.prototype, "selectCountry"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "continueToBook", [_dec7], Object.getOwnPropertyDescriptor(_class.prototype, "continueToBook"), _class.prototype)), _class));
  _exports.default = ShipmentRateCalculatorComponent;
});