define("flaneur/pods/your-contract/route", ["exports", "flaneur/models/prospective-boutique", "flaneur/models/boutique"], function (_exports, _prospectiveBoutique, _boutique) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    session: Ember.inject.service(),

    model() {
      return _prospectiveBoutique.default.findByEmail( // http://offirgolan.github.io/ember-cp-validations/docs/modules/Basic.html#a-note-on-testing--object-containers
      Ember.getOwner(this).ownerInjection(), this.session.user.email);
    },

    async afterModel(model) {
      const {
        status,
        boutique: boutiqueId
      } = await model;

      if (status !== 'accepted') {
        return;
      }

      const boutique = await _boutique.default.findById(boutiqueId);
      return this.transitionTo('brand.boutiques.boutique.terms-and-conditions', boutique.brand, boutique);
    }

  });

  _exports.default = _default;
});