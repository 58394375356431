define("flaneur/models/model", ["exports", "ember-cached-decorator-polyfill", "flaneur/util/validation"], function (_exports, _emberCachedDecoratorPolyfill, _validation) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _class, _descriptor, _descriptor2;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  let Model = (_dec = Ember._tracked, _dec2 = Ember._tracked, (_class = class Model {
    constructor({
      validations
    } = {}) {
      _initializerDefineProperty(this, "disableValidations", _descriptor, this);

      _initializerDefineProperty(this, "_fieldsToValidate", _descriptor2, this);

      this._fieldsToValidate = validations;
    }
    /**
     * Override this function to customise the fieldsToValidate
     */


    get fieldsToValidate() {
      return this._fieldsToValidate;
    }

    get validations() {
      if (this.disableValidations) {
        return true;
      }

      return (0, _validation.default)(this, this.fieldsToValidate);
    }

    get isValid() {
      return this.validations === true;
    }

  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "disableValidations", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return true;
    }
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "_fieldsToValidate", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return {};
    }
  }), _applyDecoratedDescriptor(_class.prototype, "validations", [_emberCachedDecoratorPolyfill.cached], Object.getOwnPropertyDescriptor(_class.prototype, "validations"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "isValid", [_emberCachedDecoratorPolyfill.cached], Object.getOwnPropertyDescriptor(_class.prototype, "isValid"), _class.prototype)), _class));
  _exports.default = Model;
});