define("flaneur/pods/brand/boutiques/boutique/orders/populate", ["exports", "flaneur/models/manufacturer", "flaneur/models/product"], function (_exports, _manufacturer, _product) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.populateProductsStandardManufacturers = populateProductsStandardManufacturers;
  _exports.populateProductCategories = populateProductCategories;

  async function populateProductsStandardManufacturers(reservations) {
    const manufacturersIds = reservations.reduce((manufacturersIds, reservation) => {
      var _reservation$product;

      const manufacturerId = (_reservation$product = reservation.product) === null || _reservation$product === void 0 ? void 0 : _reservation$product.standard_manufacturer;

      if (!manufacturerId || manufacturersIds.indexOf(manufacturerId) >= 0) {
        return manufacturersIds;
      }

      manufacturersIds.addObject(manufacturerId);
      return manufacturersIds;
    }, []);
    const manufacturers = await _manufacturer.default.find({
      _id: manufacturersIds,
      fields: 'name'
    });
    return reservations.forEach(reservation => {
      var _reservation$product2;

      const manufacturerId = (_reservation$product2 = reservation.product) === null || _reservation$product2 === void 0 ? void 0 : _reservation$product2.standard_manufacturer;

      if (!manufacturerId) {
        return;
      }

      Ember.set(reservation, 'product.standard_manufacturer', manufacturers.findBy('_id', manufacturerId));
    });
  }

  async function populateProductCategories([reservation]) {
    const {
      product: {
        _id
      }
    } = reservation;
    const {
      products
    } = await _product.default.find({
      _id: _id,
      populate: 'categories'
    });
    const [product] = products;
    return Ember.set(reservation, 'product', product);
  }
});