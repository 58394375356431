define("flaneur/pods/brand/boutiques/boutique/packaging/orders/confirmation/route", ["exports", "flaneur/models/packaging/order"], function (_exports, _order) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  class PackagingOrdersConfirmationRoute extends Ember.Route {
    constructor(...args) {
      super(...args);

      _defineProperty(this, "queryParams", {
        orderNumber: {
          refreshModel: true
        }
      });
    }

    async model({
      orderNumber
    }) {
      if (!orderNumber) {
        return this.transitionTo('brand.boutiques.boutique.packaging.orders');
      }

      const {
        orders
      } = await _order.default.find({
        order_number: orderNumber
      });
      const [order] = orders;
      return order;
    }

  }

  _exports.default = PackagingOrdersConfirmationRoute;
});