define("flaneur/enums/frames-badge", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  const cloudinaryUrl = () => `https://trouva-media.imgix.net/rphc/logos/fallback-image.png`;

  var _default = {
    EXCLUSIVE: cloudinaryUrl('Exclusive'),
    MUST_HAVE: cloudinaryUrl('Must_Have'),
    IDEA_GIFT: cloudinaryUrl('Idea_Gift'),
    SUSTAINABLE_2: cloudinaryUrl('Sustainable'),
    EYE: cloudinaryUrl('Eye'),
    NEW: cloudinaryUrl('New'),
    BESTSELLER: cloudinaryUrl('Best_Seller'),
    SHOP_SMALL: cloudinaryUrl('Shop_small'),
    TOP_PICK: cloudinaryUrl('Top_pick'),
    SUSTAINABLE: cloudinaryUrl('Sustainable_copy'),
    WOW: cloudinaryUrl('Wow'),
    TAKE_A_MOMENT: cloudinaryUrl('Take_a_moment'),
    SHINE_BRIGHT: cloudinaryUrl('Shine_Bright'),
    SHOP_SMALL_NO_STAR: cloudinaryUrl('Shop_small_no_star'),
    NEW_IN: cloudinaryUrl('New_In'),
    LOVE: cloudinaryUrl('Love'),
    NEW_BRAND: cloudinaryUrl('New_Brand'),
    BLACK_OWNED: cloudinaryUrl('Black_Owned'),
    HANDMADE: cloudinaryUrl('Handmade'),
    BESTSELLER_STAR: cloudinaryUrl('Best_Seller_Star')
  };
  _exports.default = _default;
});