define("flaneur/pods/components/pages/products/bulk/upload/upload-step/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _dec8, _dec9, _dec10, _dec11, _dec12, _dec13, _dec14, _dec15, _dec16, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _descriptor6, _descriptor7, _descriptor8, _descriptor9, _descriptor10, _descriptor11;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  let UploadStep = (_dec = Ember.inject.service, _dec2 = Ember.inject.service, _dec3 = Ember.inject.service, _dec4 = Ember.inject.service, _dec5 = Ember.computed.alias('brand.currentBoutique'), _dec6 = Ember.computed.readOnly('productsBulkUploader.csvFileInfo'), _dec7 = Ember.computed.readOnly('productsBulkUploader.isUploading'), _dec8 = Ember.computed.readOnly('productsBulkUploader.isUploadingCsv'), _dec9 = Ember.computed.readOnly('productsBulkUploader.isUploadingImages'), _dec10 = Ember.computed.notEmpty('errors'), _dec11 = Ember.computed.or('hasErrors', 'productsBulkUploader.hasInvalidFileType', 'productsBulkUploader.hasInvalidHeaders'), _dec12 = Ember.computed('productsBulkUploader.{errors,isUploadError}'), _dec13 = Ember.computed('hasErrors', 'productsBulkUploader.hasUploadedCsv'), _dec14 = Ember.computed('isCsvUploadSuccessful', 'hasUploadErrors'), _dec15 = Ember._action, _dec16 = Ember._action, (_class = class UploadStep extends Ember.Component {
    constructor(...args) {
      super(...args);

      _initializerDefineProperty(this, "analytics", _descriptor, this);

      _initializerDefineProperty(this, "brand", _descriptor2, this);

      _initializerDefineProperty(this, "notify", _descriptor3, this);

      _initializerDefineProperty(this, "productsBulkUploader", _descriptor4, this);

      _initializerDefineProperty(this, "boutique", _descriptor5, this);

      _initializerDefineProperty(this, "csvFileInfo", _descriptor6, this);

      _initializerDefineProperty(this, "isUploading", _descriptor7, this);

      _initializerDefineProperty(this, "isUploadingCsv", _descriptor8, this);

      _initializerDefineProperty(this, "isUploadingImages", _descriptor9, this);

      _initializerDefineProperty(this, "hasErrors", _descriptor10, this);

      _initializerDefineProperty(this, "hasUploadErrors", _descriptor11, this);
    }

    get errors() {
      const {
        errors
      } = this.productsBulkUploader;
      return errors.filter(this.productsBulkUploader.isUploadError);
    }

    get isCsvUploadSuccessful() {
      return !this.hasErrors && this.productsBulkUploader.hasUploadedCsv;
    }

    get resultsTableClass() {
      if (this.isCsvUploadSuccessful) {
        return 'results--success';
      }

      if (this.hasUploadErrors) {
        return 'results--errors';
      }

      return '';
    }

    onDragOver(ev) {
      ev.preventDefault();
    }

    onDragEnter(ev) {
      ev.preventDefault();
      document.querySelector('.upload-zone').classList.add('upload-zone--dragover');
    }

    onDragLeave() {
      document.querySelector('.upload-zone').classList.remove('upload-zone--dragover');
    }

    onChangeCsvUploadStatus({
      current,
      total
    }) {
      const step = (1 / total).toFixed(6) * current;
      const progress = document.getElementById('progress');

      if (progress) {
        progress.style.transform = `scaleX(${step})`;
      }

      if (current === total) {
        const debounced = Ember.run.debounce(this, this.onFinishCsvUploadStatus, 300);
        Ember.trySet(this, 'onFinishUploadStatusDebounce', debounced);
        return;
      }

      Ember.run.cancel(this.onFinishUploadStatusDebounce);
    }

    onFinishCsvUploadStatus() {
      const progress = document.getElementById('progress');

      if (progress) {
        progress.style.transform = 'scaleX(0)';
      }
    }

    notifyCompleteUpload(isSuccess) {
      if (!('Notification' in window)) {
        return;
      }

      const title = `CSV was uploaded ${isSuccess ? 'successfully!' : 'with errors :('}`;
      const body = isSuccess ? 'You can now proceed to the review step' : 'Please review your information';

      const notify = () => new Notification(title, {
        body,
        icon: 'https://retail.trouva.com/assets/favicon.ico'
      });

      if (Notification.permission === 'granted') {
        notify();
        return;
      }

      if (Notification.permission !== 'denied') {
        Notification.requestPermission().then(permission => {
          if (permission === 'granted') {
            notify();
          }
        });
      }
    }

    onCompleteUpload() {
      const isSuccess = Ember.isEmpty(this.errors);
      this.analytics.gtm({
        action: 'bulk_upload_import_csv',
        label: isSuccess ? 'success' : 'fail'
      });
      this.notifyCompleteUpload(isSuccess);
    }

    onDrop(ev) {
      ev.preventDefault();
      this.onDragLeave();
      const {
        files
      } = ev.dataTransfer;
      this.importCsv({
        target: {
          files
        }
      });
    }

    async importCsv({
      target
    }) {
      const csvFile = target.files[0];
      await this.productsBulkUploader.importCsv(csvFile, this.boutique, Ember.run.bind(this, this.onChangeCsvUploadStatus));
      target.value = null;
      const {
        hasInvalidFileType,
        hasInvalidHeaders
      } = this.productsBulkUploader;

      if (hasInvalidFileType || hasInvalidHeaders) {
        this.set('isInvalidCsvModalOpen', true);
        this.analytics.gtm({
          action: 'bulk_upload_invalid_csv',
          label: hasInvalidFileType ? 'file_type' : 'headers'
        });
        return;
      }

      this.onCompleteUpload();
    }

  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "analytics", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "brand", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "notify", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "productsBulkUploader", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "boutique", [_dec5], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor6 = _applyDecoratedDescriptor(_class.prototype, "csvFileInfo", [_dec6], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor7 = _applyDecoratedDescriptor(_class.prototype, "isUploading", [_dec7], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor8 = _applyDecoratedDescriptor(_class.prototype, "isUploadingCsv", [_dec8], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor9 = _applyDecoratedDescriptor(_class.prototype, "isUploadingImages", [_dec9], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor10 = _applyDecoratedDescriptor(_class.prototype, "hasErrors", [_dec10], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor11 = _applyDecoratedDescriptor(_class.prototype, "hasUploadErrors", [_dec11], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "errors", [_dec12], Object.getOwnPropertyDescriptor(_class.prototype, "errors"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "isCsvUploadSuccessful", [_dec13], Object.getOwnPropertyDescriptor(_class.prototype, "isCsvUploadSuccessful"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "resultsTableClass", [_dec14], Object.getOwnPropertyDescriptor(_class.prototype, "resultsTableClass"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "onDrop", [_dec15], Object.getOwnPropertyDescriptor(_class.prototype, "onDrop"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "importCsv", [_dec16], Object.getOwnPropertyDescriptor(_class.prototype, "importCsv"), _class.prototype)), _class));
  _exports.default = UploadStep;
});