define("flaneur/pods/components/lightbox/component", ["exports", "siema", "flaneur/enums/key-code"], function (_exports, _siema, _keyCode) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _class;

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  let LightboxComponent = (_dec = Ember._action, _dec2 = Ember._action, _dec3 = Ember._action, (_class = class LightboxComponent extends Ember.Component {
    constructor(...args) {
      super(...args);

      _defineProperty(this, "tagName", '');

      _defineProperty(this, "siema", null);
    }

    didInsertElement() {
      super.didInsertElement(...arguments);
      const siema = new _siema.default({
        selector: '.carousel',
        loop: true,
        startIndex: this.startIndex || 0
      });
      this.set('siema', siema);
      this.set('handleKeyDown', Ember.run.bind(this, this.onKeyDown), false);
      document.addEventListener('keydown', this.handleKeyDown);
    }

    onKeyDown(e) {
      if (e.keyCode === _keyCode.default.ESC) {
        this.close();
        return;
      }

      if (e.keyCode === _keyCode.default.ARROW_LEFT) {
        this.previous();
        return;
      }

      if (e.keyCode === _keyCode.default.ARROW_RIGHT) {
        this.next();
      }
    }

    willDestroyElement() {
      super.willDestroyElement(...arguments);
      document.removeEventListener('keydown', this.handleKeyDown, false);
      this.siema.destroy();
    }

    previous() {
      this.siema.prev();
    }

    next() {
      this.siema.next();
    }

    close() {
      this.onClose();
    }

  }, (_applyDecoratedDescriptor(_class.prototype, "previous", [_dec], Object.getOwnPropertyDescriptor(_class.prototype, "previous"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "next", [_dec2], Object.getOwnPropertyDescriptor(_class.prototype, "next"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "close", [_dec3], Object.getOwnPropertyDescriptor(_class.prototype, "close"), _class.prototype)), _class));
  _exports.default = LightboxComponent;
});