define("flaneur/pods/components/category-select/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "93c4FVsv",
    "block": "{\"symbols\":[\"option\"],\"statements\":[[8,\"power-select\",[],[[\"@disabled\",\"@options\",\"@onChange\",\"@searchEnabled\",\"@searchField\",\"@matcher\",\"@selected\",\"@placeholder\"],[[34,0],[34,1],[30,[36,2],[[32,0],\"onChange\"],null],true,\"name\",[30,[36,2],[[32,0],\"matcher\"],null],[34,3],\"Select a category\"]],[[\"default\"],[{\"statements\":[[2,\"\\n  \"],[1,[32,1,[\"name\"]]],[2,\"\\n\"]],\"parameters\":[1]}]]],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"disabled\",\"categoryList\",\"action\",\"selectedCategory\"]}",
    "moduleName": "flaneur/pods/components/category-select/template.hbs"
  });

  _exports.default = _default;
});