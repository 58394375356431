define("flaneur/pods/components/tooltip/component", ["exports", "@glimmer/component", "ember-component-css/pod-names"], function (_exports, _component, _podNames) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const positions = ['top', 'bottom'];

  class TooltipComponent extends _component.default {
    constructor() {
      super(...arguments);
      (false && !(positions.includes(this.position)) && Ember.assert(`The position "${this.position}" passed to <Tooltip> is not valid. Please use "top" or "bottom".`, positions.includes(this.position)));
    }

    get clickable() {
      return this.args.clickable ? true : false;
    }

    get position() {
      var _this$args$position;

      return (_this$args$position = this.args.position) !== null && _this$args$position !== void 0 ? _this$args$position : 'bottom';
    }

    get isClickable() {
      return this.clickable;
    }

    get isPositionTop() {
      return this.position === 'top';
    }

    get styleNamespace() {
      return _podNames.default['tooltip'];
    }

  }

  _exports.default = TooltipComponent;
});