define("flaneur/pods/components/reservation-product-header/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "KQCbFlaA",
    "block": "{\"symbols\":[],\"statements\":[[10,\"div\"],[14,0,\"order-product-header\"],[12],[2,\"\\n  \"],[10,\"span\"],[12],[2,\"Product\"],[13],[2,\"\\n  \"],[10,\"span\"],[12],[2,\"Name\"],[13],[2,\"\\n  \"],[10,\"span\"],[12],[2,\"Brand\"],[13],[2,\"\\n  \"],[10,\"span\"],[12],[2,\"Price\"],[13],[2,\"\\n  \"],[10,\"span\"],[12],[2,\"Quantity\"],[13],[2,\"\\n  \"],[10,\"span\"],[12],[2,\"Variation\"],[13],[2,\"\\n  \"],[10,\"span\"],[12],[2,\"SKU\"],[13],[2,\"\\n  \"],[10,\"span\"],[12],[2,\"\\n\"],[6,[37,1],[[35,0]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"      Return status\\n\"]],\"parameters\":[]},{\"statements\":[[2,\"      Stock status\\n\"]],\"parameters\":[]}]]],[2,\"  \"],[13],[2,\"\\n\"],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"isReturn\",\"if\"]}",
    "moduleName": "flaneur/pods/components/reservation-product-header/template.hbs"
  });

  _exports.default = _default;
});