define("flaneur/helpers/mul", ["exports", "big.js"], function (_exports, _big) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.mul = mul;
  _exports.default = void 0;

  function mul([start = 0, ...values]) {
    return values.reduce((total, v) => total.mul(v), (0, _big.default)(start)).round(0, _big.default.roundUp).toNumber();
  }

  var _default = Ember.Helper.helper(mul);

  _exports.default = _default;
});