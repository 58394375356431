define("flaneur/enums/retail-permission-type", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    ALL: 'all',
    TERMS_AND_CONDITIONS: 't&c',
    STATEMENTS: 'statements',
    INSIGHTS: 'insights'
  };
  _exports.default = _default;
});