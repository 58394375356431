define("flaneur/mixins/price", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Mixin.create({
    toMoney(price) {
      if (Ember.isEmpty(price)) {
        return '0.00';
      }

      return parseFloat(price / 100).toFixed(2);
    }

  });

  _exports.default = _default;
});