define("flaneur/pods/services/quick-ship/new/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "JJZ9bzkj",
    "block": "{\"symbols\":[\"@model\"],\"statements\":[[8,\"pages/shipments/new\",[],[[\"@model\",\"@routePrefix\",\"@routeModels\",\"@backToHistory\",\"@submitForm\"],[[32,1],\"services.quick-ship\",[34,0],[32,0,[\"backToHistory\"]],[32,0,[\"submitForm\"]]]],null],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"array\"]}",
    "moduleName": "flaneur/pods/services/quick-ship/new/template.hbs"
  });

  _exports.default = _default;
});