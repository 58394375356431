define("flaneur/pods/components/order-loading-message/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    orderHistory: Ember.inject.service(),
    noOrders: true,
    orderSearched: Ember.computed.alias('orderHistory.orderSearched'),
    hasMoreOrders: false,
    boutique: null,
    orderMessage: Ember.computed('tab', 'noOrders', 'orderSearched', 'hasMoreOrders', function () {
      const {
        currentTab,
        noOrders
      } = this;

      if (noOrders) {
        return `order-loading-message/${currentTab}`;
      }

      return null;
    })
  });

  _exports.default = _default;
});