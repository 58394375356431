define("flaneur/pods/forgot/route", ["exports", "flaneur/models/user"], function (_exports, _user) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    model() {
      return _user.default.create(Ember.getOwner(this).ownerInjection(), {
        email: ''
      });
    },

    actions: {
      forgotPassword() {
        const user = this.currentModel;
        return user.validate({
          on: ['email']
        }).then(({
          validations
        }) => {
          if (validations.get('isInvalid')) {
            return Ember.RSVP.reject({
              validations
            });
          }
        }).then(() => user.forgot()).then(() => {
          this.notify.show('success', 'Instructions sent by email');
        }).catch(err => {
          // Check if validations error
          if (err.validations) {
            this.notify.show('error', 'Invalid email'); // Show API error messages
          } else {
            const message = err.responseJSON.errors[0].detail;
            this.notify.show('error', message);
          }
        });
      }

    }
  });

  _exports.default = _default;
});