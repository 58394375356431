define("flaneur/pods/components/pages/settings/index/component", ["exports", "flaneur/config/environment", "ember-concurrency", "flaneur/models/area", "flaneur/models/boutique/validations"], function (_exports, _environment, _emberConcurrency, _area, _validations) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const fields = ['name', 'address', 'logo', 'oh', 'cover.url', 'cover_thumbnail.url', 'mini_description', 'description', 'primary_contact_name', 'founder_image', 'founder_quote', 'story_title', 'story_text', 'story_image'];
  const Validations = (0, _validations.getSpecificValidations)(fields, {
    prefix: 'boutique.'
  });

  var _default = Ember.Component.extend(Validations, {
    analytics: Ember.inject.service(),
    session: Ember.inject.service(),
    // Public API
    boutique: null,

    onSave() {},

    didInsertElement() {
      this._super(...arguments);

      Ember.set(this.boutique, 'fields', fields);
    },

    // Private API
    classNames: ['subpage'],
    isAdmin: Ember.computed('session.user.{isAdmin,isRetailAdmin}', function () {
      const {
        isAdmin,
        isRetailAdmin
      } = this.session.user;
      return isAdmin || isRetailAdmin;
    }),
    imageFolder: Ember.computed('boutique.brand', function () {
      return `brand/${this.boutique.brand}`;
    }),
    linkToTrouva: Ember.computed('boutique.slug', function () {
      return `${_environment.default.retailURL}/boutiques/${this.boutique.slug}`;
    }),
    _searchArea: (0, _emberConcurrency.task)(function* (value) {
      yield (0, _emberConcurrency.timeout)(500);
      return yield _area.default.find({
        name: {
          $regex: value,
          $options: 'i'
        }
      });
    }).restartable(),
    actions: {
      onChangeOpeningHours(openingHours) {
        Ember.set(this.boutique, 'oh', openingHours);
        this.analytics.gtm({
          action: 'edited_opening_hours'
        });
      },

      onUploadLogo(logo) {
        Ember.set(this.boutique, 'logo', logo);
        this.analytics.gtm({
          action: 'edited_boutique_logo'
        });
      },

      onUploadCover(cover) {
        Ember.set(this.boutique, 'cover', cover);
        this.analytics.gtm({
          action: 'added_cover_photo'
        });
      },

      onUploadCoverThumbnail(coverThumbnail) {
        Ember.set(this.boutique, 'cover_thumbnail', coverThumbnail);
        this.analytics.gtm({
          action: 'added_shop_front_image'
        });
      },

      removeImage(field) {
        Ember.set(this.boutique, field, {});

        if (field === 'cover') {
          return this.analytics.gtm({
            action: 'removed_cover_photo'
          });
        }

        if (field === 'cover_thumbnail') {
          return this.analytics.gtm({
            action: 'removed_shop_front_image'
          });
        }

        if (field === 'founder_image') {
          return this.analytics.gtm({
            action: 'removed_founder_photo'
          });
        }
      },

      selectArea(area) {
        return this.boutique.areas.push(area);
      },

      async save() {
        const {
          validations
        } = await this.validations.validate();
        return this.onSave(fields, validations);
      }

    }
  });

  _exports.default = _default;
});