define("flaneur/pods/components/order-status/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "ilGQ5g8u",
    "block": "{\"symbols\":[\"tab\",\"&default\"],\"statements\":[[6,[37,4],[[30,[36,3],[[30,[36,3],[[35,2]],null]],null]],null,[[\"default\"],[{\"statements\":[[2,\"  \"],[10,\"div\"],[15,0,[32,1,[\"status\"]]],[12],[2,\"\\n\"],[6,[37,1],[[30,[36,0],[[32,1,[\"status\"]],\"active\"],null]],null,[[\"default\"],[{\"statements\":[[2,\"      \"],[8,\"fa-icon\",[[24,0,\"fa\"]],[[\"@icon\",\"@prefix\"],[\"check\",\"far\"]],null],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"    \"],[1,[32,1,[\"label\"]]],[2,\"\\n  \"],[13],[2,\"\\n  \"],[10,\"span\"],[15,0,[31,[\"triangle \",[32,1,[\"status\"]]]]],[12],[13],[2,\"\\n\"]],\"parameters\":[1]}]]],[2,\"\\n\"],[18,2,null],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"eq\",\"if\",\"tabs\",\"-track-array\",\"each\"]}",
    "moduleName": "flaneur/pods/components/order-status/template.hbs"
  });

  _exports.default = _default;
});