define("flaneur/pods/components/pages/frames/index/component", ["exports", "@glimmer/component", "ember-component-css/pod-names", "flaneur/config/environment", "@datadog/browser-rum"], function (_exports, _component, _podNames, _environment, _browserRum) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _class, _descriptor, _descriptor2, _descriptor3;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  let PagesFramesIndexComponent = (_dec = Ember.inject.service, _dec2 = Ember.inject.service, _dec3 = Ember._tracked, _dec4 = Ember._action, _dec5 = Ember._action, _dec6 = Ember._action, _dec7 = Ember._action, (_class = class PagesFramesIndexComponent extends _component.default {
    constructor(...args) {
      super(...args);

      _initializerDefineProperty(this, "analytics", _descriptor, this);

      _initializerDefineProperty(this, "notify", _descriptor2, this);

      _initializerDefineProperty(this, "archivingFrame", _descriptor3, this);
    }

    get styleNamespace() {
      return _podNames.default['pages/frames/index'];
    }

    frameTrouvaWebUrl({
      slug
    }) {
      return `${_environment.default.retailURL}/frames/${slug}`;
    }

    async publish(frame) {
      try {
        await frame.publish();
        this.notify.show('success', 'Congrats! You new Frame was successfully published 🎉<br />Share it with your customers and Instagram followers by tapping the share button below.', 7500);
        this.analytics.gtm({
          action: 'frames_published'
        });
      } catch (e) {
        this.notify.show('error', "Oops! We're unable to publish your Frame until all content has been provided. Please fill all the missing fields.", 7500);

        _browserRum.datadogRum.addError(e);
      }
    }

    async archive(frame) {
      try {
        await frame.archive();
        this.archivingFrame = null;
        this.notify.show('success', 'Your Frame was successfully archived!');
        this.analytics.gtm({
          action: 'frames_archived'
        });
      } catch (e) {
        this.notify.show('error', 'Oops! An error occured while archiving your Frame.');

        _browserRum.datadogRum.addError(e);
      }
    }

    onCopySuccess() {
      this.notify.show('success', 'Link copied!');
    }

  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "analytics", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "notify", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "archivingFrame", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "frameTrouvaWebUrl", [_dec4], Object.getOwnPropertyDescriptor(_class.prototype, "frameTrouvaWebUrl"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "publish", [_dec5], Object.getOwnPropertyDescriptor(_class.prototype, "publish"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "archive", [_dec6], Object.getOwnPropertyDescriptor(_class.prototype, "archive"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "onCopySuccess", [_dec7], Object.getOwnPropertyDescriptor(_class.prototype, "onCopySuccess"), _class.prototype)), _class));
  _exports.default = PagesFramesIndexComponent;
});