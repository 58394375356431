define("flaneur/enums/packaging-delivery-amount", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    GBR: 795,
    FRA: 1490,
    DEU: 1290
  };
  _exports.default = _default;
});