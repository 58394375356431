define("flaneur/models/product-amendment", ["exports", "flaneur/mixins/request", "flaneur/util/default-to"], function (_exports, _request, _defaultTo) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function _extends() { _extends = Object.assign || function (target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i]; for (var key in source) { if (Object.prototype.hasOwnProperty.call(source, key)) { target[key] = source[key]; } } } return target; }; return _extends.apply(this, arguments); }

  const ProductAmendment = Ember.Object.extend(_request.default, {
    note: '',
    status: 'pending',
    created_at: (0, _defaultTo.default)(new Date()),
    schemaFields: (0, _defaultTo.default)(['_id', 'note', 'status', 'attribute', 'product']),

    /**
     * Get schema fields when no arguments passed to getProperties
     *
     * @return {Object} Object of key/value pairs
     */
    getProperties() {
      if (!arguments.length) return this._super(this.schemaFields);
      return this._super(...arguments);
    },

    /**
     * Creates an amendment if the amendment is not stored,
     * Updates the amendment if it's already saved.
     *
     * @return {Object} Saved/Created amendment
     */
    save() {
      const _this = this,
            data = _extends({}, _this);

      const method = data._id ? 'put' : 'post';
      const url = method === 'put' ? `product_amendments/${data._id}` : 'product_amendments';
      return this.request({
        method,
        url: this.apiURL(url),
        data: JSON.stringify(data)
      }).then(res => {
        this.setProperties(res.product_amendments[0]);
        return res.product_amendments[0];
      });
    },

    resolve() {
      const _id = this._id;
      return this.request({
        method: 'PUT',
        url: this.apiURL(`product_amendments/resolve/${_id}`),
        data: JSON.stringify({
          populate: 'created_by',
          boutique: this.boutique
        })
      }).then(res => {
        const amendment = Ember.get(res, 'product_amendments.firstObject'); // Hack to work around the populate not working on PUT

        delete amendment.created_by;
        this.setProperties(amendment);
        return this;
      });
    }

  });
  ProductAmendment.reopenClass(_request.default, {
    /**
     * Groups the filtered amendments by the attribute
     * @param  {Object[]}  amendments   - Array of amendment instances
     * @return {Object}                 - Returns an object containing the grouped amendments
     */
    _groupPerField(amendments = []) {
      const amendmentsPerField = {}; // Remove rejected and approved amendments

      const filteredAmendments = amendments.filter(am => !['rejected', 'approved'].includes(am.status)); // Get all the attributes

      const attributes = filteredAmendments.map(am => am.attribute); // Compose the amendmentsPerField object

      attributes.forEach(at => {
        amendmentsPerField[at] = filteredAmendments.find(am => am.attribute === at);
      });
      return amendmentsPerField;
    },

    /**
     * Retrieve amendments for product
     * @param  {Object} data Data
     * @return {Object}      Promise
     */
    findByProduct(boutique_id, product_id) {
      return this.request({
        method: 'GET',
        url: this.apiURL('product_amendments'),
        data: {
          boutique: boutique_id,
          product: product_id,
          sort: '-created_at',
          populate: 'created_by'
        }
      }).then(res => {
        const amendments = res.product_amendments.map(am => this.create(am));
        return {
          product_amendments: amendments,
          field_product_amendments: this._groupPerField(amendments)
        };
      });
    },

    /**
     * Query the product amendments resource
     *
     * @param  {Object} query   - Parameters to send to the query
     * @return {Object}         - Product amendments with meta
     */
    find(query = {}) {
      return this.request({
        method: 'POST',
        url: this.apiURL('product_amendments'),
        headers: {
          'X-HTTP-Method-Override': 'GET'
        },
        data: JSON.stringify(query)
      }).then(res => {
        const amendments = res.product_amendments.map(am => this.create(am));
        return {
          product_amendments: amendments,
          meta: res.meta || {}
        };
      });
    }

  });
  var _default = ProductAmendment;
  _exports.default = _default;
});