define("flaneur/pods/components/form-static/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "8lJcqix7",
    "block": "{\"symbols\":[],\"statements\":[[10,\"div\"],[14,0,\"item item-static\"],[12],[2,\"\\n  \"],[10,\"p\"],[14,0,\"item-label\"],[12],[1,[34,0]],[13],[2,\"\\n  \"],[10,\"div\"],[14,0,\"item-form\"],[12],[2,\"\\n    \"],[10,\"p\"],[14,0,\"form-element\"],[12],[2,\"\\n      \"],[10,\"span\"],[14,0,\"form-control-static\"],[12],[1,[34,1]],[13],[2,\"\\n    \"],[13],[2,\"\\n  \"],[13],[2,\"\\n\"],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"label\",\"value\"]}",
    "moduleName": "flaneur/pods/components/form-static/template.hbs"
  });

  _exports.default = _default;
});