define("flaneur/pods/components/form-flows/product/summary-details/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    media: Ember.inject.service(),
    classNames: ['summary-details'],
    elementId: 'summary-details',
    productDetailsOpen: false,
    shouldShowStockLineOptions: Ember.computed('product.stock_lines.@each.variants', function () {
      const {
        stock_lines
      } = this.product;
      const hasOneStockLine = stock_lines.length === 1;
      const firstStockLineHasNoVariants = Ember.get(stock_lines, '0.variants.length') === 0;
      const firstStockLineHasNoSize = Ember.get(stock_lines, '0.size') === undefined;
      return !(hasOneStockLine && firstStockLineHasNoVariants && firstStockLineHasNoSize);
    }),
    stockLineOptions: Ember.computed('product.stock_lines.@each.variants', function () {
      return this.product.stock_lines.map(stockLine => {
        if (stockLine.variants.length) {
          return stockLine.variants.map(variant => variant.value).join(' | ');
        }

        if (stockLine.size && stockLine.size.label) {
          return stockLine.size && stockLine.size.label;
        } // ember-power-select doesn't allow undefined values
        // so fallback to an empty string


        return '';
      });
    }),
    firstStockLine: Ember.computed('product.stock_lines.length', function () {
      return this.product.stock_lines[0];
    }),
    dimensions: Ember.computed.reads('firstStockLine.dimensions'),
    hasDimensions: Ember.computed('dimensions.{width,height,length}', function () {
      const {
        dimensions
      } = this;
      return dimensions.width > 0 && dimensions.height > 0 && dimensions.length > 0;
    }),
    care_instructions: Ember.computed.reads('firstStockLine.care_instructions'),
    selectedStockLineOption: Ember.computed('stockLineOptions', {
      get() {
        return this.stockLineOptions[0];
      },

      set(_, value) {
        return value;
      }

    }),
    actions: {
      changeStockLine(selectedOption) {
        this.set('selectedStockLineOption', selectedOption);
      }

    }
  });

  _exports.default = _default;
});