define("flaneur/pods/components/order-loading-message/active/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "2gH1+NAT",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[18,1,null],[2,\"\\n\\n\"],[8,\"fa-icon\",[[24,0,\"fa\"]],[[\"@icon\",\"@prefix\",\"@size\"],[\"check-circle\",\"fas\",\"3x\"]],null],[2,\"\\n\"],[10,\"div\"],[14,0,\"empty-state-title\"],[12],[2,\"\\n  GREAT!\\n\"],[13],[2,\"\\n\"],[10,\"div\"],[14,0,\"empty-state-message\"],[12],[2,\"\\n  You have confirmed all your orders\\n\"],[13],[2,\"\\n\\n\"],[6,[37,2],[[30,[36,1],[[35,0]],null]],null,[[\"default\"],[{\"statements\":[[2,\"  \"],[10,\"div\"],[14,0,\"empty-state-next-step\"],[12],[2,\"\\n    \"],[8,\"link-to\",[],[[\"@route\",\"@model\"],[\"brand.boutiques.boutique.orders.stage\",\"collection\"]],[[\"default\"],[{\"statements\":[[2,\"\\n      \"],[10,\"p\"],[12],[2,\"Next step:\"],[13],[2,\"\\n      Print your shipping labels and receipts.\\n    \"]],\"parameters\":[]}]]],[2,\"\\n  \"],[13],[2,\"\\n\"]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"isBoutiqueShippingProviderOther\",\"not\",\"if\"]}",
    "moduleName": "flaneur/pods/components/order-loading-message/active/template.hbs"
  });

  _exports.default = _default;
});