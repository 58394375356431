define("flaneur/pods/components/nav-bar-alert/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    notify: Ember.inject.service(),
    actions: {
      hide(notification) {
        this.notify.hide(notification);
      }

    }
  });

  _exports.default = _default;
});