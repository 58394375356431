define("flaneur/pods/components/pagination/component", ["exports", "@glimmer/component", "ember-component-css/pod-names"], function (_exports, _component, _podNames) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  class PaginationComponent extends _component.default {
    constructor() {
      super(...arguments);
      this.separator = this.args.separator || '...';
      this.previousLabel = this.args.previousLabel || 'Previous';
      this.nextLabel = this.args.nextLabel || 'Next';
    }

    get total() {
      return parseInt(this.args.total, 10) || 0;
    }

    get hasPrevious() {
      return this.args.page > 1;
    }

    get hasNext() {
      return this.total > this.args.page;
    }

    get isEmpty() {
      return this.total <= 0;
    }

    get pages() {
      const total = this.total;
      const page = parseInt(this.args.page, 10) || 1;

      if (total <= 6) {
        return this.getArrayOfIntegers(1, total);
      }

      if (page < 5) {
        return [...this.getArrayOfIntegers(1, 5), this.separator, total];
      }

      if (page > total - 4) {
        return [1, this.separator, ...this.getArrayOfIntegers(total - 3, total)];
      }

      return [1, this.separator, page - 1, page, page + 1, this.separator, total];
    }

    get styleNamespace() {
      return _podNames.default['pagination'];
    }

    getArrayOfIntegers(start, end) {
      const array = [];

      for (let i = start; i <= end; i++) {
        array.push(i);
      }

      return array;
    }

  }

  _exports.default = PaginationComponent;
});