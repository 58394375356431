define("flaneur/pods/components/generic-modal/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "l3oUJTvs",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[10,\"div\"],[14,0,\"generic-modal__content\"],[12],[2,\"\\n  \"],[11,\"a\"],[24,0,\"generic-modal__close\"],[4,[38,0],[[32,0],\"dismiss\"],null],[12],[2,\"\\n    \"],[8,\"fa-icon\",[[24,0,\"fa\"]],[[\"@icon\",\"@prefix\"],[\"times\",\"far\"]],null],[2,\"\\n  \"],[13],[2,\"\\n  \"],[10,\"div\"],[14,0,\"generic-modal__body\"],[12],[2,\"\\n    \"],[18,1,[[30,[36,2],null,[[\"body\",\"dismiss\"],[[30,[36,1],[\"empty-template\"],null],[30,[36,0],[[32,0],\"dismiss\"],null]]]]]],[2,\"\\n  \"],[13],[2,\"\\n\\n  \"],[10,\"div\"],[14,0,\"generic-modal__footer\"],[12],[2,\"\\n    \"],[18,1,[[30,[36,2],null,[[\"actions\",\"dismiss\"],[[30,[36,1],[\"empty-template\"],null],[30,[36,0],[[32,0],\"dismiss\"],null]]]]]],[2,\"\\n  \"],[13],[2,\"\\n\"],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"action\",\"component\",\"hash\"]}",
    "moduleName": "flaneur/pods/components/generic-modal/template.hbs"
  });

  _exports.default = _default;
});