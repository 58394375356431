define("flaneur/pods/brand/boutiques/boutique/orders/route", ["exports", "flaneur/pods/brand/boutiques/boutique/orders/fetch"], function (_exports, _fetch) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    brand: Ember.inject.service(),
    orderHistory: Ember.inject.service(),

    model() {
      const boutiqueId = this.brand.currentBoutique._id;
      return (0, _fetch.fetchOrdersCount)(boutiqueId);
    },

    afterModel(model, {
      targetName
    }) {
      this._super(...arguments);
      /**
       * @TODO: Improve this outdated behaviour inherited from the previous implementation
       *
       * You may notice that this.paramsFor sometimes works when referring to a child route,
       * but this behavior should not be relied upon as only ancestor routes are certain
       * to be loaded in time.
       *
       * Reference: https://api.emberjs.com/ember/3.15/classes/Route/methods/paramsFor?anchor=paramsFor
       */


      const {
        ordernumber
      } = this.paramsFor(targetName);
      Ember.set(model, 'ordernumber', ordernumber);
    },

    actions: {
      willTransition() {
        this.refresh();
      }

    }
  });

  _exports.default = _default;
});