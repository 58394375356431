define("flaneur/pods/components/resource-search/result/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    tagName: 'li',
    classNames: ['resource-search__result'],
    result: null,
    index: null,

    onSelect() {},

    click() {
      const onSelect = this.onSelect;
      const result = this.result;
      return onSelect(result);
    }

  });

  _exports.default = _default;
});