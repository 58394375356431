define("flaneur/pods/components/reservation-product/component", ["exports", "flaneur/config/environment", "flaneur/enums/boutique-restock-recommendation-type"], function (_exports, _environment, _boutiqueRestockRecommendationType) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    router: Ember.inject.service(),
    boutiqueRestockInformation: Ember.inject.service(),

    didReceiveAttrs() {
      this._super(...arguments);

      this.setRestockRecommendation();
    },

    setRestockRecommendation() {
      if (!this.productsRecommendations) {
        return;
      }

      const currentProductRecommendation = this.productsRecommendations[this.productId];

      if (!currentProductRecommendation) {
        return;
      }

      this.set('restockRecommendation', currentProductRecommendation[0]);
    },

    isEdit: Ember.computed('restockRecommendation', 'boutiqueRestockInformation.created.[]', function () {
      return this.boutiqueRestockInformation.getEditableRestockInformation(this.restockRecommendation);
    }),
    stockLineLabel: Ember.computed( // eslint-disable-next-line ember/use-brace-expansion
    'reservation', 'reservation.selected_size', 'reservation.stock_line', 'reservation.stock_line.variants', function () {
      if (!this.reservation) {
        return;
      }

      const {
        stock_line: stockLine,
        selected_size: selectedSize
      } = this.reservation;
      const {
        size,
        variants
      } = stockLine;

      if (variants && variants.length) {
        return variants.map(({
          value
        }) => value).join(' | ');
      }

      if (selectedSize) {
        return selectedSize;
      }

      if (size && size.label) {
        return size.label;
      }

      return;
    }),
    isReturn: false,
    reservation: null,
    moreThanOneReservation: false,
    isFirstReservation: false,
    restockRecommendation: null,
    boutiqueRestockRecommendationType: _boutiqueRestockRecommendationType.default,
    requestedReturnReason: Ember.computed.alias('reservation.requested_return_reason'),
    requestedReturnAt: Ember.computed.alias('reservation.requested_return_at'),
    reservationCssClass: Ember.computed('reservation.status', function () {
      const reservationStatus = this.get('reservation.status');

      if (reservationStatus === 'active') {
        return 'product-active';
      }

      if (reservationStatus === 'success') {
        return 'product-success';
      }

      if (reservationStatus === 'fail' || reservationStatus === 'fail cancelled' || reservationStatus === 'success from elsewhere') {
        return 'product-fail';
      }

      if (reservationStatus === 'fail other' || reservationStatus === 'fail returned') {
        return 'fail-other';
      }

      return '';
    }),
    productRetailUrl: Ember.computed('productSlug', function () {
      return `${_environment.default.retailURL}/products/${this.productSlug}`;
    }),
    productSlug: Ember.computed('reservation.product', function () {
      const {
        product,
        product_slug
      } = this.reservation;
      return product ? product.slug : product_slug;
    }),
    productName: Ember.computed('reservation.product', function () {
      const {
        product,
        product_slug
      } = this.reservation;
      return product ? product.name : product_slug;
    }),
    productId: Ember.computed.alias('reservation.product.id'),
    thumbnail: Ember.computed('reservation.product.thumbnail.url', 'reservation.stock_line', function () {
      var _stockLine$images;

      const productThumbnail = this.reservation.product.thumbnail.url;
      const stockLine = this.reservation.stock_line;
      const sortedStockLineImages = Array.from((_stockLine$images = stockLine === null || stockLine === void 0 ? void 0 : stockLine.images) !== null && _stockLine$images !== void 0 ? _stockLine$images : []).sort((a, b) => a.display_order - b.display_order);
      const [firstImage = {
        url: ''
      }] = sortedStockLineImages;
      const stockLineImage = firstImage.url;
      return stockLineImage ? stockLineImage : productThumbnail;
    }),
    actions: {
      openRestockModal() {
        this.router.transitionTo({
          queryParams: {
            'restock-product': this.productId
          }
        });
        this.analytics.gtm({
          action: 'restock-modal-open',
          label: this.restockRecommendation.recommendationId ? 'banner-edit' : 'banner-add'
        });
      }

    }
  });

  _exports.default = _default;
});