define("flaneur/pods/brand/boutiques/boutique/settings/controller", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    active: false,
    to: '',
    isDiscarded: false
  });

  _exports.default = _default;
});