define("flaneur/models/notification/index", ["exports", "fetch", "flaneur/config/environment"], function (_exports, _fetch, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _class, _descriptor;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  let Notification = (_dec = Ember._tracked, (_class = class Notification {
    constructor({
      _id,
      title,
      body,
      button,
      route,
      external_link,
      schedule,
      filters,
      featured,
      created_at,
      updated_at
    }) {
      _initializerDefineProperty(this, "isNew", _descriptor, this);

      this._id = _id;
      this.title = title;
      this.body = body;
      this.button = button;
      this.route = route;
      this.external_link = external_link;
      this.schedule = schedule;
      this.filters = filters;
      this.featured = featured;
      this.created_at = created_at;
      this.updated_at = updated_at;
    }

    get date() {
      var _this$schedule$start_;

      return (_this$schedule$start_ = this.schedule.start_date) !== null && _this$schedule$start_ !== void 0 ? _this$schedule$start_ : this.created_at;
    }

    static async find() {
      const url = `${_environment.default.apiURL}/api/v2/static/flaneur-notifications-centre.json`;
      const res = await (0, _fetch.default)(url, {
        method: 'GET',
        credentials: 'include',
        cache: false
      });

      if (!res.ok) {
        throw new Error('unable to get notifications');
      }

      const result = await res.json();
      const meta = {
        page: 1,
        total: result.static.notifications.length
      };
      const notifications = result.static.notifications.map(notification => new Notification(notification)).sort((a, b) => b.created_at.localeCompare(a.created_at));
      return {
        notifications,
        meta
      };
    }

  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "isNew", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return false;
    }
  })), _class));
  _exports.default = Notification;
});