define("flaneur/pods/brand/boutiques/boutique/orders/stage/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "ZpkrvrQj",
    "block": "{\"symbols\":[],\"statements\":[[10,\"div\"],[14,0,\"orders-stage\"],[12],[2,\"\\n  \"],[1,[30,[36,2],[[30,[36,1],[\"pages/orders/\",[32,0,[\"model\",\"stage\"]]],null]],[[\"model\",\"addLoading\",\"bookShipment\",\"inStock\",\"loadMore\",\"markAsCollected\",\"refresh\",\"requestedManualLabel\",\"resetSearch\",\"setFailOther\",\"updateReservationBoutique\"],[[32,0,[\"model\"]],[30,[36,0],[\"addLoading\"],null],[30,[36,0],[\"bookShipment\"],null],[30,[36,0],[\"inStock\"],null],[30,[36,0],[\"loadMore\"],null],[30,[36,0],[\"markAsCollected\"],null],[30,[36,0],[\"refresh\"],null],[30,[36,0],[\"requestedManualLabel\"],null],[30,[36,0],[\"resetSearch\"],null],[30,[36,0],[\"setFailOther\"],null],[30,[36,0],[\"updateReservationBoutique\"],null]]]]],[2,\"\\n\"],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"route-action\",\"concat\",\"component\"]}",
    "moduleName": "flaneur/pods/brand/boutiques/boutique/orders/stage/template.hbs"
  });

  _exports.default = _default;
});