define("flaneur/pods/components/ember-power-select-no-matches/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "Mh0isa6K",
    "block": "{\"symbols\":[\"@message\"],\"statements\":[[10,\"div\"],[15,0,[31,[\"ember-power-select-no-matches \",[32,0,[\"styleNamespace\"]]]]],[12],[2,\"\\n  \"],[8,\"fa-icon\",[],[[\"@icon\",\"@prefix\"],[\"search\",\"far\"]],null],[2,\"\\n  \"],[1,[32,1]],[2,\"\\n\"],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[]}",
    "moduleName": "flaneur/pods/components/ember-power-select-no-matches/template.hbs"
  });

  _exports.default = _default;
});