define("flaneur/pods/components/form-holidays/component", ["exports", "@glimmer/component", "ember-component-css/pod-names", "date-fns"], function (_exports, _component, _podNames, _dateFns) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _dec5, _class, _descriptor, _descriptor2;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  let FormHolidaysComponent = (_dec = Ember.inject.service, _dec2 = Ember.inject.service, _dec3 = Ember._action, _dec4 = Ember._action, _dec5 = Ember._action, (_class = class FormHolidaysComponent extends _component.default {
    constructor(...args) {
      super(...args);

      _initializerDefineProperty(this, "analytics", _descriptor, this);

      _initializerDefineProperty(this, "brand", _descriptor2, this);

      _defineProperty(this, "range", 35);

      _defineProperty(this, "dateFormat", 'dd MMMM yyyy');
    }

    get styleNamespace() {
      return _podNames.default['form-holidays'];
    }

    get boutique() {
      return this.brand.currentBoutique;
    }

    get areHolidaysEnabled() {
      return Ember.isPresent(this.args.from) && Ember.isPresent(this.args.to);
    }

    get fromAsDate() {
      return this.args.from ? (0, _dateFns.parseISO)(this.args.from) : (0, _dateFns.startOfDay)(new Date());
    }

    get toAsDate() {
      return this.args.to ? (0, _dateFns.parseISO)(this.args.to) : (0, _dateFns.startOfDay)(new Date());
    }

    nextDay(date = new Date()) {
      return (0, _dateFns.startOfDay)((0, _dateFns.addDays)(date, 1));
    }

    getDays(startDate, endDate) {
      const dates = [];
      let currDate = (0, _dateFns.startOfDay)(startDate);
      let lastDate = (0, _dateFns.startOfDay)(endDate);

      if ((0, _dateFns.differenceInDays)(currDate, lastDate)) {
        dates.push(currDate);
      }

      currDate = (0, _dateFns.addDays)(currDate, 1);

      while ((0, _dateFns.differenceInDays)((0, _dateFns.addDays)(currDate, 1), lastDate) < 0) {
        dates.push(currDate);
        currDate = (0, _dateFns.addDays)(currDate, 1);
      }

      return dates;
    }

    get fromSelected() {
      return this.args.from ? this.fromDates.find(({
        value
      }) => (0, _dateFns.isSameDay)(value, this.fromAsDate)) : null;
    }

    get toSelected() {
      return this.args.to ? this.toDates.find(({
        value
      }) => (0, _dateFns.isSameDay)(value, this.toAsDate)) : null;
    }

    get fromDates() {
      const now = new Date();
      const lastDay = (0, _dateFns.addDays)(now, this.range);
      const days = this.getDays(now, lastDay).map(day => {
        return {
          value: day,
          display: (0, _dateFns.format)(day, this.dateFormat)
        };
      });
      const includeCurrentHolidayFrom = !(0, _dateFns.isSameDay)(this.fromAsDate, now) && !(0, _dateFns.isWithinInterval)(this.fromAsDate, {
        start: now,
        end: lastDay
      });

      if (includeCurrentHolidayFrom) {
        days.unshift({
          value: this.fromAsDate,
          display: (0, _dateFns.format)(this.fromAsDate, this.dateFormat)
        });
      }

      return days;
    }

    get toDates() {
      const now = new Date();
      const nextDay = (0, _dateFns.addDays)(this.fromAsDate, 1);
      const lastDay = (0, _dateFns.addDays)(this.fromAsDate, this.range);
      const days = this.getDays(nextDay, lastDay).map(day => ({
        value: day,
        display: (0, _dateFns.format)(day, this.dateFormat)
      }));
      const includeCurrentHolidayTo = !(0, _dateFns.isSameDay)(this.toAsDate, now) && !(0, _dateFns.isWithinInterval)(this.toAsDate, {
        start: nextDay,
        end: lastDay
      });

      if (includeCurrentHolidayTo) {
        days.unshift({
          value: this.toAsDate,
          display: (0, _dateFns.format)(this.toAsDate, this.dateFormat)
        });
      }

      return days;
    }

    onChangeTo({
      value: to
    }) {
      return this.args.onChange({
        from: this.fromAsDate,
        to
      });
    }

    onChangeFrom({
      value: from
    }) {
      if ((0, _dateFns.isAfter)(from, this.toAsDate)) {
        return this.args.onChange({
          from,
          to: this.nextDay(from)
        });
      }

      const isToWithinRange = (0, _dateFns.isWithinInterval)(this.toAsDate, {
        start: from,
        end: (0, _dateFns.addDays)(from, this.range)
      });

      if (!isToWithinRange) {
        return this.args.onChange({
          from,
          to: this.nextDay(from)
        });
      }

      return this.args.onChange({
        from,
        to: this.toAsDate
      });
    }

    toggleHolidays() {
      if (this.areHolidaysEnabled) {
        this.analytics.gtm({
          action: 'disabled_holiday_mode'
        });
        this.args.onChange({
          from: null,
          to: null
        });
        return;
      }

      this.analytics.gtm({
        action: 'enabled_holiday_mode'
      });
      this.args.onChange({
        from: (0, _dateFns.startOfDay)(new Date()),
        to: this.nextDay()
      });
    }

  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "analytics", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "brand", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "onChangeTo", [_dec3], Object.getOwnPropertyDescriptor(_class.prototype, "onChangeTo"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "onChangeFrom", [_dec4], Object.getOwnPropertyDescriptor(_class.prototype, "onChangeFrom"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "toggleHolidays", [_dec5], Object.getOwnPropertyDescriptor(_class.prototype, "toggleHolidays"), _class.prototype)), _class));
  _exports.default = FormHolidaysComponent;
});