define("flaneur/models/size", ["exports", "flaneur/mixins/request"], function (_exports, _request) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const Size = Ember.Object.extend(_request.default, {});
  Size.reopenClass(_request.default, {
    // Class methods
    find(query) {
      return this.request({
        method: 'GET',
        url: this.apiURL('sizes'),
        data: query
      }).then(res => {
        return res.sizes.map(size => {
          return this.create(size);
        });
      });
    },

    findByIds(ids) {
      return this.request({
        method: 'GET',
        url: this.apiURL(`sizes/${ids}`)
      }).then(res => {
        return res.sizes.map(size => {
          return this.create(size);
        });
      });
    }

  });
  var _default = Size;
  _exports.default = _default;
});