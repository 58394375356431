define("flaneur/pods/components/banner/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "B+Qg2z20",
    "block": "{\"symbols\":[\"&default\",\"@namedBlocksInfo\",\"@text\",\"@title\",\"&attrs\"],\"statements\":[[11,\"div\"],[16,0,[31,[\"banner banner--\",[32,0,[\"type\"]],\" banner--\",[32,0,[\"theme\"]],\" \",[32,0,[\"styleNamespace\"]]]]],[17,5],[12],[2,\"\\n\"],[6,[37,2],[[30,[36,1],[[32,2],\"icon\",false],null]],null,[[\"default\"],[{\"statements\":[[2,\"    \"],[10,\"div\"],[14,0,\"banner__icon\"],[12],[2,\"\\n      \"],[18,1,[[30,[36,0],[\"icon\"],null]]],[2,\"\\n    \"],[13],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"\\n  \"],[10,\"div\"],[14,0,\"banner__text\"],[12],[2,\"\\n\"],[6,[37,2],[[30,[36,1],[[32,2],\"title\",false],null]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"      \"],[10,\"strong\"],[14,0,\"banner__title\"],[12],[18,1,[[30,[36,0],[\"title\"],null]]],[13],[2,\"\\n\"]],\"parameters\":[]},{\"statements\":[[6,[37,2],[[32,4]],null,[[\"default\"],[{\"statements\":[[2,\"      \"],[10,\"strong\"],[12],[1,[32,4]],[13],[2,\"\\n    \"]],\"parameters\":[]}]]]],\"parameters\":[]}]]],[2,\"\\n\"],[6,[37,2],[[32,3]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"      \"],[1,[32,3]],[2,\"\\n\"]],\"parameters\":[]},{\"statements\":[[6,[37,2],[[30,[36,1],[[32,2],\"text\",false],null]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"      \"],[18,1,[[30,[36,0],[\"text\"],null]]],[2,\"\\n\"]],\"parameters\":[]},{\"statements\":[[2,\"      \"],[18,1,null],[2,\"\\n    \"]],\"parameters\":[]}]]]],\"parameters\":[]}]]],[2,\"  \"],[13],[2,\"\\n\\n\"],[6,[37,2],[[30,[36,1],[[32,2],\"action\",false],null]],null,[[\"default\"],[{\"statements\":[[2,\"    \"],[10,\"div\"],[14,0,\"banner__cta\"],[12],[2,\"\\n      \"],[18,1,[[30,[36,0],[\"action\"],null]]],[2,\"\\n    \"],[13],[2,\"\\n\"]],\"parameters\":[]}]]],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"-named-block-invocation\",\"-has-block\",\"if\"]}",
    "moduleName": "flaneur/pods/components/banner/template.hbs"
  });

  _exports.default = _default;
});