define("flaneur/pods/components/truncate/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "oqulH7oy",
    "block": "{\"symbols\":[\"@text\",\"&default\",\"&attrs\",\"@namedBlocksInfo\"],\"statements\":[[11,\"div\"],[16,0,[31,[\"truncate \",[32,0,[\"styleNamespace\"]],\" \",[30,[36,2],[[30,[36,3],[[32,0,[\"isTruncated\"]]],null],\"truncate--open\"],null]]]],[17,3],[12],[2,\"\\n  \"],[11,\"p\"],[24,0,\"truncate__text\"],[4,[38,4],[[32,0,[\"setInitialOverflow\"]]],null],[12],[2,\"\\n\"],[6,[37,2],[[30,[36,5],[[32,4],\"default\",[27,[32,2]]],null]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"      \"],[18,2,null],[2,\"\\n\"]],\"parameters\":[]},{\"statements\":[[2,\"      \"],[1,[32,1]],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"  \"],[13],[2,\"\\n\\n\"],[6,[37,2],[[32,0,[\"hasInitialOverflow\"]]],null,[[\"default\"],[{\"statements\":[[2,\"    \"],[11,\"div\"],[24,\"role\",\"button\"],[24,0,\"truncate__button\"],[4,[38,1],[\"click\",[30,[36,0],[\"isTruncated\",[32,0]],null]],null],[4,[38,1],[\"click\",[32,0,[\"onToggle\"]]],null],[12],[2,\"\\n      \"],[10,\"span\"],[12],[1,[30,[36,2],[[32,0,[\"isTruncated\"]],\"Read more\",\"Read less\"],null]],[13],[2,\"\\n      \"],[8,\"fa-icon\",[],[[\"@icon\",\"@prefix\",\"@rotation\"],[\"chevron-down\",\"far\",[30,[36,2],[[32,0,[\"isTruncated\"]],0,180],null]]],null],[2,\"\\n    \"],[13],[2,\"\\n\"]],\"parameters\":[]}]]],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"toggle-action\",\"on\",\"if\",\"not\",\"did-insert\",\"-has-block\"]}",
    "moduleName": "flaneur/pods/components/truncate/template.hbs"
  });

  _exports.default = _default;
});