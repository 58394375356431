define("flaneur/pods/brand/boutiques/boutique/products/duplicate/route", ["exports", "flaneur/models/product"], function (_exports, _product) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    model() {
      return {
        product: {
          name: ''
        },
        boutique: this.brand.currentBoutique,
        fieldMessages: {}
      };
    },

    _setThumbnail(imgs) {
      const [thumbnail] = imgs;
      this.set('currentModel.product.thumbnail', thumbnail);
    },

    actions: {
      searchProducts(value) {
        return _product.default.find({
          'boutiques.boutique': this.brand.currentBoutique._id,
          name: {
            $regex: value,
            $options: 'i'
          },
          limit: 5,
          populate: 'standard_manufacturer',
          fields: ['_id', 'name', 'brand', 'standard_manufacturer', 'description', 'local_price', 'sales', 'local_sales_price', 'price', 'sales_price', 'shipping_size', 'shipping_enabled', 'categories', 'thumbnail'].join(',')
        }).then(({
          products
        }) => products);
      },

      onSelectSearchResult(product) {
        Ember.set(product, 'images', []);
        delete product.thumbnail;
        delete product._id;
        delete product._orig;
        delete product.price;
        product.stock_lines.forEach(stockLine => {
          delete stockLine.product;
        }); // Remove the fields we don't need when duplicating the product

        this.setProperties({
          'currentModel.product': product,
          'currentModel.imageFolder': `brand/${product.brand}`
        });
      },

      onSave(product) {
        return product.save().then(() => {
          this.analytics.gtm({
            action: 'duplicated_and_created_new_product',
            label: product._id
          });
          this.set('currentModel.loading', false);
          this.notify.show('success', 'Product successfully duplicated');
          this.transitionTo('brand.boutiques.boutique.products.index', this.brand);
          window.scrollTo(0, 0);
        }).catch(err => {
          this.set('currentModel.isLoading', false);
          this.set('currentModel.fieldMessages', err);
          this.notify.show('error', 'Your product is missing information. Please review fields in red.');
        });
      },

      cancel() {
        this.transitionTo('brand.boutiques.boutique.products.index');
        window.scrollTo(0, 0);
      },

      editProduct() {
        const hasSelectedProduct = Ember.isPresent(this.get('currentModel.product'));

        if (hasSelectedProduct) {
          this.set('currentModel.product.name', '');
          this.set('currentModel.hasSelectedProduct', true);
        }
      },

      submitForm(product) {
        return product.post();
      },

      imagesChanged(imgs) {
        this.set('currentModel.product.images', imgs);

        this._setThumbnail(imgs);
      }

    }
  });

  _exports.default = _default;
});