define("flaneur/pods/components/nav-bar/component", ["exports", "ember-component-css/pod-names", "flaneur/config/environment", "date-fns", "date-fns-tz"], function (_exports, _podNames, _environment, _dateFns, _dateFnsTz) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _dec8, _dec9, _dec10, _dec11, _dec12, _dec13, _dec14, _dec15, _dec16, _dec17, _dec18, _dec19, _dec20, _dec21, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _descriptor6, _descriptor7, _descriptor8, _descriptor9, _descriptor10;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  let NavBarComponent = (_dec = Ember.inject.service, _dec2 = Ember.inject.service, _dec3 = Ember.inject.service, _dec4 = Ember.inject.service, _dec5 = Ember.inject.service, _dec6 = Ember.inject.service, _dec7 = Ember.inject.service, _dec8 = Ember._tracked, _dec9 = Ember._tracked, _dec10 = Ember._tracked, _dec11 = Ember._action, _dec12 = Ember._action, _dec13 = Ember._action, _dec14 = Ember._action, _dec15 = Ember._action, _dec16 = Ember._action, _dec17 = Ember._action, _dec18 = Ember._action, _dec19 = Ember._action, _dec20 = Ember._action, _dec21 = Ember._action, (_class = class NavBarComponent extends Ember.Component {
    constructor(...args) {
      super(...args);

      _initializerDefineProperty(this, "analytics", _descriptor, this);

      _initializerDefineProperty(this, "brand", _descriptor2, this);

      _initializerDefineProperty(this, "features", _descriptor3, this);

      _initializerDefineProperty(this, "notificationsStorage", _descriptor4, this);

      _initializerDefineProperty(this, "media", _descriptor5, this);

      _initializerDefineProperty(this, "router", _descriptor6, this);

      _initializerDefineProperty(this, "session", _descriptor7, this);

      _initializerDefineProperty(this, "showNotificationMenu", _descriptor8, this);

      _initializerDefineProperty(this, "showBoutiqueMenu", _descriptor9, this);

      _initializerDefineProperty(this, "showUserMenu", _descriptor10, this);
    }

    get boutique() {
      return this.brand.currentBoutique;
    }

    get styleNamespace() {
      return _podNames.default['nav-bar'];
    }

    get notifications() {
      return this.notificationsStorage.cachedNotifications;
    }

    get newNotifications() {
      return this.notifications.filter(({
        isNew
      }) => isNew).length;
    }

    get boutiqueURL() {
      const [boutique] = this.brand.boutiques;
      return `${_environment.default.retailURL}/boutiques/${boutique.slug}`;
    }

    get isAdmin() {
      const {
        isAdmin,
        isRetailAdmin
      } = this.session.user;
      return isAdmin || isRetailAdmin;
    }

    get user() {
      const user = this.session.get('user');

      if (!user && !user.first_name || !user.last_name) {
        return {
          name: 'Boutique owner',
          acronym: ''
        };
      }

      const name = `${user.first_name} ${user.last_name}`;
      const acronym = `${user.first_name.charAt(0).toUpperCase()} ${user.last_name.charAt(0).toUpperCase()}`;
      return {
        name,
        acronym
      };
    }

    get shouldShowNotifications() {
      if (!this.brand.currentBoutique.address_country) {
        return false;
      }

      const {
        currentBoutique: {
          address_country: {
            iso_code
          }
        }
      } = this.brand;
      return ['GB', 'FR', 'BE', 'IE'].includes(iso_code);
    }

    get getNextFeedRun() {
      const now = new Date();
      const dates = [(0, _dateFns.set)(new Date(), {
        minutes: 0,
        hours: 11,
        milliseconds: 0,
        seconds: 0
      }), (0, _dateFns.set)(new Date(), {
        minutes: 0,
        hours: 15,
        milliseconds: 0,
        seconds: 0
      }), (0, _dateFns.set)(new Date(), {
        minutes: 30,
        hours: 19,
        milliseconds: 0,
        seconds: 0
      })];

      for (const value of dates) {
        const zoneDate = (0, _dateFnsTz.zonedTimeToUtc)(value, 'Europe/London');

        if ((0, _dateFns.isBefore)(now, zoneDate)) {
          return (0, _dateFnsTz.format)(zoneDate, 'HH:mm');
        }
      }

      const zoneDate = (0, _dateFnsTz.zonedTimeToUtc)(dates[0], 'Europe/London');
      return (0, _dateFnsTz.format)(zoneDate, 'HH:mm');
    }

    logout() {
      this.session.logout().then(() => this.router.transitionTo('login'));
    }

    toggleShowSidebar() {
      this.toggleProperty('showSidebar');
      this.analytics.gtm({
        action: this.showSidebar ? 'opened_navigation' : 'closed_navigation'
      });

      if (this.media.isXlarge && this.showSidebar) {
        const element = document.getElementById('overlay');

        if (element) {
          element.style.display = 'block';
        }
      }
    }

    toggleBoutiqueMenu() {
      this.showBoutiqueMenu = !this.showBoutiqueMenu;
    }

    toggleUserMenu() {
      this.showUserMenu = !this.showUserMenu;
    }

    toggleNotificationMenu() {
      this.showNotificationMenu = !this.showNotificationMenu;

      if (this.showNotificationMenu) {
        this.analytics.gtm({
          action: 'clicks_notification_dropdown',
          label: 'open'
        });
      }
    }

    closeBoutiqueMenu() {
      this.showBoutiqueMenu = false;
    }

    closeUserMenu() {
      this.showUserMenu = false;
    }

    closeNotificationMenu() {
      this.showNotificationMenu = false;
    }

    openOnTrouva() {
      this.closeBoutiqueMenu();
      window.open(this.boutiqueURL);
    }

    onClickNotification({
      route
    }) {
      this.closeNotificationMenu();
      this.analytics.gtm({
        action: 'clicks_notifications',
        label: route
      });
      return this.router.transitionTo(`brand.boutiques.boutique.${route}`, this.brand, this.brand.currentBoutique);
    }

    trackExternalLink({
      externalLink,
      track: {
        action,
        label
      }
    }) {
      this.analytics.gtm({
        action,
        label
      });
      window.open(externalLink);
    }

  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "analytics", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "brand", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "features", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "notificationsStorage", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "media", [_dec5], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor6 = _applyDecoratedDescriptor(_class.prototype, "router", [_dec6], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor7 = _applyDecoratedDescriptor(_class.prototype, "session", [_dec7], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor8 = _applyDecoratedDescriptor(_class.prototype, "showNotificationMenu", [_dec8], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return false;
    }
  }), _descriptor9 = _applyDecoratedDescriptor(_class.prototype, "showBoutiqueMenu", [_dec9], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return false;
    }
  }), _descriptor10 = _applyDecoratedDescriptor(_class.prototype, "showUserMenu", [_dec10], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return false;
    }
  }), _applyDecoratedDescriptor(_class.prototype, "logout", [_dec11], Object.getOwnPropertyDescriptor(_class.prototype, "logout"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "toggleShowSidebar", [_dec12], Object.getOwnPropertyDescriptor(_class.prototype, "toggleShowSidebar"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "toggleBoutiqueMenu", [_dec13], Object.getOwnPropertyDescriptor(_class.prototype, "toggleBoutiqueMenu"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "toggleUserMenu", [_dec14], Object.getOwnPropertyDescriptor(_class.prototype, "toggleUserMenu"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "toggleNotificationMenu", [_dec15], Object.getOwnPropertyDescriptor(_class.prototype, "toggleNotificationMenu"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "closeBoutiqueMenu", [_dec16], Object.getOwnPropertyDescriptor(_class.prototype, "closeBoutiqueMenu"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "closeUserMenu", [_dec17], Object.getOwnPropertyDescriptor(_class.prototype, "closeUserMenu"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "closeNotificationMenu", [_dec18], Object.getOwnPropertyDescriptor(_class.prototype, "closeNotificationMenu"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "openOnTrouva", [_dec19], Object.getOwnPropertyDescriptor(_class.prototype, "openOnTrouva"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "onClickNotification", [_dec20], Object.getOwnPropertyDescriptor(_class.prototype, "onClickNotification"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "trackExternalLink", [_dec21], Object.getOwnPropertyDescriptor(_class.prototype, "trackExternalLink"), _class.prototype)), _class));
  _exports.default = NavBarComponent;
});