define("flaneur/pods/components/bulk-edit-footer/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    media: Ember.inject.service(),
    classNames: ['bulk-edit-footer'],

    onApplyBulkEdit() {},

    uncheckProducts() {},

    showModal() {},

    actions: {
      showDiscountModal() {
        return this.showModal('discount');
      },

      showConfirmationModal() {
        return this.showModal('confirmation');
      },

      applyBulkEdit() {
        return this.onApplyBulkEdit();
      },

      uncheckProducts() {
        return this.uncheckProducts();
      },

      showRestockModal() {
        return this.showRestockModal();
      }

    }
  });

  _exports.default = _default;
});