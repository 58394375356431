define("flaneur/pods/components/checkbox/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "16psnaXL",
    "block": "{\"symbols\":[\"@indeterminate\",\"@checked\",\"&attrs\",\"@disabled\",\"&default\"],\"statements\":[[11,\"div\"],[16,0,[31,[\"checkbox \",[32,0,[\"styleNamespace\"]],\" \",[30,[36,0],[[32,2],\"checkbox--checked\"],null],\" \",[30,[36,0],[[32,1],\"checkbox--indeterminate\"],null]]]],[17,3],[12],[2,\"\\n  \"],[11,\"input\"],[16,1,[32,0,[\"checkboxId\"]]],[16,\"checked\",[32,2]],[16,\"disabled\",[32,4]],[24,4,\"checkbox\"],[4,[38,1],[\"click\",[32,0,[\"onClick\"]]],null],[12],[13],[2,\"\\n\\n  \"],[10,\"label\"],[15,\"for\",[32,0,[\"checkboxId\"]]],[12],[2,\"\\n    \"],[8,\"fa-icon\",[[24,0,\"checkbox__icon\"]],[[\"@icon\",\"@prefix\"],[[30,[36,0],[[32,1],\"minus\",\"check\"],null],\"far\"]],null],[2,\"\\n\\n    \"],[18,5,null],[2,\"\\n  \"],[13],[2,\"\\n\"],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"if\",\"on\"]}",
    "moduleName": "flaneur/pods/components/checkbox/template.hbs"
  });

  _exports.default = _default;
});