define("flaneur/services/packaging-basket", ["exports", "flaneur/enums/vat-rate", "flaneur/enums/packaging-delivery-amount", "flaneur/enums/packaging-promotion-code-type"], function (_exports, _vatRate, _packagingDeliveryAmount, _packagingPromotionCodeType) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  let PackagingBasket = (_dec = Ember.inject.service, _dec2 = Ember._tracked, _dec3 = Ember._tracked, _dec4 = Ember._tracked, _dec5 = Ember.computed('brand.currentBoutique.currency', 'items.@each.{price,quantity}'), _dec6 = Ember.computed('items.{@each.price,@each.quantity}'), _dec7 = Ember.computed('items.[]'), (_class = class PackagingBasket extends Ember.Service {
    constructor(...args) {
      super(...args);

      _initializerDefineProperty(this, "brand", _descriptor, this);

      _initializerDefineProperty(this, "items", _descriptor2, this);

      _initializerDefineProperty(this, "promotionCode", _descriptor3, this);

      _initializerDefineProperty(this, "toastAPI", _descriptor4, this);
    }

    get subtotalPrice() {
      const {
        currency
      } = this.brand.currentBoutique;

      if (this.items.length <= 0) {
        return {
          amount: 0,
          currency
        };
      }

      const total = this.items.reduce((total, item) => total + item.product.variant.pack_price.amount * item.quantity, 0);
      return {
        amount: total,
        currency
      };
    }

    get deliveryPrice() {
      const countryIsoCodeAlpha3 = this.brand.currentBoutique.registeredAddressIsoCodeAlpha3;
      const amount = _packagingDeliveryAmount.default[countryIsoCodeAlpha3];
      return {
        amount,
        currency: this.brand.currentBoutique.currency
      };
    }

    get discountPrice() {
      const {
        currency
      } = this.brand.currentBoutique;

      if (!this.promotionCode) {
        return {
          amount: 0,
          currency
        };
      }

      const {
        type
      } = this.promotionCode;

      if (type === _packagingPromotionCodeType.default.FREE_SHIPPING) {
        return this.deliveryPrice;
      }

      if (type === _packagingPromotionCodeType.default.AMOUNT) {
        const {
          amount
        } = this.promotionCode[`value_${currency}`];
        return {
          amount: Math.min(amount, this.subtotalPrice.amount),
          currency
        };
      }

      if (type === _packagingPromotionCodeType.default.PERCENTAGE) {
        const rate = this.promotionCode.value / 100;
        return {
          amount: parseInt((this.subtotalPrice.amount * rate).toFixed(0), 10),
          currency
        };
      }

      return {
        amount: 0,
        currency
      };
    }

    get vatPrice() {
      const countryISOCode = this.brand.currentBoutique.registeredAddressIsoCodeAlpha3;
      const rate = _vatRate.default[countryISOCode];
      const vatableAmount = this.subtotalPrice.amount + this.deliveryPrice.amount - this.discountPrice.amount;
      const amount = (vatableAmount * rate).toFixed(0);
      return {
        amount: parseInt(amount, 10),
        currency: this.subtotalPrice.currency
      };
    }

    get totalPrice() {
      const amount = this.subtotalPrice.amount + this.deliveryPrice.amount - this.discountPrice.amount + this.vatPrice.amount;
      return {
        amount,
        currency: this.brand.currentBoutique.currency
      };
    }

    get totalUnits() {
      return this.items.reduce((total, item) => total + item.quantity, 0);
    }

    get isEmpty() {
      return this.items.length === 0;
    }

    add(newItem) {
      const item = this.items.find(({
        product: {
          name,
          variant: {
            sku
          }
        }
      }) => {
        return name === newItem.product.name && sku === newItem.product.variant.sku;
      });

      if (item) {
        Ember.set(item, 'quantity', newItem.quantity + item.quantity);
        return item;
      }

      return this.items.pushObject(newItem);
    }

    remove(variant) {
      return this.items.removeObject(variant);
    }

    clear() {
      Ember.set(this, 'items', []);
      this.promotionCode = null;
    }

    isPromotionCodeMinimumAmountAchieved(promotionCode = this.promotionCode) {
      var _promotionCode$;

      if (Ember.isEmpty(promotionCode)) {
        return true;
      }

      const {
        currency
      } = this.brand.currentBoutique;
      const minimumValue = promotionCode === null || promotionCode === void 0 ? void 0 : (_promotionCode$ = promotionCode[`minimum_value_${currency}`]) === null || _promotionCode$ === void 0 ? void 0 : _promotionCode$.amount;

      if (!minimumValue) {
        return true;
      }

      return this.subtotalPrice.amount >= minimumValue;
    }

  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "brand", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "items", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return [];
    }
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "promotionCode", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return null;
    }
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "toastAPI", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return {};
    }
  }), _applyDecoratedDescriptor(_class.prototype, "subtotalPrice", [_dec5], Object.getOwnPropertyDescriptor(_class.prototype, "subtotalPrice"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "totalUnits", [_dec6], Object.getOwnPropertyDescriptor(_class.prototype, "totalUnits"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "isEmpty", [_dec7], Object.getOwnPropertyDescriptor(_class.prototype, "isEmpty"), _class.prototype)), _class));
  _exports.default = PackagingBasket;
});