define("flaneur/pods/components/gift-wrap-modal/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "/o5tz6X1",
    "block": "{\"symbols\":[],\"statements\":[[8,\"modal-dialog\",[],[[\"@onClose\",\"@containerClass\",\"@targetAttachment\",\"@translucentOverlay\"],[[30,[36,0],[\"isGiftModalOpen\",[32,0]],null],[31,[\"centered-scrolling-container \",[32,0,[\"styleNamespace\"]]]],\"center\",true]],[[\"default\"],[{\"statements\":[[2,\"\\n  \"],[10,\"div\"],[14,0,\"gift-wrap-modal__header ember-modal-dialog__header\"],[12],[2,\"\\n    \"],[10,\"h1\"],[12],[2,\"\\n      \"],[8,\"fa-icon\",[[24,0,\"fa\"]],[[\"@icon\",\"@prefix\"],[\"gift\",\"far\"]],null],[2,\"\\n      This is a gift order\\n    \"],[13],[2,\"\\n  \"],[13],[2,\"\\n\\n  \"],[11,\"div\"],[24,\"role\",\"button\"],[24,0,\"ember-modal-dialog__close\"],[4,[38,1],[\"click\",[32,0,[\"closeModal\"]]],null],[12],[2,\"\\n    \"],[8,\"fa-icon\",[],[[\"@icon\",\"@prefix\"],[\"times\",\"far\"]],null],[2,\"\\n  \"],[13],[2,\"\\n\\n  \"],[10,\"div\"],[14,0,\"gift-wrap-modal__body ember-modal-dialog__body\"],[12],[2,\"\\n    \"],[10,\"p\"],[12],[2,\"Please ensure you gift wrap the item(s) and remove any price tags as it’ll\\n      be sent straight to the recipient.\\n    \"],[13],[2,\"\\n    \"],[8,\"checkbox\",[],[[\"@checked\",\"@onChange\"],[[32,0,[\"enabled\"]],[30,[36,3],[[32,0],[30,[36,2],[[32,0,[\"enabled\"]]],null]],null]]],[[\"default\"],[{\"statements\":[[2,\"\\n      Don’t show me this again\\n    \"]],\"parameters\":[]}]]],[2,\"\\n  \"],[13],[2,\"\\n\\n  \"],[10,\"div\"],[14,0,\"gift-wrap-modal__footer ember-modal-dialog__footer\"],[12],[2,\"\\n    \"],[8,\"button\",[[4,[38,1],[\"click\",[32,0,[\"continueAndBook\"]]],null]],[[],[]],[[\"default\"],[{\"statements\":[[2,\"\\n      Continue\\n    \"]],\"parameters\":[]}]]],[2,\"\\n  \"],[13],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"toggle-action\",\"on\",\"mut\",\"action\"]}",
    "moduleName": "flaneur/pods/components/gift-wrap-modal/template.hbs"
  });

  _exports.default = _default;
});