define("flaneur/pods/components/pages/curate/brands/brand/basket-page/validations", ["exports", "ember-cp-validations"], function (_exports, _emberCpValidations) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.ProductWithValidations = _exports.Validations = void 0;

  var _dec, _dec2, _class, _descriptor, _descriptor2;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  const isInvoiceAddressValidationDisabled = Ember.computed('model.{isNewInvoiceAddress,disableValidations,disableAddressValidations}', function () {
    if (this.model.disableValidations || this.model.disableAddressValidations) {
      return true;
    }

    return !this.model.isNewInvoiceAddress;
  });
  const isDeliveryAddressValidationDisabled = Ember.computed('model.{isNewDeliveryAddress,disableValidations,disableAddressValidations}', function () {
    if (this.model.disableValidations || this.model.disableAddressValidations) {
      return true;
    }

    return !this.model.isNewDeliveryAddress;
  });
  const Validations = (0, _emberCpValidations.buildValidations)({
    'orderTotal.amount': (0, _emberCpValidations.validator)('number', {
      gte: Ember.computed.readOnly('model.brand.minimum_order_value.value.amount'),

      message() {
        const {
          amount,
          currency
        } = this.model.brand.minimum_order_value.value;
        const money = new Intl.NumberFormat('en-GB', {
          style: 'currency',
          currency
        }).format(amount / 100);
        return `This brand has a minimum spend of ${money}`;
      }

    }),
    'order.reservations': (0, _emberCpValidations.validator)('has-many'),
    selectedInvoiceOption: (0, _emberCpValidations.validator)('presence', {
      presence: true,
      message: 'Please select an invoice address'
    }),
    'invoiceNewAddress.line_1': (0, _emberCpValidations.validator)('presence', {
      disabled: isInvoiceAddressValidationDisabled,
      presence: true,
      message: 'Address line 1 should not be empty'
    }),
    'invoiceNewAddress.city': (0, _emberCpValidations.validator)('presence', {
      disabled: isInvoiceAddressValidationDisabled,
      presence: true,
      message: 'City should not be empty'
    }),
    'invoiceNewAddress.postcode': (0, _emberCpValidations.validator)('presence', {
      disabled: isInvoiceAddressValidationDisabled,
      presence: true,
      message: 'Postcode should not be empty'
    }),
    selectedDeliveryOption: (0, _emberCpValidations.validator)('presence', {
      presence: true,
      message: 'Please select a delivery address'
    }),
    'deliveryNewAddress.line_1': (0, _emberCpValidations.validator)('presence', {
      disabled: isDeliveryAddressValidationDisabled,
      presence: true,
      message: 'Address line 1 should not be empty'
    }),
    'deliveryNewAddress.city': (0, _emberCpValidations.validator)('presence', {
      disabled: isDeliveryAddressValidationDisabled,
      presence: true,
      message: 'City should not be empty'
    }),
    'deliveryNewAddress.postcode': (0, _emberCpValidations.validator)('presence', {
      disabled: isDeliveryAddressValidationDisabled,
      presence: true,
      message: 'Postcode should not be empty'
    })
  }, {
    disabled: Ember.computed.readOnly('model.disableValidations')
  });
  _exports.Validations = Validations;
  const ProductValidations = (0, _emberCpValidations.buildValidations)({
    quantity: (0, _emberCpValidations.validator)('number', {
      gte: Ember.computed.readOnly('model.selectedStockLine.minimumOrderQuantity'),
      allowNone: false,
      message: 'This product has a minimum quantity of {gte}'
    })
  }, {
    disabled: Ember.computed.readOnly('model.disableValidations')
  });
  let ProductWithValidations = (_dec = Ember._tracked, _dec2 = Ember._tracked, (_class = class ProductWithValidations extends Ember.Object.extend(ProductValidations) {
    constructor(...args) {
      super(...args);

      _initializerDefineProperty(this, "quantity", _descriptor, this);

      _initializerDefineProperty(this, "disableValidations", _descriptor2, this);
    }

  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "quantity", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "disableValidations", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return true;
    }
  })), _class));
  _exports.ProductWithValidations = ProductWithValidations;
});