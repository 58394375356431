define("flaneur/pods/brand/boutiques/boutique/route", ["exports", "flaneur/config/environment", "flaneur/models/zendesk"], function (_exports, _environment, _zendesk) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    brand: Ember.inject.service(),
    googleOptimize: Ember.inject.service(),
    session: Ember.inject.service(),

    serialize(model) {
      return {
        boutique_id: model._id
      };
    },

    model() {
      this.googleOptimize.identify(this.brand.currentBoutique);
      return this.modelFor('brand');
    },

    async afterModel() {
      try {
        const token = await _zendesk.default.createAuthenticationToken('zendesk/widget/token');

        if (token) {
          window.zESettings = {
            authenticate: {
              jwt: token
            },
            webWidget: {
              zIndex: 199,
              offset: {
                mobile: {
                  vertical: '40px'
                }
              }
            }
          };
          const script = document.createElement('script');
          script.setAttribute('src', _environment.default.widgetSrcURL);
          script.setAttribute('id', 'ze-snippet');
          document.body.appendChild(script);
        }
      } catch (error) {
        return;
      }
    }

  });

  _exports.default = _default;
});