define("flaneur/pods/brand/settings/account/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "JNzZCLED",
    "block": "{\"symbols\":[],\"statements\":[[10,\"div\"],[14,0,\"heading\"],[12],[2,\"\\n  \"],[10,\"h1\"],[14,0,\"title\"],[12],[2,\"Account\"],[13],[2,\"\\n\"],[13],[2,\"\\n\\n\"],[1,[30,[36,1],null,[[\"value\",\"label\"],[[35,0,[\"user\",\"email\"]],\"Email\"]]]],[2,\"\\n\"],[1,[30,[36,1],null,[[\"value\",\"label\"],[[35,0,[\"user\",\"createdSince\"]],\"Created\"]]]],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"model\",\"form-static\"]}",
    "moduleName": "flaneur/pods/brand/settings/account/template.hbs"
  });

  _exports.default = _default;
});