define("flaneur/pods/components/manufacturer-select/component", ["exports", "@glimmer/component", "ember-concurrency", "flaneur/models/manufacturer", "flaneur/utils/keyword-highlight"], function (_exports, _component, _emberConcurrency, _manufacturer, _keywordHighlight) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _dec8, _class, _descriptor, _descriptor2, _descriptor3;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  let ManufacturerSelectComponent = (_dec = Ember._tracked, _dec2 = Ember._tracked, _dec3 = Ember._tracked, _dec4 = Ember._action, _dec5 = Ember._action, _dec6 = Ember._action, _dec7 = Ember._action, _dec8 = Ember._action, (_class = class ManufacturerSelectComponent extends _component.default {
    constructor() {
      super(...arguments);

      _initializerDefineProperty(this, "query", _descriptor, this);

      _initializerDefineProperty(this, "options", _descriptor2, this);

      _initializerDefineProperty(this, "limit", _descriptor3, this);

      this.keywordHighlight = new _keywordHighlight.default({
        selectors: ['.manufacturer-select__dropdown .manufacturer-select__option']
      });
    }

    get hasOptions() {
      var _this$options;

      return ((_this$options = this.options) === null || _this$options === void 0 ? void 0 : _this$options.length) > 0;
    }
    /*
     * Gets the normalized name for a manufacturer, mimicking
     * promenade's behaviour implemented in the manufacturer's
     * resource.
     */


    async getManufacturerNormalizedName(name) {
      const {
        default: slug
      } = await emberAutoImportDynamic("slug");
      return slug(name, {
        lower: true,
        replacement: ' '
      });
    }

    *searchManufacturer(term) {
      yield (0, _emberConcurrency.timeout)(300);
      const normalizedName = yield this.getManufacturerNormalizedName(term);
      const manufacturers = yield _manufacturer.default.find({
        // The "normalized_name" property has an index, while "name" property hasn't
        $or: [{
          normalized_name: {
            $regex: normalizedName,
            $options: 'i'
          }
        }, {
          alternate_names: {
            $regex: normalizedName,
            $options: 'i'
          }
        }],
        sort: 'status',
        fields: 'name,status',
        limit: this.limit
      });
      this.query = term;
      this.options = manufacturers;
      return manufacturers;
    }

    onDidInsert() {
      this.keywordHighlight.init();
    }

    highlightQuery() {
      this.keywordHighlight.highlight(this.query);
    }

    changeManufacturer(manufacturer, powerSelectAPI) {
      this.args.onChangeManufacturer(manufacturer);
      powerSelectAPI.actions.close();
    }

    async createManufacturer(query, powerSelectAPI, event) {
      const hasClickedCreateBrand = event.target.classList.contains('manufacturer-suggestion__create');

      if (!hasClickedCreateBrand) {
        return;
      }

      const manufacturer = await _manufacturer.default.post({
        name: query
      });
      this.args.onCreateManufacturer(manufacturer);
      powerSelectAPI.actions.close();
    }

    showCreateManufacturerWhen() {
      return !this.hasOptions;
    }

  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "query", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "options", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return [];
    }
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "limit", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return 32;
    }
  }), _applyDecoratedDescriptor(_class.prototype, "searchManufacturer", [_emberConcurrency.restartableTask], Object.getOwnPropertyDescriptor(_class.prototype, "searchManufacturer"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "onDidInsert", [_dec4], Object.getOwnPropertyDescriptor(_class.prototype, "onDidInsert"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "highlightQuery", [_dec5], Object.getOwnPropertyDescriptor(_class.prototype, "highlightQuery"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "changeManufacturer", [_dec6], Object.getOwnPropertyDescriptor(_class.prototype, "changeManufacturer"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "createManufacturer", [_dec7], Object.getOwnPropertyDescriptor(_class.prototype, "createManufacturer"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "showCreateManufacturerWhen", [_dec8], Object.getOwnPropertyDescriptor(_class.prototype, "showCreateManufacturerWhen"), _class.prototype)), _class));
  _exports.default = ManufacturerSelectComponent;
});