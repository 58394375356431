define("flaneur/models/packaging/product/index", ["exports", "fetch", "flaneur/config/environment", "query-string"], function (_exports, _fetch, _environment, _queryString) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _class, _descriptor, _descriptor2;

  function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); enumerableOnly && (symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols); } return keys; }

  function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = null != arguments[i] ? arguments[i] : {}; i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { _defineProperty(target, key, source[key]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } return target; }

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  let PackagingProduct = (_dec = Ember._tracked, _dec2 = Ember._tracked, _dec3 = Ember._action, (_class = class PackagingProduct {
    constructor(product) {
      _initializerDefineProperty(this, "allVariants", _descriptor, this);

      _initializerDefineProperty(this, "filterByCountry", _descriptor2, this);

      const {
        _id,
        name,
        description,
        variants,
        category,
        images,
        created_at,
        updated_at,
        filterByCountry
      } = product;
      this.id = _id;
      this.name = name;
      this.description = description;
      this.allVariants = variants;
      this.category = category;
      this.images = images;
      this.created_at = created_at;
      this.updated_at = updated_at;
      this.filterByCountry = filterByCountry;
    }

    static async find(query = {}, extraProperties = {}) {
      const queryParams = _queryString.default.stringify(_objectSpread({
        status: 'published',
        sort: '-created_at'
      }, query));

      const url = `${_environment.default.packagingApiURL}/api/products?${queryParams}`;
      const res = await (0, _fetch.default)(url, {
        method: 'GET',
        credentials: 'include',
        cache: false
      });
      const {
        products = [],
        meta = {
          page: 1,
          page_total: 1,
          total: 1
        }
      } = await res.json();
      return {
        products: products.map(product => new PackagingProduct(_objectSpread(_objectSpread({}, product), extraProperties))),
        meta
      };
    }

    static async findById(id, extraProperties = {}) {
      const queryParams = _queryString.default.stringify({
        populate: 'category'
      });

      const url = `${_environment.default.packagingApiURL}/api/products/${id}?${queryParams}`;
      const res = await (0, _fetch.default)(url, {
        method: 'GET',
        credentials: 'include',
        cache: false
      });

      if (!res.ok) {
        return null;
      }

      const {
        products
      } = await res.json();
      const [product] = products;
      return product ? new PackagingProduct(_objectSpread(_objectSpread({}, product), extraProperties)) : null;
    }

    get variants() {
      if (!this.filterByCountry) {
        return this.allVariants;
      }

      return this.allVariants.filter(({
        country
      }) => country === this.filterByCountry._id);
    }

    get outOfStockVariantsCount() {
      return this.variants.reduce((outOfStock, {
        out_of_stock
      }) => out_of_stock ? outOfStock + 1 : outOfStock, 0);
    }

    get isOutOfStock() {
      return this.outOfStockVariantsCount === this.variants.length;
    }

    get lowestVariantUnitPrice() {
      return this.variants.reduce((lowestPrice, variant) => {
        const unitPrice = this.getVariantUnitPrice(variant);
        return lowestPrice.amount < unitPrice.amount ? lowestPrice : unitPrice;
      }, {
        amount: Number.MAX_SAFE_INTEGER,
        currency: null
      });
    }

    getVariantUnitPrice({
      pack: {
        price,
        units
      }
    }) {
      return {
        amount: parseInt((price.amount / units).toFixed(0), 10),
        currency: price.currency
      };
    }

  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "allVariants", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "filterByCountry", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "getVariantUnitPrice", [_dec3], Object.getOwnPropertyDescriptor(_class.prototype, "getVariantUnitPrice"), _class.prototype)), _class));
  _exports.default = PackagingProduct;
});