define("flaneur/pods/components/cloudinary-image/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "wg2aolre",
    "block": "{\"symbols\":[],\"statements\":[[10,\"img\"],[15,\"src\",[34,0]],[12],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"transformedSrc\"]}",
    "moduleName": "flaneur/pods/components/cloudinary-image/template.hbs"
  });

  _exports.default = _default;
});