define("flaneur/models/curate/brand-tag/index", ["exports", "fetch", "flaneur/config/environment", "flaneur/enums/curate-brand-tag-status", "query-string"], function (_exports, _fetch, _environment, _curateBrandTagStatus, _queryString) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); enumerableOnly && (symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols); } return keys; }

  function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = null != arguments[i] ? arguments[i] : {}; i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { _defineProperty(target, key, source[key]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } return target; }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  class CurateBrandTag {
    constructor({
      _id,
      slug,
      name,
      display_order
    }) {
      this._id = _id;
      this.slug = slug;
      this.name = name;
      this.display_order = display_order;
    }

    static async find(query = {}) {
      const queryParams = _queryString.default.stringify(_objectSpread(_objectSpread({}, query), {}, {
        status: _curateBrandTagStatus.default.PUBLISHED
      }));

      const url = `${_environment.default.curateApiURL}/api/v1/brand_tags?${queryParams}`;
      const res = await (0, _fetch.default)(url, {
        method: 'GET',
        credentials: 'include',
        cache: false
      });

      if (!res.ok) {
        return {
          curateBrandTags: [],
          meta: {
            total: 0,
            page: 1,
            page_total: 1
          }
        };
      }

      const {
        brand_tags: curateBrandTags = [],
        meta
      } = await res.json();
      return {
        curateBrandTags: curateBrandTags.map(tag => new CurateBrandTag(tag)),
        meta
      };
    }

  }

  _exports.default = CurateBrandTag;
});