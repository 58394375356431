define("flaneur/pods/components/input-search/component", ["exports", "ember-concurrency"], function (_exports, _emberConcurrency) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    // Public API
    value: null,
    placeholder: null,
    debounce: 300,

    onChange() {},

    onEnter() {},

    // Private API
    classNames: ['input-search'],
    searchValue: Ember.computed('value', {
      get() {
        return this.value;
      },

      set(_, query) {
        this._onChange.perform(query);

        return query;
      }

    }),
    hasValue: Ember.computed.gte('searchValue.length', 1),
    _onChange: (0, _emberConcurrency.task)(function* (query) {
      /*
       * Cancel the previous transition, if it's
       * actually a transition
       */
      if (this._transition && this._transition.isTransition) {
        this._transition.abort();
      }

      yield (0, _emberConcurrency.timeout)(this.debounce);
      this.set('_transition', this.onChange(query));
    }).restartable(),
    actions: {
      resetValue() {
        this.set('searchValue', null);
      },

      onEnter() {
        return this.onEnter(this.searchValue);
      }

    }
  });

  _exports.default = _default;
});