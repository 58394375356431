define("flaneur/models/prospective-boutique/index", ["exports", "flaneur/mixins/request", "flaneur/models/prospective-boutique/validations"], function (_exports, _request, _validations) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); enumerableOnly && (symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols); } return keys; }

  function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = null != arguments[i] ? arguments[i] : {}; i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { _defineProperty(target, key, source[key]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } return target; }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  const ProspectiveBoutique = Ember.Object.extend(_request.default, _validations.Validations, {
    // Instance methods
    disableValidations: false,
    isRetailerDetailsCompleted: Ember.computed('registered_name', 'registered_number', 'vat_number', 'is_vat_registered', 'disableValidations', function () {
      const fields = ['registered_name', 'registered_number'];

      if (this.is_vat_registered) {
        fields.push('vat_number');
      }

      return this._areFieldsFilled(fields) && this._areFieldsValid(fields);
    }),
    isContactDetailsCompleted: Ember.computed('contract_owner_name', 'primary_phone', 'email', 'disableValidations', function () {
      const fields = ['contract_owner_name', 'primary_phone', 'email'];
      return this._areFieldsFilled(fields) && this._areFieldsValid(fields);
    }),
    isRegisteredAddressCompleted: Ember.computed('registered_address_line_1', 'registered_address_country', 'postcode', 'disableValidations', function () {
      const fields = ['registered_address_line_1', 'registered_address_country', 'postcode'];
      return this._areFieldsFilled(fields) && this._areFieldsValid(fields);
    }),
    checks: Ember.computed('isRetailerDetailsCompleted', 'isContactDetailsCompleted', 'isRegisteredAddressCompleted', function () {
      return [{
        label: 'Retailer details',
        completed: this.isRetailerDetailsCompleted
      }, {
        label: 'Contact details',
        completed: this.isContactDetailsCompleted
      }, {
        label: 'Registered address',
        completed: this.isRegisteredAddressCompleted
      }];
    }),

    _areFieldsFilled(fields = []) {
      return fields.reduce((filled, field) => filled && Ember.isPresent(this[field]), true);
    },

    _areFieldsValid(fields) {
      return this.validate({
        on: fields
      }).then(({
        validations: {
          isValid
        }
      }) => isValid);
    },

    save() {
      return this.request({
        method: 'PUT',
        url: this.apiURL(`prospective_boutiques/${this._id}`),
        data: JSON.stringify((0, _request.sanitize)(this))
      }).then(({
        prospective_boutiques
      }) => {
        this.setProperties(_objectSpread({}, prospective_boutiques[0]));
        return prospective_boutiques[0];
      });
    },

    acceptContract() {
      return this.request({
        method: 'PUT',
        url: this.apiURL(`prospective_boutiques/${this._id}/accept-contract`),
        data: JSON.stringify({
          accepted_contract: this.terms_url
        })
      }).then(({
        prospective_boutiques
      }) => {
        this.setProperties(_objectSpread({}, prospective_boutiques[0]));
        return prospective_boutiques[0];
      });
    }

  });
  ProspectiveBoutique.reopenClass(_request.default, {
    // Class methods

    /**
     * Find prospective boutique by user's email
     *
     * @param  {String} id user _id
     * @return {Object}    Promise
     */
    findByEmail(owner, email) {
      return this.request({
        method: 'GET',
        url: this.apiURL(`prospective_boutiques?email=${encodeURIComponent(email)}`)
      }).then(({
        prospective_boutiques
      }) => this.create(owner, prospective_boutiques[0]));
    }

  });
  var _default = ProspectiveBoutique;
  _exports.default = _default;
});