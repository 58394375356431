define("flaneur/pods/components/form-flows/shipment/shipment-cost/component", ["exports", "flaneur/utils/billable-weight"], function (_exports, _billableWeight) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    analytics: Ember.inject.service(),
    classNames: ['shipment-cost'],
    classNameBindings: ['isUnshippable:shipment-cost--unshippable', 'isLarge:shipment-cost--large'],
    maxUpsBillableWeight: _billableWeight.MAX_UPS_BILLABLE_WEIGHT,
    maxLongestDimension: 274,
    isVisible: Ember.computed.readOnly('upsBillableWeight.isCalculated'),
    upsBillableWeight: Ember.computed.readOnly('shipment.upsBillableWeight'),
    isUnshippable: Ember.computed.readOnly('shipment.exceedsMaxUpsBillableWeight'),
    isLarge: Ember.computed('maxLongestDimension', 'shipment.package.{height,length,weight,width}', function () {
      const {
        width,
        height,
        length
      } = this.shipment.package;
      const dimensions = [width, height, length];
      const longest = Math.max(...dimensions);
      const indexOfLongest = dimensions.indexOf(longest);
      const girth = dimensions.reduce((girth, dimension, index) => {
        return indexOfLongest !== index ? girth + dimension * 2 : girth;
      }, 0);
      return longest > this.maxLongestDimension || longest + girth > 300;
    }),
    openSurchargesDocument: Ember._action(function () {
      this.analytics.gtm({
        action: 'quick_ship_see_surcharges_doc'
      });
      window.open(this.extraShippingSurchargesDocumentUrl, '_blank');
    }),
    openRatesDocument: Ember._action(function () {
      this.analytics.gtm({
        action: 'quick_ship_see_rates_doc'
      });
      window.open(this.extraShippingRatesDocumentUrl, '_blank');
    })
  });

  _exports.default = _default;
});