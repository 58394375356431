define("flaneur/pods/brand/boutiques/boutique/curate/brands/brand/index/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "spviFEOY",
    "block": "{\"symbols\":[\"@model\"],\"statements\":[[8,\"pages/curate/brands/brand/index-page\",[],[[\"@brand\",\"@products\",\"@allProductsCount\",\"@orders\",\"@categories\",\"@hasDescendantsCategories\",\"@meta\",\"@queryParams\"],[[32,1,[\"brand\"]],[32,1,[\"products\"]],[32,1,[\"allProductsCount\"]],[32,1,[\"orders\"]],[32,1,[\"categories\"]],[32,1,[\"hasDescendantsCategories\"]],[32,1,[\"meta\"]],[32,1,[\"queryParams\"]]]],null],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[]}",
    "moduleName": "flaneur/pods/brand/boutiques/boutique/curate/brands/brand/index/template.hbs"
  });

  _exports.default = _default;
});