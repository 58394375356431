define("flaneur/pods/components/main-nav/component", ["exports", "flaneur/config/environment", "ember-component-css/pod-names"], function (_exports, _environment, _podNames) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  let MainNavComponent = (_dec = Ember.inject.service, _dec2 = Ember.inject.service, _dec3 = Ember.inject.service, _dec4 = Ember.inject.service, _dec5 = Ember.inject.service, _dec6 = Ember._action, _dec7 = Ember._action, (_class = class MainNavComponent extends Ember.Component {
    constructor(...args) {
      super(...args);

      _initializerDefineProperty(this, "analytics", _descriptor, this);

      _initializerDefineProperty(this, "brand", _descriptor2, this);

      _initializerDefineProperty(this, "media", _descriptor3, this);

      _initializerDefineProperty(this, "session", _descriptor4, this);

      _initializerDefineProperty(this, "onboarding", _descriptor5, this);

      _defineProperty(this, "showBoutiqueMenu", false);
    }

    get styleNamespace() {
      return _podNames.default['main-nav'];
    }

    get boutique() {
      return this.brand.currentBoutique;
    }

    get showInventoryPage() {
      return !this.boutique.isFeedBoutique;
    }

    get boutiqueURL() {
      return `${_environment.default.retailURL}/boutiques/${this.boutique.slug}`;
    }

    get exploreProfileURL() {
      return `${_environment.default.exploreURL}/profile`;
    }

    get showQuickShipNewLabel() {
      return this.isBoutiqueFromCountries(['DNK']);
    }

    get showAdditionalServices() {
      return this.boutique.isQuickShipEnabled || this.boutique.isPackagingEnabled || this.boutique.isCurateEnabled;
    }

    get isRestoreEnabled() {
      return _environment.default.featureFlags.isRestoreEnabled;
    }

    get hasVideoFeedUploadFeatureFlag() {
      var _this$session$user$fe, _featureFlags$include;

      const featureFlags = (_this$session$user$fe = this.session.user.feature_flags) !== null && _this$session$user$fe !== void 0 ? _this$session$user$fe : [];
      return (_featureFlags$include = featureFlags.includes('video-feed-upload')) !== null && _featureFlags$include !== void 0 ? _featureFlags$include : false;
    }

    get restoreURL() {
      return _environment.default.restoreURL;
    }

    isBoutiqueFromCountries(countries = []) {
      const {
        registeredAddressIsoCodeAlpha3: code
      } = this.boutique;
      return countries.includes(code);
    }

    toggleShowSidebar() {
      this.toggleProperty('showSidebar');

      if (this.media.isXlarge && !this.showSidebar) {
        const element = document.getElementById('overlay');

        if (element) {
          element.style.display = 'none';
        }
      }
    }

    trackCurate() {
      this.analytics.gtm({
        action: 'viewed_curate',
        label: this.boutique.id
      });
    }

  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "analytics", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "brand", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "media", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "session", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "onboarding", [_dec5], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "toggleShowSidebar", [_dec6], Object.getOwnPropertyDescriptor(_class.prototype, "toggleShowSidebar"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "trackCurate", [_dec7], Object.getOwnPropertyDescriptor(_class.prototype, "trackCurate"), _class.prototype)), _class));
  _exports.default = MainNavComponent;
});