define("flaneur/pods/components/pages/packaging/basket/validations", ["exports", "ember-cp-validations"], function (_exports, _emberCpValidations) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  const stripeEventValidator = () => {
    return (0, _emberCpValidations.validator)('inline', {
      validate(value) {
        if (!value) {
          return "This field can't be blank";
        }

        if (value.error) {
          return value.error.message;
        }

        return true;
      }

    });
  };

  const deliveryAddressFieldValidator = () => {
    return (0, _emberCpValidations.validator)('presence', {
      presence: true,
      message: "This field can't be blank"
    });
  };

  const Validations = (0, _emberCpValidations.buildValidations)({
    cardHolderName: (0, _emberCpValidations.validator)('presence', true),
    stripeCardNumberEvent: stripeEventValidator(),
    stripeCardExpiryEvent: stripeEventValidator(),
    stripeCardCvcEvent: stripeEventValidator(),
    'deliveryAddress.line1': deliveryAddressFieldValidator(),
    'deliveryAddress.city': deliveryAddressFieldValidator(),
    'deliveryAddress.postcode': deliveryAddressFieldValidator(),
    'deliveryAddress.country': deliveryAddressFieldValidator()
  }, {
    disabled: Ember.computed.readOnly('model.disableValidations')
  });
  var _default = Validations;
  _exports.default = _default;
});