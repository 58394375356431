define("flaneur/pods/services/quick-ship/index/route", ["exports", "flaneur/pods/brand/boutiques/boutique/shipments/index/route"], function (_exports, _route) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  class ServicesQuickShipIndexRoute extends _route.default {}

  _exports.default = ServicesQuickShipIndexRoute;
});