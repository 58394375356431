define("flaneur/pods/components/stock-line-status/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "jUEcj4gr",
    "block": "{\"symbols\":[],\"statements\":[[10,\"div\"],[14,0,\"stock-line-status__label\"],[12],[2,\"\\n  \"],[1,[34,0]],[2,\"\\n\"],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"label\"]}",
    "moduleName": "flaneur/pods/components/stock-line-status/template.hbs"
  });

  _exports.default = _default;
});