define("flaneur/pods/components/pages/products/new/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "+uXz3D/9",
    "block": "{\"symbols\":[\"button\"],\"statements\":[[10,\"div\"],[14,0,\"heading\"],[12],[2,\"\\n  \"],[10,\"div\"],[14,0,\"heading__back\"],[12],[2,\"\\n    \"],[8,\"link-to\",[],[[\"@route\",\"@models\"],[\"brand.boutiques.boutique.products\",[30,[36,0],[[32,0,[\"brand\"]],[32,0,[\"brand\",\"currentBoutique\"]]],null]]],[[\"default\"],[{\"statements\":[[2,\"\\n      \"],[8,\"fa-icon\",[[24,0,\"fa\"]],[[\"@icon\",\"@prefix\"],[\"long-arrow-left\",\"far\"]],null],[2,\"Back to Products\\n    \"]],\"parameters\":[]}]]],[2,\"\\n  \"],[13],[2,\"\\n  \"],[10,\"div\"],[14,0,\"heading__title\"],[12],[2,\"\\n    \"],[10,\"h1\"],[12],[2,\"New product\"],[13],[2,\"\\n  \"],[13],[2,\"\\n\"],[13],[2,\"\\n\\n\"],[8,\"form-flows/product\",[],[[\"@model\",\"@product\",\"@boutique\",\"@imagesChanged\"],[[34,1],[34,2],[34,3],[30,[36,4],[[32,0],\"onImagesChanged\"],null]]],[[\"default\"],[{\"statements\":[[2,\"\\n  \"],[10,\"div\"],[14,0,\"product-save\"],[12],[2,\"\\n    \"],[8,\"button\",[],[[\"@class\",\"@onClick\"],[\"product-save__btn\",[30,[36,4],[[32,0],\"onSave\"],null]]],[[\"default\"],[{\"statements\":[[2,\"\\n\"],[6,[37,5],[[32,1,[\"loading\"]]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"        Creating... \"],[8,[32,1,[\"spinner\"]],[],[[],[]],null],[2,\"\\n\"]],\"parameters\":[]},{\"statements\":[[2,\"        Create product\\n\"]],\"parameters\":[]}]]],[2,\"    \"]],\"parameters\":[1]}]]],[2,\"\\n  \"],[13],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"array\",\"model\",\"product\",\"boutique\",\"action\",\"if\"]}",
    "moduleName": "flaneur/pods/components/pages/products/new/template.hbs"
  });

  _exports.default = _default;
});