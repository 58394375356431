define("flaneur/pods/components/external-link/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "1wF+BBQJ",
    "block": "{\"symbols\":[\"&attrs\",\"&default\"],\"statements\":[[11,\"a\"],[24,0,\"external-link\"],[24,\"target\",\"_blank\"],[17,1],[12],[2,\"\\n  \"],[10,\"span\"],[14,0,\"anchor\"],[12],[18,2,null],[13],[2,\"\\n  \"],[8,\"fa-icon\",[[24,0,\"external-link__icon\"]],[[\"@icon\",\"@prefix\"],[\"external-link\",\"far\"]],null],[2,\"\\n\"],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[]}",
    "moduleName": "flaneur/pods/components/external-link/template.hbs"
  });

  _exports.default = _default;
});