define("flaneur/pods/application/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "yNgOStPS",
    "block": "{\"symbols\":[\"notification\",\"index\"],\"statements\":[[1,[30,[36,2],[[30,[36,1],null,null]],null]],[2,\"\\n\\n\"],[10,\"div\"],[14,1,\"modal-location\"],[12],[13],[2,\"\\n\\n\"],[10,\"div\"],[14,0,\"notifications\"],[12],[2,\"\\n\"],[6,[37,4],[[30,[36,3],[[30,[36,3],[[32,0,[\"pubsub\",\"notifications\"]]],null]],null]],null,[[\"default\"],[{\"statements\":[[2,\"    \"],[8,\"notification\",[],[[\"@title\",\"@body\",\"@onDismiss\"],[[32,1,[\"title\"]],[32,1,[\"body\"]],[30,[36,0],[[32,0,[\"dismissNotification\"]],[32,2]],null]]],null],[2,\"\\n\"]],\"parameters\":[1,2]}]]],[2,\"\\n  \"],[8,\"release-notification\",[],[[],[]],null],[2,\"\\n  \"],[8,\"terms-and-conditions-notification\",[],[[],[]],null],[2,\"\\n\"],[13],[2,\"\\n\\n\"],[6,[37,5],[[32,0,[\"showFeatureFlagsManager\"]]],null,[[\"default\"],[{\"statements\":[[2,\"  \"],[8,\"feature-flags-manager\",[],[[],[]],null],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"\\n\"],[6,[37,5],[[32,0,[\"restockProduct\"]]],null,[[\"default\"],[{\"statements\":[[2,\"  \"],[8,\"boutique-restock-information-modal\",[],[[\"@productId\",\"@origin\",\"@action\",\"@closeModal\"],[[32,0,[\"restockProduct\"]],[32,0,[\"restockOrigin\"]],[32,0,[\"restockAction\"]],[32,0,[\"closeRestockModal\"]]]],null],[2,\"\\n\"]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"fn\",\"-outlet\",\"component\",\"-track-array\",\"each\",\"if\"]}",
    "moduleName": "flaneur/pods/application/template.hbs"
  });

  _exports.default = _default;
});