define("flaneur/pods/components/pages/shipments/new/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "xvthHM0r",
    "block": "{\"symbols\":[\"@model\",\"@backToHistory\",\"@submitForm\"],\"statements\":[[10,\"div\"],[14,0,\"page\"],[12],[2,\"\\n  \"],[10,\"div\"],[14,0,\"heading\"],[12],[2,\"\\n    \"],[10,\"div\"],[14,0,\"heading__back\"],[12],[2,\"\\n      \"],[8,\"link-to\",[],[[\"@route\",\"@models\"],[[30,[36,0],[[32,0,[\"routePrefix\"]],\".index\"],null],[32,0,[\"routeModels\"]]]],[[\"default\"],[{\"statements\":[[2,\"\\n        \"],[8,\"fa-icon\",[[24,0,\"fa\"]],[[\"@icon\",\"@prefix\"],[\"long-arrow-left\",\"far\"]],null],[2,\"Back to My Shipments\\n      \"]],\"parameters\":[]}]]],[2,\"\\n    \"],[13],[2,\"\\n\\n    \"],[10,\"div\"],[14,0,\"heading__title\"],[12],[2,\"\\n      \"],[10,\"h1\"],[12],[2,\"Book a courier\"],[13],[2,\"\\n      \"],[10,\"h2\"],[12],[2,\"Use Trouva’s discounted DHL rates to book a courier for your non-Trouva shipments.\"],[13],[2,\"\\n    \"],[13],[2,\"\\n  \"],[13],[2,\"\\n\\n  \"],[8,\"form-flows/shipment\",[],[[\"@shipment\",\"@countries\",\"@boutique\",\"@boutiqueCollectionAddress\",\"@backToHistory\",\"@submitForm\"],[[32,1,[\"shipment\"]],[32,1,[\"countries\"]],[32,1,[\"boutique\"]],[32,1,[\"boutiqueCollectionAddress\"]],[32,2],[32,3]]],null],[2,\"\\n\"],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"concat\"]}",
    "moduleName": "flaneur/pods/components/pages/shipments/new/template.hbs"
  });

  _exports.default = _default;
});