define("flaneur/pods/components/pages/products/new/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    brand: Ember.inject.service(),
    // Public API
    model: {},

    onSave() {},

    // Private API
    classNames: ['page'],
    product: Ember.computed.alias('model.product'),
    actions: {
      onSave() {
        return this.onSave(this.model.product);
      },

      onImagesChanged(images) {
        return Ember.setProperties(this.product, {
          images,
          thumbnail: images[0]
        });
      }

    }
  });

  _exports.default = _default;
});