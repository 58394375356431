define("flaneur/utils/local-storage", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _window$localStorage;

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  class MockedLocalStorage {
    constructor() {
      _defineProperty(this, "storage", {});
    }

    getItem(key) {
      this.storage[key];
    }

    setItem(key, value) {
      this.storage[key] = value;
    }

  }

  var _default = (_window$localStorage = window.localStorage) !== null && _window$localStorage !== void 0 ? _window$localStorage : new MockedLocalStorage();

  _exports.default = _default;
});