define("flaneur/pods/brand/boutiques/boutique/insights/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "WNlGEVX1",
    "block": "{\"symbols\":[],\"statements\":[[6,[37,2],[[32,0,[\"hasNoOrders\"]]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"  \"],[8,\"empty-page\",[],[[\"@heading\",\"@title\",\"@tip\",\"@subtitle\",\"@image\",\"@items\"],[[30,[36,1],null,[[\"title\",\"subtitle\"],[\"Boutique performance - Insights panel\",\"Track your own performance on Trouva\"]]],\"Check how you are performing\",\"This page will update when your first order comes through.\",\"This is the analytics hub for your boutique on Trouva.com. Track your performance in terms of sales, products and deliveries and use the data to help maximise your sales.\",\"insights\",[32,0,[\"emptyPageItems\"]]]],null],[2,\"\\n\"]],\"parameters\":[]},{\"statements\":[[2,\"  \"],[8,\"pages/insights\",[],[[\"@params\",\"@meta\",\"@recommendations\"],[[32,0,[\"model\",\"queryParams\"]],[30,[36,0],[[32,0,[\"model\",\"meta\"]]],null],[30,[36,0],[[32,0,[\"model\",\"recommendations\"]]],null]]],null],[2,\"\\n\"]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"await\",\"hash\",\"if\"]}",
    "moduleName": "flaneur/pods/brand/boutiques/boutique/insights/template.hbs"
  });

  _exports.default = _default;
});