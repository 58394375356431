define("flaneur/pods/components/pages/products/bulk/upload/amend-step/error-row/message/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "NVvrmsjt",
    "block": "{\"symbols\":[],\"statements\":[[6,[37,0],[[32,0,[\"showSuccess\"]]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"  \"],[8,\"fa-icon\",[[24,0,\"fa fa--success\"]],[[\"@icon\",\"@prefix\"],[\"check\",\"far\"]],null],[2,\"\\n\"]],\"parameters\":[]},{\"statements\":[[2,\"  \"],[8,\"fa-icon\",[[24,0,\"fa fa--error\"]],[[\"@icon\",\"@prefix\"],[\"exclamation-circle\",\"fas\"]],null],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"\\n\"],[1,[30,[36,2],[[35,1,[\"message\"]]],null]],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"if\",\"error\",\"capitalize\"]}",
    "moduleName": "flaneur/pods/components/pages/products/bulk/upload/amend-step/error-row/message/template.hbs"
  });

  _exports.default = _default;
});