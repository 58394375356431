define("flaneur/transitions", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _default;

  function _default() {
    this.transition(this.hasClass('to-left'), this.toValue(true), this.use('toLeft', {
      duration: 450
    }), this.reverse('toRight', {
      duration: 450
    }));
  }
});