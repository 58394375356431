define("flaneur/pods/components/pages/orders/click-and-collect/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    actions: {
      markAsCollected() {
        return this.markAsCollected(...arguments);
      },

      resetSearch() {
        return this.resetSearch(...arguments);
      },

      refresh() {
        return this.refresh(...arguments);
      },

      loadMore() {
        return this.loadMore(...arguments);
      }

    }
  });

  _exports.default = _default;
});