define("flaneur/validators/iban", ["exports", "ember-cp-validations/validators/base"], function (_exports, _base) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  const Iban = _base.default.extend({
    validate(value, options) {
      if (options.allowEmpty && Ember.isEmpty(value)) {
        return true;
      }

      return emberAutoImportDynamic("iban").then(({
        isValid
      }) => {
        return isValid(value) || options.message || 'Please insert a valid IBAN';
      });
    }

  });

  var _default = Iban;
  _exports.default = _default;
});