define("flaneur/couriers-data/p2g-couriers", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); enumerableOnly && (symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols); } return keys; }

  function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = null != arguments[i] ? arguments[i] : {}; i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { _defineProperty(target, key, source[key]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } return target; }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  const couriers = {
    113: {
      picked_up_by: 'parcelforce',
      courier_to_display: 'parcelforce',
      courier_service: 'parcelforce-express-am'
    },
    114: {
      picked_up_by: 'parcelforce',
      courier_to_display: 'parcelforce',
      courier_service: 'parcelforce-express-24'
    },
    115: {
      picked_up_by: 'parcelforce',
      courier_to_display: 'parcelforce',
      courier_service: 'parcelforce-express-48'
    },
    118: {
      picked_up_by: 'palletforce',
      courier_to_display: 'palletforce',
      courier_service: 'palletforce-delivery-48-hours'
    },
    124: {
      picked_up_by: 'fedex-express',
      courier_to_display: 'fedex',
      courier_service: 'fedex-world-economy'
    },
    159: {
      picked_up_by: 'ups',
      courier_to_display: 'UPS',
      logo: 'https://trouva-media.imgix.net/rphc/couriers/ups_2x.png',
      courier_service: 'parcel2go-uk-express'
    },
    162: {
      picked_up_by: 'parcelforce',
      courier_to_display: 'parcelforce',
      courier_service: 'parcelforce-multi-12-noon'
    },
    166: {
      picked_up_by: 'parcelforce',
      courier_to_display: 'parcelforce',
      courier_service: 'parcelforce-large-48'
    },
    167: {
      picked_up_by: 'tnt',
      courier_to_display: 'tnt',
      courier_service: 'tnt-express'
    },
    168: {
      picked_up_by: 'tnt',
      courier_to_display: 'tnt',
      courier_service: 'tnt-economy-express'
    },
    169: {
      picked_up_by: 'palletforce',
      courier_to_display: 'palletforce',
      courier_service: 'palletforce-delivery-next-day'
    },
    201: {
      picked_up_by: 'parcelforce',
      courier_to_display: 'parcelforce',
      courier_service: 'parcelforce-euro-priority'
    },
    205: {
      picked_up_by: 'tnt',
      courier_to_display: 'tnt',
      courier_service: 'tnt-uk-saturday-express'
    },
    206: {
      picked_up_by: 'tnt',
      courier_to_display: 'tnt',
      courier_service: 'tnt-uk-12-00-express'
    },
    207: {
      picked_up_by: 'tnt',
      courier_to_display: 'tnt',
      courier_service: 'tnt-uk-express-service'
    },
    208: {
      picked_up_by: 'hermes',
      courier_to_display: 'hermes',
      logo: 'https://trouva-media.imgix.net/rphc/couriers/myhermes_2x.png',
      courier_service: 'hermes-uk-economy'
    },
    211: {
      picked_up_by: 'tnt',
      courier_to_display: 'tnt',
      courier_service: 'tnt-uk-10-00-express'
    },
    212: {
      picked_up_by: 'tnt',
      courier_to_display: 'tnt',
      courier_service: 'tnt-uk-09-00-express'
    },
    213: {
      picked_up_by: 'yodel',
      courier_to_display: 'yodel',
      courier_service: 'yodel-24'
    },
    214: {
      picked_up_by: 'ups',
      courier_to_display: 'UPS',
      logo: 'https://trouva-media.imgix.net/rphc/couriers/ups_2x.png',
      courier_service: 'parcel2go-express-saver'
    },
    216: {
      picked_up_by: 'collectplus',
      courier_to_display: 'collectplus',
      courier_service: 'collect-plus-drop-off-service'
    },
    220: {
      picked_up_by: 'ups',
      courier_to_display: 'UPS',
      logo: 'https://trouva-media.imgix.net/rphc/couriers/ups_2x.png',
      courier_service: 'parcel2go-uk-standard'
    },
    225: {
      picked_up_by: 'ups',
      courier_to_display: 'UPS',
      logo: 'https://trouva-media.imgix.net/rphc/couriers/ups_2x.png',
      courier_service: 'parcel2go-standard'
    },
    226: {
      picked_up_by: 'yodel',
      courier_to_display: 'yodel',
      courier_service: 'yodel-48'
    },
    232: {
      picked_up_by: 'ups',
      courier_to_display: 'UPS',
      logo: 'https://trouva-media.imgix.net/rphc/couriers/ups_2x.png',
      courier_service: 'parcel2go-express'
    },
    250: {
      picked_up_by: 'hermes',
      courier_to_display: 'hermes',
      logo: 'https://trouva-media.imgix.net/rphc/couriers/myhermes_2x.png',
      courier_service: 'myhermes-parcelshop'
    },
    251: {
      picked_up_by: 'citysprint',
      courier_to_display: 'citysprint',
      courier_service: 'small-van-delivery'
    },
    252: {
      picked_up_by: 'citysprint',
      courier_to_display: 'citysprint',
      courier_service: 'large-van-delivery'
    },
    253: {
      picked_up_by: 'citysprint',
      courier_to_display: 'citysprint',
      courier_service: 'extra-large-van-delivery'
    },
    254: {
      picked_up_by: 'ajg',
      courier_to_display: 'ajg',
      courier_service: 'ajg-highlands-and-islands'
    },
    255: {
      picked_up_by: 'tnt',
      courier_to_display: 'tnt',
      courier_service: 'tnt-uk-express-(ni)'
    },
    257: {
      picked_up_by: 'parcelforce',
      courier_to_display: 'parcelforce',
      courier_service: 'parcelforce-global-priority'
    },
    260: {
      picked_up_by: 'tnt',
      courier_to_display: 'tnt',
      courier_service: 'tnt-express-(ni)'
    },
    261: {
      picked_up_by: 'tnt',
      courier_to_display: 'tnt',
      courier_service: 'tnt-economy-express-(ni)'
    },
    266: {
      picked_up_by: 'collectplus',
      courier_to_display: 'collectplus',
      courier_service: 'collect-plus-drop-off-service-(ni)'
    },
    276: {
      picked_up_by: 'dpd',
      courier_to_display: 'dpd',
      courier_service: 'dpd-classic-european'
    },
    277: {
      picked_up_by: 'ups-access-point',
      courier_to_display: 'ups-access-point',
      courier_service: 'ups-access-point'
    },
    278: {
      picked_up_by: 'ups-access-point',
      courier_to_display: 'ups-access-point',
      courier_service: 'ups-access-point-euro'
    },
    294: {
      picked_up_by: 'norsk',
      courier_to_display: 'norsk',
      courier_service: 'p2g-international-saver'
    },
    297: {
      picked_up_by: 'ups-access-point',
      courier_to_display: 'ups-access-point',
      courier_service: 'ups-shop-to-shop'
    },
    298: {
      picked_up_by: 'norsk',
      courier_to_display: 'norsk',
      courier_service: 'p2g-international-saver-dropshop'
    },
    299: {
      picked_up_by: 'hermes',
      courier_to_display: 'hermes',
      logo: 'https://trouva-media.imgix.net/rphc/couriers/myhermes_2x.png',
      courier_service: 'direct-entry'
    },
    3: {
      picked_up_by: 'yodel',
      courier_to_display: 'yodel',
      courier_service: 'yodel-northern-ireland'
    },
    306: {
      picked_up_by: 'hermes',
      courier_to_display: 'hermes',
      logo: 'https://trouva-media.imgix.net/rphc/couriers/myhermes_2x.png',
      courier_service: 'direct-entry-dropoff'
    },
    320: {
      picked_up_by: 'inpost',
      courier_to_display: 'inpost',
      courier_service: 'inpost'
    },
    322: {
      picked_up_by: 'ups',
      courier_to_display: 'UPS',
      logo: 'https://trouva-media.imgix.net/rphc/couriers/ups_2x.png',
      courier_service: 'parcel2go-express-saver-import'
    },
    323: {
      picked_up_by: 'ups',
      courier_to_display: 'UPS',
      logo: 'https://trouva-media.imgix.net/rphc/couriers/ups_2x.png',
      courier_service: 'parcel2go-express-import'
    },
    324: {
      picked_up_by: 'ups',
      courier_to_display: 'UPS',
      logo: 'https://trouva-media.imgix.net/rphc/couriers/ups_2x.png',
      courier_service: 'parcel2go-standard-import'
    },
    325: {
      picked_up_by: 'streetwise',
      courier_to_display: 'streetwise',
      courier_service: 'streetwise-couriers'
    },
    345: {
      picked_up_by: 'hermes',
      courier_to_display: 'hermes',
      logo: 'https://trouva-media.imgix.net/rphc/couriers/myhermes_2x.png',
      courier_service: 'impact-express'
    },
    347: {
      picked_up_by: 'hermes',
      courier_to_display: 'hermes',
      logo: 'https://trouva-media.imgix.net/rphc/couriers/myhermes_2x.png',
      courier_service: 'impact-express-drop-off'
    },
    350: {
      picked_up_by: 'hermes',
      courier_to_display: 'hermes',
      logo: 'https://trouva-media.imgix.net/rphc/couriers/myhermes_2x.png',
      courier_service: 'hermes-international-economy'
    },
    351: {
      picked_up_by: 'hermes',
      courier_to_display: 'hermes',
      logo: 'https://trouva-media.imgix.net/rphc/couriers/myhermes_2x.png',
      courier_service: 'myhermes-international-parcelshop'
    },
    354: {
      picked_up_by: 'hermes',
      courier_to_display: 'hermes',
      logo: 'https://trouva-media.imgix.net/rphc/couriers/myhermes_2x.png',
      courier_service: 'asendia'
    },
    355: {
      picked_up_by: 'hermes',
      courier_to_display: 'hermes',
      logo: 'https://trouva-media.imgix.net/rphc/couriers/myhermes_2x.png',
      courier_service: 'asendia-drop-shop'
    },
    357: {
      picked_up_by: 'dpd',
      courier_to_display: 'dpd',
      courier_service: 'dpd-ireland'
    },
    358: {
      picked_up_by: 'nightline',
      courier_to_display: 'nightline',
      courier_service: 'nightline-uk-to-ie'
    },
    359: {
      picked_up_by: 'parcelforce',
      courier_to_display: 'parcelforce',
      courier_service: 'parcelforce-express-48-drop-off'
    },
    361: {
      picked_up_by: 'dpd',
      courier_to_display: 'dpd',
      courier_service: 'dpd-air-classic'
    },
    362: {
      picked_up_by: 'parcelforce',
      courier_to_display: 'parcelforce',
      courier_service: 'parcelforce-express-24-drop-off'
    },
    363: {
      picked_up_by: 'dx',
      courier_to_display: 'dx',
      courier_service: 'dx-24'
    },
    364: {
      picked_up_by: 'dx',
      courier_to_display: 'dx',
      courier_service: 'dx-48'
    },
    365: {
      picked_up_by: 'parcelforce',
      courier_to_display: 'parcelforce',
      courier_service: 'parcelforce-euro-priority-drop-shop'
    },
    366: {
      picked_up_by: 'parcelforce',
      courier_to_display: 'parcelforce',
      courier_service: 'parcelforce-global-priority-drop-shop'
    },
    368: {
      picked_up_by: 'inpost',
      courier_to_display: 'inpost',
      courier_service: 'inpost-24'
    },
    369: {
      picked_up_by: 'norsk',
      courier_to_display: 'norsk',
      courier_service: 'p2g-international-saver-multi'
    },
    370: {
      picked_up_by: 'norsk',
      courier_to_display: 'norsk',
      courier_service: 'p2g-international-saver-multi-drop'
    },
    371: {
      picked_up_by: 'fastway',
      courier_to_display: 'fastway',
      courier_service: 'fastway-ni'
    },
    373: {
      picked_up_by: 'dpd',
      courier_to_display: 'dpd',
      courier_service: 'dpd'
    },
    374: {
      picked_up_by: 'dpd',
      courier_to_display: 'dpd',
      courier_service: 'dpd-classic'
    },
    375: {
      picked_up_by: 'dpd',
      courier_to_display: 'dpd',
      courier_service: 'dpd-pickup-ireland'
    },
    384: {
      picked_up_by: 'ukmail',
      courier_to_display: 'ukmail',
      courier_service: 'uk-mail-next-day'
    },
    387: {
      picked_up_by: 'dpd',
      courier_to_display: 'dpd',
      courier_service: 'dpd-air-express'
    },
    388: {
      picked_up_by: 'dpd',
      courier_to_display: 'dpd',
      courier_service: 'dpd-pickup-air-express'
    },
    390: {
      picked_up_by: 'yodel',
      courier_to_display: 'yodel',
      courier_service: 'yodel-xpress-24'
    },
    391: {
      picked_up_by: 'yodel',
      courier_to_display: 'yodel',
      courier_service: 'yodel-xpress-48'
    },
    393: {
      picked_up_by: 'ups',
      courier_to_display: 'UPS',
      logo: 'https://trouva-media.imgix.net/rphc/couriers/ups_2x.png',
      courier_service: 'parcel2go-express-uk'
    },
    394: {
      picked_up_by: 'ups',
      courier_to_display: 'UPS',
      logo: 'https://trouva-media.imgix.net/rphc/couriers/ups_2x.png',
      courier_service: 'parcel2go-uk-express-saver'
    },
    395: {
      picked_up_by: 'parcelforce',
      courier_to_display: 'parcelforce',
      courier_service: 'parcelforce-by-10am'
    },
    396: {
      picked_up_by: 'parcelforce',
      courier_to_display: 'parcelforce',
      courier_service: 'parcelforce-by-9am'
    },
    397: {
      picked_up_by: 'dpd',
      courier_to_display: 'dpd',
      courier_service: 'dpd-pickup-air-classic'
    },
    398: {
      picked_up_by: 'parcelforce',
      courier_to_display: 'parcelforce',
      courier_service: 'parcelforce-channel-islands'
    },
    399: {
      picked_up_by: 'hermes',
      courier_to_display: 'hermes',
      logo: 'https://trouva-media.imgix.net/rphc/couriers/myhermes_2x.png',
      courier_service: 'usps-via-ocs'
    },
    400: {
      picked_up_by: 'hermes',
      courier_to_display: 'hermes',
      logo: 'https://trouva-media.imgix.net/rphc/couriers/myhermes_2x.png',
      courier_service: 'upsc-via-ocs-drop-shop'
    },
    402: {
      picked_up_by: 'hermes',
      courier_to_display: 'hermes',
      logo: 'https://trouva-media.imgix.net/rphc/couriers/myhermes_2x.png',
      courier_service: 'ocs-australia-post'
    },
    403: {
      picked_up_by: 'hermes',
      courier_to_display: 'hermes',
      logo: 'https://trouva-media.imgix.net/rphc/couriers/myhermes_2x.png',
      courier_service: 'ocs-australia-post-drop-off'
    },
    404: {
      picked_up_by: 'hermes',
      courier_to_display: 'hermes',
      logo: 'https://trouva-media.imgix.net/rphc/couriers/myhermes_2x.png',
      courier_service: 'hermes-uk-economy-small'
    },
    405: {
      picked_up_by: 'hermes',
      courier_to_display: 'hermes',
      logo: 'https://trouva-media.imgix.net/rphc/couriers/myhermes_2x.png',
      courier_service: 'hermes-uk-economy-medium'
    },
    406: {
      picked_up_by: 'myhermes',
      courier_to_display: 'myhermes',
      logo: 'https://trouva-media.imgix.net/rphc/couriers/myhermes_2x.png',
      courier_service: 'myhermes-parcelshop-small'
    },
    407: {
      picked_up_by: 'myhermes',
      courier_to_display: 'myhermes',
      logo: 'https://trouva-media.imgix.net/rphc/couriers/myhermes_2x.png',
      courier_service: 'myhermes-parcelshop-medium'
    },
    413: {
      picked_up_by: 'hermes',
      courier_to_display: 'hermes',
      logo: 'https://trouva-media.imgix.net/rphc/couriers/myhermes_2x.png',
      courier_service: 'ocs'
    },
    414: {
      picked_up_by: 'hermes',
      courier_to_display: 'hermes',
      logo: 'https://trouva-media.imgix.net/rphc/couriers/myhermes_2x.png',
      courier_service: 'ocs-drop-off'
    },
    415: {
      picked_up_by: 'hermes',
      courier_to_display: 'hermes',
      logo: 'https://trouva-media.imgix.net/rphc/couriers/myhermes_2x.png',
      courier_service: 'ocs-new-zealand-post'
    },
    416: {
      picked_up_by: 'hermes',
      courier_to_display: 'hermes',
      logo: 'https://trouva-media.imgix.net/rphc/couriers/myhermes_2x.png',
      courier_service: 'ocs-new-zealand-post-drop-off'
    },
    419: {
      picked_up_by: 'collectplus',
      courier_to_display: 'collectplus',
      courier_service: 'collect-plus-drop-off-service-24hr'
    },
    422: {
      picked_up_by: 'ups-access-point',
      courier_to_display: 'ups-access-point',
      courier_service: 'ups-access-point-express'
    },
    423: {
      picked_up_by: 'tnt',
      courier_to_display: 'tnt',
      courier_service: 'tnt-euro-express-import'
    },
    424: {
      picked_up_by: 'tnt',
      courier_to_display: 'tnt',
      courier_service: 'tnt-euro-economy-express-import'
    },
    426: {
      picked_up_by: 'hermes',
      courier_to_display: 'hermes',
      logo: 'https://trouva-media.imgix.net/rphc/couriers/myhermes_2x.png',
      courier_service: 'landmark-canadapost-collection'
    },
    427: {
      picked_up_by: 'hermes',
      courier_to_display: 'hermes',
      logo: 'https://trouva-media.imgix.net/rphc/couriers/myhermes_2x.png',
      courier_service: 'landmark-canadapost-collection'
    },
    428: {
      picked_up_by: 'hermes',
      courier_to_display: 'hermes',
      logo: 'https://trouva-media.imgix.net/rphc/couriers/myhermes_2x.png',
      courier_service: 'landmark-collection'
    },
    429: {
      picked_up_by: 'hermes',
      courier_to_display: 'hermes',
      logo: 'https://trouva-media.imgix.net/rphc/couriers/myhermes_2x.png',
      courier_service: 'landmark-dropoff'
    },
    430: {
      picked_up_by: 'dpd',
      courier_to_display: 'dpd',
      courier_service: 'dpd-express-classic-v2'
    },
    431: {
      picked_up_by: 'dpd',
      courier_to_display: 'dpd',
      courier_service: 'dpd-pickup-import'
    },
    437: {
      picked_up_by: 'tnt',
      courier_to_display: 'tnt',
      courier_service: 'tnt-uk-standard-service'
    },
    438: {
      picked_up_by: 'hermes',
      courier_to_display: 'hermes',
      logo: 'https://trouva-media.imgix.net/rphc/couriers/myhermes_2x.png',
      courier_service: 'landmark-global-world-heavy'
    },
    439: {
      picked_up_by: 'hermes',
      courier_to_display: 'hermes',
      logo: 'https://trouva-media.imgix.net/rphc/couriers/myhermes_2x.png',
      courier_service: 'hermes-light-and-large'
    },
    440: {
      picked_up_by: 'parcelforce',
      courier_to_display: 'parcelforce',
      courier_service: 'parcelforce-large-24'
    },
    443: {
      picked_up_by: 'hermes',
      courier_to_display: 'hermes',
      logo: 'https://trouva-media.imgix.net/rphc/couriers/myhermes_2x.png',
      courier_service: 'landmark-canadapost-heavy-collection'
    },
    444: {
      picked_up_by: 'dpd',
      courier_to_display: 'dpd',
      courier_service: 'dpd-drop-off-standard'
    },
    445: {
      picked_up_by: 'hermes',
      courier_to_display: 'hermes',
      logo: 'https://trouva-media.imgix.net/rphc/couriers/myhermes_2x.png',
      courier_service: 'ocs-austria-post-coll'
    },
    446: {
      picked_up_by: 'hermes',
      courier_to_display: 'hermes',
      logo: 'https://trouva-media.imgix.net/rphc/couriers/myhermes_2x.png',
      courier_service: 'ocs-austria-post-drop'
    },
    448: {
      picked_up_by: 'tnt',
      courier_to_display: 'tnt',
      courier_service: 'tnt-12-00-euro-express'
    },
    449: {
      picked_up_by: 'tnt',
      courier_to_display: 'tnt',
      courier_service: 'tnt-09-00-euro-express'
    },
    450: {
      picked_up_by: 'tnt',
      courier_to_display: 'tnt',
      courier_service: 'tnt-10-00-euro-express'
    },
    451: {
      picked_up_by: 'dpd',
      courier_to_display: 'dpd',
      courier_service: 'dpd-direct-pick'
    },
    552: {
      picked_up_by: 'dpd',
      courier_to_display: 'dpd',
      courier_service: 'dpd-int-ukire'
    },
    553: {
      picked_up_by: 'dpd',
      courier_to_display: 'dpd',
      courier_service: 'dpd-ukire-dom'
    },
    554: {
      picked_up_by: 'palletways',
      courier_to_display: 'palletways',
      courier_service: 'palletways'
    },
    555: {
      picked_up_by: 'aramex',
      courier_to_display: 'aramex',
      courier_service: 'upsc-via-aramex-drop-shop'
    },
    556: {
      picked_up_by: 'one-world-express',
      courier_to_display: 'one-world-express',
      courier_service: 'one-world-express'
    },
    557: {
      picked_up_by: 'yodel',
      courier_to_display: 'yodel',
      courier_service: 'yodel-by-12'
    },
    560: {
      picked_up_by: 'ups',
      courier_to_display: 'UPS',
      logo: 'https://trouva-media.imgix.net/rphc/couriers/ups_2x.png',
      courier_service: 'parcel2go-multi-parcel-express-saver_560'
    },
    89: {
      picked_up_by: 'fedex-express',
      courier_to_display: 'fedex',
      courier_service: 'fedex-world-express'
    },
    90: {
      picked_up_by: 'yodel',
      courier_to_display: 'yodel',
      courier_service: 'yodel-highland-and-islands'
    }
  };
  const defaultCourier = {
    picked_up_by: 'other',
    courier_to_display: 'other',
    courier_service: 'other'
  };

  const getById = id => {
    return couriers[id] || defaultCourier;
  };

  var _default = _objectSpread(_objectSpread({}, couriers), {}, {
    getById
  });

  _exports.default = _default;
});