define("flaneur/pods/brand/boutiques/boutique/index/route", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    brand: Ember.inject.service(),
    features: Ember.inject.service(),

    redirect() {
      return this.transitionTo('brand.boutiques.boutique.dashboard', this.modelFor('brand'), this.brand.currentBoutique);
    }

  });

  _exports.default = _default;
});