define("flaneur/util/validation/date", ["exports", "ember-validators/utils/validation-error", "date-fns"], function (_exports, _validationError, _dateFns) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.validateDate = validateDate;

  function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); enumerableOnly && (symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols); } return keys; }

  function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = null != arguments[i] ? arguments[i] : {}; i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { _defineProperty(target, key, source[key]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } return target; }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  /**
   * This function is adapted from 'ember-validators' due to a bug preventing
   * to use `onOrAfter` option and similar options due to `Ember.set()` usage
   * to set the `options` argument. Also, it uses date-fns to simplify some
   * of the logic.
   */
  function validateDate(value, options) {
    let errorFormat = options.errorFormat || 'MMM do, yyyy';
    let {
      format,
      allowBlank = false
    } = options;
    let {
      before,
      onOrBefore,
      after,
      onOrAfter
    } = options;

    if (allowBlank && Ember.isEmpty(value)) {
      return true;
    }

    let date;

    if (format) {
      try {
        date = parseDate(value, format);
      } catch (error) {
        return (0, _validationError.default)('wrongDateFormat', value, options);
      }

      if (!(0, _dateFns.isValid)(date)) {
        return (0, _validationError.default)('date', value, options);
      }
    } else {
      date = parseDate(value);

      if (!(0, _dateFns.isValid)(date)) {
        return (0, _validationError.default)('date', value, options);
      }
    }

    if (before) {
      before = parseDate(before, format);

      if (!(0, _dateFns.isBefore)(date, before)) {
        return (0, _validationError.default)('before', value, _objectSpread(_objectSpread({}, options), {}, {
          before: (0, _dateFns.format)(before, errorFormat)
        }));
      }
    }

    if (onOrBefore) {
      onOrBefore = parseDate(onOrBefore, format);
      const isOnOrBefore = (0, _dateFns.isSameSecond)(date, onOrBefore) || (0, _dateFns.isBefore)(onOrBefore);

      if (!isOnOrBefore) {
        return (0, _validationError.default)('onOrBefore', value, _objectSpread(_objectSpread({}, options), {}, {
          onOrBefore: (0, _dateFns.format)(onOrBefore, errorFormat)
        }));
      }
    }

    if (after) {
      after = parseDate(after, format);

      if (!date.isAfter(after)) {
        return (0, _validationError.default)('after', value, _objectSpread(_objectSpread({}, options), {}, {
          after: (0, _dateFns.format)(after, errorFormat)
        }));
      }
    }

    if (onOrAfter) {
      onOrAfter = parseDate(onOrAfter, format);
      const isOnOrAfter = (0, _dateFns.isSameSecond)(date, onOrAfter) || (0, _dateFns.isAfter)(date, onOrAfter);

      if (!isOnOrAfter) {
        return (0, _validationError.default)('onOrAfter', value, _objectSpread(_objectSpread({}, options), {}, {
          onOrAfter: (0, _dateFns.format)(onOrAfter, errorFormat)
        }));
      }
    }

    return true;
  }

  function parseDate(date, format) {
    if (date === 'now' || Ember.isEmpty(date)) {
      return new Date();
    }

    if (Ember.isNone(format)) {
      return (0, _dateFns.parseISO)(date);
    }

    return (0, _dateFns.parse)(date, format, new Date());
  }
});