define("flaneur/pods/components/product-amendment-card/component", ["exports", "flaneur/util/default-to", "flaneur/config/environment"], function (_exports, _defaultTo, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    // Public API
    amendment: (0, _defaultTo.default)(null),
    resolve: (0, _defaultTo.default)(() => {}),
    trouvaLogo: _environment.default.trouvaLogo,
    defaultProductAmendmentFrom: _environment.default.defaultProductAmendmentFrom,
    // Private API
    classNames: ['product-amendment-card'],
    classNameBindings: ['_cardState'],
    isPending: Ember.computed.equal('amendment.status', 'pending'),
    isResolved: Ember.computed.equal('amendment.status', 'resolved'),
    isApproved: Ember.computed.equal('amendment.status', 'approved'),
    isRejected: Ember.computed.equal('amendment.status', 'rejected'),
    _cardState: Ember.computed('amendment.status', function () {
      // product-amendment-card--pending when the change is awaiting action from the boutique
      // product-amendment-card--resolved when the change has been made by the boutique
      // product-amendment-card--approved when the change has been approved by the curation team
      // product-amendment-card--rejected when the change has been rejected by the curation team
      const state = Ember.get(this, 'amendment.status');
      return `product-amendment-card--${state}`;
    }),
    actions: {
      resolve(amendment) {
        const resolve = this.resolve; // Set the status to resolved and save

        Ember.set(amendment, 'status', 'resolved');
        this.analytics.gtm({
          action: 'resolved_note',
          label: amendment.product
        });
        return resolve(amendment);
      }

    }
  });

  _exports.default = _default;
});