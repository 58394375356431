define("flaneur/pods/brand/boutiques/boutique/orders/index/route", ["exports", "flaneur/pods/brand/boutiques/boutique/orders/stage/route"], function (_exports, _route) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _route.default.extend({
    brand: Ember.inject.service(),
    media: Ember.inject.service(),

    beforeModel() {
      const {
        brand: {
          _id: brandId,
          currentBoutique
        }
      } = this;
      const boutiqueId = currentBoutique._id;
      const parentModel = this.modelFor('brand.boutiques.boutique.orders');
      const hasOrders = parentModel.totalNewOrdersCount > 0;
      const isBoutiqueShippingProviderOther = currentBoutique.shipping_provider === 'other';

      if (!this.media.isLarge || !hasOrders || isBoutiqueShippingProviderOther) {
        return this.transitionTo('brand.boutiques.boutique.orders.stage', brandId, boutiqueId, 'active');
      }
    }

  });

  _exports.default = _default;
});