define("flaneur/pods/components/reservation-product/return/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    reservations: [],
    reservation: null,
    classNames: ['return-order-item'],
    modalActive: false,
    requestedReturnReason: Ember.computed.alias('reservation.requested_return_reason'),
    requestedReturnAt: Ember.computed.alias('reservation.requested_return_at'),

    markAsReturned() {},

    actions: {
      showModal() {
        this.set('modalActive', true);
      }

    }
  });

  _exports.default = _default;
});