define("flaneur/pods/components/pages/products/index-page/component", ["exports", "flaneur/models/product", "flaneur/mixins/keyword-highlight", "flaneur/utils/pipeline-requirements", "flaneur/enums/boutique-restock-information-origin", "flaneur/mixins/bulk-update", "flaneur/utils/params-lists"], function (_exports, _product, _keywordHighlight, _pipelineRequirements, _boutiqueRestockInformationOrigin, _bulkUpdate, _paramsLists) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); enumerableOnly && (symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols); } return keys; }

  function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = null != arguments[i] ? arguments[i] : {}; i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { _defineProperty(target, key, source[key]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } return target; }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  var _default = Ember.Component.extend(_keywordHighlight.default, _bulkUpdate.default, {
    analytics: Ember.inject.service(),
    brand: Ember.inject.service(),
    media: Ember.inject.service(),
    router: Ember.inject.service(),
    // Public API
    model: null,
    limitList: _paramsLists.limitList,
    madeStatusFilterList: _paramsLists.madeStatusFilterList,
    salesFilterList: _paramsLists.salesFilterList,
    sortFilterList: _paramsLists.sortFilterList,
    stockFilterList: _paramsLists.stockFilterList,

    onResetFilters() {},

    onClickBulkUploadProducts() {},

    // Private API
    classNames: ['page'],
    classNameBindings: ['isEditMode:page--edit', 'isFeedBoutique:page--feed-boutique', 'isMadeEnabled:page--made-enabled', 'hideMultiSelect:page--hide-multi-select'],
    tabs: [{
      label: 'All',
      stage: 'none'
    }, {
      label: 'Updates Required',
      stage: 'pending_updates',
      tooltip: "This is the the total number of products that can't go live on Trouva until you update them"
    }, {
      label: 'Live Products',
      stage: 'total_live'
    }, {
      label: 'Unlisted',
      stage: 'unlisted',
      class: 'tabs__tab--light',
      tooltip: 'These products have been reviewed by our team but cannot be sold on Trouva at this time'
    }],
    filters: ['sales', 'sort', 'stock'],
    selectedLimit: null,
    keywordHighlightOpts: {
      keywordField: 'searchQuery',
      selectors: ['.products__product__name', '.products__product__manufacturer']
    },
    products: Ember.computed.alias('model.products'),
    stage: Ember.computed.alias('model.meta.stage'),
    searchQuery: Ember.computed.alias('model.meta.query'),
    isFeedBoutique: Ember.computed.alias('brand.currentBoutique.isFeedBoutique'),
    isMadeEnabled: Ember.computed.alias('brand.currentBoutique.isMadeEnabled'),
    showingArchived: Ember.computed.alias('model.meta.archived'),
    hasSearchQuery: Ember.computed.gte('searchQuery.length', 1),
    hasSalesFilter: Ember.computed.notEmpty('selectedSalesFilter.params.sales'),
    hasStockFilter: Ember.computed.notEmpty('selectedStockFilter.params.stock'),
    hasBulkOperationIdFilter: Ember.computed.notEmpty('model.meta.bulkOperationId'),
    hasMadeStatusFilter: Ember.computed.notEmpty('selectedMadeStatusFilter.params.made-status'),
    hasFilter: Ember.computed.or('hasSalesFilter', 'hasStockFilter', 'hasBulkOperationIdFilter', 'hasMadeStatusFilter'),
    hideMultiSelect: Ember.computed.or('isFeedBoutique', 'showingArchived'),
    columns: Ember.computed('showingArchived', function () {
      const defaultColumns = ['Image', 'Name', 'Brand', 'Price'];
      return [...defaultColumns, this.showingArchived ? 'State' : 'Stock status'].filter(Boolean);
    }),
    clearLabel: Ember.computed('hasSearchQuery', 'hasFilter', function () {
      if (this.hasSearchQuery && this.hasFilter) {
        return 'Clear all';
      }

      if (this.hasSearchQuery) {
        return 'Clear search';
      }

      if (this.hasFilter) {
        return 'Clear filter';
      }

      return null;
    }),
    emptyStateContent: Ember.computed('hasSalesFilter', 'hasSearchQuery', 'hasStockFilter', 'showingArchived', 'stage', function () {
      const defaultContent = {
        cta: 'View all',
        action: 'resetStage'
      };

      if (this.showingArchived) {
        return _objectSpread({
          title: 'You have no archived products'
        }, defaultContent);
      }

      const hasFiltersApplied = this.hasSearchQuery || this.hasSalesFilter || this.hasStockFilter;

      if (this.stage === 'unlisted' && !hasFiltersApplied) {
        return _objectSpread({
          title: 'You have no unlisted products'
        }, defaultContent);
      }

      if (this.stage === 'pending_updates' && !hasFiltersApplied) {
        return _objectSpread({
          title: 'You have no products that require updates 🎉'
        }, defaultContent);
      }

      if (this.stage === 'total_live' && !hasFiltersApplied) {
        return _objectSpread({
          title: 'You have no live products'
        }, defaultContent);
      }

      return {
        title: "Your filters didn't yield any results.",
        cta: 'Reset filters',
        action: 'resetFilters'
      };
    }),
    selectedProducts: Ember.computed('model.products.@each.checked', function () {
      const {
        products
      } = this.model;
      const selectedProducts = products.filterBy('checked').length;
      const totalProducts = products.length;

      if (selectedProducts > 0 && selectedProducts === totalProducts) {
        return 'all';
      }

      if (selectedProducts > 0 && selectedProducts < totalProducts) {
        return 'some';
      }

      return 'none';
    }),
    bulkOperation: Ember.computed('brand.currentBoutique', 'hasBulkOperationIdFilter', 'model.meta.bulkOperationId', function () {
      return this.hasBulkOperationIdFilter ? this.brand.currentBoutique.getBulkOperationById(this.model.meta.bulkOperationId) : null;
    }),
    showBanner: Ember.computed('brand.currentBoutique.{isFeedBoutique,isGBR}', function () {
      const {
        currentBoutique: boutique
      } = this.brand;
      return !boutique.isGBR || boutique.isFeedBoutique;
    }),

    didInsertElement() {
      this._super(...arguments);

      this.filters.forEach(filter => this._initializeFilter(filter, this.model.meta[filter]));
      this.setProperties({
        selectedLimit: this.limitList.find(({
          params: {
            limit
          }
        }) => Ember.isEqual(limit, this.model.meta.limit))
      });

      this._initializeNotifications();
    },

    _initializeFilter(name, value) {
      const normalizedFilterName = Ember.String.capitalize(Ember.String.camelize(name));
      const selectedFilter = this[`${Ember.String.camelize(name)}FilterList`].find(({
        params
      }) => Ember.isEqual(params[name], value));
      this.set(`selected${normalizedFilterName}Filter`, selectedFilter);
    },

    _initializeNotifications() {
      const {
        pendingUpdates,
        unlisted
      } = _pipelineRequirements.default;
      const notifications = [pendingUpdates, unlisted].map(query => {
        return _product.default.find(this._getNotificationsQuery(query));
      });
      return Ember.RSVP.all(notifications).then(res => {
        const [pending_updates, unlisted] = res.map(({
          meta: {
            total
          }
        }) => total);
        this.set('notifications', {
          pending_updates,
          unlisted
        });
      });
    },

    _getNotificationsQuery(query) {
      return _objectSpread(_objectSpread({}, query), {}, {
        limit: 1,
        page: 1,
        brand: this.brand._id,
        fields: ['_id'],
        archived: false
      });
    },

    _search(parameters) {
      return this.router.transitionTo({
        queryParams: _objectSpread(_objectSpread({}, parameters), {}, {
          page: 1
        })
      });
    },

    actions: {
      selectAllProducts(checked) {
        this.products.setEach('checked', checked);
      },

      onClickHandsfreeBulkUpload() {
        this.set('showBulkUploadOptions', false);
        this.analytics.gtm({
          action: 'click_handsfree_bulk_upload'
        });
        this.router.transitionTo('brand.boutiques.boutique.products.bulk', {
          queryParams: {
            upload: 'handsfree'
          }
        });
      },

      onClickRegularBulkUpload() {
        this.set('showBulkUploadOptions', false);
        this.router.transitionTo('brand.boutiques.boutique.products.bulk', {
          queryParams: {
            upload: 'manual'
          }
        });
        this.analytics.gtm({
          action: 'click_regular_bulk_upload'
        });
      },

      onChangeStage(stage) {
        return this._search({
          archived: false,
          stage
        });
      },

      onClickArchived() {
        return this._search({
          archived: true,
          stage: 'none'
        });
      },

      onChangeQuery(query) {
        this.analytics.gtm({
          action: 'applied_search_in_product_catalogue',
          label: query
        });
        return this._search({
          q: query
        });
      },

      onChangeSalesFilter(filter) {
        this.set('selectedSalesFilter', filter);
        this.analytics.gtm({
          action: 'applied_filter_products_in_catalogue_by',
          label: Object.keys(filter.params)[0]
        });
        return this._search(filter.params);
      },

      onChangeStockFilter(filter) {
        this.set('selectedStockFilter', filter);
        this.analytics.gtm({
          action: 'applied_filter_products_in_catalogue_by',
          label: Object.keys(filter.params)[0]
        });
        return this._search(filter.params);
      },

      onChangeMadeStatusFilter(filter) {
        this.set('selectedMadeStatusFilter', filter);
        this.analytics.gtm({
          action: 'applied_filter_products_in_catalogue_by',
          label: Object.keys(filter.params)[0]
        });
        return this._search(filter.params);
      },

      onChangeSortFilter(filter) {
        this.set('selectedSortFilter', filter);
        this.analytics.gtm({
          action: 'applied_sort_products_in_catalogue_by',
          label: filter.params.sort
        });
        return this._search(filter.params);
      },

      onChangeLimit(limit) {
        this.set('selectedLimit', limit);
        return this._search(limit.params);
      },

      resetStage() {
        return this._search({
          archived: false,
          stage: 'none'
        });
      },

      resetFilters() {
        this._initializeFilter('sales', null);

        this._initializeFilter('stock', null);

        this._initializeFilter('made-status', null);

        return this.onResetFilters();
      },

      isInStock(quantity) {
        return quantity !== 0;
      },

      openHelpCenter() {
        return window.open('https://trouvaboutiques.zendesk.com/hc/en-us/articles/360015297137-Cyber-Weekend-Sale-2020');
      },

      showRestockModal(product) {
        this.router.transitionTo({
          queryParams: {
            'restock-product': product._id,
            'restock-origin': _boutiqueRestockInformationOrigin.default.PRODUCT_CATALOGUE_ACTIONS
          }
        });
        this.analytics.gtm({
          action: 'restock-modal-open',
          label: 'dropdown'
        });
      }

    }
  });

  _exports.default = _default;
});