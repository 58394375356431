define("flaneur/pods/brand/boutiques/boutique/packaging/products/route", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  class BrandBoutiquesBoutiquePackagingProductsRoute extends Ember.Route {}

  _exports.default = BrandBoutiquesBoutiquePackagingProductsRoute;
});