define("flaneur/services/order-history", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const ORDERS_INDEX = 'brand.boutiques.boutique.orders.index';
  const ORDERS_STAGE = 'brand.boutiques.boutique.orders.stage';

  var _default = Ember.Service.extend({
    routes: [],
    orderSearched: false,

    addRoute(route) {
      this.routes.push(route);
    },

    currentRoute() {
      const routes = this.routes;
      const currentRoute = routes.length < 1 ? null : routes[routes.length - 1];

      if (Ember.isEmpty(currentRoute)) {
        return;
      }

      if (this._isIndexRoute(currentRoute)) {
        return currentRoute.name;
      }

      return `${currentRoute.name}.${currentRoute.params.stage}`;
    },

    previousRoute() {
      const routes = this.routes;
      const previousRoute = routes.length < 2 ? null : routes[routes.length - 2];

      if (Ember.isEmpty(previousRoute)) {
        return;
      }

      if (this._isIndexRoute(previousRoute)) {
        return previousRoute.name;
      }

      return `${previousRoute.name}.${previousRoute.params.stage}`;
    },

    parseRoute(route) {
      if (this._isIndexRoute(route)) {
        return {
          route,
          stage: null
        };
      }

      const parts = route.split('.');
      return {
        route: ORDERS_STAGE,
        stage: parts[parts.length - 1]
      };
    },

    _isIndexRoute(route) {
      return route.name === ORDERS_INDEX;
    }

  });

  _exports.default = _default;
});