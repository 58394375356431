define("flaneur/pods/components/generic-modal/component", ["exports", "flaneur/util/default-to"], function (_exports, _defaultTo) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    classNames: ['generic-modal'],
    classNameBindings: ['active'],
    active: (0, _defaultTo.default)(false),
    actions: {
      dismiss() {
        this.set('active', false);
      }

    }
  });

  _exports.default = _default;
});