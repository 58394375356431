define("flaneur/util/validation/index", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = validate;

  /**
   * Convert validation object to validator object
   *
   * @param {object} model        - The model to run validations on
   * @param {object} validations  - Validation object. key: property to validate, value: validator/array of validators
   * @returns {validator[]}     - Array of validator objects
   */
  function processValidations(model, validations = {}) {
    return Object.entries(validations).reduce((prev, [key, value]) => {
      if (typeof value === 'function') {
        // If the value is a function, then a single validation was passed
        prev[key] = value(model, key);
      } else if (Array.isArray(value)) {
        // If the value is an array, then multiple validations were passed
        prev[key] = value.map(validation => validation(model, key));
      }

      return prev;
    }, {});
  }
  /**
   * Returns failed validator results
   *
   * @param validatorResults          - Array of executed validators
   * @returns {object}    - Array of failed validators
   */


  function getFailingValidators(validatorResults) {
    return Object.entries(validatorResults) // Some of the keys have multiple validators.
    // The successful validators need to be removed
    .reduce((prev, [key, value]) => {
      if (Array.isArray(value)) {
        const cleanedArray = value.filter(v => v !== true);
        prev.push([key, cleanedArray]);
      } else {
        prev.push([key, value]);
      }

      return prev;
    }, []) // Remove the successful validators
    .filter(([, value]) => {
      if (Array.isArray(value)) {
        // Handle multiple validations
        return value.length !== 0;
      } else {
        // Handle a single validation
        return value !== true;
      }
    }) // Convert back to an object
    .reduce((prev, [key, value]) => {
      prev[key] = value;
      return prev;
    }, {});
  }
  /**
   * 'validate' is responsible for:
   * - Runs validations
   * - Filters by failing validations
   * - Attach failing messages to the model provided
   *
   * @param model         - The model we want to validate upon
   * @param validations   - Validation object. key: property to validate, value: validator/array of validators
   * @returns {boolean}   - true if validations passed, false if validations failed
   */


  function validate(model, validations) {
    const validatorResults = processValidations(model, validations);
    const failingValidations = getFailingValidators(validatorResults);

    if (Object.getOwnPropertyNames(failingValidations).length === 0) {
      return true;
    } else {
      // Add error messages to model
      return failingValidations;
    }
  }
});