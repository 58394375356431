define("flaneur/helpers/date-format", ["exports", "date-fns"], function (_exports, _dateFns) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const DEFAULT_OUTPUT_FORMAT = 'PPpp';

  function parsedDate(date, inputFormat) {
    if (typeof date !== 'string') {
      return date;
    }

    if (inputFormat) {
      return (0, _dateFns.parse)(date, inputFormat, new Date());
    }

    return (0, _dateFns.parseISO)(date);
  }

  var _default = Ember.Helper.helper(function dateFormat([date, outputFormat, inputFormat]) {
    if (!date) {
      console.warn('you must pass a date to {{date-format}}');
      return;
    }

    const normalizedDate = parsedDate(date, inputFormat);
    const outFormat = outputFormat !== null && outputFormat !== void 0 ? outputFormat : DEFAULT_OUTPUT_FORMAT;
    return (0, _dateFns.format)(normalizedDate, outFormat);
  });

  _exports.default = _default;
});