define("flaneur/pods/brand/boutiques/boutique/curate/brands/brand/controller", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  class BrandBoutiquesBoutiqueCurateBrandsBrandController extends Ember.Controller {
    constructor(...args) {
      super(...args);

      _defineProperty(this, "queryParams", ['query', 'page', 'limit', 'category', 'preserveScrollPosition']);

      _defineProperty(this, "query", null);

      _defineProperty(this, "page", 1);

      _defineProperty(this, "limit", 24);

      _defineProperty(this, "category", null);

      _defineProperty(this, "preserveScrollPosition", false);
    }

  }

  _exports.default = BrandBoutiquesBoutiqueCurateBrandsBrandController;
});