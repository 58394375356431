define("flaneur/pods/brand/boutiques/boutique/statements/route", ["exports", "flaneur/models/statement", "flaneur/models/currency-statement", "flaneur/mixins/authorized-route", "flaneur/enums/retail-permission-type"], function (_exports, _statement, _currencyStatement, _authorizedRoute, _retailPermissionType) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_authorizedRoute.default, {
    brand: Ember.inject.service(),
    permission: _retailPermissionType.default.STATEMENTS,

    async model() {
      const boutiqueId = this.brand.currentBoutique._id;
      return Promise.all([_statement.default.findByBoutique(boutiqueId), _currencyStatement.default.findByBoutique(boutiqueId)]).then(([{
        statements
      }, {
        currencyStatements
      }]) => statements.concat(currencyStatements).sort((a, b) => b.created_at - a.created_at));
    }

  });

  _exports.default = _default;
});