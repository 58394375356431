define("flaneur/models/brand", ["exports", "flaneur/mixins/request", "flaneur/mixins/price", "flaneur/models/boutique", "flaneur/models/carrier-lane", "flaneur/models/stock-line", "flaneur/models/zone", "ember-cp-validations", "flaneur/enums/order-channel"], function (_exports, _request, _price, _boutique, _carrierLane, _stockLine, _zone, _emberCpValidations, _orderChannel) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const Validations = (0, _emberCpValidations.buildValidations)({
    shipping_small_item_price: [(0, _emberCpValidations.validator)('presence', {
      description: 'Small item shipping charge',
      presence: true
    }), (0, _emberCpValidations.validator)('number', {
      description: 'Small item shipping charge',
      integer: true,
      gt: 0
    })],
    shipping_medium_item_price: [(0, _emberCpValidations.validator)('presence', {
      description: 'Medium item shipping charge',
      presence: true
    }), (0, _emberCpValidations.validator)('number', {
      description: 'Medium item shipping charge',
      integer: true,
      gt: 0
    })]
  });
  const Brand = Ember.Object.extend(_request.default, _price.default, Validations, {
    // Instance methods
    async _fetchBoutiquesAndSelect(brandId) {
      const boutiqueOptions = {
        brand: brandId,
        archived: false,
        populate: 'registered_address_country,address_country,areas'
      };
      const boutiques = await _boutique.default.find(boutiqueOptions);

      if (Ember.isEmpty(boutiques)) {
        throw new Error('no boutiques');
      }

      await Promise.all([this.populateRegisteredAddressCountryZone(boutiques), this.mergeIsQuickShipEnabled(boutiques), this.mergeIsMadeEnabled(boutiques), this.mergeBoutiqueSyncIntegrations(brandId, boutiques)]);
      boutiques.setEach('selected', false);
      boutiques.set('firstObject.selected', true);
      this.set('boutiques', boutiques);
      return boutiques;
    },

    async mergeIsMadeEnabled(boutiques) {
      const [boutique] = boutiques;
      const {
        meta: {
          total
        }
      } = await _stockLine.default.find({
        boutique: boutique._id,
        limit: 1,
        [`channels.${_orderChannel.default.MADE_DOT_COM}.enabled`]: true,
        fields: ['_id'],
        skip_links: true
      });
      Ember.set(boutique, 'isMadeEnabled', total > 0);
    },

    async mergeIsQuickShipEnabled(boutiques) {
      var _boutique$address_cou;

      const [boutique] = boutiques;
      const sourceIsoCode = (_boutique$address_cou = boutique.address_country) === null || _boutique$address_cou === void 0 ? void 0 : _boutique$address_cou.iso_code_alpha_3;

      const hasBoutiqueFeature = (boutique, featureName) => {
        var _boutique$features;

        return boutique === null || boutique === void 0 ? void 0 : (_boutique$features = boutique.features) === null || _boutique$features === void 0 ? void 0 : _boutique$features.includes(featureName);
      };

      if (!hasBoutiqueFeature(boutique, 'quickship')) {
        return Ember.set(boutique, 'isQuickShipEnabled', false);
      }

      if (Ember.isEmpty(sourceIsoCode)) {
        return Ember.set(boutique, 'isQuickShipEnabled', false);
      }

      try {
        const {
          carrier_lanes: carrierLanes
        } = await _carrierLane.default.findByIsoCodesWithExtraShipping({
          sourceIsoCode
        });
        Ember.set(boutique, 'isQuickShipEnabled', carrierLanes.length > 0);
      } catch (error) {
        console.warn(error);
        Ember.set(boutique, 'isQuickShipEnabled', false);
      }
    },

    async populateRegisteredAddressCountryZone(boutiques) {
      var _boutique$registered_;

      const [boutique] = boutiques;
      const {
        zones: [zone]
      } = await _zone.default.find({
        _id: (_boutique$registered_ = boutique.registered_address_country) === null || _boutique$registered_ === void 0 ? void 0 : _boutique$registered_.zone
      });
      Ember.set(boutique, 'registeredAddressCountryZone', zone);
    },

    mergeBoutiqueSyncIntegrations(brandId, boutiques = []) {
      return this.request({
        method: 'GET',
        url: this.apiURL(`brands/${brandId}/syncintegrations`)
      }).then(({
        syncintegrations
      }) => {
        // Loop over every boutique, and check if there's a
        // sync integration set-up. Add a boolean on every integration.
        return boutiques.map(boutique => {
          // Find the sync integration for every boutique.
          let syncIntegration = null;

          if (syncintegrations) {
            syncIntegration = syncintegrations.find(integration => {
              return integration.settings.boutique === boutique._id;
            });
          } // If there's a sync integration set-up. Update the boutique


          Ember.set(boutique, 'isFeedBoutique', Boolean(syncIntegration && syncIntegration.status === 'active'));
          return boutique;
        });
      });
    },

    /**
     * Reload the data of this instance by id
     * @param  {String} id Brand _id
     * @return {Object}    Promise
     */
    findById(id) {
      var brand = this;
      return this.request({
        method: 'GET',
        url: this.apiURL(`brands/${id}`)
      }).then(res => {
        brand.setProperties(res.brands[0]);
        return brand;
      }).then(() => this._fetchBoutiquesAndSelect(id)).then(() => brand);
    },

    /**
     * Save brand
     * @return {Object} Promise
     */
    save() {
      var brand = this;
      return this.request({
        method: 'PUT',
        url: this.apiURL(`brands/${brand._id}`),
        data: JSON.stringify(brand.sanitize(['boutiques']))
      }).then(res => {
        brand.setProperties(res.brands[0]);
      });
    },

    // eslint-disable-next-line ember/require-computed-property-dependencies
    currentBoutique: Ember.computed('boutiques.@each.selected', function () {
      if (Ember.isEmpty(this.boutiques)) {
        return Ember.Object.create({
          name: 'not so fast!'
        });
      }

      const selectedBoutique = this.boutiques.findBy('selected');
      return _boutique.default.create(this.owner, selectedBoutique);
    })
  });
  Brand.reopenClass(_request.default, {
    find(data = {}) {
      return this.request({
        url: this.apiURL('brands'),
        data,
        method: 'GET'
      }).then(({
        brands
      }) => brands);
    }

  });
  Brand.reopen({
    /**
     * Make a copy of the object
     * @return {Object} Copy
     */
    copy() {
      return Brand.create(this);
    }

  });
  var _default = Brand;
  _exports.default = _default;
});