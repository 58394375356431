define("flaneur/pods/components/form-flows/shipment/customer-details/component", ["exports", "flaneur/util/address"], function (_exports, _address) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    analytics: Ember.inject.service(),
    googleMapsApi: Ember.inject.service(),
    tagName: 'section',
    classNames: ['customer-details'],
    maxInputChars: 185,
    autocompleteInput: null,
    subStep: Ember.computed.alias('options.subStep'),
    countries: Ember.computed.alias('options.countries'),
    countryName: Ember.computed.readOnly('options.boutique.address_country.name'),
    selectedDeliveryCountry: Ember.computed('countries', 'shipment.delivery_address.country', function () {
      const deliveryCountry = this.shipment.delivery_address.country;
      return this.countries.find(country => country.iso_code === deliveryCountry);
    }),
    _countyLabel: Ember.computed('shipment.delivery_address.country', function () {
      const {
        country
      } = this.shipment.delivery_address;
      return (0, _address.countyLabel)(country);
    }),
    _postcodeLabel: Ember.computed('shipment.delivery_address.country', function () {
      const {
        country
      } = this.shipment.delivery_address;
      return (0, _address.postcodeLabel)(country);
    }),
    _isPostcodeRequired: Ember.computed('shipment.delivery_address.country', function () {
      const {
        country
      } = this.shipment.delivery_address;
      return (0, _address.isPostcodeRequired)(country);
    }),

    didInsertElement() {
      this._super(...arguments);

      this.setupAutocomplete('input#address-line-1', {
        componentRestrictions: {
          country: this.selectedDeliveryCountry.iso_code
        }
      });
      document.getElementById('address-line-1').focus();
    },

    async setupAutocomplete(inputSelector, options) {
      const google = await Ember.get(this, 'googleMapsApi.google');
      const addressInput = document.querySelector(inputSelector);

      if (Ember.isEmpty(addressInput)) {
        return;
      }

      const autocompleteInput = new google.maps.places.Autocomplete(addressInput);
      autocompleteInput.setTypes(['address']);
      autocompleteInput.setFields(['name', 'address_components']);

      if (Ember.isPresent(options.componentRestrictions)) {
        autocompleteInput.setComponentRestrictions(options.componentRestrictions);
      }

      autocompleteInput.addListener('place_changed', () => {
        const place = autocompleteInput.getPlace();
        const addressComponents = this.getParsedAddressComponents(place.address_components);
        const city = Ember.isPresent(addressComponents.postalTown) ? addressComponents.postalTown : addressComponents.locality;
        this.onPlaceChanged({
          address_line_1: place.name,
          postcode: addressComponents.postalCode,
          city: city
        });
      });
      this.set('autocompleteInput', autocompleteInput);
      return autocompleteInput;
    },

    getParsedAddressComponents(components = []) {
      return components.reduce((address, {
        long_name,
        types
      }) => {
        const components = types.reduce((components, type) => {
          const component = {};
          component[Ember.String.camelize(type)] = long_name;
          return Ember.assign(components, component);
        }, {});
        return Ember.assign(address, components);
      }, {});
    },

    onPlaceChanged({
      address_line_1,
      postcode,
      city
    }) {
      this.setProperties({
        'shipment.delivery_address.address_line_1': address_line_1,
        'shipment.delivery_address.postcode': postcode,
        'shipment.delivery_address.city': city
      });
      this.analytics.gtm({
        action: 'quick_ship_select_google_address'
      });
    },

    actions: {
      noop() {
        return;
      },

      prevStep() {
        return this.prevStep();
      }

    }
  });

  _exports.default = _default;
});