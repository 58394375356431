define("flaneur/pods/components/pages/products/bulk/upload/amend-step/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "f0GsA0yz",
    "block": "{\"symbols\":[\"product\",\"@onBack\",\"@onCompleteStep\"],\"statements\":[[10,\"div\"],[14,0,\"subheading\"],[12],[2,\"\\n  \"],[10,\"h1\"],[12],[2,\"2. Resolve conflicts\"],[13],[2,\"\\n  \"],[10,\"h2\"],[12],[2,\"We found some conflicts in your file. Please resolve these below.\"],[13],[2,\"\\n\"],[13],[2,\"\\n\\n\"],[10,\"div\"],[14,0,\"table\"],[12],[2,\"\\n  \"],[10,\"div\"],[14,0,\"table__header table__header--amend\"],[12],[2,\"\\n    \"],[10,\"div\"],[14,0,\"table__column\"],[12],[2,\"Product name\"],[13],[2,\"\\n    \"],[10,\"div\"],[14,0,\"table__column\"],[12],[2,\"Brand\"],[13],[2,\"\\n    \"],[10,\"div\"],[14,0,\"table__column\"],[12],[2,\"Price\"],[13],[2,\"\\n    \"],[10,\"div\"],[14,0,\"table__column\"],[12],[2,\"Variants\"],[13],[2,\"\\n    \"],[10,\"div\"],[14,0,\"table__column\"],[12],[2,\"SKUs\"],[13],[2,\"\\n    \"],[10,\"div\"],[14,0,\"table__column\"],[12],[2,\"Conflicts\"],[13],[2,\"\\n    \"],[10,\"div\"],[14,0,\"table__column\"],[12],[2,\"Actions\"],[13],[2,\"\\n  \"],[13],[2,\"\\n\\n\"],[6,[37,1],[[30,[36,0],[[30,[36,0],[[32,0,[\"productsWithErrors\"]]],null]],null]],null,[[\"default\"],[{\"statements\":[[2,\"    \"],[8,\"pages/products/bulk/upload/amend-step/error-row\",[],[[\"@product\",\"@checkStepCompleted\"],[[32,1],[32,0,[\"checkStepCompleted\"]]]],null],[2,\"\\n\"]],\"parameters\":[1]}]]],[13],[2,\"\\n\\n\"],[10,\"div\"],[14,0,\"actions\"],[12],[2,\"\\n  \"],[8,\"button\",[],[[\"@theme\",\"@onClick\"],[\"secondary\",[32,2]]],[[\"default\"],[{\"statements\":[[2,\"\\n    Back to upload\\n  \"]],\"parameters\":[]}]]],[2,\"\\n  \"],[8,\"button\",[],[[\"@disabled\",\"@onClick\"],[[30,[36,2],[[32,0,[\"isAmendStepComplete\"]]],null],[32,3]]],[[\"default\"],[{\"statements\":[[2,\"\\n    Continue\\n  \"]],\"parameters\":[]}]]],[2,\"\\n\"],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"-track-array\",\"each\",\"not\"]}",
    "moduleName": "flaneur/pods/components/pages/products/bulk/upload/amend-step/template.hbs"
  });

  _exports.default = _default;
});