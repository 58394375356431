define("flaneur/pods/components/pages/packaging/index/component", ["exports", "@glimmer/component", "ember-component-css/pod-names"], function (_exports, _component, _podNames) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _class, _descriptor;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  let PagesPackagingIndexComponent = (_dec = Ember.inject.service, (_class = class PagesPackagingIndexComponent extends _component.default {
    constructor(...args) {
      super(...args);

      _initializerDefineProperty(this, "brand", _descriptor, this);

      _defineProperty(this, "imagePrefix", 'https://trouva-media.imgix.net/rphc/packaging');

      _defineProperty(this, "categories", [{
        name: 'Boxes',
        slug: 'boxes',
        thumbnail: '/category/boxes.png'
      }, {
        name: 'Protective packaging',
        slug: 'protective-packaging',
        thumbnail: '/category/protective.png'
      }, {
        name: 'Mailing bags & envelopes',
        slug: 'mailing-bags-and-envelopes',
        thumbnail: '/category/envelopes.png'
      }, {
        name: 'Tapes',
        slug: 'tapes',
        thumbnail: '/category/tapes.png'
      }, {
        name: 'Trouva branded items',
        slug: 'trouva-branded-items',
        thumbnail: '/category/trouva.png'
      }]);

      _defineProperty(this, "faqItems", [{
        label: 'How do I place an order?',
        content: '1. Browse our many product categories to find the particular product type you need\n2. Each product type has several varieties. Find the variety you need in the table, select the quantity and click “Add” to add to your basket. (Note - Most products are sold in packs)\n3. Once you’ve added the products to your basket, click the bag icon in the top right corner to see your basket\n4. Inside your basket you can amend the products and quantities\n5. We have pre-populated your delivery and billing information, but you can amend these in your settings if you need to. Alternatively, add any special instructions in the box provided\n6. Enter your card details and any discount codes\n7. Click “Submit order”\n\n\nWe will email you a confirmation of payment as well an invoice for the order for your records.'
      }, {
        label: 'I can’t find the product I’m looking for, can I request new options?',
        content: 'If you cannot find a particular product you need, you can request an item or product SKU by clicking the arrow in the bottom right corner. We will do our best to add it to our catalogue as quickly as possible.'
      }, {
        label: 'How do I pay?',
        content: 'You can pay for your order using a credit card. Enter your card details at the basket stage.\n\nWe will take payment immediately and send you a payment receipt and invoice for your records.'
      }, {
        label: 'Is there a minimum spend?',
        content: 'There is no minimum spend. You can order as much or as little as you like.\n\nThere are only minimum spend limits applied to some discounts like free shipping for example.'
      }, {
        label: 'How long does delivery take and is there a fee?',
        content: 'Deliveries will usually arrive within 2-3 business days.\n\nThe delivery fee is dependent on the country you are in, this fee is shown on your order summary before you place an order.\n\n(We also have a free shipping promotion if you have met the minimum spend requirements)'
      }, {
        label: 'What happens if there’s a problem with my order?',
        content: 'If you have any questions you can contact us by using the “Help” widget in the bottom right corner of the screen.'
      }, {
        label: 'How do I order Trouva tape and labels?',
        content: 'We have a special category for all Trouva branded items. From there you can add these items to your order as you would any other products.'
      }]);
    }

    get boutique() {
      return this.brand.currentBoutique;
    }

    get styleNamespace() {
      return _podNames.default['pages/packaging/index'];
    }

    get featuredProducts() {
      if (this.args.products) {
        return this.args.products.slice(0, 4);
      }

      return [];
    }

  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "brand", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  })), _class));
  _exports.default = PagesPackagingIndexComponent;
});