define("flaneur/utils/pub-sub", ["exports", "pusher-js"], function (_exports, _pusherJs) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const _excluded = ["key"];

  function _objectWithoutProperties(source, excluded) { if (source == null) return {}; var target = _objectWithoutPropertiesLoose(source, excluded); var key, i; if (Object.getOwnPropertySymbols) { var sourceSymbolKeys = Object.getOwnPropertySymbols(source); for (i = 0; i < sourceSymbolKeys.length; i++) { key = sourceSymbolKeys[i]; if (excluded.indexOf(key) >= 0) continue; if (!Object.prototype.propertyIsEnumerable.call(source, key)) continue; target[key] = source[key]; } } return target; }

  function _objectWithoutPropertiesLoose(source, excluded) { if (source == null) return {}; var target = {}; var sourceKeys = Object.keys(source); var key, i; for (i = 0; i < sourceKeys.length; i++) { key = sourceKeys[i]; if (excluded.indexOf(key) >= 0) continue; target[key] = source[key]; } return target; }

  class PubSub {
    constructor(config) {
      const {
        key
      } = config,
            options = _objectWithoutProperties(config, _excluded);

      this.socket = new _pusherJs.default(key, options);
      this.channels = {};
    }

    findOrSubscribeChannel(channelName) {
      const channelExists = Ember.isPresent(this.channels[channelName]);

      if (channelExists) {
        return this.channels[channelName];
      }

      const channel = this.socket.subscribe(channelName);
      this.channels[channelName] = channel;
      return channel;
    }

    subscribe(channelName, eventName, handler) {
      const channel = this.findOrSubscribeChannel(channelName);
      channel.bind(eventName, handler);
      return channel;
    }

  }

  _exports.default = PubSub;
});