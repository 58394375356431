define("flaneur/pods/components/pages/products/bulk/upload/amend-step/brand-amend-modal/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _class;

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  let BrandAmendModal = (_dec = Ember.computed('previousManufacturer._id', 'product.standard_manufacturer._id'), _dec2 = Ember._action, _dec3 = Ember._action, _dec4 = Ember._action, (_class = class BrandAmendModal extends Ember.Component {
    init() {
      super.init(...arguments);
      Ember.set(this, 'previousManufacturer', this.product.standard_manufacturer);
    }

    get isBrandFixed() {
      if (Ember.isEmpty(this.product.standard_manufacturer)) {
        return false;
      }

      if (Ember.isEmpty(this.previousManufacturer) && Ember.isPresent(this.product.standard_manufacturer)) {
        return true;
      }

      return this.product.standard_manufacturer._id !== this.previousManufacturer._id;
    }

    selectNewBrand() {
      this.selectBrand(this.product);
    }

    onChangeManufacturer(manufacturer) {
      this.set('product.standard_manufacturer', manufacturer);
      this.set('product.unbranded', false);
    }

    close() {
      this.set('product.standard_manufacturer', this.previousManufacturer);
      this.closeModal();
    }

  }, (_applyDecoratedDescriptor(_class.prototype, "isBrandFixed", [_dec], Object.getOwnPropertyDescriptor(_class.prototype, "isBrandFixed"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "selectNewBrand", [_dec2], Object.getOwnPropertyDescriptor(_class.prototype, "selectNewBrand"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "onChangeManufacturer", [_dec3], Object.getOwnPropertyDescriptor(_class.prototype, "onChangeManufacturer"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "close", [_dec4], Object.getOwnPropertyDescriptor(_class.prototype, "close"), _class.prototype)), _class));
  _exports.default = BrandAmendModal;
});