define("flaneur/pods/shopify-integration/route", ["exports", "flaneur/mixins/request", "flaneur/models/boutique", "flaneur/models/brand", "flaneur/config/environment"], function (_exports, _request, _boutique, _brand, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_request.default, {
    session: Ember.inject.service(),

    async getUserBrand(user) {
      if (!user || !user._id) {
        return {};
      }

      const brands = await _brand.default.find({
        match: {
          users: {
            user: user._id
          }
        }
      });
      user.brand = Ember.get(brands, '0._id');
      return brands[0] || {};
    },

    getUserBoutiques(user) {
      if (!user || !user.brand) return [];
      return _boutique.default.find({
        brand: user.brand
      });
    },

    async model({
      shop,
      code
    }) {
      const session = this.session; // Check if the user is logged in

      await session.isAuthenticated().catch(() => {}); // Get their logged in status

      const {
        user,
        isLoggedIn
      } = this.session;
      const brand = await this.getUserBrand(user);
      const boutiques = await this.getUserBoutiques(user);
      const boutique = boutiques[0];
      user.brand = brand._id;
      return {
        isLoggedIn,
        boutique,
        user,
        loading: false,
        shop,
        code
      };
    },

    actions: {
      async login(email, password) {
        // Authenticate and then rerun the model
        const session = this.session;
        await session.authenticate(email, password);
        this.refresh();
      },

      async logout() {
        const session = this.session;
        await session.logout();
        this.refresh();
      },

      searchBoutiques(query) {
        return _boutique.default.find({
          name: {
            $regex: `^${query}`,
            $options: 'i'
          }
        });
      },

      selectBoutiqueAsAdmin(boutique) {
        this.controller.setProperties({
          searchBoutiqueNameAsAdmin: boutique.name,
          selectedBoutiqueAsAdmin: boutique
        });
      },

      selectBoutique(boutique, consented) {
        this.set('currentModel.consentError', !consented);
        if (!consented) return;
        const shop = this.get('currentModel.shop');
        const code = this.get('currentModel.code');
        const url = _environment.default.shopifyServiceURL;
        const queryParams = `?shop=${shop}&code=${code}&brandId=${boutique.brand}&boutiqueId=${boutique._id}`;
        window.location = `${url}/oauth/register${queryParams}`;
      }

    }
  });

  _exports.default = _default;
});