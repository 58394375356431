define("flaneur/pods/brand/boutiques/boutique/curate/orders/route", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  class BrandBoutiquesBoutiqueCurateOrdersRoute extends Ember.Route {}

  _exports.default = BrandBoutiquesBoutiqueCurateOrdersRoute;
});