define("flaneur/pods/components/resource-search-products-empty/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "FEPiY8cp",
    "block": "{\"symbols\":[],\"statements\":[[2,\"No products found for \\\"\"],[1,[34,0]],[2,\"\\\"\\n\"]],\"hasEval\":false,\"upvars\":[\"value\"]}",
    "moduleName": "flaneur/pods/components/resource-search-products-empty/template.hbs"
  });

  _exports.default = _default;
});