define("flaneur/enums/packaging-product-variant-stock-status", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    ALL: 'all',
    OUT_OF_STOCK: 'out-of-stock',
    IN_STOCK: 'in-stock'
  };
  _exports.default = _default;
});