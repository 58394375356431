define("flaneur/pods/brand/boutiques/boutique/packaging/products/index/controller", ["exports", "flaneur/enums/packaging-product-variant-stock-status"], function (_exports, _packagingProductVariantStockStatus) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  class PackagingProductsIndexController extends Ember.Controller {
    constructor(...args) {
      super(...args);

      _defineProperty(this, "queryParams", ['category', 'page', 'limit', 'stock_status']);

      _defineProperty(this, "category", null);

      _defineProperty(this, "page", 1);

      _defineProperty(this, "limit", 24);

      _defineProperty(this, "stock_status", _packagingProductVariantStockStatus.default.IN_STOCK);
    }

  }

  _exports.default = PackagingProductsIndexController;
});