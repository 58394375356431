define("flaneur/pods/components/resource-search/component", ["exports", "flaneur/util/default-to", "ember-concurrency"], function (_exports, _defaultTo, _emberConcurrency) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  const NOOP = function () {};

  var _default = Ember.Component.extend({
    value: (0, _defaultTo.default)(''),
    isCreateEnabled: (0, _defaultTo.default)(false),
    results: (0, _defaultTo.default)(null),
    // format: { label: "", value: {} }
    onSearch: (0, _defaultTo.default)(NOOP),
    onSelect: (0, _defaultTo.default)(NOOP),
    onCreate: (0, _defaultTo.default)(NOOP),
    inputClass: (0, _defaultTo.default)('resource-search__input'),
    classNames: ['resource-search'],
    resultsComponent: (0, _defaultTo.default)('resource-search/results'),
    resultComponent: (0, _defaultTo.default)('resource-search/result'),
    createComponent: (0, _defaultTo.default)('resource-search/create'),
    emptyComponent: (0, _defaultTo.default)('resource-search/empty'),

    init({
      onSearch,
      onSelect
    }) {
      this._super(...arguments);

      (false && !(typeof onSearch === 'function') && Ember.assert('"onSearch" property must be a function', typeof onSearch === 'function'));
      (false && !(typeof onSelect === 'function') && Ember.assert('"onSelect" property must be a function', typeof onSelect === 'function'));
      const props = {
        results: []
      };
      this.setProperties(props);
    },

    clearResults() {
      this.set('results', []);
    },

    searchTask: (0, _emberConcurrency.task)(function* (onSearch, value) {
      const isCreateEnabled = this.isCreateEnabled;
      this.set('noResults', false);
      yield (0, _emberConcurrency.timeout)(200);
      this.set('_loading', true);
      const results = yield onSearch(value);
      const noResults = results.length === 0 && !isCreateEnabled;
      this.setProperties({
        results,
        noResults,
        _loading: false
      });
    }).restartable(),
    actions: {
      _onInput(event) {
        const onSearch = this.onSearch;
        const value = Ember.get(event, 'target.value');
        this.set('value', value);

        if (value) {
          this.searchTask.perform(onSearch, value);
        } else {
          this.set('noResults', false);
          this.clearResults();
        }
      },

      _onSelect(result) {
        const onSelect = this.onSelect;
        const selectResult = onSelect(result);

        if (selectResult && selectResult.then) {
          return selectResult.finally(() => {
            this.clearResults();
          });
        } else {
          this.clearResults();
        }
      },

      _onCreate(value) {
        const onCreate = this.onCreate;
        const createResult = onCreate(value);

        if (createResult && createResult.then) {
          return createResult.finally(() => {
            this.clearResults();
          });
        } else {
          this.clearResults();
        }
      }

    }
  });

  _exports.default = _default;
});