define("flaneur/models/frame/index", ["exports", "fetch", "query-string", "flaneur/config/environment", "lodash.clonedeep"], function (_exports, _fetch, _queryString, _environment, _lodash) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const _excluded = ["standard_tags"];

  var _dec, _dec2, _class, _descriptor;

  function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); enumerableOnly && (symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols); } return keys; }

  function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = null != arguments[i] ? arguments[i] : {}; i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { _defineProperty(target, key, source[key]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } return target; }

  function _objectWithoutProperties(source, excluded) { if (source == null) return {}; var target = _objectWithoutPropertiesLoose(source, excluded); var key, i; if (Object.getOwnPropertySymbols) { var sourceSymbolKeys = Object.getOwnPropertySymbols(source); for (i = 0; i < sourceSymbolKeys.length; i++) { key = sourceSymbolKeys[i]; if (excluded.indexOf(key) >= 0) continue; if (!Object.prototype.propertyIsEnumerable.call(source, key)) continue; target[key] = source[key]; } } return target; }

  function _objectWithoutPropertiesLoose(source, excluded) { if (source == null) return {}; var target = {}; var sourceKeys = Object.keys(source); var key, i; for (i = 0; i < sourceKeys.length; i++) { key = sourceKeys[i]; if (excluded.indexOf(key) >= 0) continue; target[key] = source[key]; } return target; }

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  const getDefaultPageLayout = () => [{
    component: 'hero',
    order: 0,
    staticServerProps: {
      name: 'Name'
    }
  }, {
    component: 'tickertapeTop',
    order: 1,
    staticServerProps: {
      content: []
    }
  }, {
    component: 'gridContent',
    order: 2,
    staticServerProps: {
      content: [{
        content_type: 'product',
        product_id: '',
        order: 1
      }, {
        content_type: 'product',
        product_id: '',
        order: 2
      }, {
        content_type: 'product',
        product_id: '',
        order: 3
      }, {
        content_type: 'product',
        product_id: '',
        order: 4
      }, {
        content_type: 'product',
        product_id: '',
        order: 5
      }, {
        content_type: 'lifestyle',
        url: '',
        order: 6
      }, {
        content_type: 'lifestyle',
        url: '',
        order: 7
      }, {
        content_type: 'product',
        product_id: '',
        order: 8
      }, {
        content_type: 'product',
        product_id: '',
        order: 9
      }],
      numberOfItems: 9
    }
  }, {
    component: 'oneBigTwoSmallContent',
    order: 3,
    staticServerProps: {
      bigImage: {
        url: ''
      },
      products: [{
        content_type: 'product',
        product_id: '',
        order: 1
      }, {
        content_type: 'product',
        product_id: '',
        order: 2
      }]
    }
  }, {
    component: 'tickertapeBottom',
    order: 4,
    staticServerProps: {
      content: []
    }
  }, {
    component: 'moreCuratedPicks',
    order: 5,
    staticServerProps: {
      curatedProducts: Array.from({
        length: 6
      }, (_, i) => ({
        product_id: '',
        order: i
      }))
    }
  }];

  let Frame = (_dec = Ember._tracked, _dec2 = Ember._action, (_class = class Frame {
    constructor(frame = {}) {
      _initializerDefineProperty(this, "page_layout", _descriptor, this);

      const {
        _id,
        slug,
        boutique,
        published,
        archived,
        name,
        standard_tags = [],
        music,
        pageLayout = getDefaultPageLayout()
      } = frame;
      this._id = _id;
      this.slug = slug;
      this.name = name;
      this.published = published;
      this.standard_tags = standard_tags;
      this.music = music;
      this.boutique = boutique;
      this.page_layout = pageLayout;
      this.archived = archived;
    }

    get hero() {
      return this.page_layout.find(({
        component
      }) => component === 'hero');
    }

    get gridContent() {
      return this.page_layout.find(({
        component
      }) => component === 'gridContent');
    }

    get oneBigTwoSmallContent() {
      return this.page_layout.find(({
        component
      }) => component === 'oneBigTwoSmallContent');
    }

    get moreCuratedPicks() {
      return this.page_layout.find(({
        component
      }) => component === 'moreCuratedPicks');
    }

    removeFieldsFromGridContent() {
      this.gridContent.staticServerProps.content.forEach(content => {
        if (content.content_type === 'product') {
          content.url = '';
        }

        if (content.content_type === 'lifestyle') {
          content.product_id = '';
        }
      });
    }

    async createOrUpdate() {
      this.removeFieldsFromGridContent();

      if (this.slug) {
        return this.update();
      }

      const url = `${_environment.default.apiURL}/api/v2/frames`;
      const res = await (0, _fetch.default)(url, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json; charset=UTF-8'
        },
        credentials: 'include',
        cache: false,
        body: JSON.stringify({
          name: this.name,
          pageLayout: this.page_layout,
          standard_tags: this.standard_tags,
          music: this.music,
          boutique: this.boutique
        })
      });

      if (!res.ok) {
        throw new Error('We could not create your frame due to fields missing.');
      }

      const {
        frames = []
      } = await res.json();
      const [frame] = frames;
      /* Create and update do not allow "populate" */

      const {
        standard_tags
      } = frame,
            restFrame = _objectWithoutProperties(frame, _excluded);

      Ember.setProperties(this, restFrame);
      return this;
    }

    async update() {
      const url = `${_environment.default.apiURL}/api/v2/frames/${this.slug}`;
      const res = await (0, _fetch.default)(url, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json; charset=UTF-8'
        },
        credentials: 'include',
        cache: false,
        body: JSON.stringify({
          pageLayout: this.page_layout,
          standard_tags: this.standard_tags,
          music: this.music
        })
      });

      if (!res.ok) {
        throw new Error('We could not update your frame due to fields missing.');
      }

      const {
        frames = []
      } = await res.json();
      Ember.setProperties(this, frames[0]);
      return this;
    }

    async publish() {
      const url = `${_environment.default.apiURL}/api/v2/frames/${this.slug}/publish`;
      const res = await (0, _fetch.default)(url, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json; charset=UTF-8'
        },
        credentials: 'include',
        cache: false,
        body: JSON.stringify({})
      });

      if (!res.ok) {
        throw new Error(`error publishing frame "${this.slug}"`);
      }

      const {
        frames = []
      } = await res.json();
      Ember.setProperties(this, frames[0]);
      return this;
    }

    async archive() {
      const url = `${_environment.default.apiURL}/api/v2/frames/${this.slug}/archive`;
      const res = await (0, _fetch.default)(url, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json; charset=UTF-8'
        },
        credentials: 'include',
        cache: false
      });

      if (!res.ok) {
        throw new Error(`error archiving frame "${this.slug}"`);
      }

      const {
        frames = []
      } = await res.json();
      Ember.setProperties(this, frames[0]);
      return this;
    }

    clone() {
      return new Frame({
        name: '',
        standard_tags: (0, _lodash.default)(this.standard_tags),
        boutique: this.boutique,
        pageLayout: (0, _lodash.default)(this.page_layout)
      });
    }

    static async find(query = {}) {
      const queryParams = _queryString.default.stringify(_objectSpread(_objectSpread({}, query), {}, {
        populate: 'standard_tags'
      }));

      const url = `${_environment.default.apiURL}/api/v2/frames?${queryParams}`;

      try {
        const res = await (0, _fetch.default)(url, {
          method: 'GET',
          credentials: 'include',
          cache: false
        });
        const {
          frames = []
        } = await res.json();
        return {
          frames: frames.map(frame => new Frame(frame))
        };
      } catch (err) {
        return {
          frames: []
        };
      }
    }

  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "page_layout", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "archive", [_dec2], Object.getOwnPropertyDescriptor(_class.prototype, "archive"), _class.prototype)), _class));
  _exports.default = Frame;
});