define("flaneur/utils/cut-off-time", ["exports", "date-fns", "date-fns-tz"], function (_exports, _dateFns, _dateFnsTz) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.getStateFromCutOffTime = void 0;
  const IMMEDIATELY = 'immediately';
  const TODAY = 'today';
  const NEXT_OPEN_DAY = 'nextOpenDay';

  const getStateFromCutOffTime = (cutOffTime, timezone) => {
    const zonedNow = (0, _dateFnsTz.zonedTimeToUtc)(new Date(), timezone);
    const cutOffTimeDate = (0, _dateFns.parseISO)(cutOffTime);

    if ((0, _dateFns.isAfter)(zonedNow, cutOffTimeDate)) {
      return IMMEDIATELY;
    }

    if ((0, _dateFns.isSameDay)(cutOffTimeDate, zonedNow)) {
      return TODAY;
    }

    return NEXT_OPEN_DAY;
  };

  _exports.getStateFromCutOffTime = getStateFromCutOffTime;
});