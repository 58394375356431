define("flaneur/models/boutique-restock-information/validations", ["exports", "flaneur/util/validation/validations"], function (_exports, _validations) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.reasonOtherValidations = _exports.restockingValidations = void 0;
  const restockingValidations = {
    quantity: [(0, _validations.validatePresence)({
      presence: true,
      message: 'Add a quantity'
    }), (0, _validations.validateNumber)({
      integer: true,
      gt: 0,
      allowString: true,
      message: 'Quantity must be greater than 0'
    })],
    lead_date: [(0, _validations.validatePresence)({
      presence: true,
      message: 'Please use a valid date'
    }), (0, _validations.validateDate)({
      onOrAfter: 'now',
      message: 'Please enter a future date'
    })]
  };
  _exports.restockingValidations = restockingValidations;
  const reasonOtherValidations = {
    'reason.notes': [(0, _validations.validatePresence)({
      presence: true,
      message: 'Please enter a reason'
    })]
  };
  _exports.reasonOtherValidations = reasonOtherValidations;
});