define("flaneur/pods/components/oi-alternative/component", ["exports", "jquery", "big.js", "flaneur/models/zendesk", "flaneur/util/escape-regex", "flaneur/models/product"], function (_exports, _jquery, _big, _zendesk, _escapeRegex, _product) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    analytics: Ember.inject.service(),
    brand: Ember.inject.service(),
    notify: Ember.inject.service(),
    // Public API
    reservation: null,
    showError: false,
    // Private API
    classNames: ['out-of-stock'],
    query: '',
    isLoadingProductSearch: false,
    submitting: false,
    submitted: false,
    // NOTE: non computed variables are shared among component instances
    productSearch: Ember.computed(function () {
      return [];
    }),
    selectedAlternatives: Ember.computed(function () {
      return [];
    }),
    // extend products with component specific attributes
    productSearchExtended: Ember.computed('productSearch.@each.alternative_name', 'selectedAlternatives.@each.alternative_name', function () {
      return this.productSearch.map(product => {
        // NOTE: name is unique, but _id is not because we duplicate product for each size
        const alternative = this.selectedAlternatives.find(p => p.alternative_name === product.alternative_name);
        product.set('selected', Ember.isPresent(alternative));
        return product;
      });
    }),

    didInsertElement() {
      this._super(...arguments);

      this.search();
      (0, _jquery.default)('html').addClass('hide-scroll-bar');
    },

    didDestroyElement() {
      this._super(...arguments);

      (0, _jquery.default)('html').removeClass('hide-scroll-bar');
    },

    search(fallback = false) {
      this.set('isLoadingProductSearch', true);
      const query = {
        brand: this.brand._id,
        limit: 20,
        page: 1,
        archived: false
      };

      if (Ember.isPresent(this.query)) {
        query.name = {
          $regex: `${(0, _escapeRegex.default)(this.query)}`,
          $options: 'i'
        };
      } else if (!fallback) {
        query.categories = {
          $in: this.reservation.product.categories
        };
      }

      return _product.default.find(query).then(({
        products = []
      }) => {
        this.set('isLoadingProductSearch', false);
        /*
         * If there is no search query and no product was in stock for the given category,
         * do another search including all categories
         */

        if (!fallback && products.filter(product => product.isInStock).length === 0) {
          return this.search(true);
        }

        this.set('productSearch', this.splitByStockLines(products));
      });
    },

    splitByStockLines(products) {
      return products.filter(product => product._id !== this.reservation.product && product._id !== this.reservation.product._id).reduce((results, product) => {
        // create an independent product for each stock_line
        product.stock_lines.forEach(stockLine => {
          // clone
          const copy = _product.default.create(product); // set unique name


          copy.set('alternative_name', stockLine.size && stockLine.size.label ? `${copy.name} - ${stockLine.size.label}` : copy.name);
          copy.set('selected_size', stockLine);
          results.push(copy);
        });
        return results;
      }, []);
    },

    createZendeskTicket(reservation, alternatives, productWithIssueMessage) {
      const channelTag = _zendesk.default.getChannelTag(reservation.order.channel);

      return _zendesk.default.createTicket({
        requester: {
          name: this.get('brand.currentBoutique.name'),
          email: this.get('brand.currentBoutique.support_email') || this.get('brand.currentBoutique.order_email') || this.get('session.user.email')
        },
        subject: `#${reservation.order.ordernumber} - Out of stock ${productWithIssueMessage && productWithIssueMessage.length ? '- Info' : ''} ${alternatives.length ? '- Alternatives recommended' : ''}`,
        tags: [`${reservation.order.ordernumber}:${this.get('brand.currentBoutique._id')}`, `${this.get('brand.currentBoutique.address_country.iso_code')}_boutique`, channelTag],
        comment: {
          body: `
                Order number: #${reservation.order.ordernumber}
                https://backalley.trouva.com/orders/${reservation.order._id}

                ----------
                Out of stock product
                ${reservation.product.name} ${reservation.selected_size ? `\nSelected size: ${reservation.selected_size}` : ''}
                £${(0, _big.default)(reservation.price).div(100).toFixed(2)}
                Quantity: ${reservation.quantity}
                Product ID: ${reservation.product._id}
                Boutique name: ${this.get('brand.currentBoutique.name')}

                ----------
                Alternatives recommended by ${this.get('brand.currentBoutique.name')}
                ${alternatives.map(alternative => `
                    ${alternative.name}${alternative.size ? `
                        Variation: ${alternative.size}` : ''}
                    £${alternative.price}
                    Product ID: ${alternative._id}
                    ${alternative.link}
                `).join('')}

                ----------
                Message from ${this.get('brand.currentBoutique.name')}

                ${productWithIssueMessage && productWithIssueMessage.length ? productWithIssueMessage : ''}
                `
        }
      });
    },

    actions: {
      updateQuery(q) {
        this.set('query', q);
        return this.search();
      },

      selectAlternative(product) {
        const alternatives = this.selectedAlternatives;
        alternatives.find(({
          alternative_name
        }) => alternative_name === product.alternative_name) || !product.isInStock ? alternatives.removeObject(product) : alternatives.insertAt(0, product);
      },

      deselect(product) {
        this.selectedAlternatives.removeObject(product);
      },

      productWithIssue(reservation, productWithIssueMessage, selectedAlternatives = []) {
        if (Ember.isEmpty(productWithIssueMessage)) {
          return this.set('showError', true);
        }

        this.set('showError', false);
        this.set('submitting', true);
        reservation.set('status', 'fail');
        return reservation.put().then(() => {
          const alternatives = selectedAlternatives.map(product => ({
            name: product.get('name'),
            size: product.get('selected_size.size.label'),
            price: product.get('currentNestedBoutique.sales') ? (0, _big.default)(product.get('currentNestedBoutique.sales_price')).div(100).toFixed(2) : (0, _big.default)(product.get('price')).div(100).toFixed(2),
            _id: product.get('_id'),
            link: `https://www.trouva.com/boutique/${this.get('brand.currentBoutique.slug')}/${product.get('slug')}`
          }));
          this.analytics.gtm({
            action: 'clicked_mark_as_other_issue',
            label: reservation.order._id
          });
          return this.createZendeskTicket(reservation, alternatives, productWithIssueMessage);
        }).then(() => this.set('submitted', true)).catch(() => this.set('error', false)).finally(() => this.set('submitting', false));
      }

    }
  });

  _exports.default = _default;
});