define("flaneur/pods/components/nav-tabs/component", ["exports", "flaneur/util/default-to"], function (_exports, _defaultTo) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
   * @deprecated
   */
  var _default = Ember.Component.extend({
    activeTab: Ember.computed('tabs', 'activeIndex', function () {
      return this.tabs.objectAt(this.activeIndex);
    }),
    activeIndex: 0,
    tabs: (0, _defaultTo.default)([]),
    actions: {
      changeTab(index) {
        this.set('activeIndex', index);
      }

    }
  });

  _exports.default = _default;
});