define("flaneur/breakpoints", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    small: '(max-width: 576px)',
    // Small devices (landscape phones, until 576px)
    medium: '(max-width: 768px)',
    // Medium devices (tablets, until 768px)
    large: '(max-width: 992px)',
    // Large devices (desktops, until 992px)
    xlarge: '(max-width: 1200px)' // Extra large devices (large desktops, until 1200px)

  };
  _exports.default = _default;
});