define("flaneur/pods/components/form-status/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    classNames: ['form-status'],
    // Public API
    label: 'Status',
    onLabel: 'Yes',
    offLabel: 'No',

    action() {},

    actions: {
      updateSwitch(status) {
        return this.action(this, status, this.label);
      }

    }
  });

  _exports.default = _default;
});