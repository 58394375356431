define("flaneur/pods/brand/boutiques/boutique/packaging/products/index/route", ["exports", "flaneur/models/packaging/product", "flaneur/models/packaging/category", "flaneur/enums/packaging-product-variant-stock-status"], function (_exports, _product, _category, _packagingProductVariantStockStatus) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _class, _descriptor;

  function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); enumerableOnly && (symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols); } return keys; }

  function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = null != arguments[i] ? arguments[i] : {}; i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { _defineProperty(target, key, source[key]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } return target; }

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  let BrandBoutiquesBoutiquePackagingProductsIndexRoute = (_dec = Ember.inject.service, (_class = class BrandBoutiquesBoutiquePackagingProductsIndexRoute extends Ember.Route {
    constructor(...args) {
      super(...args);

      _initializerDefineProperty(this, "brand", _descriptor, this);

      _defineProperty(this, "queryParams", {
        category: {
          refreshModel: true
        },
        page: {
          refreshModel: true
        },
        limit: {
          refreshModel: true
        },
        stock_status: {
          refreshModel: true
        }
      });
    }

    async model(queryParams) {
      const {
        category,
        page,
        limit,
        stock_status: variantStockStatus
      } = queryParams;
      const {
        currentBoutique: {
          registered_address_country: country
        }
      } = this.brand;
      const {
        categories,
        meta: categoriesMeta
      } = await _category.default.find({
        metadata: 'product_count',
        variants_country: country._id
      });
      const sortedCategories = this.sortedCategories(categories, categoriesMeta);
      const [allCategoryWithCount] = sortedCategories;
      const selectedCategory = category !== null ? sortedCategories.find(({
        slug
      }) => slug === category) : allCategoryWithCount;

      if (!selectedCategory) {
        return this.transitionTo({
          queryParams: {
            category: null,
            page: 1
          }
        });
      }

      const {
        products,
        meta: productsMeta
      } = await _product.default.find(_objectSpread({
        populate: 'category',
        variants_country: country._id,
        page,
        limit,
        category: selectedCategory.id
      }, variantStockStatus !== _packagingProductVariantStockStatus.default.ALL ? {
        variant_stock_status: variantStockStatus
      } : {}), {
        filterByCountry: country
      });
      return {
        products,
        categories: sortedCategories,
        meta: productsMeta,
        selectedCategory,
        selectedVariantStockStatus: variantStockStatus
      };
    }

    sortedCategories(categories, {
      products_count
    }) {
      const productsCount = products_count !== null && products_count !== void 0 ? products_count : {};
      const totalProducts = Object.values(productsCount).reduce((acc, curr) => acc + curr, 0);
      return [_category.default.allCategory, ...categories].filter(({
        id
      }) => productsCount[id] !== 0).map(category => _objectSpread(_objectSpread({}, category), {}, {
        count: category.id ? productsCount[category.id] : totalProducts
      })).sort((a, b) => b.count - a.count);
    }

  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "brand", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  })), _class));
  _exports.default = BrandBoutiquesBoutiquePackagingProductsIndexRoute;
});