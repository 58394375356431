define("flaneur/pods/brand/boutiques/boutique/insights/controller", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  class BrandBoutiquesBoutiqueInsightsController extends Ember.Controller {
    constructor(...args) {
      super(...args);

      _defineProperty(this, "queryParams", ['page', 'limit', 'q', {
        stock: {
          type: 'boolean'
        }
      }]);

      _defineProperty(this, "page", 1);

      _defineProperty(this, "limit", 20);

      _defineProperty(this, "stock", null);
    }

  }

  _exports.default = BrandBoutiquesBoutiqueInsightsController;
});