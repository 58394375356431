define("flaneur/pods/brand/settings/route", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    model() {
      return {
        brand: this.modelFor('brand')
      };
    }

  });

  _exports.default = _default;
});