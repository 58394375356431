define("flaneur/pods/brand/boutiques/boutique/inventory/index/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "epD+aEUB",
    "block": "{\"symbols\":[],\"statements\":[[6,[37,3],[[35,2]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"  \"],[8,\"empty-page\",[],[[\"@heading\",\"@title\",\"@tip\",\"@subtitle\",\"@image\",\"@items\"],[[30,[36,1],null,[[\"title\",\"subtitle\"],[\"Inventory\",\"Manage your stock quantities\"]]],\"Keep your customers up to date.\",\"This page will update when your first product is added.\",\"The inventory view is your stock management tool for products you've listed on Trouva.com.\",\"inventory\",[32,0,[\"emptyPageItems\"]]]],null],[2,\"\\n\"]],\"parameters\":[]},{\"statements\":[[2,\"  \"],[8,\"pages/inventory-index\",[],[[\"@model\"],[[34,0]]],null],[2,\"\\n\"]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"model\",\"hash\",\"hasNoProducts\",\"if\"]}",
    "moduleName": "flaneur/pods/brand/boutiques/boutique/inventory/index/template.hbs"
  });

  _exports.default = _default;
});