define("flaneur/pods/components/pages/landing/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "7h9hP9Zy",
    "block": "{\"symbols\":[],\"statements\":[[10,\"section\"],[15,0,[31,[\"landing-page \",[32,0,[\"styleNamespace\"]]]]],[12],[2,\"\\n    \"],[10,\"div\"],[14,0,\"landing-page__col landing-page__col--left\"],[12],[2,\"\\n        \"],[10,\"h1\"],[14,0,\"landing-title\"],[12],[2,\"We curate the world's best boutiques.\"],[13],[2,\"\\n        \"],[10,\"h2\"],[14,0,\"landing-subtitle\"],[12],[2,\"Grow your business with Trouva today\"],[13],[2,\"\\n        \"],[10,\"p\"],[14,0,\"landing-text\"],[12],[2,\"Want +1.5 million customers a month to find you?\"],[13],[2,\"\\n        \"],[10,\"a\"],[14,0,\"button button--secondary\"],[14,6,\"https://7286220.hs-sites.com/sell-with-us\"],[14,\"target\",\"_blank\"],[12],[2,\"\\n            Find out how\\n        \"],[13],[2,\"\\n    \"],[13],[2,\"\\n    \"],[10,\"div\"],[14,0,\"landing-page__col\"],[12],[2,\"\\n        \"],[10,\"div\"],[14,0,\"landing-page__cta\"],[12],[2,\"\\n            \"],[10,\"a\"],[14,0,\"landing-page__apply-cta button button--primary button--large\"],[14,6,\"https://share.hsforms.com/1ygpAy1WRQdeRWTcfJAlpUg4c630\"],[14,\"target\",\"_blank\"],[12],[2,\"\\n                Apply to join Trouva\\n            \"],[13],[2,\"\\n        \"],[13],[2,\"\\n    \"],[13],[2,\"\\n\"],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[]}",
    "moduleName": "flaneur/pods/components/pages/landing/template.hbs"
  });

  _exports.default = _default;
});