define("flaneur/pods/components/resource-search/results/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  const NOOP = function () {};

  var _default = Ember.Component.extend({
    tagName: 'ul',
    classNames: ['resource-search__results'],
    value: null,
    results: null,
    createComponent: null,
    onCreate: NOOP
  });

  _exports.default = _default;
});