define("flaneur/enums/boutique-restock-recommendation-type", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    GENERAL: 'general_recommendation',
    STOCK: 'stock_recommendation'
  };
  _exports.default = _default;
});