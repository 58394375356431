define("flaneur/enums/boutique-restock-information-origin", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    BULK_EDIT: 'bulk-edit',
    PRODUCT_CATALOGUE_ACTIONS: 'dropdown',
    ORDERS_SCROLL_CARD: 'orders-scroll-card',
    ORDERS_BANNER: 'orders-banner',
    INSIGHTS_TABLE_ADD: 'insights-table-add',
    INSIGHTS_TABLE_EDIT: 'insights-table-edit'
  };
  _exports.default = _default;
});