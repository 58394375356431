define("flaneur/services/pubsub", ["exports", "flaneur/utils/pub-sub", "flaneur/config/environment"], function (_exports, _pubSub, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Service.extend({
    notifications: [],

    init() {
      this._super(...arguments);

      if (!_environment.default.pusher.enabled) {
        return;
      }

      this.client = new _pubSub.default(_environment.default.pusher);
    },

    subscribe() {
      if (!_environment.default.pusher.enabled) {
        return;
      }

      return this.client.subscribe(...arguments);
    },

    addNotification(notification) {
      this.notifications.addObject(notification);
    },

    removeNotificationAt(index) {
      this.notifications.removeAt(index, 1);
    }

  });

  _exports.default = _default;
});