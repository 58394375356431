define("flaneur/pods/index/route", ["exports", "jquery", "flaneur/config/environment"], function (_exports, _jquery, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    model() {
      return Ember.Object.create({
        contact: Ember.Object.create({}),
        contactDone: false,
        environment: _environment.default.environment
      });
    },

    afterModel(model) {
      return this.session.getLoggedIn().then(res => this.session.getUserBrand(res.user)).then(brand => {
        if (!brand) throw new Error('No brand');
        model.set('isLoggedIn', true);
        model.set('brand', brand._id);
      }).catch(() => {
        model.set('isLoggedIn', false);
      });
    },

    actions: {
      login() {
        this.transitionTo('login');
      },

      signup() {
        this.transitionTo('signup');
      },

      scrollToContent(id) {
        (0, _jquery.default)('html, body').animate({
          scrollTop: (0, _jquery.default)(id).offset().top
        }, 1000);
      },

      sendApplication() {
        const contact = this.get('currentModel.contact');

        if (!contact.name || !contact.email || !contact.boutique_name || !contact.boutique_description) {
          return this.notify.show('error', 'Missing required field');
        }

        this.contactUs.send(contact).then(() => {
          this.notify.show('success', 'Thank you! Message sent.');
          this.set('currentModel.contactDone', true);
        });
      }

    }
  });

  _exports.default = _default;
});