define("flaneur/pods/components/packaging-basket/component", ["exports", "@glimmer/component", "ember-component-css/pod-names"], function (_exports, _component, _podNames) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _dec8, _dec9, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  let PackagingBasketComponent = (_dec = Ember.inject.service, _dec2 = Ember.inject.service, _dec3 = Ember.inject.service, _dec4 = Ember._tracked, _dec5 = Ember._action, _dec6 = Ember._action, _dec7 = Ember._action, _dec8 = Ember._action, _dec9 = Ember._action, (_class = class PackagingBasketComponent extends _component.default {
    constructor(...args) {
      super(...args);

      _initializerDefineProperty(this, "brand", _descriptor, this);

      _initializerDefineProperty(this, "packagingBasket", _descriptor2, this);

      _initializerDefineProperty(this, "router", _descriptor3, this);

      _initializerDefineProperty(this, "isToastVisible", _descriptor4, this);
    }

    get styleNamespace() {
      return _podNames.default['packaging-basket'];
    }

    clearToastTimeout() {
      if (!this.hideToastTimeoutId) {
        return;
      }

      clearTimeout(this.hideToastTimeoutId);
    }

    didInsert() {
      if (!this.args.registerAPI) {
        return;
      }

      this.args.registerAPI({
        showToast: Ember.run.bind(this, this.showToast),
        hideToast: Ember.run.bind(this, this.hideToast)
      });
    }

    toggleToast() {
      return this.isToastVisible ? this.hideToast() : this.showToast();
    }

    showToast({
      hideAfter = 5000
    } = {}) {
      this.isToastVisible = true;
      this.clearToastTimeout();
      const hideToastTimeoutId = setTimeout(() => {
        this.isToastVisible = false;
      }, hideAfter);
      this.hideToastTimeoutId = hideToastTimeoutId;
    }

    hideToast() {
      this.isToastVisible = false;
      this.clearToastTimeout();
    }

    proceed() {
      return this.router.transitionTo('brand.boutiques.boutique.packaging.basket');
    }

  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "brand", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "packagingBasket", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "router", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "isToastVisible", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return false;
    }
  }), _applyDecoratedDescriptor(_class.prototype, "didInsert", [_dec5], Object.getOwnPropertyDescriptor(_class.prototype, "didInsert"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "toggleToast", [_dec6], Object.getOwnPropertyDescriptor(_class.prototype, "toggleToast"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "showToast", [_dec7], Object.getOwnPropertyDescriptor(_class.prototype, "showToast"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "hideToast", [_dec8], Object.getOwnPropertyDescriptor(_class.prototype, "hideToast"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "proceed", [_dec9], Object.getOwnPropertyDescriptor(_class.prototype, "proceed"), _class.prototype)), _class));
  _exports.default = PackagingBasketComponent;
});