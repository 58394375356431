define("flaneur/pods/components/boutique-restock-information-modal/metrics/component", ["exports", "@glimmer/component", "ember-component-css/pod-names"], function (_exports, _component, _podNames) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  const MAX_DAYS_LEFT_IN_STOCK = 90;

  class BoutiqueRestockInformationModalMetricsComponent extends _component.default {
    constructor(...args) {
      super(...args);

      _defineProperty(this, "styleNamespace", _podNames.default['boutique-restock-information-modal/metrics']);
    }

    get showDaysLeftInStock() {
      const {
        daysLeftInStock
      } = this.args.recommendation.metrics;
      return Ember.isPresent(daysLeftInStock) && this.args.recommendation.isTypeStock;
    }

    get showForecastMonthlySales() {
      const {
        forecastMonthlySales
      } = this.args.recommendation.metrics;
      return Ember.isPresent(forecastMonthlySales) && this.args.recommendation.isTypeStock;
    }

    get daysLeftInStock() {
      const {
        daysLeftInStock
      } = this.args.recommendation.metrics;

      if (Ember.isEmpty(daysLeftInStock)) {
        return null;
      }

      if (daysLeftInStock <= MAX_DAYS_LEFT_IN_STOCK) {
        return daysLeftInStock;
      }

      return `${MAX_DAYS_LEFT_IN_STOCK}+`;
    }

  }

  _exports.default = BoutiqueRestockInformationModalMetricsComponent;
});