define("flaneur/pods/components/reservation-item/component", ["exports", "@glimmer/component"], function (_exports, _component) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  class ReservationItemComponent extends _component.default {
    get thumbnail() {
      var _stockLine$images;

      const reservation = this.args.reservation;
      const productThumbnail = reservation.product.thumbnail.url;
      const stockLine = reservation.stock_line;
      const sortedStockLineImages = Array.from((_stockLine$images = stockLine === null || stockLine === void 0 ? void 0 : stockLine.images) !== null && _stockLine$images !== void 0 ? _stockLine$images : []).sort((a, b) => a.display_order - b.display_order);
      const [firstImage = {
        url: ''
      }] = sortedStockLineImages;
      const stockLineImage = firstImage.url;
      return stockLineImage ? stockLineImage : productThumbnail;
    }

  }

  _exports.default = ReservationItemComponent;
});