define("flaneur/pods/services/quick-ship/index/controller", ["exports", "flaneur/pods/brand/boutiques/boutique/shipments/index/controller"], function (_exports, _controller) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  class ServicesQuickShipIndexController extends _controller.default {}

  _exports.default = ServicesQuickShipIndexController;
});