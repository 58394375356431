define("flaneur/pods/components/made-reservation-actions-modal/component", ["exports", "@glimmer/component", "ember-component-css/pod-names", "flaneur/models/zendesk", "big.js", "flaneur/config/environment"], function (_exports, _component, _podNames, _zendesk, _big, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _dec8, _dec9, _dec10, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _descriptor6, _descriptor7, _descriptor8;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  let MadeReservationActionsModalComponent = (_dec = Ember.inject.service, _dec2 = Ember.inject.service, _dec3 = Ember.inject.service, _dec4 = Ember._tracked, _dec5 = Ember._tracked, _dec6 = Ember._tracked, _dec7 = Ember._tracked, _dec8 = Ember._tracked, _dec9 = Ember._action, _dec10 = Ember._action, (_class = class MadeReservationActionsModalComponent extends _component.default {
    constructor(...args) {
      super(...args);

      _initializerDefineProperty(this, "analytics", _descriptor, this);

      _initializerDefineProperty(this, "brand", _descriptor2, this);

      _initializerDefineProperty(this, "session", _descriptor3, this);

      _initializerDefineProperty(this, "submitting", _descriptor4, this);

      _initializerDefineProperty(this, "submitted", _descriptor5, this);

      _initializerDefineProperty(this, "showError", _descriptor6, this);

      _initializerDefineProperty(this, "error", _descriptor7, this);

      _initializerDefineProperty(this, "message", _descriptor8, this);
    }

    get styleNamespace() {
      return _podNames.default['made-reservation-actions-modal'];
    }

    getRequesterEmail() {
      return this.brand.currentBoutique.support_email || this.brand.currentBoutique.order_email || this.session.user.email;
    }

    createZendeskTicket() {
      var _this$message;

      const {
        currentBoutique: {
          _id: boutiqueId,
          address_country: {
            iso_code: boutiqueAddressCountryIsoCode
          },
          name: boutiqueName
        }
      } = this.brand;
      const {
        reservation
      } = this.args;
      const {
        channel: orderChannel,
        channel_specific: {
          order_reference: orderReference
        },
        created_at: orderCreatedAt,
        ordernumber: orderNumber
      } = reservation.order;
      return _zendesk.default.createEnrichedTicket({
        subject: [orderReference, `#${orderNumber}`, 'Out of stock', (_this$message = this.message) !== null && _this$message !== void 0 && _this$message.length ? 'Info' : null].filter(Boolean).join(' - '),
        requester_name: boutiqueName,
        requester_email: this.getRequesterEmail(),
        ordernumber: orderNumber,
        order_date: orderCreatedAt,
        boutique_name: boutiqueName,
        boutique_id: boutiqueId,
        channel: orderChannel,
        channel_specific_order_reference: orderReference,
        tags: [`${orderNumber}:${boutiqueId}`, `${boutiqueAddressCountryIsoCode}_boutique`, _zendesk.default.getChannelTag(orderChannel)],
        comment: {
          body: `${boutiqueName} marked a reservation as out of stock\n\n` + 'GENERIC INFORMATION\n\n' + `- Order number: #${orderNumber}\n` + `- MADE order number: ${orderReference}\n` + `- [Backalley URL](${_environment.default.backalleyURL}/made/orders/${orderReference})\n` + '\n' + `OUT OF STOCK PRODUCT INFORMATION:\n\n` + `- Name: ${reservation.product.name}\n` + (reservation.selected_size ? `- Selected size: ${reservation.selected_size}\n` : '') + `- ID: ${reservation.product._id}\n` + `- Price: £${(0, _big.default)(reservation.price).div(100).toFixed(2)}\n` + `- Quantity: ${reservation.quantity}\n` + `- Boutique: ${boutiqueName}\n` + '\n\n' + `MESSAGE FROM ${boutiqueName}:\n\n` + (this.message ? `"${this.message}"\n` : 'No message was provided\n')
        }
      });
    }

    outOfStock() {
      var _this$message2;

      this.submitting = true;
      this.analytics.gtm({
        action: 'clicked_mark_as_out_of_stock',
        label: this.args.reservation.order._id
      });
      return this.args.reservation.markAsOutOfStock({
        alternatives: [],
        message: (_this$message2 = this.message) !== null && _this$message2 !== void 0 ? _this$message2 : ''
      }).then(() => {
        this.submitted = true;
      }).catch(() => {
        this.error = false;
      }).finally(() => {
        this.submitting = false;
      });
    }

    submit() {
      if (Ember.isEmpty(this.message)) {
        this.showError = true;
        return this.showError;
      }

      this.showError = false;
      this.submitting = true;
      Ember.set(this.args.reservation, 'status', 'fail');
      return this.args.reservation.put().then(() => {
        this.analytics.gtm({
          action: 'clicked_mark_as_other_issue',
          label: this.args.reservation.order._id
        });
        return this.createZendeskTicket();
      }).then(() => this.submitted = true).catch(() => this.error = false).finally(() => this.submitting = false);
    }

  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "analytics", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "brand", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "session", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "submitting", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return false;
    }
  }), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "submitted", [_dec5], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return false;
    }
  }), _descriptor6 = _applyDecoratedDescriptor(_class.prototype, "showError", [_dec6], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return false;
    }
  }), _descriptor7 = _applyDecoratedDescriptor(_class.prototype, "error", [_dec7], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return false;
    }
  }), _descriptor8 = _applyDecoratedDescriptor(_class.prototype, "message", [_dec8], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return '';
    }
  }), _applyDecoratedDescriptor(_class.prototype, "outOfStock", [_dec9], Object.getOwnPropertyDescriptor(_class.prototype, "outOfStock"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "submit", [_dec10], Object.getOwnPropertyDescriptor(_class.prototype, "submit"), _class.prototype)), _class));
  _exports.default = MadeReservationActionsModalComponent;
});