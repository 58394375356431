define("flaneur/utils/is-string", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = isString;

  function isString(value) {
    return typeof value === 'string' || value instanceof String;
  }
});