define("flaneur/helpers/cloudinary-image", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.cloudinaryImage = cloudinaryImage;
  _exports.default = void 0;

  /**
   * Turn into cloudinary URL with dimensions
   * as its core dimension and format .jpg
   */
  function toCloudinary(url, dimensions) {
    if (!url) {
      return;
    }

    if (url.includes('imgix')) {
      return url;
    }

    const transformationUrl = [];
    const splitUrl = url.split('/');

    if (dimensions) {
      // Add the transformation
      splitUrl.forEach((urlPart, index) => {
        transformationUrl.push(urlPart);

        if (urlPart === 'upload' && splitUrl[index - 1] === 'image') {
          transformationUrl.push(dimensions);
        }
      });
    } // /(http|https)(.)\./


    const newUrl = transformationUrl.length ? transformationUrl : splitUrl; // Force https

    newUrl[0] = 'https:';
    const file = newUrl[newUrl.length - 1].split('.');

    if (file.length > 1) {
      file[1] = 'jpg';
    } // Replace file name with format


    newUrl[newUrl.length - 1] = `${file[0]}.${file[1]}`;
    return `${newUrl.join('/')}`;
  }

  function cloudinaryImage([url, transformations]) {
    return toCloudinary(url, transformations);
  }

  var _default = Ember.Helper.helper(cloudinaryImage);

  _exports.default = _default;
});