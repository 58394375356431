define("flaneur/pods/brand/boutiques/boutique/settings/route", ["exports", "flaneur/models/boutique", "jquery"], function (_exports, _boutique, _jquery) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    brand: Ember.inject.service(),
    media: Ember.inject.service(),
    notify: Ember.inject.service(),

    model() {
      /**
       * Make a copy of the currentBoutique to hold the unsaved changes
       */
      return _boutique.default.create(Ember.getOwner(this).ownerInjection(), this.brand.currentBoutique);
    },

    actions: {
      // eslint-disable-next-line no-unused-vars
      save(fields = [], validations = {
        isInvalid: false
      }) {
        if (validations.isInvalid) {
          this.notify.show('error', 'Oops! It looks like you have some invalid fields.');
          const [, boutiqueField] = validations.error.attribute.split('boutique.');
          const label = document.querySelector(`label[for="${Ember.String.dasherize(boutiqueField)}"]`);

          if (Ember.isEmpty(label)) {
            return;
          }

          const container = this.media.isLarge ? 'html, body' : '#outlet-container';
          (0, _jquery.default)(container).animate({
            scrollTop: label.offsetTop + 112
          }, 600);
          return;
        }

        const parsedFields = fields.map(field => field.split('.')[0]);
        const fieldsToSave = parsedFields.reduce((fieldsToSave, field) => {
          fieldsToSave[field] = this.currentModel[field];
          return fieldsToSave;
        }, {
          _id: this.currentModel._id
        });
        return _boutique.default.partialSave(fieldsToSave).then(boutique => {
          Ember.setProperties(this.brand.currentBoutique, boutique);
          const {
            name,
            _id
          } = this.brand.currentBoutique;
          this.notify.show('success', 'Your changes have been saved!');
          this.analytics.trackEvent({
            event: 'Updated Boutique',
            boutique: _id,
            boutique_name: name
          });
        }).catch(() => {
          this.notify.show('error', 'Oops! An error ocurred while saving your changes. Please try again.');
        });
      },

      willTransition(transition) {
        const {
          fields
        } = transition.from.attributes;
        const hasChangedValues = Ember.isPresent(fields) && fields.some(field => {
          const currentChangeField = this.currentModel[field];
          const originalBoutiqueField = this.brand.currentBoutique[field];
          const isCurrentFieldBooleanOrString = typeof currentChangeField === 'string' || typeof currentChangeField === 'boolean';

          if (!isCurrentFieldBooleanOrString) {
            return false;
          }

          const fieldsNotDifferent = !Ember.isEqual(currentChangeField, originalBoutiqueField);
          const fieldsNotEmpty = !(Ember.isEmpty(currentChangeField) && Ember.isEmpty(originalBoutiqueField));
          return fieldsNotDifferent && fieldsNotEmpty;
        });

        if (hasChangedValues && !this.controller.isDiscarded) {
          this.controller.setProperties({
            active: true,
            to: transition
          });
          transition.abort();
        }

        this.controller.set('isDiscarded', false);
      },

      discard() {
        this.controller.setProperties({
          isDiscarded: true,
          active: false
        });
        this.refresh();
        this.controller.to.retry();
      }

    }
  });

  _exports.default = _default;
});