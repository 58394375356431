define("flaneur/pods/components/pages/products/bulk/upload/amend-step/variants-amend-modal/component", ["exports", "ember-concurrency", "flaneur/models/variant", "flaneur/utils/products-bulk-upload/validations"], function (_exports, _emberConcurrency, _variant, _validations) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _dec8, _class;

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  const SEARCH_DEBOUNCE = 500;
  let VariantsAmendModal = (_dec = Ember.computed('changedVariants.@each.value', 'variantNames'), _dec2 = Ember.computed('changedVariants.@each._id', 'resourceNotFound'), _dec3 = Ember.computed('hasVariantNotFound', 'changedVariants.[]'), _dec4 = Ember._action, _dec5 = Ember._action, _dec6 = Ember._action, _dec7 = Ember._action, _dec8 = Ember._action, (_class = class VariantsAmendModal extends Ember.Component {
    constructor(...args) {
      super(...args);

      _defineProperty(this, "tagName", '');

      _defineProperty(this, "variantNames", ['size', 'material', 'color', 'other']);

      _defineProperty(this, "resourceNotFound", _validations.RESOURCE_NOT_FOUND);
    }

    init() {
      super.init(...arguments);
      this.set('changedVariants', [...this.stockLine.variants]);
    }

    get selected() {
      return this.variantNames.reduce((selected, name) => {
        selected[name] = this.changedVariants.findBy('name', name);
        return selected;
      }, {});
    }

    get hasVariantNotFound() {
      return this.changedVariants.some(({
        _id
      }) => _id === this.resourceNotFound);
    }

    get isUpdateDisabled() {
      return this.hasVariantNotFound || Ember.isEmpty(this.changedVariants);
    }

    updateVariant(variantName, variantValue) {
      const filteredVariants = this.changedVariants.filter(({
        name
      }) => name !== variantName);
      this.changedVariants.setObjects(filteredVariants);

      if (Ember.isPresent(variantValue)) {
        this.changedVariants.addObject(variantValue);
      }

      return variantValue;
    }

    *searchVariant(name, term) {
      yield (0, _emberConcurrency.timeout)(SEARCH_DEBOUNCE);
      const query = {
        name,
        value: {
          $regex: `^${term.trim()}`,
          $options: 'i'
        },
        limit: 24
      };
      return yield _variant.default.find(query);
    }

    async onCreateVariant(name, value) {
      const variant = await _variant.default.create({
        name,
        value
      }).save();
      return this.updateVariant(name, variant);
    }

    onChangeVariant(name, variant) {
      return this.updateVariant(name, variant);
    }

    showCreateWhen(name, term, variants) {
      if (['material', 'color'].includes(name)) {
        return false;
      }

      const hasVariant = variants.some(({
        value
      }) => {
        return value.toLowerCase().trim() === term.toLowerCase().trim();
      });
      return !hasVariant;
    }

    updateVariants() {
      Ember.set(this.stockLine, 'variants', this.changedVariants);
      this.onUpdateVariant();
      this.onClose();
    }

  }, (_applyDecoratedDescriptor(_class.prototype, "selected", [_dec], Object.getOwnPropertyDescriptor(_class.prototype, "selected"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "hasVariantNotFound", [_dec2], Object.getOwnPropertyDescriptor(_class.prototype, "hasVariantNotFound"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "isUpdateDisabled", [_dec3], Object.getOwnPropertyDescriptor(_class.prototype, "isUpdateDisabled"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "updateVariant", [_dec4], Object.getOwnPropertyDescriptor(_class.prototype, "updateVariant"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "searchVariant", [_emberConcurrency.restartableTask], Object.getOwnPropertyDescriptor(_class.prototype, "searchVariant"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "onCreateVariant", [_dec5], Object.getOwnPropertyDescriptor(_class.prototype, "onCreateVariant"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "onChangeVariant", [_dec6], Object.getOwnPropertyDescriptor(_class.prototype, "onChangeVariant"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "showCreateWhen", [_dec7], Object.getOwnPropertyDescriptor(_class.prototype, "showCreateWhen"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "updateVariants", [_dec8], Object.getOwnPropertyDescriptor(_class.prototype, "updateVariants"), _class.prototype)), _class));
  _exports.default = VariantsAmendModal;
});