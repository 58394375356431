define("flaneur/pods/components/input-range/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "u1iZIFe5",
    "block": "{\"symbols\":[],\"statements\":[[11,\"button\"],[24,0,\"input-range__button\"],[16,\"disabled\",[30,[36,0],[[32,0,[\"disabled\"]],[32,0,[\"hasReachedMinimum\"]]],null]],[4,[38,1],[[32,0],\"onClickMinus\"],null],[12],[2,\"\\n  \"],[8,\"fa-icon\",[],[[\"@icon\"],[\"minus\"]],null],[2,\"\\n\"],[13],[2,\"\\n\\n\"],[1,[30,[36,5],null,[[\"tabindex\",\"class\",\"type\",\"value\",\"min\",\"key-up\"],[[30,[36,4],[[32,0,[\"disabled\"]],-1,0],null],\"input-range__input\",\"number\",[35,3],[35,2],[30,[36,1],[[32,0],\"onInputChange\"],null]]]]],[2,\"\\n\\n\"],[11,\"button\"],[24,0,\"input-range__button\"],[16,\"disabled\",[30,[36,0],[[32,0,[\"disabled\"]],[32,0,[\"hasReachedMaximum\"]]],null]],[4,[38,1],[[32,0],\"onClickPlus\"],null],[12],[2,\"\\n  \"],[8,\"fa-icon\",[],[[\"@icon\"],[\"plus\"]],null],[2,\"\\n\"],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"or\",\"action\",\"minimum\",\"numericValue\",\"if\",\"input\"]}",
    "moduleName": "flaneur/pods/components/input-range/template.hbs"
  });

  _exports.default = _default;
});