define("flaneur/pods/components/nav-bar-alert/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "oth0oGJo",
    "block": "{\"symbols\":[\"notification\"],\"statements\":[[10,\"div\"],[14,0,\"alerts alert-dismissable\"],[12],[2,\"\\n\"],[6,[37,7],[[30,[36,6],[[30,[36,6],[[35,5,[\"notifications\"]]],null]],null]],null,[[\"default\"],[{\"statements\":[[2,\"    \"],[10,\"div\"],[15,0,[31,[\"alert alert-\",[32,1,[\"type\"]],\" \",[30,[36,1],[[32,1,[\"dismiss\"]],\"alert--dismiss\",\"\"],null]]]],[12],[2,\"\\n      \"],[11,\"div\"],[24,\"role\",\"button\"],[24,0,\"close\"],[4,[38,4],[\"click\",[30,[36,3],[[32,0],\"hide\",[32,1]],null]],null],[12],[2,\"\\n        \"],[8,\"fa-icon\",[],[[\"@icon\",\"@prefix\"],[\"times\",\"far\"]],null],[2,\"\\n      \"],[13],[2,\"\\n\\n\"],[6,[37,1],[[32,1,[\"message\"]]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"        \"],[1,[30,[36,2],[[32,1,[\"message\"]]],null]],[2,\"\\n\"]],\"parameters\":[]},{\"statements\":[[6,[37,1],[[30,[36,0],[[32,1,[\"type\"]],\"danger\"],null]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"        Oops, something went wrong!\\n\"]],\"parameters\":[]},{\"statements\":[[2,\"        Alright\\n      \"]],\"parameters\":[]}]]]],\"parameters\":[]}]]],[2,\"    \"],[13],[2,\"\\n\"]],\"parameters\":[1]}]]],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"eq\",\"if\",\"html-safe\",\"action\",\"on\",\"notify\",\"-track-array\",\"each\"]}",
    "moduleName": "flaneur/pods/components/nav-bar-alert/template.hbs"
  });

  _exports.default = _default;
});