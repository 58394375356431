define("flaneur/pods/components/pages/orders/print/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    actions: {
      addLoading() {
        return this.addLoading(...arguments);
      },

      bookShipment() {
        return this.bookShipment(...arguments);
      },

      inStock() {
        return this.inStock(...arguments);
      },

      loadMore() {
        return this.loadMore(...arguments);
      },

      refresh() {
        return this.refresh(...arguments);
      },

      requestedManualLabel() {
        return this.requestedManualLabel(...arguments);
      },

      resetSearch() {
        return this.resetSearch(...arguments);
      },

      updateReservationBoutique() {
        return this.updateReservationBoutique(...arguments);
      }

    }
  });

  _exports.default = _default;
});