define("flaneur/pods/components/pages/settings/operational-status/component", ["exports", "flaneur/enums/operational-status", "flaneur/models/zendesk", "date-fns"], function (_exports, _operationalStatus, _zendesk, _dateFns) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    brand: Ember.inject.service(),
    classNames: ['subpage'],
    showMessage: Ember.computed('boutique.operational_status', function () {
      const {
        operational_status
      } = this.boutique;
      return operational_status === _operationalStatus.default.PARTIAL || operational_status === _operationalStatus.default.OPERATIONAL;
    }),
    isPaused: Ember.computed('boutique.operational_status', function () {
      const {
        operational_status
      } = this.boutique;
      return operational_status === _operationalStatus.default.PAUSED;
    }),

    saveBoutique() {
      return this.onSave(['operational_status']);
    },

    actions: {
      save() {
        this.saveBoutique();
      },

      async saveAndCreateTicket({
        reason,
        details,
        contactName,
        contactNumber
      }) {
        this.toggleProperty('isPauseModeModalOpen');
        this.set('boutique.operational_status', _operationalStatus.default.PAUSED);

        try {
          this.saveBoutique();
        } catch (error) {
          return;
        }

        await _zendesk.default.createTicket({
          requester: {
            name: this.brand.currentBoutique.name,
            email: this.brand.currentBoutique.support_email || this.brand.currentBoutique.order_email || this.session.user.email
          },
          subject: `PAUSE request from ${this.brand.currentBoutique.name}`,
          tags: ['pause_mode'],
          comment: {
            body: `
                        ----------
                        ${this.brand.currentBoutique.name} has paused their operations on ${(0, _dateFns.format)(new Date(), 'PPpp')}

                        Reason: ${reason}

                        Additional info: ${details}

                        Contact name: ${contactName}
                        Contact Number: ${contactNumber}
                    `
          }
        });
      },

      closeModal() {
        this.toggleProperty('isPauseModeModalOpen');
      },

      unPause() {
        this.set('boutique.operational_status', _operationalStatus.default.OPERATIONAL);
        this.saveBoutique();
      }

    }
  });

  _exports.default = _default;
});