define("flaneur/services/onboarding", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Service.extend({
    brand: Ember.inject.service(),
    boutique: Ember.computed.alias('brand.currentBoutique'),

    _areBoutiqueFieldsValid(fields) {
      return fields.reduce((valid, current) => {
        const [field, nestedField] = current.split('.');
        const validationAttr = this.boutique.validations.attrs[field];
        const validation = Ember.isEmpty(nestedField) ? validationAttr : validationAttr[nestedField];
        return Ember.isEmpty(validation) ? valid : valid && validation.isValid;
      }, true);
    },

    isBoutiqueProfileCompleted: Ember.computed('boutique.{name,address,cover.url,cover_thumbnail.url,description,mini_description}', function () {
      const fields = ['name', 'address', 'cover.url', 'cover_thumbnail.url', 'description', 'mini_description'];
      return this._areBoutiqueFieldsValid(fields);
    }),
    isAccountingDetailsCompleted: Ember.computed('boutique.{bank_account_bic,bank_account_iban,bank_account_number,bank_sort_code,vat_number,is_vat_registered}', function () {
      const fields = ['bank_account_bic', 'bank_account_iban', 'bank_account_number', 'bank_sort_code', 'vat_number', 'is_vat_registered'];
      return this._areBoutiqueFieldsValid(fields);
    }),
    isOrderFulfimentCompleted: Ember.computed('boutique.{address_line_1,address_city,postcode,country}', function () {
      const fields = ['address_line_1', 'address_city', 'address_country', 'postcode'];
      return this._areBoutiqueFieldsValid(fields);
    }),
    isContactDetailsCompleted: Ember.computed('boutique.{primary_contact_name,primary_phone,primary_email,support_email,support_phone,order_email,statement_email}', function () {
      const fields = ['primary_contact_name', 'primary_phone', 'primary_email', 'support_email', 'support_phone', 'order_email', 'statement_email'];
      return this._areBoutiqueFieldsValid(fields);
    }),
    areStepsCompleted: Ember.computed.and('isBoutiqueProfileCompleted', 'isAccountingDetailsCompleted', 'isOrderFulfimentCompleted', 'isContactDetailsCompleted'),
    checks: Ember.computed('isBoutiqueProfileCompleted', 'isAccountingDetailsCompleted', 'isOrderFulfimentCompleted', 'isContactDetailsCompleted', function () {
      return [{
        label: 'Set up your boutique page',
        completed: this.isBoutiqueProfileCompleted,
        route: 'brand.boutiques.boutique.settings.index'
      }, {
        label: 'Add your accounting info to get paid',
        completed: this.isAccountingDetailsCompleted,
        route: 'brand.boutiques.boutique.settings.accounting-details'
      }, {
        label: 'Choose your fulfilment location',
        completed: this.isOrderFulfimentCompleted,
        route: 'brand.boutiques.boutique.settings.order-fulfilment'
      }, {
        label: 'Review your day-to-day contact details',
        completed: this.isContactDetailsCompleted,
        route: 'brand.boutiques.boutique.settings.contact-details'
      }];
    }),
    successChecks: Ember.computed.filterBy('checks', 'completed', true),
    successChecksCount: Ember.computed.reads('successChecks.length')
  });

  _exports.default = _default;
});