define("flaneur/pods/components/progress-bar/component", ["exports", "@glimmer/component", "ember-component-css/pod-names"], function (_exports, _component, _podNames) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  class ProgressBarComponent extends _component.default {
    get currentStep() {
      return this.args.steps.reduce((current, {
        complete
      }, index) => complete ? ++index : current, 0);
    }

    get styleNamespace() {
      return _podNames.default['progress-bar'];
    }

    get computedStyle() {
      const styles = [`grid-template-columns: repeat(${this.args.steps.length - 1}, 1fr) 40px`];
      return Ember.String.htmlSafe(styles.join(';'));
    }

  }

  _exports.default = ProgressBarComponent;
});