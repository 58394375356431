define("flaneur/pods/components/order-loading-bar/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "OkN4UedK",
    "block": "{\"symbols\":[],\"statements\":[[1,[30,[36,0],[\"loading\"],null]],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"svg-jar\"]}",
    "moduleName": "flaneur/pods/components/order-loading-bar/template.hbs"
  });

  _exports.default = _default;
});