define("flaneur/models/variant", ["exports", "flaneur/mixins/request"], function (_exports, _request) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const Variant = Ember.Object.extend(_request.default, {
    async save() {
      const saveMethod = this._id ? 'PUT' : 'POST';
      const {
        variants
      } = await this.request({
        method: saveMethod,
        url: this.apiURL('variants'),
        data: JSON.stringify(this)
      }); // Update variant instance with api properties

      this.setProperties(variants[0]);
      return this;
    }

  });
  Variant.reopenClass(_request.default, {
    async find(data) {
      const {
        variants
      } = await this.request({
        method: 'POST',
        url: this.apiURL('variants'),
        headers: {
          'X-HTTP-METHOD-OVERRIDE': 'GET'
        },
        data: JSON.stringify(data)
      });
      return variants.map(variant => this.create(variant));
    }

  });
  var _default = Variant;
  _exports.default = _default;
});