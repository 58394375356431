define("flaneur/models/user/validations", ["exports", "ember-cp-validations"], function (_exports, _emberCpValidations) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.Validations = void 0;
  const validators = {
    email: [(0, _emberCpValidations.validator)('presence', {
      presence: true,
      description: 'Email'
    }), (0, _emberCpValidations.validator)('format', {
      type: 'email',
      description: 'Email'
    })],
    password: [(0, _emberCpValidations.validator)('presence', {
      presence: true,
      description: 'Password'
    })]
  };
  const Validations = (0, _emberCpValidations.buildValidations)(validators);
  _exports.Validations = Validations;
});