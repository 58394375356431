define("flaneur/pods/components/packaging-basket/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "U3dJsrm2",
    "block": "{\"symbols\":[],\"statements\":[[11,\"div\"],[16,0,[31,[\"packaging-basket \",[32,0,[\"styleNamespace\"]],\" \",[30,[36,2],[[32,0,[\"packagingBasket\",\"isEmpty\"]],\"packaging-basket--empty\"],null]]]],[4,[38,3],[[32,0,[\"didInsert\"]]],null],[4,[38,4],[[32,0,[\"hideToast\"]]],[[\"exceptSelector\"],[\".button\"]]],[12],[2,\"\\n  \"],[11,\"div\"],[24,0,\"packaging-basket__button\"],[4,[38,5],[\"click\",[32,0,[\"toggleToast\"]]],null],[12],[2,\"\\n    \"],[8,\"fa-icon\",[],[[\"@icon\",\"@prefix\"],[\"shopping-bag\",\"far\"]],null],[2,\"\\n    \"],[10,\"span\"],[12],[1,[32,0,[\"packagingBasket\",\"totalUnits\"]]],[13],[2,\"\\n  \"],[13],[2,\"\\n\\n  \"],[10,\"div\"],[15,0,[31,[\"packaging-basket__toast \",[30,[36,2],[[30,[36,6],[[32,0,[\"isToastVisible\"]]],null],\"packaging-basket__toast--hidden\"],null]]]],[12],[2,\"\\n\"],[6,[37,2],[[32,0,[\"packagingBasket\",\"isEmpty\"]]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"      \"],[10,\"p\"],[12],[2,\"Your shopping bag is empty\"],[13],[2,\"\\n\"]],\"parameters\":[]},{\"statements\":[[2,\"      \"],[10,\"h3\"],[12],[2,\"Your shopping bag\"],[13],[2,\"\\n      \"],[10,\"div\"],[12],[2,\"\\n        \"],[10,\"div\"],[12],[2,\"Subtotal\"],[13],[2,\"\\n        \"],[10,\"div\"],[12],[2,\"\\n          \"],[1,[30,[36,1],[[30,[36,0],[[32,0,[\"packagingBasket\",\"subtotalPrice\",\"amount\"]]],null]],[[\"currency\",\"style\"],[[32,0,[\"packagingBasket\",\"subtotalPrice\",\"currency\"]],\"currency\"]]]],[2,\"\\n        \"],[13],[2,\"\\n      \"],[13],[2,\"\\n\\n      \"],[8,\"button\",[],[[\"@onClick\"],[[32,0,[\"proceed\"]]]],[[\"default\"],[{\"statements\":[[2,\"\\n        Proceed to payment\\n      \"]],\"parameters\":[]}]]],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"  \"],[13],[2,\"\\n\"],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"to-money\",\"format-number\",\"if\",\"did-insert\",\"on-click-outside\",\"on\",\"not\"]}",
    "moduleName": "flaneur/pods/components/packaging-basket/template.hbs"
  });

  _exports.default = _default;
});