define("flaneur/pods/components/radio-button/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "HIUdos5Z",
    "block": "{\"symbols\":[\"@label\",\"&default\",\"@disabled\",\"@theme\",\"&attrs\",\"@namedBlocksInfo\",\"@value\",\"@name\",\"@autofocus\",\"@required\",\"@tabindex\"],\"statements\":[[11,\"div\"],[16,0,[31,[\"radio-button \",[32,0,[\"styleNamespace\"]],\" \",[30,[36,0],[[32,0,[\"checked\"]],\"radio-button--checked\"],null],\" \",[30,[36,0],[[30,[36,2],[[32,4],\"big\"],null],\"radio-button--big\"],null],\" \",[30,[36,0],[[32,3],\"radio-button--disabled\"],null]]]],[17,5],[12],[2,\"\\n  \"],[10,\"label\"],[15,0,[31,[\"radio-button__label \",[30,[36,0],[[30,[36,3],[[32,6],\"label\",false],null],\"radio-button__label--custom\"],null],\" \"]]],[12],[2,\"\\n    \"],[11,\"input\"],[16,2,[32,7]],[16,3,[32,8]],[16,\"autofocus\",[32,9]],[16,\"disabled\",[32,3]],[16,\"required\",[32,10]],[16,\"checked\",[32,0,[\"checked\"]]],[16,\"tabindex\",[32,11]],[24,4,\"radio\"],[4,[38,4],[\"change\",[32,0,[\"change\"]]],null],[12],[13],[2,\"\\n\\n    \"],[10,\"div\"],[14,0,\"radio-button__text\"],[12],[2,\"\\n\"],[6,[37,0],[[30,[36,3],[[32,6],\"title\",false],null]],null,[[\"default\"],[{\"statements\":[[2,\"        \"],[10,\"p\"],[14,0,\"radio-button__title\"],[12],[18,2,[[30,[36,1],[\"title\"],null]]],[13],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"\\n\"],[6,[37,0],[[30,[36,3],[[32,6],\"label\",false],null]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"        \"],[10,\"p\"],[12],[18,2,[[30,[36,1],[\"label\"],null]]],[13],[2,\"\\n\"]],\"parameters\":[]},{\"statements\":[[6,[37,0],[[32,1]],null,[[\"default\"],[{\"statements\":[[2,\"        \"],[1,[32,1]],[2,\"\\n      \"]],\"parameters\":[]}]]]],\"parameters\":[]}]]],[2,\"    \"],[13],[2,\"\\n\\n    \"],[18,2,[[30,[36,1],[\"icon\"],null]]],[2,\"\\n  \"],[13],[2,\"\\n\"],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"if\",\"-named-block-invocation\",\"eq\",\"-has-block\",\"on\"]}",
    "moduleName": "flaneur/pods/components/radio-button/template.hbs"
  });

  _exports.default = _default;
});