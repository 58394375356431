define("flaneur/helpers/math-negate", ["exports", "big.js"], function (_exports, _big) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.mathNegate = mathNegate;
  _exports.default = void 0;

  function mathNegate([value]) {
    return (0, _big.default)(value).times(-1);
  }

  var _default = Ember.Helper.helper(mathNegate);

  _exports.default = _default;
});