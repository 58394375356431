define("flaneur/enums/access-point-categories", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const accessPointCategoriesWhitelist = ['Food & Drink', 'Drink', 'Food', 'Sweets & Chocolate', 'Kids Clothes', 'Baby Accessories', 'Baby clothes', 'Boys clothes', 'Girls clothes', 'Kids Accessories', 'Unisex Kids', 'Beauty & Perfume', 'Bath & Body', 'Face', 'Makeup', 'Perfume', 'Books & Stationery', 'Books', 'Stationery', 'Candles & Room Fragrance', 'Grooming', 'Watches & Jewellery', 'Eyewear', 'Gloves', 'Hats', 'Other Accessories', 'Scarves', 'Shoes', 'Ties & Bowties', 'Watches & Jewellery', 'Menswear', 'Coats, Jackets & Blazers', 'Jumpers & Cardigans', 'Nightwear', 'Shirts', 'Socks & Underwear', 'Swimwear', 'Tops & T-shirts', 'Trousers & Shorts', 'Bracelets', 'Earrings', 'Necklaces', 'Other Jewellery', 'Rings', 'Watches', 'Eyewear', 'Gloves', 'Hats', 'Other Accessories', 'Scarves', 'Shoes', 'Socks & Tights', 'Womenswear', 'Coats & Jackets', 'Dresses', 'Jeans & Trousers', 'Jumpers & Cardigans', 'Skirts & Shorts', 'Swimwear & Beachwear', 'Tops', 'Underwear & Nightwear'];
  var _default = accessPointCategoriesWhitelist;
  _exports.default = _default;
});