define("flaneur/pods/brand/boutiques/boutique/settings/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "+1YTT5Iv",
    "block": "{\"symbols\":[],\"statements\":[[8,\"pages/settings\",[],[[\"@boutique\"],[[34,0]]],[[\"default\"],[{\"statements\":[[2,\"\\n  \"],[1,[30,[36,2],[[30,[36,1],null,null]],null]],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"\\n\\n\"],[8,\"discard-changes-modal\",[],[[\"@active\",\"@confirm\"],[[34,3],[30,[36,4],[\"discard\"],null]]],null],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"model\",\"-outlet\",\"component\",\"active\",\"route-action\"]}",
    "moduleName": "flaneur/pods/brand/boutiques/boutique/settings/template.hbs"
  });

  _exports.default = _default;
});