define("flaneur/pods/services/quick-ship/new/route", ["exports", "flaneur/pods/brand/boutiques/boutique/shipments/new/route"], function (_exports, _route) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  class ServicesQuickShipNewRoute extends _route.default {}

  _exports.default = ServicesQuickShipNewRoute;
});