define("flaneur/pods/components/order-item/mobile/component", ["exports", "jquery", "date-fns"], function (_exports, _jquery, _dateFns) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    brand: Ember.inject.service(),
    classNames: ['order-item-mobile'],
    order: null,
    reservation: null,
    isActive: null,
    isPrint: null,
    isCollection: null,
    irReturn: null,

    toggleShowMobileMenu() {},

    markAsReturned() {},

    returningCustomer: Ember.computed('order.repeat_order', function () {
      return this.get('order.repeat_order') ? 'Returning customer' : 'First time customer';
    }),
    isToday: Ember.computed('order.created_at', function () {
      return (0, _dateFns.isSameDay)(new Date(), (0, _dateFns.parseISO)(this.order.created_at));
    }),
    hasCustomsForm: Ember.computed.alias('shipment.hasCustomsForm'),
    isPartiallySelfShipping: Ember.computed.alias('boutique.allow_partial_self_shipping'),
    hasBooked: Ember.computed.alias('reservationBoutique.0.arranged_courier'),
    printedReceipt: Ember.computed.alias('reservationBoutique.0.printed_receipt'),
    printedLabel: Ember.computed.alias('reservationBoutique.0.printed_shipping_label'),
    printedCustomsForm: Ember.computed.alias('reservationBoutique.0.printed_customs_form'),
    isMarkedAsCollected: Ember.computed.alias('reservationBoutique.0.marked_as_collected'),
    isNotOutOfStock: Ember.computed.not('isAllOutOfStock'),
    isActiveAndNotOutOfStock: Ember.computed.and('isActive', 'isNotOutOfStock'),
    isReservationBoutiqueShippingProviderOther: Ember.computed.equal('reservationBoutique.0.shipping_provider', 'other'),
    isAnyReservationInActive: Ember.computed.gt('activeLength', 0),

    didInsertElement() {
      this._super(...arguments);

      (0, _jquery.default)('html').addClass('hide-scroll-bar');
    },

    didDestroyElement() {
      this._super(...arguments);

      (0, _jquery.default)('html').removeClass('hide-scroll-bar');
    }

  });

  _exports.default = _default;
});