define("flaneur/models/curate/product/index", ["exports", "fetch", "query-string", "flaneur/config/environment", "flaneur/utils/is-string", "flaneur/models/curate/brand", "flaneur/enums/curate-product-status", "flaneur/enums/curate-product-stock-line-status"], function (_exports, _fetch, _queryString, _environment, _isString, _brand, _curateProductStatus, _curateProductStockLineStatus) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _class, _descriptor;

  function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); enumerableOnly && (symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols); } return keys; }

  function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = null != arguments[i] ? arguments[i] : {}; i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { _defineProperty(target, key, source[key]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } return target; }

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  const DEFAULT_MINIMUM_ORDER_QUANTITY = 1;

  class CurateProductStockLine {
    constructor(stockLine) {
      const {
        _id,
        name,
        sku,
        status,
        quantity,
        display_order,
        minimum_order_quantity,
        wholesale_price,
        description,
        dimensions,
        retail_price,
        images,
        pack_size,
        variants
      } = stockLine;
      this._id = _id;
      this.name = name;
      this.sku = sku;
      this.status = status;
      this.quantity = quantity;
      this.description = description;
      this.displayOrder = display_order;
      this.dimensions = dimensions;
      this.minimumOrderQuantity = minimum_order_quantity !== null && minimum_order_quantity !== void 0 ? minimum_order_quantity : DEFAULT_MINIMUM_ORDER_QUANTITY;
      this.wholesalePrice = wholesale_price;
      this.retailPrice = retail_price;
      this.images = images || [];
      this.packSize = pack_size;
      this.variants = variants;
      this.thumbnailUrl = images !== null && images !== void 0 && images.length ? images[0].url : undefined;
    }

    get isPublished() {
      return this.status === _curateProductStockLineStatus.default.PUBLISHED;
    }

    get displayableDimensions() {
      var _this$dimensions$dime;

      const dimensions = ['length', 'width', 'height'];
      const unit = (_this$dimensions$dime = this.dimensions[dimensions.find(key => {
        var _this$dimensions$key$, _this$dimensions$key;

        return (_this$dimensions$key$ = (_this$dimensions$key = this.dimensions[key]) === null || _this$dimensions$key === void 0 ? void 0 : _this$dimensions$key.value) !== null && _this$dimensions$key$ !== void 0 ? _this$dimensions$key$ : false;
      })]) === null || _this$dimensions$dime === void 0 ? void 0 : _this$dimensions$dime.unit;

      if (!unit) {
        return null;
      }

      const values = dimensions.reduce((acc, key) => {
        var _this$dimensions$key2;

        return (_this$dimensions$key2 = this.dimensions[key]) !== null && _this$dimensions$key2 !== void 0 && _this$dimensions$key2.value ? [...acc, this.dimensions[key].value] : acc;
      }, '');
      return `${values.join(' x ')} ${unit}`;
    }

  }

  let CurateProduct = (_dec = Ember._tracked, (_class = class CurateProduct {
    constructor({
      _id,
      name,
      slug,
      brand,
      stock_lines
    }) {
      _initializerDefineProperty(this, "brand", _descriptor, this);

      this.id = _id;
      this.name = name;
      this.slug = slug;
      this.brand = Ember.isEmpty(brand) || (0, _isString.default)(brand) ? brand : new _brand.default(brand);
      this.stockLines = stock_lines.map(stockLine => new CurateProductStockLine(stockLine)).filter(stockLine => stockLine.isPublished);
    }

    get hasSingleStockLine() {
      return this.stockLines.length === 1;
    }

    get images() {
      return this.stockLines.flatMap(stockLine => stockLine.images.map(image => _objectSpread(_objectSpread({}, image), {}, {
        sku: stockLine.sku
      }))).sort((a, b) => a.display_order - b.display_order);
    }

    get packSize() {
      const minPackSize = this.stockLines.reduce((acc, stockLine) => {
        const value = parseFloat(stockLine.packSize);
        return value && value < acc ? stockLine.packSize : acc;
      }, Number.POSITIVE_INFINITY);
      return isFinite(minPackSize) ? minPackSize : 1;
    }

    get lowestWholesalePrice() {
      return this.stockLines.reduce((acc, stockLine) => {
        const value = parseFloat(stockLine.wholesalePrice.amount);
        return value && value < acc.amount ? stockLine.wholesalePrice : acc;
      }, {
        amount: Number.POSITIVE_INFINITY,
        currency: undefined
      });
    }

    get lowestRetailPrice() {
      const lowestRetailPrice = this.stockLines.reduce((acc, stockLine) => {
        if (!stockLine.retailPrice) {
          return acc;
        }

        const value = parseFloat(stockLine.retailPrice.amount);
        return value && value < acc.amount ? stockLine.retailPrice : acc;
      }, {
        amount: Number.POSITIVE_INFINITY,
        currency: undefined
      });
      return isFinite(lowestRetailPrice.amount) ? lowestRetailPrice : null;
    }

    get hasSingleWholesalePrice() {
      return this.stockLines.every(({
        wholesalePrice
      }) => wholesalePrice.amount === this.lowestWholesalePrice.amount);
    }

    get hasSingleRetailPrice() {
      var _this$retailPrice;

      if (!Number.isFinite((_this$retailPrice = this.retailPrice) === null || _this$retailPrice === void 0 ? void 0 : _this$retailPrice.amount)) {
        return false;
      }

      return this.stockLines.every(({
        retailPrice
      }) => (retailPrice === null || retailPrice === void 0 ? void 0 : retailPrice.amount) === this.retailPrice.amount);
    }

    get hasSinglePackSize() {
      if (!Number.isFinite(this.packSize)) {
        return false;
      }

      return this.stockLines.every(({
        packSize
      }) => packSize === this.packSize);
    }

    static async find(query) {
      const queryParams = _queryString.default.stringify(_objectSpread(_objectSpread({}, query), {}, {
        status: _curateProductStatus.default.PUBLISHED
      }));

      const url = `${_environment.default.curateApiURL}/api/v1/products?${queryParams}`;
      const res = await (0, _fetch.default)(url, {
        method: 'GET',
        credentials: 'include',
        cache: false
      });
      const {
        products: items = [],
        meta = {
          total: 0
        }
      } = await res.json();
      const curateProducts = items.map(item => new CurateProduct(item));
      return {
        curateProducts,
        meta
      };
    }

  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "brand", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  })), _class));
  _exports.default = CurateProduct;
});