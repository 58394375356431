define("flaneur/models/color/index", ["exports", "flaneur/mixins/request"], function (_exports, _request) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const Color = Ember.Object.extend(_request.default, {});
  Color.reopenClass(_request.default, {
    // Class methods
    find() {
      return this.request({
        method: 'GET',
        url: this.apiURL('colors')
      }).then(res => {
        return res.colors.map(color => {
          return this.create(color);
        });
      });
    }

  });
  var _default = Color;
  _exports.default = _default;
});