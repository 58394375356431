define("flaneur/pods/components/reservation-product-gift/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "h0Ep///6",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[10,\"div\"],[15,0,[31,[\"order-gift \",[30,[36,0],[[32,0,[\"hasOptedIn\"]],\"order-gift--teal\"],null],\" \",[32,0,[\"styleNamespace\"]]]]],[12],[2,\"\\n  \"],[8,\"fa-icon\",[[24,0,\"fa\"]],[[\"@icon\",\"@prefix\"],[\"gift\",\"far\"]],null],[2,\"\\n  \"],[10,\"div\"],[14,0,\"order-gift__text\"],[12],[2,\"\\n    \"],[10,\"p\"],[14,0,\"order-gift__text-title\"],[12],[2,\"This is a gift order\"],[13],[2,\"\\n    \"],[10,\"p\"],[14,0,\"order-gift__text-description\"],[12],[2,\"\\n\"],[6,[37,0],[[32,0,[\"hasOptedIn\"]]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"        You are a gift-ready boutique, please remember to gift wrap the item(s) and remove any price tags.\\n        Find out more about the\\n        \"],[10,\"a\"],[14,6,\"https://trouvaboutiques.zendesk.com/hc/en-us/articles/360014745237-Gift-ready-Service-Stand-out-this-Christmas\"],[14,\"target\",\"_blank\"],[12],[2,\"\\n          gift-ready service.\\n        \"],[13],[2,\"\\n\"]],\"parameters\":[]},{\"statements\":[[2,\"        As this is being sent straight to the recipient, please remember to remove any price tags. You can package as normal, but a finishing touch is welcome!\\n\"]],\"parameters\":[]}]]],[2,\"    \"],[13],[2,\"\\n  \"],[13],[2,\"\\n\"],[13],[2,\"\\n\"],[18,1,null],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"if\"]}",
    "moduleName": "flaneur/pods/components/reservation-product-gift/template.hbs"
  });

  _exports.default = _default;
});