define("flaneur/pods/components/pages/dashboard/index-page/component", ["exports", "@glimmer/component", "ember-component-css/pod-names"], function (_exports, _component, _podNames) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _dec8, _dec9, _dec10, _dec11, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  const NOTIFICATIONS_LIMIT = 3;
  let PagesDashboardIndexPageComponent = (_dec = Ember.inject.service, _dec2 = Ember.inject.service, _dec3 = Ember.inject.service, _dec4 = Ember.inject.service, _dec5 = Ember.inject.service, _dec6 = Ember._action, _dec7 = Ember._action, _dec8 = Ember._action, _dec9 = Ember._action, _dec10 = Ember._action, _dec11 = Ember._action, (_class = class PagesDashboardIndexPageComponent extends _component.default {
    constructor(...args) {
      super(...args);

      _initializerDefineProperty(this, "analytics", _descriptor, this);

      _initializerDefineProperty(this, "brand", _descriptor2, this);

      _initializerDefineProperty(this, "router", _descriptor3, this);

      _initializerDefineProperty(this, "notificationsStorage", _descriptor4, this);

      _initializerDefineProperty(this, "boutiqueRestockInformation", _descriptor5, this);

      _defineProperty(this, "styleNamespace", _podNames.default['pages/dashboard/index-page']);
    }

    get boutique() {
      return this.brand.currentBoutique;
    }

    get notifications() {
      return this.notificationsStorage.cachedNotifications.filter(({
        featured
      }) => featured).slice(0, NOTIFICATIONS_LIMIT);
    }

    trackButton({
      label
    }) {
      this.analytics.gtm({
        action: 'clicked_button_in_notification',
        label
      });
    }

    trackExternalLink({
      externalLink,
      track: {
        action,
        label
      }
    }) {
      this.analytics.gtm({
        action,
        label
      });
      window.open(externalLink);
    }

    onClickNotification({
      route
    }) {
      this.analytics.gtm({
        action: 'dashboard_clicks_notifications',
        label: route
      });
      return this.router.transitionTo(`brand.boutiques.boutique.${route}`, this.brand, this.brand.currentBoutique);
    }

    async openNotificationsMenu() {
      this.analytics.gtm({
        action: 'clicked_open_notificatons_panel',
        label: this.boutique._id
      });
      const element = document.querySelector('.nav-bar__notification-menu');
      setTimeout(() => element.click(), 0);
    }

    transitionToOrdersStage(stage) {
      this.analytics.gtm({
        action: 'dashboard_click_orders_link',
        label: stage
      });
      return this.router.transitionTo('brand.boutiques.boutique.orders.stage', this.brand, this.boutique, stage);
    }

    trackLink(action) {
      this.analytics.gtm({
        action,
        label: this.boutique._id
      });
    }

  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "analytics", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "brand", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "router", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "notificationsStorage", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "boutiqueRestockInformation", [_dec5], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "trackButton", [_dec6], Object.getOwnPropertyDescriptor(_class.prototype, "trackButton"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "trackExternalLink", [_dec7], Object.getOwnPropertyDescriptor(_class.prototype, "trackExternalLink"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "onClickNotification", [_dec8], Object.getOwnPropertyDescriptor(_class.prototype, "onClickNotification"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "openNotificationsMenu", [_dec9], Object.getOwnPropertyDescriptor(_class.prototype, "openNotificationsMenu"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "transitionToOrdersStage", [_dec10], Object.getOwnPropertyDescriptor(_class.prototype, "transitionToOrdersStage"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "trackLink", [_dec11], Object.getOwnPropertyDescriptor(_class.prototype, "trackLink"), _class.prototype)), _class));
  _exports.default = PagesDashboardIndexPageComponent;
});