define("flaneur/enums/country-iso-code-alpha-3", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    FRA: 'FRA',
    DEU: 'DEU',
    GBR: 'GBR'
  };
  _exports.default = _default;
});